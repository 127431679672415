import React, { useEffect, useState } from 'react'
import { getRecentlyAddedPlayers } from '../../../services/axios';
import { DataTable } from '../../../components/tables';

export default function RecentlyAddedPlayers() {
    const columnsPlayers = [
        {
          title: "Adı Soyadı",
          dataIndex: "playerName",
        },
        {
          title: "Şehir",
          dataIndex: "playerCity",
        },
        {
          title: "Pozisyon",
          dataIndex: "position",
        },
      ];

    const [loading, setLoading] = useState(false);
    const [players , setPlayers] = useState([]);

    const fetchGetRecentlyAddedPlayers = async () => {
        try {
          setLoading(true);
          const results = await getRecentlyAddedPlayers(5);
            // console.log(results, "lalalalalalall")
          let nextMatches = results.response.data.map((item) => ({
            playerName: item.user_name ? item.user_name : "-",
            playerCity: item?.city?.name ?  item?.city?.name : "-",
            position: item?.position ? item?.position : "-",
          }));
            setPlayers(nextMatches);
         
        } catch (error) {
          console.log("hata", error);
        } finally {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        fetchGetRecentlyAddedPlayers();
      }, []);
  return (
    <>
     <div className="px-4 pt-8 bg-white py-36 rounded-2xl">
            <div className="flex items-center justify-between">
              <p className="font-semibold text-gray-400">
                Son Eklenen Oyuncular
              </p>
              <a href='/users/players' className="text-sm hover:underline text-primary">
                Tüm oyunucular
              </a>
            </div>
            <div className="pt-4">
              <DataTable columns={columnsPlayers} data={players} loading={loading} />
            </div>
          </div>
    </>
  )
}
