import React, {useEffect, useState} from "react";
import {DataTable} from "../../../components/tables";
import {getLeagues, getStandings} from "../../../services/axios";
import Select from "react-select";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import CustomModal from "../../../components/modals";
import AddBulkMatchesFields from "../../fixtures/AddBulkMatchesFields";
import FixtureMatchFields from "../../fixtures/FixtureMatchFields";
import {Input} from "antd";
import DynamicInput from "../../../components/inputs/dynamicInput";

const ScoreboardList = () => {
    const [leaguesLoading, setLeaguesLoading] = useState(false);
    const [standingsLoading, setStandingsLoading] = useState(true);
    const [leagueOptions, setLeagueOptions] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState([]);
    const [standingParams, setStandingParams] = useState([]);

    const columns = [
        {title: "Sıralama", dataIndex: "key"},
        {
            title: "Takım",
            dataIndex: "club_name",
            render: (text, record) => (<div className={record.isMainClub ? "font-bold" : ""}>{text}</div>)
        },
        {title: "Oynanan Maç", dataIndex: "matchPlayed"},
        {title: "Galibiyet", dataIndex: "win"},
        {title: "Beraberlik", dataIndex: "draw"},
        {title: "Mağlubiyet", dataIndex: "defeat"},
        {title: "Atılan Gol", dataIndex: "goalScored"},
        {title: "Yenilen Gol", dataIndex: "goalConceded"},
        {title: "Averaj", dataIndex: "average"},
        {title: "Puan", dataIndex: "point"}
    ];


    const fetchGetLeagues = async () => {
        setLeaguesLoading(true);
        try {
            const result = await getLeagues(1);
            setLeagueOptions([]);
            setLeagueOptions((prevState) => [
                ...prevState,
                ...result?.data.data.map((item) => ({
                    label: item?.name + " | " + item?.season.season_title,
                    value: item?.id
                }))
            ]);
            setSelectedLeague({
                league: {
                    label: result?.data.data[0]?.name + " | " + result?.data.data[0]?.season?.season_title,
                    value: result?.data.data[0]?.id
                }
            });
            return true;
        } catch (error) {
            console.log("hata", error);
        } finally {
            setLeaguesLoading(false);
        }
    };
    const fetchGetStandings = async () => {
        setStandingsLoading(true);
        try {
            const results = await getStandings(selectedLeague?.league?.value);
            let standings = results.response.data.map((item, index) => ({
                goalConceded: item.goalConceded,
                goalScored: item.goalScored,
                draw: item.draw,
                defeat: item.defeat,
                win: item.win,
                matchPlayed: item.matchPlayed,
                key: index + 1,
                average: item.average,
                point: item.point,
                club_name: item.club.club_name,
                isMainClub: item?.isMainClub,
            }));
            setStandingParams(standings);
        } catch (error) {
            console.log("hata", error);
        } finally {
            setStandingsLoading(false);
        }
    };

    useEffect(() => {
        fetchGetLeagues();
    }, []);

    useEffect(() => {
        if (selectedLeague) {
            if (Object.keys(selectedLeague).length > 0) {
                fetchGetStandings();
            }
        }
    }, [selectedLeague]);
    return (
        <>
            <div className="w-full p-4 bg-white rounded-md shadow">
                <div className="flex items-center justify-between text-gray-500 mb-5">
                    <h1 className="px-4 py-4 text-3xl text-primary">Puan Tablosu</h1>
                    <div className={"flex gap-3"}>
                        <DynamicInput
                            labelTitle={"Lig Seçiniz"}
                            containerStyle={"w-[450px]"}
                            placeholder={"Lig Seçiniz"}
                            name={"league"}
                            value={selectedLeague}
                            onChange={(e) => {
                                setSelectedLeague({ league: e });
                            }}
                            component_type={"select"}
                            customOptions={leagueOptions}
                            disabled={leaguesLoading}
                        />
                    </div>
                </div>
                <div>
                    <DataTable
                        columns={columns}
                        data={standingParams}
                        loading={standingsLoading}
                    />
                </div>
            </div>
        </>
    );
};

export default ScoreboardList;
