import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
// import { getSelects } from "../../services/axios";
function SelectInput({ options, onChange }) {

  return (
    <div className="w-full">
      <Select
        onChange={onChange}
        // value={value}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 0,
            boxShadow: "none",
          }),
        }}
        options={options}
        className="selectInput"
      />
    </div>
  );
}

export default SelectInput;
