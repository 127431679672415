import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
// import { Paths } from "../../../routes";
import { Paths } from "../../routes"

import {PersonalList} from "../../containers/sections";

const Employees = () => {
    const [setBreadcrumbData] = useOutletContext();

    useEffect(() => {
        setBreadcrumbData([
            {
                title: 'Panel',
            },
            {
                title: <Link to={Paths.Employees.Employees}>Personel Listesi</Link>,
            }
        ]);
    }, []);

    return (
        <div className="w-full p-4 bg-white rounded-md shadow">
            <PersonalList />
        </div>
    );
}

export default Employees;