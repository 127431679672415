import React, { useEffect, useState } from "react";
import { DataTable } from "../../../components/tables";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import {
  createMatchCriterias,
  getCriteriaTypes,
  getMatch,
  getMatchCriterias,
  getMatchPlayers,
  getUpdateMatchFinish,
  updateMatchFinish
} from "../../../services/axios";
import CustomModalHeader from "../../../components/modals/CustomModalHeader";
import { Formik } from "formik";
import DynamicInput from "../../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../../utils/handleCustomChange";
import CustomModal from "../../../components/modals";
import Swal from "sweetalert2";

function MatchCriteries({ id, matchData }) {

  const [matchFinishButton, setMatchFinishButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataChange, setIsDataChange] = useState(false);
  const [openCriteriasModal, setOpenCriteriasModal] = useState(false);
  const [matchCriteriasDataTable, setMatchCriteriasDataTable] = useState([]);
  const [selectedMatchPlayers, setSelectedMatchPlayers] = useState([]);
  const [criteriasTypeOptions, setCriteriasTypeOptions] = useState(null);
  const [criteriasTeamOptions, setCriteriasTeamOptions] = useState([
    {
      label: matchData?.home_team?.club_name,
      value: matchData?.home_team?.id
    },
    {
      label: matchData?.guest_team?.club_name,
      value: matchData?.guest_team?.id
    }
  ]);
  const [isDefaultTeamSelected, setIsDefaultTeamSelected] = useState(true);
  const criteriaColumns = [
    {
      title: "Takım",
      dataIndex: "isMainTeam"
    },
    {
      title: "Dakika",
      dataIndex: "time"
    },
    {
      title: "Kriter Tipi",
      dataIndex: "criteria_type"
    },
    {
      title: "İlk Oyuncu",
      dataIndex: "player_one"
    },
    {
      title: "İkinci Oyuncu",
      dataIndex: "player_two"
    }
  ];


  const fetchGetMatchCriterias = async () => {
    try {
      setLoading(true);
      const result = await getMatchCriterias(id);
      const matchCriterias = result?.response?.match_criterias.filter(
        (item) => item?.match == id
      );

      setMatchCriteriasDataTable(
        matchCriterias?.map((item, index) => ({
          key: index,
          time: item?.time,
          isMainTeam: item?.isMainTeam
            ? matchData?.home_team?.club_name
            : matchData?.guest_team?.club_name,
          criteria_type: item?.criteria_type?.name
            ? item?.criteria_type?.name
            : "-",
          player_one: item?.player_one?.user_name
            ? item?.player_one?.user_name
            : "-",
          player_two: item?.player_two?.user_name
            ? item?.player_two?.user_name
            : "-"
        }))
      );
      return result;
    } catch (error) {
      console.log(error, "hataaa");
    } finally {
      setLoading(false);
    }
  };

  const fetchGetCriteriaTypes = async () => {
    try {
      const result = await getCriteriaTypes();
      const criteriaTypes = result.response.criteria_types;
      setCriteriasTypeOptions(
        criteriaTypes?.map((item) => ({
          label: item?.name ? item?.name : "-",
          value: item?.id
        }))
      );
      return result;
    } catch (error) {
      console.log(error, "hata");
    }
  };
  const fetchGetSelectedTeamPlayers = async (matchId) => {
    //player infos / selected player get
    try {
      setLoading(true);
      const result = await getMatchPlayers(matchId);
      if (result.success === true) {
        setSelectedMatchPlayers(
          result?.data?.players.map((item) => ({
            label: item?.player?.user_name,
            value: item?.player?.id
          }))
        );
      } else {
        console.log("hata getMatchPlayers");
      }
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
    }
  };

  const fetchtUpdateMatchFinish = async (fkMatch, isFinish) => {
    try {
      const result = await updateMatchFinish(fkMatch, isFinish);
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Maç Bitirildi",
          showConfirmButton: false,
          timer: 1000
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Hata",
          showConfirmButton: false,
          timer: 1000
        });
      }
    } catch (error) {
      console.log(error, "hata");
    }
  };

  const fetchCreateMatchCriteria = async (
    match,
    criteria_type,
    player_one_id,
    player_two_id,
    time,
    half,
    team_id
  ) => {
    setLoading(true);
    let isMainTeam = isDefaultTeamSelected ? 1 : 0;
    try {
      const result = await createMatchCriterias(
        match,
        criteria_type,
        player_one_id,
        player_two_id,
        time,
        half,
        team_id,
        isMainTeam
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000
        });
        setIsDataChange(true);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetCriteriaTypes();
  }, []);

  useEffect(() => {
    if (matchFinishButton) {
      fetchtUpdateMatchFinish(id, 1);
      setMatchFinishButton(false);
    }
  }, [matchFinishButton]);

  useEffect(() => {
    fetchGetMatchCriterias();
    setIsDataChange(false);
  }, [isDataChange]);

  useEffect(() => {
    if (openCriteriasModal) {
      fetchGetSelectedTeamPlayers(id);
    }
  }, [openCriteriasModal]);
  return (
    <>
      <p className="pt-4 pb-8 text-2xl font-semibold text-secondary">
        Maç Kriterleri
      </p>
      <div className="">
        <DataTable
          columns={criteriaColumns}
          data={matchCriteriasDataTable}
          loading={loading}
        />
      </div>

      <div className="flex items-center gap-5 justify-end py-4 ">
        <ButtonComponent
          disabled={loading}
          fill
          text="Maç Kriteri Ekle"
          customStyle={"w-fit"}
          loading={matchData?.isFinish ? true : false}
          onClick={() => {
            setOpenCriteriasModal(true);
          }}
        />
       
      </div>
    

      <CustomModal
        width={677}
        isOpen={openCriteriasModal}
        setOpen={setOpenCriteriasModal}
        footer={null}
      >
        <CustomModalHeader
          title={"Maç Kriteri Ekle"}
          description={"Aşağıdan kriter ekleyebilirsiniz."}
        />
        <div className="mx-4">
          <Formik
            initialValues={{
              match: id,
              criteria_type: "",
              player_one_id: "",
              player_two_id: "",
              time: "",
              half: "",
              team_id: "",
              isMainTeam: ""
            }}
            onSubmit={async (values, { resetForm }) => {
              setTimeout(function () {
                resetForm();
              }, 1000);

              values.criteria_type = values?.criteria_type?.value;
              values.player_one_id = isDefaultTeamSelected
                ? values?.player_one_id.value ?? 0
                : 0;
              values.player_two_id = isDefaultTeamSelected
                ? values?.player_two_id.value ?? null
                : null;
              values.team_id = values?.team_id?.value;
              try {
                await fetchCreateMatchCriteria(
                  values?.match,
                  values?.criteria_type,
                  values.player_one_id,
                  values.player_two_id,
                  values?.time,
                  values?.half,
                  values.team_id
                );
              } catch (error) {
                console.log(error, "hata");
              }
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              resetForm
            }) => (
              <form onSubmit={handleSubmit} className="flex flex-col w-full">
                <div className="grid gap-4 md:grid-cols-2">
                  <DynamicInput
                    labelTitle={"Kriter Tipi"}
                    containerStyle={"w-full"}
                    placeholder={"Kriter Tipi Seçiniz"}
                    name={"criteria_type"}
                    value={values}
                    onChange={(e1, e2) => {
                      handleCustomChange(
                        e1,
                        e2,
                        "criteria_type",
                        setFieldValue,
                        "select"
                      );
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                    component_type={"select"}
                    customOptions={criteriasTypeOptions ?? ""}
                  />
                  <DynamicInput
                    labelTitle={"Takım Seç"}
                    containerStyle={"w-full"}
                    placeholder={"Takım Seçiniz"}
                    name={"team_id"}
                    value={values}
                    onChange={(e1, e2) => {
                      handleCustomChange(
                        e1,
                        e2,
                        "team_id",
                        setFieldValue,
                        "select"
                      );
                      if (e1.label === "Altınordu") {
                        setIsDefaultTeamSelected(true);
                      } else {
                        setIsDefaultTeamSelected(false);
                      }
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                    component_type={"select"}
                    customOptions={criteriasTeamOptions ?? ""}
                  />
                  <DynamicInput
                    labelTitle={"Süre"}
                    containerStyle={"w-full"}
                    placeholder={"Süre Giriniz"}
                    name={"time"}
                    value={values}
                    onChange={(e1, e2) => {
                      handleCustomChange(
                        e1,
                        e2,
                        "time",
                        setFieldValue,
                        "input"
                      );
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                    component_type={"input"}
                    type={"number"}
                  />
                  <DynamicInput
                    labelTitle={"Yarı"}
                    containerStyle={"w-full"}
                    placeholder={"Yarı Giriniz"}
                    name={"half"}
                    value={values}
                    onChange={(e1, e2) => {
                      handleCustomChange(
                        e1,
                        e2,
                        "half",
                        setFieldValue,
                        "input"
                      );
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                    component_type={"input"}
                    type={"number"}
                  />
                  {isDefaultTeamSelected && (
                    <>
                      <DynamicInput
                        labelTitle={"Oyuncu"}
                        containerStyle={"w-full"}
                        placeholder={"Oyuncu Seçiniz"}
                        name={"player_one_id"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "player_one_id",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={selectedMatchPlayers ?? []}
                        required={true}
                      />
                      <DynamicInput
                        labelTitle={"Asist Yapan Oyuncu"}
                        containerStyle={"w-full"}
                        placeholder={"Oyuncu Seçiniz"}
                        name={"player_two_id"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "player_two_id",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={selectedMatchPlayers ?? []}
                      />
                    </>
                  )}
                </div>
                <div className="w-full flex justify-end mt-4 gap-3">
                  <ButtonComponent
                    onClick={() => setOpenCriteriasModal(false)}
                    outline
                    text={"Vazgeç"}
                  />
                  <ButtonComponent fill text={"Ekle"} submit />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </CustomModal>
    </>
  );
}

export default MatchCriteries;
