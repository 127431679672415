import React, { useEffect, useState } from "react";
import { DataTable } from "../../../components/tables";
import ApprovalButtonsDataTable from "../../../containers/sections/ApprovalButtonsDataTable";
import {
  approveActivity,
  deleteActivity,
  getUnapprovedActivities,
} from "../../../services/axios";
import Swal from "sweetalert2";

const ApprovalActivities = () => {
  const [isDataChange, setIsDataChange] = useState(true);
  const [loading, setLoading] = useState(true);
  const [unapprovedActivitiesDataTable, setUnapprovedActivitiesDataTable] =
    useState([]);

  useEffect(() => {
    if (isDataChange) {
      fetchGetUnapprovedActivities().then((result) => {
        setUnapprovedActivitiesDataTable(
          result.map((item, index) => ({
            id: item?.id,
            title: item?.title,
            start: item?.start,
            type: item?.activity_type,
          }))
        );
      });
    }
  }, [isDataChange]);

  const fetchGetUnapprovedActivities = async () => {
    setLoading(true);
    try {
      const result = await getUnapprovedActivities();
      const calendarFormatResult = result?.data?.activities.map(
        (item, index) => ({
          start: item?.start_date + " " + item?.start_time,
          end: item?.end_date + " " + item?.end_time,
          title: item?.title,
          id: item?.id,
          color: item?.color,
          approval: item?.is_approved,
          activity_type: item?.activity_type
        })
      );
      return calendarFormatResult;
    } catch (error) {
      console.log(error, "err");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  const fetchApprovalActivity = async (id) => {
    setLoading(true);
    try {
      const result = await approveActivity(id);
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });
        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log(error, "err");
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };

  const fetchDeteleActivity = async (id) => {
    try {
      const result = await deleteActivity(id);
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setIsDataChange(true);
    }
  };

  function handleDelete(item) {
    console.log(item, "omer");
    Swal.fire({
      title: `${item.title} silinecek emin misiniz?`,
      text: "Bu işlemi geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "Vazgeç",
    }).then((result) => {
      if (result.isConfirmed) {
        // Silme işlemini gerçekleştir
        fetchDeteleActivity(item.id);
        Swal.fire(
          `${item?.title} Silindi!`,
          "Seçtiğiniz öğe başarıyla silindi.",
          "success"
        );
      }
    });
  }

  const columns = [
    {
      title: "No",
      dataIndex: "id",
    },
    {
      title: "Tip",
      dataIndex: "type",
    },
    {
      title: "Başlangıç Tarihi",
      dataIndex: "start",
    },
    {
      title: "İşlem",
      fixed: "right",
      key: "action",
      sorter: true,
      width: "",
      render: (item, index) => {
        return (
          <ApprovalButtonsDataTable
            onClikApprove={() => fetchApprovalActivity(item?.id)}
            rejectDataLabel={item?.title}
            onClickReject={() => handleDelete(item)}
            approveButton
            rejectButton
          />
        );
      },
    },
  ];
  console.log(unapprovedActivitiesDataTable, "unapprovedActivities");
  return (
    <>
      <div className="w-full p-4 bg-white rounded-md shadow">
        <div className="flex items-center justify-between pb-4 text-gray-500">
          <h4 className="text-lg font-semibold">Onay Bekleyen Faaliyetler</h4>
        </div>
        <div className="">
          <DataTable
            columns={columns}
            data={unapprovedActivitiesDataTable}
            // selection={{ rowSelectionList, setRowSelectionList }}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default ApprovalActivities;
