import React, { useEffect, useState } from "react";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { GeneralDefinitionsMainMenuDatas } from "../../utils/datas/GeneralDefinitionsMenuDatas/GeneralDefinitionsMainMenuDatas";
import SelectDefinitions from "./SelectDefinitions";
import FootballGroundsDefinitions from "./FootballGroundsDefinitions";
import SeasonsDefinitions from "./SeasonsDefinitions";
import LeagueDefinitions from "./LeagueDefinitions";
import TeamDefinitions from "./TeamDefinitions";
import ClubDefinations from "./ClubDefinations";
import JudgeDefinations from "./JudgeDefinations";
import TeamCategoryDefinitions from "./TeamCategoryDefinitions";
import ActivitiesCategories from "./ActivitiesCategoriesDefinitions";
import UnitDefinitions from "./UnitDefinitions";
import ActivitiesAreasDefinitions from "./ActivitiesAreasDefinitions";
import TestDefinitions from "./TestDefinitions";
import DrillDefinitions from "./DrillDefinitions";
import TreatmentsDefinitions from "./TreatmentsDefinitions";
import HealtcareOrganizations from './HealtcareOrganizationsDefinitions'
import { useParams, useHistory, useNavigate } from "react-router-dom";

function GeneralDefinitions() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [mainMenuSelected, setMainMenuSelected] = useState(
    id ?? "faaliyet-kategorileri"
  );

  useEffect(() => {
    navigate(`/general-definitions/${mainMenuSelected}`);
  }, [mainMenuSelected, navigate]);

  return (
    <div>
      <div className="w-full h-full py-8 bg-white shadow rounded-2xl zoom-none">
        <h1 className="px-4 py-4 text-3xl text-primary">Genel Tanımlar</h1>
        {/* main key : {mainMenuSelected} */}
        <div className="flex flex-col gap-4 px-4">
          <DrawerMenuAntd
            horizontal
            items={GeneralDefinitionsMainMenuDatas}
            selectedKey={mainMenuSelected}
            setSelectedKey={setMainMenuSelected}
          />
          {mainMenuSelected === "tedavi-tanimlari" && <TreatmentsDefinitions />}
          {mainMenuSelected === "drill-tanimlari" && <DrillDefinitions />}
          {mainMenuSelected === "test-tanimlari" && <TestDefinitions />}
          {mainMenuSelected === "faaliyet-alani" && (
            <ActivitiesAreasDefinitions />
          )}
          {mainMenuSelected === "secenek-tanimlari" && <SelectDefinitions />}
          {mainMenuSelected === "saha-tanimlari" && (
            <FootballGroundsDefinitions />
          )}
          {mainMenuSelected === "sezon-tanimlari" && <SeasonsDefinitions />}
          {mainMenuSelected === "lig-tanimlari" && <LeagueDefinitions />}
          {mainMenuSelected === "takim-tanimlari" && <TeamDefinitions />}
          {mainMenuSelected === "faaliyet-kategori-tanimlari" && (
            <TeamCategoryDefinitions />
          )}
          {mainMenuSelected === "kulup-tanimlari" && <ClubDefinations />}
          {mainMenuSelected === "hakem-tanimlari" && <JudgeDefinations />}
          {mainMenuSelected === "faaliyet-kategorileri" && (
            <ActivitiesCategories />
          )}
          {mainMenuSelected === "birim-tanimlari" && <UnitDefinitions />}
          {mainMenuSelected === 'saglik-kuruluslari' && <HealtcareOrganizations />}
        </div>
      </div>
    </div>
  );
}

export default GeneralDefinitions;
