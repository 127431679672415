import React, { useEffect } from "react";
import TeamsTable from "./teamsTable";
import { Link, useOutletContext } from "react-router-dom";
import { Paths } from "../../routes";

const Teams = () => {

  const [setBreadcrumbData] = useOutletContext();

    useEffect(() => {
        setBreadcrumbData([
            {
                title: 'Panel',
            },
            {
                title: <Link to={Paths.Teams.Teams}>Takımlar</Link>,
            }
        ]);
    }, []);

  return (
    <div className="w-full bg-white rounded-md p-4 shadow">
      <TeamsTable />
    </div>
  );
};

export default Teams;
