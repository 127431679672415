import { SettingOutlined } from "@ant-design/icons";
import { getMenuItem } from "../../components/drawerMenuAntd";

export const ReportsMenuData = [
    getMenuItem("Oyuncu İstatistikleri", "oyuncu-istatistikleri", <SettingOutlined />, null),
    getMenuItem("Atılan Goller", "atılan-goller", <SettingOutlined />, null),
    getMenuItem(" Kırmızı Kartlar ", "kırmızı-kartlar", <SettingOutlined />, null),
    getMenuItem(" Sarı Kartlar ", "sarı-kartlar", <SettingOutlined />, null),
    getMenuItem(" Koçlar ", "koçlar", <SettingOutlined />, null),
    getMenuItem(" Oyuncu Raporları ", "oyuncu-raporları", <SettingOutlined />, null),
];
