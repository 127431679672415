import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Files } from "./utils";
import { Routes } from "./routes";

import { BrowserRouter, Routes as Switch, Route } from "react-router-dom";
import {
  getAuthUser,
  getStoredBearerToken,
  logoutRequest,
} from "./services/axios";
import { setAuthUser } from "./services/redux/features/authUser/authUserSlice";
import { useDispatch, useSelector } from "react-redux";

const App = () => {
  const dispatch = useDispatch();
  const { logo } = Files;
  const [currentUser, setCurrentUser] = useState(true);
  const [loading, setLoading] = useState(true);
  const authUser = useSelector((state) => state.authUser.user);

  // login'de token oluşur, main layout'ta kontrolü yapılır
  // token'ın süresi bitmiş veya hatalı ise silinir ve login'e yönlenir.
  useEffect(() => {
    if (getStoredBearerToken) {
      setCurrentUser(true);
    } else {
      setCurrentUser(false);
    }
  }, [getStoredBearerToken]);

  // console.log(authUser, "ciooooo");

  const savedBaseUrl = localStorage.getItem("baseUrlLocal");

  if (!savedBaseUrl) {
    localStorage.setItem(
      "baseUrlLocal",
      "https://altinordu-api.golpro.com.tr"
    );
  }

  return (
    <>
      <Helmet>
        <title>Altinordu App</title>
        <link rel="icon" href={logo} />
      </Helmet>
      <BrowserRouter>
        <Switch>
          {currentUser
            ? Routes.PrivateRoutes.map((route, index) => {
                if (route.layout) {
                  return (
                    <Route key={index} element={route.layout}>
                      {route.pages.map((pRoute, pIndex) => {
                        return (
                          <Route
                            key={pIndex}
                            path={pRoute.path}
                            element={pRoute.element}
                          />
                        );
                      })}
                    </Route>
                  );
                } else {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  );
                }
              })
            : Routes.PublicRoutes.map((route, index) => {
                if (route.layout) {
                  return (
                    <Route key={index} element={route.layout}>
                      {route.pages.map((pRoute, pIndex) => {
                        return (
                          <Route
                            key={pIndex}
                            path={pRoute.path}
                            element={pRoute.element}
                          />
                        );
                      })}
                    </Route>
                  );
                } else {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  );
                }
              })}
          <Route path="*" element={<p>Bulunamadı!</p>} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
