import React, {useRef, useState, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import {BsChevronDown} from "react-icons/bs";

const MenuBox = ({menuList}) => {
    return (
        <ul className="items-center hidden h-full lg:flex">
            {menuList.map((item, index) => {
                if (!item.dropdown) {
                    return <Menu key={index} item={item}/>;
                } else {
                    return <SubMenu key={index} item={item}/>;
                }
            })}
        </ul>
    );
};

const Menu = ({item}) => {
    return (
        <li>
            <Link
                to={item.href}
                className="flex flex-col items-center justify-center ml-6 text-white text-md"
            >
                {item.title.toLocaleUpperCase("tr-TR")}
            </Link>
        </li>
    );
};

const SubMenu = ({item}) => {
    const [isActive, setIsActive] = useState(false);
    const menuRef = useRef("");
    const location = useLocation();

    const handleClickSubMenu = () => {
        setIsActive((a) => !a);
    };
    const handleOutClickSubMenu = ({target}) => {
        if (menuRef.current && !menuRef.current.contains(target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        window.addEventListener("click", handleOutClickSubMenu);
        return () => window.removeEventListener("click", handleOutClickSubMenu);
    }, []);

    useEffect(() => {
        setIsActive(false);
    }, [location]);

    return (
        <li ref={menuRef} className="relative z-50">
            <button
                onClick={handleClickSubMenu}
                className="flex items-center justify-center ml-6 text-white text-md"
            >
                {item.title.toLocaleUpperCase("tr-TR")}
                <span className="ml-1">
                    <BsChevronDown/>
                </span>
            </button>
            <ul
                className={`absolute flex flex-col bg-white rounded-md shadow overflow-hidden transition-all ${
                    isActive ? "visible opacity-100 mt-2" : "invisible opacity-0 -mt-2"
                }`}
            >
                {item.dropdown.map((item, index) => {
                    return <SubMenuItem key={index} item={item}/>;
                })}
            </ul>
        </li>
    );
};

const SubMenuItem = ({item}) => {
    return (
        <li className="">
            <Link
                to={item.href}
                className="flex items-center px-4 py-2 transition-all hover:bg-gray-100 whitespace-nowrap"
            >
                {item.leftIcon && (
                    <span className="flex items-center mr-3">{item.leftIcon}</span>
                )}
                {item.title}
            </Link>
        </li>
    );
};

export default MenuBox;
