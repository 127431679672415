import { SettingOutlined } from "@ant-design/icons";
import { getMenuItem } from "../../../components/drawerMenuAntd";

export const GeneralDefinitionsInnerMenuDatas = {
  "menu-1": [
    getMenuItem("Yönetim", "menu-1-1", <SettingOutlined />, [
      getMenuItem("Kulüp Başkanı", "menu-1-1-1"),
      getMenuItem("Yönetim Kurulu Üyesi", "menu-1-1-2"),
    ]),
    getMenuItem("İdari", "menu-1-2", <SettingOutlined />, [
      getMenuItem("Futbol Direktörü", "menu-1-2-1"),
      getMenuItem("Genel Kaptan", "menu-1-2-2"),
    ]),
  ],

  "menu-3": [
    getMenuItem("Kimlik & Pasaport Bilgileri", "menu-3-1", <SettingOutlined />, [
      getMenuItem("Kulüp Başkanı", "menu-3-1-1"),
      getMenuItem("Yönetim Kurulu Üyesi", "menu-3-1-2"),
    ]),
    getMenuItem("İdari", "menu-2-2", <SettingOutlined />, [
      getMenuItem("Futbol Direktörü", "menu-3-2-1"),
      getMenuItem("Genel Kaptan", "menu-3-2-2"),
    ]),
  ],
};
