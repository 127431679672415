import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  baseImageURL,
  deleteMatch,
  getActivitiesCategories,
  getActivitiesCategory,
  getActivity,
  getAllAreas,
  getAllDrills,
  getAllTeamCategory,
  getAllUser,
  getDrillById,
  getFootballGround,
  getStoredBearerToken,
  getPlayerUsers,
  getTeamPlayers,
  listTeams,
  searchUser,
  updateActivity,
  deleteActivity,
  baseURL,
  updateActivitySelectedPlayer,
  getFacilitiess,
} from "../../../services/axios";
import PageTitle from "../../../components/pageTitle";
import { Formik } from "formik";
import DynamicInput from "../../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../../utils/handleCustomChange";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import { DataTable } from "../../../components/tables";
import { CarryOutOutlined } from "@ant-design/icons";
import CustomModal from "../../../components/modals";
import CustomModalHeader from "../../../components/modals/CustomModalHeader";
import { Badge, Input, Tree } from "antd";
import { RoundedIconButton } from "../../../components/buttons";
import { BsFillEnvelopeFill } from "react-icons/bs";
import ActionButtonsDataTable from "../../../containers/sections/ActionButtonsDataTable";
import { Paths } from "../../../routes";
import { FiEdit, FiTrash } from "react-icons/fi";
import { CgMoreR } from "react-icons/cg";
import TakeAttendanceModal from "./modals/takeAttendance";
import { BiErrorCircle } from "react-icons/bi";
import { AiFillCheckCircle } from "react-icons/ai";
import EditActivityDescriptionForm from "./forms/EditActivityDescriptionForm";
import { PositionData } from "../../../utils/datas/positionData";
import AsyncSelect from "react-select/async";
import axios from "axios";

const { DirectoryTree } = Tree;

function EditActivities() {
  let { id: activityID } = useParams();
  const [resultData, setResultData] = useState(null); //activity data
  const [isDataChange, setIsDataChange] = useState(true);
  const [loading, setLoading] = useState(true);
  const [resultDataIsLoaded, setResultDataIsLoaded] = useState(false);
  const navigate = useNavigate();

  // listelenen satırlar
  const [selectedRowItemsPlayer, setSelectedRowItemsPlayer] = useState(null);
  const [selectedRowItemsManager, setSelectedRowItemsManager] = useState(null);

  // eklenen satırlar
  const [rowSelectionList, setRowSelectionList] = useState(null);
  const [rowSelectionListManager, setRowSelectionListManager] = useState([]);

  // datatable'ın kendisi
  const [userDataTableData, setUserDataTableData] = useState();
  const [managerDataTableData, setManagerDataTableData] = useState();

  const [formLoading, setFormLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [newActivity, setNewActivity] = useState(null);

  const [teamCategoryOptions, setTeamCategoryOptions] = useState([]);
  const [drillsOptions, setDrillsOptions] = useState([]);

  const [isDrillModalOpen, setIsDrillModalOpen] = useState(false);

  const [selectedGuestPlayers, setSelectedGuestPlayers] = useState([]);

  //drill tree
  const [treeDataDrill, setTreeDataDrill] = useState([]);
  const [selectedDrill, setSelectedDrill] = useState(null);
  const [allDrills, setAllDrills] = useState([]);

  //selected players
  const [isSelectedPlayerModalOpen, setIsSelectedPlayerModalOpen] =
    useState(false);
  const [updateSelectedPlayerData, setUpdateSelectedPlayerData] = useState({
    id: "",
    name: "",
    status: "",
    notes: "",
    average: 0.0,
  });

  const [totalActivityTime, setTotalActivityTime] = useState(0);
  const [activityMovements, setActivityMovements] = useState([]);
  const [file, setFile] = useState(null);
  const handleFileChange = (e) => {
    // setFile(e.target.files[0]);
    console.log("act", activityID);
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Dosya input tarafından doğru bir şekilde alındı mı?
    if (selectedFile) {
      console.log("Dosya başarıyla seçildi:", selectedFile.name);
      console.log("act", activityID);
    } else {
      console.log("Dosya seçilemedi veya geçersiz.");
      console.log("act", activityID);
    }
  };

  const handleRemoveActivityData = () => {
    deleteActivity(activityID)
    navigate('/calendar')
  }
  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", activityID);
      await axios.post(
        `${baseURL}/activities/update-player-activity/${activityID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getStoredBearerToken}`,
          },
        }
      );
      setFile(null);
    } catch (error) {
      console.error("Dosya yükleme hatası:", error);
    }
  };
  //mertest
  const [playerSearchInputValue, setPlayerSearchInputValue] = useState(false);
  const [filteredPlayerDataTableData, setFilteredPlayerDataTableData] =
    useState([]);
  const [selectTeamPlayerIsLoading, setSelectTeamPlayerIsLoading] =
    useState(false);

  const onSelectDrill = async (selectedKeys, info) => {
    // console.log("objectselectedKeys: ", selectedKeys[0]);
    setFormLoading(true);
    try {
      const result = await getDrillById(selectedKeys[0]); //tek kategori çağrıma
      // console.log("resultttttt: ", result);
      setSelectedDrill(result?.data?.drill);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setFormLoading(false);
    }
  };

  function getChildrenDrill(drill) {
    if (!drill.sub_category || drill.sub_category.length === 0) {
      return [];
    }
    return drill?.sub_category?.map((item) => {
      const treeItem = getTreeItem(
        item.id,
        item.title,
        item.id,
        <CarryOutOutlined />
      );
      const children = getChildrenDrill(item);
      treeItem.children = children;
      return treeItem;
    });
  }

  const handleDataPushDrill = (item) => {
    setTreeDataDrill((prev) => [...prev, item]);
  };

  const fetchGetAllDrills = async () => {
    setTreeDataDrill([]);
    try {
      const result = await getAllDrills();
      // console.log(result?.data?.drills, "treeDataDrill");

      result?.data?.drills.forEach((item) => {
        if (!item.up_category) {
          const treeItem = getTreeItem(
            item.id,
            item.title,
            item.id,
            <CarryOutOutlined />
          );
          const children = getChildrenDrill(item);
          treeItem.children = children;

          handleDataPushDrill(treeItem);
        }
      });
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setIsDataChange(false);
    }
  };
  // drill modal bitiş

  // START::SELECTED PLAYER
  const handleUpdateSelectedPlayer = (
    id,
    name,
    status,
    notes,
    average,
    fatigue
  ) => {
    setUpdateSelectedPlayerData({
      id: id,
      name: name,
      status: status,
      notes: notes,
      average: average,
      fatigue: fatigue,
    });
    setIsSelectedPlayerModalOpen(true);
  };

  const convertSelectedPlayerStatus = (status) => {
    switch (status) {
      case "geldi":
        return "Geldi";
      case "izinli":
        return "İzinli";
      case "sakat":
        return "Sakat";
      case "hasta":
        return "Hasta";
      case "bt_yukari_gitti":
        return "BT Yukarı Gitti";
      case "bt_asagi_gitti":
        return "BT Aşağı Gitti";
      case "bt_yukaridan_geldi":
        return "BT Yukarıdan Geldi";
      case "bt_asagidan_geldi":
        return "BT Aşağıdan Geldi";
      case "yok":
        return "Yok";
      case "milli_takimda":
        return "Milli Takımda";
      case "kart_cezalisi":
        return "Kart Cezalısı";
      case "takim_dinlenmesi":
        return "Takım Dinlenmesi";
      case "karma":
        return "Karma";
      case "sahaya_donus":
        return "Sahaya Dönüş";
      case "ozel_antrenmanda":
        return "Özel Antrenmanda";
      case "aday_oyuncu":
        return "Aday Oyuncu";
    }
  };

  const loadActivityData = () => {
    fetchGetActity(activityID).then((result) => {
      console.log("actaydi", activityID);
      setResultData(result?.data?.activity);
      setResultDataIsLoaded(true);
      // console.log("BAQELE", result?.data?.activity);

      setActivityMovements(result?.data?.activity?.activities);
      // Bura
      console.log("reszaltltatlaltaltatlat", resultData);
      setSelectedRowItemsPlayer({
        selectedRowItems: result?.data?.activity?.activity_player.map(
          (item) => {
            return {
              key: item?.player?.id,
              name: `${item?.player?.name}  ${item?.player?.surname}` ?? "-",
              // tckn: item?.player?.tckn ?? "-",
              total_run: item?.total_run,
              sprint_distance: item?.sprint_distance,
              sprint_number: item?.sprint_number,
              high_intensity_run: item?.high_intensity_run,
              high_intensity_movement: item?.high_intensity_movement,
              total_movement: item?.total_movement,
              total_movement_high_intensity:
                item?.total_movement && item?.high_intensity_movement
                  ? item.total_movement / item.high_intensity_movement
                  : "",
              status: item?.status ?? "-",
              statusOption: {
                label: convertSelectedPlayerStatus(item?.status),
                value: item?.status,
              },
              average: item?.average ?? "-",
              averageOption: { label: item?.average, value: item?.average },
              position: item?.player?.position,
              // PositionData.find(
              //   (pItem) => pItem.value == item?.player?.position
              // )?.label ?? "-",
              fatigue: item?.fatigue ?? "-",
              selectedFatigueSurveyOptions: {
                label: item?.fatigue,
                value: item?.fatigue,
              },
              isOtherPlayer: item?.isOtherPlayer,
            };
          }
        ),
      });

      setSelectedRowItemsManager({
        selectedRowItems: result?.data?.activity?.activity_managment.map(
          (item) => ({
            key: item?.managment?.id,
            name: item?.managment?.user_name,
            tckn: item?.managment?.tckn,
          })
        ),
      });

      fetchTeamPlayers(
        result?.data?.activity?.team_category.id,
        result?.data?.activity
      );
    });
  };

  const mergeSelectionsPlayers = rowSelectionList?.selectedRowItems
    ? selectedRowItemsPlayer?.selectedRowItems.concat(
        rowSelectionList?.selectedRowItems
      )
    : selectedRowItemsPlayer?.selectedRowItems;

  const mergeSelectionsManagment = rowSelectionListManager?.selectedRowItems
    ? selectedRowItemsManager?.selectedRowItems.concat(
        rowSelectionListManager?.selectedRowItems
      )
    : selectedRowItemsManager?.selectedRowItems;

  const fetchGetActity = async (id) => {
    try {
      const result = getActivity(id);
      return result;
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsDataChange(false);
    }
  };

  const fetchGetAllUsers = async () => {
    try {
      const result = await getAllUser();
      console.log(result.data.users, "getAllUsers");
      return result;
    } catch (error) {
      console.log(error, "error");
    }
  };

  const fetchGetAllCategory = async () => {
    try {
      const result = await listTeams();
      console.log(result?.data?.teams, "team");
      return result?.data?.teams;
    } catch (error) {
      console.log(error, "err");
    }
  };

  const fetchTeamPlayers = async (teamId, resultData = false) => {
    setSelectTeamPlayerIsLoading(true);
    try {
      const result = await getPlayerUsers(teamId);
      const teamPlayers = result.data.users?.map((item) => item?.id);
      setTeamPlayers(teamPlayers);

      return result.response;
    } catch (error) {
      console.log(error, "error");
    } finally {
      setSelectTeamPlayerIsLoading(false);
    }
  };
  const fetchAllPlayers = async (resultData) => {
    try {
      const result = await getPlayerUsers();
      const teamPlayersFormat = result.data.users?.map((item) => ({
        key: item?.id,
        name: `${item?.name} ${item?.surname}`,
        tckn: item?.tckn,
        disabled: !!resultData?.activity_player.find(
          (itemFilter) => itemFilter?.player?.id == item?.id
        ),
      }));

      // console.log("fasfasfasfaf", result);
      setSelectAllTeamPlayers(teamPlayersFormat);
      return result.response;
    } catch (error) {
      console.log(error, "error");
    } finally {
      setSelectTeamPlayerIsLoading(false);
    }
  };

  const onChangeTeamSelect = (e1) => {
    fetchTeamPlayers(e1.value, resultData);
  };

  const fetchUpdateActivity = async (
    id,
    // title,
    activity_type,
    start_date,
    status,
    start_time,
    // ozel alanlar
    description,
    activity_group,
    managers,
    players,
    drills,
    activities,
    // color,
    team_category,
    activity_category,
    is_approved,
    football_ground,
    football_ground_id,
    other_players
  ) => {
    try {
      const result = await updateActivity(
        id,
        activity_type,
        start_date,
        status,
        start_time,
        // ozel alanlar
        description,
        activity_group,
        managers,
        players,
        drills,
        activities,
        team_category,
        activity_category,
        is_approved,
        football_ground,
        football_ground_id,
        other_players
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Düzenleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });

        setTimeout(function () {
          window.location.reload();
        }, 900);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Düzenleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsDataChange(true);
    }
  };

  const fetchGetActivitiesCategoryOptions = async () => {
    setActivitiesCategoryOptions([]);
    try {
      const result = await getActivitiesCategories();

      result?.data?.categories.forEach((item) => {
        if (!item.up_category) {
          setActivitiesCategoryOptions((prevState) => [
            ...prevState,
            {
              label: item?.title,
              value: item?.id,
              type: item?.type,
            },
          ]);
        }
      });
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setIsDataChange(false);
    }
  };
  const handleActivityCategoryField = async (value, type) => {
    setTreeLoading(true);
    try {
      const result = await getActivitiesCategories(value);
      setTreeData([]);
      if (typeof type !== "undefined") {
        setSelectedActivityCategory(type);
      }

      result?.data?.categories.forEach((item) => {
        const treeItem = getTreeItem(
          item.id,
          item.title,
          item.id,
          <CarryOutOutlined />
        );
        const children = getChildren(item);
        treeItem.children = children;
        handleDataPush(treeItem);
      });
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setTreeLoading(false);
    }
  };

  function getChildren(category) {
    if (!category.sub_category || category.sub_category.length === 0) {
      return [];
    }
    return category.sub_category.map((item) => {
      const treeItem = getTreeItem(
        item.id,
        item.title,
        item.id,
        <CarryOutOutlined />
      );
      const children = getChildren(item);
      treeItem.children = children;
      return treeItem;
    });
  }

  function getTreeItem(id, title, key, icon, children = []) {
    return {
      id,
      title,
      key,
      icon,
      children,
    };
  }

  const handleDataPush = (item) => {
    setTreeData((prev) => [...prev, item]);
  };

  useEffect(() => {
    if (isDataChange) {
      fetchGetAllCategory().then((teams) =>
        setTeamCategoryOptions([
          ...teams.map((item) => ({
            label: item?.team_title,
            value: item?.id,
            players: item?.players,
          })),
        ])
      );
      fetchGetAllDrills().then((result) =>
        setDrillsOptions(
          result?.data?.drills.map((item) => ({
            label: item?.title,
            value: item?.id,
          }))
        )
      );
    }
  }, [isDataChange]);

  // END::SELECTED PLAYER
  useEffect(() => {
    if (!resultData) {
      loadActivityData();
    } else {
      fetchGetAllUsers().then((result) => {
        const datatableDataPlayer = result?.data?.users.map((item, index) => {
          return {
            key: item?.id,
            name: item?.playerName,
            tckn: item?.tckn,
            disabled: !!resultData?.activity_player.find(
              (itemFilter) => itemFilter?.player?.id == item?.id
            ),
          };
        });

        const datatableDataManager = result?.data?.users.map((item, index) => {
          return {
            key: item?.id,
            name: item?.user_name,
            tckn: item?.tckn,
            //  total_run: item?.surname,
            disabled: !!resultData?.activity_managment.find(
              (itemFilter) => itemFilter?.managment?.id == item?.id
            ),
          };
        });
        console.log("datatatabalelelasdads", datatableDataPlayer);
        setLoading(false);
        setUserDataTableData(datatableDataPlayer);
        setManagerDataTableData(datatableDataManager);
      });
    }
  }, [isDataChange, resultData]);
  const onSelect = async (selectedKeys, info) => {
    setFormLoading(true);
    // console.log("selected", selectedKeys, info);
    try {
      const result = await getActivitiesCategory(selectedKeys); //tek kategori çağrıma
      setSelectedCategory(result?.data?.category);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setFormLoading(false);
    }
  };

  //eklenen etkinlik silme
  const handleRemoveActivity = (index, activities) => {
    const newActivities = [...activityMovements];
    newActivities.splice(index, 1);

    setNewActivity((prevValues) => ({
      ...prevValues,
      activities: newActivities,
    }));
    // console.log(newActivities, "newActivities");
    setActivityMovements(newActivities);
  };
  //eklenen etkinlik ekleme
  const handleAddActivity = async (
    activities,
    football_ground,
    activities_description,
    activities_time,
    activities_drill,
    setFieldValue
  ) => {
    setFieldValue("activities", [
      ...activities,
      {
        title: selectedCategory?.title,
        time: activities_time,
        movement_field: football_ground,
        description: activities_description,
        drill: selectedDrill,
        fkActivityCategory: selectedCategory?.id,
      },
    ]);

    setActivityMovements((prevActivityMovements) => {
      const newActivityMovement = {
        newActivityMovement: true,
        title: selectedCategory?.title,
        time: parseInt(activities_time, 10),
        movement_field: football_ground,
        description: activities_description,
        drill: selectedDrill,
        activity_category: {
          id: selectedCategory?.id,
          isAddMainTime: selectedCategory?.isAddMainTime,
        },
      };

      return [...prevActivityMovements, newActivityMovement];
    });
  };

  const handlePlayerSearchOnChange = (e) => {
    const searchValue = e.target.value;
    setPlayerSearchInputValue(searchValue);

    // console.log(selectAllTeamPlayers, "userDataTableDatauserDataTableData");
    const filteredData = selectAllTeamPlayers?.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchValue.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    // console.log(filteredData, "filtered data");

    setFilteredPlayerDataTableData(filteredData ?? []);
  };

  useEffect(() => {
    if (activityMovements) {
      // const totalTime = activityMovements.reduce((acc, time) => acc + time.time, 0);

      const totalTime = activityMovements
        .filter(
          (filteredItem) => filteredItem?.activity_category?.isAddMainTime == 1
        )
        .reduce((acc, time) => acc + time.time, 0);

      setTotalActivityTime(totalTime);
    }
  }, [activityMovements]);

  function AddedInnerActivities({ activities }) {
    return (
      <div className="p-2 border rounded-xl">
        <p className="text-xl text-primary">Eklenen Aktiviteler</p>
        <div className="flex flex-col w-full gap-4">
          {activities?.map((item, index) => {
            const movementFieldLabel =
              item?.movement_field?.title || item?.movement_field?.label;
            return (
              <>
                <div className="p-2 border rounded-lg">
                  <p>
                    <span className="font-bold">Başlık:</span> {item?.title}
                  </p>
                  <p>
                    <span className="font-bold">Süre:</span> {item?.time} dakika
                  </p>
                  <p>
                    <span className="font-bold">Yer:</span> {movementFieldLabel}
                  </p>
                  <p>
                    <span className="font-bold">Açıklama:</span>{" "}
                    {item?.description}
                  </p>
                  <div className="flex flex-col pt-2 mt-2 border-t">
                    <p className="font-bold">
                      Drill Başlığı: {item?.drill?.title ?? <i>Bulunamadı!</i>}
                    </p>
                    {item?.drill?.image ? (
                      <div className="">
                        <i className="">Drill Görseli:</i>{" "}
                        <img
                          className="w-full"
                          src={baseImageURL + item.drill?.image}
                          // src={`${baseImageURL}${item.drill?.image}`}
                        />
                      </div>
                    ) : (
                      <i className="w-full p-6 text-center border border-dashed rounded-xl">
                        Görsel Bulunamadı!
                      </i>
                    )}
                  </div>
                  <div className="flex justify-end w-full mt-4">
                    <ButtonComponent
                      customStyle={"h-[24px] text-xs"}
                      outline
                      text={"Çıkar"}
                      onClick={() => {
                        handleRemoveActivity(index, activities);
                      }}
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  }

  const filteredStatus = [
    { label: "Gerçekleşti", value: "gerceklesti" },
    { label: "Planlandı", value: "planlandi" },
    { label: "İptal Edildi", value: "iptal_edildi" },
  ].filter((item) => item?.value == resultData?.status);

  const activityColors = [
    { label: "Kırmızı", value: "red" },
    { label: "Mavi", value: "blue" },
    { label: "Yeşil", value: "green" },
  ].filter((item) => item?.value == resultData?.color);

  // console.log(resultData.activity_player, "resultDataasdasdasdadBAKALELEALSDLASLD");

  // console.log(rowSelectionList, "rowSelectionList");

  // console.log(treeData, "treeData");

  const { DirectoryTree } = Tree;

  useEffect(() => {
    if (resultDataIsLoaded) {
    }
  }, [resultDataIsLoaded]);

  useEffect(() => {
    fetchGetActivitiesCategoryOptions();
  }, []);

  //25-10-23
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [selectAllTeamPlayers, setSelectAllTeamPlayers] = useState([]);
  const [activitiesCategoryOptions, setActivitiesCategoryOptions] = useState(
    []
  );
  const [selectedActivityCategory, setSelectedActivityCategory] =
    useState(false);
  const [treeLoading, setTreeLoading] = useState(false);
  const [footballGroundOptions, setFootballGroundOptions] = useState([]);
  const [managerSearchInputValue, setManagerSearchInputValue] = useState(false);
  const [filteredManagerDataTableData, setFilteredManagerDataTableData] =
    useState([]);

  const [denemeMert, setDenemeMert] = useState([]);

  const handleSelectedFact = async (value) => {
    setDenemeMert([]);
    try {
      const result = await getFacilitiess();
      let foundData = result.facilities.filter(
        (item) => item.id == value.value
      );
      let b = foundData.flatMap((item) =>
        item.grounds.map((item2) => ({ label: item2.title, value: item2.id }))
      );
      setDenemeMert(b);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFootballGround = async () => {
    try {
      try {
        const result = await getFacilitiess(true);
        setFootballGroundOptions([]);
        result?.facilities.map((item) => {
          setFootballGroundOptions((prevState) => [
            ...prevState,
            {
              label: item.tittle,
              value: item.id,
            },
          ]);
        });
      } catch (error) {
        // setLoading(false);
        console.error(error);
      }
      // const result = await getFootballGround(true);
      // setFootballGroundOptions([]);

      // result?.data?.footballGrounds.map((item) => {
      //   setFootballGroundOptions((prevState) => [
      //     ...prevState,
      //     {
      //       label: item.title,
      //       value: item.id
      //     }
      //   ]);
      // });
    } catch (error) {
      // setLoading(false);
    }
  };

  const handleManagerSearchOnChange = (e) => {
    const searchValue = e.target.value;
    setManagerSearchInputValue(searchValue);

    // console.log(managerDataTableData, "userDataTableDatauserDataTableData")
    const filteredData = managerDataTableData?.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchValue.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });

    setFilteredManagerDataTableData(filteredData ?? []);
  };

  useEffect(() => {
    fetchAllPlayers(resultData);
  }, [isDataChange, resultData]);

  useEffect(() => {
    handleActivityCategoryField(
      resultData?.activity_category?.id,
      resultData?.activity_category?.type
    );
  }, [resultData]);

  useEffect(() => {
    fetchFootballGround();
  }, []);

  useEffect(() => {
    loadDefaultOptionOtherPlayers();
  }, [mergeSelectionsPlayers]);

  //aktiviteler
  const [addedActivityModalIsOpen, setAddedActivityModalIsOpen] =
    useState(false);
  const [addedActivityData, setAddedActivityData] = useState(false);
  const [defaultOptionOtherPlayers, setDefaultOptionOtherPlayers] = useState(
    []
  );

  const fetchSearchUser = async (inputValue = "") => {
    try {
      const result = await searchUser(inputValue, "footballer");
      return result.data;
    } catch (error) {
      console.log("Bir sorun oluştu!", error);
    }
  };

  //start::other_players async select
  const loadOptionsOtherPlayers = async (inputValue, callback) => {
    try {
      const result = await fetchSearchUser(inputValue);

      const mergeSelectionsPlayerData = mergeSelectionsPlayers.map(
        (data) => data.key
      );
      const filtered = result.filter(
        (item) => !mergeSelectionsPlayerData.includes(item.value)
      );
      callback(filtered);
    } catch (error) {
      console.log("Bir sorun oluştu!", error);
    }
  };
  //end::other_players async select

  const loadDefaultOptionOtherPlayers = async () => {
    const result = await fetchSearchUser();
    const _mergeSelectionsPlayerData = mergeSelectionsPlayers?.map(
      (data) => data.key
    );

    const _filtered = result?.filter(
      (item) => !_mergeSelectionsPlayerData?.includes(item.value)
    );
    setDefaultOptionOtherPlayers(_filtered);
    console.log("defaultOpt", defaultOptionOtherPlayers);

    const selectedGuestPlayersData = result?.filter((item) =>
      _mergeSelectionsPlayerData?.includes(item.value)
    );
    setSelectedGuestPlayers(selectedGuestPlayersData);
    // console.log("BAKELE", result);
  };

  return (
    <div className="p-4 bg-white rounded-xl">
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      {!loading && (
        <>
          <PageTitle
            bg
            title={
              resultData?.activities[0] ? resultData?.activities[0].title : "-"
            }
          />
          <Formik
            initialValues={{
              start_date: resultData?.start_date ?? "",
              start_time: resultData?.start_time ?? "",
              status: filteredStatus ?? "",
              is_approved: resultData?.is_approved ?? "",
              activity_type: resultData?.activity_type ?? "",
              drills: "",
              description: resultData?.notes ?? "",
              //gönderilmeyen
              activity_group: "a", //eski
              activities: resultData?.activities, // obje dönüştür
              activities_title: "",
              activities_time: "",
              activities_category_field:
                {
                  label: resultData?.activity_category?.title,
                  value: resultData?.activity_category?.id,
                } ?? "",
              football_ground_id:
                {
                  label: resultData?.football_ground?.title,
                  value: resultData?.football_ground?.id,
                } ?? "",
              football_ground:
                {
                  label: resultData?.facility?.tittle,
                  value: resultData?.facility?.id,
                } ?? "",
              activities_description: "",
              activities_drill: selectedDrill ?? "",
              // gönderilmeyen
              managers: selectedRowItemsManager?.selectedRowItems ?? "",
              players: selectedRowItemsPlayer?.selectedRowItems ?? "",
              team_category:
                {
                  label: resultData?.team_category?.team_title,
                  value: resultData?.team_category?.id,
                } ?? "",
              other_players:
                selectedRowItemsPlayer?.selectedRowItems
                  .filter((player) => player.isOtherPlayer)
                  .map((item) => ({ label: item.name, value: item.key })) ?? "",
            }}
            onSubmit={async (values) => {
              const form = {
                activity_type: values?.activity_type,
                description: values?.description,
                status: values?.status?.value,
                start_date: values?.start_date,
                start_time: values?.start_time,
                color: 'red',
                activity_category_id: values?.activities_category_field.value,
                players: mergeSelectionsPlayers?.map(
                  (item) => item.key ?? []
                ),
                managers: mergeSelectionsManagment?.map(
                  (item) => item.key ?? []
                ),
                other_players: values?.other_players
                ? values.other_players.map((player) => player.value)
                : [],
                activities: activityMovements.map((item) => ({
                  ...item,
                  movement_field: item?.movement_field?.value,
                  drill: item?.drill?.id,
                })),
                team_category: values?.team_category?.value,
                drills: values?.activities?.map((item) => item?.drill),
                players: values.players =
                selectedActivityCategory === "team"
                  ? teamPlayers
                  : values.players,
                  football_ground: values.football_ground?.value,
                  football_ground_id: values.football_ground_id.value
              }
              await fetchUpdateActivity(
                resultData?.id,
                form
              );
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              resetForm,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-2 gap-4 px-4">
                    {/* inputlar */}
                    <>
                      {/*<DynamicInput*/}
                      {/*    labelTitle={"Başlık"}*/}
                      {/*    containerStyle={"w-full"}*/}
                      {/*    placeholder={"Başlık"}*/}
                      {/*    name={"title"}*/}
                      {/*    value={values}*/}
                      {/*    onChange={(e1, e2) => {*/}
                      {/*        handleCustomChange(*/}
                      {/*            e1,*/}
                      {/*            e2,*/}
                      {/*            "title",*/}
                      {/*            setFieldValue,*/}
                      {/*            "input"*/}
                      {/*        );*/}
                      {/*    }}*/}
                      {/*    error={errors}*/}
                      {/*    isTouched={touched}*/}
                      {/*    onBlur={handleBlur}*/}
                      {/*    component_type={"input"}*/}
                      {/*/>*/}
                      <button onClick={() => handleRemoveActivityData()} className="bg-red-500 text-white py-1.5 px-3 rounded w-36 flex items-center border border-red-500 justify-center hover:bg-white hover:border hover:border-red-500 hover:text-red-500">Aktiviteyi Sil</button>
                      <div></div>
                      <DynamicInput
                        labelTitle={"Aktivite Kategorisi Seçiniz"}
                        containerStyle={"w-full"}
                        placeholder={"Aktivite Kategorisi Seçiniz"}
                        name={"activities_category_field"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "activities_category_field",
                            setFieldValue,
                            "select"
                          );
                          handleActivityCategoryField(e1.value, e1.type);
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={activitiesCategoryOptions}
                        required={true}
                        // customOptions={activityMainCategoryOptions}
                        // disabled={treeData.length > 0 ? false : true}
                      />
                      <DynamicInput
                        labelTitle={"Onaylandı Mı?"}
                        containerStyle={"w-full"}
                        placeholder={"Onaylandı Mı?"}
                        name={"is_approved"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "is_approved",
                            setFieldValue,
                            "checkbox"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"checkbox"}
                      />
                      
                      <div className={"grid grid-cols-2 gap-4"}>
                        <DynamicInput
                          labelTitle={"Başlangıç Tarihi"}
                          containerStyle={"w-full"}
                          placeholder={""}
                          name={"start_date"}
                          value={values}
                          onChange={(e1, e2) => {
                            handleCustomChange(
                              e1,
                              e2,
                              "start_date",
                              setFieldValue,
                              "datePicker"
                            );
                          }}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          component_type={"datePicker"}
                          required={true}
                        />
                        <DynamicInput
                          labelTitle={"Başlangıç Saati"}
                          containerStyle={"w-full"}
                          placeholder={""}
                          name={"start_time"}
                          value={values}
                          onChange={(e1, e2) => {
                            handleCustomChange(
                              e1,
                              e2,
                              "start_time",
                              setFieldValue,
                              "timePicker"
                            );
                          }}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          component_type={"timePicker"}
                          required={true}
                        />
                      </div>
                      <DynamicInput
                        labelTitle={"Tesis Seçimi"}
                        containerStyle={"w-full"}
                        placeholder={"Tesis Seçiniz"}
                        name={"football_ground"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "football_ground",
                            setFieldValue,
                            "select"
                          );
                          handleSelectedFact(e1);
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={footballGroundOptions ?? []}
                        required={true}
                      />
                      <DynamicInput
                        labelTitle={"Faaliyet Yeri Seçimi"}
                        containerStyle={"w-full"}
                        placeholder={"Faaliyet Yeri Seçiniz"}
                        name={"football_ground_id"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "football_ground_id",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={denemeMert ?? []}
                        required={true}
                      />
                      <DynamicInput
                        labelTitle={"Faaliyet Açıklaması/Notları"}
                        containerStyle={"w-full"}
                        placeholder={"Not giriniz"}
                        name={"description"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "description",
                            setFieldValue,
                            "textarea"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"textarea"}
                      />
                      <DynamicInput
                        labelTitle={"Durum"}
                        containerStyle={"w-full"}
                        placeholder={"Durum"}
                        name={"status"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "status",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={[
                          { label: "Gerçekleşti", value: "gerceklesti" },
                          { label: "Planlandı", value: "planlandi" },
                          { label: "İptal Edildi", value: "iptal_edildi" },
                        ]}
                      />
                    </>
                    {/* inputlar */}
                    {/* players */}
                    {selectedActivityCategory === "team" && (
                      <div className={"col-span-full"}>
                        <DynamicInput
                          labelTitle={"Takım"}
                          containerStyle={"w-full"}
                          placeholder={"Takım Seçiniz"}
                          name={"team_category"}
                          value={values}
                          onChange={(e1, e2) => {
                            onChangeTeamSelect(e1);
                            handleCustomChange(
                              e1,
                              e2,
                              "team_category",
                              setFieldValue,
                              "select"
                            );
                          }}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          component_type={"select"}
                          customOptions={
                            teamCategoryOptions ?? userDataTableData ?? []
                          }
                        />
                        <DynamicInput
                          labelTitle={"Başka Takımdan Oyuncu Seçiniz"}
                          containerStyle={"w-full"}
                          placeholder={
                            "Antrenmanınıza Altınordu'nun Başka Takımından Katılan Oyuncuları Seçiniz..."
                          }
                          name={"other_players"}
                          value={values}
                          onChange={(e1, e2) => {
                            handleCustomChange(
                              e1,
                              e2,
                              "other_players",
                              setFieldValue,
                              "select"
                            );
                          }}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          component_type={"asyncSelect"}
                          isMulti={true}
                          isClearable={true}
                          customOptions={loadOptionsOtherPlayers}
                          defaultOption={defaultOptionOtherPlayers}
                        />
                        <div className={"mt-8"}>
                          <div>
                            <div className="flex justify-between">
                              <div>
                                <p className="pb-2 text-lg">
                                  Seçilen Oyuncular
                                </p>
                              </div>
                              <div className="flex items-center">
                                {/* <input type="file" placeholder="Json Ekle" /> */}
                                <div className="max-w-md mx-auto my-2 p-2 mr-2.5 bg-gray-100 rounded-lg shadow-md">
                                  <label className="block mb-2 font-medium text-gray-600">
                                    Dosya Seç: (dosya adı json olmalı)
                                  </label>
                                  <label className="flex items-center justify-center w-full p-4 bg-white border border-gray-300 rounded-md cursor-pointer">
                                    <svg
                                      className="w-6 h-6 mr-2 text-blue-500"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 4v16m8-8H4"
                                      ></path>
                                    </svg>
                                    Dosya seçmek için tıklayın veya sürükleyin.
                                    <input
                                      type="file"
                                      className="hidden"
                                      onChange={handleFileChange}
                                      id="fileInput"
                                    />
                                  </label>
                                  {file && (
                                    <span className="block mt-2 text-gray-700">
                                      Seçilen dosya: {file.name}
                                    </span>
                                  )}
                                  <button
                                    type="button"
                                    className="mt-4 bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-700"
                                    onClick={handleFileUpload}
                                  >
                                    Yükle
                                  </button>
                                </div>
                                {/* burarara */}
                                <ButtonComponent
                                  customStyle={"h-[30px]"}
                                  outline
                                  text={"Yoklama Al"}
                                  onClick={() =>
                                    setIsSelectedPlayerModalOpen(true)
                                  }
                                />
                              </div>
                            </div>
                            <DataTable
                              loading={resultData ? false : true}
                              columns={[
                                {
                                  title: "Ad Soyad",
                                  dataIndex: "name",
                                  render: (text, record) => (
                                    <>
                                      {text}
                                      {record.isOtherPlayer ? (
                                        <span
                                          text="Misafir"
                                          className="ml-[7px] inline-flex items-center rounded-xl bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
                                        >
                                          Misafir
                                        </span>
                                      ) : null}
                                    </>
                                  ),
                                },
                                { title: "Pozisyon", dataIndex: "position" },
                                // { title: "TC", dataIndex: "tckn" },
                                { title: "Durum", dataIndex: "status" },
                                { title: "Puan", dataIndex: "average" },
                                {
                                  title: "Yorgunluk",
                                  dataIndex: "fatigue",
                                },
                                {
                                  title: "T. Koşu Mesafesi (m)",
                                  dataIndex: "total_run",
                                },
                                {
                                  title: "Sprint Mesafesi (m)",
                                  dataIndex: "sprint_distance",
                                },
                                {
                                  title: "Sprint Sayısı (ad)",
                                  dataIndex: "sprint_number",
                                },
                                {
                                  title: "Yüksek Şiddetli Koşu (m)",
                                  dataIndex: "high_intensity_run",
                                },
                                {
                                  title: "T. Hareketlilik (ad)",
                                  dataIndex: "total_movement",
                                },
                                {
                                  title: "Yüksek Şiddetli Hareketlilik (ad)",
                                  dataIndex: "high_intensity_movement",
                                },
                                {
                                  title: "IMA TH/YŞH ( % )",
                                  dataIndex: "total_movement_high_intensity",
                                },
                              ]}
                              data={mergeSelectionsPlayers ?? []}
                            />
                          </div>
                          <CustomModal
                            width={1400}
                            isOpen={isSelectedPlayerModalOpen}
                            setOpen={setIsSelectedPlayerModalOpen}
                            footer={null}
                          >
                            <CustomModalHeader title={"Yoklama Al"} />
                            <TakeAttendanceModal
                              isOpen={setIsSelectedPlayerModalOpen}
                              selectedPlayers={mergeSelectionsPlayers}
                              activityId={activityID}
                              setIsDataChange={setIsDataChange}
                              setResultData={setResultData}
                            />
                          </CustomModal>
                        </div>
                      </div>
                    )}
                    {selectedActivityCategory === "group-player" && (
                      <>
                        <div className="">
                          <div className={"w-full flex justify-between pb-2"}>
                            <div>
                              <p className="pb-2 text-lg">Oyunucu Seç</p>
                            </div>
                            <div className={"w-50"}>
                              <Input
                                type="text"
                                placeholder="Oyuncu ara.."
                                name="playerSearchInput"
                                onChange={(e) => handlePlayerSearchOnChange(e)}
                              />
                            </div>
                          </div>
                          <DataTable
                            selection={{
                              rowSelectionList,
                              setRowSelectionList,
                            }}
                            loading={selectTeamPlayerIsLoading}
                            columns={[
                              { title: "Ad Soyad", dataIndex: "name" },
                              { title: "TC", dataIndex: "tckn" },
                            ]}
                            data={
                              playerSearchInputValue
                                ? filteredPlayerDataTableData
                                : selectAllTeamPlayers
                            }
                            // data={selectAllTeamPlayers}
                            checkboxDisable={true}
                          />
                        </div>
                        <div className="">
                          <div className="flex justify-between">
                            <div>
                              <p className="pb-2 text-lg">Seçilen Oyuncular</p>
                            </div>
                            <div>
                              <ButtonComponent
                                customStyle={"h-[30px]"}
                                outline
                                text={"Yoklama Al"}
                                onClick={() =>
                                  setIsSelectedPlayerModalOpen(true)
                                }
                              />
                            </div>
                          </div>
                          <DataTable
                            loading={resultData ? false : true}
                            columns={[
                              { title: "Ad Soyad", dataIndex: "name" },
                              { title: "TC", dataIndex: "tckn" },
                              { title: "Durum", dataIndex: "status" },
                              { title: "Puan", dataIndex: "average" },
                              {
                                title: "Yorgunluk Anketi",
                                dataIndex: "fatigue",
                              },
                              {
                                title: "Toplam Koşu Mesafesi",
                                dataIndex: "lorem-1",
                              },
                              {
                                title: "Sprint Mesafesi",
                                dataIndex: "lorem-2",
                              },
                              { title: "Sprint Sayısı", dataIndex: "lorem-3" },
                              {
                                title: "Yüksek Şiddetli Koşu",
                                dataIndex: "lorem-4",
                              },
                            ]}
                            data={mergeSelectionsPlayers ?? []}
                          />
                        </div>
                        <CustomModal
                          width={1200}
                          isOpen={isSelectedPlayerModalOpen}
                          setOpen={setIsSelectedPlayerModalOpen}
                          footer={null}
                        >
                          <CustomModalHeader title={"Yoklama Al"} />

                          <TakeAttendanceModal
                            isOpen={setIsSelectedPlayerModalOpen}
                            selectedPlayers={mergeSelectionsPlayers}
                            activityId={activityID}
                            setIsDataChange={setIsDataChange}
                            setResultData={setResultData}
                          />
                        </CustomModal>
                      </>
                    )}
                    {/* players */}
                    {/* manager */}
                    <>
                      <div className="hidden">
                        <div className={"w-full flex justify-between pb-2"}>
                          <div>
                            <p className="pb-2 text-lg">Personel Seç</p>
                          </div>
                          <div className={"w-50"}>
                            <Input
                              type="text"
                              placeholder="Personel ara.."
                              name="playerSearchInput"
                              onChange={(e) => handleManagerSearchOnChange(e)}
                            />
                          </div>
                        </div>
                        <DataTable
                          selection={{
                            rowSelectionList: rowSelectionListManager,
                            setRowSelectionList: setRowSelectionListManager,
                          }}
                          loading={loading}
                          columns={[
                            { title: "Ad Soyad", dataIndex: "name" },
                            { title: "TC", dataIndex: "tckn" },
                          ]}
                          data={
                            managerSearchInputValue
                              ? filteredManagerDataTableData
                              : managerDataTableData
                          }
                          checkboxDisable={true}
                        />
                      </div>

                      <div className="hidden">
                        <p className="pb-2 text-lg">Seçilen Personeller</p>
                        <DataTable
                          loading={loading}
                          columns={[
                            { title: "Ad Soyad", dataIndex: "name" },
                            { title: "TC", dataIndex: "tckn" },
                          ]}
                          data={mergeSelectionsManagment ?? []}
                        />
                      </div>
                    </>
                    {/* manager */}
                    {/* aktiviteler */}
                    <div className="col-span-full flex justify-end">
                      <div className="flex flex-col">
                        <p className="flex items-start justify-start py-1 text-lg">
                          Toplam Aktivite Süresi
                        </p>
                        <div className="border rounded-lg min-h-[56px] flex items-center ps-5">
                          {totalActivityTime} dakika
                        </div>
                      </div>
                    </div>
                    <div className="col-span-full">
                      <div className="grid w-full grid-cols-3 gap-2 p-2 border divide-x rounded-xl">
                        <div className="w-full">
                          {!treeLoading ? (
                            <DirectoryTree
                              className="relative z-0"
                              showLine={false}
                              showIcon={false}
                              defaultExpandedKeys={0}
                              onSelect={onSelect}
                              treeData={treeData}
                            />
                          ) : (
                            <p>Yükleniyor...</p>
                          )}

                          {!treeLoading && treeData.length <= 0 && (
                            <p className={"text-gray-400"}>
                              Seçilen kategoriye alan eklenmemiş.
                            </p>
                          )}
                        </div>
                        <div className="w-full pl-2">
                          <p className="text-xl text-primary">Aktivite Ekle</p>
                          <div className="w-full ">
                            <DynamicInput
                              labelTitle={"Aktivite Süresi"}
                              containerStyle={"w-full"}
                              placeholder={"Aktivite Süresi"}
                              name={"activities_time"}
                              value={values}
                              onChange={(e1, e2) => {
                                handleCustomChange(
                                  e1,
                                  e2,
                                  "activities_time",
                                  setFieldValue,
                                  "input"
                                );
                              }}
                              error={errors}
                              isTouched={touched}
                              onBlur={handleBlur}
                              type={"number"}
                            />
                            <DynamicInput
                              labelTitle={"Aktivite Açıklaması"}
                              containerStyle={"w-full"}
                              placeholder={"Aktivite Açıklaması"}
                              name={"activities_description"}
                              value={values}
                              onChange={(e1, e2) => {
                                handleCustomChange(
                                  e1,
                                  e2,
                                  "activities_description",
                                  setFieldValue,
                                  "textarea"
                                );
                              }}
                              error={errors}
                              isTouched={touched}
                              onBlur={handleBlur}
                              component_type={"textarea"}
                            />
                            <ButtonComponent
                              customStyle={"w-full mb-1"}
                              onClick={() => setIsDrillModalOpen(true)}
                              outline
                              text={"Drill Seç"}
                            />
                            <CustomModal
                              width={677}
                              isOpen={isDrillModalOpen}
                              setOpen={setIsDrillModalOpen}
                              footer={null}
                            >
                              <CustomModalHeader
                                title={"Drill Seçiniz"}
                                description={
                                  "Aşağıdaki drill ağacından drill seçiniz."
                                }
                              />
                              <div className="mt-8 border-t">
                                <div className="flex flex-col py-4 text-lg border-b-4">
                                  <p className="text-xl font-semibold">
                                    Seçilen Drill
                                  </p>
                                  <div className="flex flex-col gap-2 pl-4 mt-2">
                                    <p>
                                      Başlık:{" "}
                                      {selectedDrill?.title ?? (
                                        <i>Bulunamadı!</i>
                                      )}
                                    </p>
                                    <p>
                                      Açıklaması:{" "}
                                      {selectedDrill?.description ?? (
                                        <i>Bulunamadı!</i>
                                      )}
                                    </p>
                                    {selectedDrill?.image ? (
                                      <img
                                        className="max-w-[250px] w-full"
                                        src={
                                          baseImageURL + selectedDrill?.image
                                        }
                                      />
                                    ) : (
                                      <i className="border max-w-[250px] w-full p-6 border-dashed rounded-xl text-center">
                                        Görsel Bulunamadı!
                                      </i>
                                    )}
                                  </div>
                                </div>

                                <DirectoryTree
                                  className="relative z-0"
                                  showLine={false}
                                  showIcon={false}
                                  defaultExpandedKeys={0}
                                  onSelect={onSelectDrill}
                                  treeData={treeDataDrill}
                                />

                                <div className="flex gap-4 mt-8">
                                  <ButtonComponent
                                    customStyle={"w-full border-0"}
                                    onClick={() => {
                                      setSelectedDrill({});
                                      setIsDrillModalOpen(false);
                                    }}
                                    outline
                                    text={"İptal et"}
                                  />
                                  <ButtonComponent
                                    customStyle={"w-full"}
                                    onClick={() => setIsDrillModalOpen(false)}
                                    fill
                                    text={"Kaydet"}
                                  />
                                </div>
                              </div>
                            </CustomModal>
                            <div className="flex justify-end">
                              <ButtonComponent
                                onClick={() => {
                                  if (!selectedCategory) {
                                    Swal.fire({
                                      position: "center",
                                      icon: "warning",
                                      title:
                                        "Sol taraftaki kategorilerden birini seçmelisiniz.",
                                      showConfirmButton: false,
                                      timer: 1200,
                                    });
                                  } else {
                                    setSelectedDrill({});
                                    handleAddActivity(
                                      values?.activities,
                                      values?.football_ground,
                                      values?.activities_description,
                                      values?.activities_time,
                                      values?.activities_drill,
                                      setFieldValue
                                    );
                                    resetForm({
                                      activities_time: "",
                                      activities_description: "",
                                    });
                                  }
                                }}
                                fill
                                text={"Aktivite Ekle"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="p-2 border rounded-xl">
                          <p className="text-xl text-primary">
                            Eklenen Aktiviteler
                          </p>
                          <div className="flex flex-col w-full gap-4">
                            {activityMovements.map((item, index) => {
                              const movementFieldLabel =
                                item?.movement_field?.title ||
                                item?.movement_field?.label;
                              // console.log(item, "ümeerr");
                              return (
                                <>
                                  <div className="p-2 border rounded-lg">
                                    <div className={"flex justify-between"}>
                                      <div>
                                        <p>
                                          <span className="font-bold">
                                            Başlık:
                                          </span>{" "}
                                          {item?.title}
                                        </p>
                                      </div>
                                      <div>
                                        {item?.activity_category
                                          ?.isAddMainTime == 0 ? (
                                          <p
                                            className={
                                              "font-light text-xs text-orange-700"
                                            }
                                          >
                                            Toplam Süreye Dahil Değil
                                          </p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                    <p>
                                      <span className="font-bold">Süre:</span>{" "}
                                      {item?.time} dakika
                                    </p>
                                    {/*<p>*/}
                                    {/*    <span className="font-bold">Yer:</span>{" "}*/}
                                    {/*    {movementFieldLabel}*/}
                                    {/*</p>*/}
                                    <p className={"flex gap-1"}>
                                      <span className="font-bold flex items-center gap-1">
                                        {!item.newActivityMovement && (
                                          <a
                                            href="javascript:void(0);"
                                            onClick={() => {
                                              setAddedActivityData(item);
                                              setAddedActivityModalIsOpen(true);
                                            }}
                                          >
                                            <FiEdit
                                              className={"hover:text-primary"}
                                            />
                                          </a>
                                        )}{" "}
                                        Açıklama:
                                      </span>{" "}
                                      {item?.description}
                                    </p>
                                    <div className="flex flex-col pt-2 mt-2 border-t">
                                      <p className="font-bold">
                                        Drill Başlığı:{" "}
                                        {item?.drill?.title ?? (
                                          <i>Bulunamadı!</i>
                                        )}
                                      </p>
                                      {item?.drill?.image ? (
                                        <div className="">
                                          <i className="">Drill Görseli:</i>{" "}
                                          <img
                                            className="w-full"
                                            src={
                                              baseImageURL + item.drill?.image
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <i className="w-full p-6 text-center border border-dashed rounded-xl">
                                          Görsel Bulunamadı!
                                        </i>
                                      )}
                                    </div>
                                    <div className="flex justify-end w-full mt-4">
                                      <ButtonComponent
                                        customStyle={"h-[24px] text-xs"}
                                        outline
                                        text={"Çıkar"}
                                        onClick={() => {
                                          handleRemoveActivity(
                                            index,
                                            values?.activities
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                            <CustomModal
                              width={500}
                              isOpen={addedActivityModalIsOpen}
                              setOpen={setAddedActivityModalIsOpen}
                              footer={null}
                            >
                              <CustomModalHeader
                                title={"Aktivite Açıklaması Düzenle"}
                              />
                              <EditActivityDescriptionForm
                                data={addedActivityData}
                                setActivityMovements={setActivityMovements}
                                setOpen={setAddedActivityModalIsOpen}
                              />
                            </CustomModal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-8">
                    <ButtonComponent
                      fill
                      submit
                      text={"Değişiklikleri Kaydet"}
                    />
                  </div>
                </form>
              </>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}

export default EditActivities;
