import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Paths } from "../../../routes";

import {CoachList} from "../../../containers/sections";

const Coaches = () => {
    const [setBreadcrumbData] = useOutletContext();

    useEffect(() => {
        setBreadcrumbData([
            {
                title: 'Panel',
            },
            {
                title: <Link to={Paths.Coaches.Coaches}>Antrenör Listesi</Link>,
            }
        ]);
    }, []);

    return (
        <div className="w-full p-4 bg-white rounded-md shadow">
            <CoachList />
        </div>
    );
}

export default Coaches;