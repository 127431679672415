import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Paths } from "../../../routes";
import { Input } from "antd";
import { RiErrorWarningLine } from "react-icons/ri";
import * as Yup from "yup";
import { useFormik } from "formik";
import { PulseLoader } from "react-spinners";
import { BsChevronLeft } from "react-icons/bs";
import { Upload, message } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { UserFormInputsData } from "../../../utils/datas/UserFormInputsData";
import validations from "../../../utils/validations";
import InputComponent from "../../../components/inputs";
import { ActivitiesFormInputsData } from "../../../utils/datas/activitiesFormInputsData";
import PageTitle from "../../../components/pageTitle";
import AddActivitiesForm from "./addActivitiesForm";
import ButtonComponent from "../../../components/buttons/ButtonComponent";

const AddActivities = () => {
  const [setBreadcrumbData] = useOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel",
      },
      {
        title: <Link to={Paths.Users.Players.Players}>Oyuncu Listesi</Link>,
      },
      {
        title: <Link to={Paths.Users.AddUser}>Oyuncu Ekle</Link>,
      },
    ]);
  }, []);

  return (
    <div className="w-full p-4 bg-white rounded-md shadow">
      <div className="w-full p-4 pb-56 bg-white rounded-md shadow">
        <div className="flex items-center text-gray-500">
          <button onClick={() => navigate(-1)}>
            <BsChevronLeft size={21} />
          </button>
          <h4 className="ml-4 text-lg font-semibold">Faaliyet Ekle</h4>
        </div>
        {/* INPUTS */}
        <AddActivitiesForm />
        {/* INPUTS */}
      </div>
    </div>
  );
};

export default AddActivities;
