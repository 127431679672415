import React, { useState } from "react";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import { useFormik } from "formik";
import DynamicInput from "../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../utils/handleCustomChange";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import {
  createTreatmentsType,
  deleteTreatmentsMedicines,
  deleteTreatmentsType,
  updateTreatmentsType,
} from "../../services/axios";
import Swal from "sweetalert2";

export function CreateTreatmentsTypeForm({
  isOpen,
  setOpen,
  setIsDataChange,
  isDataChange,
  updateSelectedValuesProp,
}) {
  // console.log(updateSelectedValuesProp, "updateSelectedValuesProp");
  const [loading, setLoading] = useState(false);

  const fetchDeleteJudge = async (id) => {
    try {
      const result = await deleteTreatmentsType(id);
      // console.log(result, "result");
      setIsDataChange(true);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const handleJudgeDeleteOnClick = async (item) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${item.treatments_type_title}" tipini silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteJudge(item?.id);
        Swal.fire(
          "Silindi!",
          `"${item.treatments_type_title}" tipi silindi.`,
          "success"
        );
      }
    });
  };

  const {
    errors,
    values,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      treatments_type_title:
        updateSelectedValuesProp?.treatments_type_title ?? "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        let result = null;
        if (updateSelectedValuesProp) {
          result = await updateTreatmentsType(
            updateSelectedValuesProp.id,
            values.treatments_type_title
          );
        } else {
          result = await createTreatmentsType(values.treatments_type_title);
        }

        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result?.message ?? "Ekleme Başarılı!",
            showConfirmButton: false,
            timer: 1000,
          });

          // Set isDataChange to true
          setLoading(false);
          setIsDataChange(true);

          //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: result?.message ?? "Ekleme Başarısız!",
            showConfirmButton: false,
            timer: 1000,
          });
        }
      } catch (error) {
        console.error("Hata oluştu:", error);
      } finally {
        setOpen(false);
      }
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid gap-4 pt-4">
          <DynamicInput
            labelTitle={"Tedavi Tipi Başlığı"}
            containerStyle={"w-full"}
            placeholder={
              updateSelectedValuesProp?.name ?? "Tedavi Tipi Başlığı"
            }
            name={"treatments_type_title"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "treatments_type_title",
                setFieldValue,
                "input"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
          />
          {updateSelectedValuesProp && (
            <>
              <div className="flex w-full pb-1">
                <ButtonComponent
                  onClick={() =>
                    handleJudgeDeleteOnClick(updateSelectedValuesProp)
                  }
                  text={"Tipi Sil"}
                  outline
                  customStyle={"w-fit h-[56px]"}
                />
              </div>
            </>
          )}
        </div>

        <div className="flex items-center justify-end gap-4 pt-4">
          <ButtonComponent
            onClick={() => {
              setOpen(false);
              resetForm();
            }}
            outline
            text={"Vazgeç"}
          />
          <ButtonComponent submit fill text={"Kaydet"} />
        </div>
      </form>
    </>
  );
}

function CreateTreatmentsTypeModal({ isOpen, setOpen, setIsDataChange }) {
  return (
    <div>
      <div>
        <CustomModal
          width={677}
          isOpen={isOpen}
          setOpen={setOpen}
          footer={null}
        >
          <CustomModalHeader
            title={"Yeni Tedavi Tipi Ekleyin"}
            description={"Aşağıdan yeni tedavi tipi ekleyin."}
          />
          <CreateTreatmentsTypeForm
            isOpen={isOpen}
            setOpen={setOpen}
            setIsDataChange={setIsDataChange}
          />
        </CustomModal>
      </div>
    </div>
  );
}

export default CreateTreatmentsTypeModal;
