import React, { useEffect, useRef, useState } from "react";
import DynamicInput from "../../../../components/inputs/dynamicInput";
import { Formik, useFormik } from "formik";
import { handleCustomChange } from "../../../../utils/handleCustomChange";
import ButtonComponent from "../../../../components/buttons/ButtonComponent";
import {
  addUser,
  baseImageURL,
  baseURL,
  getAllRoles,
  getCities,
  getDistricts,
  getStoredBearerToken,
  getUser,
  updateUser,
} from "../../../../services/axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";

import { nanoid } from "nanoid";
import { PositionData } from "../../../../utils/datas/positionData";
import CustomModal from "../../../../components/modals";
import CustomModalHeader from "../../../../components/modals/CustomModalHeader";
import { RoundedIconButton } from "../../../../components/buttons";
import { FiPlus, FiPlusCircle, FiSettings } from "react-icons/fi";
import axios from "axios";

function AddUserStaticForm({
  isDataChange,
  setDataChange,
  updateForm,
  setSelectedUserRole,
}) {
  const [userInformationData, setUserInformationData] = useState([
    {
      type: "text",
      labelTitle: "Fotoğraf",
      name: "imageurl",
      required: false,
      component_type: "fileUpload",
      options: "",
    },
    // {
    //   type: "text",
    //   labelTitle: "Kullanıcı Kodu",
    //   placeholder: "Kullanıcı Kodu",
    //   name: "userCode",
    //   required: true,
    //   component_type: "input",
    //   options: "",
    //   disabled: true,
    // },
    {
      type: "text",
      labelTitle: "Ad",
      placeholder: "Ad",
      name: "name",
      required: true,
      component_type: "input",
      options: "",
    },
    {
      type: "text",
      labelTitle: "Soyad",
      placeholder: "Soyad",
      name: "surname",
      required: true,
      component_type: "input",
      options: "",
    },
    {
      type: "text",
      labelTitle: "E-Posta Adresi",
      placeholder: "E-Posta Adresi",
      name: "email",
      required: true,
      component_type: "input",
      options: "",
    },
    {
      type: "text",
      labelTitle: "TCKN",
      placeholder: "TCKN",
      name: "tckn",
      required: true,
      component_type: "input",
      options: "",
      onlyNumber: true,
    },

    {
      type: "text",
      labelTitle: "Telefon Numarası",
      placeholder: "Telefon Numarası",
      name: "phone",
      required: false,
      component_type: "phoneNumber",
      options: "",
    },
    {
      type: "text",
      labelTitle: "Şifre",
      placeholder: "Şifre",
      name: "password",
      required: true,
      component_type: "input",
      options: "",
    },
    // {
    //   type: "text",
    //   labelTitle: "Şifre Tekrar",
    //   placeholder: "Şifre Tekrar",
    //   name: "password_confirmation",
    //   required: true,
    //   component_type: "input",
    //   options: ""
    // },
    {
      type: "text",
      labelTitle: "Durum",
      placeholder: "Aktif mi?",
      name: "status",
      required: true,
      component_type: "checkbox",
      options: "",
    },
  ]);

  const { id } = useParams();
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [activeTab, setActiveTab] = useState("tab1");
  const [docsData, setDocsData] = useState([]);

  const getDocsDatas = async () => {
    const result = await getUser(id);
    let docsData = result.data.user;
    setDocsData(docsData);
  };
  useEffect(() => {
    getDocsDatas();
  }, []);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const [fileA, setFileA] = useState(null);
  const [fileB, setFileB] = useState(null);
  const [fileC, setFileC] = useState(null);
  const [fileD, setFileD] = useState(null);

  const fileInputRefA = useRef(null);
  const fileInputRefB = useRef(null);
  const fileInputRefC = useRef(null);
  const fileInputRefD = useRef(null);

  const handleFileChange = (e, setFileFunc) => {
    const file = e.target.files[0];
    setFileFunc(file);
  };

  const handleFileUploadA = async (file, destination) => {
    try {
      const formData = new FormData();
      formData.append("identity_file", file);
      formData.append("id", id);
      await axios.post(`${baseURL}/user/update-file-user/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      });
      setFileA(null);
      getDocsDatas()
    } catch (error) {
      console.error("hata", error);
    }
    // Dosyayı belirtilen yere göndermek için gerekli işlemleri yapabilirsiniz
    console.log(`Dosya ${file} adresine gönderilecek.`);
  };
  const handleFileUploadB = async (file, destination) => {
    try {
      const formData = new FormData();
      formData.append("blood_card", file);
      formData.append("id", id);
      await axios.post(`${baseURL}/user/update-file-user/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      });
      setFileB(null);
      getDocsDatas()
    } catch (error) {
      console.error("hata", error);
    }
    // Dosyayı belirtilen yere göndermek için gerekli işlemleri yapabilirsiniz
    console.log(`Dosya ${file.name} ${destination} adresine gönderilecek.`);
  };
  const handleFileUploadC = async (file, destination) => {
    try {
      const formData = new FormData();
      formData.append("healty_file", file);
      formData.append("id", id);
      await axios.post(`${baseURL}/user/update-file-user/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      });
      setFileC(null);
      getDocsDatas()
    } catch (error) {
      console.error("hata", error);
    }
    // Dosyayı belirtilen yere göndermek için gerekli işlemleri yapabilirsiniz
    console.log(`Dosya ${file.name} ${destination} adresine gönderilecek.`);
  };
  const handleFileUploadD = async (file, destination) => {
    try {
      const formData = new FormData();
      formData.append("license_file", file);
      formData.append("id", id);
      await axios.post(`${baseURL}/user/update-file-user/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      });
      setFileD(null);
      getDocsDatas()
    } catch (error) {
      console.error("hata", error);
    }
    // Dosyayı belirtilen yere göndermek için gerekli işlemleri yapabilirsiniz
    console.log(`Dosya ${file.name} ${destination} adresine gönderilecek.`);
  };

  const [loading, setLoading] = useState(false);
  const [staticInputData, setStaticInputData] = useState(null);
  const [allRolesOption, setAllRolesOption] = useState(null);
  const [allPermissions, setAllPermissions] = useState(null)

  const [allCity, setAllCity] = useState([{ label: "Seçiniz", value: null }]);

  const [selectedCity, setSelectedCity] = useState(false);

  const [allDistrict, setAllDistrict] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(false);

  const [random8DigitNumber, setRandom8DigitNumber] = useState(
    Math.floor(10000000 + Math.random() * 90000000)
  );

  // Random sayıyı güncellemek için fonksiyon
  const updateRandom8DigitNumber = () => {
    setRandom8DigitNumber(Math.floor(10000000 + Math.random() * 90000000));
  };
  const fetchAddUser = async (
    image,
    user_code,
    name,
    surname,
    email,
    password,
    password_confirmation,
    tckn,
    phone,
    role,
    status
  ) => {
    setLoading(true);
    try {
      const result = await addUser(
        image,
        user_code,
        name,
        surname,
        email,
        password,
        password_confirmation,
        tckn,
        phone,
        role,
        status
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });

        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setDataChange(true);
      setLoading(false);
    }
  };

  const fetchUpdateUser = async (
    userId,
    image,
    user_code,
    name,
    surname,
    email,
    password,
    password_confirmation,
    tckn,
    phone,
    status,
    role,
    fkCity,
    fkDistrict,
    position
  ) => {
    setLoading(true);
    try {
      const result = await updateUser(
        userId,
        image,
        user_code,
        name,
        surname,
        email,
        password,
        password_confirmation,
        tckn,
        phone,
        status,
        role,
        fkCity,
        fkDistrict,
        position
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Düzenleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Düzenleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      console.log(result);
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setDataChange(true);
      setLoading(false);
    }
  };

  const fetchGetCity = async () => {
    setLoading(true);
    try {
      const result = await getCities();
      setAllCity((prevState) => [
        ...prevState,
        ...result.data.data.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      ]);

      setLoading(false);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchGetDistrict = async () => {
    if (selectedCity) {
      try {
        const result = await getDistricts(selectedCity.userCity.value);
        setAllDistrict(() => [
          {
            label: "Seçiniz",
            value: null,
          },
          ...result?.data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ]);
      } catch (error) {
        console.log("hata", error);
      }
    }
  };

  const fetchGetUser = async (userID) => {
    setLoading(true);
    try {
      const result = await getUser(userID);
      console.log("ress", result);
      setSelectedUserRole(result.data.user?.roles?.[0]?.role_code);
      const staticInputData = result.data.user;
      console.log("ress2", staticInputData);
      let z = staticInputData.roles.find((item) => item.name == 'footballer')
      setAllPermissions(z)
      setStaticInputData(staticInputData);
      setSelectedCity({
        userCity: {
          label: result.data.user.city.name,
          value: result.data.user.city.id,
        },
      });
      setSelectedDistrict({
        userDistrict: {
          label: result.data.user.district.name,
          value: result.data.user.district.id,
        },
      });
      setLoading(false);
      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setDataChange(false);
    }
  };

  const fetchGetRoles = async () => {
    setLoading(true);
    try {
      const result = await getAllRoles();
      console.log("rolz?", result)
      console.log(
        result?.data.map((item) => item),
        "fetchGetRoles"
      );
      const options = result?.data?.map((item) => ({
        label: item?.name,
        value: item?.role_code,
      }));
      setAllRolesOption(options);
      console.log("allroles", allRolesOption)
      // setLoading(false);
      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetCity();
  }, []);

  useEffect(() => {
    fetchGetDistrict();
  }, [selectedCity]);

  useEffect(() => {
    fetchGetRoles();
    fetchGetUser(id);
  }, [isDataChange]);

  return (
    <>
      {loading && (
        <>
          <div className="flex">
            <PulseLoader color="red" />
            <p>Yükleniyor...</p>
          </div>
        </>
      )}
      {!loading && (
        <div className="pt-4">
          {updateForm && staticInputData && (
            <Formik
              initialValues={{
                userImage: "",
                userCode: staticInputData?.user_code ?? "",
                name: staticInputData?.name ?? "",
                surname: staticInputData?.surname ?? "",
                email: staticInputData?.email ?? "",
                tckn: staticInputData?.tckn ?? "",
                phone: staticInputData?.phone ?? "",
                password: "",
                userConfirmPassword: "",
                status: staticInputData?.status ?? "",
                userRole:
                  {
                    label: staticInputData?.roles?.[0]?.name,
                    value: staticInputData?.roles?.[0]?.role_code,
                  } ?? "",
                userCity: staticInputData?.user?.city?.name ?? "",
                position: staticInputData?.position
                  ? {
                      label: PositionData.find(
                        (item) => item.value === staticInputData?.position
                      )?.code,
                      value: staticInputData?.position,
                    }
                  : "",
              }}
              onSubmit={(values, { resetForm }) => {
                console.log(values, "update_values");
                if (staticInputData) {
                  fetchUpdateUser(
                    id,
                    values?.imageurl,
                    values?.userCode,
                    values?.name,
                    values?.surname,
                    values?.email,
                    values?.password,
                    values?.userConfirmPassword,
                    values?.tckn,
                    values?.phone,
                    values?.status,
                    values?.userRole,
                    selectedCity ? selectedCity.userCity.value : null,
                    selectedDistrict
                      ? selectedDistrict.userDistrict.value
                      : null,
                    values?.position?.value
                  );
                  console.log("sifre", values?.userConfirmPassword);
                  setDataChange(true);
                }

                //   resetForm();
              }}
              validate={(values) => {
                const errors = {};

                // userInformationData.forEach((input) => {
                //   if (input.required && !values[input.name]) {
                //     errors[input.name] = "Bu alan boş bırakılamaz";
                //   }
                // });

                userInformationData.forEach((input) => {
                  if (
                    input.required &&
                    !values["userCode"] &&
                    !values["name"] &&
                    !values["surname"] &&
                    !values["email"] &&
                    !values["tckn"] &&
                    !values["phone"] &&
                    input.name !== "password" &&
                    input.name !== "imageurl" &&
                    input.name !== "userConfirmPassword"
                  ) {
                    errors[input.name] = "Bu alan boş bırakılamaz";
                  }
                });

                console.log(errors, "hata");
                return errors;
              }}
            >
              {({
                errors,
                values,
                handleSubmit,
                touched,
                handleBlur,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="grid gap-4 xl:grid-cols-2">
                    <div className="flex flex-col col-span-full">
                      <div className="flex justify-between">
                        {staticInputData?.imageurl ? (
                          <img
                            className="w-48"
                            // src={baseImageURL + staticInputData?.imageurl}
                            src={`${baseImageURL}/users/${staticInputData?.imageurl}`}
                            // src={'http://localhost/altinordu-backend-app/public/api/users' + staticInputData.imageurl}
                          />
                        ) : (
                          <p className="flex items-center justify-center w-32 h-32 text-center border aspect-square rounded-xl">
                            Fotoğraf Eklenmedi
                          </p>
                        )}
                         {allPermissions ? 
                         <div>

                          <div className="flex items-center gap-2.5">
                            Döküman Fotoğrafı Ekle
                          <RoundedIconButton
                            icon={<FiPlus />}
                            type="button"
                            onClick={() => setIsSettingsModalOpen(true)}
                          />
                          </div>
                          <CustomModal
                            width={1100}
                            isOpen={isSettingsModalOpen}
                            setOpen={setIsSettingsModalOpen}
                            footer={null}
                          >
                            <CustomModalHeader
                              title={"Fotoğraf Ekle & Görüntüle"}
                              description={
                                "Aşağıdan Fotoğraflarınızı Ekleyebilir ve Görüntüleyebilirsiniz."
                              }
                              // icon={"IoMdSettings"}
                            />
                            <div className="flex flex-col">
                              <div className="flex justify-center items-center mt-2.5 gap-2.5">
                                <button
                                  onClick={() => handleTabChange("tab1")}
                                  className="bg-red-500 text-white rounded-md px-3 py-1.5"
                                >
                                  Fotoğraf Yükle
                                </button>
                                <button
                                  onClick={() => handleTabChange("tab2")}
                                  className="bg-blue-500 text-white rounded-md px-3 py-1.5"
                                >
                                  Yüklü Fotoğraf Görüntüle
                                </button>
                              </div>
                              <div>
                                {activeTab === "tab1" && (
                                  <div>
                                    <div className="grid grid-cols-2 p-6 bg-white rounded-md">
                                      <div className="mb-4">
                                        <label
                                          className="block text-gray-700 text-sm font-bold mb-2"
                                          htmlFor="fileInputA"
                                        >
                                          Kimlik Fotokopisi
                                        </label>
                                        <div className="flex items-center">
                                          <input
                                            type="file"
                                            id="fileInputA"
                                            className="hidden"
                                            onChange={(e) =>
                                              handleFileChange(e, setFileA)
                                            }
                                            ref={fileInputRefA}
                                          />
                                          <label
                                            htmlFor="fileInputA"
                                            className="cursor-pointer bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
                                          >
                                            {fileA
                                              ? "Dosya Seçildi"
                                              : "Dosya Seç"}
                                          </label>
                                          {fileA && (
                                            <p className="ml-4 text-gray-700">
                                              {fileA.name}
                                            </p>
                                          )}
                                          {fileA ? (
                                            <button
                                              className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                              onClick={() =>
                                                handleFileUploadA(fileA, "A")
                                              }
                                            >
                                              Gönder
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="mb-4">
                                        <label
                                          className="block text-gray-700 text-sm font-bold mb-2"
                                          htmlFor="fileInputB"
                                        >
                                          Kan Grubu Kartı
                                        </label>
                                        <div className="flex items-center">
                                          <input
                                            type="file"
                                            id="fileInputB"
                                            className="hidden"
                                            onChange={(e) =>
                                              handleFileChange(e, setFileB)
                                            }
                                            ref={fileInputRefB}
                                          />
                                          <label
                                            htmlFor="fileInputB"
                                            className="cursor-pointer bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
                                          >
                                            {fileB
                                              ? "Dosya Seçildi"
                                              : "Dosya Seç"}
                                          </label>
                                          {fileB && (
                                            <p className="ml-4 text-gray-700">
                                              {fileB.name}
                                            </p>
                                          )}
                                          {fileB ? (
                                            <button
                                              className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                              onClick={() =>
                                                handleFileUploadB(fileB, "B")
                                              }
                                            >
                                              Gönder
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="mb-4">
                                        <label
                                          className="block text-gray-700 text-sm font-bold mb-2"
                                          htmlFor="fileInputC"
                                        >
                                          Sağlık Kartı
                                        </label>
                                        <div className="flex items-center">
                                          <input
                                            type="file"
                                            id="fileInputC"
                                            className="hidden"
                                            onChange={(e) =>
                                              handleFileChange(e, setFileC)
                                            }
                                            ref={fileInputRefC}
                                          />
                                          <label
                                            htmlFor="fileInputC"
                                            className="cursor-pointer bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
                                          >
                                            {fileC
                                              ? "Dosya Seçildi"
                                              : "Dosya Seç"}
                                          </label>
                                          {fileC && (
                                            <p className="ml-4 text-gray-700">
                                              {fileC.name}
                                            </p>
                                          )}
                                          {fileC ? (
                                            <button
                                              className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                              onClick={() =>
                                                handleFileUploadC(fileC, "C")
                                              }
                                            >
                                              Gönder
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="mb-4">
                                        <label
                                          className="block text-gray-700 text-sm font-bold mb-2"
                                          htmlFor="fileInputD"
                                        >
                                          Lisans
                                        </label>
                                        <div className="flex items-center">
                                          <input
                                            type="file"
                                            id="fileInputD"
                                            className="hidden"
                                            onChange={(e) =>
                                              handleFileChange(e, setFileD)
                                            }
                                            ref={fileInputRefD}
                                          />
                                          <label
                                            htmlFor="fileInputD"
                                            className="cursor-pointer bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
                                          >
                                            {fileD
                                              ? "Dosya Seçildi"
                                              : "Dosya Seç"}
                                          </label>
                                          {fileD && (
                                            <p className="ml-4 text-gray-700">
                                              {fileD.name}
                                            </p>
                                          )}
                                          {fileD ? (
                                            <button
                                              className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                              onClick={() =>
                                                handleFileUploadD(fileD, "D")
                                              }
                                            >
                                              Gönder
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      {/* Diğer dosya yükleme alanları için benzer yapıları oluşturabilirsiniz */}
                                      {/* Dosya B */}
                                      {/* Dosya C */}
                                    </div>
                                  </div>
                                )}
                                {activeTab === "tab2" && (
                                  <div className="flex flex-col gap-2">
                                    <h2 className="font-semibold">
                                      Kimlik fotokopisi
                                    </h2>
                                    {docsData.identitity_file ? (
                                      <img
                                        src={`${baseImageURL}/uploads/${docsData?.identitity_file}`}
                                      />
                                    ) : (
                                      <span className="text-red-600">
                                        Kimlik fotokopisi yok
                                      </span>
                                    )}
                                    <h2 className="font-semibold">
                                      Sağlık kartı
                                    </h2>
                                    {docsData.healty_file ? (
                                      <img
                                        src={`${baseImageURL}/uploads/${docsData?.healty_file}`}
                                      />
                                    ) : (
                                      <span className="text-red-600">
                                        Sağlık kartı yok
                                      </span>
                                    )}
                                    <h2 className="font-semibold">
                                      Kan grubu kartı
                                    </h2>
                                    {docsData.blood_card ? (
                                      <img
                                        src={`${baseImageURL}/uploads/${docsData?.blood_card}`}
                                      />
                                    ) : (
                                      <span className="text-red-600">
                                        Kan grubu kartı yok
                                      </span>
                                    )}
                                    <h2 className="font-semibold">
                                      Lisans Fotoğrafı
                                    </h2>
                                    {docsData.license_file ? (
                                      <img
                                        src={`${baseImageURL}/uploads/${docsData?.license_file}`}
                                      />
                                    ) : (
                                      <span className="text-red-600">
                                        Lisans fotoğrafı yok
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </CustomModal>
                        </div>
                         : ''} 
                      </div>
                    </div>
                    <div className="">
                      <DynamicInput
                        labelTitle={"Roller"}
                        containerStyle={"w-full"}
                        placeholder={"Rol seçiniz..."}
                        name={"userRole"}
                        value={values}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "userRole",
                            setFieldValue,
                            "select"
                          );
                        }}
                        required={true}
                        component_type={"select"}
                        customOptions={allRolesOption ?? ""}
                      />
                    </div>
                    {/* Bura */}
                    {userInformationData.map((item) => {
                      if ((allPermissions?.role_code == 'super_admin' && item?.name == 'password') || (allPermissions?.role_code != 'super_admin' && item?.name != 'password')) {
                      // console.log("itemMert", item);
                      //   console.log("bak", allPermissions)
                      return (
                        <>
                          {
                            <DynamicInput
                              type={item?.type}
                              labelTitle={item?.labelTitle}
                              containerStyle={"w-full"}
                              placeholder={item?.placeholder}
                              name={item?.name}
                              value={
                                item?.component_type != "fileUpload" && values
                              }
                              error={errors}
                              isTouched={touched}
                              onBlur={handleBlur}
                              onChange={(e1, e2) => {
                                handleCustomChange(
                                  e1,
                                  e2,
                                  item?.name,
                                  setFieldValue,
                                  item?.component_type
                                );
                              }}
                              required={item?.required}
                              component_type={item?.component_type}
                              options={item?.options}
                              checkboxLabel={item?.checkboxLabel}
                              onlyNumber={item?.onlyNumber}
                            />
                          }
                        </>
                      );
                    } 
                    })}
                    {/* <DynamicInput
                      labelTitle={"Şehir"}
                      containerStyle={"w-full"}
                      placeholder={"Seçiniz..."}
                      name={"userCity"}
                      value={selectedCity}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setSelectedCity({
                          userCity: { label: e.label, value: e.value },
                        });
                      }}
                      component_type={"select"}
                      customOptions={allCity ?? []}
                    /> */}
                    {/* <DynamicInput
                      labelTitle={"İlçe"}
                      containerStyle={"w-full"}
                      placeholder={"Seçiniz..."}
                      name={"userDistrict"}
                      value={selectedDistrict}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setSelectedDistrict({
                          userDistrict: { label: e.label, value: e.value },
                        });
                      }}
                      component_type={"select"}
                      customOptions={allDistrict ?? []}
                    /> */}
                    {staticInputData.roles.length > 0 &&
                      staticInputData.roles.some((role) => role.id === 3) && (
                        <DynamicInput
                          labelTitle={"Pozisyon"}
                          containerStyle={"w-full"}
                          placeholder={"Seçiniz..."}
                          name={"position"}
                          value={values}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          onChange={(e1, e2) => {
                            handleCustomChange(
                              e1,
                              e2,
                              "position",
                              setFieldValue,
                              "select"
                            );
                          }}
                          component_type={"select"}
                          customOptions={[
                            { label: "Seçiniz", value: null },
                            ...PositionData.map((item) => ({
                              label: item.code,
                              value: item.value,
                            })),
                          ]}
                        />
                      )}
                  </div>
                  <div className="flex justify-end mt-4">
                    <ButtonComponent submit text={"Güncelle"} fill />
                  </div>
                </form>
              )}
            </Formik>
          )}

          {/* -------------- EKLEME-------------------- */}
          {!updateForm && (
            <Formik
              initialValues={{
                imageurl: "",
                userCode: nanoid(),
                name: "",
                surname: "",
                email: "",
                password: "",
                userConfirmPassword: "",
                tckn: "",
                phone: "",
                status: 1,
                userRole: "footballer",
              }}
              onSubmit={async (values, { resetForm }) => {
                values.userRole = values?.userRole?.value;
                await fetchAddUser(
                  values?.imageurl,
                  values?.userCode,
                  values?.name,
                  values?.surname,
                  values?.email,
                  values?.password,
                  confirmPass,
                  values?.tckn,
                  values?.phone,
                  values?.status,
                  values?.userRole
                );

                resetForm();
                updateRandom8DigitNumber();
              }}
              validate={(values) => {
                const errors = {};

                if (!values.password) {
                  errors.password = "Şifre alanı boş olamaz";
                } else if (values.password.length < 8) {
                  errors.password = "Şifre minimum 8 karakter olmalı";
                }

                if (!confirmPass) {
                  errors.passConfirm = "Şifre tekrar alanı boş olamaz";
                } else if (values?.password !== confirmPass) {
                  errors.passConfirm = "Şifreler eşleşmiyor";
                }

                userInformationData.forEach((input) => {
                  if (input.required && !values[input.name]) {
                    errors[input.name] = "Bu alan boş bırakılamaz";
                  }
                });

                console.log(errors, "hata");
                return errors;
              }}
            >
              {({
                errors,
                values,
                handleSubmit,
                touched,
                handleBlur,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="grid gap-4 xl:grid-cols-2">
                    <DynamicInput
                      labelTitle={"Roller"}
                      containerStyle={"w-full"}
                      placeholder={"Rol seçiniz..."}
                      name={"userRole"}
                      value={values}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "userRole",
                          setFieldValue,
                          "select"
                        );
                      }}
                      required={true}
                      component_type={"select"}
                      customOptions={allRolesOption ?? ""}
                    />
                    {userInformationData.map((item, index) => {
                      return (
                        item?.name != "status" && (
                          <DynamicInput
                            key={index}
                            disabled={item?.disabled}
                            type={item?.type}
                            labelTitle={item?.labelTitle}
                            containerStyle={"w-full"}
                            placeholder={item?.placeholder}
                            name={item?.name}
                            value={
                              item?.component_type != "fileUpload" && values
                            }
                            error={errors}
                            isTouched={touched}
                            onBlur={handleBlur}
                            onChange={(e1, e2) => {
                              handleCustomChange(
                                e1,
                                e2,
                                item?.name,
                                setFieldValue,
                                item?.component_type
                              );
                            }}
                            required={item?.required}
                            component_type={item?.component_type}
                            options={item?.options}
                            checkboxLabel={item?.checkboxLabel}
                            onlyNumber={item?.onlyNumber}
                          />
                        )
                      );
                    })}
                    <DynamicInput
                      labelTitle={"Şifre Tekrar"}
                      containerStyle={"w-full"}
                      placeholder={"Şifre Tekrar..."}
                      name={"passConfirm"}
                      value={confirmPass}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      onChange={(e) => setConfirmPass(e.target.value)}
                      required={true}
                      component_type={"input"}
                      defaultValue={""}
                    />
                  </div>

                  <div className="flex justify-end mt-4">
                    <ButtonComponent submit text={"Ekle"} fill />
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      )}
    </>
  );
}

export default AddUserStaticForm;
