import React, { useEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import InputComponent from "../../../components/inputs";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import CustomModal from "../../../components/modals";
import CustomModalHeader from "../../../components/modals/CustomModalHeader";
import { handleCustomChange } from "../../../utils/handleCustomChange";
import dayjs from "dayjs";
import DynamicInput from "../../../components/inputs/dynamicInput";
import CreateFootballGroundModal from "../../../containers/modals/CreateFootballGroundModal";
import {
  createActivity,
  createActivityTest,
  getAllAreas,
  getAllDrills,
  getAllTests,
  getFootballGround,
  getTestById,
  listTeams,
} from "../../../services/axios";
import { CarryOutOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import Swal from "sweetalert2";
import { DataTable } from "../../../components/tables";

// divider*********************
function AddActivitiesTestForm({
  newEventData,
  setSaveIt,
  setIsModalOpen,
  isDataChange,
  setIsDataChange,
  userDataTableData,
}) {
  console.log(newEventData, "newEventData");
  console.log(newEventData.start, "omer start");
  console.log(newEventData.end, "omer end");

  const [startTimeValue, setStartTimeValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [newTest, setNewTest] = useState(null);

  const [selectedTest, setSelectedTest] = useState(null);
  const [treeData, setTreeData] = useState([]);

  const [rowSelectionList, setRowSelectionList] = useState([]);
  const [rowSelectionListManager, setRowSelectionListManager] = useState([]);

  const [areasOptions, setAreasOptions] = useState([]);

  const [footballGroundOptions, setFootballGroundOptions] = useState([]);

  const [testOptions, setTestsOptions] = useState([]);

  const [selectTeamPlayers, setSelectTeamPlayers] = useState([]);
  const [teamCategoryOptions, setTeamCategoryOptions] = useState([]);

  const [filteredDataTableData, setFilteredDataTableData] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");

  const handleSearchOnChange = (e) => {
    const searchValue = e.target.value;
    setSearchInputValue(searchValue);
    const filteredData = userDataTableData.filter((item) => {
      for (let key in item) {
        if (typeof item[key] === "string" && item[key].includes(searchValue)) {
          return true;
        }
      }
      return false;
    });
    setFilteredDataTableData(filteredData);
  };

  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      title: "",
      activity_type: "test",
      start_date: "",
      end_date: "",
      status: "",
      start_time: "",
      end_time: "",
      // ozel alanlar
      description: "",
      weather: "",
      activity_area: "",
      managers: "",
      players: "",
      notes: "",
      tests: [],
      color: "",
      team_category: "",
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values, "values ooo");
      values.status = values?.status?.value;
      values.color = values?.color?.value;
      values.players = rowSelectionListManager?.selectedRowItems?.map(
        (item) => item.key ?? []
      );
      values.managers = rowSelectionList?.selectedRowItems?.map(
        (item) => item.key ?? []
      );
      values.weather = values?.weather.value;
      values.activity_area = values?.activity_area.value;
      values.team_category = values?.team_category.value;
      values.tests = values?.tests?.map((item) => item?.id);
      try {
        fetchCreateActivity(
          values?.title,
          values?.activity_type,
          values?.start_date,
          values?.end_date,
          values?.status,
          values?.start_time,
          values?.end_time,
          //     // ozel alanlar
          values?.description,
          values?.weather,
          values?.activity_area,
          values?.managers,
          values?.players,
          values?.notes,
          values?.tests,
          "blue",
          values?.team_category
        );
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsModalOpen(false);
      }
    },
  });

  // useEffect(() => {
  //   if (newEventData) {
  //     const start = dayjs(newEventData?.start);
  //     const end = dayjs(newEventData?.end);

  //     // initialValues'i güncelle
  //     setFieldValue("activitiesDayRange", [start, end]);

  //     const minutes = end.diff(start, "minute");
  //     console.log(minutes, "omer");
  //     setFieldValue("activitiesTime", minutes + " " + "dakika");
  //   }
  // }, [newEventData]);

  // console.log(startTimeValue, endTimeValue);

  const onSelect = async (selectedKeys, info) => {
    setFormLoading(true);
    try {
      const result = await getTestById(selectedKeys[0]); //tek kategori çağrıma
      console.log("resultttttt: ", result?.data?.test);
      setSelectedTest(result?.data?.test);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setFormLoading(false);
    }
  };

  const fetchCreateActivity = async (
    title,
    activity_type,
    start_date,
    end_date,
    status,
    start_time,
    end_time,
    // ozel alanlar
    description,
    weather,
    activity_area,
    managers,
    players,
    notes,
    tests,
    color,
    team_category
  ) => {
    try {
      const result = await createActivityTest(
        title,
        activity_type,
        start_date,
        end_date,
        status,
        start_time,
        end_time,
        // ozel alanlar
        description,
        weather,
        activity_area,
        managers,
        players,
        notes,
        tests,
        color,
        team_category
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });
        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };

  const fetchGetAllAreas = async () => {
    setLoading(true);
    try {
      const result = await getAllAreas();
      console.log(result, "result");
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };
  const fetchGetFootballGround= async (isActive) => {
    setLoading(true);
    try {
      const result = await getFootballGround(isActive);
      console.log(result, "resultttttttttt");
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  const fetchGetAllTests = async () => {
    setLoading(true);
    setTreeData([]);
    try {
      const result = await getAllTests();
      result?.data?.tests.forEach((item) => {
        if (!item.up_category) {
          const treeItem = getTreeItem(
            item.id,
            item.title,
            item.id,
            <CarryOutOutlined />
          );
          const children = getChildren(item);
          treeItem.children = children;
          handleDataPush(treeItem);
        }
      });

      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setIsDataChange(false);
      setLoading(false);
    }
  };

  const fetchGetAllCategory = async () => {
    try {
      const result = await listTeams();
      console.log(result?.data?.teams, "team");
      return result?.data?.teams;
    } catch (error) {
      console.log(error, "err");
    }
  };

  function getChildren(category) {
    if (!category.sub_category || category?.sub_category?.length === 0) {
      return [];
    }
    return category.sub_category.map((item) => {
      const treeItem = getTreeItem(
        item.id,
        item.title,
        item.id,
        <CarryOutOutlined />
      );
      const children = getChildren(item);
      treeItem.children = children;
      return treeItem;
    });
  }

  function getTreeItem(id, title, key, icon, children = []) {
    return {
      id,
      title,
      key,
      icon,
      children,
    };
  }

  const handleDataPush = (item) => {
    setTreeData((prev) => [...prev, item]);
  };

  useEffect(() => {
    fetchGetAllAreas().then((result) =>
      setAreasOptions(
        result?.response?.areas.map((item) => ({
          label: item?.title,
          value: item?.id,
        }))
      )
    );

    fetchGetFootballGround().then((result) =>
    setFootballGroundOptions(
        result?.data?.footballGrounds.map((item) => ({
          label: item?.title,
          value: item?.id,
        }))
      )
    );

    fetchGetAllTests().then((result) =>
      setTestsOptions(
        result?.data?.tests?.map((item) => ({
          label: item?.title,
          value: item?.id,
        }))
      )
    );
    fetchGetAllCategory().then((teams) =>
      setTeamCategoryOptions([
        { label: "Tüm Kullanıcılar", value: "" }, // Başlangıç öğesi
        ...teams.map((item) => ({
          label: item?.team_title,
          value: item?.id,
          players: item?.players,
        })),
      ])
    );
  }, []);

  //eklenen etkinlik silme
  const handleRemoveActivity = (index) => {
    const newtests = values?.tests.splice(index, 1);
    setNewTest((prevValues) => ({
      ...prevValues,
      tests: newtests,
    }));
  };
  //eklenen etkinlik silme
  const handleAddTest = async () => {
    await setFieldValue("tests", [
      ...values.tests,
      {
        id: selectedTest?.id,
        title: selectedTest?.title,
        test_group_type: selectedTest?.test_group_type,
        test_type: selectedTest?.test_type,
      },
    ]);
  };
  console.log(selectedTest, "selectedTest");
  console.log(values.tests, "values.tests");
  function AddedInnerTests() {
    console.log("values?.testsvalues?.testsvalues?.tests: ", values?.tests);
    return (
      <div className="p-2 border rounded-xl">
        {/* <div className="flex justify-end w-full">
          <ButtonComponent
            onClick={() => handleAddTest()}
            fill
            text={"Test Ekle"}
          />
        </div> */}
        <p className="text-xl text-primary">Eklenen Testler</p>
        <div className="flex flex-col w-full gap-4">
          {values?.tests?.map((item, index) => {
            return (
              <>
                <div className="p-2 border rounded-lg">
                  <p>
                    <span className="font-bold">Başlık:</span> {item?.title}
                  </p>
                  <p>
                    <span className="font-bold">Test Grubu:</span>{" "}
                    {item?.test_group_type ?? <i>Test Grubu Yok</i>}
                  </p>
                  <p>
                    <span className="font-bold">Test Tipi:</span>{" "}
                    {item?.test_type}
                  </p>
                  {/* <p>
                    <span className="font-bold">Açıklama:</span>{" "}
                    {item?.description}
                  </p> */}
                  <div className="flex justify-end w-full">
                    <ButtonComponent
                      customStyle={"h-[24px] text-xs"}
                      outline
                      text={"Çıkar"}
                      onClick={() => {
                        handleRemoveActivity(index);
                      }}
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  }
  const { DirectoryTree } = Tree;

  //data takvimden geldiğinde süre belirleme
  useEffect(() => {
    if (newEventData) {
      const start_date = dayjs(newEventData?.start).format("YYYY-MM-DD");
      const end_date = dayjs(newEventData?.end).format("YYYY-MM-DD");
      // dayjs(newEventData?.start).format("HH:mm:ss");
      const start_time = "12:00"
      const end_time = dayjs(newEventData?.end).format("HH:mm:ss");

      // initialValues'i güncelle
      setFieldValue("start_date", start_date);
      setFieldValue("end_date", end_date);

      setFieldValue("start_time", start_time);
      setFieldValue("end_time", end_time);

      // const minutes = end.diff(start, "minute");
      // console.log(minutes, "omer");
      // setFieldValue("activitiesTime", minutes + " " + "dakika");
    }
  }, [newEventData]);

  const onChangeTeamSelect = (selected) => {
    const teamPlayersMap = selected?.players?.map((item) => item.player);

    const teamPlayersDataTableFormat = teamPlayersMap?.map((item) => ({
      key: item?.id,
      name: item?.user_name,
      tckn: item?.tckn,
    }));
    console.log(teamPlayersMap, "selectedPlayer");

    setSelectTeamPlayers(teamPlayersDataTableFormat);

    console.log(selected, "selectTeamPlayers");
  };

  return (
    <div>
      {/* inputs */}
      <div className="grid grid-cols-1 gap-12 mt-8">
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 md:grid-cols-2">
            <DynamicInput
              labelTitle={"Başlangıç Tarihi"}
              containerStyle={"w-full"}
              placeholder={"Faaliyet Başlığı"}
              name={"start_date"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "start_date",
                  setFieldValue,
                  "datePicker"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"datePicker"}
            />
            <DynamicInput
              labelTitle={"Başlangıç Bitiş"}
              containerStyle={"w-full"}
              placeholder={"Faaliyet Başlığı"}
              name={"end_date"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "end_date",
                  setFieldValue,
                  "datePicker"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"datePicker"}
            />
            {/*<DynamicInput*/}
            {/*  labelTitle={"Faaliyet Başlığı"}*/}
            {/*  containerStyle={"w-full"}*/}
            {/*  placeholder={"Faaliyet Başlığı"}*/}
            {/*  name={"title"}*/}
            {/*  value={values}*/}
            {/*  onChange={(e1, e2) => {*/}
            {/*    handleCustomChange(e1, e2, "title", setFieldValue, "input");*/}
            {/*  }}*/}
            {/*  error={errors}*/}
            {/*  isTouched={touched}*/}
            {/*  onBlur={handleBlur}*/}
            {/*/>*/}
            <DynamicInput
              labelTitle={"Durum"}
              containerStyle={"w-full"}
              placeholder={"Durum"}
              name={"status"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(e1, e2, "status", setFieldValue, "select");
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"select"}
              customOptions={[
                { label: "Gerçekleşti", value: "gerceklesti" },
                { label: "Planlandı", value: "planlandi" },
                { label: "İptal Edildi", value: "iptal_edildi" },
              ]}
            />

            <DynamicInput
              labelTitle={"Başlangıç Saati"}
              containerStyle={"w-full"}
              name={"start_time"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "start_time",
                  setFieldValue,
                  "timePicker"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"timePicker"}
            />
            <DynamicInput
              labelTitle={"Bitiş Saati"}
              containerStyle={"w-full"}
              name={"end_time"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "end_time",
                  setFieldValue,
                  "timePicker"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"timePicker"}
            />
            <DynamicInput
              labelTitle={"Hava Durumu"}
              containerStyle={"w-full"}
              placeholder={"Durum"}
              name={"weather"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(e1, e2, "weather", setFieldValue, "select");
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"select"}
              customOptions={[
                { label: "Yağmurlu", value: "yagmurlu" },
                { label: "Güneşli", value: "gunesli" },
              ]}
            />

            <DynamicInput
              labelTitle={"Saha Seçimi"}
              containerStyle={"w-full"}
              placeholder={"Saha Seçiniz"}
              name={"isActive"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "isActive",
                  setFieldValue,
                  "select"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"select"}
              customOptions={footballGroundOptions}
            />
            <DynamicInput
              labelTitle={"Faaliyet Açıklama"}
              containerStyle={"w-full"}
              placeholder={"Faaliyet Açıklama"}
              name={"description"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "description",
                  setFieldValue,
                  "input"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
            />
            <DynamicInput
              labelTitle={"Faaliyet Notu"}
              containerStyle={"w-full"}
              placeholder={"Faaliyet Notu"}
              name={"notes"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(e1, e2, "notes", setFieldValue, "input");
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
            />
            {/*<div className="relative z-50">*/}
            {/*  <DynamicInput*/}
            {/*    labelTitle={"Gösterim Rengi"}*/}
            {/*    containerStyle={"w-full"}*/}
            {/*    placeholder={"Renk seçiniz"}*/}
            {/*    name={"color"}*/}
            {/*    value={values}*/}
            {/*    onChange={(e1, e2) => {*/}
            {/*      handleCustomChange(e1, e2, "color", setFieldValue, "select");*/}
            {/*    }}*/}
            {/*    error={errors}*/}
            {/*    isTouched={touched}*/}
            {/*    onBlur={handleBlur}*/}
            {/*    component_type={"select"}*/}
            {/*    customOptions={[*/}
            {/*      { label: "Kırmızı", value: "red" },*/}
            {/*      { label: "Mavi", value: "blue" },*/}
            {/*      { label: "Yeşil", value: "green" },*/}
            {/*    ]}*/}
            {/*  />*/}
            {/*</div>*/}
            <DynamicInput
              labelTitle={"Takım"}
              containerStyle={"w-full"}
              placeholder={"Takım Seçiniz"}
              name={"team_category"}
              value={values}
              onChange={(e1, e2) => {
                onChangeTeamSelect(e1);
                handleCustomChange(
                  e1,
                  e2,
                  "team_category",
                  setFieldValue,
                  "select"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"select"}
              customOptions={teamCategoryOptions ?? []}
            />
            {/* boş element */}
            <div className="flex w-full gap-2 p-2 border divide-x rounded-xl">
              <div className="w-full">
                {treeData.length > 0 ? (
                  <>
                    <DirectoryTree
                      className="relative z-0"
                      showLine={false}
                      showIcon={false}
                      defaultExpandedKeys={0}
                      // defaultExpandAll
                      onSelect={onSelect}
                      treeData={treeData}
                    />
                  </>
                ) : (
                  <p>Yükleniyor...</p>
                )}
                <div className="w-full pl-2">
                  <div className="w-full ">
                    <div className="flex justify-end">
                      <ButtonComponent
                        onClick={() => handleAddTest()}
                        fill
                        text={"Test Ekle"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {<AddedInnerTests />}

            <div className="">
              {/* players */}
              <p className="pb-2 text-lg">Oyuncu Seç</p>
              <DataTable
                selection={{ rowSelectionList, setRowSelectionList }}
                loading={loading}
                columns={[
                  { title: "Ad Soyad", dataIndex: "name" },
                  { title: "TC", dataIndex: "tckn" },
                ]}
                data={selectTeamPlayers ?? userDataTableData ?? []}
              />
            </div>

            <div className="">
              <p className="pb-2 text-lg">Seçilen Oyuncular</p>
              <DataTable
                loading={loading}
                columns={[
                  { title: "Ad Soyad", dataIndex: "name" },
                  { title: "TC", dataIndex: "tckn" },
                ]}
                data={rowSelectionList?.selectedRowItems ?? []}
              />
            </div>
            <div className="">
              {/* players */}
              <div className="flex items-center justify-between pb-2">
                <p className="w-full text-lg">Personel Seç</p>
                <input
                  placeholder={"Kullanıcı Arayın..."}
                  name={"searchInput"}
                  value={searchInputValue}
                  onChange={(e) => handleSearchOnChange(e)}
                  className="px-3 py-2 border rounded-lg outline-none"
                />
              </div>{" "}
              <DataTable
                selection={{
                  rowSelectionList: rowSelectionListManager,
                  setRowSelectionList: setRowSelectionListManager,
                }}
                loading={loading}
                columns={[
                  { title: "Ad Soyad", dataIndex: "name" },
                  { title: "TC", dataIndex: "tckn" },
                ]}
                data={
                  searchInputValue ? filteredDataTableData : userDataTableData
                }
              />
            </div>
            <div className="">
              <p className="pb-2 text-lg">Seçilen Personeller</p>
              <DataTable
                loading={loading}
                columns={[
                  { title: "Ad Soyad", dataIndex: "name" },
                  { title: "TC", dataIndex: "tckn" },
                ]}
                data={rowSelectionListManager?.selectedRowItems ?? []}
              />
            </div>
          </div>

          <div className="flex items-center justify-end gap-4 pt-4">
            <ButtonComponent
              onClick={() => {
                setIsModalOpen(false);
                resetForm();
              }}
              text={"Vazgeç"}
              outline
            />
            <ButtonComponent submit fill text={"Kaydet"} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddActivitiesTestForm;
