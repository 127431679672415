import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Paths } from "../../routes";

import { DataTable } from "../../components/tables";
import { Input } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { GetActivities, filterActivities } from "../../services/redux/features";

import { FiEdit, FiPlus, FiTrash } from "react-icons/fi";
import ActionButtonsDataTable from "./ActionButtonsDataTable";

const ActivitiesList = () => {
  const [filterInputValue, setFilterInputValue] = useState("");
  const [rowSelectionList, setRowSelectionList] = useState([]);

  const { activities, filteredActivities, loading } = useSelector(
    (state) => state.activities
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!activities?.length) {
      dispatch(GetActivities());
    }
  }, []);

  useEffect(() => {
    dispatch(filterActivities(filterInputValue));
  }, [filterInputValue]);

  const columns = [
    {
      title: "Durum",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      align: "center",
    },
    {
      title: "Faaliyet_Zamanı",
      dataIndex: "activitiesDate",
      // sorter: (a, b) => a.age - b.age,
      width: 100,
    },
    {
      title: "Süre",
      dataIndex: "activitiesTime",
      // filters: [
      //     {
      //         text: 'London',
      //         value: 'London',
      //     },
      //     {
      //         text: 'New York',
      //         value: 'New York',
      //     },
      // ],
      // onFilter: (value, record) => record.address.indexOf(value) === 0,
    },
    {
      title: "Faaliyet_Grubu",
      dataIndex: "activitiesGroup",
    },
    { title: "Faaliyet_Yeri", dataIndex: "activitiesZone" },
    {
      title: "Açıklama/Sonuç",
      dataIndex: "activitiesDescription",
    },
    {
      title: "Takım",
      dataIndex: "activitiesTeam",
    },
    {
      title: "Antrenör",
      dataIndex: "manager",
    },
    {
      title: "İşlem",
      fixed: "right",
      key: "action",
      sorter: true,
      width: "",
      render: (item, index) => {
        return (
          <ActionButtonsDataTable
            id={item.key}
            toUpdate={Paths.Activities.EditActivities}
            toDetail={Paths.Activities.DetailActivities}
            deleteDataLabel={item.id}
            detailButton
            updateButton
            deleteButton
          />
        );
      },
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between text-gray-500">
        <h4 className="text-lg font-semibold">Faaliyet Listesi</h4>
        {/* {filterInputValue ? (
                    <p className="text-sm ">Filtrelenmiş {filteredActivities?.length} Aktiviteler Listeleniyor!</p>
                ) : (
                    <p className="text-sm ">{activities?.length} Aktiviteler Listeleniyor!</p>
                )} */}
      </div>
      <div className="flex flex-wrap-reverse items-center justify-between px-2 py-2 my-4 bg-gray-100 rounded-lg">
        <ul className="flex items-center">
          <li>
            <Link
              to={Paths.Activities.AddActivities}
              className="flex p-2 text-gray-500 transition-all rounded-lg hover:bg-gray-200"
            >
              <FiPlus size={21} />
              <span className="ml-2 text-sm text-gray-500">Ekle</span>
            </Link>
          </li>
        </ul>
        <div className="flex items-center w-full sm:w-auto">
          <span className="hidden mr-2 text-sm text-gray-500 sm:block">
            Filtrele:{" "}
          </span>
          <Input
            type="text"
            placeholder="Filtrele.."
            value={filterInputValue}
            onChange={({ target }) => setFilterInputValue(target.value)}
          />
        </div>
      </div>
      <div className="">
        <DataTable
          columns={columns}
          data={filterInputValue ? filteredActivities : activities}
          selection={{ rowSelectionList, setRowSelectionList }}
          loading={loading}
        />
      </div>
    </>
  );
};

export default ActivitiesList;
