import React, { useEffect, useState } from "react";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import { Formik, useFormik } from "formik";
import DynamicInput from "../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../utils/handleCustomChange";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import Swal from "sweetalert2";
import { createTest, deleteTest, updateTest } from "../../services/axios";
import { nanoid } from "nanoid";

export function CreateTestForm({
  selectedTest,
  isDataChange,
  setIsDataChange,
  isOpen,
  setOpen,
  allTests,
  allUnits,
}) {
  const [upTestOptions, setUpTestOptions] = useState(null);
  const [upTestInitial, setUpTestInitial] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUpCategory, setIsUp] = useState(null); //üst kategori var mı checkbox
  const [isGrupSelected, setisGrupSelected] = useState(
    selectedTest?.test_group_type ? true : false
  );

  const allUnitsForSelect = allUnits?.map((item, index) => ({
    label: item?.title ? item?.title : "-",
    value: item?.id,
  }));

  const handleUpTest = async () => {
    try {
      const createOption = await allTests?.map((item, index) => ({
        label: item.title ? item.title : "-",
        value: item.id,
      }));
      return createOption;
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (allTests) {
      handleUpTest().then((createOption) => {
        setUpTestOptions(
          createOption?.filter((item) => item?.value != selectedTest?.id)
        );
        setUpTestInitial(
          createOption?.find((item) => item.value == selectedTest?.up_category)
        ); //sadece üst kategorileri almak için
        setIsUp(
          createOption?.find((item) => item.value == selectedTest?.up_category)
            ? true
            : false
        ); //checkbox için
      });
    }
  }, [allTests]);
  const initialValues = {
    title: selectedTest?.title ?? "",
    up_category: upTestInitial ?? "",
    test_type:
      [
        { label: "Grup", value: "grup" },
        { label: "Test", value: "test" },
      ]?.filter((item) => item.value == selectedTest?.test_type) ?? "",
    test_group_type:
      [
        { label: "Üst", value: "ust" },
        { label: "Alt", value: "alt" },
      ]?.filter((item) => item.value == selectedTest?.test_group_type) ?? "",
    code: selectedTest?.code ?? nanoid(),
    unit:
      allUnitsForSelect?.filter((item) => item?.value == selectedTest?.unit) ??
      "",
    // teams_unit: selectedTest?.teams_unit ?? "",
    teams_unit: [],
    //   is_active: selectedTest?.is_active ?? "",
    is_active: 1,
  };
  // console.log(upTestInitial, "upTestInitial");
  const {
    errors,
    values,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { resetForm }) => {},
    validate: (values) => {
      const errors = {};

      if (!values.test_group_type) {
        errors.test_group_type = "Grup Tipi alanı boş olamaz";
      }

      // if (!values.userConfirmPassword) {
      //   errors.userConfirmPassword = "Şifre tekrar alanı boş olamaz";
      // } else if (values.userPassword !== values.userConfirmPassword) {
      //   errors.userConfirmPassword = "Şifreler eşleşmiyor";
      // }

      console.log(errors, "hata");
      return errors;
    },
  });

  const onTestTypeHandler = async (
    e1,
    e2,
    test_type,
    setFieldValue,
    select
  ) => {
    handleCustomChange(e1, e2, test_type, setFieldValue, select);
    // console.log("e1e1e1e1e1: ", e1);
    e1.label === "Grup" ? setisGrupSelected(true) : setisGrupSelected(false);
  };
  const onDeleteHandler = async (item) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${item.title}" testini silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTest(item?.id);
        setIsDataChange(true);
        Swal.fire("Silindi!", `"${item.title}" test silindi.`, "success");
      }
    });
  };

  return (
    <>
      {!loading && upTestOptions && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            // console.log(values, "values");
            values.test_group_type = values?.test_group_type?.value;
            values.test_type = values?.test_type?.value;
            values.unit = values?.unit?.value;
            const finalISActive = values.is_active === true ? 1 : 0;
            try {
              let result = null;
              if (selectedTest) {
                result = await updateTest(
                  selectedTest?.id,
                  values?.title,
                  values?.test_type,
                  values?.test_group_type,
                  values?.code,
                  values?.unit,
                  values?.teams_unit,
                  finalISActive,
                  isUpCategory ? values?.up_category?.value : "",
                  isUpCategory ? 1 : 0
                );
                // console.log("resultresultresultupdateTest: ", result);
              } else {
                result = await createTest(
                  values?.title,
                  values?.test_type,
                  values?.test_group_type,
                  values?.code,
                  values?.unit,
                  values?.teams_unit,
                  finalISActive,
                  isUpCategory ? values?.up_category?.value : "",
                  isUpCategory ? 1 : 0
                );
              }

              if (result.success) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: result?.message ?? "Ekleme Başarılı!",
                  showConfirmButton: false,
                  timer: 1000,
                });

                // Set isDataChange to true
                setLoading(false);
                setIsDataChange(true);
                setisGrupSelected(true);
                //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: result?.message ?? "Ekleme Başarısız!",
                  showConfirmButton: false,
                  timer: 1000,
                });
              }
            } catch (error) {
              console.error("Hata oluştu:", error);
            } finally {
              setOpen(false);
            }
          }}
        >
          {({
            errors,
            values,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid gap-4 pt-4">
                <DynamicInput
                  labelTitle={"Test Başlığı"}
                  containerStyle={"w-full"}
                  placeholder={selectedTest?.name ?? "Test Başlığı"}
                  name={"title"}
                  component_type={"input"}
                  value={values}
                  onChange={(e1, e2) => {
                    handleCustomChange(e1, e2, "title", setFieldValue, "input");
                  }}
                  error={errors}
                  isTouched={touched}
                  onBlur={handleBlur}
                />
                <DynamicInput
                  labelTitle={"Üst Kategorisi Var Mı?"}
                  containerStyle={"w-full"}
                  placeholder={"Üst Kategorisi Var Mı?"}
                  value={isUpCategory}
                  onChange={(e1, e2) => {
                    setIsUp(e1.target.checked);
                  }}
                  component_type={"checkbox"}
                />
                {isUpCategory && (
                  <DynamicInput
                    labelTitle={"Üst Kategorisi"}
                    containerStyle={"w-full"}
                    placeholder={"Üst Kategorisi"}
                    name={"up_category"}
                    value={values}
                    onChange={(e1, e2) => {
                      handleCustomChange(
                        e1,
                        e2,
                        "up_category",
                        setFieldValue,
                        "select"
                      );
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                    component_type={"select"}
                    customOptions={upTestOptions}
                  />
                )}

                <DynamicInput
                  labelTitle={"Test Tipi"}
                  containerStyle={"w-full"}
                  placeholder={selectedTest?.test_type ?? "Test Tipi"}
                  name={"test_type"}
                  component_type={"select"}
                  customOptions={[
                    { label: "Grup", value: "grup" },
                    { label: "Test", value: "test" },
                  ]}
                  value={values}
                  onChange={(e1, e2) => {
                    onTestTypeHandler(
                      e1,
                      e2,
                      "test_type",
                      setFieldValue,
                      "select"
                    );
                  }}
                  error={errors}
                  isTouched={touched}
                  onBlur={handleBlur}
                />
                {isGrupSelected && (
                  <DynamicInput
                    required={true}
                    labelTitle={"Test Grubu"}
                    containerStyle={"w-full"}
                    placeholder={selectedTest?.test_group_type ?? "Test Grubu"}
                    name={"test_group_type"}
                    component_type={"select"}
                    customOptions={[
                      { label: "Üst", value: "ust" },
                      { label: "Alt", value: "alt" },
                    ]}
                    value={values}
                    onChange={(e1, e2) => {
                      handleCustomChange(
                        e1,
                        e2,
                        "test_group_type",
                        setFieldValue,
                        "select"
                      );
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                  />
                )}
                {/* <DynamicInput
            labelTitle={"Kod"},
            disabled={true}
            containerStyle={"w-full"}
            placeholder={selectedTest?.surname ?? "Kod"}
            name={"code"}
            component_type={"input"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(e1, e2, "code", setFieldValue, "input");
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
          /> */}
                <DynamicInput
                  labelTitle={"Birim"}
                  containerStyle={"w-full"}
                  placeholder={selectedTest?.surname ?? "Birim"}
                  name={"unit"}
                  component_type={"select"}
                  customOptions={allUnitsForSelect}
                  value={values}
                  onChange={(e1, e2) => {
                    handleCustomChange(e1, e2, "unit", setFieldValue, "select");
                  }}
                  error={errors}
                  isTouched={touched}
                  onBlur={handleBlur}
                />
                {selectedTest && (
                  <>
                    <div className="flex w-full pb-1">
                      <ButtonComponent
                        onClick={() => onDeleteHandler(selectedTest)}
                        text={"Testi Sil"}
                        outline
                        customStyle={"w-fit h-[56px]"}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end gap-4 pt-4">
                <ButtonComponent
                  onClick={() => {
                    setOpen(false);
                    resetForm();
                  }}
                  outline
                  text={"Vazgeç"}
                />
                <ButtonComponent submit fill text={"Kaydet"} />
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}

function CreateTestModal({
  allTests,
  isOpen,
  setOpen,
  setIsDataChange,
  allUnits,
}) {
  return (
    <div>
      <div>
        <CustomModal
          width={677}
          isOpen={isOpen}
          setOpen={setOpen}
          footer={null}
        >
          <CustomModalHeader
            title={"Yeni Sezon Ekleyin"}
            description={"Aşağıdan yeni sezon ekleyin."}
          />
          <CreateTestForm
            allUnits={allUnits}
            allTests={allTests}
            isOpen={isOpen}
            setOpen={setOpen}
            setIsDataChange={setIsDataChange}
          />
        </CustomModal>
      </div>
    </div>
  );
}

export default CreateTestModal;
