import React, { useEffect } from "react";
import CreateFootballGroundModal, {
  CreateFootballGroundForm,
} from "../../containers/modals/CreateFootballGroundModal";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { useState } from "react";
import { getFootballGround } from "../../services/axios";
import { SettingOutlined } from "@ant-design/icons";
import { PulseLoader } from "react-spinners";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import TabbarComponent from "../../components/tabbar";

function FootballGroundsDefinitions() {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isDataAdd, setIsDataChange] = useState(false);
  const [dynamicFormMenuData, setDynamicFormMenuData] = useState([]);
  const [innerMenuSelected, setInnerMenuSelected] = useState(0);
  const [resultGetFootballGround, setResultGetFootballGround] = useState("");
  const [openCreateFootballGroundModal, setOpenCreateFootballGroundModal] =
    useState(false);
  const [selectedTabId, setSelectedTabId] = useState(0);

  const fetchGetFootballGround = async (isActive) => {
    setLoading(true);
    setDynamicFormMenuData([]);
    try {
      const result = await getFootballGround(isActive);
      setResultGetFootballGround(result);
      result.data.footballGrounds.map((item, index) => {
        handleDataPush(item.title, index);
      });
      setLoading(false);
      setIsDataChange(false);
      return result;
    } catch (error) {
      setLoading(false);
      console.log(error, "hata");
    }
  };
  function SelectedForm() {
    return resultGetFootballGround?.data?.footballGrounds
      .filter((filterItem, filterIndex) => filterIndex == innerMenuSelected)
      .map((item, index) => {
        // console.log(index, "***********");
        return (
          <React.Fragment key={index}>
            <CreateFootballGroundForm
              isOpen={isOpen}
              setOpen={setOpen}
              setIsDataChange={setIsDataChange}
              updateId={item.id}
              updateSelectedValuesProp={item}
              innerMenuSelected={innerMenuSelected}
            />
          </React.Fragment>
        );
      });
  }

  useEffect(() => {
    fetchGetFootballGround(
      selectedTabId == 0 ? true : selectedTabId == 1 ? false : null
    );
    setInnerMenuSelected(null);
  }, [isDataAdd, selectedTabId]);

  const handleDataPush = (label, value) => {
    const newItem = getMenuItem(label, value, <SettingOutlined />, null);
    setDynamicFormMenuData((prevData) => [...prevData, newItem]);
  };

  // console.log(resultGetFootballGround, "resultGetFootballGround");
  // console.log(isDataAdd, "change");
  // console.log(innerMenuSelected, "innerMenuSelected");

  // console.log(dynamicFormMenuData, "dynamicFormMenuData");

  return (
    <>
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Inputlar Yükleniyor...</p>
        </div>
      )}


      {!loading && (
        <div className="flex w-full gap-4">
          <div className="flex flex-col justify-between h-full">
            <TabbarComponent
              type="card"
              setSelectedTabId={setSelectedTabId}
              selectedTabId={selectedTabId}
              data={[{ title: "Onaylı" }, { title: "Onaylanmayan" }]}
            />
            <DrawerMenuAntd
              items={dynamicFormMenuData}
              selectedKey={innerMenuSelected}
              setSelectedKey={setInnerMenuSelected}
            />
            <ButtonComponent
              customStyle={"mt-4"}
              text={"Yeni Saha Ekle"}
              outline
              onClick={() =>
                setOpenCreateFootballGroundModal(!openCreateFootballGroundModal)
              }
            />
            <CreateFootballGroundModal
              isOpen={openCreateFootballGroundModal}
              setOpen={setOpenCreateFootballGroundModal}
              setIsDataChange={setIsDataChange}
            />
          </div>
          <SelectedForm />
        </div>
      )}
    </>
  );
}

export default FootballGroundsDefinitions;
