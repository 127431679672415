import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Paths } from "../../../routes";
import { message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import TabbarComponent from "../../../components/tabbar";
import { BsChevronLeft } from "react-icons/bs";
import InputComponent from "../../../components/inputs";
import { PulseLoader } from "react-spinners";
import { TeamFormInputsData } from "../../../utils/datas/teamFormInputsData";

const EditTeam = () => {
  const [setBreadcrumbData] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel",
      },
      {
        title: <Link to={Paths.Teams.Teams}>Oyuncu Listesi</Link>,
      },
      {
        title: <Link to={"#"}>Oyuncu Güncelle</Link>,
      },
    ]);
  }, []);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const [imageLoading, setImageLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const imageHandleChange = (info) => {
    if (info.file.status === "uploading") {
      setImageLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl(url);
        setImageLoading(false);
      });
    }
  };
  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const { errors, values, handleChange, handleSubmit, touched, handleBlur } =
    useFormik({
      initialValues: {
        teamsCode: "1",
        teamsName: "Altınordu FK",
        teamsCategory: "U16",
      },
      onSubmit: (values, { resetForm }) => {
        setLoading(true);
        setTimeout(() => {
          setSaveError("Başarısız!");
          resetForm();
          setLoading(false);
        }, 500);
      },
    });

  const [selectedTabId, setSelectedTabId] = useState(0);
  return (
    <>
      <div className="">
        <TabbarComponent
          data={TeamFormInputsData}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
          type="card"
        />
      </div>
      <div className="w-full p-4 bg-white shadow rounded-b-md">
        <div className="w-full p-4 bg-white rounded-md shadow pb-96">
          <div className="flex items-center text-gray-500">
            <button onClick={() => navigate(-1)}>
              <BsChevronLeft size={21} />
            </button>
            <h4 className="ml-4 text-lg font-semibold">Takım Güncelle</h4>
          </div>

          {/* tabbar */}

          {/* tabbar */}

          <form onSubmit={handleSubmit}>
            {/* inputs */}
            <div className="grid grid-cols-1 gap-12 mt-8 text-primary">
              {TeamFormInputsData.map((item, index) => {
                return (
                  selectedTabId == index && (
                    <div key={index}>
                      <div className="grid w-full gap-4 md:grid-cols-2">
                        {item.inputs.map((itemInputs, indexInputs) => {
                          return (
                            <div key={indexInputs}>
                              <InputComponent
                                labelTitle={itemInputs.labelTitle}
                                id={itemInputs.id}
                                name={itemInputs.name}
                                type={itemInputs.type}
                                onChange={handleChange}
                                value={values}
                                error={errors}
                                isTouched={touched}
                                onBlur={handleBlur}
                                containerStyle={"w-full"}
                                placeholder={itemInputs.placeholder}
                                textarea={itemInputs.textarea}
                                required={itemInputs.required}
dateInput={itemInputs.dateInput}
                                leftIcon={itemInputs.leftIcon}
                                mask={itemInputs.mask}
                                maxLength={itemInputs.maxLength}
                                phoneNumber={itemInputs.phoneNumber}
                                select={itemInputs.select}
                                options={itemInputs.options}
                                onlyNumber={itemInputs.onlyNumber}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )
                );
              })}
              {/* inputs */}
            </div>
          </form>
          <div className="w-full mt-4 text-end">
            <div className="inline-flex items-center gap-4">
              <button
                type="submit"
                className="flex items-center justify-center w-full h-12 px-6 font-medium text-white transition-all rounded-md text-md bg-primary hover:bg-red-500"
                disabled={loading}
              >
                {loading ? <PulseLoader color="white" /> : "Kaydet"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTeam;
