import React, { useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button, Modal } from "antd";

function Table1() {
  //modal
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  //modal
  
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    handleSave()
  };

  const handleSave = () => {
    const content = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    console.log(content);
    // Burada içeriği kaydedebilir veya başka bir işlem yapabilirsiniz.
  };

  function PlusButton() {
    return (
      <p
        onClick={showModal}
        className="flex items-center justify-center w-6 h-6 px-2 mt-4 text-white bg-gray-300 rounded cursor-pointer hover:bg-primary"
      >
        +
      </p>
    );
  }
  return (
    <>
      <Modal
        width={1000}
        open={open}
        title="Madde Ekle"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            danger
            type="default"
            loading={loading}
            onClick={handleCancel}
          >
            İptal
          </Button>,
          <Button
            href={null}
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Kaydet
          </Button>,
        ]}
      >
        <div className="border">
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
          />
        </div>
      </Modal>
      <div className="grid grid-cols-6 w-[2000px]">
        {[...Array(6)].map((item, index) => {
          return (
            <>
              <div className="flex flex-col [&>*]:border [&>*]:p-2">
                <p className="font-semibold text-center text-primary">
                  Pazartesi{" "}
                  <span className="text-xs font-normal text-black">
                    (maç + 2)
                  </span>
                </p>
                <p className="font-semibold text-center text-primary">
                  20/03/2023
                </p>
                <p className="italic font-semibold text-center">ÖĞLEDEN ÖNCE</p>
                <p className="font-semibold text-center text-white bg-primary">
                  KUVVET 45’
                </p>
                {/* bölümler */}
                <div className="flex flex-col gap-1">
                  <p className="font-semibold bg-gray-100 text-primary">
                    Bölüm - 1
                  </p>
                  <p className="text-sm font-medium text-primary">
                    KALİSTENİKLER / STRETCH / STRETCHING – 10’
                  </p>
                  <p className="text-xs">
                    Adaptasyon amaçlı germe ve gerdirmeler ile düşük salınımlı
                    balistikler
                  </p>
                  <PlusButton />
                </div>
                {/* bölümler */}
                {/* bölümler */}
                <div className="flex flex-col gap-1">
                  <p className="font-semibold bg-gray-100 text-primary">
                    Bölüm - 2
                  </p>
                  <p className="text-sm font-medium text-primary">
                    ADAPTİF AKSELERASYON – 6’
                  </p>
                  <p className="text-xs">8 x 60 m x % 60 tempo</p>
                  <p className="text-sm font-medium text-primary">TAD 45-60”</p>
                  <PlusButton />
                </div>
                {/* bölümler */}
                {/* bölümler */}
                <div className="flex flex-col gap-1">
                  <p className="font-semibold bg-gray-100 text-primary">
                    Bölüm - 3
                  </p>
                  <p className="text-sm font-medium text-primary">
                    TAKTİKSEL OYUN – 30-32’
                  </p>
                  <p className="text-xs">Orta Genişlik alan Oyunu</p>
                  <p className="text-xs">RPE oyunda 7/10 – 8/10</p>
                  <p className="text-xs">RPE toparlanmada 4/10 – 5/10 </p>
                  <div className="flex flex-col gap-1 mt-4">
                    <p className="text-xs">
                      5v5v5 (1:2:2) ya da 6v6v6 (1:2:1:2) ya da 7v7 (1:3:1:2 /
                      1:3:2:1) ya da 8v8 (1:2:4:1)
                    </p>
                    <div className="">
                      <p className="text-sm font-medium text-primary">
                        3 Takımlılar için
                      </p>
                      <p className="text-xs">
                        3’ x 4 seri aralıksız Dışarıdakiler Germe/Gerdirme
                      </p>
                    </div>
                    <div className="">
                      <p className="text-sm font-medium text-primary">
                        2 Takımlılar için
                      </p>
                      <p className="text-xs">6’x 4 seri aralıksız SAD 2’</p>
                    </div>
                  </div>

                  <PlusButton />
                </div>
                {/* bölümler */}
                {/* bölümler */}
                <div className="flex flex-col gap-1">
                  <p className="font-semibold bg-gray-100 text-primary">
                    Bölüm - 4
                  </p>
                  <p className="text-sm font-medium text-primary">
                    Yaygın Kısa İnterval – 20’
                  </p>
                  <p className="text-sm font-medium text-primary">MAS % 95</p>
                  <p className="text-xs">20”x 10 tekrar, TAD 20”</p>
                  <p className="text-xs">SAD 2’ – 3’</p>
                  <p className="text-xs">20”x 9 tekrar, TAD 20”</p>
                  <p className="text-xs">SAD 2’ – 3’</p>
                  <p className="text-xs">20”x 9 tekrar, TAD 20”</p>

                  <PlusButton />
                </div>
                {/* bölümler */}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default Table1;
