import React, { useEffect, useState } from "react";
import UpcomingMatches from "../../containers/sections/UpcomingMatches";
import { useOutletContext } from "react-router-dom";
import Select from "react-select";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import CustomModal from "../../components/modals";
import FixtureMatchFields from "./FixtureMatchFields";
import {
  getAllClub,
  getFixtureMatches,
  getLeagues,
  getSeasons,
  listTeams
} from "../../services/axios";
import dayjs from "dayjs";
import { PulseLoader } from "react-spinners";
import { handleCustomChange } from "../../utils/handleCustomChange";
import DynamicInput from "../../components/inputs/dynamicInput";
import AddBulkMatchesFields from "./AddBulkMatchesFields";

const Fixtures = () => {
  const [setBreadcrumbData] = useOutletContext();
  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel"
      },
      {
        title: "Fikstür"
      }
    ]);
  }, []);

  const [loading, setLoading] = useState(false);
  const [isDataChange, setIsDataChange] = useState(false);

  const [addMatchesModalOpen, setAddMatchesModalOpen] = useState(false);
  const [addBulkMatchesModalOpen, setAddBulkMatchesModalOpen] = useState(false);
  const [matches, setMatches] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [weeks, setWeeks] = useState([
    { label: "Tümü", value: null },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
    { label: "13", value: 13 },
    { label: "14", value: 14 },
    { label: "15", value: 15 }
  ]);
  const [dataFilters, setDataFilters] = useState({
    week: null,
    clubId: null
  });
  const [leagueOptions, setLeagueOptions] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState({});

  const fetchGetLeagues = async () => {
    setLoading(true);
    try {
      const result = await getLeagues(1);
      setLeagueOptions([]);
      setLeagueOptions((prevState) => [
        ...prevState,
        ...result?.data.data.map((item) => ({
          label: item?.name + " | " + item?.season.season_title,
          value: item?.id
        }))
      ]);
      setSelectedLeague({
        league: {
          label: result?.data.data[0]?.name,
          value: result?.data.data[0]?.id
        }
      });
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchGetMatches = async (clubId, week) => {
    setLoading(true);
    try {
      if (selectedLeague?.league?.value === undefined) return false;
      const result = await getFixtureMatches(
        selectedLeague?.league?.value,
        clubId,
        week
      );
      if (result.success === true) {
        setMatches([]);
        setMatches(
          result?.response.matches.map((item) => ({
            id: item.id,
            home_team: item.home_team?.club_name ?? (
              <p className="text-primary">Kulüp Bulunamadı!</p>
            ),
            home_team_image: item.home_team?.image,
            guest_team: item.guest_team?.club_name ?? (
              <p className="text-primary">Kulüp Bulunamadı!</p>
            ),
            guest_team_image: item.guest_team?.image,
            home_team_score: item.home_team_score,
            guest_team_score: item.guest_team_score,
            match_date: dayjs(item.match_date).format("DD MMMM YYYY"),
            coach_comment: item.coach_comment,
            goal_keeper_coach_comment: item.goal_keeper_coach_comment,
            team: item.team?.team_title ?? ""
          }))
        );
      } else {
        console.log("hata");
      }
      setLoading(false);
    } catch (error) {
      console.log("hata", error);
    }
  };
  const fetchGetClubs = async () => {
    try {
      const result = await getAllClub();
      setClubs([
        {
          label: "Tümü",
          value: null
        }
      ]);
      setClubs((prevState) => [
        ...prevState,
        ...result?.response.clubs.map((item, index) => ({
          label: item.club_name,
          value: item.id
        }))
      ]);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const handleFilters = (e, name) => {
    setDataFilters((prevState) => ({
      ...prevState,
      [name]: e.value
    }));
  };

  useEffect(() => {
    fetchGetLeagues();
  }, []);

  useEffect(() => {
    if (selectedLeague) {
      if (Object.keys(selectedLeague).length > 0) {
        fetchGetMatches(dataFilters.clubId, dataFilters.week);
      }
    }
  }, [selectedLeague]);

  useEffect(() => {
    if (dataFilters) {
      fetchGetMatches(dataFilters?.clubId, dataFilters?.week);
    }
  }, [dataFilters]);

  useEffect(() => {
    if (isDataChange) {
      fetchGetMatches(dataFilters?.clubId, dataFilters?.week);
    }
  }, [isDataChange]);

  useEffect(() => {
    fetchGetClubs();
  }, []);

  return (
    <div className="w-full p-5 py-3 bg-white shadow-xl rounded-xl border-t-primary focus:outline-none">
      <div className="flex items-center justify-between">
        <h1 className="px-4 py-4 text-3xl text-primary">Fikstür Maçları</h1>
        <div className={"flex gap-3"}>
          <DynamicInput
            labelTitle={"Lig Seçiniz"}
            containerStyle={"w-[450px]"}
            placeholder={"Lig Seçiniz"}
            name={"league"}
            value={selectedLeague}
            onChange={(e) => {
              setSelectedLeague({ league: e });
            }}
            component_type={"select"}
            disabled={leagueOptions <= 0}
            customOptions={leagueOptions}
          />
        </div>
      </div>
      <div className="flex flex-wrap-reverse items-center justify-between px-2 py-2 my-4 bg-gray-100 rounded-lg gap-3">
        <div className="flex flex-wrap-reverse items-center w-full sm:w-auto gap-3">
          <div className="" style={{ minWidth: "150px" }}>
            <span className="w-full text-sm text-gray-500">Kulüp:</span>
            <Select
              placeholder={"Kulüp Seçiniz"}
              isDisabled={clubs.length <= 1}
              options={clubs}
              value={clubs.find((club) => club.value === dataFilters.clubId)}
              onChange={(e) => {
                handleFilters(e, "clubId");
              }}
            />
          </div>
          <div className="" style={{ minWidth: "150px" }}>
            <span className="w-full text-sm text-gray-500">Hafta:</span>
            <Select
              placeholder={"Hafta Seçiniz"}
              options={weeks}
              value={weeks.find((week) => week.value === dataFilters.week)}
              onChange={(e) => handleFilters(e, "week")}
            />
          </div>
        </div>
        <div className="flex flex-wrap-reverse items-center w-full sm:w-auto gap-3">
          <div className="">
            <ButtonComponent
              onClick={() => {
                setAddBulkMatchesModalOpen(true);
              }}
              text={"Toplu Maç Ekle"}
              outline
            />
            <CustomModal
              width={"1000px"}
              isOpen={addBulkMatchesModalOpen}
              setOpen={setAddBulkMatchesModalOpen}
              footer={null}
            >
              <AddBulkMatchesFields
                leagueOptions={leagueOptions}
                clubOptions={clubs}
                setModalIsOpen={setAddBulkMatchesModalOpen}
                setIsDataChange={setIsDataChange}
              />
            </CustomModal>
          </div>
          <div className="">
            <ButtonComponent
              onClick={() => setAddMatchesModalOpen(true)}
              text={"Maç Ekle"}
              outline
            />
            <CustomModal
              width={677}
              isOpen={addMatchesModalOpen}
              setOpen={setAddMatchesModalOpen}
              footer={null}
            >
              <FixtureMatchFields
                action={"ADD"}
                modalIsOpen={setAddMatchesModalOpen}
                setIsDataChange={setIsDataChange}
                leagueOptions={leagueOptions}
                selectedLeague={selectedLeague}
              />
            </CustomModal>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      ) : (
        <UpcomingMatches matches={matches} />
      )}
    </div>
  );
};

export default Fixtures;
