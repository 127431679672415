import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Paths } from "../../routes";

import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import trLocale from "@fullcalendar/core/locales/tr";
import Select from "react-select";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import WarningNotification from "../../components/notifications/WarningNotification";
import { Popconfirm } from "antd";
import {
  CalendarFilled,
  EditOutlined,
  FileSearchOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import AlertDialogDelete from "../../components/alertDialogDelete";
import CustomModal from "../../components/modals";
import AddActivitiesForm from "../../pages/activities/addActivities/addActivitiesForm";
import moment from "moment";
import {
  baseImageURL,
  baseURL,
  deleteActivity,
  getAllUser,
  getApprovedActivities,
  getStoredBearerToken,
  listTeams
} from "../../services/axios";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import TabbarComponent from "../../components/tabbar";
import { MdOutlineMan3, MdOutlineQuiz, MdOutlineStadium, MdPlayForWork, MdQuiz } from "react-icons/md";
import { BiFootball, BiParagraph, BiRun, BiTime } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { BsCalendar3 } from "react-icons/bs";
import PageTitle from "../../components/pageTitle";
import { DataTable } from "../../components/tables";
import AddActivitiesTestForm from "../../pages/activities/addActivitiesTest/addActivitiesTestForm";
import MatchesFields from "../../pages/matches/matchesFields";
import DynamicInput from "../../components/inputs/dynamicInput";
import axios from "axios";

const events = [{ title: "Meeting", start: new Date() }];

const Calendar = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeamCategory, setSelectedTeamCategory] = useState(null);
  const [setBreadcrumbData] = useOutletContext();
  const [newEventData, setNewEventData] = useState(null);
  const [calendarInfo, setCalendarInfo] = useState(null);
  const [saveIt, setSaveIt] = useState(null);
  const [events, setEvents] = useState(null);
  const [isDataChange, setIsDataChange] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedTabId, setSelectedTabId] = useState("");
  const [selectedActivityGroup, setSelectedActivityGroup] = useState("");
  const [userDataTableData, setUserDataTableData] = useState([]);
  const [teamCategoryOptions, setTeamCategoryOptions] = useState([]);
  const [selectTeamPlayers, setSelectTeamPlayers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedTitleTabId, setSelectedTitleTabId] = useState("")
  const [selectedActivityType, setSelectedActivityType] = useState(false);
  const [mertData, setMertData] = useState([])

  // const bakbi = async() => {
  //   const result = await axios.get(`${baseURL}/football-ground/facilities`, {
  //     headers: {
  //       Authorization: `Bearer ${getStoredBearerToken}`
  //   }
  //   })   
  //   console.log("REZALT", result)
  // }
  // useEffect(() => {
  //   bakbi()
  // }, [])

  useEffect(() => {
    if (isDataChange) {
      fetchGetUsers().then((dataTableData) => {
        setUserDataTableData(
          dataTableData?.data?.users?.map((item) => ({
            key: item?.id,
            name: item?.user_name,
            tckn: item?.tckn
          }))
        );
      });
      fetchGetApprovedActivities().then((result) => {
        // const _events = (selectedTabId //düzenle burayı sil
        //     ? result?.filter(
        //         (item) => item?.allProps?.team_category?.id == selectedTabId
        //     )
        //     : result);
        // _events.forEach((event) => {
        //   if (event?.allProps?.activities?.length > 2) {
        //     event.allProps.activities = event.allProps.activities.slice(0, 2); //takvimde alt alta listelenen aktiviteleri kısıtladık
        //   }
        // });
          setEvents(selectedTabId
            ? result?.filter(
                (item) => item?.allProps?.team_category?.id == selectedTabId
            )
            : result);
        // else if (selectedTitleTabId) {
        //   console.log("selectedTitle", selectedTitleTabId)
        //   setEvents(selectedTitleTabId
        //     ? result?.filter(
        //         (item) => item?.allProps?.id == selectedTitleTabId
        //     )
        //     : result);
        // }
      });
    }
    // selectedTitleTabId
  }, [isDataChange, selectedTabId]);

  const fetchGetUsers = async () => {
    setLoading(true);
    try {
      const result = await getAllUser();
      const dataTableData = result.data.users.map((item, index) => ({
        key: item.id,
        name: item.user_name,
        tckn: item.tckn
      }));
      return result;
    } catch (error) {
      console.error("hata", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchGetApprovedActivities = async () => {
    setLoading(true);
    try {
      const result = await getApprovedActivities();
      console.log(result, "getApprovedActivities");
      const activityTypes = result?.data?.activities.map(
        (item) => item?.activity_type
      );
      const calendarFormatResult = result?.data?.activities.map(
        (item, index) => (
          console.log(item, "calendarFormatResult"),
          {
            start: item?.start_date + " " + item?.start_time,
            end: item?.end_date + " " + item?.end_time,
            title:
              item?.activity_type === "mac"
                ? item?.activity_match?.match?.home_team?.club_name + " - " + item?.activity_match?.match?.guest_team?.club_name
                : item?.activity_type === "faaliyet"
                ? item?.activities[0]
                  ? item?.activities[0].title
                  : "Henüz faaliyete aktivite eklenmemiş."
                : item?.activity_related_test[0]
                ? item?.activity_related_test[0].tests.title
                : "-", //item?.activities[0] ? item?.activities[0].title : "-"
            id: item?.id,
            color: item?.color,
            approval: item?.is_approved,
            allProps: item
          }
        )
      );

      return calendarFormatResult;
    } catch (error) {
      console.log(error, "err");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  const fetchDeteleActivity = async (id) => {
    try {
      const result = await deleteActivity(id);
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setIsDataChange(true);
    }
  };

  const handleSelect = (info) => {
    setNewEventData(info);
    const { start, end } = info;
    console.log(info, "takvim kutu tıklama");
    // const eventNamePrompt = prompt("Etkinlik Giriniz");
    console.log(events, "events"); //yeni event eklendiğinde tüm faaliyetler
    setIsModalOpen(true); //modal'ı açar
    // if (true) {
    //   setEvents([
    //     ...events,
    //     {
    //       start,
    //       end,
    //       title: "EKLENİYOR...",
    //       id: Math.floor(Math.random() * 5),
    //     },
    //   ]);
    // }
  };
  const handleChange = (info) => {
    const updatedEvents = [];
    const newEvent = {
      start: info.event.start,
      end: info.event.end,
      title: info.event.title,
      id: Number(info.oldEvent.id),
      color: info.oldEvent.color
    };

    events.map((item, index) =>
      item.id === newEvent.id
        ? updatedEvents.push(newEvent)
        : updatedEvents.push(item)
    );
    setEvents(updatedEvents);
  };

  function handleDelete(item) {
    console.log(item._def, "omer");
    Swal.fire({
      title: `${item._def?.title} silinecek emin misiniz?`,
      text: "Bu işlemi geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "Vazgeç"
    }).then((result) => {
      if (result.isConfirmed) {
        // Silme işlemini gerçekleştir
        fetchDeteleActivity(item._def?.publicId);
        Swal.fire(
          `${item?.title} Silindi!`,
          "Seçtiğiniz öğe başarıyla silindi.",
          "success"
        );
      }
    });
  }
  const handleEventContent = (info) => {
    setCalendarInfo(info);
    return (
      <>
        {console.log(info, "event detay")}
        {/* Diğer içerik */}
      </>
    );
  };

  const fetchGetAllCategory = async () => {
    try {
      const result = await listTeams();
      return result?.data?.teams;
    } catch (error) {
      console.log(error, "err");
    }
  };
  // const [titleCategoryOptions, setTitleCategoryOptions] = useState([])
  
  useEffect(() => {
    fetchGetAllCategory().then((teams) =>
      setTeamCategoryOptions([
        { label: "Tüm Takımların Faaliyetleri", value: "" }, // Başlangıç öğesi
        ...teams.map((item) => ({
          label: item?.team_title,
          value: item?.id,
          players: item?.players
        }))
      ])
    );
      // setTitleCategoryOptions([
      //   {label: "Bak", value: ""},
      //   ...mertData.map((item) => ({
      //     label: item?.allProps.mainTitle,
      //     value: item?.allProps.id
      //   }))
      // ])
  }, []);

// const onChangeTitleSelect = (selected) => {
//   console.log("selval", selected.value)
//   setSelectedTitleTabId(selected.value)
//   setIsDataChange(true)
// }
  const onChangeTeamSelect = (selected) => {
    console.log("selectTeamPlayersselected: ", selected.value);
    const teamPlayersMap = selected?.players?.map((item) => item.player);
    setSelectedTabId(selected.value);
    setIsDataChange(true);
    const teamPlayersDataTableFormat = teamPlayersMap?.map((item) => ({
      key: item?.id,
      name: item?.user_name,
      tckn: item?.tckn
    }));

    setSelectTeamPlayers(teamPlayersDataTableFormat);
  };
  return (
    <>
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      {/* setSelectedTabId */}
      <div>
        <div className="flex items-center text-gray-500">
          <h4 className="text-lg font-semibold">Takvim</h4>
        </div>
        <div className="mt-4">
          <WarningNotification
            title={"Onay Bekleyen Faaliyetleri Gör"}
            descriptions="Faaliyetleri onaylamak için 'Kontrol Et'e tıklayınız!"
            okLabel="Kontrol Et"
            to={Paths.Activities.ApprovalActivities}
            // cancelLabel="Daha Sonra"
          />
        </div>
        <div className="relative z-50 flex flex-col items-start justify-start w-full gap-2 py-4 pb-8">
          <div className="flex items-center w-full max-w-[400px] gap-4">
            <DynamicInput
              labelTitle={"Kategori"}
              containerStyle={"w-full"}
              placeholder={"Kategori Seçiniz"}
              name={"team_category"}
              value={selectedCategory}
              onChange={(e1, e2) => {
                setSelectedCategory(e1);
                onChangeTeamSelect(e1);
              }}
              component_type={"select"}
              customOptions={teamCategoryOptions ?? userDataTableData ?? []}
            />
            {/* <DynamicInput
              labelTitle={"başlık Seç"}
              containerStyle={"w-full"}
              placeholder={"Başlık Seçiniz"}
              name={"mainTitle"}
              value={selectedCategory}
              onChange={(e1, e2) => {
                setSelectedCategory(e1);
                onChangeTitleSelect(e1);
              }}
              component_type={"select"}
              customOptions={titleCategoryOptions ?? userDataTableData ?? []}
            /> */}

            {/* takvimde sadece onaylılar görüntüleneceği için gerek yok */}
            {/* <TabbarComponent
                type="button"
                setSelectedTabId={setSelectedTabId}
                selectedTabId={selectedTabId}
                data={[{ title: "Onaylanmayan" }, { title: "Onaylanan" }]}
              /> */}
          </div>
        </div>
        <div className="grid grid-cols-3">
          <div className="w-[16px] h-[16px] border rounded-full bg-red-500 flex items-center">
            <div className="ms-2 pl-4">Aktivite</div>

            <div className="pl-[20px]">
              <div className="w-[16px] h-[16px] border rounded-full bg-green-500 flex items-center">
                <div className="ms-2 pl-4 ">Maç</div>
              </div>
            </div>
            <div className="pl-[60px]">
              <div className="w-[16px] h-[16px] border rounded-full bg-blue-500 flex items-center">
                <div className="ms-2 pl-4 ">Test</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!loading && (
        <div className="zoom-none">
          <div className="">
            <FullCalendar
              slotDuration="00:03:00"
              editable={false}
              selectable
              longPressDelay={3}
              select={handleSelect}
              events={events}
              height={"auto"}
              eventContent={(info) => (
                <div
                  style={{
                    width: "100%",
                    borderRadius: "0.75rem",
                    borderWidth: "1px",
                    borderColor: info.event._def.extendedProps.allProps.color
                  }}
                >
                  {console.log(info, "event detay")}
                  {console.log(
                    info.event._def.extendedProps.allProps,
                    "def event detay"
                  )}
                   {/* etkinlik onaylı mı? */}
                   {info.event._def.extendedProps.approval ? (
                    <Popconfirm
                      title="Yapılacak İşlemi Seçiniz"
                      description="Etkinliği inceleyebilir veya güncelleyebilirsiniz."
                      okText={
                        selectedActivityType === "mac" ? "" : "Güncelleme yap"
                      }
                      okButtonProps={{
                        icon: selectedActivityType === "mac" ? <FaTimes className="pt-1 text-red-600"/> : <EditOutlined/>,
                        type: "default",
                        danger: false,
                      }}
                      cancelText="Sil"
                      cancelButtonProps={{
                        icon: <FileSearchOutlined />,
                        type: "default",
                        danger: true,
                      }}
                      onConfirm={() => {
                        info.event._def.extendedProps.allProps?.activity_type ==
                          "faaliyet" &&
                          navigate(
                            Paths.Activities.EditActivities +
                              info.event._def.publicId
                          );
                        info.event._def.extendedProps.allProps?.activity_type ==
                          "test" &&
                          navigate(
                            Paths.Activities.EditTest + info.event._def.publicId
                          );
                      }}
                      onCancel={() => handleDelete(info.event)}
                    >
                      <div
                        className="w-full p-1 rounded-md cursor-pointer"
                        onClick={() => {
                          //typea göre ayrım yapmalısı mert !!!!
                          navigate(Paths.Activities.EditActivities + info.event._def.extendedProps.allProps.id);
                          console.log(info.event._def.extendedProps.allProps, "info, event clicked!");

                          // setSelectedActivityType(info.event._def.extendedProps.allProps.activity_type);
                          // console.log(info, "info, event clicked!");
                          // console.log(
                          //   info.event._def.extendedProps.allProps,
                          //   "selected event custom data"
                          // );
                        }}
                      >
                        <div className="flex flex-col w-full gap-2 p-1 rounded-lg border-inherit">
                          <div className="flex items-center justify-start gap-2">
                            {/* <AiFillCheckCircle className="text-xl" /> */}
                            <p className="text-sm">{info.event._def.extendedProps.allProps?.mainTitle} {console.log(info.event._def.extendedProps.allProps, "123123123net")}</p>
                          </div>
                          {info.event._def.extendedProps.allProps?.activity_match ? (
                              <div className="text-sm flex flex-col">
                                <div className="text-sm flex flex-col pb-1">
                                  <div className="flex items-center gap-1.5">
                                  <span className="text-xs">Başlama Saati:</span>
                                  <span className="text-xs font-semibold">{info.event._def.extendedProps.allProps.activity_match.match.created_at.substr(11, 5)}</span>
                                  </div>
                                  <span className="text-xs mt-1">Ev Sahibi:</span>
                                  {info.event._def.extendedProps.allProps.activity_match.match.home_team.club_name === 'Altınordu FK' ? (
                                    <span className="text-red-500 font-semibold">
                                      {info.event._def.extendedProps.allProps.activity_match.match.home_team.club_name}
                                    </span>
                                    ): 
                                    <span className="">
                                    {info.event._def.extendedProps.allProps.activity_match.match.home_team.club_name}
                                  </span>
                                    }
                                </div> 
                                <hr className="" />
                                <span className="py-1">vs</span>
                                <hr className="pb-1" />
                                <div className="text-sm flex flex-col">
                                  <span className="text-xs">Deplasman:</span>
                                  {info.event._def.extendedProps.allProps.activity_match.match.guest_team.club_name === 'Altınordu FK' ? (
                                    <span className="text-red-500 font-semibold">
                                      {info.event._def.extendedProps.allProps.activity_match.match.guest_team.club_name}
                                    </span>
                                    ): 
                                    <span className="">
                                    {info.event._def.extendedProps.allProps.activity_match.match.guest_team.club_name}
                                  </span>
                                    }
                                </div>
                              </div>
                            ) : ""}

                          {/* {info.event._def.extendedProps.allProps?.activity_match.length > 0 && (
                            <div></div>
                          )} */}
                          {/* aktiviteler */}
                          {info.event._def.extendedProps.allProps?.activities
                            .length > 0 && (
                            <div className="flex flex-col p-1 text-xs rounded-md">
                              <h1 className="flex items-center gap-1 pb-2 font-medium">
                                <BiFootball />
                                Aktiviteler
                              </h1>
                              {info.event._def.extendedProps.allProps?.activities.map(
                                (item, index) => {
                                  if (index === 1){
                                    return (<>
                                      <div className={"mt-3"}>
                                        <div className={"mt-3"}><span className={"font-bold"}>{info.event._def.extendedProps.allProps?.activities.length - 1} aktivite</span> daha var..</div>
                                      </div>
                                    </>)
                                  }
                                  if (index > 1){
                                    return null;
                                  } else{
                                    return (
                                        <>
                                          <div className="flex flex-col py-2 border-t border-inherit">
                                            <div className="flex items-center gap-1 text-base">
                                              <BiParagraph />{" "}
                                              <span className="w-full text-xs">
                                            {item?.title}
                                          </span>
                                            </div>
                                            <div className="flex flex-col gap-1 pl-2 mt-2 ml-2 text-base font-light border-l">
                                              <div className="flex items-center gap-1 text-xs">
                                                <BiTime /> {item?.time} DK
                                              </div>
                                              <div className="flex items-center gap-1 text-xs">
                                                <MdOutlineStadium />{" "}
                                                {item?.movement_field?.title} 
                                                {console.log("mertaldo", info.event._def.extendedProps.allProps)}
                                              </div>
                                              <div>
                                                {info.event._def.extendedProps.allProps?.mainTitle == 'Takım Antrenmanları' ?
                                              <div className="text-xs flex items-center gap-1.5">
                                                <MdOutlineMan3 />
                                                {/* {info.event._def.extendedProps.allProps?.team_category.team_title} */}
                                              </div>
                                              :
                                              ""  
                                              }
                                              </div>
                                              <Link
                                                  target="_blank"
                                                  to={
                                                      baseImageURL + item?.drill?.image
                                                  }
                                                  className="flex items-center gap-1 text-xs"
                                              >
                                                <BiRun />{" "}
                                                <u>{item?.drill?.title}</u>
                                              </Link>
                                            </div>
                                          </div>
                                        </>
                                    );
                                  }
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </Popconfirm>
                  ) : (
                    <Popconfirm
                      title="Faaliyet onaya gönderilidi."
                      description="Faaliyeti düzenleyebilir veya onaya gönderimini iptal edebilirsiniz."
                      okText={"Tamam"}
                      okButtonProps={{
                        icon: <EditOutlined />,
                        type: "default",
                        danger: false,
                      }}
                      cancelText="Onaya gönderimi iptal Et"
                      cancelButtonProps={{
                        icon: <FileSearchOutlined />,
                        type: "default",
                        danger: true,
                      }}
                      onConfirm={() => {
                        // navigate(
                        //   Paths.Activities.EditActivities +
                        //     info.event._def.extendedProps.key
                        // );
                        console.log(info.event._def, "info.event._def");
                      }}
                      onCancel={() => handleDelete(info.event)}
                    >
                      <div
                        className="p-1 rounded-md cursor-pointer"
                        onClick={() => {
                          console.log(info, "info, event clicked!");
                          console.log(
                            info.event._def.extendedProps,
                            "event custom data"
                          );
                        }}
                      >
                        {/* Event üzerine tıklandığında */}
                        <div className="flex items-center justify-start gap-2">
                          <AiFillWarning className="text-xl" />
                          <p>{info.event.title}</p>
                        </div>
                      </div>
                    </Popconfirm>
                  )}
                </div>
              )}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                listPlugin,
                bootstrapPlugin,
                interactionPlugin,
              ]}
              initialView="dayGridMonth"
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,listWeek",
              }}
              views={{
                dayGridMonth: { buttonText: "Ay" },
                timeGridWeek: { buttonText: "Hafta" },
                listWeek: { buttonText: "Liste" },
              }}
              // initialView="dayGridWeek"
              locale={trLocale}
              eventChange={handleChange}
              themeSystem="default"

              // slotLabelFormat={{
              //   hour: 'numeric',
              //   minute: '2-digit',
              //   omitZeroMinute: false,
              //   meridiem: 'short',
              // }}
              // slotLabelContent={handleSlotLabelFormat}
            />
          </div>
          <CustomModal
            width={1000}
            isOpen={isModalOpen}
            setOpen={setIsModalOpen}
            footer={null}
          >
            <div className="">
              <PageTitle bg title={"Aşağıdaki seçeneklerden birini seçiniz"} />
              <div className={`grid grid-cols-3 gap-12 text-2xl`}>
                <div
                  onClick={() => {
                    setSelectedActivityGroup("faaliyet");
                  }}
                  className={`flex flex-col items-center justify-center gap-1 py-4 border border-dashed cursor-pointer rounded-2xl ${
                    selectedActivityGroup == "faaliyet"
                      ? "border-primary text-primary"
                      : "border-black hover:border-primary hover:text-primary"
                  }`}
                >
                  <BsCalendar3 className="text-4xl" />
                  <p>Faaliyet Ekle</p>
                </div>
                <div
                  onClick={() => {
                    setSelectedActivityGroup("test");
                  }}
                  className={`flex flex-col items-center justify-center gap-1 py-4 border border-dashed cursor-pointer rounded-2xl ${
                    selectedActivityGroup == "test"
                      ? "border-primary text-primary"
                      : "border-black hover:border-primary hover:text-primary"
                  }`}
                >
                  <MdOutlineQuiz className="text-4xl" />
                  <p>Test Ekle</p>
                </div>
                <div
                  onClick={() => {
                    setSelectedActivityGroup("mac");
                  }}
                  className={`flex flex-col items-center justify-center gap-1 py-4 border border-dashed cursor-pointer rounded-2xl ${
                    selectedActivityGroup == "mac"
                      ? "border-primary text-primary"
                      : "border-black hover:border-primary hover:text-primary"
                  }`}
                >
                  <BiFootball className="text-4xl" />
                  <p>Maç Ekle</p>
                </div>
              </div>
            </div>
            {selectedActivityGroup == "faaliyet" && (
              <AddActivitiesForm
                newEventData={newEventData}
                setSaveIt={setSaveIt}
                setIsModalOpen={setIsModalOpen}
                isDataChange={isDataChange}
                setIsDataChange={setIsDataChange}
                userDataTableData={userDataTableData}
              />
            )}
            {selectedActivityGroup == "test" && (
              <AddActivitiesTestForm
                newEventData={newEventData}
                setSaveIt={setSaveIt}
                setIsModalOpen={setIsModalOpen}
                isDataChange={isDataChange}
                setIsDataChange={setIsDataChange}
                userDataTableData={userDataTableData}
              />
            )}
            {selectedActivityGroup == "mac" && (
              <MatchesFields
                isUpdate={false}
                setIsDataChangeList={setIsDataChange}
                isDataChangeList={isDataChange}
                newEventData={newEventData}
                isActivity={true}
              />
            )}
          </CustomModal>
        </div>
      )}
    </>
  );
};

export default Calendar;