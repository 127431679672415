import React from "react";

const Profile = () => {
    return (
        <div>
            Profile
        </div>
    );
}

export default Profile;