import React, {useEffect, useState} from "react";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import {
    getMatchCriterias, updateMatchScore,
} from "../../../services/axios";
import DynamicInput from "../../../components/inputs/dynamicInput";
import {handleCustomChange} from "../../../utils/handleCustomChange";
import {Formik} from "formik";
import Swal from "sweetalert2";


function MatchScoreChange({
                              id,
                              matchData,
                              setIsDataChange
                          }) {
    const [loading, setLoading] = useState(false);
    return (
        <>
            <p className="px-6 py-3 text-2xl font-semibold text-secondary">
                Maç Skoru Düzenle
            </p>
            <hr/>
            <div>
                <Formik
                    initialValues={{
                        match: id,
                        home_team_score: matchData.home_team_score,
                        guest_team_score: matchData.guest_team_score
                    }}
                    onSubmit={async (values) => {
                        try {
                            setLoading(true);
                            const result = await updateMatchScore(id, values.home_team_score, values.guest_team_score);
                            if (result.success === true) {
                                Swal.fire({
                                    position: "center",
                                    icon: "success",
                                    title: "Skor Durumu Düzenlendi!",
                                    showConfirmButton: false,
                                    timer: 1000,
                                });
                                setIsDataChange(true);
                            } else {
                                console.log("error");
                            }
                        } catch (error) {
                            console.log(error, "error");
                        } finally {
                            setLoading(false);
                        }
                    }}
                >
                    {({
                          errors,
                          values,
                          handleChange,
                          handleSubmit,
                          touched,
                          handleBlur,
                          setFieldValue,
                          resetForm,
                      }) => (
                        <form onSubmit={handleSubmit} className="flex flex-col w-full">
                            <div className=" grid gap-4 xl:grid-cols-2 p-6">
                                <DynamicInput
                                    labelTitle={"Ev Sahibi Gol Sayısı"}
                                    containerStyle={"w-full"}
                                    placeholder={"Ev Sahibi Gol Sayısı"}
                                    name={"home_team_score"}
                                    value={values}
                                    onChange={(e1, e2) => {
                                        handleCustomChange(
                                            e1,
                                            e2,
                                            "home_team_score",
                                            setFieldValue,
                                            "input"
                                        );
                                    }}
                                    error={errors}
                                    isTouched={touched}
                                    onBlur={handleBlur}
                                    type={"number"}
                                    component_type={"input"}
                                />
                                <DynamicInput
                                    labelTitle={"Deplasman Gol Sayısı"}

                                    containerStyle={"w-full"}
                                    placeholder={"Deplasman Gol Sayısı"}
                                    name={"guest_team_score"}
                                    value={values}
                                    onChange={(e1, e2) => {
                                        handleCustomChange(
                                            e1,
                                            e2,
                                            "guest_team_score",
                                            setFieldValue,
                                            "input"
                                        );
                                    }}
                                    error={errors}
                                    isTouched={touched}
                                    onBlur={handleBlur}
                                    type={"number"}
                                    component_type={"input"}
                                />
                            </div>
                            <div className="flex justify-end mb-3 w-full  px-6">
                                <ButtonComponent fill text={"Skor Durumunu Düzenle"} submit loading={loading}/>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default MatchScoreChange;
