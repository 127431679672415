import React, { useEffect, useState } from "react";
import {getLeagues, getTeamMatchStatistic, listTeams} from "../../../services/axios";
import { PulseLoader } from "react-spinners";
import DynamicInput from "../../../components/inputs/dynamicInput";

export default function MainClub() {
  const [loading, setLoading] = useState(true);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [teamsOptions, setTeamsOptions] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(false);
  const [statistics, setStatistics] = useState({
    rank: "-",
    win: "-",
    defeat: "-",
    draw: "-"
  });

  const fetchGetTeams = async () => {
    setTeamsLoading(true);
    try {
      const result = await listTeams();
      // console.log(result, "listTeams")
      setTeamsOptions([]);
      setTeamsOptions((prevState) => [
        ...prevState,
        ...result?.data.teams.map((item) => ({
          label: item?.team_title,
          value: item?.id
        }))
      ]);
      setSelectedTeam({
        team: {
          label: result?.data.teams[0]?.team_title,
          value: result?.data.teams[0]?.id
        }
      });
      return true;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setTeamsLoading(false);
    }
  };


  const fetchGetTeamStatistics = async () => {
    setLoading(true);
    try {
      const results = await getTeamMatchStatistic(selectedTeam?.team?.value);
      // console.log(results);
      const data = results.response.data;
      setStatistics(data);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetTeams();
  }, []);

  useEffect(() => {
    if (selectedTeam) {
      if (Object.keys(selectedTeam).length > 0) {
        fetchGetTeamStatistics();
      }
    }
  }, [selectedTeam]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between items-center">
          <div className="text-base font-semibold text-gray-400">
            Maç istatistikleri
          </div>
          <div>
            <DynamicInput
                containerStyle={"w-[300px]"}
                placeholder={"Takım Seçiniz"}
                name={"team"}
                value={selectedTeam}
                onChange={(e) => {
                  setSelectedTeam({ team: e });
                }}
                component_type={"select"}
                customOptions={teamsOptions}
                disabled={teamsLoading}
                minHeight={"40px"}
            />
          </div>
          {/*<a*/}
          {/*  href="/reports/oyuncu-istatistikleri"*/}
          {/*  className="text-sm transition duration-700 ease-out text-primary hover:border-b hover:border-b-primary hover:ease-in"*/}
          {/*>*/}
          {/*  📈 Tüm istatistikler*/}
          {/*</a>*/}
        </div>
        {loading ? (
          <div className="flex justify-center items-center h-36">
            <PulseLoader color="red" />
            <p>Yükleniyor...</p>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <div className="grid grid-cols-2 gap-20 mt-[2.75rem] md:grid-cols-4 xl:grid-cols-2 justify-evenly mx-[2rem]">
              <div className="flex flex-col items-center justify-center mx-14">
                <span className="text-[30px]">🏆</span>
                <span className="my-1 text-gray-400">Sıralama</span>
                <span className="text-orange-500">{statistics.rank ? statistics.rank : "__"}</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-[30px]">👍</span>
                <span className="my-1 text-gray-400">Kazanılan</span>
                <span className="my-1 text-gray-400">{statistics.win ? statistics.win : "__" }</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-[30px]">👎</span>
                <span className="my-1 text-gray-400">Kaybedilen</span>
                <span className="my-1 text-gray-400">{statistics.defeat ? statistics.defeat : "__"}</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-[30px]">🤝</span>
                <span className="my-1 text-gray-400">Beraberlik</span>
                <span className="my-1 text-gray-400">{statistics.draw ? statistics.draw : "__"}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
