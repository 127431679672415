import { createSlice } from "@reduxjs/toolkit";

const unitDefinitionsSlice = createSlice({
    name: "unitDefinitions",
    initialState: { allUnits: [] },
    reducers: {
        updateState(state, action) {
            const params = action.payload;
            Object.keys(params).map((i) => {
                return (state[i] = params[i]);
            });
        },
    },
});

export const unitDefinitionsActions = unitDefinitionsSlice.actions;

export default unitDefinitionsSlice;
