import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { useFormik } from "formik";
import validations from "../../utils/validations";
import InputComponent from "../../components/inputs";
import Swal from "sweetalert2";
import { BiUser } from "react-icons/bi";
import { RiLockPasswordLine } from "react-icons/ri";
import ZoomOnResize from "../../utils/zoom";
import { getAuthUser, loginRequest } from "../../services/axios";
import { useDispatch } from "react-redux";
import { setAuthUser } from "../../services/redux/features/authUser/authUserSlice";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const navigate = useNavigate();

  const fetchLogin = async () => {
    try {
      setLoading(true);
      const result = await loginRequest(values.tckn, values.password);
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Giriş Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });
        // Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Giriş Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }

      setLoading(false);
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };
  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      tckn: "",
      password: "",
    },
    onSubmit: async (values, { resetForm }) => {
      await fetchLogin();
    },
    validationSchema: validations,
    validate: (values) => {
      const errors = {};
      // Tüm alanların zorunlu olduğunu doğrula
      Object.keys(values).forEach((key) => {
        if (!values[key]) {
          errors[key] = "Bu alan boş bırakılamaz!";
        }
      });
      return errors;
    },
  });

  const [formInputs, setFormInputs] = useState([
    {
      labelTitle: "TC. Kimlik No",
      id: "tckn",
      name: "tckn",
      type: "text",
      placeholder: "Örn 12345678900",
      required: true,
      leftIcon: <BiUser className="w-full h-full" />,
      maxLength: 11,
    },
    {
      labelTitle: "Şifre",
      id: "password",
      name: "password",
      type: "password",
      placeholder: "Şifeniz",
      required: true,
      leftIcon: <RiLockPasswordLine className="w-full h-full" />,
    },
  ]);
  return (
    <div className="w-full max-w-2xl px-8 py-10 mx-auto bg-white shadow-xl rounded-xl">
      <div className="flex flex-col items-center justify-center w-full text-center">
        <p className="text-2xl font-medium text-gray-900">Altınordu App</p>
        <p className="mb-4 font-light text-gray-500 text-md">
          Altınordu FK. Yönetim ve Analiz Portalı
        </p>
      </div>
      <form
        className="flex flex-col items-center justify-center mx-auto space-y-2"
        onSubmit={handleSubmit}
      >
        {/* inputs */}
        {formInputs.map((item, index) => {
          return (
            <div key={index} className="w-full">
              <InputComponent
                labelTitle={item.labelTitle}
                id={item.id}
                name={item.name}
                type={item.type}
                onChange={handleChange}
                value={values}
                error={errors}
                isTouched={touched}
                onBlur={handleBlur}
                containerStyle={"w-full"}
                placeholder={item.placeholder}
                textarea={item.textarea}
                required={item.required}
                leftIcon={item.leftIcon}
                mask={item.mask}
                maxLength={item.maxLength}
              />
            </div>
          );
        })}
        {/* inputs */}
        <div className="w-full pt-8">
          <button
            type="submit"
            className="flex items-center justify-center w-full text-xl font-medium text-white transition-all rounded-md h-14 bg-primary hover:bg-red-500"
            disabled={loading}
          >
            {loading ? <PulseLoader color="white" /> : "Giriş Yap"}
          </button>
        </div>
        <div className="flex items-center justify-end w-full">
          <Link to="/forgot-password" className="text-gray-600 hover:underline">
            Şifremi Unuttum?
          </Link>
        </div>
        <div className="pt-4 text-md">
          Altınordu FK. &copy; {new Date().getFullYear()}
        </div>
      </form>
    </div>
  );
};

export default Login;
