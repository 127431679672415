import React, { useEffect, useState } from "react";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import { useFormik } from "formik";
import DynamicInput from "../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../utils/handleCustomChange";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import Swal from "sweetalert2";
import {
  baseImageURL,
  createDrill,
  createTest,
  deleteDrill,
  deleteTest,
  updateDrill,
  updateTest,
} from "../../services/axios";
import { nanoid } from "nanoid";

export function CreateDrillForm({
  selectedDrill,
  isDataChange,
  setIsDataChange,
  isOpen,
  setOpen,
  allDrills,
}) {
  const [upDrillOptions, setUpDrillOptions] = useState(null);
  const [upDrillInitial, setUpDrillInitial] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUpCategory, setIsUp] = useState(null); //üst kategori var mı checkbox

  // console.log(upDrillInitial, "upDrillInitial");

  const handleUpDrill = async () => {
    try {
      const createOption = await allDrills?.map((item, index) => ({
        label: item.title ? item.title : "-",
        value: item.id,
      }));
      return createOption;
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (allDrills) {
      handleUpDrill().then((createOption) => {
        setUpDrillOptions(
          createOption?.filter((item) => item?.value != selectedDrill?.id)
          //sadece üst kategorileri almak için
        );
        setFieldValue(
          "up_category",
          createOption?.find((item) => item.value == selectedDrill?.up_category)
        );
        setIsUp(
          createOption?.find((item) => item.value == selectedDrill?.up_category)
            ? true
            : false
        ); //checkbox için
      });
    }
  }, [allDrills]);

  const initialValues = {
    image: selectedDrill?.image ?? "",
    title: selectedDrill?.title ?? "",
    description: selectedDrill?.description ?? "",
    up_category: upDrillInitial ?? "",
  };
  // console.log(upDrillOptions, "upDrillOptions");
  const {
    errors,
    values,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      // console.log("valuesvalues: ", values);
      // console.log("valuesvalues: ", upDrillInitial);
      try {
        let result = null;
        if (selectedDrill) {
          result = await updateDrill(
            selectedDrill?.id,
            values?.title,
            values?.description,
            values?.image,
            isUpCategory ? values?.up_category?.value : "",
            isUpCategory ? 1 : 0
          );
        } else {
          result = await createDrill(
            values?.title,
            values?.description,
            values?.image,
            isUpCategory ? values?.up_category?.value : "",
            isUpCategory ? 1 : 0
          );
        }

        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result?.message ?? "Ekleme Başarılı!",
            showConfirmButton: false,
            timer: 1000,
          });

          // Set isDataChange to true
          setLoading(false);
          setIsDataChange(true);
          // Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: result?.message ?? "Ekleme Başarısız!",
            showConfirmButton: false,
            timer: 1000,
          });
        }
      } catch (error) {
        console.error("Hata oluştu:", error);
      } finally {
        setOpen(false);
      }
    },
    validate: (values) => {
      const errors = {};

      if (!values.image) {
        errors.image = "Resim alanı boş olamaz";
      }

      // if (!values.userConfirmPassword) {
      //   errors.userConfirmPassword = "Şifre tekrar alanı boş olamaz";
      // } else if (values.userPassword !== values.userConfirmPassword) {
      //   errors.userConfirmPassword = "Şifreler eşleşmiyor";
      // }

      console.log(errors, "hata");
      return errors;
    },
  });
  const onDeleteHandler = async (item) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${item.title}" drill'ini silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDrill(item?.id);
        setIsDataChange(true);
        Swal.fire("Silindi!", `"${item.title}" drill silindi.`, "success");
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid gap-4 pt-4">
          <DynamicInput
            labelTitle={"Drill"}
            containerStyle={"w-full"}
            placeholder={selectedDrill?.title ?? "Drill"}
            name={"title"}
            component_type={"input"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(e1, e2, "title", setFieldValue, "input");
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
          />
          <DynamicInput
            labelTitle={"Üst Kategorisi Var Mı?"}
            containerStyle={"w-full"}
            placeholder={"Üst Kategorisi Var Mı?"}
            value={isUpCategory}
            onChange={(e1, e2) => {
              setIsUp(e1.target.checked);
            }}
            component_type={"checkbox"}
          />
          {isUpCategory && (
            <DynamicInput
              labelTitle={"Üst Kategorisi"}
              containerStyle={"w-full"}
              placeholder={"Üst Kategorisi"}
              name={"up_category"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "up_category",
                  setFieldValue,
                  "select"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"select"}
              customOptions={upDrillOptions}
            />
          )}
          <DynamicInput
            labelTitle={"Açıklama"}
            containerStyle={"w-full"}
            placeholder={selectedDrill?.description ?? "Açıklama"}
            name={"description"}
            component_type={"textarea"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "description",
                setFieldValue,
                "textarea"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
          />
          <DynamicInput
            labelTitle={"Fotoğraf"}
            containerStyle={"w-full"}
            placeholder={selectedDrill?.image ?? "Fotoğraf"}
            name={"image"}
            component_type={"fileUpload"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(e1, e2, "image", setFieldValue, "fileUpload");
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
          />
          {selectedDrill?.image && (
            <div className="rounded-full">
              <img
                src={`${baseImageURL}${selectedDrill?.image}`}
                width="100%"
              />
            </div>
          )}
          {selectedDrill && (
            <>
              <div className="flex w-full pb-1">
                <ButtonComponent
                  onClick={() => onDeleteHandler(selectedDrill)}
                  text={"Drill'i Sil"}
                  outline
                  customStyle={"w-fit h-[56px]"}
                />
              </div>
            </>
          )}
        </div>

        <div className="flex items-center justify-end gap-4 pt-4">
          <ButtonComponent
            onClick={() => {
              setOpen(false);
              resetForm();
            }}
            outline
            text={"Vazgeç"}
          />
          <ButtonComponent submit fill text={"Kaydet"} />
        </div>
      </form>
    </>
  );
}

function CreateDrillModal({ isOpen, setOpen, setIsDataChange, allDrills }) {
  return (
    <div>
      <div>
        <CustomModal
          width={677}
          isOpen={isOpen}
          setOpen={setOpen}
          footer={null}
        >
          <CustomModalHeader
            title={"Yeni Sezon Ekleyin"}
            description={"Aşağıdan yeni sezon ekleyin."}
          />
          <CreateDrillForm
            allDrills={allDrills}
            isOpen={isOpen}
            setOpen={setOpen}
            setIsDataChange={setIsDataChange}
          />
        </CustomModal>
      </div>
    </div>
  );
}

export default CreateDrillModal;
