import React, {useEffect, useState} from "react";
import {Link, useNavigate, useOutletContext, useParams} from "react-router-dom";
import DrawerMenuAntd from "../../components/drawerMenuAntd";
import {ReportsMenuData} from "../../utils/datas/ReportsMenuData";
import PlayerStatistics from "./playerStatistics/list";
import PlayerGoals from "./playerGoals/list";
import RedCards from "./redCards/list";
import YellowCards from "./yellowCards/list";
import Coachs from "./coachs/list";
import PlayerReports from "./playerReports/list";
import { Paths } from "../../routes";

const Reports = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [setBreadcrumbData] = useOutletContext();
    const [mainMenuSelected, setMainMenuSelected] = useState(
        id ?? "oyuncu-istatistikleri"
    );
    
    useEffect(() => {
        setBreadcrumbData([
            {
                title: 'Panel',
            },
            {
                title: <Link className="font-bold" to={Paths.Reports}>Raporlar</Link>,
            }
        ]);
    }, []);

    useEffect(() => {
        navigate(`/reports/${mainMenuSelected}`);
    }, [mainMenuSelected, navigate]);

    return (
        <>
            <div className="w-full h-full py-8 bg-white shadow rounded-2xl zoom-none">
                <h1 className="px-4 py-4 text-3xl text-primary">Raporlar</h1>
                <div className="flex flex-col gap-4 px-4">
                    <DrawerMenuAntd
                        horizontal
                        items={ReportsMenuData}
                        selectedKey={mainMenuSelected}
                        setSelectedKey={setMainMenuSelected}
                    />
                    {mainMenuSelected === "oyuncu-istatistikleri" &&  <PlayerStatistics/>}
                    {mainMenuSelected === "atılan-goller" &&  <PlayerGoals/>} 
                    {mainMenuSelected === "kırmızı-kartlar" &&  <RedCards/>} 
                    {mainMenuSelected === "sarı-kartlar" &&  <YellowCards/>} 
                    {mainMenuSelected === "koçlar" &&  <Coachs/>} 
                    {mainMenuSelected === "oyuncu-raporları" &&  <PlayerReports/>} 
                </div>
            </div>
        </>
    );
};

export default Reports;
