import { BiFootball } from "react-icons/bi";

export const updateMatchFormInputsData = [
  {
    icon: <BiFootball className="text-2xl " />,
    title: "Maç Ekle",
    updateTitle: "Maç Güncelle",
    inputs: [
      {
        labelTitle: "Ev Sahibi Takım",
        id: "",
        name: "home_team",
        type: "text",
        placeholder: "Ev Sahibi Takım",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "select",
      },
      {
        labelTitle: "Misafir Takım",
        id: "",
        name: "guest_team",
        type: "text",
        placeholder: "Misafir Takım",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "select",
      },

      // {
      //   labelTitle: "Ev Sahibi Takım Skor",
      //   id: "",
      //   name: "homeScore",
      //   type: "text",
      //   placeholder: "Ev Sahibi Takım Skor",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "input",
      //   onlyNumber: true,
      // },
      // {
      //   labelTitle: "Deplasman Takım Skor",
      //   id: "",
      //   name: "awayScore",
      //   type: "text",
      //   placeholder: "Deplasman Takım Skor",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "input",
      //   onlyNumber: true,
      // },

      {
        labelTitle: "Sezon",
        id: "",
        name: "season",
        type: "text",
        placeholder: "Sezon",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "select",
      },
      {
        labelTitle: "Tip",
        id: "",
        name: "type",
        type: "text",
        placeholder: "Tip",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "select",
      },
      {
        labelTitle: "Zorluk",
        id: "",
        name: "difficulty",
        type: "text",
        placeholder: "Zorluk",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "select",
      },
       {
         labelTitle: "Lig",
         id: "",
         name: "league",
         type: "text",
         placeholder: "Lig",
         required: true,
         leftIcon: <BiFootball className="w-full h-full" />,
         component_type: "input",
       },
      //  {
      //    labelTitle: "Takım",
      //    id: "",
      //    name: "matchesTeam",
      //    type: "text",
      //    placeholder: "Takım",
      //    required: true,
      //    leftIcon: <BiFootball className="w-full h-full" />,
      //    component_type: "input",
      //  },
      {
        labelTitle: "Hafta",
        id: "",
        name: "week",
        type: "number",
        placeholder: "Hafta",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "input",
      },
      {
        labelTitle: "Tarih",
        id: "",
        name: "start_date",
        type: "text",
        placeholder: "Tarih",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "datePicker",
      },
      // {
      //   labelTitle: "Antrenör Yorumu",
      //   id: "",
      //   name: "coachReview",
      //   type: "text",
      //   placeholder: "Antrenör Yorumu",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "textarea",
      // },
      // {
      //   labelTitle: "Kaleci Antrenör Yorumu",
      //   id: "",
      //   name: "goalkeepingCoachReview",
      //   type: "text",
      //   placeholder: "Kaleci Antrenör Yorumu",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "textarea",
      // },
    ],
  },
];

export const addMatchFormInputsData = [
  {
    icon: <BiFootball className="text-2xl " />,
    title: "Maç Ekle",
    updateTitle: "Maç Güncelle",
    inputs: [
      {
        labelTitle: "Maç Tarihi",
        id: "",
        name: "start_date",
        type: "date",
        placeholder: "maç tarihi",
        required: true,
        
      },
      {
        labelTitle: "Maç Saati",
        id: "",
        name: "start_time",
        type: "text",
        placeholder: "Maç Saati",
        required: true,
        component_type: "timePicker"
      },
      {
        labelTitle: "Maç Türü",
        id: "",
        name: "type",
        type: "text",
        placeholder: "Tip",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "select",
      },
      {
        labelTitle: "Sezon",
        id: "",
        name: "season",
        type: "text",
        placeholder: "Sezon",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "select",
      },
      {
        labelTitle: "Lig",
        id: "",
        name: "league",
        type: "text",
        placeholder: "Lig",
        component_type: "select",
        required: true,
      },
      {
        labelTitle: "Maç Haftası",
        id: "",
        name: "week",
        type: "text",
        placeholder: "Maç Haftası",
        component_type: "select",
        required: true,
      },
      {
        labelTitle: "Ev Sahibi Takım",
        id: "",
        name: "home_team",
        type: "text",
        placeholder: "Ev Sahibi Takım",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "select",
      },
      {
        labelTitle: "Misafir Takım",
        id: "",
        name: "guest_team",
        type: "text",
        placeholder: "Misafir Takım",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "select",
      },
      {
        labelTitle: "Zorluk",
        id: "",
        name: "difficulty",
        type: "text",
        placeholder: "Zorluk",
        component_type: "select",
        required: true,
      },  
      // {
      //   labelTitle: "Altınordu Takımı",
      //   id: "",
      //   name: "ao_team",
      //   type: "text",
      //   placeholder: "AO Takımı",
      //   component_type: "select",
      //   required: true,
      // },
      {
        labelTitle: "Rakip Takım Kategorisi",
        id: "",
        name: "guest_team_category_id",
        type: "text",
        placeholder: "Rakip Takım",
        component_type: "select",
        required: true,
      },
      {
        labelTitle: "Bir yaş küçük grubumuz ile oynadık",
        id: "",
        name: "small_team",
        type: "checkbox",
        placeholder: "Evet",
        component_type: "checkbox",
        required: false,
      },
      {
        labelTitle: "Tesis Adı",
        id: "",
        name: "football_ground_id",
        type: "text",
        placeholder: "Tesis Adı",
        component_type: "select",
        required: true,
      },
      {
        labelTitle: "Saha Adı",
        id: "",
        name: "field",
        type: "text",
        placeholder: "Saha Adı",
        component_type: "select",
        required: true,
      },
      {
        labelTitle: "Maç Süresi Dk.",
        id: "",
        name: "duration",
        type: "text",
        placeholder: "Maç Dakikası",
        component_type: "select",
        required: true,
      },
      {
        labelTitle: "Maç Yarı",
        id: "",
        name: "half",
        type: "text",
        placeholder: "Evet",
        component_type: "select",
        required: true,
      },
      {
        labelTitle: "Oyuncu Sayısı",
        id: "",
        name: "number_of_player",
        type: "text",
        placeholder: "Oyuncu Sayısı",
        required: true,
        leftIcon: <BiFootball className="w-full h-full" />,
        component_type: "select",
      },
      // {
      //   labelTitle: "Ev Sahibi Takım Skor",
      //   id: "",
      //   name: "homeScore",
      //   type: "text",
      //   placeholder: "Ev Sahibi Takım Skor",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "input",
      //   onlyNumber: true,
      // },
      // {
      //   labelTitle: "Deplasman Takım Skor",
      //   id: "",
      //   name: "awayScore",
      //   type: "text",
      //   placeholder: "Deplasman Takım Skor",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "input",
      //   onlyNumber: true,
      // },
      // {
      //   labelTitle: "Zorluk",
      //   id: "",
      //   name: "match_dificulty",
      //   type: "text",
      //   placeholder: "Zorluk",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "select",
      // },
      // {
      //   labelTitle: "Lig",
      //   id: "",
      //   name: "matchesLeague",
      //   type: "text",
      //   placeholder: "Lig",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "input",
      // },
      // {
      //   labelTitle: "Takım",
      //   id: "",
      //   name: "matchesTeam",
      //   type: "text",
      //   placeholder: "Takım",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "input",
      // },
      // {
      //   labelTitle: "Hafta",
      //   id: "",
      //   name: "matchesWeek",
      //   type: "number",
      //   placeholder: "Hafta",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "input",
      //   onlyNumber: true,
      // },
      // {
      //   labelTitle: "Tarih",
      //   id: "",
      //   name: "matchesDate",
      //   type: "text",
      //   placeholder: "Tarih",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "datePicker",
      // },
      // {
      //   labelTitle: "Antrenör Yorumu",
      //   id: "",
      //   name: "coachReview",
      //   type: "text",
      //   placeholder: "Antrenör Yorumu",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "textarea",
      // },
      // {
      //   labelTitle: "Kaleci Antrenör Yorumu",
      //   id: "",
      //   name: "goalkeepingCoachReview",
      //   type: "text",
      //   placeholder: "Kaleci Antrenör Yorumu",
      //   required: true,
      //   leftIcon: <BiFootball className="w-full h-full" />,
      //   component_type: "textarea",
      // },
    ],
  },
];
