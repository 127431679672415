import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Paths } from "../../../routes";
import { DataTable } from "../../../components/tables";
import {Input, Select} from "antd";
import { FiPlus } from "react-icons/fi";
import ActionButtonsDataTable from "../../../containers/sections/ActionButtonsDataTable";
import {
  getAllTreatments,
  getPlayerStatistics,
  getSeasons,
} from "../../../services/axios";



const columns = [
  { title: "Oyuncu", dataIndex: "player_name" },
  { title: "Gol", dataIndex: "count_goals" },
  { title: "Asist", dataIndex: "count_assists" },
  { title: "Kırmızı Kart", dataIndex: "count_redcards" },
  { title: "Sarı Kart", dataIndex: "count_yellowcards" },
  { title: "Yaş", dataIndex: "age" },
  { title: "Boy", dataIndex: "height" },
  { title: "Kilo", dataIndex: "weight" },
  { title: "Kullanılan Gıda", dataIndex: "foodUsed" },
  { title: "Katıldığı Sezon", dataIndex: "seasonAttended" },
  { title: "Kamulaştırma Tarihi", dataIndex: "nationalizationDate" },
  {
    title: "İşlem",
    fixed: "right",
    key: "action",
    width: "",
    render: (item, index) => {
      return (
        <ActionButtonsDataTable
          id={item.key}
          toDetail={Paths.Users.Players.EditPlayers}
          detailButton
        />
      );
    },
  },
];
const PlayerStatistics = () => {
  const [isDataChange, setIsDataChange] = useState(true);
  const [dataTableData, setDataTableData] = useState([]);
  const [playerStatisticData, setPlayerStatisticData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [playerStatisticFilterData, setPlayerStatisticFilterData] = useState({
    startDate: null,
    endDate: null,
    searchTerm: null,
    seasonId: {
      label: "Tümü",
      value: null,
    },
  });
  const [allSeasonsOptions, setAllSeasonsOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);



  
  const fetchGetAllSeasons = async () => {
    setLoading(true);
    try {
      const result = await getSeasons();

      let allSeasonsOptions = result.data.seasons.map((item) => ({
        label: item.season_title,
        value: item.id,
      }));

      const defaultOption = {
        label: "Tümü",
        value: null,
      };

      allSeasonsOptions.unshift(defaultOption);

      setAllSeasonsOptions(allSeasonsOptions);
      setLoading(false);
    } catch (error) {
      console.log("hata", error);
    }
  };
  
  const fetchGetPlayerStatistic = async () => {
    setLoading(true);
    try {
      const result = await getPlayerStatistics(
        playerStatisticFilterData.startDate,
        playerStatisticFilterData.endDate,
        playerStatisticFilterData.searchTerm,
        playerStatisticFilterData.seasonId.value
      );
      console.log(result, "rrrrrrrrrrrrr")
      setPlayerStatisticData(result?.data);
      const resultDataTableData = result?.data.map((item) => ({
        key: item?.player_id,
        player_name: item?.player_name ? item?.player_name : "-",
        count_goals: item?.count_goals ? item?.count_goals : "-",
        count_assists: item?.count_assists ? item?.count_assists : "-",
        count_yellowcards: item?.count_yellowcards ? item?.count_yellowcards: "-",
        count_redcards: item?.count_redcards ? item?.count_redcards : "-",
        age: item?.age ? item?.age : "-",
        height: item?.height ? item?.height : "-",
        weight: item?.weight ? item?.weight : "-",
        foodUsed: item?.foodUsed ? item?.foodUsed : "-",
        seasonAttended: item?.seasonAttended ? item?.seasonAttended : "-",
        nationalizationDate: item?.nationalizationDate? item?.nationalizationDate: "-",
      }));
      setDataTableData(resultDataTableData);
      setLoading(false);
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  useEffect(() => {
    isDataChange && fetchGetPlayerStatistic();
  }, [isDataChange, playerStatisticFilterData]);

  useEffect(() => {
    setIsDataChange(true);
  }, [playerStatisticFilterData]);

  useEffect(() => {
    fetchGetAllSeasons();
  }, []);

  
  let timer;
  useEffect(() => {
    timer = setTimeout(() => {
      setPlayerStatisticFilterData((prevState) => ({
        ...prevState,
        searchTerm: searchTerm,
      }));
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchTerm]);
  return (
    <>
      <div className="w-full p-4 bg-white rounded-md shadow">
        <div className="flex items-center justify-between text-gray-500">
          <h4 className="text-lg font-semibold">İstatistikler</h4>
        </div>
        <div className="flex flex-wrap-reverse items-center justify-between px-2 py-2 my-4 bg-gray-100 rounded-lg">
          <div className="flex items-center w-full sm:w-auto gap-3">
            <div className="">
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                Tarih Aralığı:
              </span>
              <div className="flex items-center w-full sm:w-auto">
                <Input
                  type="date"
                  onChange={(e) =>
                    setPlayerStatisticFilterData((prevState) => ({
                      ...prevState,
                      startDate: e.target.value,
                    }))
                  }
                />
                <span className="mx-2">-</span>
                <Input
                  type="date"
                  onChange={(e) =>
                    setPlayerStatisticFilterData((prevState) => ({
                      ...prevState,
                      endDate: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="" style={{ minWidth: "150px" }}>
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                Sezon:
              </span>
              <Select
                  className={"w-[150px]"}
                onChange={(e) =>
                  setPlayerStatisticFilterData((prevState) => ({
                    ...prevState,
                    seasonId: e,
                  }))
                }
                value={playerStatisticFilterData.seasonId}
                options={allSeasonsOptions ?? []}
              />
            </div>
          </div>
          <div className="flex items-center w-full sm:w-auto">
            <Input
              type="text"
              placeholder="Tabloda ara.."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="">
          <DataTable
            columns={columns}
            data={dataTableData?.reverse() ?? []}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default PlayerStatistics;
