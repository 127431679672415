import React, { useState } from "react";
import { RoundedIconButton } from "../components/buttons";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FiUser, FiLogOut, FiSettings } from "react-icons/fi";
import { baseImageURL, logoutRequest } from "../services/axios";
import CustomModal from "../components/modals";
import CustomModalHeader from "../components/modals/CustomModalHeader";

const ToolBox = () => {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const savedBaseUrl = localStorage.getItem("baseUrlLocal");

  return (
    <ul className="flex items-center space-x-2">
      <li>
        <RoundedIconButton icon={<BsFillEnvelopeFill />} count="1" />
      </li>
      <li>
        <RoundedIconButton icon={<FiUser />} />
      </li>
      <li>
        <RoundedIconButton
          icon={<FiSettings />}
          onClick={() => setIsSettingsModalOpen(true)}
        />
        <CustomModal
          width={677}
          isOpen={isSettingsModalOpen}
          setOpen={setIsSettingsModalOpen}
          footer={null}
        >
          <CustomModalHeader
            title={"Ayarlar"}
            description={"Aşağıdan ayarlarınızı düzenleyebilirsiniz."}
            icon={"IoMdSettings"}
          />
          <div className="py-8">
            <p>
              <b>
                Anlık Kullanılan <u>API</u> URL :{" "}
              </b>
              {savedBaseUrl}
            </p>
            <p>
              <b>
                Anlık Kullanılan <u>Image</u> URL :{" "}
              </b>
              {baseImageURL}
            </p>
            <div className="flex flex-col gap-4 mt-4">
              <p
                onClick={() => {
                  localStorage.setItem(
                    "baseUrlLocal",
                    "https://backend.afk.net.tr"
                  );
                  window.location.reload();
                }}
                className="p-2 border cursor-pointer rounded-xl hover:border-primary"
              >
                <b>Test : </b>https://backend.afk.net.tr/api
                {savedBaseUrl == "https://backend.afk.net.tr" && (
                  <i className="px-2 py-1 mx-4 text-white rounded-full bg-primary">
                    SEÇİLDİ
                  </i>
                )}
              </p>
              <p
                onClick={() => {
                  localStorage.setItem(
                    "baseUrlLocal",
                    "https://altinordu-api.golpro.com.tr"
                  );
                  window.location.reload();
                }}
                className="p-2 border cursor-pointer rounded-xl hover:border-primary"
              >
                <b>Canlı : </b>https://altinordu-api.golpro.com.tr/public/api
                {savedBaseUrl == "https://altinordu-api.golpro.com.tr" && (
                  <i className="px-2 py-1 mx-4 text-white rounded-full bg-primary">
                    SEÇİLDİ
                  </i>
                )}
              </p>
            </div>
          </div>
        </CustomModal>
      </li>
      <li>
        <RoundedIconButton
          icon={<FiLogOut />}
          onClick={() => {
            logoutRequest();
          }}
        />
      </li>
    </ul>
  );
};

export default ToolBox;
