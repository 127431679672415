import React, { useEffect, useState } from "react";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { SettingOutlined } from "@ant-design/icons";
import ButtonComponent from "../../components/buttons/ButtonComponent"; 
import { getAllUnits } from "../../services/axios";
import { PulseLoader } from "react-spinners";
import { useSelector } from "react-redux";
import CreateUnitModal, { CreateUnitForm } from "../../containers/modals/CreateUnitModal";

function UnitDefinitions() {
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isDataChange, setIsDataChange] = useState(true);
    const [loading, setLoading] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [listUnitsResultData, setListUnitsResultData] = useState([]);
    const allUnits = useSelector(({ unitDefinitions }) => unitDefinitions.allUnits);

    const fetchListUnits = async () => {
        setIsDataChange(true);
        setLoading(true);
        setMenuData([]);
        try {
            const result = await getAllUnits();
            setListUnitsResultData(result.data.units);
            result.data.units.forEach((item, index) => {
                handleDataPush(item.title, index);
            });
            return result;
        } catch (error) {
            console.log("hata", error);
        } finally {
            setLoading(false);
            setIsDataChange(false);
        }
    };
    useEffect(() => {
        if (isDataChange) {
            fetchListUnits();
        }
    }, [isDataChange]);

    const handleDataPush = (label, value) => {
        const newItem = getMenuItem(label, value, <SettingOutlined />, null);
        setMenuData((prev) => [...prev, newItem]);
    };

    function SelectedItemForm() {
        return listUnitsResultData
            ?.filter((filterItem, filterIndex) => filterIndex.toString() === selectedMenuIndex.toString())
            ?.map((item, index) => {
                return (
                    <React.Fragment key={index}> 
                        <CreateUnitForm
                            isOpen={isModalOpen}
                            setOpen={setModalOpen}
                            setIsDataChange={setIsDataChange}
                            isDataChange={isDataChange}
                            updateSelectedValuesProp={item}
                        />
                    </React.Fragment>
                );
            });
    }
    return (
        <div>
            {loading && (
                <div className="flex">
                    <PulseLoader color="red" />
                    <p>Yükleniyor...</p>
                </div>
            )}
            {!loading && (
                <div className="flex">
                    <div className="flex flex-col">
                        <DrawerMenuAntd
                            items={menuData}
                            selectedKey={selectedMenuIndex}
                            setSelectedKey={setSelectedMenuIndex}
                        />
                        <ButtonComponent
                            customStyle={"mt-4"}
                            text={"Yeni Birim Ekle"}
                            outline
                            onClick={() => {
                                setModalOpen(true);
                            }}
                        />
                        <CreateUnitModal
                            isOpen={isModalOpen}
                            setOpen={setModalOpen}
                            setIsDataChange={setIsDataChange}
                        />
                    </div>
                    <div className="w-full px-4">
                        <SelectedItemForm />
                    </div>
                </div>
            )}
        </div>
    );
}

export default UnitDefinitions;
