import React, { useEffect, useState } from "react";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import { Form, Formik, useFormik } from "formik";
import { Paths } from "../../routes/";
import { handleCustomChange } from "../../utils/handleCustomChange";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import DynamicInput from "../../components/inputs/dynamicInput";
import Swal from "sweetalert2";
import { PulseLoader } from "react-spinners";
import {
  createTeams,
  deleteTeam,
  getAllUser,
  updateTeams,
} from "../../services/axios";
import DataTable from "../../components/tables/DataTable";
import ActionButtonsDataTable from "../sections/ActionButtonsDataTable";
import { Button } from "antd";

export function CreateTeamForm({
  isOpen,
  setOpen,
  setIsDataChange,
  isDataChange,
  updateSelectedValuesProp,
  updateId,
  teamCategoryOptions,
  leagueOptions,
  allPlayer,
  allPlayerLoading,
  seasonOptions
}) {
  const [loading, setLoading] = useState(false);
  const [rowSelectionListPlayer, setRowSelectionListPlayer] = useState([]);
  const [selectPlayerData, setSelectPlayerData] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [filteredDataTableData, setFilteredDataTableData] = useState([]);

  const playersColumns = [
    {
      title: "No",
      dataIndex: "countNo",
    },
    {
      title: "Oyuncu Adı Soyadı",
      dataIndex: "name",
    },
    {
      title: "TC Kimlik No",
      dataIndex: "tckn",
    },
  ];
  const handleSearchOnChange = (e) => {
    const searchValue = e.target.value;
    setSearchInputValue(searchValue);
    const filteredData = allPlayer?.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchValue.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredDataTableData(filteredData ? filteredData : []);
  };

  const removeUser = (userId) => {
    setRowSelectionListPlayer((prevState) => ({
      selectedRowItems: prevState.selectedRowItems.filter(
        (item) => item.key !== userId
      ),
    }));
  };

  const handleCreateTeam = async (team_title, team_category, leagueIds, seasonId) => {
    try {
      const result = await createTeams(team_title, team_category, leagueIds, seasonId);
      // console.log(result, "resultresultresult");

      setIsDataChange(true);

      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1500,
        });

        setLoading(false);
        setIsDataChange(true);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Başarısız",
          text: result?.response?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Kapat",
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    }
  };
  const handleUpdateTeam = async (
    team_title,
    team_category,
    leagueIds,
    playerIds,
    seasonId
  ) => {
    try {
      const result = await updateTeams(
        updateSelectedValuesProp.id,
        team_title,
        team_category,
        leagueIds,
        playerIds,
        seasonId
      );
      setIsDataChange(true);
      // console.log(result, "resss")
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Düzenleme Başarılı!",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Başarısız",
          text: result?.response?.message ?? "Düzenleme Başarısız!",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Kapat",
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    }
  };

  const fetchDeleteTeam = async () => {
    try {
      const result = await deleteTeam(updateSelectedValuesProp?.id);
      setIsDataChange(true);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const handleDeleteTeam = async (updateSelectedValuesProp) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `Silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteTeam(updateSelectedValuesProp?.id);
        Swal.fire("Silindi!", `Silindi.`, "success");
      }
    });
  };
  const {
    errors,
    values,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      team_title: updateSelectedValuesProp?.team_title ?? "",
      seasonId: updateSelectedValuesProp
      ? {
          label: updateSelectedValuesProp?.season?.season_title,
          value: updateSelectedValuesProp?.season?.id
        }
      : "",
      team_category: updateSelectedValuesProp?.team_category
        ? {
            label: updateSelectedValuesProp.team_category.category_title,
            value: updateSelectedValuesProp.team_category.id,
          }
        : "",
      leagueIds: updateSelectedValuesProp
        ? updateSelectedValuesProp?.leagues
          ? updateSelectedValuesProp.leagues.map((league) => ({
              label: league.name,
              value: league.id,
            }))
          : ""
        : "",
    },
    onSubmit: async (values) => {
      // console.log(values, "values");
      values.team_category = values.team_category.value;
      values.playerIds = [];
      values.seasonId = values.seasonId.value;
      if (updateSelectedValuesProp) {
        if (rowSelectionListPlayer.selectedRowItems !== undefined) {
          values.playerIds = rowSelectionListPlayer.selectedRowItems.map(
            (item) => item.key
          );
        }
        values.leagueIds.map((item) => {
          if (typeof item === "object") {
            values.leagueIds = [item?.value ?? null];
          }
        });
        handleUpdateTeam(
          values.team_title,
          values.team_category,
          values.leagueIds,
          values.playerIds,
          values.seasonId
        );
      } else {
        handleCreateTeam(
          values.team_title,
          values.team_category,
          values.leagueIds,
          values.seasonId
        );
      }
    },
  });

  const initialOrders = () => {

    if (updateSelectedValuesProp) {
      // console.log("ALLPLAYER", allPlayer)
      const newPlayer = allPlayer.map((item, index) => ({
        key: item.id,
        countNo: index + 1,
        name: `${item?.name} ${item?.surname}`,
        tckn: item.tckn,
        // disabled: !!updateSelectedValuesProp?.players.find(
        //   (itemFilter) => itemFilter?.player?.id == item?.id
        // )
      }));
      setSelectPlayerData(newPlayer);

      const updateSelectedValues = updateSelectedValuesProp?.players.map(
        (item, index) => ({
          key: item.player?.id,
          countNo: index + 1,
          name: `${item.player?.name} ${item.player?.surname}`,
          tckn: item.player?.tckn,
        })
      );

      setRowSelectionListPlayer({
        selectedRowItems: updateSelectedValues,
      });
    }
  };

  useEffect(() => {
    initialOrders();
    
    // console.log(updateSelectedValuesProp, "updateSelectedValuesProp")
  }, []);


  return (
    <>
      <div>
        {updateSelectedValuesProp && (
          <div className={"flex justify-end"}>
            <div className={"border px-4 py-3"}>
              <span class={"font-bold"}>Takım Aktif Lig:</span>{" "}
              {updateSelectedValuesProp?.currentLeague?.name ?? "Seçilmemiş"}
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <DynamicInput
            labelTitle={"Takım Adı"}
            containerStyle={"w-full"}
            placeholder={"Takım Adı "}
            name={"team_title"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(e1, e2, "team_title", setFieldValue, "input");
            }}
            error={errors}
            onBlur={handleBlur}
            required={true}
          />
          <DynamicInput
            labelTitle={"Sezon Seçiniz"}
            containerStyle={"w-full"}
            placeholder={"Seçiniz"}
            name={"seasonId"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(e1, e2, "seasonId", setFieldValue, "select");
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"select"}
            customOptions={seasonOptions}
            required={true}
          />
          {/* <DynamicInput
                        labelTitle={"Takım Kategorileri"}
                        containerStyle={"w-full"}
                        placeholder={"Takım Adı "}
                        name={"team_category"}
                        value={values}
                        onChange={(e1, e2) => {
                            handleCustomChange(
                                e1,
                                e2,
                                "team_category",
                                setFieldValue,
                                "select"
                            );
                        }}
                        error={errors}
                        onBlur={handleBlur}
                        disabled={teamCategoryOptions.length === 0}
                        customOptions={teamCategoryOptions ?? []}
                        component_type={"select"}
                        required={true}
                    /> */}
          {/* <DynamicInput
                        labelTitle={"Ligler"}
                        containerStyle={"w-full"}
                        placeholder={"Lig Seçiniz "}
                        name={"leagueIds"}
                        value={values}
                        onChange={(e1, e2) => {
                            handleCustomChange(e1, e2, "leagueIds", setFieldValue, "select");
                        }}
                        error={errors}
                        onBlur={handleBlur}
                        disabled={leagueOptions.length === 0}
                        customOptions={leagueOptions ?? []}
                        component_type={"multipleSelect"}
                        required={true}
                    /> */}
          {updateSelectedValuesProp && (
            <>
              <div className="grid w-full gap-4 pt-4 pb-4"></div>
              <DynamicInput
                labelTitle={"Arama Yap"}
                containerStyle={"w-full"}
                placeholder={"Arayın..."}
                name={"searchInput"}
                value={searchInputValue}
                onChange={(e) => handleSearchOnChange(e)}
                error={null}
                isTouched={null}
                onBlur={null}
              />
              <div className="grid grid-cols-2 gap-5">
                <div className="w-full p-4  bg-white customBoxShadow rounded-2xl">
                  <p className="pt-4 pb-8 text-2xl font-semibold text-secondary">
                    Oyuncu Seç
                  </p>
                  <div>
                    <DataTable
                      loading={allPlayerLoading}
                      selection={{
                        rowSelectionList: rowSelectionListPlayer,
                        setRowSelectionList: setRowSelectionListPlayer,
                      }}
                      columns={playersColumns}
                      data={
                        searchInputValue
                          ? filteredDataTableData.map((item) => ({
                              name: item.playerName,
                            }))
                          : selectPlayerData
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="flex flex-col gap-4 p-4 border shadow-xl rounded-xl">
                    <h1 className="text-xl font-medium text-secondary">
                      Seçilen Oyuncular
                    </h1>
                    <DataTable
                      columns={[
                        {
                          title: "No",
                          dataIndex: "countNo",
                        },
                        {
                          title: "Oyuncu Adı Soyadı",
                          dataIndex: "name",
                        },
                        {
                          title: "TC Kimlik No",
                          dataIndex: "tckn",
                        },
                        {
                          title: "İşlem",
                          fixed: "right",
                          key: "action",
                          width: "",
                          render: (index) => {
                            return (
                              <Button onClick={() => removeUser(index.key)}>
                                Çıkar
                              </Button>
                            );
                          },
                        },
                      ]}
                      data={rowSelectionListPlayer.selectedRowItems || []}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="flex justify-end gap-2 pt-9">
            {updateSelectedValuesProp ? (
              <ButtonComponent
                submit
                outline
                text={"Takım Kaydını Sil"}
                onClick={() => handleDeleteTeam()}
              />
            ) : (
              <ButtonComponent
                customStyle={"mr-5"}
                onClick={() => {
                  setOpen(false);
                }}
                outline
                text={"Vazgeç"}
              />
            )}
            {/*silmek istediğinze emin misin diye sormalı*/}
            <ButtonComponent
              submit
              fill
              text={
                updateSelectedValuesProp ? "Düzenlemeleri Kaydet" : "Kaydet"
              }
            />
          </div>
        </form>
        {!updateSelectedValuesProp && (
          <div
            class="flex items-start p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 w-full mt-7"
            role="alert"
          >
            <svg
              className="flex-shrink-0 inline w-4 h-4 mr-3 mt-[2px]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div>
              <span class="font-medium">DİKKAT!</span>
              <div className="flex flex-col gap-2 my-2 ml-2">
                <p className="pl-2 border-l-2 border-yellow-300">
                  Oyuncu giriş çıkış işlemlerini <b>takım oluşturduktan</b>{" "}
                  sonra yapabilirsiniz.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function CreateTeamModal({
  isOpen,
  setOpen,
  setIsDataChange,
  teamCategoryOptions,
  leagueOptions,
  seasonOptions
}) {
  return (
    <div>
      <CustomModal width={677} isOpen={isOpen} setOpen={setOpen} footer={null}>
        <CustomModalHeader
          title={"Yeni Takım Ekleyin"}
          description={"Aşağıdan yeni takım ekleyin."}
        />
        <CreateTeamForm
          isOpen={isOpen}
          setOpen={setOpen}
          setIsDataChange={setIsDataChange}
          teamCategoryOptions={teamCategoryOptions}
          leagueOptions={leagueOptions}
          seasonOptions={seasonOptions}
        />
      </CustomModal>
    </div>
  );
}

export default CreateTeamModal;
