import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Paths} from "../../../routes";

import {DataTable} from "../../../components/tables";
import {Input, Select} from "antd";
import {PositionData} from "../../../utils/datas/positionData";

import {FiEdit, FiPlus, FiTrash} from "react-icons/fi";
import {
    GetApplicants,
    filterApplicants
} from "../../../services/redux/features";
import ActionButtonsDataTable from "../../../containers/sections/ActionButtonsDataTable";
import {
    deleteCandidate,
    deleteCandidateUser,
    deleteMatch,
    getAllCandidates,
    getCandidates
} from "../../../services/axios";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import Swal from "sweetalert2";

const ApplicantsList = () => {
    const [loading, setLoading] = useState(false);
    const [applicants, setApplicants] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");

    const [searchValue, setSearchValue] = useState([]);
    const [positionOptions, setPositionOptions] = useState([]);

    const [selectWatchStatus, setSelectWatchStatus] = useState({
        label: "Tümü",
        value: null
    });
    const [selectCandidateStatus, setSelectCandidateStatus] = useState({
        label: "Tümü",
        value: null
    });
    const [selectCandidatePosition, setSelectCandidatePosition] = useState({
        label: "Tümü",
        value: null
    });

    const [candidateFilter, setCandidateFilter] = useState({
        isConfirmed: null,
        watchStatus: null,
        position: null,
        startWatchDate: null,
        endWatchDate: null
    });

    const convertWatchStatus = (value) => {
        switch (value) {
            case "izlendi":
                return "İzlendi"
            case "izlenmedi":
                return "İzlenmedi";
            case "gorusme-yapildi":
                return "Görüşme Yapıldı";
            default:
                return "";
        }
    }

    const position = () => {
        setPositionOptions((prevState) => [
            {
                label: "Tümü",
                value: null
            },
            ...PositionData.map((item) => ({
                label: item.code,
                value: item.value
            }))
        ]);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        const filteredUsers = applicants.filter((applicants) =>
            applicants.nameAndSurname.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchValue(filteredUsers);
    };
    console.log(applicants, "applicantsapplicantsapplicants");

    const getPostion = (value) => {
        return PositionData.find(item => item.value === value)?.code;
    }
    const fetchGetgetCandidates = async () => {
        setLoading(true);
        try {
            setApplicants([])
            const res = await getCandidates(
                candidateFilter.isConfirmed,
                candidateFilter.watchStatus,
                candidateFilter.position,
                candidateFilter.startWatchDate,
                candidateFilter.endWatchDate
            );
            const dataTable = res?.response?.data?.map((item) => ({
                id: item.id,
                nameAndSurname: item.name + " " + item.surname,
                email: item.email,
                position: getPostion(item?.position),
                watchStatus: item.watchStatus,
                isConfirmed: item.isConfirmed,
                monitoredTournament: item.monitoredTournament
            }));
            setApplicants(dataTable);
        } catch (error) {
            console.log(error, "error!!");
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGetgetCandidates();
    }, [candidateFilter]);

    useEffect(() => {
        position();
    }, []);

    const columns = [
        {
            title: "Ad Soyad",
            dataIndex: "nameAndSurname"
        },
        {
            title: "Pozisyon",
            dataIndex: "position",
            render: (item) => {
                return (
                    <span
                        className={'bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300'}>
              {item}
            </span>
                );
            }
        },
        {
            title: "İzlenme Durumu",
            dataIndex: "watchStatus",
            render: (item) => {
                return (
                    item && (
                        <span
                            className={"bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"}>
                            {convertWatchStatus(item)}
                        </span>
                    )
                );
            }
        },
        {
            title: "Kaydın Son Durumu",
            dataIndex: "isConfirmed",
            render: (item) => {
                return (
                    <span
                        className={`${item === 1 ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300" : item === 0 ? "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-30" : "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300"} text-xs font-medium me-2 px-2.5 py-0.5 rounded`}>
             {item === 1 ? "Onaylandı" : item === 0 ? "Onaylanmadı" : "Henüz Belirlenmedi"}
            </span>
                );
            }
        },
        {
            title: "İşlem",
            key: "action",
            width: "",
            render: (item) => {
                console.log(item, "iteeeeeeeeeeeeeem");
                return (
                    <ActionButtonsDataTable
                        id={item.id}
                        toDetail={Paths.Applicants.EditApplicants}
                        deleteDataLabel={item.name}
                        detailButton
                    />
                );
            }
        }
    ];

    return (
        <>
            <div className="w-full p-4 bg-white rounded-md shadow">
                <div className="flex items-center justify-between text-gray-500">
                    <h4 className="text-lg font-semibold">Adaylar</h4>
                </div>
                <div
                    className="flex flex-wrap-reverse items-center justify-between px-2 py-2 my-4 bg-gray-100 rounded-lg">
                    <ul className="flex items-center">
                        <li>
                            <Link
                                to={Paths.Applicants.AddApplicant}
                                className="flex p-2 text-gray-500 transition-all rounded-lg hover:bg-gray-200"
                            >
                                <FiPlus size={21}/>
                                <span className="ml-2 text-sm text-gray-500">Ekle</span>
                            </Link>
                        </li>
                    </ul>

                    <div className="flex gap-1">
                        <div>
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                Aday Durumu:
              </span>
                            <Select
                                className="w-[150px]"
                                value={selectWatchStatus}
                                onChange={(e) => {
                                    setCandidateFilter((prevState) => ({
                                        ...prevState,
                                        isConfirmed: e
                                    }));
                                    setSelectWatchStatus(e);
                                }}
                                options={[
                                    {label: "Tümü", value: null},
                                    {label: "Onaylandı", value: 1},
                                    {label: "Onaylanmadı", value: 0}
                                ]}
                            />
                        </div>
                        <div>
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                İzlenme Durumu:
              </span>
                            <Select
                                className="w-[150px]"
                                value={selectCandidateStatus}
                                onChange={(e) => {
                                    setCandidateFilter((prevState) => ({
                                        ...prevState,
                                        watchStatus: e
                                    }));
                                    setSelectCandidateStatus(e);
                                }}
                                options={[
                                    {label: "Tümü", value: null},
                                    {label: "İzlendi", value: "izlendi"},
                                    {label: "İzlenmedi", value: "izlenmedi"},
                                    {label: "Görüşme Yapıldı", value: "gorusme-yapildi"}
                                ]}
                            />
                        </div>
                        <div>
              <span className="hidden w-full text-sm text-gray-500 sm:block">
              Pozisyon
              </span>
                            <Select
                                className="w-[150px]"
                                value={selectCandidatePosition}
                                onChange={(e) => {
                                    setCandidateFilter((prevState) => ({
                                        ...prevState,
                                        position: e
                                    }));
                                    setSelectCandidatePosition(e);
                                }}
                                options={positionOptions}
                            />
                        </div>


                        <div className={"flex gap-1"}>
                            <div>
                <span className="hidden w-full text-sm text-gray-500 sm:block">
                  İzlenme Tarih Aralığı:
                </span>

                                <Input
                                    onChange={(e) => {
                                        setCandidateFilter((prevState) => ({
                                            ...prevState,
                                            startWatchDate: e.target.value
                                        }));
                                    }}
                                    type="date"
                                />
                            </div>
                            <div>
                <span className="hidden w-full text-sm text-gray-500 sm:block">
                  &nbsp;
                </span>
                                <Input
                                    onChange={(e) => {
                                        setCandidateFilter((prevState) => ({
                                            ...prevState,
                                            endWatchDate: e.target.value
                                        }));
                                    }}
                                    type="date"
                                />
                            </div>
                        </div>

                        <div>
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                Arama:
              </span>
                            <Input
                                type="text"
                                placeholder="Arama yap"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                <div className=" mt-10">
                    <DataTable
                        columns={columns}
                        data={searchTerm ? searchValue : applicants.reverse()}
                        loading={loading}
                        bordered={false}
                    />
                </div>
            </div>
        </>
    );
};

export default ApplicantsList;
