import React, { useEffect, useState } from "react";
import {
  CarryOutOutlined,
  CheckOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { Select, Switch, Tree } from "antd";
import {
  getActivitiesCategories,
  getActivitiesCategory,
} from "../../services/axios";
import { PulseLoader } from "react-spinners";
import { getMenuItem } from "../../components/drawerMenuAntd";
import { Formik } from "formik";
import DynamicInput from "../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../utils/handleCustomChange";
import CreateCategoriesModal, {
  CreateCategoriesForm,
} from "../../containers/modals/CreateCategoriesModal";
import ButtonComponent from "../../components/buttons/ButtonComponent";

function ActivitiesCategoriesDefinitions() {
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [resultCategories, setResultCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [isDataChange, setIsDataChange] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [allCategories, setAllCategories] = useState([]);

  const onSelect = async (selectedKeys, info) => {
    setFormLoading(true);
    // console.log("selected", selectedKeys, info);
    try {
      const result = await getActivitiesCategory(selectedKeys); //tek kategori çağrıma
      setSelectedCategory(result?.data?.category);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setFormLoading(false);
    }

    // console.log(
    //   resultCategories?.filter((itemF, indexF) => itemF?.id == selectedKeys),
    //   "klavye"
    // );
    // console.log(
    //   resultCategories.flatMap((item) =>
    //     item.sub_category.filter((iF) => iF.id == selectedKeys)
    //   ),
    //   "klavye 2"
    // );
  };
  // console.log(selectedCategory, "selectedCategory");

  const fetchGetActivitiesCategories = async () => {
    setTreeData([]);
    setLoading(true);
    try {
      const result = await getActivitiesCategories();
      // console.log(result, "result");
      result?.data?.categories.forEach((item) => {
        if (!item.up_category) {
          const treeItem = getTreeItem(
            item.id,
            item.title,
            item.id,
            <CarryOutOutlined />
          );
          const children = getChildren(item);
          treeItem.children = children;
          handleDataPush(treeItem);
        }
      });
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  function getChildren(category) {
    if (!category.sub_category || category.sub_category.length === 0) {
      return [];
    }
    return category.sub_category.map((item) => {
      const treeItem = getTreeItem(
        item.id,
        item.title,
        item.id,
        <CarryOutOutlined />
      );
      const children = getChildren(item);
      treeItem.children = children;
      return treeItem;
    });
  }

  function getTreeItem(id, title, key, icon, children = []) {
    return {
      id,
      title,
      key,
      icon,
      children,
    };
  }

  const handleDataPush = (item) => {
    setTreeData((prev) => [...prev, item]);
  };

  useEffect(() => {
    if (isDataChange) {
      fetchGetActivitiesCategories()
        .then((result) => setAllCategories(result?.data?.categories))
        .then((result) => setResultCategories(result?.data?.categories));
    }
  }, [isDataChange, selectedCategory]);

  // console.log(treeData, "treeData");

  function SelectedDataForm() {
    return (
      <>
        <CreateCategoriesForm
          isDataChange={isDataChange}
          setIsDataChange={setIsDataChange}
          selectedCategory={selectedCategory}
          loading={formLoading}
          allCategories={allCategories}
        />
      </>
    );
  }
  const { DirectoryTree } = Tree;
  // console.log(allCategories, "allCategories");
  return (
    <div>
      {loading ? (
        <>
          <div className="flex">
            <PulseLoader color="red" />
            <p>Yükleniyor...</p>
          </div>
        </>
      ) : null}
      {!loading && (
        <>
          <div className="flex gap-8">
            <div className="flex flex-col w-full max-w-[350px] border-r pr-4">
              <DirectoryTree
                      className="relative z-0"
                showLine={false}
                showIcon={false}
                defaultExpandedKeys={0}
                // defaultExpandAll
                onSelect={onSelect}
                treeData={treeData}
              />
              <ButtonComponent
                customStyle={"mt-4"}
                text={"Kategori Ekle"}
                outline
                onClick={() => {
                  setIsOpenModal(true);
                }}
              />
              {allCategories && (
                <CreateCategoriesModal
                  isOpen={isOpenModal}
                  setOpen={setIsOpenModal}
                  setIsDataChange={setIsDataChange}
                  allCategories={allCategories}
                />
              )}
            </div>
            <div className="flex flex-col w-full">
              <SelectedDataForm />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ActivitiesCategoriesDefinitions;
