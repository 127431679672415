import React, { useEffect, useState } from "react";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import MapComponent from "../../components/mapSelect";
import { handleCustomChange } from "../../utils/handleCustomChange";
import DynamicInput from "../../components/inputs/dynamicInput";
import {
  createFootballGround,
  deleteFootballGround,
  updateFootballGround
} from "../../services/axios";
import { Formik, useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

export function CreateFootballGroundForm({
  isOpen,
  setOpen,
  setIsDataChange,
  updateId,
  updateSelectedValuesProp,
  innerMenuSelected
}) {
  const [loading, setLoading] = useState(false);

  // const [updateSelectedValues, setUpdateSelectedValues] = useState();

  async function fetchCreateFootballGround(
    title,
    address,
    code,
    url,
    is_active,
    football_ground_type,
    rival_field
  ) {
    try {
      const result = await createFootballGround(
        title,
        address,
        code,
        url,
        is_active,
        football_ground_type,
        rival_field
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000
        });
        setLoading(true);
        setIsDataChange(true);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000
        });
      }

      // console.log(result, "result");
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  }

  async function fetchUpdateFootballGround(
    update_id,
    title,
    address,
    code,
    url,
    is_active,
    football_ground_type,
    rival_field
  ) {
    try {
      const result = await updateFootballGround(
        update_id,
        title,
        address,
        code,
        url,
        is_active,
        football_ground_type,
        rival_field
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Düzenleme Başarılı!",
          showConfirmButton: false,
          timer: 1000
        });
        setIsDataChange(true);
        setLoading(false);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000
        });
      }

      // console.log(result, "result");
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  }

  async function fetchDeleteFootballGround(id) {
    try {
      const result = await deleteFootballGround(id);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  }
// console.log(updateSelectedValuesProp, 'wwwwwwwwwwww')


  const initailData = () => {
    if(updateSelectedValuesProp) {

    }

}

  // useEffect(() => {
  //   setUpdateSelectedValues(updateSelectedValuesProp);
  // }, [updateSelectedValuesProp]);

  // console.log(updateSelectedValuesProp, "updateSelectedValuesProp");

  const groundType = [
    { label: "Doğal Çim", value: 1 },
    { label: "Suni Çim", value: 2 },
    { label: "Hibrit Çim", value: 3 },
    { label: "Diğer Spor Alanı", value: 4 },
    { label: "Dershane", value: 5 }
  ];
  const validations = Yup.object().shape({
    // ... Other fields
    url: Yup.string().max(191, "Saha türü 191 karakterden uzun olmamalı")
    // .required("Saha türü zorunlu bir alandır"),
    // ... Other fields
  });

  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      title: updateSelectedValuesProp?.title ?? "",
      address: updateSelectedValuesProp?.address ?? "",
      code: updateSelectedValuesProp?.code ?? "",
      url: updateSelectedValuesProp?.url ?? "",
      is_active: updateSelectedValuesProp?.is_active ?? false,
      football_ground_type:
        groundType.find(
          (item) =>
            item?.value == updateSelectedValuesProp?.football_ground_type
        ) ?? "",
      rival_field: updateSelectedValuesProp?.rival_field ?? false
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      resetForm();
      setIsDataChange(true);
      values.football_ground_type = values?.football_ground_type?.value;
      try {
        let result = null;
        if (updateId) {
          result = await fetchUpdateFootballGround(
            updateId,
            values.title,
            values.address,
            values.code,
            values.url,
            values.is_active,
            values.football_ground_type,
            values.rival_field ? 1 : 0
          );
        } else {
          result = await fetchCreateFootballGround(
            values.title,
            values.address,
            values.code,
            values.url,
            values.is_active,
            values.football_ground_type,
            values.rival_field ? 1 : 0
          );
        }
        // if (result.success) {
        //   Swal.fire({
        //     position: "center",
        //     icon: "success",
        //     title: result?.message ?? "Ekleme Başarılı!",
        //     showConfirmButton: false,
        //     timer: 1000
        //   });
        //   setLoading(true);
        //   setIsDataChange(true);
        // } else {
        //   Swal.fire({
        //     position: "center",
        //     icon: "error",
        //     title: result?.message ?? "Ekleme Başarısız!",
        //     showConfirmButton: false,
        //     timer: 1000
        //   });
        // }
        setLoading(false);
      } catch (error) {
        console.error("Hata oluştu:", error);
        setLoading(false);
      }
    },
    validationSchema: validations
  });

  const handleDeleteOnClick = async (updateSelectedValuesProp) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${updateSelectedValuesProp.title}" sahasını silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal"
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteFootballGround(updateSelectedValuesProp?.id);
        Swal.fire(
          "Silindi!",
          `"${updateSelectedValuesProp?.title}" sahası silindi.`,
          "success"
        );
        setIsDataChange(true);
      }
    });
  };

  // console.log(updateSelectedValuesProp, "updateSelectedValuesProp");

  return (

    <form onSubmit={handleSubmit} className="w-full">
      <div className="flex flex-col w-full gap-4 pt-4 pb-8">
        {/* {selectedLocaitonData.city} */}
        <DynamicInput
          labelTitle={"Tesis Adı"}
          containerStyle={"w-full"}
          placeholder={updateSelectedValuesProp?.title ?? "Tesis Adı"}
          name={"title"}
          value={values}
          onChange={(e1, e2) => {
            handleCustomChange(e1, e2, "title", setFieldValue, "input");
          }}
          error={errors}
          isTouched={touched}
          onBlur={handleBlur}
        />

        <DynamicInput
          labelTitle={"Adres"}
          containerStyle={"w-full"}
          placeholder={updateSelectedValuesProp?.address ?? "Adres"}
          name={"address"}
          value={values}
          onChange={(e1, e2) => {
            handleCustomChange(e1, e2, "address", setFieldValue, "textarea");
          }}
          error={errors}
          isTouched={touched}
          onBlur={handleBlur}
          component_type={"textarea"}
        />
        <DynamicInput
          labelTitle={"Saha Kısa Adı"}
          containerStyle={"w-full"}
          placeholder={updateSelectedValuesProp?.code ?? "Saha Kısa Adı"}
          name={"code"}
          value={values}
          onChange={(e1, e2) => {
            handleCustomChange(e1, e2, "code", setFieldValue, "input");
          }}
          error={errors}
          isTouched={touched}
          onBlur={handleBlur}
        />
        <div className="">
          <DynamicInput
            labelTitle={"Google Maps URL"}
            containerStyle={"w-full"}
            placeholder={
              updateSelectedValuesProp?.url ??
              "https://goo.gl/maps/yL99rWnZW95X4Cnm8"
            }
            name={"url"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(e1, e2, "url", setFieldValue, "input");
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
          />
          <i className="text-xs text-gray-500">
            Google Haritalar'da seçtiğiniz alanın "Paylaş" alınındaki
            kısaltılmış URL'i girmeniz gerekmektedir!
          </i>
        </div>
        <DynamicInput
          labelTitle={"Saha Türü"}
          containerStyle={"w-full"}
          placeholder={"Saha Türü"}
          name={"football_ground_type"}
          value={values}
          onChange={(e1, e2) => {
            handleCustomChange(
              e1,
              e2,
              "football_ground_type",
              setFieldValue,
              "select"
            );
          }}
          error={errors}
          isTouched={touched}
          onBlur={handleBlur}
          component_type={"select"}
          customOptions={groundType}
        />
        <DynamicInput
          labelTitle={"Rakip Saha"}
          containerStyle={"w-full"}
          placeholder={"Rakip Saha"}
          name={"rival_field"}
          value={values}
          onChange={(e1, e2) => {
            handleCustomChange(
              e1,
              e2,
              "rival_field",
              setFieldValue,
              "checkbox"
            );
          }}
          error={errors}
          isTouched={touched}
          onBlur={handleBlur}
          component_type={"checkbox"}
        />
        {updateSelectedValuesProp && (
          <>
            <DynamicInput
              labelTitle={"Görünürlük Aktif Mi?"}
              containerStyle={"w-full"}
              placeholder={"Görünürlük Aktif Mi?"}
              name={"is_active"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "is_active",
                  setFieldValue,
                  "checkbox"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"checkbox"}
            />
          </>
        )}
      </div>
      <div className="flex items-center justify-end gap-4">
        {!updateSelectedValuesProp && (
          <ButtonComponent
            onClick={() => {
              setOpen(false);
              resetForm();
            }}
            outline
            text={"Vazgeç"}
          />
        )}
        <div className="flex justify-start w-full">
          <ButtonComponent
            onClick={() => handleDeleteOnClick(updateSelectedValuesProp)}
            text={"Sahayı Sil"}
            outline
            customStyle={"w-fit"}
          />
        </div>
        <ButtonComponent
          onClick={() => {
            setOpen(false);
          }}
          submit
          fill
          text={"Kaydet"}
        />
      </div>
    </form>
  );
}

function CreateFootballGroundModal({ isOpen, setOpen, setIsDataChange }) {
  return (
    <div>
      <CustomModal
        //faaliyet adresi ekleme
        width={677}
        isOpen={isOpen}
        setOpen={setOpen}
        footer={null}
      >
        <CustomModalHeader
          title={"Yeni Saha Ekleyin"}
          description={"Aşağıdan yeni saha ekleyin."}
        />
        <CreateFootballGroundForm
          isOpen={isOpen}
          setOpen={setOpen}
          setIsDataChange={setIsDataChange}
        />
      </CustomModal>
    </div>
  );
}

export default CreateFootballGroundModal;
