import React from "react";
import {useState, useEffect} from "react";
import {PulseLoader} from "react-spinners";
import AddApplicantForm from "./form";

const AddApplicant = () => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            {loading ? (
                <div className="flex">
                    <PulseLoader color="red"/>
                    <p>Yükleniyor...</p>
                </div>
            ) : (
                <>
                    <AddApplicantForm/>
                </>
            )}
        </>
    );
};

export default AddApplicant;
