import { Formik } from "formik";
import React from "react";
import { handleCustomChange } from "../../../utils/handleCustomChange";
import DynamicInput from "../../../components/inputs/dynamicInput";
import ButtonComponent from "../../../components/buttons/ButtonComponent";

const userInformationData = [
  {
    type: "text",
    labelTitle: "Kullanıcı Kodu",
    placeholder: "Kullanıcı Kodu",
    name: "userCode",
    required: true,
    component_type: "input",
    options: "",
  },
  {
    type: "text",
    labelTitle: "Kullanıcı Adı",
    placeholder: "Kullanıcı Adı",
    name: "userName",
    required: true,
    component_type: "input",
    options: "",
  },
  {
    type: "text",
    labelTitle: "Şifre",
    placeholder: "Şifre",
    name: "userPassword",
    required: true,
    component_type: "input",
    options: "",
  },
  {
    type: "text",
    labelTitle: "Şifre Tekrar",
    placeholder: "Şifre Tekrar",
    name: "userConfirmPassword",
    required: true,
    component_type: "input",
    options: "",
  },
  {
    type: "text",
    labelTitle: "Pasif mi?",
    placeholder: "Pasif mi?",
    name: "userIsActive",
    required: true,
    component_type: "checkbox",
    options: "",
  },
];

function UserInformationForm() {
  return (
    <div className="pt-4">
      <Formik
        initialValues={{
          userCode: "",
          userName: "",
          userPassword: "",
          userConfirmPassword: "",
          userIsActive: false,
        }}
        onSubmit={(values, { resetForm }) => {
          console.log(values, "values");
          resetForm();
        }}
        validate={(values) => {
          const errors = {};

          if (!values.userPassword) {
            errors.userPassword = "Şifre alanı boş olamaz";
          }

          if (!values.userConfirmPassword) {
            errors.userConfirmPassword = "Şifre tekrar alanı boş olamaz";
          } else if (values.userPassword !== values.userConfirmPassword) {
            errors.userConfirmPassword = "Şifreler eşleşmiyor";
          }

          // Tüm alanların zorunlu olduğunu doğrula
          // Object.keys(values).forEach((key) => {
          //   if (!values[key]) {
          //     errors[key] = "Bu alan boş bırakılamaz";
          //   }
          // });

          userInformationData.forEach((input) => {
            if (input.required && !values[input.name]) {
              errors[input.name] = "Bu alan boş bırakılamaz";
            }
          });

          console.log(errors, "hata");
          return errors;
        }}
      >
        {({
          errors,
          values,
          handleSubmit,
          touched,
          handleBlur,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 xl:grid-cols-2">
              {userInformationData.map((item, index) => {
                return (
                  <DynamicInput
                    type={item.type}
                    labelTitle={item.labelTitle}
                    containerStyle={"w-full"}
                    placeholder={item.placeholder}
                    name={item.name}
                    value={values}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                    onChange={(e1, e2) => {
                      handleCustomChange(
                        e1,
                        e2,
                        item.name,
                        setFieldValue,
                        item.component_type
                      );
                    }}
                    required={item.required}
                    component_type={item.component_type}
                    options={item.options}
                    checkboxLabel={item.checkboxLabel}
                  />
                );
              })}
             
            </div>
            <div className="flex justify-end mt-4">
              <ButtonComponent submit text={"Kaydet"} fill />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default UserInformationForm;
