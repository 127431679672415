import React from "react";
// örnek kullanım
// <TabbarComponent
//   type="card"
//   setSelectedTabId={setSelectedTabId}
//   selectedTabId={selectedTabId}
//   data={[{ title: "Şablon 1" }, { title: "Şablon 2" }]}
// />;
function TabbarComponent({
  data,
  selectedTabId,
  setSelectedTabId,
  type,
  onClick,
}) {
  const handleOnClick = (index, itemOnClick) => {
    setSelectedTabId(index);
    if (itemOnClick && typeof itemOnClick === "function") {
      itemOnClick();
    }
  };
  return (
    <>
      <div className="flex gap-2 overflow-x-auto">
        {data.map((item, index) => {
          return (
            <div key={item?.id ?? index}>
              {type == "card" && (
                <div
                  onClick={() => handleOnClick(item?.id ?? index, item.onClick)}
                  className={`flex items-center gap-2 px-4 py-2 text-base rounded-t-lg cursor-pointer w-fit border border-b-0 ${
                    (item?.id ?? index) == selectedTabId
                      ? "bg-white text-primary border-primary"
                      : "bg-gray-200 text-gray-500 hover:text-gray-700 border-transparent"
                  }`}
                >
                  {item.icon && <div className="">{item.icon}</div>}
                  <p className="truncate">{item.title}</p>
                </div>
              )}

              {type == "button" && (
                <div
                  onClick={() => handleOnClick(item?.id ?? index, item.onClick)}
                  className={`flex items-center gap-2 px-4 py-2 text-base rounded-lg cursor-pointer w-fit  ${
                    (item?.id ?? index) == selectedTabId
                      ? "bg-primary text-white"
                      : "bg-gray-200 text-gray-500"
                  }`}
                >
                  {item.icon && <div className="">{item.icon}</div>}
                  <p>{item.title}</p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default TabbarComponent;
