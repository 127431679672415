import React, { useEffect, useState } from "react";
import { Paths } from "../../../routes";
import { DataTable } from "../../../components/tables";
import { Input } from "antd";
import ActionButtonsDataTable from "../../../containers/sections/ActionButtonsDataTable";
import {
  getSeasons,
  getYellowCardStatistics,
  listTeams,
} from "../../../services/axios";
import Select from "react-select";




const columns = [
  { title: "Oyuncu", dataIndex: "playerName" },
  { title: "Sezon", dataIndex: "season" },
  { title: "Takım", dataIndex: "team" },
  { title: "Maç Tarihi", dataIndex: "matchDate" },
  {
    title: "İşlem",
    key: "action",
    width: "",
    render: (item) => {
      return (
        <ActionButtonsDataTable
          id={item.key}
          toDetail={Paths.Users.Players.EditPlayers}
          detailButton
        />
      );
    },
  },
];

const YellowCards = () => {
  const [isDataChange, setIsDataChange] = useState(true);
  const [dataTableData, setDataTableData] = useState([]);
  const [teams, setTeams] = useState([{ label: "Tümü", value: null }]);
  const [playerStatisticData, setPlayerStatisticData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState({
    label: "Tümü",
    value: null,
  });

  const [playerStatisticFilterData, setPlayerStatisticFilterData] = useState({
    startDate: null,
    endDate: null,
    searchTerm: null,
    seasonId: {
      label: "Tümü",
      value: null,
    },
    teamId: {
      label: "Tümü",
      value: null,
    },
  });


  const [allSeasonsOptions, setAllSeasonsOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);

  const fetchGetAllSeasons = async () => {
    setLoading(true);
    try {
      const result = await getSeasons();

      let allSeasonsOptions = result.data.seasons.map((item) => ({
        label: item.season_title,
        value: item.id,
      }));

      const defaultOption = {
        label: "Tümü",
        value: null,
      };

      allSeasonsOptions.unshift(defaultOption);

      setAllSeasonsOptions(allSeasonsOptions);
      setLoading(false);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchGetPlayerStatistic = async () => {
    setLoading(true);
    try {
      const result = await getYellowCardStatistics(
        playerStatisticFilterData.startDate,
        playerStatisticFilterData.endDate,
        playerStatisticFilterData.searchTerm,
        playerStatisticFilterData.seasonId.value,
        playerStatisticFilterData.teamId.value
      );
      setPlayerStatisticData(result?.data);
      const resultDataTableData = result?.data.map((item) => ({
        key: item?.playerOne?.id,
        playerName: item?.playerOne?.name ? item?.playerOne?.name : "-",
        season: item?.season?.season ? item?.season?.season : "-",
        team: item?.team?.name ? item?.team?.name : "-",
        matchDate: item?.match?.matchDate ? item?.match?.matchDate : "-",
      }));

      setDataTableData(resultDataTableData);
      setLoading(false);
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  const fetchGetTeams = async () => {
    try {
      const results = await listTeams();
      setTeams((prevState) => [
        ...prevState,
        ...results?.data.teams.map((item) => ({
          label: item.team_title + " | " + item.team_category.category_title,
          value: item.id,
        })),
      ]);
    } catch (error) {
      console.log("hata", error);
    }
  };

  useEffect(() => {
    isDataChange && fetchGetPlayerStatistic();
  }, [isDataChange, playerStatisticFilterData]);

  useEffect(() => {
    setIsDataChange(true);
  }, [playerStatisticFilterData]);

  useEffect(() => {
    fetchGetAllSeasons();
    fetchGetTeams();
  }, []);
  let timer;

  useEffect(() => {
    timer = setTimeout(() => {
      setPlayerStatisticFilterData((prevState) => ({
        ...prevState,
        searchTerm: searchTerm,
      }));
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchTerm]);
  return (
    <>
      <div className="w-full p-4 bg-white rounded-md shadow">
        <div className="flex items-center justify-between text-gray-500"></div>
        <div className="flex flex-wrap-reverse items-center justify-between px-2 py-2 my-4 bg-gray-100 rounded-lg">
          <div className="flex items-center w-full sm:w-auto gap-3">
            <div className="">
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                Tarih Aralığı:
              </span>
              <div className="flex items-center w-full sm:w-auto">
                <Input
                  type="date"
                  onChange={(e) =>
                    setPlayerStatisticFilterData((prevState) => ({
                      ...prevState,
                      startDate: e.target.value,
                    }))
                  }
                />
                <span className="mx-2">-</span>
                <Input
                  type="date"
                  onChange={(e) =>
                    setPlayerStatisticFilterData((prevState) => ({
                      ...prevState,
                      endDate: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="" style={{ minWidth: "150px" }}>
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                Sezon:
              </span>
              <Select
                onChange={(e) =>
                  setPlayerStatisticFilterData((prevState) => ({
                    ...prevState,
                    seasonId: e,
                  }))
                }
                value={playerStatisticFilterData.seasonId}
                options={allSeasonsOptions ?? []}
              />
            </div>
            <div className="" style={{ minWidth: "150px" }}>
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                Takımlar:
              </span>
              <Select
                options={teams}
                value={selectedTeam}
                onChange={(e) => {
                  setPlayerStatisticFilterData((prevState) => ({
                    ...prevState,
                    teamId: e,
                  }));
                  setSelectedTeam(e);
                }}
              />
            </div>
          </div>
          <div className="flex items-center w-full sm:w-auto">
            <Input
              type="text"
              placeholder="Tabloda ara.."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="">
          <DataTable
            columns={columns}
            data={dataTableData?.reverse() ?? []}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default YellowCards;
