import React, { useEffect, useState } from "react";

import { Paths } from "../../../routes";
import {
  getCities,
  getDynamicForm,
  getPlayerReports,
  getSeasons
} from "../../../services/axios";
import { DataTable } from "../../../components/tables";
import { Input } from "antd";
import { selectedPlayerStatuses } from "../../../components/attendance/options";
import Select from "react-select";
import ActionButtonsDataTable from "../../../containers/sections/ActionButtonsDataTable";

const PlayerReports = () => {
  const [dataTableData, setDataTableData] = useState([]);

  const [allCtiy, setAllCity] = useState([{ label: "Tümü", value: null }]);

  const [selectedAllCity, setSelectedAllCity] = useState({
    label: "Tümü",
    value: null
  });
  const [dataTableColumns, setDataTableColumns] = useState([]);

  const [loading, setLoading] = useState(false);

  const [axiosFilters, setAxiosFilters] = useState({
    label: "Tümü",
    value: null
  });

  const [allSeasonsOptions, setAllSeasonsOptions] = useState({
    label: "Seçiniz",
    value: null
  });

  const [searchTerm, setSearchTerm] = useState([]);

  const [selectedOption, setSelectedOption] = useState({
    label: "Şehir ve Bölge",
    value: "CITY_AND_REGION"
  });

  const [selectedAttendanceOption, setSelectedAttendanceOption] = useState({
    label: "Tümü",
    value: null
  });

  const [selectedTeamOption, setselectedTeamOption] = useState({
    label: "Tümü",
    value: null
  });

  const [cityOptions, setCityOptions] = useState([
    { label: "Tümü", value: null }
  ]);

  const [cityOptionsSelected, setCityOptionsSelected] = useState([
    { label: "Tümü", value: null }
  ]);

  const [familyMartialStatusOptions, setFamilyMartialStatusOptions] = useState([
    { label: "Tümü", value: null }
  ]);

  const [
    familyMartialStatusOptionsSelected,
    setFamilyMartialStatusOptionsSelected
  ] = useState([{ label: "Tümü", value: null }]);

  const columnsRegion = [
    { title: "Oyuncu", dataIndex: "playerOneName" },
    { title: "Şehir", dataIndex: "city" },
    { title: "Bölge", dataIndex: "region" },
    { title: "TC No", dataIndex: "tckn" },

    {
      title: "İşlem",
      key: "action",
      width: "",
      render: (item) => {
        return (
          <ActionButtonsDataTable
            id={item.key}
            toDetail={Paths.Users.Players.EditPlayers}
            detailButton
          />
        );
      }
    }
  ];
  const columnsAttendance = [
    { title: "Oyuncu", dataIndex: "playerName" },
    { title: "Yoklama Tipi", dataIndex: "attendance" },
    {
      title: "Tekrar",
      dataIndex: "attendanceCount",
      className: "text-red-400 font-bold"
    },
    {
      title: "İşlem",
      key: "action",
      width: "",
      render: (item) => {
        return (
          <ActionButtonsDataTable
            id={item.playerId}
            toDetail={Paths.Users.Players.EditPlayers}
            detailButton
          />
        );
      }
    }
  ];
  const columnsFamilyMartialStatus = [
    { title: "Oyuncu", dataIndex: "playerOneName" },
    { title: "TC No", dataIndex: "tckn" },
    { title: "Veli Medeni Durum", dataIndex: "martialStatus" },
    {
      title: "İşlem",
      key: "action",
      width: "",
      render: (item) => {
        return (
          <ActionButtonsDataTable
            id={item.key}
            toDetail={Paths.Users.Players.EditPlayers}
            detailButton
          />
        );
      }
    }
  ];

  const handleChange = (e) => {
    setSelectedOption(e);
  };

  const fetchGetAllSeasons = async () => {
  
    try {
      const result = await getSeasons();
      let allSeasonsOptions = result.data.seasons.map((item) => ({
        label: item.season_title,
        value: item.id
      }));

      const defaultOption = {
        label: "Tümü",
        value: null
      };

      allSeasonsOptions.unshift(defaultOption);
      setAllSeasonsOptions(allSeasonsOptions);
    } catch (error) {
      console.log("hata", error);
    } 
  };

  const fetchGetPlayerReports = async () => {
setLoading(true)
    try {
      const result = await getPlayerReports(
        axiosFilters.reportType,
        axiosFilters.searchTerm,
        axiosFilters.cityFilter,
        axiosFilters.regionFilter,
        axiosFilters.attendanceStatusFilter,
        axiosFilters.martialStatusFilter,
        axiosFilters.seasonId?.value
      );

      if (result?.data?.type === "CITY_AND_REGION") {
        const resultCityAndRegionData = result.data.data.map((user) => ({
          key: user.userId ?? "",
          playerOneName: user.name ?? "-",
          city: user.city ?? "-",
          region: user.region ?? "-",
          tckn: user.tckn ?? "-",
          userId: user.userId ?? "-"
        }));
        setDataTableData(resultCityAndRegionData);

      }
       else if (result?.data?.type === "FAMILY_MARTIAL_STATUS") {
        const resultFamilyMartialStatus = result.data.data.map((user) => ({
          key: user.id,
          playerOneName: user.name ? user.name : "-",
          tckn: user.tckn ? user.tckn : "-",
          martialStatus: user.martialStatus.value
            ? user.martialStatus.value
            : "-"
        }));
        setDataTableData(resultFamilyMartialStatus);
      }
       else if (result.data.type === "ATTENDANCE") {
        const resultAttendance = result?.data?.data.map(
          (attendance, index) => ({
            key: ++index,
            playerId: attendance?.user?.id,
            playerName: attendance?.user?.name ?? "-",
            attendance: attendance?.attendance ?? "-",
            attendanceCount: attendance?.attendanceCount ?? "-"
          })
        );
        setDataTableData(resultAttendance);
        }
    } catch (error) {
      setLoading(false)
      console.error("Hata oluştu:", error);
    } finally {
    setLoading(false)
    }
  };

  const fetchGetDynamicForm = async () => {
   
    try {
      let result = await getDynamicForm("add-player-personal-info");
      result?.response.form_elements.map((item) => {
        if (item?.id == 10) {
          //11
          item?.options_json?.options?.options.map((item2) => {
            setCityOptions((prevState) => [
              ...prevState,
              { label: item2?.label, value: item2?.id }
            ]);
          });
        }
      });
    } catch (error) {
      console.error("Hata oluştu:", error);
    } 
  };

  const fetchGetFamilyMartialStatus = async () => {
   
    try {
      let result = await getDynamicForm("add-parrent-info");
      result?.response.form.form_elements.map((item) => {
        if (item?.id == 90) {
          item?.options_json?.options?.options.map((item2) => {
            setFamilyMartialStatusOptions((prevState) => [
              ...prevState,
              { label: item2?.label, value: item2?.id }
            ]);
          });
        }
      });
    } catch (error) {
      console.error("Hata oluştu:", error);
    }
  };

  const fetchGetCity = async () => {
  
    try {
      const result = await getCities();
      let allCity = result.data.data.map((item) => ({
        label: item.name,
        value: item.id
      }));
      setAllCity((prevState) => [
        ...prevState,
        ...allCity
      ]);
    } catch (error) {
      console.log("hata", error);
    }
  };

  useEffect(() => {
    fetchGetFamilyMartialStatus();
    fetchGetAllSeasons();
    fetchGetCity();
    fetchGetDynamicForm();
  }, []);


  const handleAttendanceFilterSelect = (e) => {
    setAxiosFilters({
      reportType: "ATTENDANCE",
      serachTerm: searchTerm,
      attendanceStatusFilter: e.value
    });
  };

  let timer;
  useEffect(() => {
    timer = setTimeout(() => {
      setAxiosFilters((prevState) => ({
        ...prevState,
        searchTerm: searchTerm
      }));
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchTerm]);

  useEffect(() => {
   
    if (selectedOption.value === "CITY_AND_REGION") {
      setAxiosFilters({
        reportType: "CITY_AND_REGION",
        searchTerm: searchTerm,
        cityFilter: null,
        regionFilter: null
      });
      setDataTableColumns(columnsRegion);
    }
     else if (selectedOption.value === "ATTENDANCE") {
      setAxiosFilters({
        reportType: "ATTENDANCE",
        serachTerm: searchTerm,
        attendanceStatusFilter: null,
        seasonId: {
          label: "Tümü",
          value: null
        }
      });
      setDataTableColumns(columnsAttendance);
    } else if (selectedOption.value === "FAMILY_MARTIAL_STATUS") {
      setAxiosFilters({
        reportType: "FAMILY_MARTIAL_STATUS",
        serachTerm: searchTerm,
        martialStatusFilter: null
      });
      setDataTableColumns(columnsFamilyMartialStatus);
    }
  }, [selectedOption]);

  useEffect(() => {
    fetchGetPlayerReports();
  }, [axiosFilters]);

  return (
    <>
      <div className="w-full p-4 bg-white rounded-md shadow">
        <div className="flex items-center justify-between text-gray-500">
          <h4 className="text-lg font-semibold">Raporlar</h4>
        </div>
        <div className="" style={{ minWidth: "150px" }}>
          <span className="w-full text-sm text-gray-500 sm:block">Seçim</span>
          <Select
            onChange={(e) => {
              handleChange(e);
            }}
            options={[
              { label: "Şehir ve Bölge", value: "CITY_AND_REGION" },
              { label: "Yoklama", value: "ATTENDANCE" },
              { label: "Veli Medeni Durum", value: "FAMILY_MARTIAL_STATUS" }
            ]}
            value={selectedOption}
          />
        </div>
        <div className="flex flex-wrap-reverse items-center justify-between px-2 py-2 my-4 bg-gray-100 rounded-lg">
          <div className="flex items-center w-full sm:w-auto gap-3">
            {selectedOption.value === "CITY_AND_REGION" && (
              <>
                <div className="" style={{ minWidth: "150px" }}>
                  <span className=" w-full text-sm text-gray-500 sm:block">
                    Bölge:
                  </span>
                  <Select
                    onChange={(e) => {
                      setAxiosFilters({
                        reportType: "CITY_AND_REGION",
                        serachTerm: searchTerm,
                        regionFilter: e.value
                      });
                      setCityOptionsSelected(e);
                    }}
                    value={cityOptionsSelected}
                    options={cityOptions}
                  />
                </div>
                <div className="" style={{ minWidth: "150px" }}>
                  <span className=" w-full text-sm text-gray-500 sm:block">
                    Şehir:
                  </span>
                  <Select
                    onChange={(e) => {
                      setAxiosFilters((prevState) => ({
                        ...prevState,
                        cityFilter: e.value
                      }));
                      setSelectedAllCity(e);
                    }}
                    value={selectedAllCity}
                    options={allCtiy}
                  />
                </div>
              </>
            )}

            {selectedOption.value === "ATTENDANCE" && (
              <>
                <div className="" style={{ minWidth: "150px" }}>
                  <span className=" w-full text-sm text-gray-500 sm:block">
                    Yoklama
                  </span>
                  <Select
                    options={selectedPlayerStatuses(true)}
                    value={selectedAttendanceOption}
                    onChange={(e) => {
                      handleAttendanceFilterSelect(e);
                      setSelectedAttendanceOption(e);
                    }}
                  />
                </div>
                <div className="hidden" style={{ minWidth: "150px" }}>
                  <span className="hidden w-full text-sm text-gray-500 sm:block">
                    Sezon:
                  </span>
                  <Select
                    onChange={(e) => {
                      setAxiosFilters((prevState) => ({
                        ...prevState,
                        seasonId: e
                      }));
                      setselectedTeamOption(e);
                    }}
                    value={selectedTeamOption}
                    options={allSeasonsOptions ?? []}
                  />
                </div>
              </>
            )}
            {selectedOption.value === "FAMILY_MARTIAL_STATUS" && (
              <>
                <div className="" style={{ minWidth: "150px" }}>
                  <span className=" w-full text-sm text-gray-500 sm:block">
                    Aile Medeni Durumu
                  </span>
                  <Select
                    onChange={(e) => {
                      setAxiosFilters({
                        reportType: "FAMILY_MARTIAL_STATUS",
                        serachTerm: searchTerm,
                        martialStatusFilter: e.value
                      });
                      setFamilyMartialStatusOptionsSelected(e);
                    }}
                    options={familyMartialStatusOptions}
                    value={familyMartialStatusOptionsSelected}
                  />
                </div>
              </>
            )}

            <div className="" style={{ minWidth: "150px" }}></div>
          </div>

          <div className="flex items-center w-full sm:w-auto">
            <Input
              type="text"
              placeholder="Arama"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <DataTable
            columns={dataTableColumns}
            data={dataTableData ?? []}
            loading={loading }
          />
        </div>
      </div>
    </>
  );
};

export default PlayerReports;
