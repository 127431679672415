import React, { useState } from "react";
import { FootballField } from "../../utils/filePath";
import { useRef } from "react";
import { motion } from "framer-motion";
import { BsFillPersonFill } from "react-icons/bs";

function StartingLineup() {
  const constraintsRef = useRef(null);
  const [players, setPlayers] = useState([
    {
      id: 0,
      position: {
        x: 220, //220
        y: 30, //30
      },
    },
    {
      id: 1,
      position: {
        x: 50,
        y: 175,
      },
    },
    {
      id: 2,
      position: {
        x: 150,
        y: 175,
      },
    },
    {
      id: 3,
      position: {
        x: 300,
        y: 175,
      },
    },
    {
      id: 4,
      position: {
        x: 400,
        y: 175,
      },
    },
    {
      id: 5,
      position: {
        x: 50,
        y: 330,
      },
    },
    {
      id: 6,
      position: {
        x: 150,
        y: 330,
      },
    },
    {
      id: 7,
      position: {
        x: 300,
        y: 330,
      },
    },
    {
      id: 8,
      position: {
        x: 400,
        y: 330,
      },
    },
    {
      id: 9,
      position: {
        x: 150,
        y: 500,
      },
    },
    {
      id: 10,
      position: {
        x: 300,
        y: 500,
      },
    },
  ]);
  // delta her hakette hareket miktarını veren koordinat verisi
  // biz de bu veriyi sürükleme işlemi boyunca alıp eski değerle topluyoruz
  // yeni değerleri olan position objesini ilgili oyuncunun id'isi ile güncelliyoruz
  const handleDrag = (event, info, index) => {
    const offsetX = info.delta.x;
    const offsetY = info.delta.y;
    setPlayers((prevPlayers) => {
      const updatedPlayers = [...prevPlayers];
      const oldX = updatedPlayers[index].position.x;
      const oldY = updatedPlayers[index].position.y;
      let newX = oldX + offsetX;
      let newY = oldY + offsetY;
      // X koordinatı sınırları
      if (newX < 20) {
        newX = 20;
      }
      if (newX > 420) {
        newX = 420;
      }
      // Y koordinatı sınırları
      if (newY < 20) {
        newY = 20;
      }
      if (newY > 660) {
        newY = 660;
      }
      updatedPlayers[index] = {
        ...updatedPlayers[index],
        position: { x: newX, y: newY },
      };
      return updatedPlayers;
    });
  };
  const handleDragEnd = (event, info, index) => {
    console.log(players, "players");
  };
  return (
    <div>
      <div className="flex justify-center w-full p-4 overflow-x-auto bg-white rounded-md shadow">
        <div className="relative w-[500px] h-[750px]">
          <img
            className="absolute z-0 w-full h-full select-none"
            src={FootballField}
          />
          <div
            className="relative w-[500px] h-[750px] bg-white/10 z-20"
            ref={constraintsRef}
          >
            {/* players */}
            {players.map((item, index) => {
              return (
                <motion.div
                  key={index}
                  className="absolute p-2 text-white border-2 border-white rounded-lg bg-primary w-14"
                  drag
                  // dragTransition={{ bounceStiffness: 0, bounceDamping: 0 }}
                  dragElastic={0} //dışarı taşma animasyonunu sıfırlar
                  dragMomentum={false} //bıraktıktan sonra süzülmeyi sıfırlar
                  dragConstraints={constraintsRef}
                  onDrag={(event, info) => handleDrag(event, info, item.id)}
                  onDragEnd={(event, info) => handleDragEnd(event, info, index)}
                  style={{
                    x: item.position.x,
                    y: item.position.y,
                  }}
                >
                  <div className="flex flex-col items-center justify-center">
                    <BsFillPersonFill className="w-6 h-6" />
                    <p className="text-xl font-semibold">{item.id}</p>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartingLineup;
