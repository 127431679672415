import React from "react";
import {useState, useEffect} from "react";
import InputComponent from "../../../components/inputs";
import {PulseLoader} from "react-spinners";
import PageTitle from "../../../components/pageTitle";
import {Formik} from "formik";
import {
    BsFillPenFill, BsFillExclamationCircleFill
} from "react-icons/bs";
import {
    candidateIsConfirmedUpdate,
    deleteCandidate,
    getAllClub,
    getAllUser,
    getCities,
    getSelectOptions, getSelects,
    listTeams,
    updateCandidate
} from "../../../services/axios";
import {handleCustomChange} from "../../../utils/handleCustomChange";
import DynamicInput from "../../../components/inputs/dynamicInput";
import Swal from "sweetalert2";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import {PositionData} from "../../../utils/datas/positionData";

const EditApplicantForm = ({userId, candidateData, setIsDataChange}) => {
    const [loading, setLoading] = useState(false);
    const [selectLoading, setSelectLoading] = useState(false);
    const [optionTeams, setOptionTeams] = useState({});
    const [followerOptions, setFollowerOptions] = useState({});
    const [getAllClubOptions, setGetAllClubOptions] = useState([]);
    const [regions, setRegions] = useState([]);
    const [allCity, setAllCity] = useState([{label: "Seçiniz", value: null}]);
    const [positionOptions, setPositionOptions] = useState([]);
    const [FootOptions, setFootOptions] = useState([]);
    const [competitionLevel, setCompetitionLevel] = useState([]);
    const [inputsDisabled, setInputsDisabled] = useState(false);
    const [resultOptions, setResultOptions] = useState([]);

    const convertWatchStatus = (value) => {
        switch (value){
            case "izlendi":
                return "İzlendi"
            case "izlenmedi":
                return "İzlenmedi";
            case "gorusme-yapildi":
                return "Görüşme Yapıldı";
            default:
                return "";
        }
    }
    const fetchGetTeams = async () => {
        setSelectLoading(true);
        try {
            const res = await listTeams(userId);
            let teamOptions = res.data.teams.map((item) => ({
                label: item.team_title,
                value: item.id
            }));
            setOptionTeams(teamOptions);
        } catch (error) {
            console.log(error, "error!");
        } finally {
            setSelectLoading(false);
        }
    };

    const fetchDeleteCandidate = async () => {
        setSelectLoading(true);
        try {
            const result = await deleteCandidate(userId);
            return  result;
        } catch (error) {
            console.log(error, "error!");
        } finally {
            setSelectLoading(false);
        }
    };

    const handleCandidateDeleteOnClick = async (item) => {
        Swal.fire({
            title: "Silmek istediğinize emin misiniz?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Evet, sil!",
            cancelButtonText: "İptal"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const result = await fetchDeleteCandidate(item?.id);
                Swal.fire({
                    position: "center",
                    icon: result?.data?.success ? "success" : "warning",
                    html: result?.data?.message ? result?.data?.message : "Aday Oyuncu Silindi!",
                    showConfirmButton: false,
                    showCancelButton: false
                });
                if (result?.data?.success){
                    setTimeout(() => {
                        window.location.href = "/applicants";
                    }, 800);
                }

            }
        });
    };

    const fetchFollower = async () => {
        try {
            const result = await getAllUser("team_coach");
            const result2 = await getAllUser("goalkeeper_coach");
            const mergedResults = [...result?.data?.users, ...result2?.data?.users];

            let followerOptions = mergedResults.map((item, index) => ({
                label: item.user_name,
                value: item.id
            }));
            setFollowerOptions(followerOptions);
        } catch (error) {
            console.log("hata", error);
        }
    };

    const fetchGetSelects = async () => {
        try {
            const results = await getSelects();
            const usedFoot = results?.response?.find(
                (item) => item.id === 8
            );
            const result = results?.response?.find(
                (item) => item.id === 16
            );
            const usedFootOptions = usedFoot?.options?.map((item) => ({
                label: item?.label,
                value: item?.id
            }));
            const resultOptions = result?.options?.map((item) => ({
                label: item?.label,
                value: item?.id
            }));
            setFootOptions(usedFootOptions);
            setResultOptions(resultOptions);
        } catch (error) {
            console.log("hata", error);
        }
    };

    const handleCandidteIsConfirmedUpdate = async (isConfirmed) => {
        try {
            const res = await candidateIsConfirmedUpdate(userId, isConfirmed);
            setIsDataChange(true);
            if (res.success) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "İşlem Başarılı!",
                    html: res?.response.message ?? "İşlem Başarılı!",
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: "Kapat"
                });
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Hata Oluştu!",
                    html: res?.response.message ?? "Hata Oluştu!",
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: "Kapat"
                });
            }
        } catch (error) {
            console.log(error, "error");
        }
    };

    const position = () => {
        setPositionOptions([]);
        setPositionOptions((prevState) => [
            ...prevState,
            ...PositionData.map((item) => ({
                label: item.code,
                value: item.value
            }))
        ]);
    };

    const fetchGetRegions = async () => {
        try {
            const results = await getSelectOptions();
            console.log(results, "resultsresultsresults");
            const region = results?.data?.select_lists?.find(
                (item) => item.id === 13
            );
            const regionOptions = region?.options?.map((item) => ({
                label: item?.label,
                value: item?.id
            }));
            setRegions(regionOptions);
        } catch (error) {
            console.log("hata", error);
        }
    };

    const fetchGetMonitoredCompetitionLevel = async () => {
        try {
            const results = await getSelectOptions();
            console.log(results, "resultsresultsresults");
            const region = results?.data?.select_lists?.find((item) => item.id === 2);
            const competitionLevel = region?.options?.map((item) => ({
                label: item?.label,
                value: item?.value
            }));
            setCompetitionLevel(competitionLevel);
        } catch (error) {
            console.log("hata", error);
        }
    };

    const fetchGetCity = async () => {
        setLoading(true);
        try {
            const result = await getCities();
            setAllCity((prevState) => [
                ...prevState,
                ...result.data.data.map((item) => ({
                    label: item.name,
                    value: item.id
                }))
            ]);

            setLoading(false);
        } catch (error) {
            console.log("hata", error);
        }
    };

    const fetchGetAllClub = async () => {
        setLoading(true);
        try {
            const result = await getAllClub();
            setGetAllClubOptions(
                result.response.clubs.map((item) => ({
                    label: item.club_name,
                    value: item.id
                }))
            );
            return result;
        } catch (error) {
            console.log(error, "hata");
        } finally {
            setLoading(false);
        }
    };

    const fetchUpdateApplicantUser = async (
        name,
        surname,
        email,
        tckn,
        watchMatch,
        watchDate,
        monitoredTournament,
        notes,
        watchStatus,
        position,
        fkFootUsed,
        fkFollower,
        fkTeam,
        birthDate,
        clubId,
        cityId,
        fkRegion,
        recommendedPosition,
        height,
        weight,
        trainingAge,
        fkMonitoredCompetitionDifficulty,
        strengths,
        weaknesses,
        opportunities,
        threats,
        ratingByPlaceOfPlay,
        correspondingPointsToAlfa,
        fkResult
    ) => {
        setLoading(true);
        try {
            const result = await updateCandidate(
                name,
                surname,
                email,
                tckn,
                watchMatch,
                watchDate,
                monitoredTournament,
                notes,
                watchStatus,
                position,
                fkFootUsed,
                fkFollower,
                fkTeam,
                birthDate,
                clubId,
                cityId,
                fkRegion,
                recommendedPosition,
                height,
                weight,
                trainingAge,
                fkMonitoredCompetitionDifficulty,
                strengths,
                weaknesses,
                opportunities,
                threats,
                ratingByPlaceOfPlay,
                correspondingPointsToAlfa,
                fkResult,
                userId
            );
            setIsDataChange(true)

            if (result.success) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: result?.data?.message ?? "Düzenleme Başarılı!",
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: result?.data?.message ?? "Düzenleme Başarısız!",
                    showConfirmButton: false,
                    timer: 1000
                });
            }
            return result;
        } catch (error) {
            console.log(error, "hata");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGetMonitoredCompetitionLevel();
        fetchGetAllClub();
        fetchGetCity();
        fetchGetRegions();
        position();
        fetchGetSelects();
        fetchGetTeams();
        fetchFollower();
        setInputsDisabled(candidateData.isConfirmed)
    }, []);
    console.log(candidateData, "candidateData");
    return (
        <>
            {loading || selectLoading ? (
                <div className="flex">
                    <PulseLoader color="red"/>
                    <p>Yükleniyor...</p>
                </div>
            ) : (
                <Formik
                    initialValues={{
                        name: candidateData?.name ?? "",
                        surname: candidateData?.surname ?? "",
                        email: candidateData?.email ?? "",
                        tckn: candidateData?.tckn ?? "",
                        watchMatch: candidateData?.watchMatch ?? "",
                        watchDate: candidateData?.watchDate ?? "",
                        monitoredTournament: candidateData?.monitoredTournament ?? "",
                        notes: candidateData?.notes ?? "",

                        watchStatus: candidateData?.watchStatus
                            ? {
                                value: candidateData?.watchStatus?.id,
                                label: convertWatchStatus(candidateData?.watchStatus)
                            }
                            : "",

                        position: candidateData?.position
                            ? {
                                value: candidateData?.position,
                                label: PositionData.find(
                                    (item) => item.value === candidateData?.position
                                )?.code
                            }
                            : "",

                        fkFootUsed: candidateData?.foot_used
                            ? {
                                value: candidateData?.foot_used?.id,
                                label: candidateData?.foot_used?.label
                            }
                            : "",

                        fkFollower: candidateData?.follower
                            ? {
                                value: candidateData?.follower?.id,
                                label: candidateData?.follower?.user_name
                            }
                            : "",

                        fkTeam: candidateData?.team
                            ? {
                                value: candidateData?.team?.id,
                                label: candidateData?.team?.team_title
                            }
                            : "",

                        birthDate: candidateData?.birthDate ?? "",
                        clubId: candidateData?.club
                            ? {
                                value: candidateData?.club?.id,
                                label: candidateData?.club?.club_name
                            }
                            : "",

                        cityId: candidateData?.city
                            ? {
                                value: candidateData?.city?.id,
                                label: candidateData?.city?.name
                            }
                            : "",

                        fkRegion: candidateData?.region
                            ? {
                                value: candidateData?.region?.id,
                                label: candidateData?.region?.label
                            }
                            : "",

                        recommendedPosition: candidateData?.recommendedPosition
                            ? {
                                value: candidateData?.recommendedPosition,
                                label: PositionData.find((item) => item.value === candidateData?.recommendedPosition)?.code
                            }
                            : "",
                        height: candidateData?.height ?? "",
                        weight: candidateData?.weight ?? "",
                        trainingAge: candidateData?.trainingAge ?? "",

                        fkMonitoredCompetitionDifficulty:
                            candidateData?.monitored_competition_difficulty
                                ? {
                                    value: candidateData?.monitored_competition_difficulty?.id,
                                    label:
                                    candidateData?.monitored_competition_difficulty?.label
                                }
                                : "",
                        strengths: candidateData?.strengths ?? "",
                        weaknesses: candidateData?.weaknesses ?? "",
                        opportunities: candidateData?.opportunities ?? "",
                        threats: candidateData?.threats ?? "",
                        ratingByPlaceOfPlay: candidateData?.ratingByPlaceOfPlay
                            ? {
                                value: candidateData?.ratingByPlaceOfPlay,
                                label: candidateData?.ratingByPlaceOfPlay
                            } : "",
                        correspondingPointsToAlfa: candidateData?.correspondingPointsToAlfa
                            ? {
                                value: candidateData?.correspondingPointsToAlfa,
                                label: candidateData?.correspondingPointsToAlfa
                            } : "",
                        fkResult: candidateData?.result
                            ? {
                                value: candidateData?.result?.id,
                                label: candidateData?.result?.label
                            } : ""

                    }}
                    onSubmit={(values) => {
                        console.log(values, "valuessss");
                        fetchUpdateApplicantUser(
                            values.name,
                            values.surname,
                            values.email,
                            values.tckn,
                            values.watchMatch,
                            values.watchDate,
                            values.monitoredTournament,
                            values.notes,
                            values.watchStatus.value,
                            values.position.value,
                            values.fkFootUsed.value,
                            values.fkFollower.value,
                            values.fkTeam.value,
                            values.birthDate,
                            values.clubId.value,
                            values.cityId.value,
                            values.fkRegion.value,
                            values.recommendedPosition.value,
                            values.height,
                            values.weight,
                            values.trainingAge,
                            values.fkMonitoredCompetitionDifficulty.value,
                            values.strengths,
                            values.weaknesses,
                            values.opportunities,
                            values.threats,
                            values.ratingByPlaceOfPlay.value,
                            values.correspondingPointsToAlfa.value,
                            values.fkResult.value
                        );
                    }}
                >
                    {({
                          values,
                          handleSubmit,
                          errors,
                          setFieldValue,
                          touched,
                          handleBlur
                      }) => (
                        <form id={"primaryForm"} onSubmit={handleSubmit}>
                            <div className="mt-8">
                                <div>
                                    <div>
                                        <PageTitle
                                            icon={<BsFillPenFill className="text-2xl"/>}
                                            title={"Aday Güncelle"}
                                            bg
                                        />
                                    </div>
                                    <div>
                                        {candidateData?.isConfirmed == 1 && (
                                            <div className={"mb-3"}>
                                                <p className="px-6 py-3 text-2xl font-semibold text-white flex !items-center justify-center bg-slate-500 rounded-xl">
                                                    <span className="mr-[10px]"><BsFillExclamationCircleFill/></span>
                                                    Onaylanmış oyuncu olduğu için düzenleme yapılamaz.
                                                </p>
                                            </div>
                                        )}
                                        {candidateData?.isConfirmed == 0 && (
                                            <div className={"mb-3"}>
                                                <p className="px-6 py-3 text-2xl font-semibold text-white flex !items-center justify-center bg-slate-500 rounded-xl">
                                                    <span className="mr-[10px]"><BsFillExclamationCircleFill/></span>Oyuncu 'onaylanmadı' olarak kaydedilmiş.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="grid grid-cols-2 gap-6">
                                        <div>
                                            <InputComponent
                                                labelTitle={"Oyuncu Adı"}
                                                name={"name"}
                                                type={"text"}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "name",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                value={values}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                containerStyle={"w-full"}
                                                placeholder={"Oyuncu Adı"}
                                                required={true}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <InputComponent
                                                labelTitle={"Oyuncu Soyadı"}
                                                name={"surname"}
                                                type={"text"}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "surname",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                value={values}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                containerStyle={"w-full"}
                                                placeholder={"Oyuncu Soyadı"}
                                                required={true}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <InputComponent
                                                labelTitle={"TC Kimlik No"}
                                                name={"tckn"}
                                                type={"text"}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "tckn",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                value={values}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                containerStyle={"w-full"}
                                                placeholder={"TC Kimlik No"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <InputComponent
                                                labelTitle={"E-posta"}
                                                name={"email"}
                                                type={"text"}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "email",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                value={values}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                containerStyle={"w-full"}
                                                placeholder={"E-posta"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Pozisyon"}
                                                containerStyle={"w-full"}
                                                placeholder={"Pozisyon"}
                                                name={"position"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "position",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={positionOptions}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Hakim Ayak"}
                                                containerStyle={"w-full"}
                                                placeholder={"Hakim Ayak"}
                                                name={"fkFootUsed"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "fkFootUsed",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={FootOptions}
                                                disabled={inputsDisabled}
                                            />
                                        </div>

                                        <div>
                                            <DynamicInput
                                                labelTitle={"İzlendiği Maç"}
                                                containerStyle={"w-full"}
                                                placeholder={"İzlendiği Maç"}
                                                name={"watchMatch"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "watchMatch",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"input"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"İzleme Tarihi"}
                                                containerStyle={"w-full"}
                                                placeholder={"İzleme Tarihi"}
                                                name={"watchDate"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "watchDate",
                                                        setFieldValue,
                                                        "datePicker"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"datePicker"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div className="flex justify-start">
                                            <DynamicInput
                                                labelTitle={"İzlendiği Turnuva"}
                                                containerStyle={"w-full"}
                                                placeholder={"İzlendiği Turnuva"}
                                                name={"monitoredTournament"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "monitoredTournament",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"input"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Oynadığı Takım"}
                                                containerStyle={"w-full"}
                                                placeholder={"Oynadığı Takım"}
                                                name={"fkTeam"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "fkTeam",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={optionTeams}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"İzlenme Durumu"}
                                                containerStyle={"w-full"}
                                                placeholder={"İzlenme Durumu"}
                                                name={"watchStatus"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "watchStatus",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={[
                                                    {label: "İzlendi", value: "izlendi"},
                                                    {label: "İzlenmedi", value: "İzlenmedi"},
                                                    {label: "Görüşme Yapıldı", value: "gorusme-yapildi"}
                                                ]}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"İzleyen"}
                                                containerStyle={"w-full"}
                                                placeholder={"İzleyen"}
                                                name={"fkFollower"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "fkFollower",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={followerOptions}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Notlar"}
                                                containerStyle={"w-full"}
                                                placeholder={"Notlar"}
                                                name={"notes"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "notes",
                                                        setFieldValue,
                                                        "textarea"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"textarea"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center text-gray-500 pt-7 mb-3">
                                    <div className="grid grid-cols-2 gap-5 w-full">
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Doğum Tarihi"}
                                                containerStyle={"w-full"}
                                                placeholder={"Doğum Tarihi"}
                                                name={"birthDate"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "birthDate",
                                                        setFieldValue,
                                                        "datePicker"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"datePicker"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>

                                        <div>
                                            <DynamicInput
                                                labelTitle={"Kulübü"}
                                                containerStyle={"w-full"}
                                                placeholder={"Kulübü"}
                                                name={"clubId"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "clubId",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={getAllClubOptions}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Şehir"}
                                                containerStyle={"w-full"}
                                                placeholder={"Şehir"}
                                                name={"cityId"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "cityId",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={allCity}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Bölge"}
                                                containerStyle={"w-full"}
                                                placeholder={"Bölge"}
                                                name={"fkRegion"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "fkRegion",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={regions}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Scout'un Önerdiği Pozisyon"}
                                                containerStyle={"w-full"}
                                                placeholder={"Scout'un Önerdiği Pozisyon"}
                                                name={"recommendedPosition"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "recommendedPosition",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={positionOptions}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Boy (m)"}
                                                containerStyle={"w-full"}
                                                placeholder={"Boy (m)"}
                                                name={"height"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "height",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"input"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Kilo (kg)"}
                                                containerStyle={"w-full"}
                                                placeholder={"Kilo (kg)"}
                                                name={"weight"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "weight",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"input"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Antrenman Yaşı"}
                                                containerStyle={"w-full"}
                                                placeholder={"Antrenman Yaşı"}
                                                name={"trainingAge"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "trainingAge",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"input"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"İzlenen Müsabaka Seviyesi"}
                                                containerStyle={"w-full"}
                                                placeholder={"İzlenen Müsabaka Seviyesi"}
                                                name={"fkMonitoredCompetitionDifficulty"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "fkMonitoredCompetitionDifficulty",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={competitionLevel}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Oynadığı Yere-Ortama Göre Puanı"}
                                                containerStyle={"w-full"}
                                                placeholder={"Oynadığı Yere-Ortama Göre Puanı"}
                                                name={"ratingByPlaceOfPlay"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "ratingByPlaceOfPlay",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={[
                                                    {label: "0", value: 0},
                                                    {label: "1", value: 1},
                                                    {label: "2", value: 2},
                                                    {label: "3", value: 3},
                                                    {label: "4", value: 4},
                                                    {label: "5", value: 5},
                                                    {label: "6", value: 6},
                                                    {label: "7", value: 7},
                                                    {label: "8", value: 8},
                                                    {label: "9", value: 9},
                                                    {label: "10", value: 10}
                                                ]}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Puanın ALFA'ya Göre Karşılığı"}
                                                containerStyle={"w-full"}
                                                placeholder={"Puanın ALFA'ya Göre Karşılığı"}
                                                name={"correspondingPointsToAlfa"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "correspondingPointsToAlfa",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={[
                                                    {label: "0", value: 0},
                                                    {label: "1", value: 1},
                                                    {label: "2", value: 2},
                                                    {label: "3", value: 3},
                                                    {label: "4", value: 4},
                                                    {label: "5", value: 5},
                                                    {label: "6", value: 6},
                                                    {label: "7", value: 7},
                                                    {label: "8", value: 8},
                                                    {label: "9", value: 9},
                                                    {label: "10", value: 10},
                                                ]}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"Sonuç"}
                                                containerStyle={"w-full"}
                                                placeholder={"Sonuç"}
                                                name={"fkResult"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "fkResult",
                                                        setFieldValue,
                                                        "select"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"select"}
                                                customOptions={resultOptions ?? []}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <h1 className="text-[24px] font-bold mt-3">Swot Analizi</h1>
                                <div className="flex items-center text-gray-500 pt-7">
                                    <div className="grid grid-cols-2 gap-5 w-full">
                                        <div>
                                            <DynamicInput
                                                labelTitle={"S (Güçlü Yanları)"}
                                                containerStyle={"w-full"}
                                                placeholder={"S (Güçlü Yanları)"}
                                                name={"strengths"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "strengths",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"input"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"W (Zayıf Yanları)"}
                                                containerStyle={"w-full"}
                                                placeholder={"W (Zayıf Yanları)"}
                                                name={"weaknesses"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "weaknesses",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"input"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"O (Fırsatlar)"}
                                                containerStyle={"w-full"}
                                                placeholder={"O (Fırsatlar)"}
                                                name={"opportunities"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "opportunities",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"input"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>
                                        <div>
                                            <DynamicInput
                                                labelTitle={"T (Tehditler)"}
                                                containerStyle={"w-full"}
                                                placeholder={"T (Tehditler)"}
                                                name={"threats"}
                                                value={values}
                                                onChange={(e1, e2) => {
                                                    handleCustomChange(
                                                        e1,
                                                        e2,
                                                        "threats",
                                                        setFieldValue,
                                                        "input"
                                                    );
                                                }}
                                                error={errors}
                                                isTouched={touched}
                                                onBlur={handleBlur}
                                                component_type={"input"}
                                                disabled={inputsDisabled}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-10">
                                <div className="flex justify-between">
                                    <div className="flex gap-5">
                                        {candidateData?.isConfirmed != 1 && (
                                            <div>
                                                <ButtonComponent
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: "Emin misiniz?",
                                                            text: "Seçilen aday onaylandı olarak kaydedilsin mi? Aday oyuncu, onaylandı olarak kaydedilecek ve sistemde oyuncuya hesap oluşturulacaktır. ",
                                                            icon: "question",
                                                            showCancelButton: true,
                                                            confirmButtonColor: "#3085d6",
                                                            cancelButtonColor: "#d33",
                                                            confirmButtonText: "Evet, kaydet.",
                                                            cancelButtonText: "Kapat"
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                handleCandidteIsConfirmedUpdate(1);
                                                            }
                                                        });
                                                    }}
                                                    fill
                                                    text={"Onaylandı olarak kaydet"}
                                                />
                                            </div>
                                        )}
                                        {candidateData?.isConfirmed == null && (
                                            <div>
                                                <ButtonComponent
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: "Emin misiniz?",
                                                            text: "Seçilen aday onaylanmadı olarak kaydedilsin mi? ",
                                                            icon: "question",
                                                            showCancelButton: true,
                                                            confirmButtonColor: "#3085d6",
                                                            cancelButtonColor: "#d33",
                                                            confirmButtonText: "Evet, kaydet.",
                                                            cancelButtonText: "Kapat"
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                handleCandidteIsConfirmedUpdate(0);
                                                            }
                                                        });
                                                    }}
                                                    fill
                                                    text={"Onaylanmadı olarak kaydet"}
                                                />
                                            </div>
                                        )}

                                        <div>

                                        </div>
                                    </div>

                                    <div className="flex gap-3">
                                        {candidateData.isConfirmed != 1 && (
                                            <>
                                                <div className="">
                                                    <ButtonComponent
                                                        onClick={() => {
                                                            handleCandidateDeleteOnClick();
                                                        }}
                                                        outline
                                                        text={"Sil"}
                                                    />
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="flex items-center justify-center w-full h-12 px-6 font-medium text-white transition-all rounded-md text-md bg-primary hover:bg-red-500"
                                                    disabled={loading}
                                                >
                                                    {loading ? <PulseLoader color="white"/> : "Değişiklikleri Kaydet"}
                                                </button>
                                            </>
                                        ) }
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default EditApplicantForm;
