import React, {useEffect, useState} from "react";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, Form, DatePicker, Select, Space, Input} from "antd";
import {PulseLoader} from "react-spinners";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import dayjs from "dayjs";
import {
    createAddBulkFixtureMatch,
    getAllJudges,
    getLeagues
} from "../../services/axios";
import Swal from "sweetalert2";

function FixtureMatchFields({
                                setModalIsOpen,
                                setIsDataChange,
                                leagueOptions,
                                clubOptions
                            }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [judgeOptions, setJudgeOptions] = useState([]);
    const [selectedLeagueId, setSelectedLeagueId] = useState(null);
    const [weeks, setWeeks] = useState([
        {label: "1", value: 1},
        {label: "2", value: 2},
        {label: "3", value: 3},
        {label: "6", value: 6},
        {label: "7", value: 7},
        {label: "8", value: 8},
        {label: "9", value: 9},
        {label: "10", value: 10},
        {label: "4", value: 4},
        {label: "5", value: 5},
        {label: "11", value: 11},
        {label: "12", value: 12},
        {label: "13", value: 13},
        {label: "14", value: 14},
        {label: "15", value: 15}
    ]);

    const fetchGetJudges = async () => {
        setLoading(true);
        try {
            const result = await getAllJudges();
            setJudgeOptions([]);
            setJudgeOptions((prevState) => [
                ...prevState,
                ...result?.response?.judges.map((item) => ({
                    label: item?.name + " " + item?.surname,
                    value: item?.id
                }))
            ]);
        } catch (error) {
            console.log("hata", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCreateBulkFixturesMatch = async (leagueId, matches) => {
        try {
            const result = await createAddBulkFixtureMatch(leagueId, matches);
            console.log("resssss", result);
            if (result.success) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: result?.message ?? "Ekleme Başarılı!",
                    showConfirmButton: false,
                    timer: 1000
                });
                setIsDataChange(true);
                setModalIsOpen(false);
            } else {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: result?.message ?? "Ekleme Başarısız!",
                    showConfirmButton: false,
                    timer: 1000
                });
            }

            setIsDataChange(true);
        } catch (error) {
            console.log("hata", error);
        }
    };

    const onFinish = (values) => {
        if (selectedLeagueId == null){
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Lig Seçiniz",
                confirmButtonText: "Kapat"
            });
            return false;
        }
        const matches = [
            ...values.matches.map((item) => ({
                homeTeam: item.homeTeam,
                guestTeam: item.guestTeam,
                week: item.week,
                matchDate: dayjs(new Date(item.matchDate.$d)).format("YYYY-MM-DD"),
                judge: item.judge
            }))
        ];
        // fetchCreateBulkFixturesMatch(values.matches.leagueId, matches);
        console.log(selectedLeagueId, "selectedLeagueId")
        fetchCreateBulkFixturesMatch(selectedLeagueId, matches);
    };

    useEffect(() => {
        fetchGetJudges();
    }, []);

    return (
        <div>
            {loading ? (
                <div className="flex justify-center items-center h-20">
                    <PulseLoader color="red"/>
                    <p>Yükleniyor...</p>
                </div>
            ) : (
                <div>
                    <p className="px-6 py-3 text-2xl font-semibold text-secondary">
                        Toplu Fikstür Maçı Ekle
                    </p>
                    <hr/>
                    <div className={"p-6"}>
                        <Form
                            form={form}
                            name="matchesForm"
                            onFinish={onFinish}
                            style={
                                {
                                    // maxWidth: 600,
                                }
                            }
                            autoComplete="off"
                        >
                            <Form.List name="matches" initialValue={["", "", ""]}>
                                {(fields, {add, remove}) => (
                                    <>
                                        <div className={"flex justify-end"}>
                                            <div className={"w-[300px]"}>
                                                <Select
                                                    showSearch
                                                    placeholder="Lig Seçiniz"
                                                    size={"large"}
                                                    options={leagueOptions}
                                                    onChange={(e) => setSelectedLeagueId(e)}
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '30px'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={"flex justify-between font-semibold pb-1 mb-3 border-b-4"}>
                                            <div className="w-[150px]">Ev Sahibi Takım</div>
                                            <div className="w-[150px]">Misafir Takım</div>
                                            <div className="w-[150px]">Hafta</div>
                                            <div className="w-[150px]">Tarih</div>
                                            <div className="w-[150px]">Hakem</div>
                                        </div>
                                        {fields.map(({key, name, ...restField}) => (
                                            <Space
                                                key={key}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    marginBottom: 8
                                                }}
                                                align="baseline"
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "homeTeam"]}
                                                    style={{
                                                        width: "150px"
                                                    }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Bu alan zorunlu.."
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Ev Sahibi Takım"
                                                        options={clubOptions?.filter(
                                                            (option) => option.value !== null
                                                        )}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "guestTeam"]}
                                                    style={{
                                                        width: "150px"
                                                    }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Bu alan zorunlu.."
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Misafir Takım"
                                                        options={clubOptions?.filter(
                                                            (option) => option.value !== null
                                                        )}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "week"]}
                                                    style={{
                                                        width: "150px"
                                                    }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Bu alan zorunlu.."
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Hafta"
                                                        options={weeks}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "matchDate"]}
                                                    style={{
                                                        width: "150px"
                                                    }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Bu alan zorunlu.."
                                                        }
                                                    ]}
                                                >
                                                    <DatePicker
                                                        className={"w-full"}
                                                        placeholder={"Tarih"}
                                                        picker={"date"}
                                                        format={"DD-MM-YYYY"}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "judge"]}
                                                    style={{
                                                        width: "100px"
                                                    }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Bu alan zorunlu.."
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Hakem"
                                                        options={judgeOptions}
                                                    />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined/>}
                                            >
                                                Satır Ekle
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Form.Item>
                                <div className={"flex justify-between"}>
                                    <ButtonComponent
                                        text={"Vazgeç"}
                                        outline
                                        onClick={() => {
                                            setModalIsOpen(false);
                                        }}
                                    />
                                    <div className={"flex gap-3"}>
                                        <ButtonComponent
                                            text={"Tümünü Temizle"}
                                            outline
                                            submit
                                            onClick={() => {
                                                form.resetFields();
                                            }}
                                        />
                                        <ButtonComponent text={"Kaydet"} fill submit/>
                                    </div>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FixtureMatchFields;
