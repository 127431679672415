import React, { useEffect, useState } from "react";
import {
  Link,
  Route,
  Navigate,
  useOutletContext,
  useParams
} from "react-router-dom";
import { Paths } from "../../routes";
import MatchesHeroSection from "../matches/matchesHeroSection";
import { getMatch } from "../../services/axios";
import { PulseLoader } from "react-spinners";
import FixtureMatchFields from "./FixtureMatchFields";
import PlayerInfos from "../../containers/sections/matches/PlayerInfos";
import MatchCriteries from "../../containers/sections/matches/MatchCriteries";
import MatchScoreChange from "../../containers/sections/matches/MatchScoreChange";
import MatchUpdateFinishBtn from "../../containers/sections/matches/MatchUpdateFinishBtn";

const EditFixture = () => {
  const { id } = useParams();

  const [isFixture, setIsFixture] = useState(0);
  const [isDataChange, setIsDataChange] = useState(true);
  const [loading, setLoading] = useState(true);
  const [matchData, setMatchData] = useState([]);
  const [isDefaultTeam, setIsDefaultTeam] = useState(false);
  const [setBreadcrumbData] = useOutletContext();
 //
  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel"
      },
      {
        title: <Link to={Paths.Fixtures.Fixtures}>Fikstür</Link>
      },
      {
        title: "Maç"
      }
    ]);
  }, []);

  const fetchGetMatch = async (id) => {
    setLoading(true);
    try {
      const result = await getMatch(id);
      if (
        result?.response?.match.home_team.club_name == "Altınordu" ||
        result?.response?.match.guest_team.club_name == "Altınordu"
      ) {
        setIsDefaultTeam(true);
      }
      setIsFixture(result?.response?.match?.is_fixture);
      setMatchData(result?.response?.match);
      return result;
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isDataChange && matchData) {
      fetchGetMatch(id);
    }
    setIsDataChange(false);
  }, [isDataChange]);

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-36">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      ) : isFixture ? (
        <div className="w-full bg-white shadow-xl rounded-t-3xl border-t-primary focus:outline-none">
          <MatchesHeroSection updateData={matchData} />
          <div className="p-5 mt-5">
            <FixtureMatchFields
              action={"EDIT"}
              matchData={matchData}
              setIsDataChange={setIsDataChange}
            />
          </div>
          {isDefaultTeam ? (
            <div className="p-5">
              <div className="w-full gap-8 xl:grid xl:grid-cols-2">
                <div className="w-full p-4 bg-white customBoxShadow rounded-2xl">
                  <PlayerInfos id={id} matchData={matchData} />
                </div>
                <div className="w-full p-4 bg-white customBoxShadow rounded-2xl">
                  <MatchCriteries id={id} matchData={matchData} />
                </div>
              </div>
            </div>
          ) : (
            <div className=" px-5 pb-10">
              <MatchScoreChange
                id={id}
                matchData={matchData}
                setIsDataChange={setIsDataChange}
              />
            </div>
          )}
          {matchData?.isFinish == 1 ? (
            <MatchUpdateFinishBtn
              matchId={id}
              isFinish={0}
              title={"Maçı Tekrar Başlat"}
              setIsDataChange={setIsDataChange}
            />
          ) : (
            <MatchUpdateFinishBtn
              matchId={id}
              isFinish={1}
              title={"Maçı Sonlandır"}
              setIsDataChange={setIsDataChange}
            />
          )}
        </div>
      ) : (
        <Navigate replace to="/fixtures" />
      )}
    </>
  );
};

export default EditFixture;
