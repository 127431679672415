import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Paths } from "../../../routes";

import { DataTable } from "../../../components/tables";
import { Input } from "antd";
import { FiPlus } from "react-icons/fi";
import ActionButtonsDataTable from "../../../containers/sections/ActionButtonsDataTable";
import { deleteMatch, getAllMatches } from "../../../services/axios";
import CustomModal from "../../../components/modals";
import CustomModalHeader from "../../../components/modals/CustomModalHeader";
import MatchesFields from "../matchesFields";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const MatchesList = () => {
  const [loading, setLoading] = useState(false);
  const [addMatchesModalOpen, setAddMatchesModalOpen] = useState(false);
  const [matchesDataTableData, setMatchesDataTableData] = useState([]);

  const [isDataChange, setIsDataChange] = useState(true);

  const fetchGetAllMatches = async () => {
    setLoading(true);
    try {
      const result = await getAllMatches();
      console.log(result.response.matches, "result");
      setMatchesDataTableData(
        result.response.matches.map((item, index) => ({
          id: item.id,
          home_team: item.home_team?.club_name ?? (
            <p className="text-primary">Kulüp Bulunamadı!</p>
          ),
          guest_team: item.guest_team?.club_name ?? (
            <p className="text-primary">Kulüp Bulunamadı!</p>
          ),
          home_team_score: item.home_team_score,
          guest_team_score: item.guest_team_score ? item.guest_team_score : "-",
          season: item.season?.season_title ? item.season.season_title : "-",
          week: item.week ? item.week : "-",
          match_date: item.match_date ? item.match_date : "-",
          coach_comment: item.coach_comment ? item.coach_comment : "-",
          goal_keeper_coach_comment: item.goal_keeper_coach_comment ? item.goal_keeper_coach_comment : "-",
        }))
      );
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setIsDataChange(false);
      setLoading(false);
    }
  };

  const fetchDeleteMatch = async (id) => {
    try {
      const result = await deleteMatch(id);
      setIsDataChange(true);
      return result;
    } catch (error) {
      console.log(error, "hata");
    }
  };

  useEffect(() => {
    if (isDataChange) {
      fetchGetAllMatches();
      setAddMatchesModalOpen(false);
    }
  }, [isDataChange]);

  console.log(isDataChange, "listisDataChange");

  const columns = [
    {
      title: "Ev Sahibi",
      dataIndex: "home_team",
    },
    { title: "Deplasman", dataIndex: "guest_team" },
    {
      title: "Ev Sahibi Skor",
      dataIndex: "home_team_score",
    },
    {
      title: "Deplasman Skor",
      dataIndex: "guest_team_score",
    },
    {
      title: "Sezon",
      dataIndex: "season",
    },
    {
      title: "Hafta",
      dataIndex: "week",
    },
    {
      title: "Tarih",
      dataIndex: "match_date",
    },
    {
      title: "Antrenör Yorumu",
      dataIndex: "coach_comment",
    },
    {
      title: "Kaleci Antrenör Yorumu",
      dataIndex: "goal_keeper_coach_comment",
    },
    {
      title: "İşlem",
      fixed: "right",
      key: "action",
      sorter: true,
      width: "",
      render: (item, index) => {
        console.log(index);
        return (
          <ActionButtonsDataTable
            id={index.id}
            fetchFunction={() => fetchDeleteMatch(index.id)}
            toUpdate={Paths.Matches.EditMatches}
            toDetail={Paths.Matches.EditMatches}
            deleteDataLabel={index?.home_team + " - " + index?.guest_team}
            updateButton
            deleteButton
          />
        );
      },
    },
  ];

  return (
    <>
      <div className="w-full p-4 bg-white rounded-md shadow">
        <div className="flex items-center justify-between text-gray-500">
          <h4 className="text-lg font-semibold">Maçlar</h4>
        </div>
        <div className="flex justify-end px-2 py-2 my-4 bg-gray-100 rounded-lg">
          <ButtonComponent
              onClick={() => setAddMatchesModalOpen(true)}
              text={"Maç Ekle"}
              outline
          />
          <CustomModal
              width={677}
              isOpen={addMatchesModalOpen}
              setOpen={setAddMatchesModalOpen}
              footer={null}
          >
            <MatchesFields
                isUpdate={false}
                setIsDataChangeList={setIsDataChange}
                isDataChangeList={isDataChange}
                isActivity={false}
            />
          </CustomModal>
          {/* <div className="flex items-center w-full sm:w-auto">
            <span className="hidden mr-2 text-sm text-gray-500 sm:block">
              Filtrele:{" "}
            </span>
            <Input
              type="text"
              placeholder="Filtrele.."
              value={filterInputValue}
              onChange={({ target }) => setFilterInputValue(target.value)}
            />
          </div> */}
        </div>
        <div className="">
          <DataTable
            columns={columns}
            data={matchesDataTableData?.reverse()}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default MatchesList;
