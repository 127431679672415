import React, { useEffect, useState } from "react";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import { Formik, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import DynamicInput from "../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../utils/handleCustomChange";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import {
  createTreatments,
  createTreatmentsBelongMedicine,
  createTreatmentsControl,
  deleteTreatments,
  deleteTreatmentsBelongMedicine,
  deleteTreatmentsControl,
  getAllTreatmentsFormInfos,
  getAllUser,
  updateTreatments,
  listTeams,
  getPlayerUsers,
  updateTreatmentsControl,
  getSavedBaseUrl,
  baseImageURL
} from "../../services/axios";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import DataTable from "../../components/tables/DataTable";
import { Paths } from "../../routes";
import { Link } from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import { PositionData } from "../../utils/datas/positionData";

export function CreateTreatmentsForm({
  setIsDataChange,
  isDataChange,
  updateSelectedValuesProp
}) {
  const [loading, setLoading] = useState(false);
  const [userDataTableData, setUserDataTableData] = useState([]);

  const [medicinesDataTableData, setMedicinesDataTableData] = useState([]);

  const [filteredDataTableData, setFilteredDataTableData] = useState([]);

  const [filteredMedicinesDataTableData, setFilteredMedicinesDataTableData] =
    useState([]);

  const [selectedUserData, setSelectedUserData] = useState(null);
  const [selectedDoctorData, setSelectedDoctorData] = useState(null);

  const [searchInputValue, setSearchInputValue] = useState("");
  const [medicinesInputValue, setMedicinesInputValue] = useState("");

  const [allTreatmentsTypeOption, setAllTreatmentsTypeOption] = useState([]);
  const [allTreatmentsStatusOption, setAllTreatmentsStatusOption] = useState(
    []
  );


  const [staticInputData, setStaticInputData] = useState(null);

  console.log(updateSelectedValuesProp, "updateSelectedValuesProp");

  const [injurySiteValue, setInjurySiteValue] = useState("");

  const [playerTreatmentFilter, setPlayerTreatmentFilter] = useState({
    teamId: {
      label: "Tümü",
      value: null
    },
    searchTerm: null
  });

  const [teams, setTeams] = useState([{ label: "Tümü", value: null }]);
  const [selectTeam, setSelectTeam] = useState({ label: "Tümü", value: null });

  const [doctorOptions, setDoctorOptions] = useState({});
  const [selectDoctor, setSelectDoctor] = useState({});

  useEffect(() => {
    setSelectDoctor(
      updateSelectedValuesProp
        ? {
          label:
            updateSelectedValuesProp?.treatment_doctor?.user_name +
            " - " +
            updateSelectedValuesProp?.treatment_doctor?.tckn,
          value: updateSelectedValuesProp?.treatment_doctor?.id,
          data: {
            name:
              updateSelectedValuesProp?.treatment_doctor?.user_name +
              " - " +
              updateSelectedValuesProp?.treatment_doctor?.tckn,
            tckn: updateSelectedValuesProp?.treatment_doctor?.tckn
          }
        }
        : ""
    );
  }, []);

  const fetchGetTeams = async () => {
    try {
      const results = await listTeams();
      setTeams((prevState) => [
        ...prevState,
        ...results?.data.teams.map((item) => ({
          label: item.team_title + " | " + item.team_category.category_title,
          value: item.id
        }))
      ]);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchUsers = async (teamId) => {
    setLoading(true);
    try {
      const results = await getPlayerUsers(teamId?.value);
      // Oyuncu tablosu data
      const resultUsers = results.data.users.map((item) => ({
        id: item?.id,
        name: item?.name ? item?.name : "-",
        surname: item?.surname ? item?.surname : "-",
        tckn: item?.tckn ? item.tckn : "-",
        img: item?.imageurl ? item.imageurl : "-",
        position: item.position ? PositionData.find((pItem) => pItem.value == item.position)?.label : "-"
      }));
      setUserDataTableData(resultUsers);
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
    }
  };

  const injurySiteController = () => {
    const key = updateSelectedValuesProp?.injurySite ?? "";
    const [initialInjurySiteValue, initialInjurySite] = key.split("-");

    if (initialInjurySite) {
      // console.log(initialInjurySiteValue, "initialInjurySiteValue");
      // console.log(initialInjurySite, "initialInjurySite");

      const newInitialInjurySite = injuryOptions.find(
        (item) => item?.value === initialInjurySite ?? ""
      );
      const newInitialInjuryValueSite = injuryValueOptions.find(
        (item) => item?.value === initialInjurySiteValue ?? ""
      );

      //values.injurySite = {label: newInitialInjurySite, value: initialInjurySite}
      // console.log(initialInjurySiteValue, "saga"); //buradayız

      values.injurySite = newInitialInjurySite ?? "";
      values.injuryValueSite = newInitialInjuryValueSite ?? "";
      setInjurySiteValue(newInitialInjurySite?.value ?? "");
    } else {
      const newInitialInjurySite = injuryOptions.find(
        (item) => item?.value === initialInjurySiteValue ?? ""
      );
      values.injurySite = newInitialInjurySite ?? "";
    }
  };
  useEffect(() => {
    fetchUsers();
    injurySiteController();
  }, []);

  // console.log(userDataTableData, "userDataTableData-userDataTableData");

  const fetchUpdateTreatmentsControl = async (
    control_date,
    control_description,
    health_organization
  ) => {
    setLoading(true);
    try {
      const result = await updateTreatmentsControl(
        selectedControlData.id,
        updateSelectedValuesProp?.id,
        control_date,
        control_description,
        health_organization
      );
      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };

  const fetchDeleteBelong = async (treatment_id, medicine_id) => {
    try {
      const result = await deleteTreatmentsBelongMedicine(
        treatment_id,
        medicine_id
      );
      setIsDataChange(true);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchDeleteTreatments = async (treatment_id) => {
    try {
      const result = await deleteTreatments(treatment_id);
      // console.log(result, "result");
      setIsDataChange(true);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchDeleteTreatmentsConrol = async (control_id) => {
    try {
      const result = await deleteTreatmentsControl(control_id);
      // console.log(result, "result");
      setIsDataChange(true);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const handleJudgeDeleteOnClick = async (item) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${item?.treatments_start_date}" tarihli tedaviyi silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal"
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteTreatments(item?.id);
        Swal.fire(
          "Silindi!",
          `"${item?.treatments_start_date}" tarihli tedavi silindi.`,
          "success"
        );
      }
    });
  };

  const handleControlDeleteOnClick = async (item) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${item?.control_date}" tarihli kontrolü silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal"
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteTreatmentsConrol(item?.id);
        Swal.fire(
          "Silindi!",
          `"${item?.control_date}" tarihli kontrol silindi.`,
          "success"
        );
      }
    });
  };

  const [controlUpdateModalIsOpen, setControlUpdateModalIsOpen] =
    useState(false);

  const handleBelongDeleteOnClick = async (item) => {
    // fetchDeleteBelong(updateSelectedValuesProp?.id, item?.id);
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${item?.medicine_title}" tipini silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal"
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteBelong(updateSelectedValuesProp?.id, item?.id);
        Swal.fire(
          "Silindi!",
          `"${item?.medicine_title}" tipi silindi.`,
          "success"
        );
      }
    });
  };

  const fetchGetUsers = async () => {
    setLoading(true);
    try {
      const result = await getAllUser("footballer,doctor");
      const dataTableData2 = result.data.users
        .filter((itemFilter) => itemFilter?.roles[0]?.role_code == "doctor")
        .map((item, index) => ({
          id: item.id,
          name: item.user_name ? item.user_name : "-",
          tckn: item.tckn ? item.tckn : "-"
        }));

      const doctors = result.data.users
        .filter((itemFilter) => itemFilter?.roles[0]?.role_code == "doctor")

        .map((item, index) => ({
          label: item.user_name ? item.user_name + " - " + item.tckn : "-",
          value: item.id,
          data: {
            name: item.user_name,
            tckn: item.tckn
          }
        }));
      const doctorsName = result.data.users
        .filter((itemFilter) => itemFilter?.roles[0]?.role_code == "doctor")

        .map((item, index) => ({
          name: item.user_name ? item.user_name + " - " + item.tckn : "-"
        }));

      // console.log(doctorsName, "doctorsNamedoctorsNamedoctorsName");
      setDoctorOptions(doctors);
      // console.log(doctorOptions, "doctorOptions");

      setLoading(false);
      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchOnChange = (e) => {
    const searchValue = e.target.value;
    setSearchInputValue(searchValue);
    const filteredData = userDataTableData?.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchValue.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredDataTableData(filteredData ?? []);
  };

  const handleMedicinesSearchOnChange = (e) => {
    const searchValue = e.target.value;
    setMedicinesInputValue(searchValue);
    const filteredData = medicinesDataTableData?.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchValue.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredMedicinesDataTableData(filteredData ?? []);
  };

  const fetchGetAllTreatmentsFormInfos = async () => {
    setLoading(true);
    try {
      const result = await getAllTreatmentsFormInfos();
      // type
      const allTreatmentsTypeOptions = result?.data?.types.map(
        (item, index) => ({
          label: item?.treatments_type_title
            ? item?.treatments_type_title
            : "-",
          value: item?.id
        })
      );
      setAllTreatmentsTypeOption(allTreatmentsTypeOptions);
      // type

      // status
      const allTreatmentsStatusOptions = result?.data?.statuses.map(
        (item, index) => ({
          label: item?.treatments_status_title
            ? item?.treatments_status_title
            : "-",
          value: item?.id
        })
      );
      setAllTreatmentsStatusOption(allTreatmentsStatusOptions);
      // status

      // medicines
      const datatableMedicines = result?.data?.medicines.map((item, index) => ({
        id: item?.id,
        title: item?.medicine_title ? item?.medicine_title : "-",
        prospectus: item?.medicine_prospectus ? item?.medicine_prospectus : "-"
      }));
      setMedicinesDataTableData(datatableMedicines ?? []);
      // medicines
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
    }
  };

  const fetchCreateTreatmentsBelongMedicines = async (
    treatment_id,
    medicine_id
  ) => {
    setLoading(true);
    try {
      const result = await createTreatmentsBelongMedicine(
        treatment_id,
        medicine_id
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };

  const fetchCreateTreatmentsControl = async (
    treatment_id,
    control_date,
    control_description,
    health_organization
  ) => {
    setLoading(true);
    try {
      const result = await createTreatmentsControl(
        treatment_id,
        control_date,
        control_description,
        health_organization
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000
        });

        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };

  const injuryOptions = [
    { value: "baldir", label: "Baldır" },
    { value: "diz", label: "Diz" }
  ];

  const injuryValueOptions = [
    { value: "sag", label: "Sağ" },
    { value: "sol", label: "Sol" }
  ];

  useEffect(() => {
    fetchGetUsers();
    fetchGetAllTreatmentsFormInfos();
    setIsDataChange(false);
  }, [isDataChange]);

  useEffect(() => {
    fetchGetTeams();
  }, []);

  const [selectedControlData, setSelectedControlData] = useState();
  const { errors, values, handleSubmit, touched, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        treatments_start_date:
          dayjs(updateSelectedValuesProp?.treatments_start_date).format(
            "YYYY-MM-DD"
          ) ?? "",
        treatments_start_time:
          dayjs(updateSelectedValuesProp?.treatments_start_date).format(
            "HH:mm"
          ) ?? "",
        // date ve time ayrı ayrı form'dan alınıp treatments_start_date şeklinde api'ye gönderilir
        player_id: updateSelectedValuesProp?.player_id ?? "",
        doctor_id: updateSelectedValuesProp?.doctor_id ?? "",

        treatments_type_id:
          (updateSelectedValuesProp && {
            label:
              updateSelectedValuesProp?.treatment_type?.treatments_type_title,
            value: updateSelectedValuesProp?.treatment_type?.id
          }) ??
          "",
        treatments_status_id:
          (updateSelectedValuesProp && {
            label:
              updateSelectedValuesProp?.treatment_status
                ?.treatments_status_title,
            value: updateSelectedValuesProp?.treatment_status?.id
          }) ??
          "",

        recovery_day: updateSelectedValuesProp?.recovery_day ?? "",
        is_active: updateSelectedValuesProp?.is_active ?? 1,
        complaint: updateSelectedValuesProp?.complaint ?? "",
        examination: updateSelectedValuesProp?.examination ?? "",
        treatment: updateSelectedValuesProp?.treatment ?? "",

        criticism: updateSelectedValuesProp?.criticism ?? "",
        diagnosis: updateSelectedValuesProp?.diagnosis ?? "",

        grade: updateSelectedValuesProp
          ? {
            label: updateSelectedValuesProp?.grade,
            value: updateSelectedValuesProp?.grade
          }
          : "",

        injurySite: {},
        injuryValueSite: updateSelectedValuesProp?.injuryValueSite ?? ""
      },
      onSubmit: async (values) => {
        setLoading(true);
        values.treatments_status_id = values?.treatments_status_id.value;
        values.treatments_type_id = values?.treatments_type_id.value;
        values.player_id = getPlayerId;
        values.injuryValueSite = values?.injuryValueSite.value;
        values.grade = values?.grade.value;
        values.injurySite = values?.injurySite.value;
        try {
          let result = null;
          if (updateSelectedValuesProp) {
            result = await updateTreatments(
              updateSelectedValuesProp.id,
              values?.treatments_start_date +
              " " +
              values?.treatments_start_time,
              values?.player_id,
              values?.doctor_id,
              values?.treatments_type_id,
              values?.treatments_status_id,
              values?.recovery_day,
              values?.is_active,
              values?.complaint,
              values?.examination,
              values?.diagnosis,
              values?.treatment,
              values?.injurySite,
              values?.injuryValueSite,
              values?.criticism,
              values?.grade
            ).then((res) => {
              console.log('res', res);
              if (res.success) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: res?.message ?? "Güncelleme Başarılı!",
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  window.location.reload()
                });
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: res?.message ?? "Güncelleme Başarısız!",
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  window.location.reload()
                });
              }
            });
          } else {
            // OLUŞTURMA
             let form = {
               treatments_starts_date: values?.treatments_start_date + " " +
               values?.treatments_start_time,
               player_id: values?.player_id,
               doctor_id: selectDoctor.value,
               treatments_type_id: values?.treatments_type_id,
               treatments_status_id: values?.treatments_status_id,
               recovery_day: values?.recovery_day,
               is_active: values?.is_active,
               complaint: values?.complaint,
               examination: values?.examination,
               diagnosis: values?.diagnosis,
               treatment: values?.treatment,
               injurySite: values?.injurySite,
               injuryValueSite: values?.injuryValueSite,
               criticism: values?.criticism,
               grade: values?.grade
          
             }
            result = await createTreatments(
              form
              // values?.treatments_start_date +
              // " " +
              // values?.treatments_start_time,
              // values?.player_id,
              // selectDoctor.value,
              // values?.treatments_type_id,
              // values?.treatments_status_id,
              // values?.recovery_day,
              // values?.is_active,
              // values?.complaint,
              // values?.examination,
              // values?.diagnosis,
              // values?.treatment,
              // values?.injurySite,
              // values?.injuryValueSite,
              // values?.criticism,
              // values?.grade
            );
          }
        } catch (error) {
          console.error("Hata oluştu:", error);
        } finally {
          setLoading(false);
        }
      }
    });

  const [getPlayerId, setGetPlayerId] = useState(
    updateSelectedValuesProp?.treatment_player?.id
  );

  function CheckPlayerName() {
    console.log("bak1", selectedUserData);
    if (!(selectedUserData?.name) && updateSelectedValuesProp?.treatment_player?.user_name) {
      return updateSelectedValuesProp?.treatment_player?.name
    } else if (selectedUserData?.name) {
      return selectedUserData?.name + selectedUserData?.surname
    } else {
      return <i>Bulunamadı!</i>
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4 pt-4">
          <DynamicInput
            labelTitle={"Tedavi Başlangıç Tarihi"}
            containerStyle={"w-full"}
            name={"treatments_start_date"}
            value={values}
            required={false}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "treatments_start_date",
                setFieldValue,
                "datePicker"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"datePicker"}
          />
          <DynamicInput
            labelTitle={"Tedavi Başlangıç Saati"}
            containerStyle={"w-full"}
            name={"treatments_start_time"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "treatments_start_time",
                setFieldValue,
                "timePicker"
              );
            }}
            error={errors}
            required={false}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"timePicker"}
          />

          {/* oyuncu seçimi */}

          <div className="flex flex-col gap-4">
            <DynamicInput
              name={"selectTeam"}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"select"}
              labelTitle={"Takımlar"}
              containerStyle={"w-full"}
              placeholder={"Tümü"}
              required={false}
              customOptions={teams ?? []}
              value={{ selectTeam: selectTeam }}
              onChange={(e) => {
                setSelectTeam(e);
                fetchUsers(e);
              }}
            />

            <DynamicInput
              labelTitle={"Futbolcu Ara"}
              containerStyle={"w-full"}
              placeholder={"Ara..."}
              name={"searchInput"}
              required={false}
              value={searchInputValue}
              onChange={(e) => handleSearchOnChange(e)}
              error={null}
              isTouched={null}
              onBlur={null}
            />
{/* Bura */}
            <DataTable
              loading={loading}
              columns={[
                { title: "Ad", dataIndex: "name" },
                { title: "Soyad", dataIndex: "surname"},
                { title: "TC", dataIndex: "tckn" },
                { title: "Pozisyon", dataIndex: "position" },
                {
                  title: "Seçenekler",
                  dataIndex: "options",
                  render: (_, index) => {
                    return (
                      <>
                        <div className="flex gap-4">
                          <ButtonComponent
                            customStyle={"h-8"}
                            onClick={() => {
                              setSelectedUserData(index);
                              setFieldValue("player_id", index?.id);
                              setGetPlayerId(index?.id);
                            }}
                            text={"Seç"}
                            fill
                          />
                          {/* <ButtonComponent text={"Detay"} outline /> */}
                        </div>
                      </>
                    );
                  }
                }
              ]}
              data={
                searchInputValue ? filteredDataTableData : userDataTableData
              }
            />
          </div>
          <div className="">
            <h1 className="mt-2 text-xl">Seçilen Oyuncu</h1>
            <div className="flex flex-col w-full gap-4 p-6 mt-2 text-xl border border-primary rounded-xl">
              <div className="flex justify-between">
                <div>
                  <h1>
                    <b>Adı:</b>
                    <CheckPlayerName />
                  </h1>
                </div>

                <div className="flex flex-col col-span-full">
                  {selectedUserData?.img ||
                    updateSelectedValuesProp?.treatment_player?.imageurl ? (
                    <img
                      src={
                        baseImageURL +
                        (selectedUserData?.img
                          ? selectedUserData?.img
                          : updateSelectedValuesProp?.treatment_player
                            ?.imageurl)
                      }
                      className="w-[100px] h-[100px] object-fill "
                    />
                  ) : (
                    <p className="flex items-center justify-center w-32 h-32 text-center border aspect-square rounded-xl">
                      Fotoğraf Eklenmedi
                    </p>
                  )}
                </div>
              </div>
              {/* <h1>
                <b>TCKN:</b>
                {selectedUserData?.tckn ??
                  updateSelectedValuesProp?.treatment_player?.tckn ?? (
                    <i>Bulunamadı!</i>
                  )}
              </h1> */}
              {selectedUserData?.id ?? updateSelectedValuesProp ? (
                <Link
                  target="_blank"
                  to={
                    Paths.Users.Players.EditPlayers +
                    (selectedUserData?.id ??
                      updateSelectedValuesProp?.treatment_player?.id)
                  }
                >
                  <ButtonComponent outline text={"Oyuncu Detayı"} />
                </Link>
              ) : (
                <i className="text-sm text-gray-500">
                  Tedavi gören oyuncuyu seçiniz!
                </i>
              )}
            </div>
          </div>
          {/* oyuncu seçimi */}
          {/* doktor seçimi */}
          <div className="flex flex-col gap-4">
            <DynamicInput
              name={"selectDoctor"}
              labelTitle={"Doktor Seç"}
              containerStyle={"w-full"}
              required={false}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"select"}
              placeholder={"Doktor Seçiniz"}
              customOptions={doctorOptions ?? []}
              value={{ selectDoctor: selectDoctor }}
              onChange={(e) => {
                // console.log("Seçinize", e);
                setSelectDoctor(e);
                setPlayerTreatmentFilter((prevState) => ({
                  ...prevState
                }));
              }}
            />
          </div>

          <div className="">
            <h1 className="mt-2 text-xl">Seçilen Doktor</h1>
            <div className="flex flex-col w-full gap-4 p-6 mt-2 text-xl border border-primary rounded-xl">
              <h1>
                <b>Adı </b>
                {selectDoctor?.data?.name ? (
                  selectDoctor.data.name
                ) : (
                  <i>Bulunamadı!</i>
                )}
              </h1>
              {/* <h1>
                <b>TCKN: </b>
                {
                  selectDoctor?.data?.tckn ? selectDoctor.data.tckn : (  <i>Bulunamadı!</i> )
                }
              </h1> */}

              {selectedDoctorData?.id ?? updateSelectedValuesProp ? (
                <Link
                  target="_blank"
                  to={
                    Paths.Users.Players.EditPlayers +
                    (selectedDoctorData?.id ??
                      updateSelectedValuesProp?.treatment_doctor?.id)
                  }
                >
                  <ButtonComponent outline text={"Doktor Detayı"} />
                </Link>
              ) : (
                <i className="text-sm text-gray-500">
                  Tedavi eden doktoru seçiniz!
                </i>
              )}
            </div>
          </div>
          <div className="flex items-center justify-start gap-5 w-full h-full">
            <DynamicInput
              labelTitle={"Sakatlık Genel Bölge Seçimi"}
              containerStyle={"w-full"}
              placeholder={"Sakatlık Bölge Seçimi"}
              name={"injurySite"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "injurySite",
                  setFieldValue,
                  "select"
                );
                setInjurySiteValue(e1.value);
              }}
              error={errors}
              isTouched={touched}
              required={false}
              onBlur={handleBlur}
              component_type={"select"}
              customOptions={injuryOptions}
            />

            {injurySiteValue === "diz" ? (
              <>
                <DynamicInput
                  labelTitle={"Sakatlık Yönü"}
                  containerStyle={"w-full"}
                  placeholder={"Sakatlık Yönü"}
                  customOptions={injuryValueOptions}
                  value={values}
                  required={false}
                  name={"injuryValueSite"}
                  error={errors}
                  isTouched={touched}
                  onBlur={handleBlur}
                  component_type={"select"}
                  onChange={(e1, e2) => {
                    handleCustomChange(
                      e1,
                      e2,
                      "injuryValueSite",
                      setFieldValue,
                      "select"
                    );
                  }}
                />
              </>
            ) : null}
          </div>
          <DynamicInput
            labelTitle={"Sakatlık Seviyesi"}
            containerStyle={"w-full"}
            placeholder={"Sakatalık Seviyesi"}
            customOptions={[
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" }
            ]}
            value={values}
            name={"grade"}
            error={errors}
            required={false}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"selectColor"}
            onChange={(e1, e2) => {
              handleCustomChange(e1, e2, "grade", setFieldValue, "select");
            }}
          />
          <DynamicInput
            labelTitle={"Tedavi Tipi"}
            containerStyle={"w-full"}
            placeholder={
              updateSelectedValuesProp?.treatment_type?.treatments_type_title ??
              "Tedavi Tipi Başlığı"
            }
            name={"treatments_type_id"}
            value={values}
            required={false}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "treatments_type_id",
                setFieldValue,
                "select"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"select"}
            customOptions={allTreatmentsTypeOption}
          />

          <DynamicInput
            labelTitle={"Tedavi Durumu"}
            containerStyle={"w-full"}
            placeholder={
              updateSelectedValuesProp?.treatment_status
                ?.treatments_status_id ?? "Tedavi Durumu"
            }
            name={"treatments_status_id"}
            value={values}
            required={false}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "treatments_status_id",
                setFieldValue,
                "select"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"select"}
            customOptions={allTreatmentsStatusOption}
          />

          <DynamicInput
            labelTitle={"Geri Dönüş Zamanı"}
            containerStyle={"w-full"}
            required={false}
            placeholder={
              updateSelectedValuesProp?.recovery_day ?? "Gün Sayısı Giriniz"
            }
            name={"recovery_day"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "recovery_day",
                setFieldValue,
                "input"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"input"}
            type={"number"}
          />

          <DynamicInput
            labelTitle={"Aktif Mi?"}
            containerStyle={"w-full"}
            placeholder={"Aktif mi?"}
            name={"is_active"}
            required={false}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "is_active",
                setFieldValue,
                "checkbox"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"checkbox"}
          />

          <DynamicInput
            labelTitle={"Yakınma/Şikayet"}
            containerStyle={"w-full"}
            placeholder={updateSelectedValuesProp?.complaint ?? "Şikayet"}
            name={"complaint"}
            required={false}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "complaint",
                setFieldValue,
                "textarea"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"textarea"}
          />

          <DynamicInput
            labelTitle={"Tetkik"}
            containerStyle={"w-full"}
            placeholder={updateSelectedValuesProp?.examination ?? "Tetkik"}
            name={"examination"}
            value={values}
            required={false}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "examination",
                setFieldValue,
                "textarea"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"textarea"}
          />
          <DynamicInput
            labelTitle={"Tanı/Tehşis"}
            containerStyle={"w-full"}
            placeholder={updateSelectedValuesProp?.diagnosis ?? "Tanı"}
            name={"diagnosis"}
            value={values}
            required={false}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "diagnosis",
                setFieldValue,
                "textarea"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"textarea"}
          />
          {/* <DynamicInput
            labelTitle={"Eleştiri"}
            containerStyle={"w-full"}
            placeholder={updateSelectedValuesProp?.criticism ?? "Eleştiri"}
            name={"criticism"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "criticism",
                setFieldValue,
                "textarea"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"textarea"}
          /> */}

          <DynamicInput
            labelTitle={"İlk Müdahale"}
            containerStyle={"w-full"}
            placeholder={updateSelectedValuesProp?.treatment ?? "İlk Müdahale"}
            name={"treatment"}
            value={values}
            required={false}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "treatment",
                setFieldValue,
                "textarea"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
            component_type={"textarea"}
          />

          {/* kontoller */}

          {!updateSelectedValuesProp && (
            <div
              class="col-span-full flex items-start p-4 my-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 w-full"
              role="alert"
            >
              <svg
                class="flex-shrink-0 inline w-4 h-4 mr-3 mt-[2px]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span class="sr-only">Info</span>
              <div>
                <span class="font-medium">DİKKAT!</span>
                <div className="flex flex-col gap-2 my-2 ml-2">
                  <p className="pl-2 border-l-2 border-yellow-300">
                    <b>İlaç girişleri</b> ve yapılan <b>kontrol girişleri</b>{" "}
                    tedaviyi oluşturduktan sonra güncelleme kısmında
                    eklenebilir.
                  </p>
                </div>
              </div>
            </div>
          )}

          {updateSelectedValuesProp && (
            <>
              <div className="flex w-full pb-1 col-span-full">
                <ButtonComponent
                  onClick={() =>
                    handleJudgeDeleteOnClick(updateSelectedValuesProp)
                  }
                  text={"Tedaviyi Sil"}
                  outline
                  customStyle={"w-fit h-[56px]"}
                />
              </div>
            </>
          )}
        </div>

        <div className="flex items-center justify-end gap-4 pt-4">
          <ButtonComponent submit fill text={"Kaydet"} />
        </div>
      </form>
      <div className="grid grid-cols-2 gap-4 py-16">
        {updateSelectedValuesProp && (
          <>
            <div className="col-span-full">
              <PageTitle bg title={"İlaçlar"} />
            </div>
            {/* ilaç seçimi */}
            <div className="flex flex-col gap-4">
              <DynamicInput
                labelTitle={"İlaç Seç"}
                containerStyle={"w-full"}
              required={false}
                placeholder={"Değer Arayın..."}
                name={"searchInput"}
                value={medicinesInputValue}
                onChange={(e) => handleMedicinesSearchOnChange(e)}
                error={null}
                isTouched={null}
                onBlur={null}
              />

              <DataTable
                loading={loading}
                columns={[
                  { title: "İlaç Adı", dataIndex: "title" },
                  { title: "İlaç Kullanımı", dataIndex: "prospectus" },
                  {
                    title: "Seçenekler",
                    dataIndex: "options",
                    render: (_, index) => {
                      return (
                        <>
                          <div className="flex gap-4">
                            <ButtonComponent
                              loading={updateSelectedValuesProp?.treatment_medicines.find(
                                (item) => item?.id == index?.id
                              )}
                              customStyle={"h-8"}
                              onClick={() => {
                                // setSelectedMedicinesData(index);
                                fetchCreateTreatmentsBelongMedicines(
                                  updateSelectedValuesProp?.id,
                                  index?.id
                                );
                              }}
                              text={"Seç"}
                              fill
                            />
                            {/* <ButtonComponent text={"Detay"} outline /> */}
                          </div>
                        </>
                      );
                    }
                  }
                ]}
                data={
                  medicinesInputValue
                    ? filteredMedicinesDataTableData
                    : medicinesDataTableData?.reverse()
                }
              />
            </div>
            <div className="">
              {updateSelectedValuesProp?.treatment_medicines?.length ==
                0 ? null : (
                <h1 className="mt-2 text-xl">Seçilen İlaç</h1>
              )}
              {updateSelectedValuesProp?.treatment_medicines?.map(
                (item, index) => {
                  return (
                    <>
                      <div className="flex flex-col w-full gap-4 p-6 mt-2 text-xl border border-primary rounded-xl">
                        <h1>
                          <b>Adı:</b>
                          {item?.medicine_title ?? <i>Bulunamadı!</i>}
                        </h1>
                        <h1>
                          <b>Kullanımı:</b>
                          {item?.medicine_prospectus ?? <i>Bulunamadı!</i>}
                        </h1>
                        <ButtonComponent
                          text={"İlacı Çıkar"}
                          outline
                          customStyle={"w-fit"}
                          onClick={() => handleBelongDeleteOnClick(item)}
                        />
                      </div>
                    </>
                  );
                }
              )}
            </div>
            {/* ilaç seçimi */}
          </>
        )}
        {/* kontroller */}
        {
          <ControlSeciton
            fetchCreateTreatmentsControl={fetchCreateTreatmentsControl}
            updateSelectedValuesProp={updateSelectedValuesProp}
          />
        }
        {/* kontoller */}
        <div>
          <div className="flex flex-col gap-4">
            {updateSelectedValuesProp?.treatment_controls?.map(
              (item, index) => {
                return (
                  <>
                    <div className="flex flex-col w-full gap-4 p-6 text-xl border border-primary rounded-xl">
                      <h1>
                        <b>Tarih:</b>{" "}
                        {dayjs(item?.control_date).format("DD-MM-YYYY") ?? (
                          <i>Bulunamadı!</i>
                        )}
                      </h1>
                      <h1>
                        <b>Açıklama:</b>
                        {item?.control_description ?? <i>Bulunamadı!</i>}
                      </h1>
                      <h1>
                        <b>Sağlık Kuruluşu:</b>
                        {item?.health_organization ?? <i>Bulunamadı!</i>}
                      </h1>
                      <div className="flex items-center gap-4">
                        <ButtonComponent
                          text={"Kaydını Sil"}
                          outline
                          customStyle={"w-fit"}
                          onClick={() => handleControlDeleteOnClick(item)}
                        />
                        <ButtonComponent
                          text={"Kaydı Güncelle"}
                          outline
                          customStyle={"w-fit"}
                          onClick={() => {
                            setControlUpdateModalIsOpen(true);
                            setSelectedControlData({
                              id: item?.id,
                              control_date: item?.control_date,
                              control_description: item?.control_description,
                              health_organization: item?.health_organization
                            });
                          }}
                        />
                        <div>
                          {controlUpdateModalIsOpen && (
                            <div>
                              <CustomModal
                                isOpen={controlUpdateModalIsOpen}
                                setOpen={setControlUpdateModalIsOpen}
                                width={677}
                                footer={null}
                              >
                                <CustomModalHeader
                                  title={"Kontrol Kaydını Güncelleyin"}
                                  description={
                                    "Aşağıdan kontrol kaydını güncelleyin."
                                  }
                                />
                                <Formik
                                  initialValues={{
                                    control_date:
                                      dayjs(
                                        selectedControlData?.control_date
                                      ).format("YYYY-MM-DD") ?? "",
                                    control_description:
                                      selectedControlData?.control_description,
                                    health_organization:
                                      selectedControlData?.health_organization
                                  }}
                                  onSubmit={(values) => {
                                    fetchUpdateTreatmentsControl(
                                      values?.control_date,
                                      values?.control_description,
                                      values?.health_organization
                                    );
                                  }}
                                >
                                  {({
                                    errors,
                                    values,
                                    handleSubmit,
                                    touched,
                                    handleBlur,
                                    setFieldValue
                                  }) => (
                                    <form
                                      onSubmit={handleSubmit}
                                      className="mt-4"
                                    >
                                      <DynamicInput
                                        labelTitle={"Kontrol Tarihi"}
                                        containerStyle={"w-full"}
                                        name={"control_date"}
                                        required={false}
                                        value={values}
                                        onChange={(e1, e2) => {
                                          handleCustomChange(
                                            e1,
                                            e2,
                                            "control_date",
                                            setFieldValue,
                                            "datePicker"
                                          );
                                        }}
                                        error={errors}
                                        isTouched={touched}
                                        onBlur={handleBlur}
                                        component_type={"datePicker"}
                                      />
                                      <DynamicInput
                                        labelTitle={"Kontrol Açıklaması"}
                                        containerStyle={"w-full"}
                                        placeholder={item.control_description}
                                        name={"control_description"}
                                        required={false}
                                        value={values}
                                        onChange={(e1, e2) => {
                                          handleCustomChange(
                                            e1,
                                            e2,
                                            "control_description",
                                            setFieldValue,
                                            "textarea"
                                          );
                                        }}
                                        error={errors}
                                        isTouched={touched}
                                        onBlur={handleBlur}
                                        component_type={"textarea"}
                                      />
                                      <DynamicInput
                                        labelTitle={"Sağlık Kuruluşu"}
                                        containerStyle={"w-full"}
                                        placeholder={item.health_organization}
                                        name={"health_organization"}
                                        value={values}
                                         required={false}
                                        onChange={(e1, e2) => {
                                          handleCustomChange(
                                            e1,
                                            e2,
                                            "health_organization",
                                            setFieldValue,
                                            "textarea"
                                          );
                                        }}
                                        error={errors}
                                        isTouched={touched}
                                        onBlur={handleBlur}
                                        component_type={"textarea"}
                                      />
                                      <div className=" flex justify-end pt-2 gap-5 ">
                                        <ButtonComponent
                                          fill
                                          text={"Vazgeç"}
                                          onClick={() =>
                                            setControlUpdateModalIsOpen(false)
                                          }
                                        />
                                        <ButtonComponent
                                          fill
                                          text={"Kaydet"}
                                          submit
                                        />
                                      </div>
                                    </form>
                                  )}
                                </Formik>
                              </CustomModal>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </div>
        </div>
        {/* kontroller */}
      </div>
    </>
  );
}

function CreateTreatmentsModal({ isOpen, setOpen, setIsDataChange }) {
  return (
    <div>
      <div>
        <CustomModal
          width={777}
          isOpen={isOpen}
          setOpen={setOpen}
          footer={null}
        >
          <CustomModalHeader
            title={"Yeni Tedavi Ekleyin"}
            description={"Aşağıdan yeni tedavi ekleyin."}
          />
          <CreateTreatmentsForm
            isOpen={isOpen}
            setOpen={setOpen}
            setIsDataChange={setIsDataChange}
          />
        </CustomModal>
      </div>
    </div>
  );
}

export function ControlSeciton({
  fetchCreateTreatmentsControl,
  updateSelectedValuesProp
}) {
  return (
    updateSelectedValuesProp && (
      <>
        <div className="col-span-full">
          <PageTitle bg title={"Kontroller"} />
        </div>
        <Formik
          initialValues={{
            control_date: dayjs("").format("YYYY-MM-DD") ?? "",
            control_description: "",
            health_organization: ""
          }}
          onSubmit={async (values) => {
            // console.log(values, "controlvalues");
            await fetchCreateTreatmentsControl(
              updateSelectedValuesProp?.id,
              values?.control_date,
              values?.control_description,
              values?.health_organization
            );
          }}
        >
          {({
            errors,
            values,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <DynamicInput
                labelTitle={"Kontrol Tarihi"}
                containerStyle={"w-full"}
                name={"control_date"}
                value={values}
              required={false}
                onChange={(e1, e2) => {
                  handleCustomChange(
                    e1,
                    e2,
                    "control_date",
                    setFieldValue,
                    "datePicker"
                  );
                }}
                error={errors}
                isTouched={touched}
                onBlur={handleBlur}
                component_type={"datePicker"}
              />
              <DynamicInput
                labelTitle={"Kontrol Açıklaması"}
                containerStyle={"w-full"}
                placeholder={"Tedavi Açıklaması"}
                name={"control_description"}
                value={values}
              required={false}
                onChange={(e1, e2) => {
                  handleCustomChange(
                    e1,
                    e2,
                    "control_description",
                    setFieldValue,
                    "textarea"
                  );
                }}
                error={errors}
                isTouched={touched}
                onBlur={handleBlur}
                component_type={"textarea"}
              />
              <DynamicInput
                labelTitle={"Sağlık Kuruluşu"}
                containerStyle={"w-full"}
                placeholder={"Gidilen Sağlık Kuruluşu"}
                name={"health_organization"}
                value={values}
              required={false}
                onChange={(e1, e2) => {
                  handleCustomChange(
                    e1,
                    e2,
                    "health_organization",
                    setFieldValue,
                    "textarea"
                  );
                }}
                error={errors}
                isTouched={touched}
                onBlur={handleBlur}
                component_type={"textarea"}
              />
              <ButtonComponent
                text={"Kontrol Kaydını Ekle"}
                outline
                customStyle={"w-fit mt-4"}
                submit
              />
            </form>
          )}
        </Formik>
      </>
    )
  );
}

export default CreateTreatmentsModal;
