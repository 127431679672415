import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/pageTitle";
import DrawerMenuAntd, {
  getMenuItem,
} from "../../../components/drawerMenuAntd";
import { SettingOutlined } from "@ant-design/icons";
import {
  createDynamicFormElement,
  getDynamicForm,
  getDynamicFormGroup,
  getSelects,
} from "../../../services/axios";
import { useFormik } from "formik";
import { handleCustomChange } from "../../../utils/handleCustomChange";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import CustomModalHeader from "../../../components/modals/CustomModalHeader";
import CustomModal from "../../../components/modals";
import { FiActivity } from "react-icons/fi";
import DynamicInput from "../../../components/inputs/dynamicInput";

import { nanoid } from "nanoid";

export const DynamicFormMenuData = [
  //getMenuItem("Oyuncular Formu", "menu-1", <SettingOutlined />, null),
];

const inputElement = {
  //   disabled: "",
  label: "",
  //   id: "",
  //   name: "",
  type: "",
  //   component_type: "",
  placeholder: "",
  //   value: "",
  required: "",
  //   max_length: "", 191
  //   min_length: "", 1
  options: "",
  //   [
  //     { label: "Option 1", value: "option_1" },
  //     { label: "Option 2", value: "option_2" },
  //   ]
};
const CreateDynamicFormInputData = [
  {
    labelTitle: "Başlık",
    name: "label",
    placeholder: "Label",
    required: true,
    leftIcon: <FiActivity className="w-full h-full" />,
    component_type: "input",
  },
  {
    labelTitle: "Tip",
    name: "type",
    placeholder: "Type",
    required: true,
    leftIcon: <FiActivity className="w-full h-full" />,
    onChangeType: "event",
    options: [
      // { value: "dateRange", label: "Zamanlı Tarih Aralığı" },
      { value: "input", label: "Düz Metin" },
      { value: "textarea", label: "Yorum Metni" },
      { value: "select", label: "Seçenek Seçimi" },
      { value: "checkbox", label: "Doğru / Yanlış Seçimi" },
      { value: "datePicker", label: "Tarih Seçim" },
      { value: "fileUpload", label: "Dosya Yükleme" },
      { value: "phoneNumber", label: "Telefon Numarası" },
    ],
    component_type: "select",
  },
  {
    labelTitle: "İç Başlık",
    name: "placeholder",
    placeholder: "Placeholder",
    required: true,
    leftIcon: <FiActivity className="w-full h-full" />,
    component_type: "input",
  },
  {
    labelTitle: "Zorunlu mu?",
    name: "required",
    required: true,
    leftIcon: <FiActivity className="w-full h-full" />,
    checkbox: true,
    placeholder: "Giriş zorunlu olsun mu?",
    onChangeType: "event.target.checked",
    component_type: "checkbox",
  },
  {
    labelTitle: "Name",
    name: "name",
    placeholder: "name",
    required: true,
    leftIcon: <FiActivity className="w-full h-full" />,
    component_type: "input",
    disabled: true,
  },
];

function CreateDynamicForm() {
  const [mainMenuSelected, setMainMenuSelected] = useState(0);
  const [loading, setLoading] = useState(false);

  const [responseDataItem, setResponseDataItem] = useState([]);
  const [selectedResponseDataItem, setSelectedResponseDataItem] =
    useState(null); //form id'yi modal'a almak için
  const [dynamicFormMenuData, setDynamicFormMenuData] = useState([
    // getMenuItem("Oyuncular Formu", "menu-1", <SettingOutlined />, null),
  ]);
  const [getSelectsData, setGetSelectsData] = useState(null);

  const [allSelectOptions, setAllSelectOptions] = useState(null);

  const [openCreateElementForm, setOpenCreateElementForm] = useState(false);

  const [transformedData, setTransformedData] = useState([]);

  async function fetchGetSelects() {
    setLoading(true);
    try {
      const result = await getSelects();
      setGetSelectsData(result.response);
      return result;
    } catch (error) {
      console.error("Hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchGetDynamicFormGroup() {
    setLoading(true);
    setDynamicFormMenuData([]);
    try {
      const result = await getDynamicFormGroup("User");
      setResponseDataItem(result.response.map((item) => item));
      result.response.forEach((item, index) => {
        handleDataPush(item.title, index, <SettingOutlined />, null);
      });
      setLoading(false);
    } catch (error) {
      console.error("Hata oluştu:", error);
      setLoading(false);
    }
  }
  const handleDataPush = (label, value) => {
    const newItem = getMenuItem(label, value, <SettingOutlined />, null);
    setDynamicFormMenuData((prevInputs) => [...prevInputs, newItem]);
  };

  const { errors, values, handleSubmit, touched, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        disabled: false,
        label: "",
        id: nanoid(),
        name: nanoid(),
        type: "",
        component_type: "",
        placeholder: "",
        value: "",
        required: "",
        max_length: "191",
        min_length: "1",
        options: "",
      },
      onSubmit: async (values, { resetForm }) => {
        values.component_type = values.type["value"];
        values.type = values.type["label"];
        values.options = values?.options?.id;

        try {
          setLoading(true);
          const result = await createDynamicFormElement(
            JSON.stringify(values),
            selectedFormIdString
            //  form id
          );
          if (result.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result?.message ?? "Ekleme Başarılı!",
              showConfirmButton: false,
              timer: 1000,
            });
            setOpenCreateElementForm(false);
            await fetchGetDynamicFormGroup();
            //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: result?.message ?? "Ekleme Başarısız!",
              showConfirmButton: false,
              timer: 1000,
            });
          }

          setLoading(false);
          resetForm();
          // window.location.reload();
        } catch (error) {
          console.error(error);
        }
      },
      validate: (values) => {
        const errors = {};
        //   CreateDynamicFormInputData.forEach((input) => {
        //     if (input.required && !values[input.name]) {
        //       errors[input.name] = `${input.labelTitle} alanı boş bırakılamaz.`;
        //     }
        //   });
        //Tüm alanların zorunlu olduğunu doğrula
        //   Object.keys(values).forEach((key) => {
        //     console.log(errors,"errors")
        //     if (!values[key]) {
        //       errors[key] = "Bu alan boş bırakılamaz!";
        //     }
        //   });
        console.log(errors, "errors");
        return errors;
      },
    });

  useEffect(() => {
    const transformedData = getSelectsData?.map((item) => ({
      id: item.id,
      label: item.title,
      value: nanoid(),
    }));

    setTransformedData(transformedData);
  }, [getSelectsData]);

  useEffect(() => {
    if (true) {
      fetchGetDynamicFormGroup();
    }
  }, []);

  useEffect(() => {
    fetchGetSelects();
  }, []);

  useEffect(() => {
    setSelectedResponseDataItem(
      responseDataItem
        ?.filter((filterItem, filterIndex) => filterIndex == mainMenuSelected)
        .map((item, index) => {
          return item;
        })
    );
  }, [mainMenuSelected, responseDataItem]);
  //   console.log(responseDataItem[0].form_elements[0].options_json.component_type, "responseDataItem id");

  const selectedFormIdString = String(
    selectedResponseDataItem?.map((item) => item.id)
  );

  return (
    <div>
      <div className="w-full h-full py-4 bg-white shadow rounded-2xl">
        <PageTitle title={"Dinamik Formlar"} bg />
        {/* <p>{mainMenuSelected}</p> */}
        {loading && (
          <div className="flex">
            <PulseLoader color="red" />
            <p>Yükleniyor...</p>
          </div>
        )}
        {!loading && (
          <div className="flex w-full">
            <DrawerMenuAntd
              items={dynamicFormMenuData}
              selectedKey={mainMenuSelected}
              setSelectedKey={setMainMenuSelected}
            />
            <div className="w-full">
              {responseDataItem
                ?.filter(
                  (filterItem, filterIndex) => filterIndex == mainMenuSelected
                )
                .map((item, index) => {
                  return (
                    <>
                      {/* {item.form_elements.map((itemFormElement) =>
                      console.table(itemFormElement.options_json)
                    )} */}

                      {loading && (
                        <>
                          <div className="flex items-center justify-center w-full gap-4">
                            <PulseLoader color="red" />
                            <p>Yükleniyor...</p>
                          </div>
                        </>
                      )}
                      {!loading && (
                        <div className="w-full">
                          <div className="grid w-full grid-cols-2 gap-4 px-8">
                            {item.form_elements.map(
                              (itemFormElement, indexFormElent) => {
                                return (
                                  <>
                                    <div
                                      key={indexFormElent}
                                      className="w-full"
                                    >
                                      <DynamicInput
                                        disabled={true}
                                        type={itemFormElement?.options_json?.type}
                                        labelTitle={
                                          itemFormElement?.options_json?.label
                                        }
                                        containerStyle={"w-full"}
                                        placeholder={
                                          itemFormElement?.options_json?.placeholder
                                        }
                                        name={itemFormElement?.options_json?.name}
                                        value={
                                          itemFormElement?.options_json?.value
                                        }
                                        error={""}
                                        isTouched={""}
                                        onBlur={""}
                                        onChange={(e1, e2) =>
                                          handleCustomChange(
                                            e1,
                                            e2,
                                            item.name,
                                            setFieldValue,
                                            item.component_type
                                          )
                                        }
                                        required={itemFormElement.required}
                                        //   options={itemFormElement.options}
                                        select={itemFormElement.select}
                                        component_type={
                                          itemFormElement?.options_json?.component_type
                                        }
                                        options={""}
                                      />
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                          <div className="flex items-center justify-end gap-4 px-8 mt-8">
                            <ButtonComponent
                              onClick={() => {
                                setOpenCreateElementForm(true);
                              }}
                              outline
                              text="Ekle"
                            />
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>

            <CustomModal
              //faaliyet adresi ekleme
              width={677}
              isOpen={openCreateElementForm}
              setOpen={setOpenCreateElementForm}
              footer={null}
            >
              <CustomModalHeader
                title={"Yeni Input Ekleyin"}
                description={"Yeni Input Ekleyin"}
              />
              <form onSubmit={handleSubmit} className="w-full pt-8">
                <div className="grid w-full grid-cols-2 gap-4 px-8">
                  {CreateDynamicFormInputData.map((item, index) => (
                    <div key={index} className="w-full">
                      <DynamicInput
                        disabled={item?.disabled}
                        type={"text"}
                        labelTitle={item.labelTitle}
                        containerStyle={"w-full"}
                        placeholder={item?.placeholder}
                        name={item.name}
                        value={values}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        onChange={(e, e2) =>
                          handleCustomChange(
                            e,
                            e2,
                            item.name,
                            setFieldValue,
                            item.component_type
                          )
                        }
                        required={true}
                        customOptions={item?.options}
                        checkboxLabel={item.checkboxLabel}
                        component_type={item.component_type}
                      />
                    </div>
                  ))}
                  <DynamicInput
                    disabled={values.type.value == "select" ? false : true}
                    type={"text"}
                    labelTitle={"Tanımlı Seçenekler"}
                    containerStyle={"w-full"}
                    placeholder={"Tanımlı Seçenekler"}
                    name={"options"}
                    value={values.options}
                    error={errors.options}
                    isTouched={touched.options}
                    onBlur={handleBlur}
                    onChange={(e, e2) =>
                      handleCustomChange(
                        e,
                        e2,
                        "options",
                        setFieldValue,
                        "select"
                      )
                    }
                    required={true}
                    customOptions={transformedData}
                    component_type={"select"}
                  />
                </div>
                <div className="flex items-center justify-end gap-4 px-8 mt-8">
                  <ButtonComponent
                    onClick={() => setOpenCreateElementForm(false)}
                    outline
                    text="Vazgeç"
                  />
                  <ButtonComponent fill text="Kaydet" submit />
                </div>
              </form>
            </CustomModal>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateDynamicForm;
