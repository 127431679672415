import React from "react";
import { useSelector } from "react-redux";
import { Files } from "../utils";

const Profile = ({ image, name, job }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img
        src={image}
        className="object-contain bg-white border-4 border-gray-100 rounded-full w-36 h-36"
      />
      <h3 className="mt-2 text-lg font-semibold">{name}</h3>
      <h5 className="text-sm text-gray-400">
        <strong>{job}</strong>
      </h5>
    </div>
  );
};

export default Profile;
