import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getActivitiesCategories,
  getActivitiesCategory,
  getActivity,
  getAllAreas,
  getAllDrills,
  getAllTeamCategory,
  getAllTests,
  getAllUser,
  getTestById,
  listTeams,
  updateActivity,
  updateActivityTest,
} from "../../../services/axios";
import PageTitle from "../../../components/pageTitle";
import { Formik } from "formik";
import DynamicInput from "../../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../../utils/handleCustomChange";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import { DataTable } from "../../../components/tables";
import { CarryOutOutlined } from "@ant-design/icons";
import { Tree } from "antd";

function EditTest() {
  let { id: activityID } = useParams();
  const [resultData, setResultData] = useState(null); //activity data
  const [isDataChange, setIsDataChange] = useState(true);
  const [loading, setLoading] = useState(false);

  // listelenen satırlar
  const [selectedRowItemsPlayer, setSelectedRowItemsPlayer] = useState(null);
  const [selectedRowItemsManager, setSelectedRowItemsManager] = useState(null);

  // eklenen satırlar
  const [rowSelectionList, setRowSelectionList] = useState(null);
  const [rowSelectionListManager, setRowSelectionListManager] = useState([]);

  // datatable'ın kendisi
  const [userDataTableData, setUserDataTableData] = useState();
  const [managerDataTableData, setManagerDataTableData] = useState();

  console.log(userDataTableData, "userDataTableData");

  const [formLoading, setFormLoading] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [treeData, setTreeData] = useState([]);

  const [areasOptions, setAreasOptions] = useState([]);
  const [drillsOptions, setDrillsOptions] = useState([]);

  const [newTest, setNewTest] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);

  const [selectTeamPlayers, setSelectTeamPlayers] = useState([]);
  const [teamCategoryOptions, setTeamCategoryOptions] = useState([]);

  useEffect(() => {
    if (!resultData) {
      fetchGetActity(activityID).then((result) => {
        setResultData(result?.data?.activity);
        setSelectedRowItemsPlayer({
          selectedRowItems: result?.data?.activity?.activity_player.map(
            (item) => ({
              key: item?.player?.id,
              name: item?.player?.user_name,
              tckn: item?.player?.tckn,
            })
          ),
        });
        setSelectedRowItemsManager({
          selectedRowItems: result?.data?.activity?.activity_managment.map(
            (item) => ({
              key: item?.managment?.id,
              name: item?.managment?.user_name,
              tckn: item?.managment?.tckn,
            })
          ),
        });
      });
    } else {
      fetchGetAllUsers().then((result) => {
        const datatableDataPlayer = result?.data?.users.map((item, index) => {
          return {
            key: item?.id,
            name: item?.user_name,
            tckn: item?.tckn,
            disabled: !!resultData?.activity_player.find(
              (itemFilter) => itemFilter?.player?.id == item?.id
            ),
          };
        });

        const datatableDataManager = result?.data?.users.map((item, index) => {
          return {
            key: item?.id,
            name: item?.user_name,
            tckn: item?.tckn,
            disabled: !!resultData?.activity_managment.find(
              (itemFilter) => itemFilter?.managment?.id == item?.id
            ),
          };
        });
        setUserDataTableData(datatableDataPlayer);
        setManagerDataTableData(datatableDataManager);
      });
    }
  }, [isDataChange, resultData]);

  const mergeSelectionsPlayers = rowSelectionList?.selectedRowItems
    ? selectedRowItemsPlayer?.selectedRowItems.concat(
        rowSelectionList?.selectedRowItems
      )
    : selectedRowItemsPlayer?.selectedRowItems;

  const mergeSelectionsManagment = rowSelectionListManager?.selectedRowItems
    ? selectedRowItemsManager?.selectedRowItems.concat(
        rowSelectionListManager?.selectedRowItems
      )
    : selectedRowItemsManager?.selectedRowItems;

  console.log(managerDataTableData, "managerDataTableData");

  const fetchGetActity = async (id) => {
    setLoading(true);
    try {
      const result = getActivity(id);
      return result;
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsDataChange(false);
      setLoading(false);
    }
  };

  const fetchGetAllUsers = async () => {
    try {
      const result = await getAllUser();
      console.log(result.data.users, "getAllUsers");
      return result;
    } catch (error) {
      console.log(error, "error");
    }
  };

  const fetchGetAllDrills = async () => {
    setLoading(true);
    try {
      const result = await getAllDrills();
      return result;
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  const fetchGetAllCategory = async () => {
    try {
      const result = await listTeams();
      console.log(result?.data?.teams, "team");
      return result?.data?.teams;
    } catch (error) {
      console.log(error, "err");
    }
  };

  const onChangeTeamSelect = (selected) => {
    const teamPlayersMap = selected?.players?.map((item) => item.player);
    console.log(teamPlayersMap, "teamPlayersMap");
    const teamPlayersDataTableFormat = teamPlayersMap?.map((item) => ({
      key: item?.id,
      name: item?.user_name,
      tckn: item?.tckn,
      disabled: !!resultData?.activity_player.find(
        (itemFilter) => itemFilter?.player?.id == item?.id
      ),
    }));
    console.log(teamPlayersMap, "selectedPlayer");

    setSelectTeamPlayers(teamPlayersDataTableFormat);

    console.log(selected, "selectTeamPlayers");
  };

  const fetchUpdateActivity = async (
    id,
    title,
    activity_type,
    start_date,
    end_date,
    status,
    start_time,
    end_time,
    // ozel alanlar
    description,
    weather,
    activity_area,
    managers,
    players,
    notes,
    tests,
    color,
    team_category
  ) => {
    setLoading(true);
    try {
      const result = await updateActivityTest(
        id,
        title,
        activity_type,
        start_date,
        end_date,
        status,
        start_time,
        end_time,
        // ozel alanlar
        description,
        weather,
        activity_area,
        managers,
        players,
        notes,
        tests,
        color,
        team_category
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });
        window.location.reload();
        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log("error update test", error);
    } finally {
      // setIsDataChange(true);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isDataChange) {
      fetchGetAllTests().then((result) =>
        setAllCategories(result?.data?.categories)
      );
      fetchGetAreas().then((result) =>
        setAreasOptions(
          result?.response?.areas?.map((item, index) => ({
            label: item?.title,
            value: item?.id,
          }))
        )
      );
      fetchGetAllCategory().then((teams) =>
        setTeamCategoryOptions([
          { label: "Tüm Kullanıcılar", value: "" }, // Başlangıç öğesi
          ...teams.map((item) => ({
            label: item?.team_title,
            value: item?.id,
            players: item?.players,
          })),
        ])
      );
      fetchGetAllDrills().then((result) =>
        setDrillsOptions(
          result?.data?.drills.map((item) => ({
            label: item?.title,
            value: item?.id,
          }))
        )
      );
    }
  }, [isDataChange]);

  const onSelect = async (selectedKeys, info) => {
    setFormLoading(true);
    console.log("selected", selectedKeys, info);
    try {
      const result = await getTestById(selectedKeys); //tek kategori çağrıma
      console.log(result, "ergoo");
      setSelectedTest(result?.data?.test);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setFormLoading(false);
    }
  };

  const fetchGetAreas = async () => {
    try {
      const result = await getAllAreas();
      console.log(result?.response?.areas, "fetchGetAreas");
      return result;
    } catch (error) {
      console.log(error, "error");
    }
  };

  const filteredStatus = [
    { label: "Gerçekleşti", value: "gerceklesti" },
    { label: "Planlandı", value: "planlandi" },
    { label: "İptal Edildi", value: "iptal_edildi" },
  ].find((item) => item?.value == resultData?.status);

  const activityColors = [
    { label: "Kırmızı", value: "red" },
    { label: "Mavi", value: "blue" },
    { label: "Yeşil", value: "green" },
  ].find((item) => item?.value == resultData?.color);

  const filteredWeather = [
    { label: "Yağmurlu", value: "yagmurlu" },
    { label: "Güneşli", value: "gunesli" },
  ].find((item) => item?.value == resultData?.test_activity_meta?.weather);

  console.log(resultData, "resultData");

  console.log(rowSelectionList, "rowSelectionList");

  //TESTS

  const fetchGetAllAreas = async () => {
    setLoading(true);
    try {
      const result = await getAllAreas();
      console.log(result, "result");
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  const fetchGetAllTests = async () => {
    setLoading(true);
    setTreeData([]);
    try {
      const result = await getAllTests();
      result?.data?.tests.forEach((item) => {
        if (!item.up_category) {
          const treeItem = getTreeItem(
            item.id,
            item.title,
            item.id,
            <CarryOutOutlined />
          );
          const children = getChildren(item);
          treeItem.children = children;
          handleDataPush(treeItem);
        }
      });

      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setIsDataChange(false);
      setLoading(false);
    }
  };

  function getChildren(category) {
    if (!category.sub_category || category?.sub_category?.length === 0) {
      return [];
    }
    return category.sub_category.map((item) => {
      const treeItem = getTreeItem(
        item.id,
        item.title,
        item.id,
        <CarryOutOutlined />
      );
      const children = getChildren(item);
      treeItem.children = children;
      return treeItem;
    });
  }

  function getTreeItem(id, title, key, icon, children = []) {
    return {
      id,
      title,
      key,
      icon,
      children,
    };
  }

  const handleDataPush = (item) => {
    setTreeData((prev) => [...prev, item]);
  };

  useEffect(() => {
    fetchGetAllAreas().then((result) =>
      setAreasOptions(
        result?.response?.areas.map((item) => ({
          label: item?.title,
          value: item?.id,
        }))
      )
    );
    treeData.length > 0 &&
      fetchGetAllTests().then((result) => {
        console.log(result, "testler");
      });
  }, []);

  console.log(treeData, "rte");
  //eklenen etkinlik silme
  const handleRemoveActivity = (index, tests) => {
    const newtests = tests.splice(index, 1);
    setNewTest((prevValues) => ({
      ...prevValues,
      tests: newtests,
    }));
  };
  //eklenen etkinlik silme
  const handleAddTest = (tests, setFieldValue) => {
    setFieldValue("tests", [
      ...tests,
      {
        id: selectedTest?.id,
        title: selectedTest?.title,
        test_group_type: selectedTest?.test_group_type,
        test_type: selectedTest?.test_type,
      },
    ]);
    console.log(tests, "asdasdasd");
  };
  function AddedInnerTests({ tests }) {
    return (
      <div className="p-2 border rounded-xl">
        {/* <div className="flex justify-end w-full">
          <ButtonComponent
            onClick={() => handleAddTest()}
            fill
            text={"Test Ekle"}
          />
        </div> */}
        <p className="text-xl text-primary">Eklenen Testler</p>
        <div className="flex flex-col w-full gap-4">
          {tests?.map((item, index) => {
            return (
              <>
                <div className="p-2 border rounded-lg">
                  <p>
                    <span className="font-bold">Başlık:</span>{" "}
                    {item?.tests?.title ?? item?.title ?? <i>Başlık Yok</i>}
                  </p>
                  <p>
                    <span className="font-bold">Test Grubu:</span>{" "}
                    {item?.tests?.test_group_type ?? item?.test_group_type ?? (
                      <i>Test Grubu Yok</i>
                    )}
                  </p>
                  <p>
                    <span className="font-bold">Test Tipi:</span>{" "}
                    {item?.tests?.test_type ?? item?.test_type ?? (
                      <i>Test Tipi Yok</i>
                    )}
                  </p>
                  {/* <p>
                    <span className="font-bold">Açıklama:</span>{" "}
                    {item?.description}
                  </p> */}
                  <div className="flex justify-end w-full">
                    <ButtonComponent
                      customStyle={"h-[24px] text-xs"}
                      outline
                      text={"Çıkar"}
                      onClick={() => {
                        handleRemoveActivity(index, tests);
                      }}
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  }
  const { DirectoryTree } = Tree;
  console.log(resultData, "a resultData");
  console.log(filteredWeather, "filteredWeather");
  return (
    <div className="p-4 bg-white rounded-xl">
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      {resultData && !loading && (
        <>
          <PageTitle bg title={resultData?.activity_related_test[0] ? resultData?.activity_related_test[0].tests.title : "-"} />
          <Formik
            initialValues={{
              start_date: resultData?.start_date ?? "",
              end_date: resultData?.end_date ?? "",
              start_time: resultData?.start_time ?? "",
              end_time: resultData?.end_time ?? "",
              title: resultData?.title ?? "",
              status: filteredStatus ?? "",
              is_approved: resultData?.is_approved ?? "",
              activity_type: resultData?.activity_type ?? "",
              color: activityColors ?? "",
              team_category:
                {
                  label: resultData?.team_category?.team_title,
                  value: resultData?.team_category?.id,
                } ?? "",
              managers: selectedRowItemsManager?.selectedRowItems ?? "",
              players: selectedRowItemsPlayer?.selectedRowItems ?? "",
              //ekstra
              description: resultData?.test_activity_meta?.description ?? "",
              weather: filteredWeather ?? "",
              activity_area:
                {
                  label: resultData?.test_activity_meta?.activity_area?.title,
                  value: resultData?.test_activity_meta?.activity_area?.id,
                } ?? "",
              notes: resultData?.test_activity_meta?.notes ?? "",
              tests: resultData?.activity_related_test ?? "",
            }}
            onSubmit={async (values) => {
              values.status = values?.status?.value;
              values.color = values?.color?.value;
              values.activity_area =
                values.activity_area && values?.activity_area?.value;
              values.players = mergeSelectionsPlayers?.map(
                (item) => item.key ?? []
              );
              values.managers = mergeSelectionsManagment?.map(
                (item) => item.key ?? []
              );
              values.drills = values?.drills?.value;
              values.team_category = values?.team_category?.value;
              values.weather = values?.weather?.value;
              values.tests = values?.tests.map(
                (item) => item?.tests?.id ?? item?.id
              );
              console.log(values, "values");

              await fetchUpdateActivity(
                resultData?.id,
                values?.title,
                values?.activity_type,
                values?.start_date,
                values?.end_date,
                values?.status,
                values?.start_time,
                values?.end_time,
                // ozel alanlar
                values?.description,
                values?.weather,
                values?.activity_area,
                values?.managers,
                values?.players,
                values?.notes,
                values?.tests,
                values?.color,
                values?.team_category
              );
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              resetForm,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 md:grid-cols-2">
                    <DynamicInput
                      labelTitle={"Başlangıç Tarihi"}
                      containerStyle={"w-full"}
                      placeholder={"Faaliyet Başlığı"}
                      name={"start_date"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "start_date",
                          setFieldValue,
                          "datePicker"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"datePicker"}
                    />
                    <DynamicInput
                      labelTitle={"Başlangıç Bitiş"}
                      containerStyle={"w-full"}
                      placeholder={"Faaliyet Başlığı"}
                      name={"end_date"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "end_date",
                          setFieldValue,
                          "datePicker"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"datePicker"}
                    />
                    <DynamicInput
                      labelTitle={"Faaliyet Başlığı"}
                      containerStyle={"w-full"}
                      placeholder={"Faaliyet Başlığı"}
                      name={"title"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "title",
                          setFieldValue,
                          "input"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                    />
                    <DynamicInput
                      labelTitle={"Durum"}
                      containerStyle={"w-full"}
                      placeholder={"Durum"}
                      name={"status"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "status",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={[
                        { label: "Gerçekleşti", value: "gerceklesti" },
                        { label: "Planlandı", value: "planlandi" },
                        { label: "İptal Edildi", value: "iptal_edildi" },
                      ]}
                    />

                    <DynamicInput
                      labelTitle={"Başlangıç Saati"}
                      containerStyle={"w-full"}
                      name={"start_time"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "start_time",
                          setFieldValue,
                          "timePicker"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"timePicker"}
                    />
                    <DynamicInput
                      labelTitle={"Bitiş Saati"}
                      containerStyle={"w-full"}
                      name={"end_time"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "end_time",
                          setFieldValue,
                          "timePicker"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"timePicker"}
                    />
                    <DynamicInput
                      labelTitle={"Hava Durumu"}
                      containerStyle={"w-full"}
                      placeholder={"Durum"}
                      name={"weather"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "weather",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={[
                        { label: "Yağmurlu", value: "yagmurlu" },
                        { label: "Güneşli", value: "gunesli" },
                      ]}
                    />
                    <DynamicInput
                      labelTitle={"Faaliyet Açıklama"}
                      containerStyle={"w-full"}
                      placeholder={"Faaliyet Açıklama"}
                      name={"description"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "description",
                          setFieldValue,
                          "input"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                    />
                    <DynamicInput
                      labelTitle={"Faaliyet Notu"}
                      containerStyle={"w-full"}
                      placeholder={"Faaliyet Notu"}
                      name={"notes"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "notes",
                          setFieldValue,
                          "input"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                    />
                    <DynamicInput
                      labelTitle={"Faaliyet Alanı"}
                      containerStyle={"w-full"}
                      placeholder={"Durum"}
                      name={"activity_area"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "activity_area",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={areasOptions}
                    />
                    <DynamicInput
                      labelTitle={"Gösterim Rengi"}
                      containerStyle={"w-full"}
                      placeholder={"Renk seçiniz"}
                      name={"color"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "color",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={[
                        { label: "Kırmızı", value: "red" },
                        { label: "Mavi", value: "blue" },
                        { label: "Yeşil", value: "green" },
                      ]}
                    />
                    <DynamicInput
                      labelTitle={"Takım"}
                      containerStyle={"w-full"}
                      placeholder={"Takım Seçiniz"}
                      name={"team_category"}
                      value={values}
                      onChange={(e1, e2) => {
                        onChangeTeamSelect(e1);
                        handleCustomChange(
                          e1,
                          e2,
                          "team_category",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={teamCategoryOptions ?? []}
                    />

                    <div className="">
                      {/* players */}
                      <p className="pb-2 text-lg">Oyuncu Seç</p>
                      <DataTable
                        selection={{ rowSelectionList, setRowSelectionList }}
                        loading={loading}
                        columns={[
                          { title: "Ad Soyad", dataIndex: "name" },
                          { title: "TC", dataIndex: "tckn" },
                        ]}
                        data={selectTeamPlayers ?? userDataTableData ?? []}
                      />
                    </div>

                    <div className="">
                      <p className="pb-2 text-lg">Seçilen Oyuncular</p>
                      <DataTable
                        loading={loading}
                        columns={[
                          { title: "Ad Soyad", dataIndex: "name" },
                          { title: "TC", dataIndex: "tckn" },
                        ]}
                        data={mergeSelectionsManagment ?? []}
                      />
                    </div>
                    <div className="">
                      {/* players */}
                      <p className="pb-2 text-lg">Personel Seç</p>
                      <DataTable
                        selection={{
                          rowSelectionList: rowSelectionListManager,
                          setRowSelectionList: setRowSelectionListManager,
                        }}
                        loading={loading}
                        columns={[
                          { title: "Ad Soyad", dataIndex: "name" },
                          { title: "TC", dataIndex: "tckn" },
                        ]}
                        data={managerDataTableData ?? []}
                      />
                    </div>
                    <div className="">
                      <p className="pb-2 text-lg">Seçilen Personeller</p>
                      <DataTable
                        loading={loading}
                        columns={[
                          { title: "Ad Soyad", dataIndex: "name" },
                          { title: "TC", dataIndex: "tckn" },
                        ]}
                        data={mergeSelectionsPlayers ?? []}
                      />
                    </div>
                  </div>
                  <div className="grid w-full grid-cols-2 gap-2 p-2 border divide-x rounded-xl">
                    <div className="w-full">
                      {treeData.length > 0 ? (
                        <>
                          <DirectoryTree
                            className="relative z-0"
                            showLine={false}
                            showIcon={false}
                            defaultExpandedKeys={0}
                            // defaultExpandAll
                            onSelect={onSelect}
                            treeData={treeData}
                          />
                        </>
                      ) : (
                        <p>Yükleniyor...</p>
                      )}
                      <div className="w-full pl-2">
                        <div className="w-full ">
                          <div className="flex justify-end">
                            <ButtonComponent
                              onClick={() => {
                                console.log("ooppp", values?.tests);
                                handleAddTest(values?.tests, setFieldValue);
                              }}
                              fill
                              text={"Test Ekle"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {<AddedInnerTests tests={values?.tests} />}
                  </div>
                  <div className="flex justify-end mt-8">
                    <ButtonComponent fill submit text={"Kaydet"} />
                  </div>
                </form>
              </>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}

export default EditTest;
