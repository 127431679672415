import { BiUser } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { FaRegAddressBook } from "react-icons/fa";
export const UserFormInputsData = [
    {
      icon: <BiUser className="text-2xl" />,
      title: "Kişisel Bilgileri",
      inputs: [
        {
          labelTitle: "Adı",
          id: "firstname",
          name: "firstname",
          type: "text",
          placeholder: "Adı",
          required: true,
          leftIcon: <BiUser className="w-full h-full" />,
        },
        {
          labelTitle: "Soyadı",
          id: "surname",
          name: "surname",
          type: "text",
          placeholder: "Soyadı",
          required: true,
          leftIcon: <BiUser className="w-full h-full" />,
        },
        {
          labelTitle: "Takımı",
          id: "team",
          name: "team",
          type: "text",
          placeholder: "Soyadı",
          required: true,
          leftIcon: <BiUser className="w-full h-full" />,
        },
        {
          labelTitle: "TC. Kimlik No",
          id: "tckn",
          name: "tckn",
          type: "text",
          placeholder: "Örn 12345678900",
          required: true,
          maxLength: 11,
          leftIcon: <BiUser className="w-full h-full" />,
          onlyNumber: true,
        },
        {
          labelTitle: "Mevki",
          id: "position",
          name: "position",
          type: "text",
          placeholder: "Mevki",
          required: true,
          leftIcon: <BiUser className="w-full h-full" />,
        },
        {
          labelTitle: "Doğum Tarihi",
          id: "date_of_birth",
          name: "date_of_birth",
          dateInput: true,
onChangeType: "event",
          placeholder: "Doğum Tarihi",
          required: true,
          leftIcon: <BiUser className="w-full h-full" />,
        },
        {
          labelTitle: "Telefon Numarası",
          id: "phoneNumber",
          name: "phoneNumber",
          type: "text",
          placeholder: "(5xx) - - -   - -   - -",
          required: true,
          phoneNumber: true,
        },
      ],
    },
    {
      icon: <FaRegAddressBook className="text-2xl" />,
      title: "Adres Bilgileri",
      inputs: [
        {
          labelTitle: "İl",
          id: "province",
          name: "province",
          type: "text",
          placeholder: "",
          required: true,
          select: true,
          options: [
            { value: "izmir", label: "İzmir" },
            { value: "istanbul", label: "İstanbul" },
            { value: "ankara", label: "Ankara" },
          ],
          leftIcon: <FaRegAddressBook className="w-full h-full" />,
        },
        {
          labelTitle: "İlçe",
          id: "district",
          name: "district",
          type: "text",
          placeholder: "",
          required: true,
          select: true,
          options: [
            { value: "bornova", label: "Bornova" },
            { value: "uskudar", label: "Üsküdar" },
            { value: "mamak", label: "Mamak" },
          ],
          leftIcon: <FaRegAddressBook className="w-full h-full" />,
        },
        {
          labelTitle: "Mahalle",
          id: "neighbourhood",
          name: "neighbourhood",
          type: "text",
          placeholder: "",
          required: true,
          select: true,
          options: [
            { value: "mahalle1", label: "Mahalle1" },
            { value: "mahalle2", label: "Mahalle2" },
            { value: "mahalle3", label: "Mahalle3" },
          ],
          leftIcon: <FaRegAddressBook className="w-full h-full" />,
        },
      ],
    },
    {
      icon: <FiUsers className="text-2xl" />,
      title: "Aile Bilgileri",
      inputs: [
        {
          labelTitle: "Baba Adı",
          id: "father_firstname",
          name: "father_firstname",
          type: "text",
          placeholder: "Baba Adı",
          required: true,
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Baba Soyadı",
          id: "father_surname",
          name: "father_surname",
          type: "text",
          placeholder: "Baba Soyadı",
          required: true,
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Baba Eğitim Durumu",
          id: "neighbourhood",
          name: "neighbourhood",
          type: "text",
          placeholder: "",
          required: true,
          select: true,
          options: [
            { value: "ilkokul", label: "İlkokul" },
            { value: "ortaokul", label: "Ortaokul" },
            { value: "lise", label: "Lise" },
            { value: "on_lisans", label: "Ön lisans" },
            { value: "yuksek_lisans", label: "Yüksek Lisans" },
          ],
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Baba Doğum Tarihi",
          id: "father_date_of_birth",
          name: "father_date_of_birth",
          dateInput: true,
onChangeType: "event",
          placeholder: "Doğum Tarihi",
          required: true,
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Baba Doğum Yeri",
          id: "",
          name: "",
          type: "text",
          placeholder: "",
          required: true,
          select: true,
          options: [
            { value: "izmir", label: "İzmir" },
            { value: "istanbul", label: "İstanbul" },
            { value: "ankara", label: "Ankara" },
          ],
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Anne Adı",
          id: "",
          name: "",
          type: "text",
          placeholder: "Anne Adı",
          required: true,
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Anne Soyadı",
          id: "",
          name: "",
          type: "text",
          placeholder: "Anne Soyadı",
          required: true,
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Anne Eğitim Durumu",
          id: "",
          name: "",
          type: "text",
          placeholder: "",
          required: true,
          select: true,
          options: [
            { value: "ilkokul", label: "İlkokul" },
            { value: "ortaokul", label: "Ortaokul" },
            { value: "lise", label: "Lise" },
            { value: "on_lisans", label: "Ön lisans" },
            { value: "yuksek_lisans", label: "Yüksek Lisans" },
          ],
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Anne Doğum Tarihi",
          id: "",
          name: "",
          dateInput: true,
onChangeType: "event",
          placeholder: "Doğum Tarihi",
          required: true,
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Anne Doğum Yeri",
          id: "",
          name: "",
          type: "text",
          placeholder: "",
          required: true,
          select: true,
          options: [
            { value: "izmir", label: "İzmir" },
            { value: "istanbul", label: "İstanbul" },
            { value: "ankara", label: "Ankara" },
          ],
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Anne Baba Durumu",
          id: "",
          name: "",
          type: "text",
          placeholder: "",
          required: true,
          select: true,
          options: [
            { value: "izmir", label: "Anne, baba birlikte" },
            { value: "istanbul", label: "Anne, baba birlikte" },
            { value: "ankara", label: "Anne, baba birlikte" },
          ],
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Ev Durumu",
          id: "",
          name: "",
          type: "text",
          placeholder: "",
          required: true,
          select: true,
          options: [
            { value: "izmir", label: "Kendi Evleri	" },
            { value: "istanbul", label: "Kendi Evleri	" },
            { value: "ankara", label: "Kendi Evleri	" },
          ],
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Aylık Gelir (₺)",
          id: "monthly_income",
          name: "monthly_income",
          type: "text",
          placeholder: "Aylık Gelir",
          required: true,
          leftIcon: <FiUsers className="w-full h-full" />,
          onlyNumber: true,
        },
        {
          labelTitle: "Kardeş Sayısı",
          id: "number_of_siblings",
          name: "number_of_siblings",
          type: "text",
          placeholder: "Kardeş Sayısı",
          required: true,
          leftIcon: <FiUsers className="w-full h-full" />,
          onlyNumber: true,
        },
        {
          labelTitle: "Lisans No",
          id: "licance_no",
          name: "licance_no",
          type: "text",
          placeholder: "Lisans No",
          required: true,
          leftIcon: <FiUsers className="w-full h-full" />,
          onlyNumber: true,
        },
        {
          labelTitle: "Altınordu Kulübüne Başlangıç Tarihi",
          id: "",
          name: "",
          dateInput: true,
onChangeType: "event",
          placeholder: "Doğum Tarihi",
          required: true,
          leftIcon: <BiUser className="w-full h-full" />,
        },
        {
          labelTitle: "Geldiği İl",
          id: "",
          name: "",
          type: "text",
          placeholder: "",
          required: true,
          select: true,
          options: [
            { value: "izmir", label: "İzmir" },
            { value: "istanbul", label: "İstanbul" },
            { value: "ankara", label: "Ankara" },
          ],
          leftIcon: <FiUsers className="w-full h-full" />,
        },
        {
          labelTitle: "Geldiği İlçe",
          id: "district",
          name: "district",
          type: "text",
          placeholder: "",
          required: true,
          select: true,
          options: [
            { value: "bornova", label: "Bornova" },
            { value: "uskudar", label: "Üsküdar" },
            { value: "mamak", label: "Mamak" },
          ],
          leftIcon: <FaRegAddressBook className="w-full h-full" />,
        },
        {
          labelTitle: "Anne Boy",
          id: "mother_height",
          name: "mother_height",
          type: "text",
          placeholder: "Aylık Gelir",
          required: true,
          leftIcon: <FiUsers className="w-full h-full" />,
          onlyNumber: true,
        },
        {
          labelTitle: "Baba Boy",
          id: "father_height",
          name: "father_height",
          type: "text",
          placeholder: "Aylık Gelir",
          required: true,
          leftIcon: <FiUsers className="w-full h-full" />,
          onlyNumber: true,
        },
      ],
    },
  ];