import React from "react";
import ReactDOM from "react-dom/client";
import { SidebarContextProvider } from "./context";

import "./index.css";

import { store } from "./services/redux/ConfigureStore";
import { Provider } from "react-redux";

import App from "./App";
import { ConfigProvider } from "antd";

import dayjs from "dayjs";
import "dayjs/locale/tr"; // Türkçe dil dosyasını ekleyin
import locale from "antd/lib/locale/tr_TR"; // Türkçe dil dosyasını ekleyin

dayjs.locale("tr"); // dayjs'ye Türkçe dil ayarını yapın

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <SidebarContextProvider>
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: "#DC2026",
          },
        }}
      >
        <App />
      </ConfigProvider>
    </SidebarContextProvider>
  </Provider>
);
