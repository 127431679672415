import React, { useEffect, useState } from "react";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { SettingOutlined } from "@ant-design/icons";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import CreateTeamModal, {
  CreateTeamForm
} from "../../containers/modals/CreateTeamModal";
import { CreateSeasonsForm } from "../../containers/modals/CreateSeasonsModal";
import {
  getAllTeamCategory,
  getAllUser,
  getLeagues,
  getSeasons,
  listTeams
} from "../../services/axios";
import { PulseLoader } from "react-spinners";

function TeamDefinitions() {
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDataChange, setIsDataChange] = useState(true);
  const [loading, setLoading] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [listTeamsResultData, setListTeamsResultData] = useState([]);

  const [data, setData] = useState([]);

  const [teamCategoryOptions, setTeamCategoryOptions] = useState([]);
  const [leagueOptions, setLeagueOptions] = useState([]);
  const [allPlayer, setAllPlayer] = useState([]);
  const [allFootballerLoading, setAllFootballerLoading] = useState(false);
  const [seasonOptions, setSeasonOptions] = useState([]);


  const fetchListTeams = async () => {
    setIsDataChange(true);
    setLoading(true);
    setMenuData([]);
    try {
      const result = await listTeams();
      console.log("bakbi", result.data)
      setListTeamsResultData(result.data.teams);
      // console.log("teams", result.data);
      result.data.teams.forEach((item, index) => {
        handleDataPush(item.team_title, index);
      });

      setLoading(false);
      setIsDataChange(false);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const handleDataPush = (label, value) => {
    const newItem = getMenuItem(label, value, <SettingOutlined />, null);
    setMenuData((prev) => [...prev, newItem]);
  };

  const fetchGetSeasons = async () => {
    try {
      const result = await getSeasons();
      let allSeasonOptions = result.data.seasons.map((item) => ({
        label: item.season_title,
        value: item.id
      }));
      setSeasonOptions(allSeasonOptions);
    } catch (error) {
      console.log(error, "hata");
    }
  };
useEffect(() => {
  fetchGetSeasons()
},[])
  function SelectedItemForm() {
    // console.log(allPlayer, "allPlayer");
    return listTeamsResultData
      ?.filter((filterItem, filterIndex) => filterIndex == selectedMenuIndex)
      ?.map((item, index) => {
        setData(item);
        console.log(item)
        return (
          <React.Fragment key={index}>
            <CreateTeamForm
              isOpen={isModalOpen}
              setOpen={setModalOpen}
              setIsDataChange={setIsDataChange}
              isDataChange={isDataChange}
              updateSelectedValuesProp={item}
              updateId={item.id}
              teamCategoryOptions={teamCategoryOptions}
              leagueOptions={leagueOptions}
              allPlayer={allPlayer}
              allPlayerLoading={allFootballerLoading}
              seasonOptions={seasonOptions}
            />
          </React.Fragment>
        );
      });
  }

  const fetchGetTeamCategories = async () => {
    try {
      const result = await getAllTeamCategory();
      result?.response?.categories.map((item) => {
        setTeamCategoryOptions((prevState) => [
          ...prevState,
          {
            label: item.category_title,
            value: item.id
          }
        ]);
      });
    } catch (error) {
      console.log(error, "hata");
    }
  };

  const fetchGetLeagues = async (isCurrent) => {
    try {
      const result = await getLeagues(isCurrent);
      setLeagueOptions([]);
      result?.data?.data.map((item) => {
        if (item.isCurrent === 1) {
          setLeagueOptions((prevState) => [
            ...prevState,
            {
              label: item.name,
              value: item.id
            }
          ]);
        }
      });
    } catch (error) {
      console.log(error, "hata");
    }
  };

  const fetchGetAllFootballer = async () => {
     setAllFootballerLoading(true);
    try {
      const result = await getAllUser("footballer", "");
      setAllPlayer(result.data.users);
      setAllFootballerLoading(false);
    } catch (error) { 
      console.log("hata", error);
    }
  };

  useEffect(() => {
    if (isDataChange) {
      fetchListTeams();
      fetchGetLeagues();
    }
  }, [isDataChange]);

  useEffect(() => {
    fetchGetAllFootballer();
    fetchGetTeamCategories();
  }, []);

  return (
    <div>
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      {!loading && (
        <div className="flex">
          <div className="flex flex-col">
            <DrawerMenuAntd
              items={menuData}
              selectedKey={selectedMenuIndex}
              setSelectedKey={setSelectedMenuIndex}
            />
            <ButtonComponent
              customStyle={"mt-4"}
              text={"Yeni Takım Ekle"}
              outline
              onClick={() => {
                setModalOpen(true);
              }}
            />
            <CreateTeamModal
              isOpen={isModalOpen}
              setOpen={setModalOpen}
              setIsDataChange={setIsDataChange}
              teamCategoryOptions={teamCategoryOptions}
              leagueOptions={leagueOptions}
              seasonOptions={seasonOptions}
            />
          </div>
          <div className="w-full px-4">
            <SelectedItemForm />
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamDefinitions;
