import { BiUser } from "react-icons/bi";
export const TeamFormInputsData = [
    {
      icon: <BiUser className="text-2xl" />,
      title: "Takım Bilgileri",
      inputs: [
        {
          labelTitle: "Takım Kodu",
          id: "teamsCode",
          name: "teamsCode",
          type: "text",
          placeholder: "Takım Kodu",
          required: true,
          leftIcon: <BiUser className="w-full h-full" />,
        },
        {
          labelTitle: "Takım Adı",
          id: "teamsName",
          name: "teamsName",
          type: "text",
          placeholder: "Takım Adı",
          required: true,
          leftIcon: <BiUser className="w-full h-full" />,
        },
        {
          labelTitle: "Kategori",
          id: "teamsCategory",
          name: "teamsCategory",
          type: "text",
          placeholder: "Kategori",
          required: true,
          leftIcon: <BiUser className="w-full h-full" />,
        },
        
      ],
    },
  ];