import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  players: [],
  filteredPlayers: [],
  loading: false,
  message: null,
  error: {
    code: null,
    message: null,
  },
};

export const GetPlayers = createAsyncThunk(
  "players/GetPlayers",
  async (arg, thunkAPI) => {
    return new Promise((resolve, reject) => {
      const dateStr = "Sun Jun 11 2023 00:00:00 GMT+0300 (GMT+03:00)";
      const birthDate = new Date(dateStr);

      setTimeout(() => {
        let data = [];
        for (let i = 1; i <= 250; i++) {
          data.push({
            key: i,
            name: "Cengiz ÜNDER",
            tckn: `${i}2123123412`,
            team: "ALFA U18/19",
            position: `${i}2`,
            birthDate: birthDate,
            phoneNumber: "05212521144",
            town: "Karşıyaka",
            district: "Bostanlı",
            city: "İzmir",
            trainingAge: `${i}1`,
            dadName: "John Brown",
            dadJobName: "Memur",
            dadEducation: "University",
            dadBirthDate: birthDate,
            dadBirthPlace: "İzmir",
            momName: "Deniz",
            momJobName: "Öğretmen",
            momEducation: "University",
            momBirthDate: birthDate,
            momBirthPlace: "İzmir",
            momDadWith: "Anne, baba birlikte",
            homeStatus: "Kendi Evleri",
            totalSalary: "9000₺",
            brotherCount: "2",
            licenseNo: `${i}75127`,
            ao: birthDate,
            cityOfOrigin: "Ankara",
            cityOfTown: "Çankaya",
            momHeight: "1.65",
            dadHeight: "1.75",
          });
        }
        resolve(data);
      }, 750);
    });
  }
);

export const PlayerSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    FilterPlayers: (state, action) => {
      const filterInputValue = action.payload;
      if (filterInputValue) {
        state.loading = true;
        const filteredData = state.players.filter(
          (item) =>
            item.name.toLowerCase().includes(filterInputValue.toLowerCase()) ||
            item.tckn.toLowerCase().includes(filterInputValue.toLowerCase()) ||
            item.position.toLowerCase().includes(filterInputValue.toLowerCase())
        );
        state.filteredPlayers = filteredData;
        state.loading = false;
      } else {
        state.filteredPlayers = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetPlayers.pending, (state, action) => {
      state.loading = true;
      state.message = null;
      state.error = {
        code: null,
        message: null,
      };
    });
    builder.addCase(GetPlayers.fulfilled, (state, action) => {
      state.players = action.payload;
      state.loading = false;
    });
    builder.addCase(GetPlayers.rejected, (state, action) => {
      state.loading = false;
      state.message = "Oyuncu Verileri Getirme Reddedildi!!";
      state.error = {
        code: 500,
        message: "Get Players Rejected!!",
      };
    });
  },
});

export const { FilterPlayers } = PlayerSlice.actions;

export default PlayerSlice.reducer;
