import React, { useState } from "react";
import { MailOutlined } from "@ant-design/icons";
import { ConfigProvider, Divider, Menu, Switch } from "antd";

export function getMenuItem(label, key, icon, children) {
  return {
    label,
    key,
    icon,
    children,
  };
}

function DrawerMenuAntd({
  vertical,
  inline,
  horizontal,
  light,
  dark,
  items,
  selectedKey,
  setSelectedKey,
}) {
  const [mode, setMode] = useState("inline");
  const [theme, setTheme] = useState("light");
  const changeMode = (value) => {
    setMode(value ? "vertical" : "inline");
  };
  const changeTheme = (value) => {
    setTheme(value ? "dark" : "light");
  };
  function handleMenuClick({ key }) {  
    setSelectedKey(key);
  }
  return (
    <>
      {/* <Switch onChange={changeMode} /> Change Mode
      <Divider type="vertical" />
      <Switch onChange={changeTheme} /> Change Style
      <br />
      <br /> */}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#DC2026",
          },
        }}
      >
        <Menu
          onClick={handleMenuClick}
          selectedKeys={[selectedKey]}
          style={{
            width: horizontal ? "100%" : "300px",
          }}
          //  defaultSelectedKeys={["1"]}
          // defaultOpenKeys={["menu-1"]}
          mode={
            vertical
              ? "vertical"
              : inline
                ? "inline"
                : horizontal
                  ? "horizontal"
                  : "inline"
          }
          theme={dark ? "dark" : "light"}
          items={items}
        />
      </ConfigProvider>
    </>
  );
}

export default DrawerMenuAntd;
