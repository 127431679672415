import React, {useState} from "react";
import {BsChevronRight} from "react-icons/bs";
import {FiSearch} from "react-icons/fi";

const SearchBox = () => {
    const [searchValue, setSearchValue] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        if (searchValue?.length > 0) {
            alert(`Aranan Kelime: ${searchValue}`);
            setSearchValue("");
        } else {
            alert("Boş Bırakılamaz!");
        }
    };

    return (
        <div className="relative flex items-center justify-center px-2">
              <span className="flex items-center mr-2 text-lg">
                <FiSearch/>
              </span>
            <form onSubmit={handleSubmit} className="flex">
                <input
                    className="p-2 outline-none w-full"
                    type="text"
                    placeholder="Üye veya Modül Ara..."
                    value={searchValue}
                    onChange={({target}) => setSearchValue(target.value)}
                />
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="rounded-full p-3 border flex items-center justify-center hover:bg-gray-100 transition-all"
                >
                    <BsChevronRight/>
                </button>
            </form>
        </div>
    );
};

export default SearchBox;
