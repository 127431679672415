import React from "react";

const RoundedIconButton = ({ icon, count, onClick, type }) => {
  return (
    <button className="relative flex items-center justify-center border p-3 rounded-lg hover:bg-gray-100 transition-all" onClick={onClick} type={type}>
      {count && (
        <span className="absolute -top-2 -right-1 w-5 h-5 flex items-center justify-center bg-primary rounded-full text-white text-xs p-2">
          {count}
        </span>
      )}
      {icon}
    </button>
  );
};

export default RoundedIconButton;
