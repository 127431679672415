import React from "react";
import { FiEdit, FiTrash } from "react-icons/fi";
import { CgMoreR } from "react-icons/cg";
import { Link } from "react-router-dom";
import AlertDialogDelete from "../../components/alertDialogDelete";
import Swal from "sweetalert2";
import { deleteActivity } from '../../services/axios'
function ActionButtonsDataTable({
  toUpdate,
  toDetail,
  id,
  deleteDataLabel,
  updateButton,
  detailButton,
  deleteButton,
  // fetchFunction,
}) {
  const handleDelete = async (label) => {
    try {
      Swal.fire({
        title: `${label} silinecek emin misiniz?`,
        text: "Bu işlemi geri alamazsınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          // fetchFunction(id);
          deleteActivity(id)
          // Silme işlemini gerçekleştir
          Swal.fire(
            `${label} Silindi!`,
            "Seçtiğiniz öğe başarıyla silindi.",
            "success"
          );
        }
      });
    } catch (error) {
      console(error, "hata");
    }
  };

  return (
    <div className="flex items-center">
      {updateButton && (
        <a
          href={`${toUpdate + id}`}
          className="flex items-center p-2 text-gray-500 transition-all rounded-lg cursor-pointer hover:bg-gray-200"
        >
          <FiEdit size={21} />
          <span className="hidden ml-2 text-xs text-gray-500 md:block">
            Güncelle
          </span>
        </a>
      )}
      {detailButton && (
        <a
          href={`${toDetail + id}`}
          className="flex items-center p-2 text-gray-500 transition-all rounded-lg cursor-pointer hover:bg-gray-200"
        >
          <CgMoreR size={21} />
          <span className="hidden ml-2 text-xs text-gray-500 md:block">
            İncele
          </span>
        </a>
      )}

      {/* Kullanıcı silme butonu */}

      {deleteButton && (
        <button
          onClick={() => handleDelete(deleteDataLabel)}
          className="flex p-2 text-red-500 transition-all rounded-lg cursor-pointer hover:bg-red-100"
        >
          <FiTrash size={21} />
          <span className="hidden ml-2 text-sm text-red-500 md:block">Sil</span>
        </button>
      )}
    </div>
  );
}

export default ActionButtonsDataTable;
