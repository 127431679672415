import React, { useEffect, useState } from "react";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import { Form, Formik, useFormik } from "formik";
import { handleCustomChange } from "../../utils/handleCustomChange";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import DynamicInput from "../../components/inputs/dynamicInput";
import { createLeague, deleteLeague, updateLeague } from "../../services/axios";
import Swal from "sweetalert2";
import { PulseLoader } from "react-spinners";

export function CreateLeagueForm({
  setOpen,
  setIsDataChange,
  updateSelectedValuesProp,
  updateId,
  selectedTabId,
  seasonOptions
}) {
  const [loading, setLoading] = useState(false);

  const fetchDeleteLeague = async () => {
    try {
      const result = await deleteLeague(updateSelectedValuesProp?.id);
      setIsDataChange(true);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };
  const handleDeleteLeague = async (updateSelectedValuesProp) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `Silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal"
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteLeague(updateSelectedValuesProp?.id);
        Swal.fire("Silindi!", `Silindi.`, "success");
      }
    });
  };

  const {
    errors,
    values,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      name: updateSelectedValuesProp?.name ?? "",
      seasonId: updateSelectedValuesProp
        ? {
            label: updateSelectedValuesProp?.season?.season_title,
            value: updateSelectedValuesProp?.season?.id
          }
        : "",
      isCurrent: updateSelectedValuesProp
        ? updateSelectedValuesProp?.isCurrent
        : selectedTabId == 0
        ? true
        : false
    },
    onSubmit: async (values) => {
      // console.log(values, "values");
      setLoading(true);
      try {
        let result = null;
        values.seasonId = values.seasonId.value;
        if (updateId) {
          result = await updateLeague(
            updateId,
            values.name,
            values.isCurrent,
            values.seasonId
          );
        } else {
          result = await createLeague(
            values.name,
            values.isCurrent,
            values.seasonId
          );
          setIsDataChange(true);
        }

        setLoading(false);
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result?.message ?? "Ekleme Başarılı!",
            showConfirmButton: false,
            timer: 1500
          });

          setLoading(false);
          setIsDataChange(true);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: result?.message ?? "Ekleme Başarısız!",
            showConfirmButton: false,
            timer: 1000
          });
        }
      } catch (error) {
        console.log(error, "error");
      }
    }
  });
  // console.log(values, "valuesssssss");

  return (
    <>
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      {!loading && (
        <Formik>
          <form onSubmit={handleSubmit}>
            <div className="grid w-full gap-4 pt-4 pb-4">
              <DynamicInput
                labelTitle={"Lig Adı"}
                containerStyle={"w-full"}
                placeholder={"Lig Adı "}
                name={"name"}
                value={values}
                onChange={(e1, e2) => {
                  handleCustomChange(e1, e2, "name", setFieldValue, "input");
                }}
                error={errors}
                isTouched={touched}
                onBlur={handleBlur}
                required={true}
              />
              <DynamicInput
                labelTitle={"Sezon Seçiniz"}
                containerStyle={"w-full"}
                placeholder={"Seçiniz"}
                name={"seasonId"}
                value={values}
                onChange={(e1, e2) => {
                  handleCustomChange(
                    e1,
                    e2,
                    "seasonId",
                    setFieldValue,
                    "select"
                  );
                }}
                error={errors}
                isTouched={touched}
                onBlur={handleBlur}
                component_type={"select"}
                customOptions={seasonOptions}
                required={true}
              />
              {/* <DynamicInput
                labelTitle={"Aktif Mi?"}
                containerStyle={"w-full"}
                placeholder={"Aktif Mi?"}
                name={"isCurrent"}
                value={values}
                onChange={(e1, e2) => {
                  handleCustomChange(
                    e1,
                    e2,
                    "isCurrent",
                    setFieldValue,
                    "checkbox"
                  );
                }}
                error={errors}
                isTouched={touched}
                onBlur={handleBlur}
                component_type={"checkbox"}
                required={true}
              /> */}
            </div>

            <div className="flex justify-end">
              {/* lig silme kalktı */}
              {/* {updateSelectedValuesProp ? (
                <ButtonComponent
                  customStyle={"mr-5"}
                  text={"Ligi Sil"}
                  outline
                  onClick={() => handleDeleteLeague()}
                />
              ) : (
                <ButtonComponent
                  customStyle={"mr-5"}
                  onClick={() => {
                    setOpen(false);
                    resetForm();
                  }}
                  outline
                  text={"Vazgeç"}
                />
              )} */}

              <ButtonComponent
                submit
                fill
                text={
                  updateSelectedValuesProp ? "Düzenlemeleri Kaydet" : "Kaydet"
                }
              />
            </div>
          </form>
        </Formik>
      )}
    </>
  );
}

function CreateLeagueModal({
  isOpen,
  setOpen,
  setIsDataChange,
  selectedTabId,
  seasonOptions
}) {
  return (
    <div>
      <CustomModal
        //faaliyet adresi ekleme
        width={677}
        isOpen={isOpen}
        setOpen={setOpen}
        footer={null}
      >
        <CustomModalHeader
          title={"Yeni Lig Ekleyin"}
          description={"Aşağıdan yeni lig ekleyin."}
        />
        <CreateLeagueForm
          isOpen={isOpen}
          setOpen={setOpen}
          setIsDataChange={setIsDataChange}
          selectedTabId={selectedTabId}
          seasonOptions={seasonOptions}
        />
      </CustomModal>
    </div>
  );
}

export default CreateLeagueModal;
