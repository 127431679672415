import React from "react";
import { Link, Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    <>
      <div className="w-full min-h-screen bg-center bg-cover bg-login-image ">
        <div className="relative flex flex-col items-center justify-center w-full min-h-screen p-4 zoom">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
