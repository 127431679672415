import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/pageTitle";
import DrawerMenuAntd, {
  getMenuItem,
} from "../../../components/drawerMenuAntd";
import { SettingOutlined } from "@ant-design/icons";
import { addUserMetasz, getDynamicForm, getUser } from "../../../services/axios";
import InputComponent from "../../../components/inputs";
import { Formik, useFormik } from "formik";
import { handleCustomChange } from "../../../utils/handleCustomChange";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import DynamicInput from "../../../components/inputs/dynamicInput";
import { useParams } from "react-router-dom";

export const DynamicFormMenuData = [
  getMenuItem("Oyuncular Formu", "menu-1", <SettingOutlined />, null),
  getMenuItem("Faaliyetler Formu", "menu-2", <SettingOutlined />, null),
  getMenuItem("Maçlar Formu", "menu-3", <SettingOutlined />, null),
  getMenuItem("Saha Tanımları ", "menu-4", <SettingOutlined />, null),
  getMenuItem(
    "Test ve Test Faaliyetleri ",
    "menu-5",
    <SettingOutlined />,
    null
  ),
];

function AddPlayerDynamicForm({ formName }) {
  const { id } = useParams();
  const [allFormInputs, setAllFormInputs] = useState([]);
  const [formId, setFormId] = useState("");
  const [userId, setUserId] = useState(id);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isDataChange, setDataChange] = useState(true);

  useEffect(() => {
    console.log("denemelr", allFormInputs);
  }, [allFormInputs])


  const fetchAddUserMetas = async (values) => {
    console.log("fetch çalıştı");
    setLoading(true);
    try {
      const result = await addUserMetasz(userId, formId, values);
      console.log(values);
      console.log(result.data, "result");
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    } catch (error) {
      console.error("Hata oluştu:", error);
    } finally {
      setLoading(false);
      setDataChange(true);
    }
  };

  const fetchGetDynamicForm = async () => {
    setLoading(true);
    try {
      let result;
      if (formName) {
        result = await getDynamicForm(formName);
        console.log(result, "fofofofoof");
      } else {
        result = await getDynamicForm("add-player-personal-info");
      }
      setAllFormInputs(
        result?.response.form_elements.map((item) => item?.options_json),
      );
      console.log("bakbi", result?.response.form.name)
      setFormId(result?.response.form.name);
    } catch (error) {
      console.error("Hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchGetUser = async (userID) => {
    setLoading(true);
    try {
      const result = await getUser(userID);
      const allUserMetas = result.data.user.user_metas;
      console.log("maarrem", result.data.user)
      console.log(allUserMetas, "allUserMetas");
      const initialMetaValues = {};
      console.log("bakqle", allFormInputs)
      allFormInputs.forEach((itemMap) => {
        const userMeta = allUserMetas.find(
          (item) => item.meta_key === itemMap.name
        );
        console.log(userMeta, "userMetaadsasad");
        if (userMeta) {
          console.log("BAKBI", userMeta)
          if (itemMap?.component_type === "select") {
            initialMetaValues[itemMap.name] = {
              label: userMeta?.meta_label,
              value: userMeta?.meta_value,
            };
          } else {
            initialMetaValues[itemMap.name] = userMeta?.meta_label;
            console.log("initial", initialMetaValues)
          }
        }
      });
      setInitialValues(initialMetaValues);
      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
      setDataChange(false);
    }
  };

  useEffect(() => {
    fetchGetDynamicForm().then(() => fetchGetUser(id));
  }, [isDataChange]);

  console.log(initialValues, "initialValues");

  console.log(isDataChange, "isDataChange");
  console.log(
    allFormInputs.map((item, index) => item?.component_type == "select"),
    "allFormInputs"
  );
  return (
    <div className="w-full">
      {loading ? (
        <>
          <div className="flex">
            <PulseLoader color="red" />
            <p>Yükleniyor...</p>
          </div>
        </>
      ) : null}
      {!loading && initialValues && allFormInputs?.length > 0 ? (
        <Formik
          initialValues={initialValues ?? null}
          onSubmit={async (values) => {
            const transformedValues = {};

            // Tüm form alanlarını gezmek için for döngüsü kullanın
            for (const key in values) {
              if (Object.hasOwnProperty.call(values, key)) {
                const value = values[key];

                // Eğer değer bir nesne ise "value" özelliğini alın, değilse olduğu gibi bırakın
                transformedValues[key] =
                  typeof value === "object" && value !== null
                    ? value.value
                    : value;
              }
            }

            // Dönüştürülmüş değerleri kullanın
            console.log(transformedValues, "transformedValues");

            // ... Diğer işlemler
            await fetchAddUserMetas(transformedValues);
          }}
        >
          {({
            errors,
            values,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
          }) => (
            <form
              encType="multipart/form-data"
              method=""
              onSubmit={handleSubmit}
              className="w-full"
            >
              <div className="grid w-full grid-cols-2 gap-4">
                {allFormInputs.map((item, index) => {
                  console.log(item?.options, "item.options");
                  if (item !== null) {
                    return (
                      <div key={index} className="w-full">
                        <DynamicInput
                          type={item?.type}
                          labelTitle={item?.label}
                          containerStyle={"w-full"}
                          placeholder={item?.placeholder}
                          name={item?.name}
                          value={values}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          onChange={(e1, e2) => {
                            handleCustomChange(
                              e1,
                              e2,
                              item.name,
                              setFieldValue,
                              item?.component_type
                            );
                          }}
                          required={item?.required}
                          component_type={item?.component_type}
                          options={item?.options}
                        />
                      </div>
                    );
                  }
                })}

                {/*  */}
              </div>
              <div className="flex items-center justify-end gap-4 mt-8">
                {/* <ButtonComponent outline text="Tümünü Sil" /> */}
                <ButtonComponent fill text="Kaydet" submit />
              </div>
            </form>
          )}
        </Formik>
      ) : null}
    </div>
  );
}

export default AddPlayerDynamicForm;
