import React, { useEffect, useState } from "react";
import { getAllDrills, getDrillById } from "../../services/axios";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { CarryOutOutlined, SettingOutlined } from "@ant-design/icons";
import { PulseLoader } from "react-spinners";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import CreateDrillModal, {
  CreateDrillForm,
} from "../../containers/modals/CreateDrillModal";
import { Tree } from "antd";

function DrillDefinitions() {
  const [loading, setLoading] = useState(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [menuData, setMenuData] = useState([]);
  const [allDrillsData, setAllDrillsData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDataChange, setIsDataChange] = useState(true);
  const [treeData, setTreeData] = useState([]);
  const [allDrills, setAllDrills] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [selectedDrill, setSelectedDrill] = useState(null);

  const onSelect = async (selectedKeys, info) => {
    // console.log("objectselectedKeys: ", selectedKeys[0]);
    setFormLoading(true);
    try {
      const result = await getDrillById(selectedKeys[0]); //tek kategori çağrıma
      // console.log("resultttttt: ", result);
      setSelectedDrill(result?.data?.drill);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setFormLoading(false);
    }
  };
  function getChildren(drill) {
    if (!drill.sub_category || drill.sub_category.length === 0) {
      return [];
    }
    return drill?.sub_category?.map((item) => {
      const treeItem = getTreeItem(
        item.id,
        item.title,
        item.id,
        <CarryOutOutlined />
      );
      const children = getChildren(item);
      treeItem.children = children;
      return treeItem;
    });
  }

  function getTreeItem(id, title, key, icon, children = []) {
    return {
      id,
      title,
      key,
      icon,
      children,
    };
  }

  const handleDataPush = (item) => {
    setTreeData((prev) => [...prev, item]);
  };

  const fetchGetAllDrills = async () => {
    setLoading(true);
    setTreeData([]);
    try {
      const result = await getAllDrills();
      setAllDrillsData(result.data.drills);
      result?.data?.drills.forEach((item) => {
        if (!item.up_category) {
          const treeItem = getTreeItem(
            item.id,
            item.title,
            item.id,
            <CarryOutOutlined />
          );
          const children = getChildren(item);
          treeItem.children = children;
          handleDataPush(treeItem);
        }
      });
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setIsDataChange(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isDataChange) {
      fetchGetAllDrills().then((result) => setAllDrills(result?.data?.drills));
    }
  }, [isDataChange]);

  // const handleDataPush = (label, value) => {
  //     const newItem = getMenuItem(label, value, <SettingOutlined />, null);
  //     setMenuData((prev) => [...prev, newItem]);
  // };

  function SelectedItemForm() {
    return (
      <>
        <CreateDrillForm
          isDataChange={isDataChange}
          setIsDataChange={setIsDataChange}
          selectedDrill={selectedDrill}
          loading={formLoading}
          allDrills={allDrills}
        />
      </>
    );
  }
  const { DirectoryTree } = Tree;

  return (
    <div>
      <div>
        {loading && (
          <div className="flex">
            <PulseLoader color="red" />
            <p>Yükleniyor...</p>
          </div>
        )}
        {!loading && (
          <div className="flex">
            <div className="flex flex-col w-full max-w-[350px]">
              <DirectoryTree
                className="relative z-0"
                showLine={false}
                showIcon={false}
                defaultExpandedKeys={0}
                // defaultExpandAll
                onSelect={onSelect}
                treeData={treeData}
              />
              <ButtonComponent
                customStyle={"mt-4"}
                text={"Yeni Drill Ekle"}
                outline
                onClick={() => {
                  setModalOpen(true);
                }}
              />
              <CreateDrillModal
                isOpen={isModalOpen}
                setOpen={setModalOpen}
                setIsDataChange={setIsDataChange}
                allDrills={allDrills}
              />
            </div>
            <div className="w-full px-4">
              <SelectedItemForm />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DrillDefinitions;
