export const PositionData = [
    {
        label: "Forvet",
        value: "forvet",
        code: "7/11"
    },
    {
        label: "Kaleci",
        value: "kaleci",
        code: "1"
    },
    {
        label: "Defans",
        value: "defans",
        code: "11"
    },
];