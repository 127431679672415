import React, {useEffect, useState} from "react";
import {Formik, useFormik} from "formik";
import InputComponent from "../../../components/inputs";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import CustomModal from "../../../components/modals";
import CustomModalHeader from "../../../components/modals/CustomModalHeader";
import {handleCustomChange} from "../../../utils/handleCustomChange";
import dayjs from "dayjs";
import DynamicInput from "../../../components/inputs/dynamicInput";
import CreateFootballGroundModal from "../../../containers/modals/CreateFootballGroundModal";
import {
    baseImageURL,
    createActivity,
    getActivitiesCategories,
    getActivitiesCategory,
    getAllAreas,
    getAllDrills,
    getAllTeamCategory,
    getAllUser,
    getDrillById, getFacilitiess, getFootballGround, getPlayerUsers,
    getUser,
    listTeams,
} from "../../../services/axios";
import Swal from "sweetalert2";
import {DataTable} from "../../../components/tables";
import {CarryOutOutlined} from "@ant-design/icons";
import {Input, Tree} from "antd";
import PageTitle from "../../../components/pageTitle";

// divider*********************
function AddActivitiesForm({
                               newEventData,
                               setSaveIt,
                               setIsModalOpen,
                               isDataChange,
                               setIsDataChange,
                               userDataTableData,
                           }) {
    // console.log(newEventData, "newEventData");
    // console.log(newEventData.start, "omer start");
    // console.log(newEventData.end, "omer end");

    // console.log(userDataTableData, "userDataTableData")
    const [startTimeValue, setStartTimeValue] = useState(null);
    const [endTimeValue, setEndTimeValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [rowSelectionList, setRowSelectionList] = useState([]);
    const [rowSelectionListManager, setRowSelectionListManager] = useState([]);
    const [drillsOptions, setDrillsOptions] = useState([]);
    const [managerDataTable, setManagerDataTable] = useState([]);

    const [formLoading, setFormLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [allCategories, setAllCategories] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [newActivity, setNewActivity] = useState(null);

    const [teamCategoryOptions, setTeamCategoryOptions] = useState([]);

    const [selectTeamPlayers, setSelectTeamPlayers] = useState([]);

    const [isDrillModalOpen, setIsDrillModalOpen] = useState(false);

    // console.log(drillsOptions, "drillsOptions");

    const fetchGetUsers = async () => {
        try {
            const result = await getAllUser();
            return result;
        } catch (error) {
            console.error("error", error);
        }
    };

    const fetchCreateActivity = async (
        form
    ) => {
        try {
            const result = await createActivity(
                form
            );
            if (result.success) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: result?.message ?? "Ekleme Başarılı!",
                    showConfirmButton: false,
                    timer: 1000,
                });
                //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: result?.message ?? "Ekleme Başarısız!",
                    showConfirmButton: false,
                    timer: 1000,
                });
            }
            return result;
        } catch (error) {
            console.error(error, "hata");
        } finally {
            setLoading(false);
            setIsDataChange(true);
        }
    };
    //data takvimden geldiğinde süre belirleme
    useEffect(() => {
        if (newEventData) {
            const start_date = dayjs(newEventData?.start).format("YYYY-MM-DD");
            const end_date = dayjs(newEventData?.end).format("YYYY-MM-DD");

            const start_time = "12:00";
            const end_time = dayjs(newEventData?.end).format("HH:mm:ss");

            // initialValues'i güncelle
            setFieldValue("start_date", start_date);
            setFieldValue("end_date", end_date);

            setFieldValue("start_time", start_time);
            setFieldValue("end_time", end_time);

            // const minutes = end.diff(start, "minute");
            // console.log(minutes, "omer");
            // setFieldValue("activitiesTime", minutes + " " + "dakika");
        }
    }, [newEventData]);

    const fetchGetActivitiesCategories = async () => {
        setTreeData([]);
        setLoading(true);
        try {
            const result = await getActivitiesCategories();
            // console.log(result, "result");
            result?.data?.categories.forEach((item) => {
                if (!item.up_category) {
                    const treeItem = getTreeItem(
                        item.id,
                        item.title,
                        item.id,
                        <CarryOutOutlined/>
                    );
                    const children = getChildren(item);
                    treeItem.children = children;
                    handleDataPush(treeItem);
                }
            });
            return result;
        } catch (error) {
            console.error(error, "hata");
        } finally {
            setLoading(false);
            setIsDataChange(false);
        }
    };

    function getChildren(category) {
        if (!category.sub_category || category.sub_category.length === 0) {
            return [];
        }
        return category.sub_category.map((item) => {
            const treeItem = getTreeItem(
                item.id,
                item.title,
                item.id,
                <CarryOutOutlined/>
            );
            const children = getChildren(item);
            treeItem.children = children;
            return treeItem;
        });
    }

    function getTreeItem(id, title, key, icon, children = []) {
        return {
            id,
            title,
            key,
            icon,
            children,
        };
    }

    const handleDataPush = (item) => {
        setTreeData((prev) => [...prev, item]);
    };

    const fetchGetAllCategory = async () => {
        try {
            const result = await listTeams();
            // console.log(result?.data?.teams, "team");
            return result?.data?.teams;
        } catch (error) {
            console.error(error, "err");
        }
    };

    const onChangeTeamSelect = (e1) => {
        fetchTeamPlayers(e1.value);

        // const teamPlayersMap = selected?.players?.map((item) => item.player);
        //
        // const teamPlayersDataTableFormat = teamPlayersMap?.map((item) => ({
        //     key: item?.id,
        //     name: item?.user_name,
        //     tckn: item?.tckn,
        // }));
        // console.log(teamPlayersMap, "selectedPlayer");
        //
        // setSelectTeamPlayers(teamPlayersDataTableFormat);
        //
        // console.log(selected, "selectTeamPlayers");
    };

    useEffect(() => {
        fetchGetAllDrills().then((result) => setAllDrills(result?.data?.drills));

        fetchGetUsers().then((result) =>
            setManagerDataTable(
                result?.data?.users?.map((item) => ({
                    name: item?.user_name,
                    tckn: item?.tckn,
                }))
            )
        );

        fetchGetActivitiesCategories().then((result) =>
            setAllCategories(result?.data?.categories)
        );

        fetchGetAllCategory().then((teams) =>
            setTeamCategoryOptions([
                ...teams.map((item) => ({
                    label: item?.team_title,
                    value: item?.id,
                    players: item?.players,
                })),
            ])
        );
    }, [isDataChange]);
    
    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        touched,
        handleBlur,
        setFieldValue,
        resetForm,
    } = useFormik({
        initialValues: {
            activity_category_id: "",
            title: "",
            activity_type: "faaliyet",
            start_date: "",
            status: "",
            start_time: "",
            // end_time: "",
            // ozel alanlar
            description: "",
            activity_group: "a", //eski
            managers: "",
            players: "",
            activities: [],
            // gönderilmeyen
            activities_drill: "",
            activities_title: "",
            activities_time: "",
            football_ground: "",
            activities_description: "",
            add_main_time: true,
            // gönderilmeyen
            color: "blue",
            team_category: "",
            football_ground_id: "",
        },
        onSubmit: async (values) => {
            const form = { 
                title: values?.title,
                activity_type: values?.activity_type,
                description: values?.description,
                status: values?.status?.value,
                start_date: values?.start_date,
                start_time: values?.start_time,
                // description: values?.description,
                activity_group: values?.activity_group,
                // color: values?.color?.value,
                players: rowSelectionList?.selectedRowItems?.map(
                    (item) => item.key ?? []
                ),
                managers: rowSelectionListManager?.selectedRowItems?.map(
                    (item) => item.key ?? []
                ),
                drills: selectedDrill?.id,
                activities: activityMovements.map((item) => ({
                    ...item,
                    movement_field: item?.movement_field?.value,
                    drill: item?.drill?.id,
                    activity_category: selectedCategory?.id
                })),
                color: 'red',
                team_category: values?.team_category?.value,
                players: selectedActivityCategory === "team" ? teamPlayerIds : values.players,
                activity_category_id: values?.activity_category_id?.value,
                football_ground: values.football_ground?.value,
                football_ground_id: values.football_ground_id.value
            }
            // values.status = values?.status?.value;
            // values.color = values?.color?.value;
            // values.players = rowSelectionList?.selectedRowItems?.map(
            //     (item) => item.key ?? []
            // );
            // values.managers = rowSelectionListManager?.selectedRowItems?.map(
            //     (item) => item.key ?? []
            // );
            // values.drills = selectedDrill?.id;
            // values.activities = activityMovements.map((item) => ({
            //     ...item,
            //     movement_field: item?.movement_field?.value,
            //     drill: item?.drill?.id,
            //     activity_category: selectedCategory?.id
            // }));
            // values.team_category = values?.team_category?.value;
            // values.players = selectedActivityCategory === "team" ? teamPlayerIds : values.players;
            // values.activities_category_field = values.activities_category_field.value
            // values.football_ground = values.football_ground.value
            // values.football_ground_id = values.football_ground_id.value
            // console.log("vals", values.football_ground_id.value);
            try {
                // await fetchCreateActivity(
                //     values.title,
                //     values.activity_type,
                //     values.start_date,
                //     values.start_date, //values.end_date
                //     values.status,
                //     values.start_time,
                //     // values.end_time,
                //     // ozel alanlar
                //     values.description,
                //   values.activity_group,
                //     values.managers,
                //    values.players,
                //     values.drills,
                //     values.activities,
                //     "red",
                //    values.team_category,
                //     values.activities_category_field,
                //    values.football_ground,
                //     values.football_ground_id
                // );

                 await fetchCreateActivity(
                    form,
                    // values.description,
                    // values.title,
                );
            } catch (error) {
                console.error(error, "hata");
            } finally {
                setIsModalOpen(false);
            }
        },

        // validationSchema: validations,
    });

    const onSelect = async (selectedKeys, info) => {
        setFormLoading(true);
        // console.log("selected", selectedKeys, info);
        try {
            const result = await getActivitiesCategory(selectedKeys); //tek kategori çağrıma
            setSelectedCategory(result?.data?.category);
            // console.log("dana2", selectedCategory.id)
        } catch (error) {
            console.error("hata", error);
        } finally {
            setFormLoading(false);
        }
    };

    //eklenen etkinlik silme
    const handleRemoveActivity = (index) => {
        const newActivities = activityMovements.splice(index, 1);
        setNewActivity((prevValues) => ({
            ...prevValues,
            activities: newActivities,
        }));

        //activityMovements change olsun Toplam Aktivite Süresi guncellensin diye
        setActivityMovements((prevState) => [
            ...prevState
        ]);
    };

    const handleAddActivity = (
        activities,
        football_ground,
        activities_description,
        activities_time,
        activities_drill,
        add_main_time,
    ) => {
        // setFieldValue("activities", [
        //     ...activities,
        //     {
        //         title: selectedCategory?.title,
        //         time: activities_time,
        //         movement_field: football_ground,
        //         description: activities_description,
        //         drill: selectedDrill,
        //         isAddMainTime: add_main_time,
        //     },
        // ]);

        setActivityMovements(prevState => {
            const newActivityMovement = {
                title: selectedCategory?.title,
                time: parseInt(activities_time, 10),
                movement_field: football_ground,
                description: activities_description,
                drill: selectedDrill,
                isAddMainTime: add_main_time,
            };
            // console.log(newActivityMovement, "newActivityMovement")

            return [...prevState, newActivityMovement];
        });
    };

    const fetchGetAreas = async () => {
        try {
            const result = await getAllAreas();
            // console.log(result?.response?.areas, "fetchGetAreas");
            return result;
        } catch (error) {
            console.error(error, "error");
        }
    };


    const {DirectoryTree} = Tree;

    //drill tree
    const [treeDataDrill, setTreeDataDrill] = useState([]);
    const [selectedDrill, setSelectedDrill] = useState(null);
    const [allDrills, setAllDrills] = useState([]);

    const onSelectDrill = async (selectedKeys, info) => {
        // console.log("objectselectedKeys: ", selectedKeys[0]);
        setFormLoading(true);
        try {
            const result = await getDrillById(selectedKeys[0]); //tek kategori çağrıma
            // console.log("resultttttt: ", result);
            setSelectedDrill(result?.data?.drill);
            setFieldValue("activities_drill", result?.data?.drill);
        } catch (error) {
            console.error("hata", error);
        } finally {
            setFormLoading(false);
        }
    };
    // console.log(selectedDrill, "selectedDrill");

    function getChildrenDrill(drill) {
        if (!drill.sub_category || drill.sub_category.length === 0) {
            return [];
        }
        return drill?.sub_category?.map((item) => {
            const treeItem = getTreeItem(
                item.id,
                item.title,
                item.id,
                <CarryOutOutlined/>
            );
            const children = getChildrenDrill(item);
            treeItem.children = children;
            return treeItem;
        });
    }

    const handleDataPushDrill = (item) => {
        setTreeDataDrill((prev) => [...prev, item]);
    };

    const fetchGetAllDrills = async () => {
        setLoading(true);
        setTreeDataDrill([]);
        try {
            const result = await getAllDrills();
            // console.log(result?.data?.drills, "treeDataDrill");

            result?.data?.drills.forEach((item) => {
                if (!item.up_category) {
                    const treeItem = getTreeItem(
                        item.id,
                        item.title,
                        item.id,
                        <CarryOutOutlined/>
                    );
                    const children = getChildrenDrill(item);
                    treeItem.children = children;
                    handleDataPushDrill(treeItem);
                }
            });
            return result;
        } catch (error) {
            console.error(error, "hata");
        } finally {
            setIsDataChange(false);
            setLoading(false);
        }
    };


    //26-10
    const [activitiesCategoryOptions, setActivitiesCategoryOptions] = useState([]);
    const [selectedActivityCategory, setSelectedActivityCategory] = useState(false);
    const [treeLoading, setTreeLoading] = useState(false);
    const [selectTeamPlayerIsLoading, setSelectTeamPlayerIsLoading] = useState(false);
    const [teamPlayerIds, setTeamPlayerIds] = useState([]);
    const [selectAllPlayers, setSelectAllPlayers] = useState([]);
    const [footballGroundOptions, setFootballGroundOptions] = useState([]);
    const [inputLoading, setInputLoading] = useState(false);
    const [totalActivityTime, setTotalActivityTime] = useState(0);
    const [playerSearchInputValue, setPlayerSearchInputValue] = useState(false);
    const [filteredPlayerDataTableData, setFilteredPlayerDataTableData] = useState(false);
    const [managerSearchInputValue, setManagerSearchInputValue] = useState(false);
    const [filteredManagerDataTableData, setFilteredManagerDataTableData] = useState(false);
    const [activityMovements, setActivityMovements] = useState([]);


    const fetchGetActivitiesCategoryOptions = async () => {
        setInputLoading(true);
        try {
            const result = await getActivitiesCategories();
            setActivitiesCategoryOptions([]);
            result?.data?.categories.forEach((item) => {
                if (!item.up_category) {
                    setActivitiesCategoryOptions((prevState) => [
                        ...prevState,
                        {
                            label: item?.title,
                            value: item?.id,
                            type: item?.type
                        }
                    ]);
                }
            });
            return result;
        } catch (error) {
            console.error(error, "hata");
        } finally {
            setIsDataChange(false);
            setInputLoading(false);
        }
    }

    const fetchTeamPlayers = async (teamId) => {
        setSelectTeamPlayerIsLoading(true);
        try {
            const result = await getPlayerUsers(teamId);
            const teamPlayerIds = result.data.users?.map((item) => (item?.id));
            setTeamPlayerIds(teamPlayerIds);

            return result.response;
        } catch (error) {
            console.error(error, "error");
        } finally {
            setSelectTeamPlayerIsLoading(false);
        }
    }

    const fetchAllPlayers = async () => {
        setSelectTeamPlayerIsLoading(true);
        try {
            const result = await getPlayerUsers();
            const teamPlayersFormat = result.data.users?.map((item) => ({
                key: item?.id,
                name: item?.name,
                surname: item?.surname,
                tckn: item?.tckn
            }));

            setSelectAllPlayers(teamPlayersFormat);
            return result.response;
        } catch (error) {
            console.error(error, "error");
        } finally {
            setSelectTeamPlayerIsLoading(false);
        }
    }
    const [denemeMert, setDenemeMert] = useState([])
    const handleSelectedFact = async(value) => {
        setDenemeMert([])
        try {
            const result = await getFacilitiess();
            let foundData = result.facilities.filter((item) => item.id == value.value)
            let b = foundData.flatMap((item) => item.grounds.map((item2) => ({ label: item2.title, value: item2.id })));
            setDenemeMert(b);
            console.log("deneme", denemeMert);
        } catch (error) {
            console.error(error)
        }
      };
    
    const fetchFootballGround = async () => {
        setInputLoading(true);
        try {
            const result = await getFacilitiess();
            setFootballGroundOptions([]);
            result?.facilities.map((item) => {
                setFootballGroundOptions((prevState) => [
                    ...prevState,
                    {
                        label: item.tittle,
                        value: item.id
                    }
                ])
            });
        } catch (error) {
            // setLoading(false);
            console.error(error)
            setInputLoading(false);
        }
    };

    const handleActivityCategoryField = async (value, type) => {
        setTreeLoading(true);
        try {
            const result = await getActivitiesCategories(value);
            setTreeData([]);
            setSelectedActivityCategory(type);

            result?.data?.categories.forEach((item) => {
                const treeItem = getTreeItem(
                    item.id,
                    item.title,
                    item.id,
                    <CarryOutOutlined/>
                );
                const children = getChildren(item);
                treeItem.children = children;
                handleDataPush(treeItem);
            });
        } catch (error) {
            console.log(error, "hata");
        } finally {
            setTreeLoading(false);
        }
    };

    const handlePlayerSearchOnChange = (e) => {
        const searchValue = e.target.value;
        setPlayerSearchInputValue(searchValue);

        // console.log(selectAllPlayers, "userDataTableDatauserDataTableData")
        const filteredData = selectAllPlayers?.filter((item) => {
            for (let key in item) {
                if (typeof item[key] === "string" && item[key].toLowerCase().includes(searchValue.toLowerCase())) {
                    return true;
                }

            }
            return false;
        });
        // console.log(filteredData, "filtered data");

        setFilteredPlayerDataTableData(filteredData ?? []);
    };

    const handleManagerSearchOnChange = (e) => {
        const searchValue = e.target.value;
        setManagerSearchInputValue(searchValue);

        const filteredData = userDataTableData?.filter((item) => {
            for (let key in item) {
                if (typeof item[key] === "string" && item[key].toLowerCase().includes(searchValue.toLowerCase())) {
                    return true;
                }
            }
            return false;
        });
        // console.log(filteredData, "filtered data");

        setFilteredManagerDataTableData(filteredData ?? []);
    };

    useEffect(() => {
        fetchGetActivitiesCategoryOptions()
        fetchAllPlayers()
        fetchFootballGround()
    }, [])


    useEffect(() => {
        // console.log(activityMovements, "activityMovements")
        const totalTime = activityMovements
            .filter(filteredItem => filteredItem.isAddMainTime === 1)
            .reduce((acc, time) => acc + time.time, 0);

        setTotalActivityTime(totalTime);

    }, [activityMovements]);

    return (
        <div>
            {/* inputs */}
            <div className="grid grid-cols-1 gap-12 mt-8">
                <form onSubmit={handleSubmit}>
                    <div className="grid gap-4 md:grid-cols-2">
                        <DynamicInput
                            labelTitle={"Aktivite Kategorisi Seçiniz"}
                            containerStyle={"w-full"}
                            placeholder={"Aktivite Kategorisi Seçiniz"}
                            name={"activity_category_id"}
                            value={values}
                            onChange={(e1, e2) => {
                                handleCustomChange(
                                    e1,
                                    e2,
                                    "activity_category_id",
                                    setFieldValue,
                                    "select"
                                );
                                handleActivityCategoryField(e1.value, e1.type)
                            }}
                            error={errors}
                            isTouched={touched}
                            onBlur={handleBlur}
                            component_type={"select"}
                            customOptions={activitiesCategoryOptions ?? []}
                            required={true}
                            disabled={inputLoading}
                            // customOptions={activityMainCategoryOptions}
                            // disabled={treeData.length > 0 ? false : true}
                        />
                        <div className={"flex gap-3"}>
                            <DynamicInput
                                labelTitle={"Başlangıç Tarihi"}
                                containerStyle={"w-full"}
                                placeholder={"Faaliyet Başlığı"}
                                name={"start_date"}
                                value={values}
                                onChange={(e1, e2) => {
                                    handleCustomChange(
                                        e1,
                                        e2,
                                        "start_date",
                                        setFieldValue,
                                        "datePicker"
                                    );
                                }}
                                error={errors}
                                isTouched={touched}
                                onBlur={handleBlur}
                                component_type={"datePicker"}
                                required={true}
                            />
                            <DynamicInput
                                labelTitle={"Başlangıç Saati"}
                                containerStyle={"w-full"}
                                name={"start_time"}
                                value={values}
                                onChange={(e1, e2) => {
                                    handleCustomChange(
                                        e1,
                                        e2,
                                        "start_time",
                                        setFieldValue,
                                        "timePicker"
                                    );
                                }}
                                error={errors}
                                isTouched={touched}
                                onBlur={handleBlur}
                                component_type={"timePicker"}
                                required={true}
                            />
                        </div>
                        <DynamicInput
                            labelTitle={"Tesis Seçimi"}
                            containerStyle={"w-full"}
                            placeholder={"Tesis Seçiniz"}
                            name={"football_ground"}
                            value={values}
                            onChange={(e1, e2) => {
                                handleCustomChange(
                                    e1,
                                    e2,
                                    "football_ground",
                                    setFieldValue,
                                    "select"
                                );
                                handleSelectedFact(e1)  
                            }}
                            error={errors}
                            isTouched={touched}
                            onBlur={handleBlur}
                            component_type={"select"}
                            customOptions={footballGroundOptions ?? []}
                            required={true}
                            disabled={inputLoading}
                        />
                        <DynamicInput
                            labelTitle={"Faaliyet Yeri Seçimi"}
                            containerStyle={"w-full"}
                            placeholder={"Faaliyet Yeri Seçiniz"}
                            name={"football_ground_id"}
                            value={values}
                            onChange={(e1, e2) => {
                                handleCustomChange(
                                    e1,
                                    e2,
                                    "football_ground_id",
                                    setFieldValue,
                                    "select"
                                );
                            }}
                            error={errors}
                            isTouched={touched}
                            onBlur={handleBlur}
                            component_type={"select"}
                            customOptions={denemeMert ?? []}
                            required={true}
                            disabled={inputLoading}
                        />
                        <DynamicInput
                            labelTitle={"Durum"}
                            containerStyle={"w-full"}
                            placeholder={"Durum"}
                            name={"status"}
                            value={values}
                            onChange={(e1, e2) => {
                                handleCustomChange(e1, e2, "status", setFieldValue, "select");
                            }}
                            error={errors}
                            isTouched={touched}
                            onBlur={handleBlur}
                            component_type={"select"}
                            customOptions={[
                                {label: "Gerçekleşti", value: "gerceklesti"},
                                {label: "Planlandı", value: "planlandi"},
                                {label: "İptal Edildi", value: "iptal_edildi"},
                            ]}
                            required={true}
                        />
                        {/* <DynamicInput
                            labelTitle={"Notlar"}
                            containerStyle={"w-full"}
                            placeholder={"Notlar"}
                            name={"description"}
                            value={values}
                            onChange={(e1, e2) => {
                                handleCustomChange(
                                    e1,
                                    e2,
                                    "description",
                                    setFieldValue,
                                    "textarea"
                                );
                            }}
                            error={errors}
                            isTouched={touched}
                            onBlur={handleBlur}
                            component_type={"textarea"}
                        /> */}
                        {selectedActivityCategory === "team" && (
                            <div className={"col-span-full"}>
                                <DynamicInput
                                    labelTitle={"Takım"}
                                    containerStyle={"w-full"}
                                    placeholder={"Takım Seçiniz"}
                                    name={"team_category"}
                                    value={values}
                                    onChange={(e1, e2) => {
                                        onChangeTeamSelect(e1);
                                        handleCustomChange(
                                            e1,
                                            e2,
                                            "team_category",
                                            setFieldValue,
                                            "select"
                                        );
                                    }}
                                    error={errors}
                                    isTouched={touched}
                                    onBlur={handleBlur}
                                    component_type={"select"}
                                    customOptions={teamCategoryOptions ?? []}
                                    disabled={treeLoading}
                                />
                            </div>
                        )}

                        {selectedActivityCategory === "group-player" && (
                            <>
                                <div></div>
                                <div className="">
                                    {/* players */}
                                    <div className={"w-full flex justify-between pb-2"}>
                                        <div>
                                            <p className="pb-2 text-lg">Oyunucu Seç</p>

                                        </div>
                                        <div className={"w-50"}>
                                            <Input
                                                type="text"
                                                placeholder="Oyuncu ara.."
                                                name="playerSearchInput"
                                                onChange={(e) => handlePlayerSearchOnChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <DataTable
                                        selection={{rowSelectionList, setRowSelectionList}}
                                        loading={loading}
                                        columns={[
                                            {title: "Ad", dataIndex: "name"},
                                            {title: "Soyad", dataIndex: "surname"},
                                            // {title: "TC", dataIndex: "tckn"},
                                        ]}
                                        data={playerSearchInputValue ? filteredPlayerDataTableData : selectAllPlayers}
                                    />
                                </div>
                                <div className="">
                                    <p className="pb-2 text-lg">Seçilen Oyuncular</p>
                                    <DataTable
                                        loading={loading}
                                        columns={[
                                            {title: "Ad Soyad", dataIndex: "name"},
                                            {title: "TC", dataIndex: "tckn"},
                                        ]}
                                        data={rowSelectionList?.selectedRowItems ?? []}
                                    />
                                </div>
                            </>
                        )}

                        {!selectedActivityCategory && (<div></div>)}
                        <div className="hidden">
                            {/* players */}
                            <div className={"w-full flex justify-between pb-2"}>
                                <div>
                                    <p className="pb-2 text-lg">Personel Seç</p>

                                </div>
                                <div className={"w-50"}>
                                    <Input
                                        type="text"
                                        placeholder="Personel ara.."
                                        name="managerSearchInput"
                                        onChange={(e) => handleManagerSearchOnChange(e)}
                                    />
                                </div>
                            </div>
                            <DataTable
                                selection={{
                                    rowSelectionList: rowSelectionListManager,
                                    setRowSelectionList: setRowSelectionListManager,
                                }}
                                loading={loading}
                                columns={[
                                    {title: "Ad Soyad", dataIndex: "name"},
                                    {title: "TC", dataIndex: "tckn"},
                                ]}
                                data={managerSearchInputValue ? filteredManagerDataTableData : userDataTableData}
                            />
                        </div>
                        <div className="hidden">
                            <p className="pb-2 text-lg">Seçilen Personeller</p>
                            <DataTable
                                loading={loading}
                                columns={[
                                    {title: "Ad Soyad", dataIndex: "name"},
                                    {title: "TC", dataIndex: "tckn"},
                                ]}
                                data={rowSelectionListManager?.selectedRowItems ?? []}
                            />
                        </div>

                        <div className="col-span-full flex justify-end">
                            <div className="flex flex-col">
                                <p className="flex items-start justify-start py-1 text-lg">
                                    Toplam Aktivite Süresi
                                </p>
                                <div
                                    className="border rounded-lg min-h-[56px] flex items-center ps-5">
                                    {totalActivityTime} dakika
                                </div>
                            </div>
                        </div>
                        <div className="col-span-full">
                            <div className="grid w-full grid-cols-3 gap-2 p-2 border divide-x rounded-xl">
                                <div className="w-full">
                                    {!selectedActivityCategory ? (
                                        <>
                                            <p className={"text-gray-400"}>Önce aktivite kategorisi seçmelisiniz.</p>
                                        </>
                                    ) : (
                                        <>
                                            {!treeLoading ? (
                                                <DirectoryTree
                                                    className="relative z-0"
                                                    showLine={false}
                                                    showIcon={false}
                                                    defaultExpandedKeys={0}
                                                    onSelect={onSelect}
                                                    treeData={treeData}
                                                />
                                            ) : (
                                                <p>Yükleniyor...</p>
                                            )}

                                            {!treeLoading && treeData.length <= 0 && (
                                                <p className={"text-gray-400"}>Seçilen kategoriye alan eklenmemiş.</p>
                                            )}
                                        </>
                                    )}


                                </div>
                                <div className="w-full pl-2">
                                    <p className="text-xl text-primary">Aktivite Ekle</p>
                                    <div className="w-full ">
                                        <DynamicInput
                                            labelTitle={"Aktivite Süresi"}
                                            containerStyle={"w-full"}
                                            placeholder={"Aktivite Süresi"}
                                            name={"activities_time"}
                                            value={values}
                                            onChange={(e1, e2) => {
                                                handleCustomChange(
                                                    e1,
                                                    e2,
                                                    "activities_time",
                                                    setFieldValue,
                                                    "input"
                                                );
                                            }}
                                            error={errors}
                                            isTouched={touched}
                                            onBlur={handleBlur}
                                            type={"number"}
                                        />
                                        <DynamicInput
                                            labelTitle={"Aktivite Açıklaması"}
                                            containerStyle={"w-full"}
                                            placeholder={"Aktivite Açıklaması"}
                                            name={"activities_description"}
                                            value={values}
                                            onChange={(e1, e2) => {
                                                handleCustomChange(
                                                    e1,
                                                    e2,
                                                    "activities_description",
                                                    setFieldValue,
                                                    "textarea"
                                                );
                                            }}
                                            error={errors}
                                            isTouched={touched}
                                            onBlur={handleBlur}
                                            component_type={"textarea"}
                                        />
                                        <DynamicInput
                                            containerStyle={"w-full"}
                                            placeholder={"Ana Faaliyet Süresine Eklensin"}
                                            name={"add_main_time"}
                                            value={values}
                                            onChange={(e1, e2) => {
                                                handleCustomChange(
                                                    e1,
                                                    e2,
                                                    "add_main_time",
                                                    setFieldValue,
                                                    "checkbox"
                                                );
                                            }}
                                            error={errors}
                                            isTouched={touched}
                                            onBlur={handleBlur}
                                            component_type={"checkbox"}
                                        />

                                        <ButtonComponent
                                            customStyle={"w-full"}
                                            onClick={() => setIsDrillModalOpen(true)}
                                            outline
                                            text={"Drill Seç"}
                                        />
                                        <CustomModal
                                            width={677}
                                            isOpen={isDrillModalOpen}
                                            setOpen={setIsDrillModalOpen}
                                            footer={null}
                                        >
                                            <CustomModalHeader
                                                title={"Drill Seçiniz"}
                                                description={"Aşağıdaki drill ağacından drill seçiniz."}
                                            />
                                            <div className="mt-8 border-t">
                                                <div className="flex flex-col py-4 text-lg border-b-4">
                                                    <p className="text-xl font-semibold">Seçilen Drill</p>
                                                    <div className="flex flex-col gap-2 pl-4 mt-2">
                                                        <p>
                                                            Başlık:{" "}
                                                            {selectedDrill?.title ?? <i>Bulunamadı!</i>}
                                                        </p>
                                                        <p>
                                                            Açıklaması:{" "}
                                                            {selectedDrill?.description ?? <i>Bulunamadı!</i>}
                                                        </p>
                                                        {selectedDrill?.image ? (
                                                            <img
                                                                className="max-w-[250px] w-full"
                                                                src={baseImageURL + selectedDrill?.image}
                                                            />
                                                        ) : (
                                                            <i className="border max-w-[250px] w-full p-6 border-dashed rounded-xl text-center">
                                                                Görsel Bulunamadı!
                                                            </i>
                                                        )}
                                                    </div>
                                                </div>

                                                <DirectoryTree
                                                    className="relative z-0"
                                                    showLine={false}
                                                    showIcon={false}
                                                    defaultExpandedKeys={0}
                                                    onSelect={onSelectDrill}
                                                    treeData={treeDataDrill}
                                                />

                                                <div className="flex gap-4 mt-8">
                                                    <ButtonComponent
                                                        customStyle={"w-full border-0"}
                                                        onClick={() => {
                                                            setSelectedDrill({});
                                                            setIsDrillModalOpen(false);
                                                        }}
                                                        outline
                                                        text={"İptal et"}
                                                    />
                                                    <ButtonComponent
                                                        customStyle={"w-full"}
                                                        onClick={() => setIsDrillModalOpen(false)}
                                                        fill
                                                        text={"Kaydet"}
                                                    />
                                                </div>
                                            </div>
                                        </CustomModal>

                                        <div className="flex justify-end pt-4">
                                            <ButtonComponent
                                                onClick={() => {
                                                    // console.log(selectedCategory, "selectedCategory");
                                                    if (!selectedCategory){
                                                        Swal.fire({
                                                            position: "center",
                                                            icon: "warning",
                                                            title: "Sol taraftaki kategorilerden birini seçmelisiniz.",
                                                            showConfirmButton: false,
                                                            timer: 1200,
                                                        });
                                                    }else{
                                                        setSelectedDrill({});
                                                        handleAddActivity(
                                                            values?.activities,
                                                            values?.football_ground,
                                                            values?.activities_description,
                                                            values?.activities_time,
                                                            values?.activities_drill,
                                                            values?.add_main_time ? 1 : 0,
                                                        )

                                                        setFieldValue("activities_time", "");
                                                        setFieldValue("activities_description", "");
                                                        setFieldValue("add_main_time", true);
                                                    }
                                                }}
                                                fill
                                                text={"Aktivite Ekle"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2 border rounded-xl">
                                    <p className="text-xl text-primary">Eklenen Aktiviteler</p>
                                    <div className="flex flex-col w-full gap-4">
                                        {activityMovements.map((item, index) => {
                                            return (
                                                <>
                                                    <div className="p-2 border rounded-lg">
                                                        <div className={"flex justify-between"}>
                                                            <div>
                                                                <p>
                                                                                    <span
                                                                                        className="font-bold">Başlık:</span>{" "}{item?.title}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                {item?.isAddMainTime === 0 ? (
                                                                    <p className={"font-light text-xs text-orange-700"}>Toplam
                                                                        Süreye Dahil
                                                                        Değil</p>) : (<></>)}
                                                            </div>
                                                        </div>
                                                        <p>
                                                            <span className="font-bold">Süre:</span>{" "}
                                                            {item?.time} dakika
                                                        </p>
                                                        <p>
                                                            <span className="font-bold">Açıklama:</span>{" "}
                                                            {item?.description}
                                                        </p>
                                                        <div className="flex flex-col pt-2 mt-2 border-t">
                                                            <p className="font-bold">
                                                                Drill Başlığı:{" "}
                                                                {item?.drill?.title ?? <i>Bulunamadı!</i>}
                                                            </p>
                                                            {item?.drill?.image ? (
                                                                <div className="">
                                                                    <i className="">Drill Görseli:</i>{" "}
                                                                    <img
                                                                        className="w-full"
                                                                        src={baseImageURL + item.drill?.image}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <i className="w-full p-6 text-center border border-dashed rounded-xl">
                                                                    Görsel Bulunamadı!
                                                                </i>
                                                            )}
                                                        </div>
                                                        <div className="flex justify-end w-full mt-2">
                                                            <ButtonComponent
                                                                customStyle={"h-[24px] text-xs"}
                                                                outline
                                                                text={"Çıkar"}
                                                                onClick={() => {
                                                                    handleRemoveActivity(index);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end gap-4 pt-4">
                        <ButtonComponent
                            onClick={() => {
                                setIsModalOpen(false);
                                resetForm();
                            }}
                            text={"Vazgeç"}
                            outline
                        />
                        <ButtonComponent submit fill text={"Kaydet"}/>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddActivitiesForm;
