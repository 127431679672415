import React, { useEffect, useState } from "react";
import ActionButtonsDataTable from "../../../../containers/sections/ActionButtonsDataTable";
import { DataTable } from "../../../../components/tables";
import PageTitle from "../../../../components/pageTitle";
import TabbarComponent from "../../../../components/tabbar";
import CustomModal from "../../../../components/modals";
import ButtonComponent from "../../../../components/buttons/ButtonComponent";
import CustomModalHeader from "../../../../components/modals/CustomModalHeader";
import InputComponent from "../../../../components/inputs";
import { useFormik } from "formik";
import { AllUserFormInputsData } from "../../../../utils/datas/AllUserFormInputsData";
import { handleCustomChange } from "../../../../utils/handleCustomChange";
import UserInformationForm from "../../../dynamicForm/addPlayerDynamicForm/userInformationForm";

function AllUsersList() {
  const [rowSelectionList, setRowSelectionList] = useState([]);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [openActivitiesLocalicationModal, setOpenActivitiesLocalicationModal] =
    useState(null);
  const [loading, setLoading] = useState(false);

  //modal kapandığında tabbar başa döner
  useEffect(() => {
    if (!openActivitiesLocalicationModal) {
      setSelectedTabId(0);
    }
  }, [openActivitiesLocalicationModal]);

  const columns = [
    {
      title: "Kullanıcı Kodu",
      dataIndex: "code",
    },
    {
      title: "Kullanıcı Adı",
      dataIndex: "nickname",
    },
    {
      title: "İşlem",
      fixed: "right",
      key: "action",
      sorter: true,
      width: "",
      render: (item, index) => {
        return (
          <>
            <ActionButtonsDataTable
              to={"a"}
              id={item.key}
              toUpdate={"a"}
              toDetail={"a"}
              deleteDataLabel={item.name}
              detailButton
              updateButton
              deleteButton
            />
          </>
        );
      },
    },
  ];
  const [dynamicInputs, setDynamicInputs] = useState(
    AllUserFormInputsData.map((item) => item.inputs)
  );
  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: dynamicInputs.reduce((acc, input) => {
      acc[input.name] = "";
      return acc;
    }, {}),
    onSubmit: (values, { resetForm }) => {
      // console.log(values, "values");
      setLoading(true);
      setTimeout(() => {
        // console.log("Başarısız!");
        resetForm();
        setLoading(false);
      }, 500);
    },
    // validationSchema: validations,
    validate: (values) => {
      const errors = {};

      if (!values.userPassword) {
        errors.userPassword = "Şifre alanı boş olamaz";
      }

      if (!values.userConfirmPassword) {
        errors.userConfirmPassword = "Şifre tekrar alanı boş olamaz";
      } else if (values.userPassword !== values.userConfirmPassword) {
        errors.userConfirmPassword = "Şifreler eşleşmiyor";
      }

      // Tüm alanların zorunlu olduğunu doğrula
      // Object.keys(values).forEach((key) => {
      //   if (!values[key]) {
      //     errors[key] = "Bu alan boş bırakılamaz";
      //   }
      // });

      dynamicInputs.forEach((input) => {
        if (input.required && !values[input.name]) {
          errors[input.name] = "Bu alan boş bırakılamaz";
        }
      });

      console.log(errors, "hata");
      return errors;
    },
  });

  return (
    <div>
      <PageTitle title={"Tüm Kullanıcılar"} bg />
      <div className="py-8">
        <TabbarComponent
          type={"card"}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
          data={[
            { title: "Kullanıcıları Listele" },
            {
              title: "Yeni Kullanıcı Ekle",
              onClick: () => {
                setOpenActivitiesLocalicationModal(true);
              },
            },
          ]}
        />
        <DataTable
          columns={columns}
          data={[{ code: "007", nickname: "Ömer Say" }]}
          selection={{ rowSelectionList, setRowSelectionList }}
        />

        <CustomModal
          //faaliyet yer seçimi
          width={677}
          isOpen={openActivitiesLocalicationModal}
          setOpen={setOpenActivitiesLocalicationModal}
          footer={null}
        >
          <CustomModalHeader
            title={"Yeni Kullanıcı Ekle"}
            description={"Aşağıdan yeni kullanıcı oluşturabilirsiniz."}
          />
          <UserInformationForm />
        </CustomModal>
      </div>
    </div>
  );
}

export default AllUsersList;
