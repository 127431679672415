import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";

function CustomModal({ isOpen, setOpen, children, width, footer }) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");

  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      className="overflow-hidden xl:overflow-visible"
      // title="Title"
      open={isOpen}
      // onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      footer={footer}
      closeIcon={<></>}
      width={width}
    >
      <div className="w-full">{children}</div>
    </Modal>
  );
}

export default CustomModal;
