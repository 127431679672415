import React, { useEffect, useState } from "react";
import MatchesHeroSection from "../matchesHeroSection";
import { DataTable } from "../../../components/tables";
import TabbarComponent from "../../../components/tabbar";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import CustomModal from "../../../components/modals";
import CustomModalHeader from "../../../components/modals/CustomModalHeader";
import MatchesFields from "../matchesFields";
import DynamicInput from "../../../components/inputs/dynamicInput";
import {
  addMatchesPlayers,
  baseImageURL,
  createMatchCriterias,
  getCriteriaTypes,
  getMatch,
  getMatchCriterias,
  getSeasons,
  listTeams,
} from "../../../services/axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Formik } from "formik";
import { handleCustomChange } from "../../../utils/handleCustomChange";
import { CiImageOff } from "react-icons/ci";
import MatchDataEntry from "./components/MatchDataEntry";
import MatchesReportForm from "../matchesReportForm";
import MatchesReportPlayers from "../matchesReportPlayers";
import MatchesReportCard from "../matchesReportCard";
const playersColumns = [
  {
    title: "Oyuncu Adı Soyadı",
    dataIndex: "nameAndSurname",
  },
  // {
  //   title: "İşlem",
  //   fixed: "right",
  //   key: "action",
  //   sorter: true,
  //   width: "",
  //   render: (item, index) => {
  //     return (
  //       <ActionButtonsDataTable
  //         id={item.key}
  //         toUpdate={Paths.Matches.EditMatches}
  //         toDetail={Paths.Matches.EditMatches}
  //         deleteDataLabel={item.matchesType}
  //         detailButton
  //         updateButton
  //         deleteButton
  //       />
  //     );
  //   },
  // },
];

const criteriaColumns = [
  {
    title: "Dakika",
    dataIndex: "time",
  },
  {
    title: "Kriter Tipi",
    dataIndex: "criteria_type",
  },
  {
    title: "İlk Oyuncu",
    dataIndex: "player_one",
  },
  {
    title: "İkinci Oyuncu",
    dataIndex: "player_two",
  },

  // {
  //   title: "İşlem",
  //   fixed: "right",
  //   key: "action",
  //   sorter: true,
  //   width: "",
  //   render: (item, index) => {
  //     return (
  //       <ActionButtonsDataTable
  //         id={item.key}
  //         toUpdate={Paths.Matches.EditMatches}
  //         toDetail={Paths.Matches.EditMatches}
  //         deleteDataLabel={item.matchesType}
  //         detailButton
  //         updateButton
  //         deleteButton
  //       />
  //     );
  //   },
  // },
];

function EditMatches() {
  const { id } = useParams();
  console.log(id, "param");

  const [isDataChange, setIsDataChange] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [openPlayerModal, setOpenPlayerModal] = useState(false);
  const [openCriteriasModal, setOpenCriteriasModal] = useState(false);
  const [allTeamsOptions, setAllTeamsOptions] = useState([]);
  const [selectedTeamOption, setSelectedTeamOption] = useState(null);
  const [selectedTeamPlayers, setSelectedTeamPlayers] = useState([]);
  const [selectedTeamPlayersDataTable, setSelectedTeamPlayersDataTable] =
    useState([]); //takım seçildiklten sonra modal'daki datatable verisi
  const [rowSelectionList, setRowSelectionList] = useState([]);
  const [getMatchData, setGetMatchData] = useState({});
  const [postPlayersData, setPostPlayersData] = useState([]); //seçilen ve gönderilecek olan data
  const [getMatchPlayersDataTable, setGetMatchPlayersDataTable] = useState([]);

  const [matchCriteriasDataTable, setMatchCriteriasDataTable] = useState([]);
  const [criteriasTypeOptions, setCriteriasTypeOptions] = useState(null);
  const [criteriasPlayerOptions, setCriteriasPlayerOptions] = useState(null);

  let seasonId = getMatchData?.season?.id;
  let matchId = getMatchData?.id;
  let criteriasTeamOptions = [
    {
      label: getMatchData?.home_team?.club_name,
      value: getMatchData?.home_team?.id,
    },
    {
      label: getMatchData?.guest_team?.club_name,
      value: getMatchData?.guest_team?.id,
    },
  ];

  const fetchGetMatch = async (id) => {
    try {
      const result = await getMatch(id);
      console.log(result.response.match, "result");
      setGetMatchData(result?.response?.match);
      setGetMatchPlayersDataTable(
        result?.response?.players.map((item, index) => ({
          nameAndSurname: item?.player?.user_name,
        }))
      );
      setCriteriasPlayerOptions(
        result?.response?.players.map((item, index) => ({
          label: item?.player?.user_name,
          value: item?.player?.id,
        }))
      );
      return result;
    } catch (error) {
      console.log(error, "hataaa");
    } finally {
      setIsDataChange(false);
    }
  };

  const fetchGetMatchCriterias = async () => {
    try {
      const result = await getMatchCriterias(id);
      const matchCriterias = result?.response?.match_criterias.filter(
        (item, index) => item?.match == matchId
      );
      console.log(matchCriterias, "fetchGetMatchCriterias");

      setMatchCriteriasDataTable(
        matchCriterias?.map((item, index) => ({
          time: item?.time,
          criteria_type: item?.criteria_type?.name,
          player_one: item?.player_one?.user_name,
          player_two: item?.player_two?.user_name,
        }))
      );
      return result;
    } catch (error) {
      console.log(error, "hataaa");
    } finally {
      setIsDataChange(false);
    }
  };

  const fetchGetAllTeams = async () => {
    try {
      let seasonId = getMatchData?.season?.id;

      const result = await listTeams();
      const filteredTeams = await result.data.teams.filter(
        (itemFilter, indexFilter) => itemFilter?.seasons?.id === seasonId
      );

      console.log(
        result.data.teams.filter((item) => item.seasons),
        "filteredTeams"
      );

      setAllTeamsOptions(
        filteredTeams.map((item, index) => ({
          label: item?.team_title,
          value: item?.id,
          players: item?.players,
        }))
      );

      return result;
    } catch (error) {
      console.log(error, "hata");
    }
  };

  const handleTeamsSelectOnChange = async (e) => {
    try {
      setSelectedTeamOption(e);
      const playersMap = e.players.map((item) => item);
      setSelectedTeamPlayers(playersMap);
      setSelectedTeamPlayersDataTable(
        playersMap.map((item, index) => ({
          key: item?.id,
          id: item?.player?.id,
          nameAndSurname: item?.player?.user_name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddPlayersToMatch = async (players) => {
    setLoading(true);
    try {
      const result = await addMatchesPlayers(matchId, players);
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });

        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }

      console.log(result, "resultCio");
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };

  const fetchGetCriteriaTypes = async () => {
    try {
      const result = await getCriteriaTypes();
      console.log(result.response.criteria_types, "fetchGetTypes");
      const criteriaTypes = result.response.criteria_types;
      setCriteriasTypeOptions(
        criteriaTypes?.map((item, index) => ({
          label: item?.name,
          value: item?.id,
        }))
      );
      return result;
    } catch (error) {
      console.log(error, "hata");
    }
  };

  const fetchCreateMatchCriteria = async (
    match,
    criteria_type,
    player_one_id,
    player_two_id,
    time,
    half,
    team_id
  ) => {
    setLoading(true);
    try {
      const result = await createMatchCriterias(
        match,
        criteria_type,
        player_one_id,
        player_two_id,
        time,
        half,
        team_id
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });

        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };

  useEffect(() => {
    if (isDataChange && getMatchData) {
      fetchGetMatch(id);
      fetchGetAllTeams();
      fetchGetCriteriaTypes();
      fetchGetMatchCriterias();
    }
  }, [isDataChange, getMatchData]);

  useEffect(() => {
    seasonId && fetchGetAllTeams();
  }, [seasonId]);
  useEffect(() => {
    if (matchId) {
      fetchGetMatchCriterias();
    }
  }, [matchId]);

  useEffect(() => {
    setPostPlayersData(
      rowSelectionList?.selectedRowItems?.map((item) => ({ id: item?.id }))
    );
  }, [rowSelectionList]);

  console.log(seasonId, "season id");
  console.log(matchId, "maç id");
  console.log(rowSelectionList, "rowSelectionList");
  console.log(criteriasTeamOptions, "criteriasTeamOptions");

  return getMatchData ? (
    <div>
      <MatchesHeroSection updateData={getMatchData || {}} />


      <MatchesReportForm updateData={getMatchData || {}}/>


      <MatchesReportPlayers updateData={getMatchData || {}}/>



      <MatchesReportCard updateData={getMatchData || {}}/>
      
      
      {/* <div className="h-full px-6 py-12 bg-white rounded-b-3xl">
        <MatchesFields
          isUpdate={true}
          updateData={getMatchData || {}}
          setIsDataChangeList={setIsDataChange}
          isDataChangeList={isDataChange}
          isActivity={false}
        />
        <div className="w-full gap-8 py-16 xl:grid xl:grid-cols-2">
          <div className="w-full p-4 bg-white customBoxShadow rounded-2xl">
            <p className="pt-4 pb-8 text-2xl font-semibold text-secondary">
              Oyuncu Bilgileri
            </p>
            <div className="">
              <TabbarComponent
                data={[
                  { title: "Oyuncular Listesi" },
                ]}
                type={"card"}
                selectedTabId={selectedTabId}
                setSelectedTabId={setSelectedTabId}
              />
            

              <DataTable
                columns={playersColumns}
                data={getMatchPlayersDataTable}
              />
            </div>
            <div className="flex items-center justify-end gap-4 py-4">
              <ButtonComponent
                onClick={() => setOpenPlayerModal(true)}
                outline
                text="Oyuncu Seç"
                customStyle={"w-fit"}
              />
              <ButtonComponent fill text="Kaydet" customStyle={"w-fit"} />
            </div>
          </div>
          <div className="p-4 mt-8 bg-white xl:mt-0 xl:col-span-1 customBoxShadow rounded-2xl">
            <p className="pt-4 pb-8 text-2xl font-semibold text-secondary">
              Maç Kriterleri
            </p>
            <div className="">
              <DataTable
                loading={!matchCriteriasDataTable}
                data={matchCriteriasDataTable}
                columns={criteriaColumns}
              />
            </div>
            <div className="flex items-center justify-end py-4 ">
              <ButtonComponent
                fill
                text="Maç Kriteri Ekle"
                customStyle={"w-fit"}
                onClick={() => setOpenCriteriasModal(true)}
              />
            </div>
          </div>
        </div>

        <div className="">
          <MatchDataEntry />
        </div>
      </div> */}
      {/* oyuncu seç modal */}
      <CustomModal
        width={677}
        isOpen={openPlayerModal}
        setOpen={setOpenPlayerModal}
        footer={null}
      >
        <CustomModalHeader
          title={"Oyuncu seç"}
          description={"Aşağıdan oyuncuyu seçebilirsiniz."}
        />
        <div className="mt-4">
          <div className="flex w-full gap-4 py-4">
            <div className="flex flex-col w-full">
              <DynamicInput
                labelTitle={"Takımlar"}
                containerStyle={"w-full"}
                placeholder={"Takım Seç"}
                name={""}
                value={selectedTeamOption}
                onChange={(e) => {
                  handleTeamsSelectOnChange(e);
                }}
                component_type={"select"}
                customOptions={allTeamsOptions ?? null}
                error={""}
                isTouched={""}
                onBlur={""}
              />
            </div>
          </div>
          <div className="">
            <DataTable
              selection={{ rowSelectionList, setRowSelectionList }}
              columns={playersColumns}
              data={selectedTeamPlayersDataTable ?? []}
            />
            <div className="flex items-center justify-end gap-4">
              <ButtonComponent
                onClick={() => setOpenPlayerModal(false)}
                outline
                text={"Vazgeç"}
              />
              <ButtonComponent
                onClick={() => {
                  handleAddPlayersToMatch(postPlayersData);
                  setOpenPlayerModal(false);
                  console.log(postPlayersData, "postPlayersData");
                }}
                fill
                text={"Kaydet"}
              />
            </div>
          </div>
        </div>
      </CustomModal>
      {/* oyuncu seç modal */}

      {/* oyuncu seç modal */}
      <CustomModal
        width={677}
        isOpen={openCriteriasModal}
        setOpen={setOpenCriteriasModal}
        footer={null}
      >
        <CustomModalHeader
          title={"Maç Kriteri Ekle"}
          description={"Aşağıdan kriter ekleyebilirsiniz."}
        />
        <div className="mt-4">
          <div className="flex w-full gap-4 py-4">
            <div className="flex flex-col w-full">
              {criteriasTypeOptions &&
                criteriasPlayerOptions &&
                criteriasTeamOptions &&
                matchId && (
                  <Formik
                    initialValues={{
                      match: matchId ?? "",
                      criteria_type: "",
                      player_one_id: "",
                      player_two_id: "",
                      time: "",
                      half: "",
                      team_id: "",
                    }}
                    onSubmit={async (values) => {
                      values.criteria_type = values?.criteria_type?.value;
                      values.player_one_id = values?.player_one_id?.value;
                      values.player_two_id = values?.player_two_id?.value;
                      values.team_id = values?.team_id?.value;
                      try {
                        console.log(values, "values");
                        await fetchCreateMatchCriteria(
                          values?.match,
                          values?.criteria_type,
                          values?.player_one_id,
                          values?.player_two_id,
                          values?.time,
                          values?.half,
                          values.team_id
                        );
                      } catch (error) {
                        console.log(error, "hata");
                      }
                    }}
                  >
                    {({
                      errors,
                      values,
                      handleChange,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                      resetForm,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="grid gap-4 md:grid-cols-2"
                      >
                        <DynamicInput
                          labelTitle={"Kriter Tipi"}
                          containerStyle={"w-full"}
                          placeholder={"Kriter Tipi Seçiniz"}
                          name={"criteria_type"}
                          value={values}
                          onChange={(e1, e2) => {
                            handleCustomChange(
                              e1,
                              e2,
                              "criteria_type",
                              setFieldValue,
                              "select"
                            );
                          }}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          component_type={"select"}
                          customOptions={criteriasTypeOptions ?? ""}
                        />
                        <DynamicInput
                          labelTitle={"Takım Seç"}
                          containerStyle={"w-full"}
                          placeholder={"Takım Seçiniz"}
                          name={"team_id"}
                          value={values}
                          onChange={(e1, e2) => {
                            handleCustomChange(
                              e1,
                              e2,
                              "team_id",
                              setFieldValue,
                              "select"
                            );
                          }}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          component_type={"select"}
                          customOptions={criteriasTeamOptions ?? ""}
                        />
                        <DynamicInput
                          labelTitle={"Süre"}
                          containerStyle={"w-full"}
                          placeholder={"Süre Giriniz"}
                          name={"time"}
                          value={values}
                          onChange={(e1, e2) => {
                            handleCustomChange(
                              e1,
                              e2,
                              "time",
                              setFieldValue,
                              "input"
                            );
                          }}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          component_type={"input"}
                          type={"number"}
                        />
                        <DynamicInput
                          labelTitle={"Yarı"}
                          containerStyle={"w-full"}
                          placeholder={"Yarı Giriniz"}
                          name={"half"}
                          value={values}
                          onChange={(e1, e2) => {
                            handleCustomChange(
                              e1,
                              e2,
                              "half",
                              setFieldValue,
                              "input"
                            );
                          }}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          component_type={"input"}
                          type={"number"}
                        />
                        <DynamicInput
                          labelTitle={"Oyuncu"}
                          containerStyle={"w-full"}
                          placeholder={"Oyuncu Seçiniz"}
                          name={"player_one_id"}
                          value={values}
                          onChange={(e1, e2) => {
                            handleCustomChange(
                              e1,
                              e2,
                              "player_one_id",
                              setFieldValue,
                              "select"
                            );
                          }}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          component_type={"select"}
                          customOptions={criteriasPlayerOptions ?? ""}
                        />
                        <DynamicInput
                          labelTitle={"Asist Yapan Oyuncu"}
                          containerStyle={"w-full"}
                          placeholder={"Oyuncu Seçiniz"}
                          name={"player_two_id"}
                          value={values}
                          onChange={(e1, e2) => {
                            handleCustomChange(
                              e1,
                              e2,
                              "player_two_id",
                              setFieldValue,
                              "select"
                            );
                          }}
                          error={errors}
                          isTouched={touched}
                          onBlur={handleBlur}
                          component_type={"select"}
                          customOptions={criteriasPlayerOptions ?? ""}
                        />
                        <div className="flex">
                          <ButtonComponent fill text={"Ekle"} submit />
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
            </div>
          </div>
        </div>
      </CustomModal>
      {/* oyuncu seç modal */}
    </div>
  ) : (
    <p>Bulunamadı</p>
  );
}
export default EditMatches;
