import React from "react";

function ButtonComponent({
  outline,
  fill,
  text,
  loading,
  customStyle,
  onClick,
  key,
  submit,
}) {
  return (
    <>
      {outline && (
        <button
          disabled={loading}
          type={submit ? "submit" : "button"}
          key={key}
          onClick={onClick}
          className={`flex items-center justify-center h-12 px-6 font-medium transition-all bg-white border rounded-md text-primary text-md hover:bg-primary/10 border-primary ${
            loading && "cursor-not-allowed opacity-50"
          } ${customStyle}`}
        >
          {text}
        </button>
      )}

      {fill && (
        <button
          key={key}
          onClick={onClick}
          type={submit ? "submit" : "button"}
          className={`flex items-center justify-center h-12 px-6 font-medium text-white transition-all rounded-md text-md bg-primary hover:bg-red-500 ${
            loading && "cursor-not-allowed opacity-50"
          } ${customStyle}`}
          disabled={loading}
        >
          {text}
        </button>
      )}
    </>
  );
}

export default ButtonComponent;
