import React, { useEffect, useState } from "react";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { SettingOutlined } from "@ant-design/icons";
import { Input } from "antd";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import {
  createTeamCategory,
  getAllTeamCategory,
} from "../../services/axios";
import { CreateTeamCategory } from "../../containers/modals/CreateTeamCategoryModal";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";

function TeamCategoryDefinitions() {
  const [loading, setLoading] = useState(false);

  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [selectTitleInputValue, setSelectTitleInputValue] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [resultCategories, setResultCategories] = useState([]);
  const [isDataChange, setIsDataChange] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);

  const fetchGetAllTeamCategory = async () => {
    setLoading(true);
    setMenuItems([]);
    try {
      const result = await getAllTeamCategory();
      // console.log(result.response.categories, "result");
      result.response.categories.map((item, index) => {
        handleMenuItemsPush(item.category_title, index);
      });
      setResultCategories(result.response.categories);
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  const fetchCreateTeamCategory = async (category_title) => {
    try {
      const result = await createTeamCategory(category_title);
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });
        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };

  const handleMenuItemsPush = (label, key) => {
    const newItem = getMenuItem(label, key, <SettingOutlined />, null);
    setMenuItems((prev) => [...prev, newItem]);
  };

  useEffect(() => {
    isDataChange && fetchGetAllTeamCategory();
  }, [isDataChange]);

  function SelectedItemForm() {
    return resultCategories
      .filter((filterItem, filterIndex) => filterIndex == selectedMenuIndex)
      .map((item, index) => {
        return (
          <React.Fragment>
            <CreateTeamCategory
              // isOpen={isModalOpen}
              // setOpen={setModalOpen}
              setIsDataChange={setIsDataChange}
              isDataChange={isDataChange}
              updateSelectedValuesProp={item}
            />
          </React.Fragment>
        );
      });
  }

  return (
    <>
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      {!loading && (
        <div className="flex">
          <div className="flex flex-col gap-8">
            <DrawerMenuAntd
              selectedKey={selectedMenuIndex}
              setSelectedKey={setSelectedMenuIndex}
              items={menuItems}
            />
            <div className="flex flex-col gap-2">
              <p className="text-xs">Yeni Seçenek Seçimi Ekle</p>
              <Input
                value={selectTitleInputValue}
                onChange={(e) => setSelectTitleInputValue(e.target.value)}
                placeholder="Başlık giriniz..."
              />
              <ButtonComponent
                loading={!selectTitleInputValue}
                outline
                text="Kategori Ekle"
                onClick={() => {
                  fetchCreateTeamCategory(selectTitleInputValue);
                  setSelectTitleInputValue("");
                }}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <SelectedItemForm />
          </div>
        </div>
      )}
    </>
  );
}

export default TeamCategoryDefinitions;
