import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Paths } from "../../../../routes";
import { useFormik } from "formik";
import { PulseLoader } from "react-spinners";
import { BsChevronLeft } from "react-icons/bs";
import { message } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import validations from "../../../../utils/validations";
import InputComponent from "../../../../components/inputs";
import { UserFormInputsData } from "../../../../utils/datas/UserFormInputsData";
import TabbarComponent from "../../../../components/tabbar";
import NextButton from "../../../../components/buttons/NextButton";
import AddPlayerDynamicForm from "../../../dynamicForm/addPlayerDynamicForm";
import UserInformationForm from "../../../dynamicForm/addPlayerDynamicForm/userInformationForm";
import AddUserStaticForm from "../../allUsers/addUser/addUserStaticForm";

const AddPlayers = () => {
  const [setBreadcrumbData] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [isDataChange, setDataChange] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel",
      },
      {
        title: <Link to={Paths.Users.Players.Players}>Oyuncu Listesi</Link>,
      },
      {
        title: <Link to={Paths.Users.AddUser}>Oyuncu Ekle</Link>,
      },
    ]);
  }, []);

  const [selectedTabId, setSelectedTabId] = useState(0);

  return (
    <>
      <div className="">
        <TabbarComponent
          data={[
            { title: "Kullanıcı Bilgileri" },
            { title: "Kişisel Bilgileri" },
            { title: "Adres Bilgileri" },
            { title: "Aile Bilgileri" },
          ]}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
          type="card"
        />
      </div>
      <div className="w-full p-4 bg-white shadow rounded-b-md">
        <div className="w-full p-4 bg-white rounded-md shadow pb-96">
          <div className="flex items-center text-gray-500">
            <button onClick={() => navigate(-1)}>
              <BsChevronLeft size={21} />
            </button>
            <h4 className="ml-4 text-lg font-semibold">Oyuncu Ekle</h4>
          </div>

          {/* tabbar */}

          {/* tabbar */}
          {/* form */}
          {selectedTabId == 0 && <AddUserStaticForm />}
          {selectedTabId == 1 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              personalInfo
            />
          )}
          {selectedTabId == 2 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              addressInfo
            />
          )}
          {selectedTabId == 3 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              familyInfo
            />
          )}

          {/* form */}

          <div className="w-full mt-4 text-end">
            <div className="inline-flex items-center gap-4">
              <NextButton
                length={UserFormInputsData?.length}
                setSelectedTabId={setSelectedTabId}
                selectedTabId={selectedTabId}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPlayers;
