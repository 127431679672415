import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { baseImageURL } from "../../services/axios";
import { halisahaImage } from "../../utils/filePath";
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

function MatchesHeroSection({ updateData }) {

  console.log(updateData)
  return (
    <div className="min-h-[320px]  w-full relative rounded-3xl">
      <img
        className="h-[320px] absolute z-0 object-cover w-full h-full rounded-3xl"
        src={halisahaImage}
      />
      <div className="relative z-10  flex min-h-[320px] w-full items-center justify-center">
        <div className="w-full h-full grid  md:grid-cols-3 items-center justify-center">
          <div className="flex flex-col items-center justify-center h-full">
            <div className="h-[152px] relative cursor-pointer hover:scale-110 transition-all">
              <img
                src={`${baseImageURL}/${updateData?.home_team?.image}`}
                className="h-full"
              />
            </div>
            <p className="mt-4 text-2xl font-bold text-center text-white">
              {updateData?.home_team?.club_name ?? "Ev Sahibi Takım Seçin"}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center w-full h-full ">
            <div className="flex flex-col gap-4 text-center items-center justify-center">
              <span className="text-[48px] font-bold text-white mr-4">
                {updateData?.time
                  ? moment(updateData.time, "HH:mm:ss").format("HH:mm").replace(' ',"")
                  : "Zaman Belirtilmemiş"}
              </span>
              <span className="text-[32px] font-semibold text-white w-[]">
                {updateData?.date
                  ? moment(updateData.date).format("LL dddd")
                  : "Tarih Belirtilmemiş"}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center h-full">
            <div className="h-[152px] relative cursor-pointer hover:scale-110 transition-all">
              <img
                src={`${baseImageURL}/${updateData?.guest_team?.image}`}
                className="h-full"
              />
            </div>
            <p className="mt-4 text-2xl font-bold text-center text-white">
              {updateData?.guest_team?.club_name ?? "Deplasman Takım Seçin"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchesHeroSection;
