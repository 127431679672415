import React, { useState } from "react";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import { useFormik } from "formik";
import DynamicInput from "../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../utils/handleCustomChange";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import {
  createClub,
  createJudge,
  createTreatmentsMedicines,
  deleteClub,
  deleteJudge,
  deleteTreatmentsMedicines,
  updateClub,
  updateJudge,
  updateTreatmentsMedicines,
} from "../../services/axios";
import Swal from "sweetalert2";

export function CreateMedicineForm({
  isOpen,
  setOpen,
  setIsDataChange,
  isDataChange,
  updateSelectedValuesProp,
}) {
  // console.log(updateSelectedValuesProp, "updateSelectedValuesProp");
  const [loading, setLoading] = useState(false);

  const fetchDeleteJudge = async (id) => {
    try {
      const result = await deleteTreatmentsMedicines(id);
      // console.log(result, "result");
      setIsDataChange(true);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const handleJudgeDeleteOnClick = async (item) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${item.medicine_title}" ilacını istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteJudge(item?.id);
        Swal.fire(
          "Silindi!",
          `"${item.medicine_title}" ilacı silindi.`,
          "success"
        );
      }
    });
  };
  const {
    errors,
    values,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      medicine_title: updateSelectedValuesProp?.medicine_title ?? "",
      medicine_prospectus: updateSelectedValuesProp?.medicine_prospectus ?? "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        let result = null;
        if (updateSelectedValuesProp) {
          result = await updateTreatmentsMedicines(
            updateSelectedValuesProp.id,
            values.medicine_title,
            values.medicine_prospectus
          );
        } else {
          result = await createTreatmentsMedicines(
            values.medicine_title,
            values.medicine_prospectus
          );
        }

        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result?.message ?? "Ekleme Başarılı!",
            showConfirmButton: false,
            timer: 1000,
          });

          // Set isDataChange to true
          setLoading(false);
          setIsDataChange(true);

          //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: result?.message ?? "Ekleme Başarısız!",
            showConfirmButton: false,
            timer: 1000,
          });
        }
      } catch (error) {
        console.error("Hata oluştu:", error);
      } finally {
        setOpen(false);
      }
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid gap-4 pt-4">
          <DynamicInput
            labelTitle={"İlaç Adı"}
            containerStyle={"w-full"}
            placeholder={updateSelectedValuesProp?.name ?? "İlaç Adı"}
            name={"medicine_title"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "medicine_title",
                setFieldValue,
                "input"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
          />
          <DynamicInput
            labelTitle={"İlaç Prospektüs"}
            containerStyle={"w-full"}
            placeholder={updateSelectedValuesProp?.surname ?? "İlaç Prospektüs"}
            name={"medicine_prospectus"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(
                e1,
                e2,
                "medicine_prospectus",
                setFieldValue,
                "input"
              );
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
          />
          {updateSelectedValuesProp && (
            <>
              <div className="flex w-full pb-1">
                <ButtonComponent
                  onClick={() =>
                    handleJudgeDeleteOnClick(updateSelectedValuesProp)
                  }
                  text={"İlacı Sil"}
                  outline
                  customStyle={"w-fit h-[56px]"}
                />
              </div>
            </>
          )}
        </div>

        <div className="flex items-center justify-end gap-4 pt-4">
          <ButtonComponent
            onClick={() => {
              setOpen(false);
              resetForm();
            }}
            outline
            text={"Vazgeç"}
          />
          <ButtonComponent submit fill text={"Kaydet"} />
        </div>
      </form>
    </>
  );
}

function CreateMedicineModal({ isOpen, setOpen, setIsDataChange }) {
  return (
    <div>
      <div>
        <CustomModal
          width={677}
          isOpen={isOpen}
          setOpen={setOpen}
          footer={null}
        >
          <CustomModalHeader
            title={"Yeni İlaç Ekleyin"}
            description={"Aşağıdan yeni ilaç ekleyin."}
          />
          <CreateMedicineForm
            isOpen={isOpen}
            setOpen={setOpen}
            setIsDataChange={setIsDataChange}
          />
        </CustomModal>
      </div>
    </div>
  );
}

export default CreateMedicineModal;
