import React from "react";
import { useState, useEffect } from "react";
import { PulseLoader } from "react-spinners";
import { Form, Formik, useFormik } from "formik";
import Swal from "sweetalert2";
import DynamicInput from "../../../components/inputs/dynamicInput";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import InputComponent from "../../../components/inputs";
import { BsFillPenFill } from "react-icons/bs";
import PageTitle from "../../../components/pageTitle";
import { PositionData } from "../../../utils/datas/positionData";
import {
  createCandidate,
  getAllClub,
  getAllUser,
  getCities,
  getSelectOptions, getSelects,
  listTeams
} from "../../../services/axios";
import { handleCustomChange } from "../../../utils/handleCustomChange";

const AddApplicantForm = () => {
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [regions, setRegions] = useState([]);
  const [competitionLevel, setCompetitionLevel] = useState([]);
  const [allCity, setAllCity] = useState([{ label: "Seçiniz", value: null }]);
  const [followerOptions, setFollowerOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [footOptions, setFootOptions] = useState([]);

  const [getAllClubOptions, setGetAllClubOptions] = useState([]);
  console.log(PositionData, "PositionDataPositionDataPositionData");

  const fetchGetTeams = async () => {
    try {
      const results = await listTeams();
      setTeams((prevState) => [
        ...prevState,
        ...results?.data.teams.map((item) => ({
          label: item.team_title + " | " + item.team_category.category_title,
          value: item.id
        }))
      ]);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchGetSelects = async () => {
    try {
      const results = await getSelects();
      console.log(results, "aaaaa")
      const usedFoot = results?.response?.find(
        (item) => item.id === 8
      );
      const usedFootOptions = usedFoot?.options?.map((item) => ({
        label: item?.label,
        value: item?.id
      }));
      setFootOptions(usedFootOptions);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchFollower = async () => {
    try {
      const result = await getAllUser("team_coach");
      const result2 = await getAllUser("goalkeeper_coach");
      const mergedResults = [...result?.data?.users, ...result2?.data?.users];

      setFollowerOptions((prevState) => [
        ...prevState,
        ...mergedResults.map((item) => ({
          label: item.user_name,
          value: item.id
        }))
      ]);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const position = () => {
    setPositionOptions((prevState) => [
      ...prevState,
      ...PositionData.map((item) => ({
        label: item.code,
        value: item.value
      }))
    ]);
  };

  const fetchGetRegions = async () => {
    try {
      const results = await getSelectOptions();
      console.log(results, "resultsresultsresults");
      const region = results?.data?.select_lists?.find(
        (item) => item.id === 13
      );
      const regionOptions = region?.options?.map((item) => ({
        label: item?.label,
        value: item?.value
      }));
      setRegions(regionOptions);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchGetMonitoredCompetitionLevel = async () => {
    try {
      const results = await getSelectOptions();
      console.log(results, "resultsresultsresults");
      const region = results?.data?.select_lists?.find(
        (item) => item.id === 2
      );
      const competitionLevel = region?.options?.map((item) => ({
        label: item?.label,
        value: item?.value
      }));
      setCompetitionLevel(competitionLevel);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchGetCity = async () => {
    setLoading(true);
    try {
      const result = await getCities();
      setAllCity((prevState) => [
        ...prevState,
        ...result.data.data.map((item) => ({
          label: item.name,
          value: item.id
        }))
      ]);

      setLoading(false);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchGetAllClub = async () => {
    setLoading(true);
    try {
      const result = await getAllClub();
      setGetAllClubOptions(
        result.response.clubs.map((item, index) => ({
          label: item.club_name,
          value: item.id,
        }))
      );
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      // setIsDataChangeList(false);
    }
  };

  useEffect(() => {
    fetchGetMonitoredCompetitionLevel();
    fetchGetAllClub()
    fetchGetCity();
    fetchGetRegions();
    position();
    fetchGetSelects();
    fetchGetTeams();
    fetchFollower();
  }, []);

  const { errors, values, handleSubmit, touched, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        name: "",
        surname: "",
        tckn: "",
        email: "",
        watchMatch: "",
        watchDate: "",
        monitoredTournament: "",
        notes: "",
        watchStatus: "",
        fkFollower: "",
        fkTeam: "",
        position: "",
        usedFoot: "",

        birthDate: "",
        clubId: "",
        cityId: "",
        regionKey: "",
        recommendedPosition: "",
        height: "",
        weight: "",
        trainingAge: "",
        fkMonitoredCompetitionDifficulty: ""
      },
      onSubmit: async (values) => {
        console.log(values, "values");
        try {
          const result = await createCandidate(
            values.name,
            values.surname,
            values.tckn,
            values.email,
            values.watchMatch,
            values.watchDate,
            values.monitoredTournament,
            values.notes,
            values.watchStatus.value,
            values.fkFollower.value,
            values.fkTeam.value,
            values.position.value,
            values.usedFoot.value,

            values.birthDate,
            values.clubId,
            values.cityId.value,
            values.regionKey.value,
            values.recommendedPosition.value,
            values.height,
            values.weight,
            values.trainingAge,
            values.fkMonitoredCompetitionDifficulty.value
          );
          console.log(result, "resulttttt");
          if (result.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result?.data?.message ?? "Ekleme Başarılı!",
              showConfirmButton: false,
              timer: 1000
            });
             window.location.href = `/applicants/edit-applicants/${result?.data?.candidate?.id}`;
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: result?.data?.message ?? "Ekleme Başarısız!",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "Kapat"
            });
          }
        } catch (error) {}
      }
    });

  return (
    <>
      {loading ? (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className="w-full p-5 py-3 bg-white shadow-xl rounded-xl border-t-primary focus:outline-none">
              <div className="flex items-center justify-between">
                <div className="w-full">
                  <PageTitle
                    icon={<BsFillPenFill className="text-2xl " />}
                    title={"Aday Ekle"}
                    bg
                  />
                </div>
              </div>

              <div className="px-2 py-2">
                <div className="flex items-center w-full sm:w-auto ">
                  <div className="w-[200px] hidden"></div>
                </div>
              </div>
              <div className="p-4 pb-10 bg-white rounded-md ">
                <div className="flex items-center text-gray-500">
                  <div className="grid grid-cols-2 gap-5 w-full">
                    <div>
                      <InputComponent
                        labelTitle={"Oyuncu Adı "}
                        name={"name"}
                        type={"text"}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "name",
                            setFieldValue,
                            "input"
                          );
                        }}
                        value={values}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        containerStyle={"w-full"}
                        placeholder={"Oyuncu Adı "}
                        required={true}
                      />
                    </div>
                    <div>
                      <InputComponent
                        labelTitle={"Oyuncu Soyadı"}
                        name={"surname"}
                        type={"text"}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "surname",
                            setFieldValue,
                            "input"
                          );
                        }}
                        value={values}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        containerStyle={"w-full"}
                        placeholder={"Oyuncu Soyadı"}
                        required={true}
                      />
                    </div>
                    <div>
                      <InputComponent
                        labelTitle={"TC Kimlik No"}
                        name={"tckn"}
                        type={"text"}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "tckn",
                            setFieldValue,
                            "input"
                          );
                        }}
                        value={values}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        containerStyle={"w-full"}
                        placeholder={"TC Kimlik No"}
                      />
                    </div>

                    <div>
                      <InputComponent
                        labelTitle={"E-posta"}
                        name={"email"}
                        type={"text"}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "email",
                            setFieldValue,
                            "input"
                          );
                        }}
                        value={values}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        containerStyle={"w-full"}
                        placeholder={"E-posta"}
                      />
                    </div>

                    <div>
                      <DynamicInput
                        labelTitle={"Pozisyon"}
                        containerStyle={"w-full"}
                        placeholder={"Pozisyon"}
                        name={"position"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "position",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={positionOptions}
                      />
                    </div>

                    <div>
                      <DynamicInput
                        labelTitle={"Hakim Ayak"}
                        containerStyle={"w-full"}
                        placeholder={"Hakim Ayak"}
                        name={"usedFoot"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "usedFoot",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={footOptions}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"Oynadığı Takım"}
                        containerStyle={"w-full"}
                        placeholder={"Oynadığı Takım"}
                        name={"fkTeam"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "fkTeam",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={teams}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"İzlendiği Maç"}
                        containerStyle={"w-full"}
                        placeholder={"İzlendiği Maç"}
                        name={"watchMatch"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "watchMatch",
                            setFieldValue,
                            "input"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"input"}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"İzlendiği Tarih"}
                        containerStyle={"w-full"}
                        placeholder={"İzlendiği Tarih"}
                        name={"watchDate"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "watchDate",
                            setFieldValue,
                            "datePicker"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"datePicker"}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"İzlendiği Turnuva"}
                        containerStyle={"w-full"}
                        placeholder={"İzlendiği Turnuva"}
                        name={"monitoredTournament"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "monitoredTournament",
                            setFieldValue,
                            "input"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"input"}
                      />
                    </div>

                    <div>
                      <DynamicInput
                        labelTitle={"İzlenme Durumu"}
                        containerStyle={"w-full"}
                        placeholder={"İzlenme Durumu"}
                        name={"watchStatus"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "watchStatus",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={[
                          { label: "İzlendi", value: "izlendi" },
                          { label: "İzlenmedi", value: "İzlenmedi" },
                          {
                            label: "Görüşme Yapıldı",
                            value: " gorusme-yapildi"
                          }
                        ]}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"İzleyen"}
                        containerStyle={"w-full"}
                        placeholder={"İzleyen"}
                        name={"fkFollower"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "fkFollower",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={followerOptions}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"Notlar"}
                        containerStyle={"w-full"}
                        placeholder={"Notlar"}
                        name={"notes"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "notes",
                            setFieldValue,
                            "textarea"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"textarea"}
                      />
                    </div>
                  </div>
                </div>
                {/* <hr /> */}
                <div className="flex items-center text-gray-500 pt-7 hidden">
                  <div className="grid grid-cols-2 gap-5 w-full">
                    <div>
                      <DynamicInput
                        labelTitle={"Doğum Tarihi"}
                        containerStyle={"w-full"}
                        placeholder={"Doğum Tarihi"}
                        name={"birthDate"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "birthDate",
                            setFieldValue,
                            "datePicker"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"datePicker"}
                      />
                    </div>



                    <div>
                      <DynamicInput
                        labelTitle={"Kulübü"}
                        containerStyle={"w-full"}
                        placeholder={"Kulübü"}
                        name={"clubId"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "clubId",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={getAllClubOptions}
                      />
                    </div>




                
                    <div>
                      <DynamicInput
                        labelTitle={"Şehir"}
                        containerStyle={"w-full"}
                        placeholder={"Şehir"}
                        name={"cityId"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "cityId",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={allCity}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"Bölge"}
                        containerStyle={"w-full"}
                        placeholder={"Bölge"}
                        name={"regionKey"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "regionKey",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={regions}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"Scout'un Önerdiği Pozisyon"}
                        containerStyle={"w-full"}
                        placeholder={"Scout'un Önerdiği Pozisyon"}
                        name={"recommendedPosition"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "recommendedPosition",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={positionOptions}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"Boy (m)"}
                        containerStyle={"w-full"}
                        placeholder={"Boy (m)"}
                        name={"height"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "height",
                            setFieldValue,
                            "input"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"input"}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"Kilo (kg)"}
                        containerStyle={"w-full"}
                        placeholder={"Kilo (kg)"}
                        name={"weight"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "weight",
                            setFieldValue,
                            "input"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"input"}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"Antrenman Yaşı"}
                        containerStyle={"w-full"}
                        placeholder={"Antrenman Yaşı"}
                        name={"trainingAge"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "trainingAge",
                            setFieldValue,
                            "input"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"input"}
                      />
                    </div>
                    <div>
                      <DynamicInput
                        labelTitle={"İzlenen Müsabaka Seviyesi"}
                        containerStyle={"w-full"}
                        placeholder={"İzlenen Müsabaka Seviyesi"}
                        name={"fkMonitoredCompetitionDifficulty"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "fkMonitoredCompetitionDifficulty",
                            setFieldValue,
                            "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={competitionLevel}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <ButtonComponent submit fill text={"Kaydet"} />
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default AddApplicantForm;
