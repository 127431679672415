import React, {useEffect} from "react";
import {Link, useOutletContext} from "react-router-dom";
import {Paths} from "../../../routes";

import {PlayerList} from "../../../containers/sections";

const Players = () => {
    const [setBreadcrumbData] = useOutletContext();

    useEffect(() => {
        setBreadcrumbData([
            {
                title: 'Panel',
            },
            {
                title: <Link to={Paths.Users.Players.Players}>Oyuncu Listesi</Link>,
            }
        ]);
    }, []);

    return (
        <div className="w-full p-4 bg-white rounded-md shadow">
            <PlayerList/>
        </div>
    );
}

export default Players;