import React, { useEffect, useState } from "react";
import { createJudge, getAllAreas, getAllJudges } from "../../services/axios";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { SettingOutlined } from "@ant-design/icons";
import { PulseLoader } from "react-spinners";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import CreateJudgeModal, {
  CreateJudgeForm,
} from "../../containers/modals/CreateJudgeModal";
import CreateActivitiesAreasModal, { CreateActivitiesAreasForm } from "../../containers/modals/CreateActivitiesAreasModal";

// ÖNEMLİ NOT
// acil yetişmesi gerektiği için hakemler sayfasından kopyalandı,
// judge yazan değerler areas olmalı normalde

function ActivitiesAreasDefinitions() {
  const [loading, setLoading] = useState(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [menuData, setMenuData] = useState([]);
  const [allJudgesData, setAllJudgesData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDataChange, setIsDataChange] = useState(true);

  const fetchGetAllJudges = async () => {
    setLoading(true);
    setMenuData([]);
    try {
      const result = await getAllAreas();
      // console.log(result, "result");
      setAllJudgesData(result?.response.areas);
      result?.response.areas.map((item, index) => {
        handleDataPush(item?.title, index);
      });
      setLoading(false);
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setIsDataChange(false);
    }
  };

  useEffect(() => {
    isDataChange && fetchGetAllJudges();
  }, [isDataChange]);

  const handleDataPush = (label, value) => {
    const newItem = getMenuItem(label, value, <SettingOutlined />, null);
    setMenuData((prev) => [...prev, newItem]);
  };

  // console.log(allJudgesData, "allJudgesData");

  function SelectedItemForm() {
    return allJudgesData
      .filter((filterItem, filterIndex) => filterIndex == selectedMenuIndex)
      .map((item, index) => {
        return (
          <React.Fragment>
            <CreateActivitiesAreasForm
              isOpen={isModalOpen}
              setOpen={setModalOpen}
              setIsDataChange={setIsDataChange}
              isDataChange={isDataChange}
              updateSelectedValuesProp={item}
            />
          </React.Fragment>
        );
      });
  }

  return (
    <div>
      <div>
        {loading && (
          <div className="flex">
            <PulseLoader color="red" />
            <p>Yükleniyor...</p>
          </div>
        )}
        {!loading && (
          <div className="flex">
            <div className="flex flex-col">
              <DrawerMenuAntd
                items={menuData}
                selectedKey={selectedMenuIndex}
                setSelectedKey={setSelectedMenuIndex}
              />
              <ButtonComponent
                customStyle={"mt-4"}
                text={"Yeni Alan Ekle"}
                outline
                onClick={() => {
                  setModalOpen(true);
                }}
              />
              <CreateActivitiesAreasModal
                isOpen={isModalOpen}
                setOpen={setModalOpen}
                setIsDataChange={setIsDataChange}
              />
            </div>
            <div className="w-full px-4">
              <SelectedItemForm />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ActivitiesAreasDefinitions;
