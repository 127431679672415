import React, { useState } from "react";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import { useFormik } from "formik";
import DynamicInput from "../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../utils/handleCustomChange";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import { createClub, deleteClub, updateClub } from "../../services/axios";
import Swal from "sweetalert2";

export function CreateClubForm({
  isOpen,
  setOpen,
  setIsDataChange,
  isDataChange,
  updateSelectedValuesProp
}) {
  const [loading, setLoading] = useState(false);

  const fetchDeleteClub = async (id) => {
    try {
      const result = await deleteClub(id);
      // console.log(result, "result");
      setIsDataChange(true);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const handleClubDeleteOnClick = async (item) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${item.club_name}" kişini silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal"
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteClub(item?.id);
        Swal.fire("Silindi!", `"${item.club_name}" kulübü silindi.`, "success");
      }
    });
  };

  const {
    errors,
    values,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      image: updateSelectedValuesProp?.image ?? null,
      club_name: updateSelectedValuesProp?.club_name ?? "",
      short_code: updateSelectedValuesProp?.short_code ?? ""
    },
    onSubmit: async (values, { resetForm }) => {
      // console.log(values, "values");
      setLoading(true);
      try {
        let result = null;
        if (updateSelectedValuesProp) {
          // console.log(values.image, "desene2");
          result = await updateClub(
            updateSelectedValuesProp.id,
            values.image,
            values.club_name,
            values.short_code
          );
        } else {
          // console.log(values.image, "desene");
          result = await createClub(
            values.image,
            values.club_name,
            values.short_code
          );
        }

        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result?.message ?? "Ekleme Başarılı!",
            showConfirmButton: false,
            timer: 1000
          });

          // Set isDataChange to true
          setLoading(false);
          setIsDataChange(true);

          //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: result?.message ?? "Ekleme Başarısız!",
            showConfirmButton: false,
            timer: 1000
          });
        }
        // console.log(result, "resultFile");
      } catch (error) {
        console.error("Hata oluştu:", error);
      } finally {
        setOpen(false);
      }
    }
  });

  return (
    <>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="grid gap-4 pt-4">
          <div className="w-full">
            <DynamicInput
              labelTitle={"Kulüp Amblemi"}
              containerStyle={"w-full"}
              name={"image"}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "image",
                  setFieldValue,
                  "fileUpload"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
              component_type={"fileUpload"}
            />
          </div>
          <DynamicInput
            labelTitle={"Kulüp Adı"}
            containerStyle={"w-full"}
            placeholder={updateSelectedValuesProp?.club_name ?? "Kulüp Adı"}
            name={"club_name"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(e1, e2, "club_name", setFieldValue, "input");
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
          />
          <DynamicInput
            labelTitle={"Kulüp Kısa Kodu"}
            containerStyle={"w-full"}
            placeholder={updateSelectedValuesProp?.short_code ?? "Kulüp Adı"}
            name={"short_code"}
            value={values}
            onChange={(e1, e2) => {
              handleCustomChange(e1, e2, "short_code", setFieldValue, "input");
            }}
            error={errors}
            isTouched={touched}
            onBlur={handleBlur}
          />
          {updateSelectedValuesProp && (
            <>
              {updateSelectedValuesProp.club_name === "Altınordu" ? null : (
                <div className="flex w-full pb-1">
                  <ButtonComponent
                    onClick={() =>
                      handleClubDeleteOnClick(updateSelectedValuesProp)
                    }
                    text={"Kulübü Sil"}
                    outline
                    customStyle={"w-fit h-[56px]"}
                  />
                </div>
              )}
            </>
          )}
        </div>

        <div className="flex items-center justify-end gap-4 pt-4">
          <ButtonComponent
            onClick={() => {
              setOpen(false);
              resetForm();
            }}
            outline
            text={"Vazgeç"}
          />
          <ButtonComponent submit fill text={"Kaydet"} />
        </div>
      </form>
    </>
  );
}

function CreateClubModal({ isOpen, setOpen, setIsDataChange }) {
  return (
    <div>
      <div>
        <CustomModal
          width={677}
          isOpen={isOpen}
          setOpen={setOpen}
          footer={null}
        >
          <CustomModalHeader
            title={"Yeni Sezon Ekleyin"}
            description={"Aşağıdan yeni sezon ekleyin."}
          />
          <CreateClubForm
            isOpen={isOpen}
            setOpen={setOpen}
            setIsDataChange={setIsDataChange}
          />
        </CustomModal>
      </div>
    </div>
  );
}

export default CreateClubModal;
