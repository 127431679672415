import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  activities: [],
  filteredActivities: [],
  loading: false,
  error: {
    code: null,
    message: null,
  },
};

export const GetActivities = createAsyncThunk(
  "activities/GetActivities",
  async (arg, thunkAPI) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let activitiesData = [];
        for (let i = 1; i <= 25; i++) {
          activitiesData.push({
            key: i,
            status: `Faaliyet ${i} Gerçekleşti`,
            activitiesDate: "02/02/2019",
            activitiesTime: "00:50",
            activitiesGroup: "Takım Antrenmanları ALFA U18/19",
            activitiesZone: "Metin Oktay Yerleşkesi",
            activitiesDescription: "Kuvvet takım Antrenmanları",
            activitiesTeam: "ALFA U18/19",
            manager: "Serkan Yeğin, Emre Inan",
          });
        }
        resolve(activitiesData);
      }, 750);
    });
  }
);

export const ActivitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    filterActivities: (state, action) => {
      const filterInputValue = action.payload;
      if (filterInputValue) {
        state.loading = true;
        const filteredData = state.activities.filter(
          (item) =>
            item.status
              .toLowerCase()
              .includes(filterInputValue.toLowerCase()) ||
            item.manager
              .toLowerCase()
              .includes(filterInputValue.toLowerCase()) ||
            item.activitiesZone
              .toLowerCase()
              .includes(filterInputValue.toLowerCase()) ||
            item.activitiesGroup
              .toLowerCase()
              .includes(filterInputValue.toLowerCase()) ||
            item.activitiesDate
              .toLowerCase()
              .includes(filterInputValue.toLowerCase()) ||
            item.activitiesTime
              .toLowerCase()
              .includes(filterInputValue.toLowerCase()) ||
            item.activitiesDescription
              .toLowerCase()
              .includes(filterInputValue.toLowerCase()) ||
            item.activitiesTeam
              .toLowerCase()
              .includes(filterInputValue.toLowerCase())
        );
        state.filteredActivities = filteredData;
        state.loading = false;
      } else {
        state.filteredActivities = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetActivities.pending, (state, action) => {
      state.loading = true;
      state.message = null;
      state.error = {
        code: null,
        message: null,
      };
    });
    builder.addCase(GetActivities.fulfilled, (state, action) => {
      state.activities = action.payload;
      state.loading = false;
    });
    builder.addCase(GetActivities.rejected, (state, action) => {
      state.loading = false;
      state.message = "Aktivite Verileri Getirme Reddedildi!!";
      state.error = {
        code: 500,
        message: "Get Activities Rejected",
      };
    });
  },
});

export const { filterActivities } = ActivitiesSlice.actions;

export default ActivitiesSlice.reducer;
