import React from "react";

const Curriculum = () => {
    return (
        <div>
            Curriculum
        </div>
    );
}

export default Curriculum;