import { SettingOutlined } from "@ant-design/icons";
import { getMenuItem } from "../../../components/drawerMenuAntd";

export const GeneralDefinitionsMainMenuDatas = [
  getMenuItem("Faaliyet Kategorileri", "faaliyet-kategorileri", <SettingOutlined />, null),
  getMenuItem("Sezon Tanımları", "sezon-tanimlari", <SettingOutlined />, null),
  getMenuItem("Lig Tanımları", "lig-tanimlari", <SettingOutlined />, null),
  getMenuItem("Tedavi Tanımları", "tedavi-tanimlari", <SettingOutlined />, null),
  getMenuItem("Test Tanımları", "test-tanimlari", <SettingOutlined />, null),
  getMenuItem("Takım Tanımları", "takim-tanimlari", <SettingOutlined />, null),
  getMenuItem("Drill Tanımları", "drill-tanimlari", <SettingOutlined />, null),
  getMenuItem("Birim Tanımları", "birim-tanimlari", <SettingOutlined />, null),
  getMenuItem("Kulüp Tanımları", "kulup-tanimlari", <SettingOutlined />, null),
  getMenuItem("Sağlık Kuruluşları", "saglik-kuruluslari", <SettingOutlined />, null),
  getMenuItem("Seçenek Tanımları", "secenek-tanimlari", <SettingOutlined />, null),
  getMenuItem("Takım Kategori Tanımları", "faaliyet-kategori-tanimlari", <SettingOutlined />, null),
  getMenuItem("Saha Tanımları", "saha-tanimlari", <SettingOutlined />, null),
  // getMenuItem("Hakem Tanımları", "hakem-tanimlari", <SettingOutlined />, null),

  //   getMenuItem(
  //     <>
  //     <div className="px-4 text-white rounded-full bg-primary">
  //         <p>Custom HTML Menu</p>
  //     </div>
  //     </>,
  //     "link",
  //     <<SettingOutlined /> />
  //   ),
];
