import React, { useEffect, useState } from "react";
import TabbarComponent from "../../../components/tabbar";
import { DataTable } from "../../../components/tables";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import {
  addMatchesPlayers,
  deletePlayersInfoPlayer,
  getMatchPlayers,
  getTeamPlayers,
  listTeams
} from "../../../services/axios";
import CustomModal from "../../../components/modals";
import CustomModalHeader from "../../../components/modals/CustomModalHeader";
import DynamicInput from "../../../components/inputs/dynamicInput";
import Swal from "sweetalert2";
import ApprovalButtonsDataTable from "../../../containers/sections/ApprovalButtonsDataTable";

function PlayerInfos({ id, matchData }) {
  const [loading, setLoading] = useState(false);
  const [isDataChange, setIsDataChange] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [openPlayerModal, setOpenPlayerModal] = useState(false);
  const [allTeamsOptions, setAllTeamsOptions] = useState([]);
  const [selectedTeamId, setSelectedTeamId] = useState(false);
  const [rowSelectionList, setRowSelectionList] = useState(false);
  const [teamPlayersDataTable, setTeamPlayersDataTable] = useState([]);
  const [selectedMatchPlayers, setSelectedMatchPlayers] = useState([]);
  const playersColumns = [
    {
      title: "Oyuncu Adı Soyadı",
      dataIndex: "nameAndSurname"
    },
    {
      title: "İşlem",
      key: "action",
      width: "",
      render: (item) => {
          return (
            <ApprovalButtonsDataTable
              disabled={matchData.isFinish ? true : false}
              rejectDataLabel={item?.title}
              onClickReject={() => handleDelete(item)}
              rejectButton
            />
          );
      
      }
    }
  ];

  const fetchGetAllTeams = async () => {
    try {
      let seasonId = matchData?.season?.id;
      const result = await listTeams();
      const filteredTeams = result.data.teams.filter(
        (itemFilter) => itemFilter?.currentLeague?.fkSeason === seasonId
      );

      setAllTeamsOptions(
        filteredTeams?.map((item) => ({
          label: item?.team_title,
          value: item?.id,
          players: item?.players
        }))
      );

      return result;
    } catch (error) {
      console.log(error, "hata");
    }
  };
  const fetchGetTeamPlayers = async (team, ids) => {
    try {
      setLoading(true);

      let availablePlayerIds = [];
      selectedMatchPlayers.map((item) => {
        availablePlayerIds.push(item.key);
      });

      if (ids) {
        ids.map((item) => {
          availablePlayerIds.push(item);
        });
      }

      const result = await getTeamPlayers(team, availablePlayerIds);
      if (result.success === true) {
        setTeamPlayersDataTable(
          result.response.team.map((item, index) => ({
            key: item?.player?.id,
            nameAndSurname: item?.player?.user_name
          }))
        );
      } else {
        console.log("hata getTeamPlayers");
      }
    } catch (error) {
      console.log(error, "hataaaaaa");
    } finally {
      setLoading(false);
    }
  };
  const fetchGetSelectedTeamPlayers = async (matchId) => {
    //player infos / selected player get
    try {
      setLoading(true);
      const result = await getMatchPlayers(matchId);
      if (result.success === true) {
        setSelectedMatchPlayers(
          result?.data?.players.map((item) => ({
            key: item?.player?.id,
            nameAndSurname: item?.player?.user_name
          }))
        );
      } else {
        console.log("hata getMatchPlayers");
      }
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (item) => {
    try {
      Swal.fire({
        title: `${item?.nameAndSurname} silinecek emin misiniz?`,
        text: "Bu işlemi geri alamazsınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "Vazgeç"
      }).then((result) => {
        if (result.isConfirmed) {
          deletePlayersInfoPlayer(matchData.id, item.key);
          Swal.fire(
            `${item?.nameAndSurname} Silindi!`,
            "Seçtiğiniz öğe başarıyla silindi.",
            "success"
          );
          setIsDataChange(true);
        }
      });
    } catch (error) {
      console.log(error, "hata");
    }
  };

  const handleSubmitTeamPlayer = async () => {
    //player infos / selected player save
    try {
      let postSelectedPlayers = [];
      rowSelectionList.selectedRowItems.map((item) => {
        postSelectedPlayers.push({
          id: item.key
        });
      });

      const result = await addMatchesPlayers(id, postSelectedPlayers);
      postSelectedPlayers = [];
      setRowSelectionList([]);

      if (result.success === true) {
        fetchGetTeamPlayers(selectedTeamId, result?.data?.playerIds);
        setIsDataChange(true);
      } else {
        console.log("hata addMatchesPlayers");
      }
    } catch (error) {
      console.log(error, "hata");
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetAllTeams();
  }, []);

  useEffect(() => {
    fetchGetSelectedTeamPlayers(id);
    setIsDataChange(false);
  }, [isDataChange]);
  return (
    <>
      <p className="pt-4 pb-8 text-2xl font-semibold text-secondary">
        Oyuncu Bilgileri
      </p>
      <div className="">
        <TabbarComponent
          data={[
            { title: "Oyuncular Listesi" }
            // {title: "Catapult Verileri"},
            // {title: "Bireysel Analiz"},
          ]}
          type={"card"}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
        />

        <DataTable
          columns={playersColumns}
          data={selectedMatchPlayers}
          loading={loading}
        />
      </div>
      <div className="flex items-center justify-end gap-4 py-4">
        <ButtonComponent
          onClick={() => setOpenPlayerModal(true)}
          fill
          loading={matchData?.isFinish ? true : false}
          disabled={loading}
          text="Oyuncu Seç"
          customStyle={"w-fit"}
        />
      </div>
      <CustomModal
        width={677}
        isOpen={openPlayerModal}
        setOpen={setOpenPlayerModal}
        footer={null}
      >
        <CustomModalHeader
          title={"Oyuncu seç"}
          description={"Aşağıdan oyuncuyu seçebilirsiniz."}
        />
        <div className="mt-4">
          <div className="flex w-full gap-4 py-4">
            <div className="flex flex-col w-full">
              <DynamicInput
                labelTitle={"Takımlar"}
                containerStyle={"w-full"}
                placeholder={"Takım Seç"}
                name={""}
                onChange={(e) => {
                  fetchGetTeamPlayers(e.value);
                  setSelectedTeamId(e.value);
                }}
                component_type={"select"}
                disabled={allTeamsOptions <= 0}
                customOptions={allTeamsOptions ?? []}
                error={""}
                isTouched={""}
                onBlur={""}
              />
            </div>
          </div>
          <div className="">
            <DataTable
              selection={{ rowSelectionList, setRowSelectionList }}
              columns={[
                {
                  title: "Oyuncu Adı Soyadı",
                  dataIndex: "nameAndSurname"
                }
              ]}
              data={teamPlayersDataTable ?? []}
              loading={loading}
            />
            <div className="flex items-center justify-end gap-4 pt-4">
              <ButtonComponent
                onClick={() => setOpenPlayerModal(false)}
                outline
                text={"Vazgeç"}
              />
              <ButtonComponent
                onClick={() => handleSubmitTeamPlayer()}
                fill
                loading={loading}
                text={"Kaydet"}
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default PlayerInfos;
