import React, { useState } from "react";
import Table1 from "./table1";
import TabbarComponent from "../../components/tabbar";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import { PasCalismasiPdf } from "../../utils/filePath";

function TrainingCalendar() {
  //modal
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  //modal

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    handleSave();
  };

  const handleSave = () => {
    const content = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    console.log(content);
    // Burada içeriği kaydedebilir veya başka bir işlem yapabilirsiniz.
  };
  function PlusButton() {
    return (
      <p className="flex items-center justify-center w-6 h-6 text-white rounded cursor-pointer bg-primary">
        +
      </p>
    );
  }
  const [selectedTabId, setSelectedTabId] = useState(0);

  function EmptyRow() {
    return (
      <div
        onClick={showModal}
        className="flex items-center h-6 text-xs cursor-pointer hover:bg-gray-100 group"
      >
        <div className="items-center hidden gap-2 group-hover:flex">
          <PlusButton />
          <p className=" text-primary">Satır Ekle</p>
        </div>
      </div>
    );
  }
  return (
    <>
      <Modal
        width={1000}
        open={open}
        title="Madde Ekle"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            danger
            type="default"
            loading={loading}
            onClick={handleCancel}
          >
            İptal
          </Button>,
          <Button
            href={null}
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Kaydet
          </Button>,
        ]}
      >
        <div className="border">
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
          />
        </div>
      </Modal>
      <div className="flex gap-4">
        <TabbarComponent
          type="card"
          setSelectedTabId={setSelectedTabId}
          selectedTabId={selectedTabId}
          data={[{ title: "Şablon 1" }, { title: "Şablon 2" }]}
        />
      </div>
      <div className="w-full p-4 bg-white rounded-md shadow">
        {selectedTabId == 0 && (
          <div className="overflow-x-auto">
            <Table1 />
          </div>
        )}
        {selectedTabId == 1 && (
          <>
            <div className="overflow-x-auto">
              <div className="grid grid-cols-6 w-[2000px]">
                {[...Array(3)].map((item, index) => {
                  return (
                    <>
                      <div className="flex flex-col [&>*]:border [&>*]:p-2">
                        <p className="font-semibold text-center text-primary">
                          Maç +2
                        </p>
                        <p className="font-semibold text-center text-primary">
                          MD - G2
                        </p>
                        <p className="italic font-semibold text-center">
                          Antrenman Önü
                        </p>
                        <div className="text-white bg-primary">
                          <p className="font-semibold text-start">
                            Kuvvet Çalışması - (30')
                          </p>
                          <p className="font-semibold text-start">Kod:</p>
                        </div>
                        {/* bölümler */}
                        <div className="flex flex-col gap-1">
                          <p className="font-semibold bg-gray-100 text-primary">
                            Bölüm - 1
                          </p>
                          <Link to={PasCalismasiPdf} target={"_blank"} className="text-sm font-medium text-primary hover:underline">
                            Pas Çalışması (25')
                          </Link>
                          <p className="text-xs font-semibold">Kod:</p>
                          <p className="text-xs">2 gurup halinde</p>
                          <p className="text-xs">pas mesafesi en az 10-15m</p>

                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />

                          {/* <PlusButton /> */}
                        </div>
                        {/* bölümler */}
                        {/* bölümler */}
                        <div className="flex flex-col gap-1">
                          <p className="font-semibold bg-gray-100 text-primary">
                            Bölüm - 2
                          </p>
                          <p className="text-sm font-medium text-primary">
                            4v4+K / 4v4+j+K / 5v5+K / 5v5+j+K
                          </p>
                          <p className="text-sm font-medium text-primary">
                            5v5v5+j+K / 6v6v6+K / 7v7v7+K vb…
                          </p>
                          <p className="text-xs font-semibold">Kod:</p>
                          <p className="text-xs">4x4' / SAD 2'</p>
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                        </div>
                        {/* bölümler */}
                        {/* bölümler */}
                        <div className="flex flex-col gap-1">
                          <p className="font-semibold bg-gray-100 text-primary">
                            Bölüm - 3
                          </p>
                          <p className="text-sm font-medium text-primary">
                            Mevkisel Özel Çalışma (30')
                          </p>
                          <p className="text-xs font-semibold">Kod:</p>

                          <p className="text-xs">
                            Doruk - Tuna - Kuzey - Arif - Hüseyin
                          </p>
                          <p className="text-xs">Ali - Yusuf - İrfan</p>

                          <p className="text-xs font-semibold">Kod:</p>

                          <p className="text-xs">
                            Doruk - Tuna - Kuzey - Arif - Hüseyin
                          </p>
                          <p className="text-xs">Ali - Yusuf - İrfan</p>

                          <p className="text-xs font-semibold">Kod:</p>

                          <p className="text-xs">
                            Doruk - Tuna - Kuzey - Arif - Hüseyin
                          </p>
                          <p className="text-xs">Ali - Yusuf - İrfan</p>
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                        </div>
                        {/* bölümler */}
                      </div>

                      {/* col-2 */}
                      <div className="flex flex-col [&>*]:border [&>*]:p-2">
                        <p className="font-semibold text-center text-primary">
                          Maç +3
                        </p>
                        <p className="font-semibold text-center text-primary">
                          MD - G3
                        </p>
                        <p className="italic font-semibold text-center">
                          Antrenman Önü
                        </p>
                        <div className="text-white bg-primary">
                          <p className="font-semibold text-start">
                            Kuvvet Çalışması - (30')
                          </p>
                          <p className="font-semibold text-start">Kod:</p>
                        </div>
                        {/* bölümler */}
                        <div className="flex flex-col gap-1">
                          <p className="font-semibold bg-gray-100 text-primary">
                            Bölüm - 1
                          </p>
                          <p className="text-sm font-medium text-primary">
                            Akselerasyon (20')
                          </p>
                          <p className="text-xs">10 x 60m x %60</p>
                          <p className="text-xs">TAD: 1'</p>

                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />

                          {/* <PlusButton /> */}
                        </div>
                        {/* bölümler */}
                        {/* bölümler */}
                        <div className="flex flex-col gap-1">
                          <p className="font-semibold bg-gray-100 text-primary">
                            Bölüm - 2
                          </p>
                          <p className="text-sm font-medium text-primary">
                            Taç, Korner, S.Vuruş, Aut, Penaltı
                          </p>
                          <p className="text-sm font-medium text-primary">
                            Standart Durumlar (30')
                          </p>

                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                          <EmptyRow />
                        </div>
                        {/* bölümler */}
                        {/* bölümler */}
                        <div className="flex flex-col gap-1">
                          <p className="font-semibold bg-gray-100 text-primary">
                            Bölüm - 3
                          </p>
                          <EmptyRow />
                          <p className="text-sm font-medium text-primary">
                            2v2+K / 3v2+j+K / 3v3+K / 3v3+j+K
                          </p>
                          <p className="text-sm font-medium text-primary">
                            4v3+K / 4v4+j+K / 5v4+K / 5v5+K vb…
                          </p>

                          <p className="text-sm font-medium text-blue-500">
                            PB + PO + Taktik (20')
                          </p>

                          <p className="text-xs font-semibold">Kod:</p>
                          <p className="text-xs font-semibold">3x6' / SAD:2'</p>

                          <EmptyRow />
                          <EmptyRow />
                          <p className="text-sm font-medium text-blue-500">
                            Oyun (20')
                          </p>

                          <p className="text-xs font-semibold">Kod:</p>
                          <p className="text-xs font-semibold">3x6' / SAD:2'</p>
                        </div>
                        {/* bölümler */}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default TrainingCalendar;
