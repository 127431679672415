import React, { useEffect } from "react";
import { Table } from "antd";
import { useLocation } from "react-router-dom";

const DataTable = ({
  columns,
  data,
  selection,
  loading,
  rowBgColor,
  columnsColor,
  bordered,
  checkboxDisable,
  rowSelectionList,
  setRowSelectionList,
}) => {
  const location = useLocation();
  const getPageSize = () => {
    return location.pathname == "/users/players" ||
      location.pathname == "/coaches" ||
      location.pathname == "/employees"
      ? 100
      : 10;
  };
  let tableProps = {};
  bordered = bordered === undefined ? true : bordered;
  const rowClassName = (record) => {
    return record.rowBgColor ? "text-green-800 " : "text-yellow-300  ";
  };

  if (selection) {
    const { rowSelectionList, setRowSelectionList } = selection;

    let selectedRowKeys = [];
    if (rowSelectionList?.selectedRowItems !== undefined) {
      //default seçili gelmesi gerekn row var ise seçili hale getirir
      selectedRowKeys = [
        ...rowSelectionList?.selectedRowItems.map((item) => item.key),
      ];
    }

    const selectFunc = {
      getCheckboxProps: (record) => (
        console.log(record?.disabled, "record"),
        {
          disabled: record?.disabled,
        }
      ),
      rowSelectionList,
      onChange: (selectedRowKeys, selectedRowItems) => {
        setRowSelectionList({
          ...rowSelectionList,
          selectedRowItems,
        });
      },
      selectedRowKeys,
    };

    tableProps = {
      bordered: true,
      size: "large",
      loading,
      rowSelection: selectFunc,
      columns,
      dataSource: data ? data : [],
    };
  } else {
    tableProps = {
      bordered: bordered,
      size: "small",
      loading,
      columns,
      dataSource: data ? data : [],
    };
  }

  return (
    <Table
      {...tableProps}
      rowClassName={rowBgColor ? rowClassName : ""}
      scroll={{ x: "max-content" }}
      pagination={{ pageSize: getPageSize() }}
    />
  );
};

export default DataTable;
