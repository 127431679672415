import axios from "axios";
import { store } from "../redux/ConfigureStore";
import { unitDefinitionsActions } from "../redux/features/unitDefinitions/unitDefinitions-slice";
import Swal from "sweetalert2";
import { CgLayoutGridSmall } from "react-icons/cg";

const savedBaseUrl = localStorage.getItem("baseUrlLocal");

// Base URL değerini ayarla
//  const baseURL =
//     savedBaseUrl == "https://altinordu-api.golpro.com.tr"
//              ? "https://altinordu-api.golpro.com.tr/public/api"
//          : savedBaseUrl == "https://backend.afk.net.tr"
//              ? "https://backend.afk.net.tr/api"
//              : "https://altinordu-api.golpro.com.tr/public/api"; // local

// const baseURL = "https://altinordu-api.golpro.com.tr/public/api";
// export const baseImageURL = "https://altinordu-api.golpro.com.tr/public/";

// const baseURL = "http://127.0.0.1:8000/api";
export const baseURL = "https://altinordu-api.golpro.com.tr/public/api";
//  const baseURL = "https://altinordu.golpro.com.tr/public/api"

// export const baseImageURL = "http://localhost/altinordu-backend-app/public/api";

// export const baseImageURL =
//   savedBaseUrl == "https://altinordu-api.golpro.com.tr"
//     ? "https://altinordu-api.golpro.com.tr/public/"
//     : savedBaseUrl == "https://backend.afk.net.tr"
//     ? "https://backend.afk.net.tr/"
//     : "https://altinordu-api.golpro.com.tr/public/"; // local
// const baseURL = "https://backend.afk.net.tr/api"; //test
// export const baseImageURL = "https://backend.afk.net.tr/"; //test

// const baseURL = "https://altinordu-api.golpro.com.tr/public/api"; //live
export const baseImageURL = "https://altinordu-api.golpro.com.tr/public/"; //live

//
//
// Login işlemini gerçekleştiren fonksiyon
export const loginRequest = async (tckn, password) => {
  try {
    const response = await axios.post(`${baseURL}/auth/login`, {
      tckn,
      password,
    });

    // Gelen bearer token'ı tarayıcıda tut
    const bearerToken = response.data.token;

    localStorage.setItem("bearerToken", bearerToken);
    window.location.reload();
    // İşlemin başarılı olduğunu bildir
    return { success: true, message: response.data.message };
  } catch (error) {
    // Hata durumunda hata mesajını döndür
    return { success: false, message: error.response.data.message };
  }
};

// Bearer token'ı localStorage'dan getirme
export const getStoredBearerToken = localStorage?.getItem("bearerToken");

// Bearer token'ı localStorage'dan silme
export const removeStoredToken = () => {
  localStorage.removeItem("bearerToken");
  window.location.reload();
};
//
//
// Logout işlemini gerçekleştiren fonksiyon
export const logoutRequest = async () => {
  try {
    // localStorage veya localStorage'daki token bilgisini alabilirsiniz.
    // Örnek olarak localStorage kullanalım:

    const response = await axios.post(`${baseURL}/auth/logout`, null, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    //Token sil
    removeStoredToken();

    // İşlemin başarılı olduğunu bildir
    return { success: true, message: response.data.message };
  } catch (error) {
    //Token sil
    removeStoredToken();

    // Hata durumunda hata mesajını döndür
    return { success: false, message: error.response.data.message };
  }
};

// form getirme işlemini gerçekleştiren fonksiyon
export const getDynamicForm = async (formName) => {
  try {
    const response = await axios.get(`${baseURL}/form/get/${formName}`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    console.log("resppppp12312", response);
    return { success: true, response: response.data };
  } catch (error) {
    return error;
  }
};

export const getFamilyMartialStatus = async (formName) => {
  try {
    const response = await axios.get(
      `${baseURL}/form/add-parent-info/${formName}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, response: response.data };
  } catch (error) {
    return error;
  }
};

//
//
// form getirme işlemini gerçekleştiren fonksiyon
export const getDynamicFormGroup = async (groupName) => {
  // console.log(groupName);
  try {
    const response = await axios.get(
      `${baseURL}/form/get-grouped-form/${groupName}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, response: response.data.forms };
  } catch (error) {
    return error;
  }
};
//
//
// forma input ekleme işlemini gerçekleştiren fonksiyon
export const createForm = async (name, form_type, options) => {
  try {
    const response = await axios.post(
      `${baseURL}/form/create`,
      {
        name,
        form_type: "User",
        options: {
          action: "action",
          method: "method",
          id: "id",
          name: "deneme",
          onSubmit: "onSubmit",
          encypt: "encypt",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message };
  }
};
//
//
// forma input ekleme işlemini gerçekleştiren fonksiyon
export const createDynamicFormElement = async (options, form_id) => {
  try {
    const response = await axios.post(
      `${baseURL}/form/elements/create`,
      {
        options,
        form_id,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    // İşlemin başarılı olduğunu bildir
    return { success: true, message: response.data.message };
  } catch (error) {
    // Hata durumunda hata mesajını döndür
    return { success: false, message: error.response.data.message };
  }
};
//
//
// kullanıcıya metas ekleme işlemini gerçekleştiren fonksiyon
export const addUserMetasz = async (user_id, form_id, elements) => {
  // console.log(`${baseURL}/user/add-user-metas/${user_id}/${form_id}`, "url");
  try {
    const response = await axios.post(
      `${baseURL}/user/update/${user_id}`,

      { elements: elements, form_name: form_id },

      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    console.log(elements, "elements");
    console.log(error, "error");
    return { success: false, data: error.response.data };
  }
};
//
//
// kullanıcı ekleme işlemini gerçekleştiren fonksiyon
export const addUser = async (
  imageurl,
  user_code,
  name,
  surname,
  email,
  password,
  password_confirmation,
  tckn,
  phone,
  status,
  role
) => {
  try {
    const response = await axios.post(
      `${baseURL}/user/add`,
      {
        imageurl,
        user_code,
        name,
        surname,
        email,
        password,
        password_confirmation,
        tckn,
        phone,
        status,
        role,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// kullanıcı güncelleme işlemini gerçekleştiren fonksiyon
export const updateUser = async (
  id,
  imageurl,
  user_code,
  name,
  surname,
  email,
  password,
  password_confirmation,
  tckn,
  phone,
  status,
  role,
  fkCity,
  fkDistrict,
  position,
  mother_name
) => {
  try {
    const response = await axios.post(
      `${baseURL}/user/update/${id}`,
      {
        imageurl,
        user_code,
        name,
        surname,
        email,
        password,
        password_confirmation,
        tckn,
        phone,
        status,
        role,
        fkCity,
        fkDistrict,
        position,
        mother_name,

        // user_code: 1,
        // user_name: "89350906213",
        // email: "zafer@gmail.com",
        // role: 1
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // console.log('yanıt', response);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// tüm selectleri getirme işlemini gerçekleştiren fonksiyon
export const getSelects = async () => {
  try {
    const response = await axios.get(`${baseURL}/form/select/get-selects`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    console.log("resp", response);
    return { success: true, response: response.data.select_lists };
  } catch (error) {
    return error;
  }
};
//
//
// select ekleme işlemini gerçekleştiren fonksiyon
export const addSelect = async (title) => {
  try {
    const response = await axios.post(
      `${baseURL}/form/select/add-select`,
      {
        title,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// option ekleme işlemini gerçekleştiren fonksiyon
export const addOption = async (label, value, select_list_id) => {
  try {
    const response = await axios.post(
      `${baseURL}/form/select/options/add-option`,
      {
        label,
        value,
        select_list_id,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// option güncelleme işlemini gerçekleştiren fonksiyon
export const updateOption = async (label, value, select_list_id, option_id) => {
  try {
    const response = await axios.post(
      `${baseURL}/form/select/options/update-option/${option_id}`,
      {
        label,
        value,
        select_list_id,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// option silme işlemi
export const deleteOption = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/form/select/options/delete-option/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// saha ekleme işlemini gerçekleştiren fonksiyon
export const createFootballGround = async (
  title,
  address,
  code,
  url,
  is_active,
  football_ground_type,
  rival_field
) => {
  try {
    const response = await axios.post(
      `${baseURL}/football-ground/create-football-ground`,
      {
        title,
        address,
        code,
        url,
        is_active,
        football_ground_type,
        rival_field,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// saha getirme işlemini gerçekleştiren fonksiyon
export const getFootballGround = async (isActive) => {
  try {
    let url = null;
    if (isActive) {
      url = `${baseURL}/football-ground/get-football-grounds`;
    } else {
      url = `${baseURL}/football-ground/get-passive-football-grounds`;
    }
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.message };
  }
};
export const getFacilitiess = async () => {
  let facURL = `${baseURL}/football-ground/facilities`;
  try {
    const response = await axios.get(facURL, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return response.data;
  } catch (error) {
    return { success: false, data: error.response.message };
  }
};
//
//
// saha güncelleme işlemini gerçekleştiren fonksiyon
export const updateFootballGround = async (
  update_id,
  title,
  address,
  code,
  url,
  is_active,
  football_ground_type,
  rival_field
) => {
  try {
    const response = await axios.post(
      `${baseURL}/football-ground/update-football-ground/${update_id}`,
      {
        title,
        address,
        code,
        url,
        is_active,
        football_ground_type,
        rival_field,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// saha silme işlemi
export const deleteFootballGround = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/football-ground/delete-football-ground/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// tüm sezonları getir
export const getSeasons = async () => {
  try {
    const response = await axios.get(`${baseURL}/seasons/get-seasons`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// sezon ekleme işlemini gerçekleştiren fonksiyon
export const createSeason = async (
  season_code,
  season_title,
  start_date,
  end_date,
  is_active
) => {
  try {
    const response = await axios.post(
      `${baseURL}/seasons/create-season`,
      { season_code, season_title, start_date, end_date, is_active },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// sezon güncelleme işlemini gerçekleştiren fonksiyon
export const updateSeason = async (
  update_id,
  season_code,
  season_title,
  start_date,
  end_date,
  is_active
) => {
  try {
    const response = await axios.post(
      `${baseURL}/seasons/update-season/${update_id}`,
      { season_code, season_title, start_date, end_date, is_active },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// sezon silme işlemini gerçekleştiren fonksiyon
export const deleteSeason = async (
  id,
  season_code,
  season_title,
  is_active
) => {
  try {
    const response = await axios.delete(
      `${baseURL}/seasons/delete-season/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// all roles getirme işlemini gerçekleştiren fonksiyon
export const getAllRoles = async () => {
  try {
    const response = await axios.get(`${baseURL}/roles/get-all`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, data: response.data.roles };
  } catch (error) {
    return { success: false, data: error.response.data.roles };
  }
};
// all user getirme işlemini gerçekleştiren fonksiyon
// backend yasin

export const getAllUser = async (role, checkStatus, filters) => {
  const roleValue = role ?? "";

  const localParams = { role: roleValue, checkStatus: "" };

  if (checkStatus.value) {
    localParams['status'] = checkStatus.value
  }
  if (filters) {
    for (const key in filters) {
      if (filters[key]) {
        localParams[key] = filters[key].value;
      }
    }
  }
  try {
    const response = await axios.get(`${baseURL}/user/all?role=${roleValue}`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: localParams
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
// export const getAllUser = async (role, checkStatus, filters) => {
//   const roleValue = role ?? "";
//   try {
//     const params = { role: roleValue, checkStatus: "" };

//     if (checkStatus.value) {
//       params['status'] = checkStatus.value
//     }

//     // Add filters to params if they exist
//     if (filters) {
//       for (const key in filters) {
//         if (filters[key]) {
//           params[key] = filters[key].value;
//         }
//       }
//     }

//     const response = await axios.get(`${baseURL}/user/all`, {
//       headers: {
//         Authorization: `Bearer ${getStoredBearerToken()}`,
//       },
//       params : {...params},
//     });

//     return { success: true, data: response.data };
//   } catch (error) {
//     return { success: false, data: error.response.data };
//   }
// };

//
//
// user getirme işlemini gerçekleştiren fonksiyon
export const getUser = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/user/get/${id}`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// user getirme işlemini gerçekleştiren fonksiyon
export const getAuthUser = async () => {
  try {
    const response = await axios.get(`${baseURL}/user/get-auth-user`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
//managment type tümünü getirir
export const getAllManagmentTypes = async () => {
  try {
    const response = await axios.get(`${baseURL}/seasons/get-managment-types`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
//season managment ekleme
export const createSeasonManagment = async (
  season_id,
  user_id,
  role_id,
  managment_type,
  start_date,
  end_date,
  notes,
  type
) => {
  try {
    const response = await axios.post(
      `${baseURL}/seasons/create-season-management`,
      {
        season_id,
        user_id,
        role_id,
        managment_type,
        start_date,
        end_date,
        notes,
        type,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const createCandidate = async (
  name,
  surname,
  tckn,
  email,
  watchMatch,
  watchDate,
  monitoredTournament,
  notes,
  watchStatus,
  fkFollower,
  fkTeam,
  position,
  fkFoodUsed,
  birthDate,
  clubId,
  cityId,
  fkRegion,
  recommendedPosition,
  height,
  weight,
  trainingAge,
  fkMonitoredCompetitionDifficulty
) => {
  try {
    const response = await axios.post(
      `${baseURL}/candidate/create-candidate`,
      {
        name,
        surname,
        tckn,
        email,
        watchMatch,
        watchDate,
        monitoredTournament,
        notes,
        watchStatus,
        fkFollower,
        fkTeam,
        position,
        fkFoodUsed,

        birthDate,
        clubId,
        cityId,
        fkRegion,
        recommendedPosition,
        height,
        weight,
        trainingAge,
        fkMonitoredCompetitionDifficulty,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

//
//
// sezon managment silme işlemi
export const deleteSeasonManagement = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/seasons/delete-season-management/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const deleteLeague = async (id) => {
  try {
    const response = await axios.delete(`${baseURL}/leagues/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// takım listeleme işlemi
export const listTeams = async () => {
  try {
    const response = await axios.get(`${baseURL}/teams/get-all-teams`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// takıma oyuncu ekleme
export const addPlayerToTeam = async (team, player) => {
  try {
    const response = await axios.post(
      `${baseURL}/teams/add-player-to-team`,
      {
        team,
        player,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

//
// takım oyuncu silme işlemi
export const deletePlayerFromTeam = async (teamId, playerId) => {
  try {
    const response = await axios.delete(
      `${baseURL}/teams/delete-player-from-team/${teamId}/${playerId}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const deletePlayersInfoPlayer = async (matchId, playerId) => {
  try {
    const response = await axios.delete(
      `${baseURL}/matches/delete-matches-players/${matchId}/${playerId}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

//
//
// takım ekleme işlemi
export const createTeams = async (
  team_title,
  team_category,
  leagueIds,
  seasonId
) => {
  try {
    const response = await axios.post(
      `${baseURL}/teams/create-teams`,
      {
        team_title,
        team_category,
        leagueIds,
        seasonId,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// takım güncelleme işlemi
export const updateTeams = async (
  id,
  team_title,
  team_category,
  leagueIds,
  playerIds,
  seasonId
) => {
  try {
    const response = await axios.post(
      `${baseURL}/teams/update-teams/${id}`,
      {
        team_title,
        team_category,
        leagueIds,
        playerIds,
        seasonId,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// takım silme işlemi
export const deleteTeam = async (id) => {
  try {
    const response = await axios.delete(`${baseURL}/teams/delete-teams/${id}`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// takım kategori listeleme
export const getAllTeamCategory = async () => {
  try {
    const response = await axios.get(`${baseURL}/teams/get-all-team-category`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// takım kategori ekleme işlemi
export const createTeamCategory = async (category_title) => {
  try {
    const response = await axios.post(
      `${baseURL}/teams/create-team-category`,
      {
        category_title,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// takım kategori ekleme işlemi
export const updateTeamCategory = async (id, category_title) => {
  try {
    const response = await axios.post(
      `${baseURL}/teams/update-team-category/${id}`,
      {
        category_title,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// takım kategori silme işlemi
export const deleteTeamCategory = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/teams/delete-team-category/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// kulüp listeleme işlemi
export const getAllClub = async () => {
  try {
    const response = await axios.get(`${baseURL}/club/get-all-club`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    // console.log(response, "response");
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
//  kulüp ekleme
export const createClub = async (image, club_name, short_code) => {
  try {
    const response = await axios.post(
      `${baseURL}/club/create-club`,
      {
        image,
        club_name,
        short_code,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
//  kulüp güncelleme
export const updateClub = async (id, image, club_name, short_code) => {
  try {
    const response = await axios.post(
      `${baseURL}/club/update-club/${id}`,
      {
        image,
        club_name,
        short_code,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
// excel file yükleme
export const uploadExcelFile = async (file) => {
  try {
    // Create FormData object
    const formData = new FormData();
    formData.append("excel", file[0]);

    const response = await axios.post(`${baseURL}/user/add-excel`, formData, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// kulüp silme işlemi
export const deleteClub = async (id) => {
  try {
    const response = await axios.delete(`${baseURL}/club/delete-club/${id}`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
//  hakem ekleme
export const createJudge = async (name, surname) => {
  try {
    const response = await axios.post(
      `${baseURL}/judge/add-judge`,
      {
        name,
        surname,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
//  hakem güncelleme
export const updateJudge = async (id, name, surname) => {
  try {
    const response = await axios.post(
      `${baseURL}/judge/update-judge/${id}`,
      {
        name,
        surname,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// kulüp silme işlemi
export const deleteJudge = async (id) => {
  try {
    const response = await axios.delete(`${baseURL}/judge/delete-judge/${id}`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// kulüp getirme
export const getAllJudges = async () => {
  try {
    const response = await axios.get(`${baseURL}/judge/get-judges`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    // console.log(response, "response");
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// maç ekle
export const createMatches = async (
  home_team,
  guest_team,
  home_team_score,
  guest_team_score,
  season,
  week,
  match_date,
  coach_comment,
  goal_keeper_coach_comment,
  matches_type,
  match_dificulty
) => {
  try {
    const response = await axios.post(
      `${baseURL}/matches/create-matches`,
      {
        home_team,
        guest_team,
        home_team_score,
        guest_team_score,
        season,
        week,
        match_date,
        coach_comment,
        goal_keeper_coach_comment,
        matches_type,
        match_dificulty,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
export const updateMatches = async (
  id,
  home_team,
  guest_team,
  home_team_score,
  guest_team_score,
  season,
  week,
  match_date,
  coach_comment,
  goal_keeper_coach_comment,
  matches_type,
  match_dificulty
) => {
  try {
    const response = await axios.post(
      `${baseURL}/matches/update-matches/${id}`,
      {
        home_team,
        guest_team,
        home_team_score,
        guest_team_score,
        season,
        week,
        match_date,
        coach_comment,
        goal_keeper_coach_comment,
        matches_type,
        match_dificulty,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// tüm maç listele
export const getAllMatches = async () => {
  try {
    const response = await axios.get(`${baseURL}/matches/get-all-matches`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// tek maç listele
export const getMatch = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/matches/get-matches/${id}`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error };
  }
};
//
//
// maç silme işlemi
export const deleteMatch = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/matches/delete-matches/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// maça oyuncu ekleme
export const addMatchesPlayers = async (matchId, players) => {
  try {
    const response = await axios.post(
      `${baseURL}/matches/add-matches-players/${matchId}`,
      {
        players,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
export const getMatchPlayers = async (matchId) => {
  try {
    const response = await axios.get(
      `${baseURL}/matches/get-matches-players/${matchId}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// maç kriter tiplerini getirir
export const getCriteriaTypes = async () => {
  try {
    const response = await axios.get(`${baseURL}/matches/criteria-types`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// maç kriterlerini getirir
export const getMatchCriterias = async () => {
  try {
    const response = await axios.get(`${baseURL}/matches/match-criterias`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// maç kriteri ekleme
export const createMatchCriterias = async (
  match,
  criteria_type,
  player_one_id,
  player_two_id,
  time,
  half,
  team_id,
  isMainTeam
) => {
  try {
    const response = await axios.post(
      `${baseURL}/matches/match-criterias`,
      {
        match,
        criteria_type,
        player_one_id,
        player_two_id,
        time,
        half,
        team_id,
        isMainTeam,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// tüm faaliyet kategorileri getirme

export const getActivitiesCategories = async (categoryId) => {
  try {
    const response = await axios.get(
      `${baseURL}/activities/categories/get-all-categories`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
        params: {
          categoryId: categoryId,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// tek faaliyet kategori getirme
export const getActivitiesCategory = async (id) => {
  try {
    const response = await axios.get(
      `${baseURL}/activities/categories/get-category/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// aktivite ekleme işlemini gerçekleştiren fonksiyon
export const createActivityCategory = async (
  activity_field,
  title,
  up_category,
  type_field,
  isAddMainTime
) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/categories/create-activity-category`,
      {
        activity_field,
        title,
        up_category,
        type_field,
        isAddMainTime,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// aktivite güncelleme işlemini gerçekleştiren fonksiyon
export const updateActivityCategory = async (
  id,
  activity_field,
  title,
  up_category,
  is_up_category,
  type_field,
  isAddMainTime
) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/categories/update-activity-category/${id}`,
      {
        activity_field,
        title,
        up_category,
        is_up_category,
        type_field,
        isAddMainTime,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// option silme işlemi
export const deleteActivityCategory = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/activities/categories/delete-activity-category/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    store.dispatch(
      unitDefinitionsActions.updateState({ allUnits: response?.data?.units })
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tüm Unitleri getirme
export const getAllUnits = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/activities/unit/get-all-units`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    store.dispatch(
      unitDefinitionsActions.updateState({ allUnits: response?.data?.units })
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
// unit silme işlemi
export const deleteUnit = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/activities/unit/delete-activity-unit/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
// unit güncelleme işlemi
export const updateUnit = async (id, title, short_title, type) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/unit/update-activity-unit/${id}`,
      {
        id,
        title,
        short_title,
        type,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
// unit ekleme işlemi
export const createUnit = async (title, short_title, type, referance) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/unit/create-activity-unit`,
      {
        referance,
        title,
        short_title,
        type,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// tüm areas getir
export const getAllAreas = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/activities/areas/get-all-areas`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//
//
// areas ekleme
export const createActivityArea = async (title) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/areas/create-activity-area`,
      {
        title,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// areas güncelleme
export const updateActivityArea = async (id, title) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/areas/update-activity-area/${id}`,
      {
        id,
        title,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
//
//
// areas silme işlemi
export const deleteActivityArea = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/activities/areas/delete-activity-area/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tüm Drill getirme
export const getAllTests = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/activities/test/get-all-tests`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log("objecresponseresponse:t: ", response.data.tests);

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// unit güncelleme işlemi
export const createTest = async (
  title,
  test_type,
  test_group_type,
  code,
  unit,
  teams_unit,
  is_active,
  up_category,
  is_up_category
) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/test/create-activity-test`,
      {
        title,
        test_type,
        test_group_type,
        code,
        unit,
        teams_unit,
        is_active,
        up_category,
        is_up_category,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// unit güncelleme işlemi
export const updateTest = async (
  id,
  title,
  test_type,
  test_group_type,
  code,
  unit,
  teams_unit,
  is_active,
  up_category,
  is_up_category
) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/test/update-activity-test/${id}`,
      {
        title,
        test_type,
        test_group_type,
        code,
        unit,
        teams_unit,
        is_active,
        up_category,
        is_up_category,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
// unit güncelleme işlemi
export const getTestById = async (id) => {
  try {
    const response = await axios.get(
      `${baseURL}/activities/test/get-test/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// Test silme işlemi
export const deleteTest = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/activities/test/delete-activity-test/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
// tüm Drill getirme
export const getAllDrills = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/activities/drills/get-all-drills`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
export const getDrillById = async (id) => {
  // console.log("şkjkjhskjhdjl: ", id);
  try {
    const response = await axios.get(
      `${baseURL}/activities/drills/get-drill/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// unit Drill işlemi
export const createDrill = async (
  title,
  description,
  image,
  up_category,
  is_up_category
) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/drills/create-activity-drill`,
      {
        title,
        description,
        image,
        up_category,
        is_up_category,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// Drill güncelleme işlemi
export const updateDrill = async (
  id,
  title,
  description,
  image,
  up_category,
  is_up_category
) => {
  // console.log("objectobjectobject: ", {
  //     id,
  //     title,
  //     description,
  //     image,
  //     up_category,
  //     is_up_category
  // });
  try {
    const response = await axios.post(
      `${baseURL}/activities/drills/update-activity-drill/${id}`,
      {
        title,
        description,
        image,
        up_category,
        is_up_category,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// Drill silme işlemi
export const deleteDrill = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/activities/drills/delete-activity-drill/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// faaliyet oluşturma işlemi
export const createActivity = async (form) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/create-activity`,
      form,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

//
//
// faaliyet güncelleme işlemini gerçekleştiren fonksiyon
export const updateActivity = async (
  id,
  form
  // activity_type,
  // start_date,
  // status,
  // start_time,
  // description,
  // activity_group,
  // managers,
  // players,
  // drills,
  // activities,
  // team_category,
  // activity_category,
  // is_approved,
  // football_ground_id,
  // other_players
) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/update-activity/${id}`,
      form,
      // {
      //     activity_type,
      //     start_date,
      //     status,
      //     start_time,
      //     // ozel alanlar
      //     description,
      //     activity_group,
      //     managers,
      //     players,
      //     drills,
      //     activities,
      //     team_category,
      //     activity_category,
      //     is_approved,
      //     football_ground_id,
      //     other_players
      // },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// faaliyet test oluşturma işlemi
export const createActivityTest = async (
  title,
  activity_type,
  start_date,
  end_date,
  status,
  start_time,
  end_time,
  // ozel alanlar
  description,
  weather,
  activity_area,
  managers,
  players,
  notes,
  tests,
  color,
  team_category
) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/create-activity`,
      {
        title,
        activity_type,
        start_date,
        end_date,
        status,
        start_time,
        end_time,
        // ozel alanlar
        description,
        weather,
        activity_area,
        managers,
        players,
        notes,
        tests,
        color,
        team_category,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// faaliyet test oluşturma işlemi
export const updateActivityTest = async (
  id,
  title,
  activity_type,
  start_date,
  end_date,
  status,
  start_time,
  end_time,
  // ozel alanlar
  description,
  weather,
  activity_area,
  managers,
  players,
  notes,
  tests,
  color,
  team_category
) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/update-activity/${id}`,
      {
        title,
        activity_type,
        start_date,
        end_date,
        status,
        start_time,
        end_time,
        // ozel alanlar
        description,
        weather,
        activity_area,
        managers,
        players,
        notes,
        tests,
        color,
        team_category,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tüm onaylı faaliyet getirme
export const getApprovedActivities = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/activities/get-approved-activities`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tüm onaysız faaliyet getirme
export const getUnapprovedActivities = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/activities/get-unapproved-activities`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tüm faaliyet getirme
export const getAllActivities = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/activities/get-all-activities`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// faaliyet getirme
export const getActivity = async (id) => {
  try {
    const response = await axios.get(
      `${baseURL}/activities/get-activity/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// faaliyet silme işlemi
export const deleteActivity = async (id) => {
  console.log("axiosID", id);
  try {
    const response = await axios.delete(
      `${baseURL}/activities/delete-activity/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// faaliyet onaylama işlemi
export const approveActivity = async (id, is_approved) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/approve-activity/${id}`,
      { is_approved },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// faaliyet test oluşturma işlemi
export const createActivityMatch = async (
  title,
  activity_type,
  start_date,
  start_time,
  type,
  season,
  league,
  week,
  difficulty,
  home_team,
  guest_team,
  small_team,
  football_ground_id,
  field,
  duration,
  half,
  number_of_player,
  activity_category_id,
  guest_team_category_id
) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/create-activity`,
      {
        title,
        activity_type,
        start_date,
        start_time,
        type,
        season,
        league,
        week,
        difficulty,
        home_team,
        guest_team,
        small_team,
        football_ground_id,
        field,
        duration,
        half,
        number_of_player,
        activity_category_id,
        guest_team_category_id
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi kontrol oluşturma
export const createTreatmentsControl = async (
  treatment_id,
  control_date,
  control_description,
  health_organization
) => {
  try {
    const response = await axios.post(
      `${baseURL}/treatment/create-control`,
      {
        treatment_id,
        control_date,
        control_description,
        health_organization,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi kontrol güncelleme
export const updateTreatmentsControl = async (
  id,
  treatment_id,
  control_date,
  control_description,
  health_organization
) => {
  try {
    const response = await axios.post(
      `${baseURL}/treatment/update-control/${id}`,
      {
        id,
        treatment_id,
        control_date,
        control_description,
        health_organization,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi kontrol sil
export const deleteTreatmentsControl = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/treatment/delete-control/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tüm tedavileri ekleme ihtiyaçları
export const getAllTreatmentsFormInfos = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/treatment/get-treatments-create-form-infos`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tüm tedavileri getirme
export const getAllTreatments = async () => {
  try {
    const response = await axios.get(`${baseURL}/treatment/get-treatments`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    console.log("rees", response.data);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tek tedavileri getirme
export const getTreatments = async (id) => {
  try {
    const response = await axios.get(
      `${baseURL}/treatment/get-treatment/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi oluşturma
export const createTreatments = async (
  form
  // treatments_start_date,
  // player_id,
  // doctor_id,
  // treatments_type_id,
  // treatments_status_id,
  // recovery_day,
  // is_active,
  // complaint,
  // examination,
  // diagnosis,
  // treatment,
  // injurySite,
  // injuryValueSite,
  // criticism,
  // grade
) => {
  try {
    const newInjurySite =
      form.injurySite == "diz"
        ? form.injuryValueSite + "-" + form.injurySite
        : form.injurySite;

    const response = await axios.post(
      `${baseURL}/treatment/create-treatment`,
      form,
      // {
      //     treatments_start_date,
      //     player_id,
      //     doctor_id,
      //     treatments_type_id,
      //     treatments_status_id,
      //     recovery_day,
      //     is_active,
      //     complaint,
      //     examination,
      //     diagnosis,
      //     treatment,
      //     newInjurySite,
      //     criticism,
      //     grade
      // },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi oluşturma
export const updateTreatments = async (
  id,
  treatments_start_date,
  player_id,
  doctor_id,
  treatments_type_id,
  treatments_status_id,
  recovery_day,
  is_active,
  complaint,
  examination,
  diagnosis,
  treatment,
  injurySite,
  injuryValueSite,
  criticism,
  grade
) => {
  try {
    const newInjurySite =
      injurySite == "diz" ? injuryValueSite + "-" + injurySite : injurySite;

    const response = await axios.post(
      `${baseURL}/treatment/update-treatment/${id}`,
      {
        treatments_start_date,
        player_id,
        doctor_id,
        treatments_type_id,
        treatments_status_id,
        recovery_day,
        is_active,
        complaint,
        examination,
        diagnosis,
        treatment,
        newInjurySite,
        criticism,
        grade,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi durumu sil
export const deleteTreatments = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/treatment/delete-treatment/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedaviye ilaç ekleme
export const createTreatmentsBelongMedicine = async (
  treatment_id,
  medicine_id
) => {
  try {
    const response = await axios.post(
      `${baseURL}/treatment/create-belong-treatment-medicine`,
      {
        treatment_id,
        medicine_id,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
// tedaviye ilaç ekleme
export const deleteTreatmentsBelongMedicine = async (
  treatment_id,
  medicine_id
) => {
  try {
    const response = await axios.delete(
      `${baseURL}/treatment/delete-belong-treatment-medicine/${treatment_id}/${medicine_id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tüm tedavi durumlarını getirme
export const getAllTreatmentsStatus = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/treatment/status/get-statuses`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi durumu oluşturma
export const createTreatmentsStatus = async (treatments_status_title) => {
  try {
    const response = await axios.post(
      `${baseURL}/treatment/status/create-status`,
      {
        treatments_status_title,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi durumu güncelleme
export const updateTreatmentsStatus = async (id, treatments_status_title) => {
  try {
    const response = await axios.post(
      `${baseURL}/treatment/status/update-status/${id}`,
      {
        treatments_status_title,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi durumu sil
export const deleteTreatmentsStatus = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/treatment/status/delete-status/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tüm tedavi tipleri getirme
export const getAllTreatmentsTypes = async () => {
  try {
    const response = await axios.get(`${baseURL}/treatment/types/get-types`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi tipi oluşturma
export const createTreatmentsType = async (treatments_type_title) => {
  try {
    const response = await axios.post(
      `${baseURL}/treatment/types/create-type`,
      {
        treatments_type_title,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi tipi güncelleme
export const updateTreatmentsType = async (id, treatments_type_title) => {
  try {
    const response = await axios.post(
      `${baseURL}/treatment/types/update-type/${id}`,
      {
        treatments_type_title,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tedavi tipi sil
export const deleteTreatmentsType = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/treatment/types/delete-type/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// tüm ilaçları getirme
export const getAllTreatmentsMedicines = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/treatment/medicine/get-medicines`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// ilaç oluşturma
export const createTreatmentsMedicines = async (
  medicine_title,
  medicine_prospectus
) => {
  try {
    const response = await axios.post(
      `${baseURL}/treatment/medicine/create-medicine`,
      {
        medicine_title,
        medicine_prospectus,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// ilaç oluşturma
export const updateTreatmentsMedicines = async (
  id,
  medicine_title,
  medicine_prospectus
) => {
  try {
    const response = await axios.post(
      `${baseURL}/treatment/medicine/update-medicine/${id}`,
      {
        medicine_title,
        medicine_prospectus,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

// ilaç sil

export const deleteTreatmentsMedicines = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/treatment/medicine/delete-medicine/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

//activity selected player update
export const updateActivitySelectedPlayer = async (
  activity,
  player,
  notes,
  average
) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/update-activity-player-attendance/${activity}/${player}`,
      {
        notes,
        average,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const bulkUpdateSelectedPlayers = async (data, activity) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/bulk-update-activity-players/${activity}`,
      {
        data,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    // console.log(response, "response");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

//REPORTS
//player statistics
export const getPlayerStatistics = async (
  startDate,
  endDate,
  searchTerm,
  seasonId
) => {
  try {
    const response = await axios.get(
      `${baseURL}/user/get-player-statistic-reports`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
        params: {
          startDate: startDate,
          endDate: endDate,
          searchTerm: searchTerm,
          seasonId: seasonId,
        },
      }
    );
    return { success: true, data: response.data.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const getGoalStatistics = async (
  startDate,
  endDate,
  searchTerm,
  seasonId,
  teamId
) => {
  try {
    const response = await axios.get(`${baseURL}/user/get-goal-reports`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        startDate: startDate,
        endDate: endDate,
        searchTerm: searchTerm,
        seasonId: seasonId,
        teamId: teamId,
      },
    });
    return { success: true, data: response.data.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const getCoachStatistics = async (startDate, endDate, searchTerm) => {
  try {
    const response = await axios.get(`${baseURL}/user/get-coach-reports`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        startDate: startDate,
        endDate: endDate,
        searchTerm: searchTerm,
      },
    });

    return { success: true, data: response.data.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const getRedCardStatistics = async (
  startDate,
  endDate,
  searchTerm,
  seasonId,
  teamId
) => {
  try {
    const response = await axios.get(`${baseURL}/user/get-red-card-reports`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        startDate: startDate,
        endDate: endDate,
        searchTerm: searchTerm,
        seasonId: seasonId,
        teamId: teamId,
      },
    });
    return { success: true, data: response.data.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
export const getYellowCardStatistics = async (
  startDate,
  endDate,
  searchTerm,
  seasonId,
  teamId
) => {
  try {
    const response = await axios.get(
      `${baseURL}/user/get-yellow-card-reports`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
        params: {
          startDate: startDate,
          endDate: endDate,
          searchTerm: searchTerm,
          seasonId: seasonId,
          teamId: teamId,
        },
      }
    );
    return { success: true, data: response.data.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
export const getPlayerReports = async (
  reportType,
  searchTerm,
  cityFilter,
  regionFilter,
  attendanceStatusFilter,
  martialStatusFilter,
  seasonId
) => {
  try {
    const response = await axios.get(`${baseURL}/user/get-player-reports`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        reportType: reportType,
        searchTerm: searchTerm,
        cityFilter: cityFilter,
        regionFilter: regionFilter,
        attendanceStatusFilter: attendanceStatusFilter,
        martialStatusFilter: martialStatusFilter,
        seasonId: seasonId,
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
export const getCities = async () => {
  try {
    const response = await axios.get(`${baseURL}/cities`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {},
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const getDistricts = async (fkCity) => {
  try {
    const response = await axios.get(`${baseURL}/districts`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        fkCity: fkCity,
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const getSelectOptions = async () => {
  try {
    const response = await axios.get(`${baseURL}/form/select/get-selects`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

//fixture matches
export const createFixtureMatches = async (
  fkLeague,
  home_team,
  guest_team,
  season,
  week,
  match_date,
  judge,
  is_fixture,
  team
) => {
  try {
    const response = await axios.post(
      `${baseURL}/matches/create-matches`,
      {
        fkLeague,
        home_team,
        guest_team,
        season,
        week,
        match_date,
        judge,
        is_fixture,
        team,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
export const updateFixtureMatches = async (
  id,
  home_team,
  guest_team,
  season,
  week,
  match_date,
  judge,
  team
) => {
  try {
    const response = await axios.post(
      `${baseURL}/matches/update-matches/${id}`,
      {
        home_team,
        guest_team,
        season,
        week,
        match_date,
        judge,
        team,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//candidate
export const getAllCandidateUsers = async () => {
  try {
    const response = await axios.get(`${baseURL}/user/all-candidate-users`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const getCandidatePlayer = async (id) => {
  try {
    const response = await axios.get(
      `${baseURL}/candidate/get-candidate/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const getCandidateUser = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/user/get-candidate-user`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        id: id,
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
export const updateCandidate = async (
  name,
  surname,
  email,
  tckn,
  watchMatch,
  watchDate,
  monitoredTournament,
  notes,
  watchStatus,
  position,
  fkFootUsed,
  fkFollower,
  fkTeam,
  birthDate,
  clubId,
  cityId,
  fkRegion,
  recommendedPosition,
  height,
  weight,
  trainingAge,
  fkMonitoredCompetitionDifficulty,
  strengths,
  weaknesses,
  opportunities,
  threats,
  ratingByPlaceOfPlay,
  correspondingPointsToAlfa,
  fkResult,
  candidate_id
) => {
  try {
    const response = await axios.post(
      `${baseURL}/candidate/update-candidate/${candidate_id}`,
      {
        name,
        surname,
        email,
        tckn,
        watchMatch,
        watchDate,
        monitoredTournament,
        notes,
        watchStatus,
        position,
        fkFootUsed,
        fkFollower,
        fkTeam,
        birthDate,
        clubId,
        cityId,
        fkRegion,
        recommendedPosition,
        height,
        weight,
        trainingAge,
        fkMonitoredCompetitionDifficulty,
        strengths,
        weaknesses,
        opportunities,
        threats,
        ratingByPlaceOfPlay,
        correspondingPointsToAlfa,
        fkResult,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const deleteCandidate = async (id) => {
  try {
    const response = await axios.delete(
      `${baseURL}/candidate/delete-candidate/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    if (response?.success) {
      return { success: true, data: response.data };
    } else {
      return { success: false, data: response.message };
    }
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
// fixture matches
export const getFixtureMatches = async (leagueId, clubId, week) => {
  try {
    const response = await axios.get(`${baseURL}/matches/get-fixture-matches`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        leagueId: leagueId,
        clubId: clubId,
        week: week,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error };
  }
};
export const getTeamPlayers = async (team, availablePlayerIds) => {
  try {
    const response = await axios.get(
      `${baseURL}/teams/get-team-players/${team}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
        params: {
          availablePlayerIds: availablePlayerIds,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
//update match score
export const updateMatchScore = async (
  matchId,
  new_home_team_score,
  new_guest_team_score
) => {
  try {
    const response = await axios.post(
      `${baseURL}/matches/custom-update-score/${matchId}`,
      {
        new_home_team_score,
        new_guest_team_score,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getStandings = async (leagueId, limit) => {
  try {
    const response = await axios.get(`${baseURL}/standings`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        leagueId: leagueId,
        limit: limit,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getTeamMatchStatistic = async (teamId) => {
  try {
    const response = await axios.get(
      `${baseURL}/matches/team-match-statistics`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
        params: {
          teamId: teamId,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getNextMatches = async (leagueId, limit) => {
  try {
    const response = await axios.get(`${baseURL}/matches/next-matches`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        leagueId: leagueId,
        limit: limit,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
export const getRecentlyAddedPlayers = async (limit) => {
  try {
    const response = await axios.get(`${baseURL}/user/recently-added-players`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        limit: limit,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getRecentlyAddedCandidates = async (limit) => {
  try {
    const response = await axios.get(
      `${baseURL}/user/recently-added-candidates`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
        params: {
          limit: limit,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getRecentlyAddedTreatments = async (limit) => {
  try {
    const response = await axios.get(
      `${baseURL}/treatment/recently-added-treatments`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
        params: {
          limit: limit,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const updateMatchFinish = async (fkMatch, isFinish) => {
  try {
    const response = await axios.post(
      `${baseURL}/matches/update-match-finish`,
      {
        fkMatch,
        isFinish,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

//********************* */

export const getTreatmentStatistics = async (limit) => {
  try {
    const response = await axios.get(`${baseURL}/`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        limit: limit,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getDoctors = async (limit) => {
  try {
    const response = await axios.get(`${baseURL}/`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        limit: limit,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getInjurySite = async (injurySite) => {
  try {
    const response = await axios.post(
      `${baseURL}/treatment/create-treatment`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      },
      {
        injurySite,
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const createLeague = async (name, isCurrent, seasonId) => {
  try {
    const response = await axios.post(
      `${baseURL}/leagues/create`,
      {
        name: name,
        isCurrent: isCurrent,
        seasonId: seasonId,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const createAddBulkFixtureMatch = async (leagueId, matches) => {
  try {
    const response = await axios.post(
      `${baseURL}/matches/add-bulk-fixture-match`,
      {
        leagueId: leagueId,
        matches: matches,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const candidateIsConfirmedUpdate = async (id, isConfirmed) => {
  try {
    const response = await axios.post(
      `${baseURL}/candidate/is-confirmed-update/${id}`,
      {
        isConfirmed: isConfirmed,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );

    return { success: response?.data?.success, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const updateLeague = async (update_id, name, isCurrent, seasonId) => {
  try {
    const response = await axios.put(
      `${baseURL}/leagues/update/${update_id}`,
      {
        name: name,
        isCurrent: isCurrent,
        seasonId: seasonId,
      },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getDisabilityZone = async () => {
  try {
    const response = await axios.get(`${baseURL}/`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getCandidates = async (
  isConfirmed,
  watchStatus,
  position,
  startWatchDate,
  endWatchDate
) => {
  try {
    const response = await axios.get(
      `${baseURL}/candidate/get-candidates`,

      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
        params: {
          isConfirmed: isConfirmed,
          watchStatus: watchStatus,
          position: position,
          startWatchDate: startWatchDate,
          endWatchDate: endWatchDate,
        },
      }
    );
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getDisabilityAspect = async (limit) => {
  try {
    const response = await axios.get(`${baseURL}/`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        limit: limit,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
export const getDisabilityLevel = async (limit) => {
  try {
    const response = await axios.get(`${baseURL}/`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        limit: limit,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};
export const getTreatmentType = async (limit) => {
  try {
    const response = await axios.get(`${baseURL}/`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        limit: limit,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getDisabilityConditions = async () => {
  try {
    const response = await axios.get(`${baseURL}/`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
    });
    return { success: true, response: response.data };
  } catch (error) {
    return { success: false, response: error.response.data };
  }
};

export const getPlayerUsers = async (teamId) => {
  try {
    const response = await axios.get(`${baseURL}/user/get-player-users`, {
      headers: {
        Authorization: `Bearer ${getStoredBearerToken}`,
      },
      params: {
        teamId: teamId,
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const getLeagues = async (isCurrent) => {
  try {
    if (isCurrent === undefined) {
      const response = await axios.get(`${baseURL}/leagues`, {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      });
      return { success: true, data: response.data };
    } else {
      const response = await axios.get(`${baseURL}/leagues`, {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
        params: {
          isCurrent: isCurrent,
        },
      });
      return { success: true, data: response.data };
    }
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export const editActivityMovementDescription = async (id, description) => {
  try {
    const response = await axios.post(
      `${baseURL}/activities/movements/edit-description/${id}`,
      { description },
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    // Hata durumunda hata mesajını döndür
    return { success: false, message: error.response.data.message };
  }
};

export const searchUser = async (term, role) => {
  const roleValue = role ?? "";
  try {
    const response = await axios.get(
      `${baseURL}/user/search-user?term=${term}`,
      {
        headers: {
          Authorization: `Bearer ${getStoredBearerToken}`,
        },
        params: {
          role: roleValue,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
