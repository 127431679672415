import React, { useEffect, useState } from "react";
import { baseImageURL, getLeagues, getNextMatches } from "../../../../services/axios";
import { PulseLoader } from "react-spinners";
import dayjs from "dayjs";
import { logo } from "../../../../utils/filePath";
import { NoData } from "../../NoData";
import DynamicInput from "../../../../components/inputs/dynamicInput";

export const NextMatches = () => {
  const [loading, setLoading] = useState(true);
  const [leaguesLoading, setLeaguesLoading] = useState(false);
  const [leagueOptions, setLeagueOptions] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState([]);
  const [nextMatchesParams, setNextMatchesParams] = useState([]);
  const [season, setSeason] = useState([]);

  const fetchGetLeagues = async () => {
    setLeaguesLoading(true);
    try {
      const result = await getLeagues(1);
      setLeagueOptions([]);
      setLeagueOptions((prevState) => [
        ...prevState,
        ...result?.data.data.map((item) => ({
          label: item?.name,
          value: item?.id
        }))
      ]);
      setSelectedLeague({
        league: {
          label: result?.data.data[0]?.name,
          value: result?.data.data[0]?.id
        }
      });
      return true;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLeaguesLoading(false);
    }
  }

  const fetchGetNextMatches = async () => {
    try {
      setLoading(true);
      const results = await getNextMatches(selectedLeague?.league?.value, 3);
      let nextMatches = results?.response?.data.map((item) => ({
        home_team_name: item.home_team.club_name,
        home_team_img: baseImageURL + item.home_team.image,

        match_date: item.match_date,

        guest_team_name: item.guest_team.club_name,
        guest_team_img: baseImageURL + item.guest_team.image
      }));

      setNextMatchesParams(nextMatches);
      setSeason(results.response.season.season_title);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetLeagues();
  }, []);

  useEffect(() => {
    if (selectedLeague) {
      if (Object.keys(selectedLeague).length > 0) {
        fetchGetNextMatches();
      }
    }
  }, [selectedLeague]);
  return (
    <div className="flex flex-col">
      <div className="h-full p-5 pt-8 pb-12 bg-white border border-t-4 border-gray-100 shadow-xl rounded-xl border-t-primary focus:outline-none lg:h-[500px]">
        <div className="flex items-center justify-between">
          <div className="text-base font-semibold text-gray-400">
            Sıradaki Maç
          </div>
          <div>
            <DynamicInput
              containerStyle={"w-[300px]"}
              placeholder={"Lig Seçiniz"}
              name={"league"}
              value={selectedLeague}
              onChange={(e) => {
                setSelectedLeague({ league: e });
              }}
              component_type={"select"}
              customOptions={leagueOptions}
              disabled={leaguesLoading}
              minHeight={"40px"}
            />
          </div>
          {/*<a*/}
          {/*  href="/fixtures"*/}
          {/*  className="text-sm transition duration-700 ease-out text-primary hover:border-b hover:border-b-primary hover:ease-in"*/}
          {/*>*/}
          {/*  📅 Fikstür*/}
          {/*</a>*/}
        </div>

        <div className="flex flex-col items-center mt-5 md:-space-x-12 md:flex-row md:justify-evenly">
          <div className="flex items-center">
            <span className="text-gray-400 font-semibold">
              {season} <span className="mx-2 text-gray-500"> | </span> {selectedLeague?.league?.label}
            </span>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center items-center h-36">
            <PulseLoader color="red" />
            <p>Yükleniyor...</p>
          </div>
        ) : (
          <>
            {nextMatchesParams.length > 0 ? (
              <>
                {nextMatchesParams.map((item, index) => (
                  <>
                    <div
                      key={index}
                      className="grid grid-cols-5 mb-[3rem] mt-[2rem]"
                    >
                      <div className="flex justify-end gap-2 col-span-2">
                        <div className="flex items-center font-semibold">
                          {item.home_team_name}
                        </div>
                        <div className="flex items-center justify-center w-7">
                          <img
                            className=""
                            src={item.home_team_img}
                            alt={"Kulüp Logo"}
                          />
                        </div>
                      </div>

                      <div className="flex justify-center items-center relative">
                        <p className="p-3 font-semibold text-white rounded-full bg-primary">
                          VS
                        </p>
                        <div className="absolute bottom-[-30px] text-gray-400 text-[12px]">
                          {dayjs(item.match_date).format("DD MMMM YYYY")}
                        </div>
                      </div>

                      <div className="flex gap-2 col-span-2">
                        <div className="flex items-center justify-center w-7">
                          <img
                            className=""
                            src={item.guest_team_img}
                            alt={"Kulüp Logo"}
                          />
                        </div>
                        <div className="flex items-center font-semibold">
                          {item.guest_team_name}
                        </div>
                      </div>
                    </div>
                    {index != 2 && (
                      <>
                        <hr />
                      </>
                    )}
                  </>
                ))}
              </>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    </div>
  );
};
