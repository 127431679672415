import React, { useEffect, useState } from "react";
import DrawerMenuAntd, {
  getMenuItem,
} from "../../../components/drawerMenuAntd";
import { SettingOutlined } from "@ant-design/icons";
import { PulseLoader } from "react-spinners";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import {
  getAllTreatmentsMedicines,
  getAllTreatmentsTypes,
} from "../../../services/axios";
import CreateTreatmentsTypeModal, {
  CreateTreatmentsTypeForm,
} from "../../../containers/modals/CreateTreatmentsTypeModal";

function AllTreatmentsType() {
  const [loading, setLoading] = useState(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [isDataChange, setIsDataChange] = useState(true);
  const [menuData, setMenuData] = useState([]);
  const [allTreatmentsData, setAllTreatmentsData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const fetchGetAllTreatmentsType = async () => {
    setLoading(true);
    setMenuData([]);
    try {
      const result = await getAllTreatmentsTypes();
      // console.log(result?.data?.treatment_types, "result");
      setAllTreatmentsData(result?.data?.treatment_types);

      const allTreatmentsType = result?.data?.treatment_types;
      allTreatmentsType.map((item, index) => {
        handleDataPush(item?.treatments_type_title, index);
      });
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  const handleDataPush = (label, value) => {
    const newItem = getMenuItem(label, value, <SettingOutlined />, null);
    setMenuData((prev) => [...prev, newItem]);
  };

  useEffect(() => {
    isDataChange && fetchGetAllTreatmentsType();
  }, [isDataChange]);

  function SelectedItemForm() {
    return allTreatmentsData
      .filter((filterItem, filterIndex) => filterIndex == selectedMenuIndex)
      .map((item, index) => {
        return (
          <React.Fragment>
            <CreateTreatmentsTypeForm
              isOpen={modalOpen}
              setOpen={setModalOpen}
              setIsDataChange={setIsDataChange}
              isDataChange={isDataChange}
              updateSelectedValuesProp={item}
            />
          </React.Fragment>
        );
      });
  }

  return (
    <div>
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      <div className="flex mt-4">
        <div className="flex flex-col">
          <DrawerMenuAntd
            items={menuData}
            selectedKey={selectedMenuIndex}
            setSelectedKey={setSelectedMenuIndex}
          />
          <ButtonComponent
            customStyle={"mt-4"}
            text={"Yeni Tedavi Tipi Ekle"}
            outline
            onClick={() => {
              setModalOpen(true);
            }}
          />
          <CreateTreatmentsTypeModal
            isOpen={modalOpen}
            setOpen={setModalOpen}
            setIsDataChange={setIsDataChange}
          />
        </div>
        <div className="w-full px-4">
          <SelectedItemForm />
        </div>
      </div>
    </div>
  );
}

export default AllTreatmentsType;
