import React from "react";
import { FiEdit, FiTrash } from "react-icons/fi";
import AlertDialogDelete from "../../components/alertDialogDelete";
import AlertDialogApprove from "../../components/alertDialogApprove";
function ApprovalButtonsDataTable({
  onClikApprove,
  onClickReject,
  id,
  approveDataLabel,
  rejectDataLabel,
  approveButton,
  rejectButton,
  disabled,
}) {
  return (
    <div className="flex items-center">
      {approveButton && (
        <button
          onClick={onClikApprove}
          className="flex items-center p-2 text-green-500 transition-all rounded-lg cursor-pointer hover:bg-green-200"
        >
          <FiEdit size={21} />
          <span className="ml-2 text-xs text-green-500">Onayla</span>
        </button>
      )}
      {rejectButton && (
        <button
          disabled={disabled}
          onClick={onClickReject}
          className={`flex p-2 text-red-500 transition-all rounded-lg hover:bg-red-100 ${disabled ? "cursor-no-drop hover:bg-[#F5F5F5]" : "cursor-pointer"}`}
        >
          <FiTrash size={21} />
          <span className="ml-2 text-sm text-red-500">Sil</span>
        </button>
      )}
    </div>
  );
}

export default ApprovalButtonsDataTable;
