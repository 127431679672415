import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Paths } from "../../routes";
import ScoreboardList from "./scoreboardList";
const Scoreboard = () => {
  const [setBreadcrumbData] = useOutletContext();

  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel"
      },
      {
        title: <Link to={Paths.Dashboard}>Puan Durumu</Link>
      }
    ]);
  }, []);

  return (
    <div>
      <ScoreboardList />
    </div>
  );
};

export default Scoreboard;
