import React from "react";
import {logo} from "../../utils/filePath";
import { Link } from "react-router-dom";
import {baseImageURL} from "../../services/axios";
import { NoData } from "./NoData";

function UpcomingMatches({
    matches
                         }) {
    return (
        <div>
            {matches && (
                <>
                    {matches.length > 0 ? (
                        matches.map((item, index) => (
                            <div key={item.id}>
                                <Link to={"/fixtures/" + item.id}>
                                    <div className="py-10 border-2 hover:bg-gray-100 flex justify-center gap-12 xl:gap-52 flex-col xl:flex-row flex-wrap cursor-pointer">
                                        <div className="flex items-center flex-col gap-2">
                                            <span className="font-semibold">{item.home_team}</span>
                                            <figure>
                                                <img
                                                    className="w-14 inline-block"
                                                    src={baseImageURL + item.home_team_image}
                                                    alt="team-logo"
                                                />
                                            </figure>
                                        </div>
                                        <div>
                                            {/*<div className="text-gray-400 text-sm font-semibold text-center">{item.team ? "Takım:" + item.team : ""}</div>*/}
                                            <div className="text-gray-400 text-center">
                                                {item.match_date}
                                            </div>
                                            <div className="flex justify-around gap-2 mt-8">
                                                <div className="p-3 font-semibold text-gray-900 rounded-full bg-gray-300">{item.home_team_score}</div>
                                                <div className="p-3 font-semibold text-white rounded-full bg-primary">VS</div>
                                                <div className="p-3 font-semibold text-gray-900  rounded-full bg-gray-300">{item.guest_team_score}</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center flex-col gap-2">
                                            <span className="font-semibold">{item.guest_team}</span>
                                            <figure>
                                                <img
                                                    className="w-14 inline-block"
                                                    src={baseImageURL + item.guest_team_image}
                                                    alt="team-logo"
                                                />
                                            </figure>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    ) : (
                  <NoData />
                    )}
                </>
            )}
        </div>
    );
}

export default UpcomingMatches;
