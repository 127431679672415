import React, { useEffect, useState } from "react";

import { Paths } from "../../../routes";
import {
  getAllTreatments,
  getGoalStatistics,
  getPlayerStatistics,
  getSeasons,
  listTeams,
} from "../../../services/axios";
import { DataTable } from "../../../components/tables";
import { Input } from "antd";

import Select from "react-select";

const columns = [
  { title: "Gol", dataIndex: "playerOneName" },
  { title: "Asist", dataIndex: "playerTwoName" },
  { title: "Sezon", dataIndex: "season" },
  { title: "Takım", dataIndex: "teamName" },
  { title: "Maç Tarihi", dataIndex: "matchDate" },
];

const PlayerGoals = () => {
  const [isDataChange, setIsDataChange] = useState(true);
  const [dataTableData, setDataTableData] = useState([]);
  const [selectTeam, setSelectTeam] = useState({ label: "Tümü", value: null });

  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([{label: "Tümü", value: null}]);

  const [allSeasonsOptions, setAllSeasonsOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);

  const [playerStatisticFilterData, setPlayerStatisticFilterData] = useState({
    startDate: null,
    endDate: null,
    searchTerm: null,
    seasonId: {
      label: "Tümü",
      value: null,
    },
    teamId: {
      label: "Tümü",
      value: null,
    },
  });


  const fetchGetAllSeasons = async () => {
    setLoading(true);
    try {
      const results = await getSeasons();
      let allSeasonsOptions = results.data.seasons.map((item) => ({
        label: item.season_title,
        value: item.id,
      }));
      const defaultOption = {
        label: "Tümü",
        value: null,
      };
      allSeasonsOptions.unshift(defaultOption);
      setAllSeasonsOptions(allSeasonsOptions);
      setLoading(false);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchGetGoalStatistic = async () => {
    setLoading(true);
    try {
      const results = await getGoalStatistics(
        playerStatisticFilterData.startDate,
        playerStatisticFilterData.endDate,
        playerStatisticFilterData.searchTerm,
        playerStatisticFilterData.seasonId.value,
        playerStatisticFilterData.teamId.value
      );

      const resultsDataTable = results?.data.map((item) => ({
        key: item?.id,
        playerOneName: item?.playerOne.name ? item?.playerOne.name : "-",
        playerTwoName: item?.playerTwo.name ? item?.playerTwo.name : "-",
        teamName: item?.team.name ? item?.team.name : "-",
        matchDate: item?.match.matchDate ? item?.match.matchDate : "-",
        season: item?.season.season ?  item?.season.season : "-",
      }));
      setDataTableData(resultsDataTable);
      setLoading(false);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  const fetchGetTeams = async () => {
    try {
      const results = await listTeams();
      setTeams((prevState) => [
        ...prevState,
        ...results?.data.teams.map((item) => ({
          label: item.team_title + " | " + item.team_category.category_title,
          value: item.id,
        })),
      ]);
    } catch (error) {
      console.log("hata", error);
    }
  };

  useEffect(() => {
    setIsDataChange(true);
  }, [playerStatisticFilterData]);

  useEffect(() => {
    isDataChange && fetchGetGoalStatistic();
  }, [isDataChange, playerStatisticFilterData]);

  useEffect(() => {
    fetchGetAllSeasons();
    fetchGetTeams();
  }, []);

  let timer;
  useEffect(() => {
    timer = setTimeout(() => {
      setPlayerStatisticFilterData((prevState) => ({
        ...prevState,
        searchTerm: searchTerm,
      }));
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchTerm]);

  return (
    <>
      <div className="w-full p-4 bg-white rounded-md shadow">
        <div className="flex items-center justify-between text-gray-500">
          <h4 className="text-lg font-semibold">Goller</h4>
        </div>
        <div className="flex flex-wrap-reverse items-center justify-between px-2 py-2 my-4 bg-gray-100 rounded-lg">
          <div className="flex items-center w-full sm:w-auto gap-3">
            <div className="">
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                Tarih Aralığı:
              </span>
              <div className="flex items-center w-full sm:w-auto">
                <Input
                  type="date"
                  onChange={(e) => {
                    setPlayerStatisticFilterData((prevState) => ({
                      ...prevState,
                      startDate: e.target.value,
                    }));
                  }}
                />
                <span className="mx-2">-</span>
                <Input
                  type="date"
                  onChange={(e) => {
                    setPlayerStatisticFilterData((prevState) => ({
                      ...prevState,
                      endDate: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="" style={{ minWidth: "150px" }}>
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                Sezon:
              </span>
              <Select
                onChange={(e) =>
                  setPlayerStatisticFilterData((prevState) => ({
                    ...prevState,
                    seasonId: e,
                  }))
                }
                value={playerStatisticFilterData.seasonId}
                options={allSeasonsOptions}
              />
            </div>

            <div className="" style={{ minWidth: "150px" }}>
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                Takımlar:
              </span>
              <Select
                options={teams}
                value={selectTeam}
                onChange={(e) => {
                  setSelectTeam(e);
                  setPlayerStatisticFilterData((prevState) => ({
                    ...prevState,
                    teamId: e,
                  }));
                }}
              />
            </div>
          </div>

          <div className="flex items-center w-full sm:w-auto">
            <Input
              type="text"
              placeholder="Arama"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="">
          <DataTable
            columns={columns}
            data={dataTableData?.reverse() ?? []}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default PlayerGoals;
