import React from "react";
import {Calendar as ACalendar} from "../../containers/sections";
import style from "./css/style.css";
const Calendar = () => {

    return (
        <div className="w-full bg-white rounded-md p-4 shadow space-y-10">
            <ACalendar/>
        </div>
    );
};

export default Calendar;
