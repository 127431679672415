import React from "react";
import { Card, LeftRoundedCard } from "../../components/widgets";
import { Paths } from "../../routes";
import { FiUser } from "react-icons/fi";

const Widgets = () => {
  return (
    <section className="w-full">
      <div className="grid w-full gap-5 lg:grid-cols-1 xl:grid-cols-3">
        <div className="sm:col-span-1 lg:col-span-1">
          <LeftRoundedCard
            bgColor="bg-primary"
            href={Paths.Dashboard}
            percent={30}
            title="Bekleyen İşler"
            subTitle="Görevler"
          />
        </div>
        <div className="grid gap-4 lg:col-span-1 xl:col-span-2 sm:grid-cols-2">
          <Card
            href={Paths.Players}
            title="Oyuncular"
            titleColor="text-orange-500"
            icon={<FiUser />}
            iconBgColor="bg-orange-500"
            subTitle="Oyuncu İşlemleri"
          />
          <Card
            href={Paths.Dashboard}
            title="Oyuncular"
            titleColor="text-green-500"
            icon={<FiUser />}
            iconBgColor="bg-green-500"
            subTitle="Oyuncu İşlemleri"
          />
          <Card
            href={Paths.Dashboard}
            title="Oyuncular"
            titleColor="text-blue-950"
            icon={<FiUser />}
            iconBgColor="bg-blue-950"
            subTitle="Oyuncu İşlemleri"
          />
          <Card
            href={Paths.Dashboard}
            title="Oyuncular"
            titleColor="text-red-600"
            icon={<FiUser />}
            iconBgColor="bg-red-600"
            subTitle="Oyuncu İşlemleri"
          />
        </div>
      </div>
    </section>
  );
};

export default Widgets;
