import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Paths } from "../../../routes";

import { DataTable } from "../../../components/tables";
import { Input } from "antd";
import { FiPlus } from "react-icons/fi";
import ActionButtonsDataTable from "../../../containers/sections/ActionButtonsDataTable";
import { getAllTreatments } from "../../../services/axios";
import { PositionData } from "../../../utils/datas/positionData";

const columns = [
  { title: "Tedavi Tarihi", dataIndex: "treatments_start_date" },
  { title: "Oyuncu", dataIndex: "player_id" },
  { title: "Doktor", dataIndex: "doctor_id" },
  { title: "Tedavi Tipi", dataIndex: "treatments_type_id" },
  { title: "Durum Tipi", dataIndex: "treatments_status_id" },
  { title: "Dinlenme Günü", dataIndex: "recovery_day" },
  { title: "Aktif Mi?", dataIndex: "is_active" },
  { title: "Şikayet", dataIndex: "complaint" },
  { title: "Muayene", dataIndex: "examination" },
  { title: "Teşhis", dataIndex: "diagnosis" },
  { title: "İlk Müdahale", dataIndex: "treatment" },
  { title: "Kontrol Tarihi", dataIndex: "control_dates" },
  { title: "Pozisyon", dataIndex: "position" },

  {
    title: "İşlem",
    fixed: "right",
    key: "action",
    sorter: true,
    width: "",
    render: (item, index) => {
      return (
        <ActionButtonsDataTable
          id={item.key}
          toUpdate={Paths.Treatments.EditTreatments}
          // toDetail={Paths.Treatments.EditTreatments}
          deleteDataLabel={item.treatmentsNameAndSurname}
          // detailButton
          updateButton
        />
      );
    }
  }
];
const TreatmentsList = () => {
  const [isDataChange, setIsDataChange] = useState(true);
  const [dataTableData, setDataTableData] = useState([]);

  const [filterInputValue, setFilterInputValue] = useState("");
  const [allTreatmentsData, setAllTreatmentsData] = useState([]);
  const [loading, setLoading] = useState(false);


  const fetchGetAllTreatments = async () => {
    setLoading(true);
    try {
      const result = await getAllTreatments();
      // console.log(
      //   result?.data?.treatments.map((item) => item.treatment_player.position),
      //   "ssssssssssssssss"
      // );

      setAllTreatmentsData(result?.data?.treatments);
      const resultDataTableData = result?.data?.treatments.map(
        (item, index) => ({
          key: item?.id ?? "",
          treatments_start_date: item?.treatments_start_date
            ? item?.treatments_start_date
            : "-",
          player_id: item?.treatment_player?.user_name
            ? item?.treatment_player?.user_name
            : "-",
          doctor_id: item?.treatment_doctor?.user_name
            ? item?.treatment_doctor?.user_name
            : "-",
          treatments_type_id: item?.treatment_type?.treatments_type_title
            ? item?.treatment_type?.treatments_type_title
            : "-",
          treatments_status_id: item?.treatment_status?.treatments_status_title
            ? item?.treatment_status?.treatments_status_title
            : "-",
          recovery_day: item?.recovery_day ? item?.recovery_day : "-",
          is_active: item?.is_active ? "Evet" : "Hayır",
          complaint: item?.complaint ? item?.complaint : "-",
          examination: item?.examination ? item?.examination : "-",
          diagnosis: item?.diagnosis ? item?.diagnosis : "-",
          treatment: item?.treatment ? item?.treatment : "-",
          control_dates: item?.control_dates ? item?.control_dates : "-",
          position: item.position
            ? PositionData.find((pItem) => pItem.value == item.position)?.label
            : "-"
        })
      );

      setDataTableData(resultDataTableData);
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  useEffect(() => {
    isDataChange && fetchGetAllTreatments();
  }, [isDataChange]);

  return (
    <>
      <div className="w-full p-4 bg-white rounded-md shadow">
        <div className="flex items-center justify-between text-gray-500">
          <h4 className="text-lg font-semibold">Tedaviler</h4>
        </div>
        <div className="flex flex-wrap-reverse items-center justify-between px-2 py-2 my-4 bg-gray-100 rounded-lg">
          <ul className="flex items-center">
            <li>
              <Link
                to={Paths.Treatments.AddTreatments}
                className="flex p-2 text-gray-500 transition-all rounded-lg hover:bg-gray-200"
              >
                <FiPlus size={21} />
                <span className="ml-2 text-sm text-gray-500">Ekle</span>
              </Link>
            </li>
          </ul>
          {/* <div className="flex items-center w-full sm:w-auto">
            <span className="hidden mr-2 text-sm text-gray-500 sm:block">
              Filtrele:{" "}
            </span>
            <Input
              type="text"
              placeholder="Filtrele.."
              value={filterInputValue}
              onChange={({ target }) => setFilterInputValue(target.value)}
            />
          </div> */}
        </div>
        <div className="">
          <DataTable
            columns={columns}
            data={dataTableData?.reverse() ?? []}
            // selection={{ rowSelectionList, setRowSelectionList }}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default TreatmentsList;
