import { Formik, useFormik } from "formik";
import { handleCustomChange } from "../../utils/handleCustomChange";
import DynamicInput from "../../components/inputs/dynamicInput";
import React, { useEffect, useState } from "react";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import {
  createFixtureMatches,
  getAllClub,
  getAllJudges,
  getSeasons,
  listTeams,
  updateFixtureMatches
} from "../../services/axios";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";

function FixtureMatchFields({
  action,
  modalIsOpen,
  setIsDataChange,
  matchData,
  leagueOptions,
  selectedLeague
}) {
  if (!action) {
    alert("FixtureMatchFields | Action required");
  }

  const [loading, setLoading] = useState(false);

  const [allClubOptions, setAllClubOptions] = useState({
    home: [],
    guest: []
  });
  const [allSeasonsOptions, setAllSeasonsOptions] = useState([]);
  const [allJudgesOptions, setAllJudgesOptions] = useState([]);
  const [isDefaultTeam, setIsDefaultTeam] = useState({
    home: false,
    guest: false
  });
  const [allTeamsOption, setAllTeamsOption] = useState([]);
  const weeks = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
    { label: "13", value: 13 },
    { label: "14", value: 14 },
    { label: "15", value: 15 }
  ];
  const fetchGetAllClub = async () => {
    setLoading(true);
    try {
      const result = await getAllClub();
      setAllClubOptions((prevOptions) => ({
        ...prevOptions,
        home: result.response.clubs.map((item, index) => ({
          label: item.club_name,
          value: item.id
        })),
        guest: result.response.clubs.map((item, index) => ({
          label: item.club_name,
          value: item.id
        }))
      }));
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
    }
  };
  const fetchGetAllSeasons = async () => {
    setLoading(true);
    try {
      const result = await getSeasons();
      const allSeasonsOptions = result.data.seasons.map((item, index) => ({
        label: item.season_title,
        value: item.id
      }));
      setAllSeasonsOptions(allSeasonsOptions);

      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchGetAllJudges = async () => {
    setLoading(true);
    try {
      const result = await getAllJudges();
      const allJudgesOptions = result?.response.judges.map((item, index) => ({
        label: item.name,
        value: item.id
      }));
      setAllJudgesOptions(allJudgesOptions);

      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCreateFixtureMatch = async (
    leagueId,
    home_team,
    guest_team,
    season,
    week,
    match_date,
    judge,
    is_fixture,
    team
  ) => {
    try {
      const result = await createFixtureMatches(
        leagueId,
        home_team,
        guest_team,
        season,
        week,
        match_date,
        judge,
        is_fixture,
        team
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000
        });
        modalIsOpen(true);
        setIsDataChange(true);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    }
  };

  const fetchUpdateFixtureMatch = async (
    id,
    home_team,
    guest_team,
    season,
    week,
    match_date,
    judge
  ) => {
    setLoading(true);
    try {
      const result = await updateFixtureMatches(
        id,
        home_team,
        guest_team,
        season,
        week,
        match_date,
        judge
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Düzenleme Başarılı!",
          showConfirmButton: false,
          timer: 1000
        });
        setIsDataChange(true);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Düzenleme Başarısız!",
          showConfirmButton: false,
          timer: 1000
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
    }
  };

  const handleIsDefaultTeam = (e, type) => {
    if (e === "Altınordu" && type === "home") {
      // setAllClubOptions((prevOptions) => ({
      //     ...prevOptions,
      //     guest: prevOptions.home.filter((option) => option.label !== "Altınordu")
      // }));
      setIsDefaultTeam((prevState) => ({
        ...prevState,
        home: true
      }));
    } else if (e !== "Altınordu" && type === "home") {
      setIsDefaultTeam((prevState) => ({
        ...prevState,
        home: false
      }));
      // setAllClubOptions((prevOptions) => {
      //     const homeOptions = prevOptions.home;
      //     const hasDefaultTeam = homeOptions.some(
      //         (option) => option.label === "Altınordu"
      //     );
      //
      //     if (!hasDefaultTeam) {
      //         homeOptions.push({label: "Altınordu", value: 2});
      //     }
      //
      //     return {
      //         ...prevOptions,
      //         guest: homeOptions
      //     };
      // });
    }

    if (e === "Altınordu" && type === "guest") {
      setAllClubOptions((prevOptions) => ({
        ...prevOptions,
        home: prevOptions.home.filter((option) => option.label !== "Altınordu")
      }));
      setIsDefaultTeam((prevState) => ({
        ...prevState,
        guest: true
      }));
    } else if (e !== "Altınordu" && type === "guest") {
      setAllClubOptions((prevOptions) => {
        const homeOptions = prevOptions.home;
        const hasDefaultTeam = homeOptions.some(
          (option) => option.label === "Altınordu"
        );

        if (!hasDefaultTeam) {
          homeOptions.push({ label: "Altınordu", value: 2 });
        }

        return {
          ...prevOptions,
          home: homeOptions
        };
      });
      setIsDefaultTeam((prevState) => ({
        ...prevState,
        guest: false
      }));
    }
  };

  const fetchGetAllTeams = async () => {
    try {
      const result = await listTeams();
      if (result.success) {
        setAllTeamsOption(
          result?.data?.teams.map((item) => ({
            label: item.team_title + " | " + item.team_category.category_title,
            value: item.id
          }))
        );
      } else {
        console.log("hata");
      }
    } catch (error) {
      console.log(error, "hata");
    }
  };

  useEffect(() => {
    if (action === "ADD" || action === "EDIT") {
      fetchGetAllClub();
      fetchGetAllSeasons();
      fetchGetAllJudges();
      fetchGetAllTeams();
    }
  }, []);

  useEffect(() => {
    switch (action) {
      case "EDIT":
        if (matchData?.home_team.club_name === "Altınordu") {
          setIsDefaultTeam((prevState) => ({
            ...prevState,
            home: true
          }));
        } else if (matchData?.guest_team.club_name === "Altınordu") {
          handleIsDefaultTeam("Altınordu", "guest");
          setIsDefaultTeam((prevState) => ({
            ...prevState,
            guest: true
          }));
        }
        break;
    }
  }, [loading]);

  const {
    values,
    handleSubmit,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm
  } = useFormik({
    initialValues: {
      leagueId: selectedLeague ? selectedLeague?.league : "",
      home_team: "",
      guest_team: "",
      season: "",
      week: "",
      match_date: "",
      judge: "",
      is_fixture: 1,
      team: ""
    },
    onSubmit: async (values, { resetForm }) => {
      fetchCreateFixtureMatch(
        values?.leagueId.value,
        values?.home_team.value,
        values?.guest_team.value,
        values?.season.value,
        values?.week.value,
        values?.match_date,
        values?.judge.value,
        values?.is_fixture,
        values?.team.value
      );
    }
  });

  useEffect(() => {
    setFieldValue("leagueId", selectedLeague ? selectedLeague.league : "");
  }, [selectedLeague]);

  switch (action) {
    case "ADD":
      return (
        <div>
          {loading ? (
            <div className="flex justify-center items-center h-20">
              <PulseLoader color="red" />
              <p>Yükleniyor...</p>
            </div>
          ) : (
            <div>
              <p className="px-6 py-3 text-2xl font-semibold text-secondary">
                Fikstür Maçı Ekle
              </p>
              <hr />
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 xl:grid-cols-1 px-6 pt-6">
                  <DynamicInput
                    labelTitle={"Lig Seçimi"}
                    containerStyle={"w-full"}
                    placeholder={"Lig Seçimi"}
                    name={"leagueId"}
                    value={values}
                    onChange={(e1, e2) => {
                      handleCustomChange(
                        e1,
                        e2,
                        "leagueId",
                        setFieldValue,
                        "select"
                      );
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                    component_type={"select"}
                    customOptions={leagueOptions ?? []}
                    required
                    noBorder
                  />
                </div>
                <div className="grid gap-4 xl:grid-cols-2 p-6">
                  <div>
                    <DynamicInput
                      labelTitle={"Ev Sahibi Takım"}
                      containerStyle={"w-full"}
                      placeholder={"Ev Sahibi Takım"}
                      name={"home_team"}
                      value={values}
                      onChange={(e1, e2) => {
                        // handleIsDefaultTeam(e1.label, "home");
                        handleCustomChange(
                          e1,
                          e2,
                          "home_team",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={allClubOptions.home}
                      required
                      noBorder
                    />
                  </div>
                  <div>
                    <DynamicInput
                      labelTitle={"Misafir Takım"}
                      containerStyle={"w-full"}
                      placeholder={"Misafir Takım"}
                      name={"guest_team"}
                      value={values}
                      onChange={(e1, e2) => {
                        // handleIsDefaultTeam(e1.label, "guest");
                        handleCustomChange(
                          e1,
                          e2,
                          "guest_team",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={allClubOptions.guest}
                      required
                      noBorder
                    />
                  </div>

                  <div>
                    <DynamicInput
                      labelTitle={"Haftalar"}
                      containerStyle={"w-full"}
                      placeholder={"Haftalar"}
                      name={"week"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "week",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={weeks}
                      required
                      noBorder
                    />
                  </div>
                  <div>
                    <p className="flex items-start justify-start py-1 text-lg">
                      Tarih <span className="text-sm text-primary">*</span>
                    </p>
                    <div
                      className={
                        "border rounded-lg min-h-[56px] undefined  flex items-center px-4 gap-4 "
                      }
                    >
                      <input
                        name={"match_date"}
                        type={"date"}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "match_date",
                            setFieldValue,
                            "input"
                          );
                        }}
                        value={values.match_date}
                        onBlur={handleBlur}
                        className={`w-full relative z-0 min-h-[56px] text-md outline-none text-[#5F6368] bg-transparent`}
                        placeholder={"Tarih"}
                        required
                      />
                    </div>
                  </div>
                  {/* <div>
                    <DynamicInput
                      labelTitle={"Hakem"}
                      containerStyle={"w-full"}
                      placeholder={"Hakem"}
                      name={"judge"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "judge",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={allJudgesOptions}
                      noBorder
                    />
                  </div> */}
                </div>
                <div className="p-6">
                  <div className="flex justify-between gap-4">
                    <div>
                      {modalIsOpen && (
                        <ButtonComponent
                          customStyle={"w-full"}
                          onClick={() => {
                            modalIsOpen(false);
                          }}
                          outline
                          text={"Vazgeç"}
                          loading={loading}
                        />
                      )}
                    </div>
                    <div className="flex gap-4">
                      <ButtonComponent
                        onClick={() => resetForm()}
                        outline
                        text="Tümünü Temizle"
                        loading={loading}
                      />
                      <ButtonComponent
                        submit
                        fill
                        loading={loading}
                        text="Kaydet"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      );
    case "EDIT":
      if (!matchData) {
        alert("FixtureMatchFields | matchData undefined");
        return <div>FixtureMatchFields | matchData undefined</div>;
      }
      return (
        <div>
          {matchData?.isFinish ? (
            <span>
              <p className=" px-6 py-3 text-2xl font-semibold text-white flex !items-center justify-center bg-slate-500 rounded-xl">
                <span className="mr-[10px]">
                  <BsFillExclamationCircleFill />
                </span>
                Maç sonlandırıldığı için düzenleme yapılamaz
              </p>
            </span>
          ) : null}

          <p className="px-6 py-3 text-2xl font-semibold text-secondary">
            Maç Detayları
          </p>
          <hr />
          <Formik
            initialValues={{
              home_team: {
                label: matchData?.home_team.club_name,
                value: matchData?.home_team.id
              },
              guest_team: {
                label: matchData?.guest_team.club_name,
                value: matchData?.guest_team.id
              },
              season: matchData?.season
                ? {
                    label: matchData?.season.season_title,
                    value: matchData?.season.id
                  }
                : "",
              week: matchData?.week
                ? { label: matchData?.week, value: matchData?.week }
                : "",
              match_date: matchData?.match_date,
              judge: matchData?.get_judge
                  ? { label: matchData?.get_judge.name + " " + matchData?.get_judge.surname, value: matchData?.get_judge.id }
                  : "",
            }}
            onSubmit={(values, actions) => {
              fetchUpdateFixtureMatch(
                matchData?.id,
                values?.home_team.value,
                values?.guest_team.value,
                values?.season.value,
                values?.week.value,
                values?.match_date,
                values?.judge.value
              );
            }}
          >
            {({
              values,
              handleSubmit,
              errors,
              setFieldValue,
              touched,
              handleBlur,
              resetForm
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 xl:grid-cols-2 p-6">
                  <div>
                    <DynamicInput
                      disabled={matchData?.isFinish ? true : false}
                      labelTitle={"Ev Sahibi Takım"}
                      containerStyle={"w-full"}
                      placeholder={"Ev Sahibi Takım"}
                      name={"home_team"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleIsDefaultTeam(e1.label, "home");
                        handleCustomChange(
                          e1,
                          e2,
                          "home_team",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={allClubOptions.home}
                      required
                      noBorder
                    />
                  </div>
                  <div>
                    <DynamicInput
                      labelTitle={"Misafir Takım"}
                      disabled={matchData?.isFinish ? true : false}
                      containerStyle={"w-full"}
                      placeholder={"Misafir Takım"}
                      name={"guest_team"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleIsDefaultTeam(e1.label, "guest");
                        handleCustomChange(
                          e1,
                          e2,
                          "guest_team",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={allClubOptions.guest}
                      required
                      noBorder
                    />
                  </div>
                  <div>
                    <DynamicInput
                      labelTitle={"Sezon"}
                      disabled={matchData?.isFinish ? true : false}
                      containerStyle={"w-full"}
                      placeholder={"Sezon"}
                      name={"season"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "season",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={allSeasonsOptions}
                      required
                      noBorder
                    />
                  </div>
                  <div>
                    <DynamicInput
                      labelTitle={"Haftalar"}
                      disabled={matchData?.isFinish ? true : false}
                      containerStyle={"w-full"}
                      placeholder={"Haftalar"}
                      name={"week"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "week",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={weeks}
                      required
                      noBorder
                    />
                  </div>
                  <div>
                    <p className="flex items-start justify-start py-1 text-lg">
                      Tarih <span className="text-sm text-primary">*</span>
                    </p>
                    <div
                      className={
                        "border rounded-lg min-h-[56px] undefined  flex items-center px-4 gap-4 "
                      }
                    >
                      <input
                        name={"match_date"}
                        disabled={matchData?.isFinish ? true : false}
                        type={"date"}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                            e1,
                            e2,
                            "match_date",
                            setFieldValue,
                            "input"
                          );
                        }}
                        value={values.match_date}
                        onBlur={handleBlur}
                        className={`w-full relative z-0 min-h-[56px] text-md outline-none text-[#5F6368] bg-transparent ${
                          matchData?.isFinish
                            ? "cursor-no-drop"
                            : "cursor-pointer"
                        }`}
                        placeholder={"Tarih"}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <DynamicInput
                      labelTitle={"Hakem"}
                      disabled={matchData?.isFinish ? true : false}
                      containerStyle={"w-full"}
                      placeholder={"Hakem"}
                      name={"judge"}
                      value={values}
                      onChange={(e1, e2) => {
                        handleCustomChange(
                          e1,
                          e2,
                          "judge",
                          setFieldValue,
                          "select"
                        );
                      }}
                      error={errors}
                      isTouched={touched}
                      onBlur={handleBlur}
                      component_type={"select"}
                      customOptions={allJudgesOptions}
                      noBorder
                    />
                  </div>
                </div>
                <div className="p-6 flex justify-end">
                  <ButtonComponent
                    submit
                    fill
                    loading={matchData?.isFinish ? true : false}
                    text="Kaydet"
                    onChange={() => {}}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      );
    default:
      alert("FixtureMatchFields | Undefined action");
      break;
  }
}

export default FixtureMatchFields;
