import React, { useEffect, useState } from "react";
import { CreateTreatmentsForm } from "../../../containers/modals/CreateTreatmentsModal";
import PageTitle from "../../../components/pageTitle";
import { getTreatments } from "../../../services/axios";
import { useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";

function EditTreatments() {
  const [isDataChange, setIsDataChange] = useState(true);
  const [updateSelectedValuesProp, setUpdateSelectedValuesProp] = useState({});

  const { id } = useParams();
  console.log(updateSelectedValuesProp, "id omer");

  const [loading, setLoading] = useState(false);

  const fetchGetAllTreatments = async () => {
    setLoading(true);
    try {
      const result = await getTreatments(id);
      console.log(result?.data?.treatment, "treatments");
      setUpdateSelectedValuesProp(result?.data?.treatment);
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  useEffect(() => {
    isDataChange && fetchGetAllTreatments();
  }, [isDataChange]);

  return (
    <div className="p-4 bg-white">
      <PageTitle bg title={"Tedavi Düzenle"} />
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      {!loading && (
        <>
          <CreateTreatmentsForm
            isOpen={true}
            setOpen={null}
            setIsDataChange={setIsDataChange}
            updateSelectedValuesProp={updateSelectedValuesProp}
          />
        </>
      )}
    </div>
  );
}

export default EditTreatments;
