import React, { useEffect, useState } from "react";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { getLeagues, getSeasons } from "../../services/axios";
import { SettingOutlined } from "@ant-design/icons";
import { PulseLoader } from "react-spinners";
import CreateLeagueModal, {
  CreateLeagueForm
} from "../../containers/modals/CreateLeagueModal";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import TabbarComponent from "../../components/tabbar";

function LeagueDefinitions() {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDataChange, setIsDataChange] = useState(true);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [resultData, setResultData] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [openCreateLeagueModal, setOpenCreateLeagueModal] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(0);
  // console.log(selectedTabId, "selectedTabId");

  const [seasonOptions, setSeasonOptions] = useState([]);

  useEffect(() => {
    if (isDataChange) {
      fetchGetLeagues(selectedTabId == 0 ? 1 : selectedTabId == 1 ? 0 : null);
    }
  }, [isDataChange]);

  useEffect(() => {
    setIsDataChange(true);
  }, [selectedTabId]);

  const fetchGetLeagues = async (isCurrent) => {
    setLoading(true);
    try {
      const result = await getLeagues(isCurrent);
      setMenuItems([]);
      setResultData(result.data);
      result?.data?.data?.map((item, index) => {
        item.isCurrent == isCurrent && handleMenuItemsPush(item.name, index);
      });
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }

  };

  const fetchGetSeasons = async () => {
    try {
      const result = await getSeasons();
      let allSeasonOptions = result.data.seasons.map((item) => ({
        label: item.season_title,
        value: item.id
      }));
      setSeasonOptions(allSeasonOptions);
    } catch (error) {
      console.log(error, "hata");
    }
  };

  function SelectedItemForm() {
    return resultData?.data
      .filter((filterItem, filterIndex) => filterIndex == selectedMenuIndex)
      .map((item, index) => {
        return (
          <React.Fragment key={index}>
            {/* {item.id} */}
            <CreateLeagueForm
              isOpen={openCreateLeagueModal}
              setOpen={setOpenCreateLeagueModal}
              setIsDataChange={setIsDataChange}
              isDataChange={isDataChange}
              updateSelectedValuesProp={item}
              updateId={item.id}
              seasonOptions={seasonOptions}
            />
          </React.Fragment>
        );
      });
  }

  useEffect(() => {
    fetchGetSeasons();
  }, []);

  const handleMenuItemsPush = (label, key) => {
    const newItem = getMenuItem(label, key, <SettingOutlined />, null);
    // console.log(newItem, "new item");
    setMenuItems((prev) => [...prev, newItem]);
  };

  return (
    <div>
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      {!loading && (
        <>
          <div className="flex">
            <div className="flex flex-col">
              <TabbarComponent
                type="card"
                setSelectedTabId={setSelectedTabId}
                selectedTabId={selectedTabId}
                data={[{ title: "Aktif" }, { title: "Geçmiş Ligler" }]}
              />
              <DrawerMenuAntd
                items={menuItems}
                selectedKey={selectedMenuIndex}
                setSelectedKey={setSelectedMenuIndex}
              />
              <ButtonComponent
                customStyle={"mt-4"}
                text={"Yeni Lig Ekle"}
                outline
                onClick={() => setOpenCreateLeagueModal(!openCreateLeagueModal)}
              />
              <CreateLeagueModal
                isOpen={openCreateLeagueModal}
                setOpen={setOpenCreateLeagueModal}
                setIsDataChange={setIsDataChange}
                selectedTabId={selectedTabId}
                seasonOptions={seasonOptions}
              />
            </div>
            <div className="w-full px-4">
              <SelectedItemForm />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default LeagueDefinitions;
