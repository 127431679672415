import React, { useEffect, useState } from "react";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { getSeasons } from "../../services/axios";
import { SettingOutlined } from "@ant-design/icons";
import { PulseLoader } from "react-spinners";
import CreateSeasonsModal, {
  CreateSeasonsForm,
} from "../../containers/modals/CreateSeasonsModal";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import TabbarComponent from "../../components/tabbar";

function SeasonsDefinitions() {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDataChange, setIsDataChange] = useState(true);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [resultData, setResultData] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [openCreateSeasonModal, setOpenCreateSeasonModal] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(0);

  // console.log(isDataChange, "isDataChange");
  useEffect(() => {
    isDataChange &&
      fetchGetSeasons(
        selectedTabId == 0 ? true : selectedTabId == 1 ? false : null
      );
  }, [isDataChange]);

  useEffect(() => {
    setIsDataChange(true);
  }, [selectedTabId]);

  const fetchGetSeasons = async (isActive) => {
    setLoading(true);
    setMenuItems([]);
    try {
      const result = await getSeasons();
      console.log("result",result)
      setResultData(result.data);
      result?.data.seasons.map((item, index) => {
        item.is_active == isActive &&
          handleMenuItemsPush(item.season_title, index);
      });
      setLoading(false);
      // console.log(result, "result");
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setIsDataChange(false);
    }
  };

  function SelectedItemForm() {
    // console.log(resultData?.seasons, "resultData?.seasons");
    return resultData?.seasons
      .filter((filterItem, filterIndex) => filterIndex == selectedMenuIndex)
      .map((item, index) => {
        return (
          <React.Fragment key={index}>
            {/* {item.id} */}
            <CreateSeasonsForm
              isOpen={openCreateSeasonModal}
              setOpen={setOpenCreateSeasonModal}
              setIsDataChange={setIsDataChange}
              isDataChange={isDataChange}
              updateSelectedValuesProp={item}
              updateId={item.id}
            />
          </React.Fragment>
        );
      });
  }

  const handleMenuItemsPush = (label, key) => {
    const newItem = getMenuItem(label, key, <SettingOutlined />, null);
    setMenuItems((prev) => [...prev, newItem]);
  };

  return (
    <div>
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      {!loading && (
        <>
          <div className="flex">
            <div className="flex flex-col">
              <TabbarComponent
                type="card"
                setSelectedTabId={setSelectedTabId}
                selectedTabId={selectedTabId}
                data={[{ title: "Aktif" }, { title: "Geçmiş Sezonlar" }]}
              />
              <DrawerMenuAntd
                items={menuItems}
                selectedKey={selectedMenuIndex}
                setSelectedKey={setSelectedMenuIndex}
              />
              {selectedTabId == 0 ? 
              <ButtonComponent
                customStyle={"mt-4"}
                text={"Yeni Sezon Ekle"}
                outline
                onClick={() => setOpenCreateSeasonModal(!openCreateSeasonModal)}
              />
              : null
              }
              <CreateSeasonsModal
                isOpen={openCreateSeasonModal}
                setOpen={setOpenCreateSeasonModal}
                setIsDataChange={setIsDataChange}
              />
            </div>
            <div className="w-full px-4">
              <SelectedItemForm />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SeasonsDefinitions;
