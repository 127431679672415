// src/App.js
import React from 'react';
import { Table } from 'antd';

const columns = [
  {
    title: <span className='text-red-500'>{'Oyuncu Bilgileri'}</span>,
    children: [
      {
        title: 'Sıra No',
        dataIndex: 'siraNo',
        key: 'siraNo',
        
      },
      {
        title: 'Forma No',
        dataIndex: 'formaNo',
        key: 'formaNo',
        
      },
      {
        title: 'Oyuncu',
        dataIndex: 'oyuncu',
        key: 'oyuncu',
        
      },
      {
        title: 'Doğum Tarihi',
        dataIndex: 'dogumTarihi',
        key: 'dogumTarihi',
        
      },
      {
        title: 'Yaş',
        dataIndex: 'yas',
        key: 'yas',
        
      },
      {
        title: 'Kadro',
        dataIndex: 'kadro',
        key: 'kadro',
        
      },
      {
        title: 'Mevcut Pozisyon',
        dataIndex: 'mevcutPozisyon',
        key: 'mevcutPozisyon',
        
      },
      {
        title: 'Oynayacağı Pozisyon',
        dataIndex: 'oynayacagiPozisyon',
        key: 'oynayacagiPozisyon',
        
      },
    ],
  },
  {
    title:  <span className='text-red-500'>{'Oynama Süreleri'}</span>,
    children: [
      {
        title: 'Girdiği Dakika',
        dataIndex: 'girdigiDakika',
        key: 'girdigiDakika',
        
      },
      {
        title: 'Çıktığı Dakika',
        dataIndex: 'ciktigDakika',
        key: 'ciktigDakika',
        
      },
      {
        title: 'Oynadığı Süre',
        dataIndex: 'oynadigiSure',
        key: 'oynadigiSure',
        
      },
    ],
  },
  {
    title:  <span className='text-red-500'>{'Maç Notları'}</span>,
    children: [
      {
        title: 'Maç Notu - Teknik Sorumlu',
        dataIndex: 'macNotuTeknik',
        key: 'macNotuTeknik',
        
      },
      {
        title: 'Maç Notu - Takım Antrenör',
        dataIndex: 'macNotuTakim',
        key: 'macNotuTakim',
        
      },
      {
        title: 'Maç Notu - Teknik Direktör',
        dataIndex: 'macNotuDirektor',
        key: 'macNotuDirektor',
        
      },
      {
        title: 'Ortalama Maç Notu',
        dataIndex: 'ortalamaMacNotu',
        key: 'ortalamaMacNotu',
        
      },
    ],
  },
  {
    title:  <span className='text-red-500'>{'Catapult - Koşu Mesafeleri'}</span>,
    children: [
      {
        title: 'Koşu Mesafesi (m)',
        dataIndex: 'kossuMesafesi',
        key: 'kossuMesafesi',
        
      },
      {
        title: 'Sprint Sayısı',
        dataIndex: 'sprintSayisi',
        key: 'sprintSayisi',
        
      },
      {
        title: 'Yüksek Şiddetli Koşu',
        dataIndex: 'yuksekSiddetliKossu',
        key: 'yuksekSiddetliKossu',
        
      },
      {
        title: 'Sprint Mesafesi',
        dataIndex: 'sprintMesafesi',
        key: 'sprintMesafesi',
      },
    ],
  },
  {
    title:  <span className='text-red-500'>{'Catapult - IMA (Hareketlilik)'}</span>,
    children: [
      {
        title: 'Toplam Hareketlilik',
        dataIndex: 'toplamHareketlilik',
        key: 'toplamHareketlilik',
      },
      {
        title: 'Yüksek Şiddetli Hareketlilik / Toplam Hareketlilik Oranı',
        dataIndex: 'hareketlilikOrani',
        key: 'hareketlilikOrani',
      },
    ],
  },
];


const data = [
  {
    key: '1',
    siraNo: '1',
    formaNo: '19',
    oyuncu: 'Arda Yılmaz',
    dogumTarihi: '01.09.2000',
    yas: '20',
    kadro: 'İlk Kadro',
    mevcutPozisyon: '1',
    oynayacagiPozisyon: '1',
    girdigiDakika: '0',
    ciktigDakika: '90',
    oynadigiSure: '90',
    macNotuTeknik: '7',
    macNotuTakim: '8',
    macNotuDirektor: '-',
    ortalamaMacNotu: '7,50',
    kossuMesafesi: '10.895m',
    sprintSayisi: '6',
    yuksekSiddetliKossu: '-',
    sprintMesafesi: '245',
    toplamHareketlilik: '10.895m',
    hareketlilikOrani: '6',
  },
  // Repeat the above data structure for more rows
];


const MatchesReportPlayers = () => {
  return (
    <div className="p-8 bg-white rounded-lg flex flex-col ">
      <p className="pb-8 text-2xl font-semibold">Oyuncular</p>
      <div className='overflow-x-auto'>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        className="ant-table-content"
      />
      </div>
    </div>

  );
};

export default MatchesReportPlayers;
