import { type } from "@testing-library/user-event/dist/type";
import { message } from "antd";
import dayjs from "dayjs";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const handleCustomChange = (
  event1,
  event2,
  name,
  setFieldValue,
  component_type
) => {
  // Form değerlerini güncelleme
  switch (component_type) {
    case "datePicker":
      // const dateString = dayjs(event1).format("YYYY-MM-DD");
      // console.log("event1 değişti dateString:", dateString);
      // console.log("orijinal date Format:", dayjs(dateString, "YYYY-MM-DD"));
      console.log("event1.value değişti:", event1.target.value);
      setFieldValue(name, event1.target.value);
      break;

    case "dateRange":
      const start = dayjs(event1[0]).format("DD-MM-YYYY HH:mm");
      const end = dayjs(event1[1]).format("DD-MM-YYYY HH:mm");
      console.log("event1 değişti dateRange:", [event1, event2]);
      setFieldValue(name, event1);
      break;

    case "select":
      console.log("event1.value değişti:", event1);
      setFieldValue(name, event1);
      break;

    case "checkbox":
      console.log("event1.target.checked değişti:", event1.target.checked);
      setFieldValue(name, event1.target.checked);
      break;

    case "input":
      console.log("event1.target.value değişti:", event1.target.value);
      setFieldValue(name, event1.target.value);
      break;

    case "timePicker":
      console.log("timePicker:", event1.target.value);
      setFieldValue(name, event1.target.value);
      break;

    case "textarea":
      console.log("event1.target.value değişti:", event1.target.value);
      setFieldValue(name, event1.target.value);
      break;

    case "phoneNumber":
      console.log("event1 değişti:", event1);
      setFieldValue(name, event1);
      break;

    case "fileUpload":
      console.log("event1.target.value değişti:", event1);

      setFieldValue(name, event1);
      return false;
      break;

    default:
      console.log("handleCustomChange default değişti", event1, event2);
      break;
  }
};
