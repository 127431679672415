import headerImage from "../assets/images/header.jpg";
import profile from "../assets/images/background.jpeg";
import authBackground from "../assets/images/header.jpg";

const Files = {
  profile,
  headerImage,
  authBackground,
};

export default Files;

// kullanım dışı, yerine filePath.js.
