import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";

import { BiErrorCircle } from "react-icons/bi";
import { AiFillCheckCircle } from "react-icons/ai";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import SelectInput from "../select";
import { Checkbox, DatePicker, TimePicker } from "antd";

import dayjs from "dayjs";

import tr_TR from "antd/lib/locale/tr_TR";

function InputComponent({
  labelTitle,
  id,
  name,
  type,
  onChange,
  value,
  error,
  isTouched,
  onBlur,
  containerStyle,
  icon,
  placeholder,
  textarea,
  rows,
  required,
  leftIcon,
  leftIconName,
  mask,
  maxLength,
  phoneNumber,
  select,
  options,
  onlyNumber,
  time,
  disabled,
  rangePicker,
  dateInput,
  dataInputShowTime,
  presets,
  checkbox,
  checkboxLabel,
  
}) {
  const isError = error?.[name] && isTouched?.[name];
  const isSuccess = value?.[name] && isTouched?.[name] && !isError;

  //bu kodlar ile phone değerini alıp formikteki phoneNumber alanına atıyoruz.
  const [valuePhone, setValuePhone] = useState("");
  useEffect(() => {
    valuePhone == undefined
      ? setValuePhone("")
      : phoneNumber && (value["phoneNumber"] = valuePhone); //phoneNumber eğer yoksa value'de phoneNumber name'ini aramaz ve hata vermez.
  }, [valuePhone]);

  //DatePicker
  const { RangePicker } = DatePicker;

  // const rangePresets = [
  //   {
  //     label: "Sonraki 7 Gün",
  //     value: [dayjs().add(+7, "d"), dayjs()],
  //   },
  //   {
  //     label: "Sonraki 14 Gün",
  //     value: [dayjs().add(+14, "d"), dayjs()],
  //   },
  //   {
  //     label: "Sonraki 30 Gün",
  //     value: [dayjs().add(+30, "d"), dayjs()],
  //   },
  //   {
  //     label: "Sonraki 90 Gün",
  //     value: [dayjs().add(+90, "d"), dayjs()],
  //   },
  // ];

  return (
    <>
      <div
        className={`relative ${
          disabled && "cursor-not-allowed z-50"
        } ${containerStyle}`}
      >
        <div className="flex flex-col">
          <p className="flex items-start justify-start py-1 text-lg">
            {labelTitle}
            {required && <span className="text-sm text-primary">*</span>}
          </p>
          <div
            className={`border rounded-lg min-h-[56px] ${
              isError && "border-red-500"
            } ${isSuccess && "border-green-500"} flex items-center px-4 gap-4 `}
          >
            {/* sol icon */}
            {leftIcon  && (
              <div
                className={`${isError && "text-primary"} ${
                  isSuccess && "text-green-500"
                } w-6 h-6 aspect-square`}
              >
                {leftIcon} 
              </div>
            )}
             {leftIconName && (
              <div
                className={`${isError && "text-primary"} ${
                  isSuccess && "text-green-500"
                } w-6 h-6 aspect-square`}
              >
                {leftIconName} 
              </div>
            )}
            {/* sol icon */}
            {phoneNumber ? (
              <PhoneInput
                disabled={disabled}
                id={id}
                name={name}
                type={type}
                value={valuePhone}
                onChange={setValuePhone}
                maxLength={maxLength}
                onBlur={onBlur}
                className={`w-full max-h-[56px] text-md outline-none text-[#5F6368] bg-transparent`}
                placeholder={placeholder}
                defaultCountry="TR"
              />
            ) : select ? (
              <SelectInput onChange={onChange} options={options} />
            ) : textarea ? (
              <textarea
                disabled={disabled}
                rows={rows}
                id={id}
                name={name}
                type={type}
                onChange={onChange}
                value={value[name]}
                onBlur={onBlur}
                className={`w-full min-h-[56px] text-md outline-none text-[#5F6368] bg-transparent resize-y py-4`}
                placeholder={placeholder}
              />
            ) : time ? (
              <TimePicker
                showNow={false}
                disabled={disabled}
                value={value[name]}
                onChange={onChange}
                style={{
                  height: "56px",
                  width: "100%",
                  border: 0,
                  backgroundColor: "white",
                }}
                format="HH:mm"
              />
            ) : dateInput ? (
              // <DatePicker
              //   locale={tr_TR} // Türkçe dil dosyasını locale prop'uyla ekleyin
              //   // disabled={disabled}
              //   // defaultValue={dayjs("2015-01-01", "YYYY-MM-DD")}
              //   format="DD-MM-YYYY"
              //   value={value[name]}
              //   onChange={onChange}
              //   style={{
              //     height: "56px",
              //     width: "100%",
              //     border: 0,
              //     backgroundColor: "white",
              //   }}
              // />
              <DatePicker
                locale={tr_TR}
                disabled={disabled}
                format="DD-MM-YYYY"
                value={value[name]}
                onChange={onChange}
                style={{
                  height: "56px",
                  width: "100%",
                  border: 0,
                  backgroundColor: "white",
                }}
              />
            ) : rangePicker ? (
              <RangePicker
                locale={tr_TR}
                showTime={dataInputShowTime}
                disabled={disabled}
                format="DD-MM-YYYY HH:mm"
                // presets={presets && rangePresets}
                value={value[name]}
                onChange={onChange}
                style={{
                  height: "56px",
                  width: "100%",
                  border: 0,
                  backgroundColor: "white",
                }}
              />
            ) : checkbox ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  style={{
                    margin: "0",
                  }}
                  onChange={onChange}
                  value={value[name]}
                >
                  <span>{checkboxLabel}</span>
                </Checkbox>
              </div>
            ) : (
              <input
                disabled={disabled}
                id={id}
                name={name}
                type={type}
                onChange={onChange}
                value={
                  onlyNumber
                    ? value[name]?.replace(/\D/g, "").replace(/^/g, "")
                    : // : type == "date"
                      // ? value[name]?.toISOString().split("T")[0]
                      value[name] ?? value
                }
                maxLength={maxLength}
                onBlur={onBlur}
                className={`w-full min-h-[56px] text-md outline-none text-[#5F6368] bg-transparent`}
                placeholder={placeholder}
                required={required}
              />
            )}

            {isError ? (
              <BiErrorCircle className="text-2xl text-primary" />
            ) : isSuccess ? (
              <AiFillCheckCircle className="text-2xl text-green-500" />
            ) : null}
          </div>
        </div>
        <p className="relative mt-2 text-primary">{isError && error[name]}</p>
      </div>
    </>
  );
}

export default InputComponent;
