import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Paths } from "../../../../routes";
import { useFormik } from "formik";
import { PulseLoader } from "react-spinners";
import { BsChevronLeft } from "react-icons/bs";
import { message } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import validations from "../../../../utils/validations";
import InputComponent from "../../../../components/inputs";
import { UserFormInputsData } from "../../../../utils/datas/UserFormInputsData";
import TabbarComponent from "../../../../components/tabbar";
import NextButton from "../../../../components/buttons/NextButton";
import AddPlayerDynamicForm from "../../../dynamicForm/addPlayerDynamicForm";
import UserInformationForm from "../../../dynamicForm/addPlayerDynamicForm/userInformationForm";
import { getUser } from "../../../../services/axios";
import AddUserStaticForm from "../../../users/allUsers/addUser/addUserStaticForm";

const EditCoaches = () => {
  const { id } = useParams();
  const [setBreadcrumbData] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [isDataChange, setDataChange] = useState(true);
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [tabbarData, setTabbarData] = useState(null);

  const navigate = useNavigate();

  const [selectedTabId, setSelectedTabId] = useState(0);
  console.log(selectedTabId, "selectedTabId");

  const fetchGetUser = async (userID) => {
    setLoading(true);
    try {
      const result = await getUser(userID);
      console.log(result.data.user, "result Tabbar");
      setLoading(false);
      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setDataChange(false);
    }
  };

  const tabbarSwitch = () => {
    switch (selectedUserRole) {
      case "team_coach":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 6, title: "Antrenör Bilgileri" },
        ];

      case "parent":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 7, title: "Veli Bilgileri" },
        ];

      case "admin":
        return [{ id: 0, title: "Kullanıcı Bilgileri" }];

      case "footballer":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 1, title: "Özlük Bilgileri" },
          { id: 2, title: "Bedensel Özellikleri" },
          { id: 3, title: "Lisans Bilgileri" },
          { id: 4, title: "Disiplin Kararları" },
          { id: 5, title: "Aile ve Okul Bilgileri" },
          { id: 7, title: "Veli Bilgileri" },
        ];

      case "candidate":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 1, title: "Aday Oyuncu Bilgileri" },
        ];
      default:
        return [{ id: 0, title: "Kullanıcı Bilgileri" }];
    }
  };

  // useEffect(() => {
  //   if (isDataChange) {
  //     fetchGetUser(id);
  //   }
  // }, [isDataChange]);

  // useEffect(() => {
  //   setDataChange(true);
  // }, [selectedTabId]);

  return (
    <>
      <div className="">
        {selectedUserRole && (
          <TabbarComponent
            data={tabbarSwitch()}
            selectedTabId={selectedTabId}
            setSelectedTabId={setSelectedTabId}
            type="card"
          />
        )}
      </div>
      <div className="w-full p-4 bg-white shadow rounded-b-md">
        <div className="w-full p-4 bg-white rounded-md shadow pb-96">
          <div className="flex items-center text-gray-500">
            <button onClick={() => navigate(-1)}>
              <BsChevronLeft size={21} />
            </button>
            <h4 className="ml-4 text-lg font-semibold">Antrenör Düzenle</h4>
          </div>

          {/* tabbar */}

          {/* tabbar */}
          {/* form */}
          {selectedTabId == 0 && (
            <AddUserStaticForm
              setDataChange={setDataChange}
              isDataChange={isDataChange}
              updateForm={true}
              setSelectedUserRole={setSelectedUserRole}
            />
          )}
          {selectedTabId == 1 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"add-player-personal-info"}
            />
          )}
          {selectedTabId == 2 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"physical_characteristics_and_health_status"}
            />
          )}
          {selectedTabId == 3 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"license_and_club_information"}
            />
          )}
          {selectedTabId == 4 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"disciplinary_decisions_and_separation_information"}
            />
          )}
          {selectedTabId == 5 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"family_and_school_information"}
            />
          )}
          {selectedTabId == 6 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"add-coach-info"}
            />
          )}
          {selectedTabId == 7 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"add-parrent-info"}
            />
          )}

          {/* form */}

          <div className="w-full mt-4 text-end">
            <div className="inline-flex items-center gap-4">
              <NextButton
                length={UserFormInputsData?.length}
                setSelectedTabId={setSelectedTabId}
                selectedTabId={selectedTabId}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCoaches;
