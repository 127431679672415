import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Paths } from "../../routes";
import TreatmentsList from "./treatmentsList";

const Treatments = () => {
  const [setBreadcrumbData] = useOutletContext();

  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel"
      },
      {
        title: <Link to={Paths.Treatments}>Tedaviler</Link>
      }
    ]);
  }, []);
  return (
    <>
      <div className="w-full p-4 bg-white rounded-md shadow">
        <TreatmentsList />
      </div>
    </>
  );
};

export default Treatments;
