import React, { useEffect, useState } from "react";
import { getAllTests, getAllUnits, getTestById } from "../../services/axios";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { CarryOutOutlined, SettingOutlined } from "@ant-design/icons";
import { PulseLoader } from "react-spinners";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import CreateJudgeModal, {
  CreateJudgeForm,
} from "../../containers/modals/CreateJudgeModal";
import CreateTestModal, {
  CreateTestForm,
} from "../../containers/modals/CreateTestModal";
import { Tree } from "antd";

function TestDefinitions() {
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDataChange, setIsDataChange] = useState(true);
  const [allUnits, setAllUnits] = useState();
  const [treeData, setTreeData] = useState([]);
  const [allTests, setAllTests] = useState([]);

  const onSelect = async (selectedKeys, info) => {
    // console.log("objectselectedKeys: ", selectedKeys[0], " ", info);
    setFormLoading(true);
    try {
      const result = await getTestById(selectedKeys[0]); //tek kategori çağrıma
      // console.log("resultttttt: ", result?.data?.test);
      setSelectedTest(result?.data?.test);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setFormLoading(false);
    }
  };

  function getChildren(test) {
    if (!test.sub_category || test.sub_category.length === 0) {
      return [];
    }
    return test.sub_category.map((item) => {
      const treeItem = getTreeItem(
        item.id,
        item.title,
        item.id,
        <CarryOutOutlined />
      );
      const children = getChildren(item);
      treeItem.children = children;
      return treeItem;
    });
  }

  function getTreeItem(id, title, key, icon, children = []) {
    return {
      id,
      title,
      key,
      icon,
      children,
    };
  }

  const handleDataPush = (item) => {
    setTreeData((prev) => [...prev, item]);
  };

  const fetchListUnits = async () => {
    try {
      const result = await getAllUnits();
      setAllUnits(result.data.units);
      // console.log("result.data.units:  ", result.data.units);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchGetAllTests = async () => {
    setLoading(true);
    setTreeData([]);
    try {
      const result = await getAllTests();
      result?.data?.tests.forEach((item) => {
        if (!item.up_category) {
          const treeItem = getTreeItem(
            item.id,
            item.title,
            item.id,
            <CarryOutOutlined />
          );
          const children = getChildren(item);
          treeItem.children = children;
          handleDataPush(treeItem);
        }
      });
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setIsDataChange(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isDataChange) {
      fetchGetAllTests().then((result) => setAllTests(result?.data?.tests));
    }
    fetchListUnits();

    // isDataChange && fetchGetAllTests();
    // fetchListUnits();
  }, [isDataChange]);

  // const handleDataPush = (label, value) => {
  //   const newItem = getMenuItem(label, value, <SettingOutlined />, null);
  //   setMenuData((prev) => [...prev, newItem]);
  // };

  function SelectedItemForm() {
    return (
      <>
        <CreateTestForm
          isDataChange={isDataChange}
          setIsDataChange={setIsDataChange}
          selectedTest={selectedTest}
          loading={formLoading}
          allTests={allTests}
          allUnits={allUnits}
        />
      </>
    );
  }
  const { DirectoryTree } = Tree;

  return (
    <div>
      <div>
        {loading && (
          <div className="flex">
            <PulseLoader color="red" />
            <p>Yükleniyor...</p>
          </div>
        )}
        {!loading && (
          <div className="flex">
            <div className="flex flex-col w-full max-w-[350px] border-r pr-4">
              <DirectoryTree
                className="relative z-0"
                showLine={false}
                showIcon={false}
                defaultExpandedKeys={0}
                // defaultExpandAll
                onSelect={onSelect}
                treeData={treeData}
              />
              {/* <DrawerMenuAntd
                items={treeData}
                selectedKey={selectedMenuIndex}
                setSelectedKey={setSelectedMenuIndex}
              /> */}
              <ButtonComponent
                customStyle={"mt-4"}
                text={"Yeni Test Ekle"}
                outline
                onClick={() => {
                  setModalOpen(true);
                }}
              />
              <CreateTestModal
                allUnits={allUnits}
                allTests={allTests}
                isOpen={isModalOpen}
                setOpen={setModalOpen}
                setIsDataChange={setIsDataChange}
              />
            </div>
            <div className="w-full px-4">
              <SelectedItemForm />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TestDefinitions;
