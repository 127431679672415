import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Paths } from "../../../../routes";
import { useFormik } from "formik";
import { PulseLoader } from "react-spinners";
import { BsChevronLeft } from "react-icons/bs";
import { message } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import validations from "../../../../utils/validations";
import InputComponent from "../../../../components/inputs";
import { UserFormInputsData } from "../../../../utils/datas/UserFormInputsData";
import TabbarComponent from "../../../../components/tabbar";
import NextButton from "../../../../components/buttons/NextButton";
import AddPlayerDynamicForm from "../../../dynamicForm/addPlayerDynamicForm";
import UserInformationForm from "../../../dynamicForm/addPlayerDynamicForm/userInformationForm";
import AddUserStaticForm from "../../allUsers/addUser/addUserStaticForm";
import { getUser } from "../../../../services/axios";

const EditPlayers = () => {
  const { id } = useParams();
  const [setBreadcrumbData] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [isDataChange, setDataChange] = useState(true);
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [tabbarData, setTabbarData] = useState(null);
  const [playerName, setPlayerName] = useState([])
  
  const navigate = useNavigate();
  
  const [selectedTabId, setSelectedTabId] = useState(0);
  console.log(selectedTabId, "selectedTabId");
  
  const fetchGetUser = async () => {
    setLoading(true);
    try {
      const result = await getUser(id);
      const zeta = result.data.user
      setPlayerName(zeta)
      setLoading(false);
      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setDataChange(false);
    }
  };
  useEffect(() => {
    fetchGetUser()
  }, [])

  const tabbarSwitch = () => {
    switch (selectedUserRole) {
      case "team_coach":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 6, title: "Antrenör Bilgileri" },
        ];
      case "team_manager":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 6, title: "Antrenör Bilgileri" },
        ];
      case "technical_director":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 6, title: "Antrenör Bilgileri" },
        ];
      case "goalkeeper_coach":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 6, title: "Antrenör Bilgileri" },
        ];
      case "parent":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 7, title: "Veli Bilgileri" },
        ];

      case "admin":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 8, title: "Personel Bilgileri" },
        ];
      case "super_admin":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 8, title: "Personel Bilgileri" },
        ];
      case "club_worker":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 8, title: "Personel Bilgileri" },
        ];
      case "executive_director":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 8, title: "Personel Bilgileri" },
        ];
      case "sport_director":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 8, title: "Personel Bilgileri" },
        ];
      case "doctor":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 8, title: "Personel Bilgileri" },
        ];
      case "physiotherapist":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 8, title: "Personel Bilgileri" },
        ];
      case "masseur":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 8, title: "Personel Bilgileri" },
        ];
      case "psychologist":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 8, title: "Personel Bilgileri" },
        ];

      case "footballer":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 1, title: "Özlük Bilgileri" },
          { id: 2, title: "Bedensel Özellikleri" },
          { id: 3, title: "Lisans Bilgileri" },
          { id: 4, title: "Disiplin Kararları" },
          { id: 5, title: "Pasaport ve Vize" },
          { id: 7, title: "Veli Bilgileri" },
        ];
      // BURDAISVAR
      case "administrative_responsible":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 8, title: "Personel Bilgileri" },
        ];
      case "candidate":
        return [
          { id: 0, title: "Kullanıcı Bilgileri" },
          { id: 1, title: "Aday Oyuncu Bilgileri" },
        ];
      default:
        return [{ id: 0, title: "Kullanıcı Bilgileri" }];
    }
  };

  // useEffect(() => {
  //   if (isDataChange) {
  //     fetchGetUser(id);
  //   }
  // }, [isDataChange]);

  // useEffect(() => {
  //   setDataChange(true);
  // }, [selectedTabId]);

  return (
    <>
      <div className="flex items-center gap-2.5">
         {/* {playerName.map((item) => (
          <div>{item.name}</div>
        ))}  */}
        {playerName.name && (
           <div className="bg-white px-2 py-2.5 rounded-t-lg border-t-2 border-r-2 border-l-2 border-blue-500 text-sm font-semibold"> {playerName.name} {playerName.surname}</div>
        )}
        {selectedUserRole && (
          <TabbarComponent
            data={tabbarSwitch()}
            selectedTabId={selectedTabId}
            setSelectedTabId={setSelectedTabId}
            type="card"
          />
        )}
      </div>
      <div className="w-full p-4 bg-white shadow rounded-b-md">
        <div className="w-full p-4 bg-white rounded-md shadow pb-96">
          <div className="flex items-center text-gray-500">
            <button onClick={() => navigate(-1)}>
              <BsChevronLeft size={21} />
            </button>
            <h4 className="ml-4 text-lg font-semibold">Profil Düzenle</h4>
          </div>

          {/* tabbar */}

          {/* tabbar */}
          {/* form */}
          {selectedTabId == 0 && (
            <AddUserStaticForm
              setDataChange={setDataChange}
              isDataChange={isDataChange}
              updateForm={true}
              setSelectedUserRole={setSelectedUserRole}
            />
          )}
          {selectedTabId == 1 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"add-player-personal-info"}
            />
          )}
          {selectedTabId == 2 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"physical_characteristics_and_health_status"}
            />
          )}
          {selectedTabId == 3 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"license_and_club_information"}
            />
          )}
          {selectedTabId == 4 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"disciplinary_decisions_and_separation_information"}
            />
          )}
          {selectedTabId == 5 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"passport_visa_information"}
            />
          )}
          {selectedTabId == 6 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"add-coach-info"}
            />
          )}
          {selectedTabId == 7 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"parent_info"}
            />
          )}
          {selectedTabId == 8 && (
            <AddPlayerDynamicForm
              isDataChange={isDataChange}
              setDataChange={setDataChange}
              formName={"manage_support_information"}
            />
          )}

          {/* form */}

          <div className="w-full mt-4 text-end">
            <div className="inline-flex items-center gap-4">
              <NextButton
                length={UserFormInputsData?.length}
                setSelectedTabId={setSelectedTabId}
                selectedTabId={selectedTabId}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPlayers;
