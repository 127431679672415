import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components/tables";
import { getRecentlyAddedCandidates } from "../../../services/axios";

const RecentlyAddedCandidate = () => {
  const columnsCandidates = [
    {
      title: "Adı Soyadı",
      dataIndex: "candidateName"
    }
  ];
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchGetRecentlyAddedCandidate = async () => {
    try {
      setLoading(true);
      const results = await getRecentlyAddedCandidates(10);
      let recentlyCandidates = results.response.data.map((item) => ({
        candidateName: item.user_name ? item.user_name : "-"
      }));
      setCandidates(recentlyCandidates);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetRecentlyAddedCandidate();
  }, []);

  return (
    <>
      <div className="px-4 py-8 bg-white rounded-2xl">
        <div className="flex items-center justify-between">
          <p className="font-semibold text-gray-400">Son Eklenen Adaylar</p>
          <a href="/applicants" className="text-sm hover:underline text-primary">Tüm Adaylar</a>
        </div>
        <div className="pt-4">
          <DataTable
            columns={columnsCandidates}
            data={candidates}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default RecentlyAddedCandidate;
