import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Paths } from "../../../routes";
import AllUsersList from "./allUsersList";

function AllUsers() {
  const [setBreadcrumbData] = useOutletContext();

  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel",
      },
      {
        title: <Link to={Paths.Users.AllUsers.AllUsers}>Tüm Kullanıcılar</Link>,
      },
    ]);
  }, []);
  return (
    <div className="w-full p-4 bg-white rounded-md shadow">
      <AllUsersList />
    </div>
  );
}

export default AllUsers;
