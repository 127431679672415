import React, { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";

import { BiErrorCircle } from "react-icons/bi";
import { AiFillCheckCircle } from "react-icons/ai";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
    Button,
    Checkbox,
    DatePicker,
    TimePicker,
    message,
    Upload
} from "antd";
import Select from "react-select";
import { Select as AntdSelect } from "antd";

import dayjs from "dayjs";

import tr_TR from "antd/lib/locale/tr_TR";
import { UploadOutlined } from "@ant-design/icons";

import { InboxOutlined } from "@ant-design/icons";
import { getSelects, searchUser } from "../../services/axios";
import moment from "moment";
import AsyncSelect from "react-select/async";

const { Dragger } = Upload;

function DynamicInput({
    labelTitle,
    id,
    name,
    type,
    onChange,
    value,
    error,
    isTouched,
    onBlur,
    containerStyle,
    containerStyleInner,
    placeholder,
    rows,
    required,
    leftIcon,
    leftIconTeam,
    leftIconPursue,
    leftIconDate,
    leftIconCase,
    leftIconTourney,
    leftIconMatch,
    leftIconNotes,
    maxLength,
    phoneNumber,
    options, //options id gelir, ona göre eşleşme yapılır.
    customOptions, //kişisel array döndrürülür.
    onlyNumber,
    disabled,
    checkboxLabel,
    dateDefaultValue,
    defaultSelectValue,
    noBorder,
    //
    component_type,
    minHeight,
    isMulti,
    isClearable,
    defaultOption,
    defaultValue,
    files,
}) {
    const isError = error?.[name] && isTouched?.[name];
    const isSuccess = value?.[name] && isTouched?.[name] && !isError;
    noBorder = !noBorder;
    //DatePicker
    const { RangePicker } = DatePicker;

    // const fileUploadProps = {
    //   name: name,
    //   multiple: true,
    //   onChange: onChange,
    //   onDrop(e) {
    //     console.log("Dropped files", e.dataTransfer.files);
    //   },
    // };

    function ComponentTypeSwitch(component_type) {
        const [loading, setLoading] = useState(false);
        // const [selectsResponseDataItem, setSelectsResponseDataItem] = useState([]);
        const [filteredIdOptions, setFilteredIdOptions] = useState(
            options?.options?.map((item, index) => ({
                label: item?.label,
                value: item?.id
            })) ?? []
        );
        const [isDataChange, setIsDataChange] = useState(false);

        const dateInputRef = useRef(null); // Ref oluştur
        const openCalendar = () => {
            dateInputRef.current.click();
        };

        // // SELECT
        // async function fetchGetSelects() {
        //   console.log("istek");
        //   setLoading(true);
        //   setSelectsResponseDataItem([]);
        //   setIsDataChange(false);
        //   try {
        //     const result = await getSelects();
        //     setSelectsResponseDataItem(result.response);
        //     setIsDataChange(true);
        //     return result;
        //   } catch (error) {
        //     console.error("Hata oluştu:", error);
        //   } finally {
        //     setLoading(false);
        //   }
        // }
        // async function filteredOptions() {
        //   setLoading(true);
        //   try {
        //     const filteredOptions = selectsResponseDataItem?.filter(
        //       (item) => item?.id == options
        //     );
        //     setFilteredIdOptions(filteredOptions);
        //     setLoading(false);
        //   } catch (error) {
        //     console.log(error, "hata");
        //   }
        // }
        // useEffect(() => {
        //   if (selectsResponseDataItem?.length === 0 && component_type == "select") {
        //     fetchGetSelects();
        //   }
        // }, []);
        // useEffect(() => {
        //   if (isDataChange && component_type == "select") {
        //     filteredOptions();
        //   }
        // }, [isDataChange]);
        // // console.log(selectsResponseDataItem, "selectsResponseDataItem");
        // // console.log(filteredIdOptions, "selectresponse");
        // // SELECT

        switch (component_type) {
            case "fileUpload":
                return (
                    <>
                        <div className="relative z-0 w-full">
                            <Dragger
                                fileList={files}
                                listType="picture"
                                multiple={false}
                                beforeUpload={onChange}
                                style={{
                                    width: "100%",
                                    height: "32px",
                                    border: 0,
                                    backgroundColor: "white"
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    <p className="text-4xl text-primary">
                                        <InboxOutlined />
                                    </p>
                                    <div className="flex flex-col">
                                        {/* <p className="ant-upload-text">
                  Dosyayı sürükleyip bırakın ya da tıklayın
                </p> */}
                                        <p className="ant-upload-hint">
                                            Dosyanızı bu alana yükleyin
                                        </p>
                                    </div>
                                </div>
                            </Dragger>
                            {/* <input
                disabled={disabled}
                id={id}
                name={name}
                type={"file"}
                onChange={onChange}
                value={value?.[name]}
                maxLength={maxLength}
                onBlur={onBlur}
                className={`w-full relative z-0 min-h-[56px] text-md outline-none text-[#5F6368] bg-transparent`}
                placeholder={placeholder}
              /> */}
                        </div>
                    </>
                );
                break;
            case "input":
                return (
                    <>
                        <input
                            disabled={disabled}
                            id={id}
                            name={name}
                            type={type}
                            onChange={onChange}
                            value={
                                onlyNumber
                                    ? value?.[name]?.replace(/\D/g, "").replace(/^/g, "")
                                    : value?.[name]
                                // : type == "date"
                                // ? value?.[name]?.toISOString().split("T")[0]
                            }
                            maxLength={maxLength}
                            onBlur={onBlur}
                            className={`w-full relative z-0 min-h-[56px] text-md outline-none text-[#5F6368] bg-transparent`}
                            placeholder={placeholder}
                            min="0"
                        />
                    </>
                );
                break;
            case "phoneNumber":
                return (
                    <>
                        <PhoneInput
                            disabled={disabled}
                            id={id}
                            name={name}
                            type={type}
                            value={value?.[name]}
                            onChange={onChange}
                            maxLength={maxLength}
                            onBlur={onBlur}
                            className={`w-full relative max-h-[56px] z-0 text-md outline-none text-[#5F6368] bg-transparent`}
                            placeholder={placeholder}
                            defaultCountry="TR"
                        />
                    </>
                );
                break;
            case "checkbox":
                return (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Checkbox
                                style={{
                                    margin: "0"
                                }}
                                onChange={onChange}
                                checked={value?.[name] ?? value}
                                value={value?.[name] ?? value}
                            >
                                <span>{placeholder}</span>
                            </Checkbox>
                        </div>
                    </>
                );
                break;
            case "select":
                return (
                    <>
                        <div className="w-full">
                            <Select
                                isDisabled={disabled}
                                onChange={onChange}
                                value={value?.[name]}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: 0,
                                        boxShadow: "none"
                                    })
                                }}
                                options={options ? filteredIdOptions : customOptions}
                                className="selectInput"
                                placeholder={placeholder}
                                required={required}
                            />
                        </div>
                    </>
                );
                break;
            case "selectColor":
                const coloredOptions = customOptions.map((option, index) => ({
                    ...option,
                    color:
                        index === 0
                            ? "#22c55e"
                            : index === 1
                                ? "#eab308"
                                : index === 2
                                    ? "#ef4444"
                                    : null
                }));
                return (
                    <>
                        <div className="w-full">
                            <Select
                                isDisabled={disabled}
                                onChange={onChange}
                                value={value?.[name]}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: 0,
                                        boxShadow: "none"
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.data.color || "transparent",
                                        color: state.data.color ? "black" : "black"
                                    })
                                }}
                                options={options ? filteredIdOptions : coloredOptions}
                                className="selectInput"
                                placeholder={placeholder}
                                required={required}
                            />
                        </div>
                    </>
                );
            case "asyncSelect":
                return <div className={"w-full"}>
                    <AsyncSelect
                        isDisabled={disabled}
                        onChange={onChange}
                        value={value?.[name]}
                        placeholder={placeholder}
                        className="selectInput"
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                border: 0,
                                boxShadow: "none"
                            })
                        }}
                        isClearable={isClearable}
                        isMulti={isMulti}
                        loadOptions={customOptions}
                        defaultOptions={defaultOption}
                        defaultValue={defaultValue}
                        required={required}
                    />
                </div>
            case "textarea":
                return (
                    <>
                        <textarea
                            disabled={disabled}
                            rows={rows}
                            id={id}
                            name={name}
                            type={type}
                            onChange={onChange}
                            value={value?.[name] ?? "" ?? value}
                            onBlur={onBlur}
                            className={`w-full relative z-0 min-h-[56px] text-md outline-none text-[#5F6368] bg-transparent resize-y py-4`}
                            placeholder={placeholder}
                        />
                    </>
                );
                break;
            case "timePicker":
                return (
                    <>
                        {/* <TimePicker
              showNow={false}
              disabled={disabled}
              value={value?.[name]}
              onChange={onChange}
              style={{
                height: "56px",
                width: "100%",
                border: 0,
                backgroundColor: "white",
              }}
              format="HH:mm"
            /> */}
                        <input
                            disabled={disabled}
                            id={id}
                            name={name}
                            type={"time"}
                            onChange={onChange}
                            value={
                                value?.[name]
                                // value?.[name]?.toISOString().split("T")[0]
                            }
                            onBlur={onBlur}
                            className={`w-full relative z-0 min-h-[56px] text-md outline-none text-[#5F6368] bg-transparent`}
                            placeholder={placeholder}
                        // ref={dateInputRef}
                        // onClick={() => openCalendar}
                        />
                    </>
                );
                break;
            case "datePicker":
                // let selectedDate;
                // if (typeof value?.[name] === "string") {
                //   // selectedDate = dayjs(value?.[name]);
                //   selectedDate = null;
                // }
                // if (typeof value?.[name] === "object") {
                //   selectedDate = value?.[name];
                // }

                // console.log(value?.[name], "omer value");
                // console.log(dateDefaultValue, "omer default");
                // console.log(typeof value?.[name], "omer"); //string
                // console.log(typeof dayjs(value?.[name]), "omer"); //object

                // console.log(selectedDate, "selectedDate");

                return (
                    <>
                        {/* <DatePicker
              onClick={() => console.log("omer")}
              locale={tr_TR}
              disabled={disabled}
              format="YYYY-MM-DD"
              value={dateDefaultValue ? selectedDate ?? null : value?.[name]}
              // defaultValue={dateDefaultValue && dayjs(dateDefaultValue)}
              onChange={onChange}
              placeholder={
                dateDefaultValue
                  ? dayjs(dateDefaultValue).format("YYYY-MM-DD")
                  : "Tarih Giriniz..."
              }
              style={{
                height: "56px",
                width: "100%",
                border: 0,
                backgroundColor: "white",
              }}
            /> */}

                        <input
                            disabled={disabled}
                            id={id}
                            name={name}
                            type={"date"}
                            onChange={onChange}
                            value={
                                value?.[name]
                                // value?.[name]?.toISOString().split("T")[0]
                            }
                            onBlur={onBlur}
                            className={`w-full relative z-0 min-h-[56px] text-md outline-none text-[#5F6368] bg-transparent`}
                            placeholder={placeholder}
                        // ref={dateInputRef}
                        // onClick={() => openCalendar}
                        />
                    </>
                );
                break;
            case "dateRange":
                return (
                    <>
                        <RangePicker
                            locale={tr_TR}
                            showTime={true}
                            disabled={disabled}
                            format="DD-MM-YYYY HH:mm"
                            // presets={presets && rangePresets}
                            value={value?.[name] || null}
                            onChange={onChange}
                            style={{
                                height: "56px",
                                width: "100%",
                                border: 0,
                                backgroundColor: "white"
                            }}
                        />
                    </>
                );
                break;
            case "multipleSelect":
                console.log("Multipleasfsagfasgasg", value?.[name]);
                return (
                    <>
                        <div className="w-full">
                            <AntdSelect
                                mode="multiple"
                                allowClear
                                style={{
                                    width: "100%"
                                }}
                                defaultValue={value?.[name].length <= 0 ? [] : value?.[name]}
                                placeholder={placeholder}
                                onChange={onChange}
                                options={options ? filteredIdOptions : customOptions}
                                filterOption={(input, option) =>
                                    (option?.label.toLowerCase() ?? "").includes(
                                        input.toLowerCase()
                                    )
                                }
                                isDisabled={disabled}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: 0,
                                        boxShadow: "none"
                                    })
                                }}
                                className="selectInput"
                            />
                        </div>
                    </>
                );
                break;
            default:
                return (
                    <>
                        <input
                            disabled={disabled}
                            id={id}
                            name={name}
                            type={type}
                            onChange={onChange}
                            value={
                                onlyNumber
                                    ? value?.[name]?.replace(/\D/g, "").replace(/^/g, "")
                                    : value?.[name] ?? value
                                // : type == "date"
                                // ? value?.[name]?.toISOString().split("T")[0]
                            }
                            maxLength={maxLength}
                            onBlur={onBlur}
                            className={`w-full relative z-0 min-h-[56px] text-md outline-none text-[#5F6368] bg-transparent ${containerStyleInner}`}
                            placeholder={placeholder}
                            required={required}
                        />
                    </>
                );
                break;
        }
    }

    return (
        <>
            <div
                className={`relative ${disabled && "cursor-not-allowed"
                    } ${containerStyle}`}
            >
                <div className="flex flex-col">
                    <p className="flex items-start justify-start py-1 text-lg">
                        {labelTitle}
                        {required && <span className="text-sm text-primary">*</span>}
                    </p>
                    <div
                        className={`border rounded-lg ${minHeight ? minHeight : "min-h-[56px]"
                            } ${isError && "border-red-500"} ${isSuccess && noBorder && "border-green-500"
                            } flex items-center px-4 gap-4 `}
                    >
                        {/* sol icon */}
                        {leftIcon && (
                            <div
                                className={`${isError && "text-primary"} ${isSuccess && noBorder && "text-green-500"
                                    } w-6 h-6 aspect-square`}
                            >
                                {leftIcon}
                            </div>
                        )}
                        {leftIconTeam && (
                            <div
                                className={`${isError && "text-primary"} ${isSuccess && noBorder && "text-green-500"
                                    } w-6 h-6 aspect-square`}
                            >
                                {leftIconTeam}
                            </div>
                        )}
                        {leftIconPursue && (
                            <div
                                className={`${isError && "text-primary"} ${isSuccess && noBorder && "text-green-500"
                                    } w-6 h-6 aspect-square`}
                            >
                                {leftIconPursue}
                            </div>
                        )}
                        {leftIconDate && (
                            <div
                                className={`${isError && "text-primary"} ${isSuccess && noBorder && "text-green-500"
                                    } w-6 h-6 aspect-square`}
                            >
                                {leftIconDate}
                            </div>
                        )}
                        {leftIconCase && (
                            <div
                                className={`${isError && "text-primary"} ${isSuccess && noBorder && "text-green-500"
                                    } w-6 h-6 aspect-square`}
                            >
                                {leftIconCase}
                            </div>
                        )}
                        {leftIconTourney && (
                            <div
                                className={`${isError && "text-primary"} ${isSuccess && noBorder && "text-green-500"
                                    } w-6 h-6 aspect-square`}
                            >
                                {leftIconTourney}
                            </div>
                        )}
                        {leftIconMatch && (
                            <div
                                className={`${isError && "text-primary"} ${isSuccess && noBorder && "text-green-500"
                                    } w-6 h-6 aspect-square`}
                            >
                                {leftIconMatch}
                            </div>
                        )}
                        {leftIconNotes && (
                            <div
                                className={`${isError && "text-primary"} ${isSuccess && noBorder && "text-green-500"
                                    } w-6 h-6 aspect-square`}
                            >
                                {leftIconNotes}
                            </div>
                        )}

                        {/* İÇ */}
                        {ComponentTypeSwitch(component_type)}
                        {/* İÇ */}
                        {isError ? (
                            <BiErrorCircle className="text-2xl text-primary" />
                        ) : isSuccess && noBorder ? (
                            <AiFillCheckCircle className="text-2xl text-green-500" />
                        ) : null}
                    </div>
                </div>
                <p className="relative mt-2 text-primary">{isError && error[name]}</p>
            </div>
        </>
    );
}

export default DynamicInput;
