import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Paths } from "../../routes";
import MatchesList from "./matchesList";

const Matches = () => {
  const [setBreadcrumbData] = useOutletContext();

  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel",
      },
      {
        title: <Link to={Paths.Matches}>Maçlar</Link>,
      },
    ]);
  }, []);
  return (
    <>
      <div className="w-full p-4 bg-white rounded-md shadow">
        <MatchesList />
      </div>
    </>
  );
};

export default Matches;
