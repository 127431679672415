import React from "react";
import { Paths } from "../routes";
import {
  FiActivity,
  FiCalendar,
  FiHome,
  FiSettings,
  FiUser,
} from "react-icons/fi";
import { BiBorderAll, BiFootball, BiStats, BiUser } from "react-icons/bi";
import {
  MdOutlineTableRows,
  MdOutlineLocalHospital,
  MdScoreboard,
} from "react-icons/md";
import {
  BsCalendarDay,
  BsFillPersonCheckFill,
  BsInfoCircle,
} from "react-icons/bs";
import { TbSchoolBell } from "react-icons/tb";
import {
  GiBabyfootPlayers,
  GiHealthCapsule,
  GiTestTubes,
} from "react-icons/gi";
import { GrDocument, GrDocumentConfig, GrTest } from "react-icons/gr";
import { SiTestinglibrary } from "react-icons/si";
import {
  AiOutlineHistory,
  AiOutlineStock,
  AiOutlineTeam,
} from "react-icons/ai";
import { FormOutlined, SettingOutlined } from "@ant-design/icons";
const HeaderMenuList = [
  {
    title: "Genel Tanımlar",
    dropdown: [
      // genel tanımlar
      {
        leftIcon: <SettingOutlined />,
        title: "Faaliyet Kategorileri",
        href:
          Paths.GeneralDefinitions.GeneralDefinitions +
          "/faaliyet-kategorileri",
      },
      {
        leftIcon: <SettingOutlined />,
        title: "Sezon Tanımları",
        href: Paths.GeneralDefinitions.GeneralDefinitions + "/sezon-tanimlari",
      },
      {
        leftIcon: <SettingOutlined />,
        title: "Lig Tanımları",
        href: Paths.GeneralDefinitions.GeneralDefinitions + "/lig-tanimlari",
      },
      {
        leftIcon: <SettingOutlined />,
        title: "Tedavi Tanımları",
        href: Paths.GeneralDefinitions.GeneralDefinitions + "/tedavi-tanimlari",
      },
      {
        leftIcon: <SettingOutlined />,
        title: "Test Tanımları",
        href: Paths.GeneralDefinitions.GeneralDefinitions + "/test-tanimlari",
      },
      {
        leftIcon: <SettingOutlined />,
        title: "Takım Tanımları",
        href: Paths.GeneralDefinitions.GeneralDefinitions + "/takim-tanimlari",
      },
      {
        leftIcon: <SettingOutlined />,
        title: "Drill Tanımları",
        href: Paths.GeneralDefinitions.GeneralDefinitions + "/drill-tanimlari",
      },
      {
        leftIcon: <SettingOutlined />,
        title: "Birim Tanımları",
        href: Paths.GeneralDefinitions.GeneralDefinitions + "/birim-tanimlari",
      },
      {
        leftIcon: <SettingOutlined />,
        title: "Kulüp Tanımları",
        href: Paths.GeneralDefinitions.GeneralDefinitions + "/kulup-tanimlari",
      },
      {
        leftIcon: <SettingOutlined />,
        title: "Seçenek Tanımları",
        href:
          Paths.GeneralDefinitions.GeneralDefinitions + "/secenek-tanimlari",
      },
      {
        leftIcon: <SettingOutlined />,
        title: "Faaliyet Kategori Tanım",
        href:
          Paths.GeneralDefinitions.GeneralDefinitions +
          "/faaliyet-kategori-tanimlari",
      },
      {
        leftIcon: <SettingOutlined />,
        title: "Saha Tanımları",
        href: Paths.GeneralDefinitions.GeneralDefinitions + "/saha-tanimlari",
      },
      // {
      //   leftIcon: <SettingOutlined />,
      //   title: "Hakem Tanımları",
      //   href: Paths.GeneralDefinitions.GeneralDefinitions + "/hakem-tanimlari",
      // },
      // genel tanımlar
    ],
  },
];
const ReportHeaderMenuList = [
  {
    title: "Raporlar",
    dropdown: [
      {
        leftIcon: <SettingOutlined />,
        title: "Oyuncu İs",
        href:
          Paths.Reports.GeneralDefinitions +
          "/oyuncu-istatistikleri",
      },
    ],
  },
];

// const HeaderMenuList = [
//   {
//     title: "Genel",
//     dropdown: [
//       {
//         leftIcon: <BiUser />,
//         title: "Oyuncular",
//         href: Paths.Users.Players.Players,
//       },
//       {
//         leftIcon: <AiOutlineTeam />,
//         title: "Takımlar",
//         href: Paths.Users.Players.Players,
//       },
//       {
//         leftIcon: null,
//         title: "",
//         href: "#",
//       },
//       {
//         leftIcon: <BiUser />,
//         title: "Kişisel Bilgiler",
//         href: Paths.Users.Players.Players,
//       },
//       {
//         leftIcon: <GrDocumentConfig />,
//         title: "Kulüp Tanımları",
//         href: Paths.Users.Players.Players,
//       },
//       {
//         leftIcon: <GrDocumentConfig />,
//         title: "Sezon Tanımları",
//         href: Paths.Users.Players.Players,
//       },
//       {
//         leftIcon: <GrDocumentConfig />,
//         title: "Drill Tanımları",
//         href: Paths.Users.Players.Playersr,
//       },
//       {
//         leftIcon: null,
//         title: "",
//         href: "#",
//       },
//       {
//         leftIcon: <GrDocument />,
//         title: "Raporlar",
//         href: Paths.Users.Players.Players,
//       },
//       {
//         leftIcon: null,
//         title: "",
//         href: "#",
//       },
//       {
//         leftIcon: <AiOutlineHistory />,
//         title: "Kayıt Geçmişi",
//         href: Paths.Users.Players.Players,
//       },
//       {
//         leftIcon: <BiBorderAll />,
//         title: "Genel Tanımlar",
//         href: Paths.GeneralDefinitions.GeneralDefinitions,
//       },
//     ],
//   },

//   {
//     title: "Faaliyetler",
//     dropdown: [
//       {
//         leftIcon: <BsCalendarDay />,
//         title: "Faaliyet Takvimi",
//         href: Paths.Calendar,
//       },
//       {
//         leftIcon: <BsInfoCircle />,
//         title: "Faaliyet Bilgileri",
//         href: Paths.Activities.Activities,
//       },
//       {
//         leftIcon: <AiOutlineStock />,
//         title: "Faaliyet İstatistikleri",
//         href: Paths.Activities.Activities,
//       },
//       {
//         leftIcon: <AiOutlineStock />,
//         title: "Faaliyet Tanımları",
//         href: Paths.Activities.Activities,
//       },
//     ],
//   },
//   {
//     title: "Testler",
//     dropdown: [
//       {
//         leftIcon: <GrTest />,
//         title: "Test Sonuçları",
//         href: "#",
//       },
//       {
//         leftIcon: <GrTest />,
//         title: "Test İstatistikleri",
//         href: "#",
//       },
//       {
//         leftIcon: <GrTest />,
//         title: "Test Tanımları",
//         href: "#",
//       },
//       {
//         leftIcon: <GrTest />,
//         title: "Test Birim Tanımları",
//         href: "#",
//       },
//     ],
//   },
//   {
//     title: "Lig",
//     dropdown: [
//       {
//         leftIcon: <BiFootball />,
//         title: "Ligler",
//         href: Paths.Fixtures,
//       },
//       {
//         leftIcon: <BiFootball />,
//         title: "Fikstür",
//         href: Paths.Fixtures,
//       },

//       {
//         leftIcon: <BiFootball />,
//         title: "Puan Tablosu",
//         href: Paths.Scoreboard,
//       },
//     ],
//   },
//   {
//     title: "Lig ve Maçlar",
//     dropdown: [
//       {
//         leftIcon: <BiFootball />,
//         title: "Maçlar",
//         href: Paths.Matches.Matches,
//       },
//       {
//         leftIcon: <BiFootball />,
//         title: "Maç İstatistikleri",
//         href: Paths.Statistics,
//       },
//       {
//         leftIcon: <BiFootball />,
//         title: "Maç Kriter Tanımları",
//         href: Paths.Matches.Matches,
//       },
//       {
//         leftIcon: <BiFootball />,
//         title: "Oyun Sistemi / Mevki & Kriterler",
//         href: Paths.StartingLineup.StartingLineup,
//       },
//     ],
//   },
//   {
//     title: "Yardımcı Modüller",
//     dropdown: [
//       {
//         leftIcon: <GiHealthCapsule />,
//         title: "Tedavi Takibi",
//         href: Paths.Treatments.Treatments,
//       },
//       {
//         leftIcon: <MdOutlineTableRows />,
//         title: "Malzeme Takibi",
//         href: Paths.Treatments.Treatments,
//       },
//       {
//         leftIcon: <GiBabyfootPlayers />,
//         title: "Aday Oyuncular",
//         href: Paths.Applicants.Applicants,
//       },
//     ],
//   },
//   {
//     title: "Sistem",
//     dropdown: [
//       {
//         leftIcon: <FiSettings />,
//         title: "Kullanıcılar",
//         href: Paths.Users.AllUsers.AllUsers,
//       },
//       {
//         leftIcon: <FiSettings />,
//         title: "Grup ve Yetkileri",
//         href: Paths.Treatments.Treatments,
//       },
//       {
//         leftIcon: <FiSettings />,
//         title: "Yetki Tanımlamaları",
//         href: Paths.Applicants.Applicants,
//       },
//       {
//         leftIcon: <FiSettings />,
//         title: "Dinamik Formlar",
//         href: Paths.DynamicForm.CreateDynamicForm,
//       },
//     ],
//   },
// ];

const SidebarMenuList = [
  {
    icon: <FiHome />,
    title: "Anasayfa",
    href: Paths.Dashboard,
  },
  {
    icon: <FiCalendar />,
    title: "Takvim",
    href: Paths.Calendar,
  },
  // {
  //   icon: <SettingOutlined />,
  //   title: "Genel Tanımlar",
  //   href: Paths.GeneralDefinitions.GeneralDefinitions,
  // },
  {
    icon: <FiUser />,
    title: "Oyuncu Listesi",
    href: Paths.Users.Players.Players,
  },
  {
    icon: <FiUser />,
    title: "Antrenör Listesi",
    href: Paths.Coaches.Coaches,
  },
  {
    icon: <FiUser />,
    title: "İdari, Destek ve Hizmet",
    href: Paths.Employees.Employees,
  },
  {
    icon: <FiUser />,
    title: "Tedavi Listesi",
    href: Paths.Treatments.Treatments,
  },

  {
    icon: <BiFootball />,
    title: "Maçlar",
    href: Paths.Matches.Matches,
  },
  {
    icon: <FormOutlined />,
    title: "Dinamik Formlar",
    href: Paths.DynamicForm.CreateDynamicForm,
  },
  {
    icon: <FiActivity />,
    title: "Raporlar",
    href: Paths.Reports.Reports,
  },
  {
    icon: <MdOutlineTableRows />,
    title: "Fikstür",
    href: Paths.Fixtures.Fixtures,
  },
  {
    icon: <BsFillPersonCheckFill />,
    title: "Adaylar",
    href: Paths.Applicants.Applicants,
  },
  // {
  //   icon: <FiCalendar />,
  //   title: "Antreman Takvimi",
  //   href: Paths.TrainingCalendar.TrainingCalendar,
  // },
  // {
  //   icon: <FiActivity />,
  //   title: "Faliyetler",
  //   href: Paths.Activities.Activities,
  // },
  // {
  //   icon: <MdOutlineTableRows />,
  //   title: "Fikstür",
  //   href: Paths.Fixtures,
  // },
  {
    icon: <MdScoreboard />,
    title: "Puan Durumu",
    href: Paths.Scoreboard,
  },

  // {
  //   icon: <BiStats />,
  //   title: "Maç İstatistikleri",
  //   href: Paths.Statistics,
  // },
  // {
  //   icon: <MdOutlineLocalHospital />,
  //   title: "Tedaviler",
  //   href: Paths.Treatments.Treatments,
  // },
  // {
  //   icon: <BsFillPersonCheckFill />,
  //   title: "Adaylar",
  //   href: Paths.Applicants.Applicants,
  // },
  // {
  //   icon: <TbSchoolBell />,
  //   title: "Müfredat",
  //   href: Paths.Curriculum,
  // },
  // {
  //   icon: <BiStats />,
  //   title: "Takımlar",
  //   href: Paths.Teams.Teams,
  // },
];

export { HeaderMenuList, SidebarMenuList };
