import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Paths } from "../../routes";

import { DataTable } from "../../components/tables";
import { Input } from "antd";

import ActionButtonsDataTable from "./ActionButtonsDataTable";
import { FiPlus } from "react-icons/fi";
import { TbDownload } from "react-icons/tb";
import {
  getAllRoles,
  getAllUser,
  getDynamicForm,
  uploadExcelFile,
  getUserMert,
  getSelects,
} from "../../services/axios";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import AddUserStaticForm from "../../pages/users/allUsers/addUser/addUserStaticForm";
import DynamicInput from "../../components/inputs/dynamicInput";
import { Formik } from "formik";
import { PositionData } from "../../utils/datas/positionData";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import Swal from "sweetalert2";
import dayjs from "dayjs";

function PlayerList() {
  const navigate = useNavigate();
  const [deleteFile, setDeleteFile] = useState(false);
  const [excelFile, setExcelFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDataChange, setDataChange] = useState(true);
  const [dataTableData, setDataTableData] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [allUsersRoles, setAllUsersRoles] = useState();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [upCategoryOptions, setUpCategoryOptions] = useState();
  const [selectedRole, setSelectedRole] = useState("");
  const [roleUserName, setRoleUserName] = useState([]);

  const [statusDataOptions, setStatusDataOptions] = useState([]);
  const [checkedStatus, setCheckedStatus] = useState("");

  const [filterPosition, setFilterPosition] = useState([])
  const [checkFilterPosition, setCheckFilterPosition] = useState("")



  const [dataCount, setDataCount] = useState(null)

  // const fetchUserByRole = async () => {
  //   const result = await getAllUser(selectedRole);
  //   console.log("result: ", result);
  //   setDataTableColumns(
  //     result?.data?.users[0]?.user_metas?.map((item, index) => ({
  //       title: item.meta_key,
  //       dataIndex: item.meta_key,
  //     }))
  //   );
  // }

  // useEffect(() => {
  //   if (selectedRole) {
  //     fetchUserByRole()
  //   }
  // }, [selectedRole]);
  function deleteExcelFile() {
    // console.log(excelFile);
    if (excelFile.length !== 0) {
      setExcelFile([]);
    }
  }

  function handleExcelFile(e) {
    e.preventDefault();
    // console.log(e.target.file);
    if (excelFile) {
      uploadExcelFile(excelFile).then((res) => {
        // console.log(res);
        if (res?.data?.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Ekleme Başarılı!",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ekleme Başarısız!",
            showConfirmButton: false,
            timer: 1000,
          });
        }
      });
      setExcelFile([]);
    }
  }

  const handleDownload = async () => {
    try {
      const response = await fetch(
        "https://altinordu-api.golpro.com.tr/public/altinorduuserexcel.xlsx"
      );
      const blob = await response.blob();

      // Dosyayı indirmek için bir link oluşturun
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "example.xlsx"); // İndirilen dosyanın adını ve uzantısını belirtin
      document.body.appendChild(link);

      // Linki tıklayarak dosyayı indirin
      link.click();

      // Linki temizleyin
      document.body.removeChild(link);
    } catch (error) {
      console.error("Dosya indirme hatası:", error);
    }
  };

  const [provinceOptions, setProvinceOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);

  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [filters, setFilters] = useState({});

  const handleFilter = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };


  useEffect(() => {
    fetchGetUsers(selectedRole?.value);
  }, [filters])
  


  const fetchGetSelects = async () => {
    try {
      const results = await getSelects();
      console.log(results, "aaaaa")
      const province = results?.response?.find(
        (item) => item.id === 14
      );
      const team = results?.response?.find(
        (item) => item.id === 21
      );
      const position = results?.response?.find(
        (item) => item.id === 17
      );
      const provinceOptions = province?.options?.map((item) => ({
        label: item?.label,
        value: item?.value,
      })) || [];

      const teamOptions = team?.options?.map((item) => ({
        label: item?.label,
        value: item?.value,
      })) || [];

      const positionOptions = position?.options?.map((item) => ({
        label: item?.label,
        value: item?.value,
      })) || [];

      // Update state with mapped options
      setProvinceOptions([ { label: "Tümü", value: "" },...provinceOptions]);
      setTeamOptions([ { label: "Tümü", value: "" },...teamOptions]);
      setPositionOptions([ { label: "Tümü", value: "" },...positionOptions]);
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchGetUsers = async (role) => {
    let result = [];
    setDataTableData([]);
    setLoading(true);
    try {
      const result = await getAllUser(role, checkedStatus, filters);
      setDataTableColumns(
        result?.data?.users[0]?.user_metas?.map((item, index) => ({
          title: item.meta_key,
          dataIndex: item.meta_key,
        }))
      );
      const userMetas = result.data.users;
      // let newIndex = userMetas.length;
      const dataTableData = userMetas?.map((item, index) => ({
        key: item?.id,
        user_name: item?.name ? `${item?.name} ${item?.surname}` : "-",
        role: item.roles.map((item) => item.role_code),
        birthDate: dayjs(item?.birthday).format("DD.MM.YYYY"),
        playerage: item?.age,
        birthPlace: item?.province_id,
        position: item?.position ? item?.position : "-",
      }));
      setDataTableData(dataTableData);
      let checkDataLength = dataTableData.filter((item) => item.role == 'footballer').length
      setDataCount(checkDataLength)
      console.log(
        "dtable",
        dataTableData.map((item) => item.role)
      );

      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      fetchGetSelects()
      setLoading(false);
      setDataChange(false);
    }
  };
  const fetchGetRoles = async () => {
    setLoading(true);
    try {
      const result = await getAllRoles();
      setAllUsersRoles(result.data);
      const checkRole = result.data.map((item) => item.name);
      setRoleUserName(checkRole);
      setLoading(false);
      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setDataChange(false);
    }
  };

  const fetchDeleteUser = async (id) => {
    try {
      // const result = await deleteMatch(id);
      // setIsDataChange(true);
      // return result;
    } catch (error) {
      console.log(error, "hata");
    }
  };

  const handleSelectedRole = (role) => {
    setSelectedRole(role);
    setDataChange(true);
  };
  const handleCheckStatus = (checkStats) => {
    setCheckedStatus(checkStats);
    setDataChange(true);
  };
  const handleCheckPosition = (paylo) => {
    setCheckFilterPosition(paylo)
    setDataChange(true);
  }
  const handleSelectRole = async () => {
    try {
      const createOption = await allUsersRoles?.map((item, index) => ({
        label: item.name,
        value: item.role_code,
      }));
      return createOption;
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (allUsersRoles) {
      handleSelectRole().then((createOption) =>
        setUpCategoryOptions([
          { label: "Tüm Roller", value: "" },
          ...createOption,
        ])
      );
    }
  }, [allUsersRoles]);

  useEffect(() => {
    if (allUsersRoles) {
      setStatusDataOptions([
        { label: "Tümü", value: "" },
        { label: "Aktif Oyuncular", value: "active" },
        { label: "Pasif Oyuncular", value: "passive" },
      ]);
    }
  }, [allUsersRoles]);
  // bura
  useEffect(() => {
    if (allUsersRoles) {
      setFilterPosition([
        { label: "Tümü", value: "" },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 },
        { label: "11", value: 11 },
      ])
    }
  }, [allUsersRoles])

  useEffect(() => {
    if (isDataChange) {
      fetchGetUsers(selectedRole?.value);
      fetchGetRoles();
    }
  }, [isDataChange, selectedRole]);

  const columns = [
    {
      title: "Ad Soyad",
      dataIndex: "user_name",
    },
    {
      title: "Doğum Tarihi",
      dataIndex: "birthDate",
    },
    {
      title: "Yaş",
      dataIndex: "playerage",
    },
    {
      title: "Doğum Yeri (İl)",
      dataIndex: "birthPlace",
    },
    {
      title: "Pozisyon",
      dataIndex: "position",
    },
    {
      title: "Takım",
      dataIndex: "team",
    },
    {
      title: "İşlem",
      fixed: "right",
      key: "action",
      sorter: true,
      width: "",
      render: (item, index) => {
        return (
          <>
            <ActionButtonsDataTable
              id={index?.key}
              fetchFunction={(id) => {
                fetchDeleteUser(id);
              }}
              toUpdate={Paths.Users.Players.EditPlayers}
              toDetail={Paths.Users.Players.EditPlayers}
              // deleteDataLabel={index?.user_name}
              detailButton
              updateButton
            // deleteButton
            />
          </>
        );
      },
    },
  ];
  // console.log(selectedRole, "selectedRole");
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between text-gray-500">
          <h4 className="text-lg font-semibold">Oyuncu Listesi</h4>
        </div>
        <div className="z-30">

          <Formik
            initialValues={{
              title: selectedRole ?? "",
            }}
            onSubmit={async (values) => {
              // values.activity_field = values?.activity_field?.value;
              // values.up_category = values?.up_category?.value;
              handleSelectedRole(values);
            }}
          >
            {({
              errors,
              values,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit} className="flex gap-2.5 items-center">
                <div className="italic flex items-center gap-1 text-gray-500">Oyuncu Sayısı: <span className="font-semibold text-black">{dataCount}</span></div>
                <div className="w-64">
                  <DynamicInput
                    containerStyle={"w-full"}
                    placeholder={"Kullanıcı Rolleri"}
                    name={"up_category"}
                    value={values}
                    onChange={(e1, e2) => {
                      handleSelectedRole(e1);
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                    component_type={"select"}
                    customOptions={upCategoryOptions}
                  />
                </div>
                <div className="w-64">
                  <DynamicInput
                    containerStyle={"w-full"}
                    placeholder={"Durum"}
                    name={"check_status"}
                    value={values}
                    onChange={(e1, e2) => {
                      handleCheckStatus(e1);
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                    component_type={"select"}
                    customOptions={statusDataOptions}
                  />
                </div>
                <div className="w-64">
                  <DynamicInput
                    containerStyle={"w-full"}
                    placeholder={"Doğum Yeri"}
                    name={"province_id"}
                    value={filters.check_status}
                    onChange={(value) => handleFilter('province_id', value)}
                    error={errors.province_id}
                    isTouched={touched.province_id}
                    onBlur={handleBlur}
                    component_type={"select"}
                    customOptions={provinceOptions}
                  />
                </div>
                <div className="w-64">
                  <DynamicInput
                    containerStyle={"w-full"}
                    placeholder={"Takım"}
                    name={"team_id"}
                    value={filters.team_id}
                    onChange={(value) => handleFilter('team_id', value)}
                    error={errors.team_id}
                    isTouched={touched.team_id}
                    onBlur={handleBlur}
                    component_type={"select"}
                    customOptions={teamOptions}
                  />
                </div>
                <div className="w-64">
                  <DynamicInput
                    containerStyle={"w-full"}
                    placeholder={"Pozisyon"}
                    name={"position"}
                    value={filters.position}
                    onChange={(value) => handleFilter('position', value)}
                    error={errors.position}
                    isTouched={touched.position}
                    onBlur={handleBlur}
                    component_type={"select"}
                    customOptions={positionOptions}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className="flex flex-wrap-reverse items-center justify-between px-2 py-2 my-4 bg-gray-100 rounded-lg">
        <ul className="flex items-center">
          <li>
            <button
              onClick={() => {
                setOpenAddModal(true);
              }}
              // to={Paths.Users.Players.AddPlayers}
              className="flex p-2 text-gray-500 transition-all rounded-lg hover:bg-gray-200"
            >
              <FiPlus size={21} />
              <span className="ml-2 text-sm text-gray-500">Ekle</span>
            </button>
            <CustomModal
              width={677}
              isOpen={openAddModal}
              setOpen={setOpenAddModal}
              footer={null}
            >
              <CustomModalHeader
                title={"Yeni Kullanıcı Oluştur"}
                icon={"MdOutlinedFlag"}
                description={
                  "Yeni kullanıcı oluşturun ve daha sonrasında güncellemek için 'Kullanıcı Güncelle' sayfasına gidiniz. "
                }
              />
              <AddUserStaticForm
                isDataChange={isDataChange}
                setDataChange={setDataChange}
                updateForm={false}
              />
            </CustomModal>
          </li>

          <li>
            <button
              onClick={() => {
                setOpenExcelModal(true);
              }}
              // to={Paths.Users.Players.AddPlayers}
              className="flex p-2 text-gray-500 transition-all rounded-lg hover:bg-gray-200"
            >
              <FiPlus size={21} />
              <span className="ml-2 text-sm text-gray-500">Excel import</span>
            </button>
            <CustomModal
              width={677}
              isOpen={openExcelModal}
              setOpen={setOpenExcelModal}
              footer={null}
            >
              <CustomModalHeader
                icon={"RiFileExcel2Fill"}
                title={"Excel içeri aktar"}
                description={"Excel tablo "}
              />
              <DynamicInput
                labelTitle={"Excel Dosyası"}
                isMulti={false}
                files={excelFile}
                containerStyle={"w-full"}
                onChange={(e) => {
                  setExcelFile([e]);
                  e.preventDefault();
                }}
                required={true}
                component_type={"fileUpload"}
              />
              <div
                style={{
                  marginTop: "20px",
                  gap: "10px",
                  justifyContent: "end",
                  display: "flex",
                }}
              >
                <ButtonComponent
                  onClick={(e) => deleteExcelFile()}
                  text={"Temizle"}
                  fill
                />
                <ButtonComponent
                  onClick={(e) => handleExcelFile(e)}
                  text={"Ekle"}
                  fill
                />
              </div>
            </CustomModal>
          </li>
          <li>
            <button
              onClick={() => {
                handleDownload();
              }}
              // to={Paths.Users.Players.AddPlayers}
              className="flex p-2 text-gray-500 transition-all rounded-lg hover:bg-gray-200"
            >
              <TbDownload size={20} />
              <span className="ml-2 text-sm text-gray-500">Örnek Excel</span>
            </button>
          </li>
        </ul>
        <div className="flex justify-between gap-32">
          {/* <div className="flex items-center w-full sm:w-auto"> */}

          <span className="flex items-center p-2 text-gray-500 transition-all rounded-lg hover:bg-gray-200">
            Filtrele:{" "}
          </span>
          {/* </div> */}
        </div>
      </div>
      <div className="">
        <DataTable
          columns={columns}
          data={
            dataTableData
              ?.filter((item) => item.role == "footballer")
              .reverse() ?? []
          }
          loading={loading}
        />
      </div>

      {/* {allUsersRoles.find((item) => item.name == 'footballer') && (
              <div className="">
              <DataTable
                columns={columns}
                data={dataTableData?.reverse() ?? []}
                loading={loading}
              />
            </div>
        )} */}
    </>
  );
}

export default PlayerList;
