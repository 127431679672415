import React, { useEffect, useState } from "react";
import TabbarComponent from "../../components/tabbar";
import AllTreatments from "./Treatments/AllTreatments";
import AllTreatmentsStatus from "./Treatments/AllTreatmentsStatus";
import AllTreatmentsType from "./Treatments/AllTreatmentsType";
import AllTreatmentsMedicines from "./Treatments/AllTreatmentsMedicines";

function TreatmentsDefinitions() {
  const [selectedTabId, setSelectedTabId] = useState(0);

  return (
    <div>
      <div>
        <div className="border-b w-fit border-primary">
          <TabbarComponent
            type="card"
            setSelectedTabId={setSelectedTabId}
            selectedTabId={selectedTabId}
            data={[
              // { title: "Tüm Tedaviler" },
              { title: "Tedavi Durumları" },
              { title: "Tedavi Tipi" },
              { title: "İlaç Tanımları" },
            ]}
          />
        </div>
        {/* {selectedTabId == 0 && <AllTreatments />} */}
        {selectedTabId == 0 && <AllTreatmentsStatus />}
        {selectedTabId == 1 && <AllTreatmentsType />}
        {selectedTabId == 2 && <AllTreatmentsMedicines />}
      </div>
    </div>
  );
}

export default TreatmentsDefinitions;
