import React, { useEffect, useState } from "react";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import { Form, Formik, useFormik } from "formik";
import { handleCustomChange } from "../../utils/handleCustomChange";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import DynamicInput from "../../components/inputs/dynamicInput";
import {
  createSeason,
  createSeasonManagment,
  deleteSeason,
  deleteSeasonManagement,
  getAllManagmentTypes,
  getAllUser,
  getSeasons,
  updateSeason,
  listTeams,
} from "../../services/axios";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { PulseLoader } from "react-spinners";
import TabbarComponent from "../../components/tabbar";
import DataTable from "../../components/tables/DataTable";
import { Button } from "antd";
import PageTitle from "../../components/pageTitle";
import { nanoid } from "nanoid";

export function CreateSeasonsForm({
  isOpen,
  setOpen,
  setIsDataChange,
  isDataChange,
  updateSelectedValuesProp,
  updateId,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [dataTableData, setDataTableData] = useState([]);
  const [filteredDataTableData, setFilteredDataTableData] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");

  // console.log(updateSelectedValuesProp, "season_managements_yonetim");
  //season managment
  // console.log(updateSelectedValuesProp, "updateSelectedValuesProp");
  // Burada
  const [managementManagementData, setManagementManagementData] = useState(
    updateSelectedValuesProp?.season_managements_yonetim.map((item, index) => ({
      id: item.id,
      name: item?.user?.name ? item?.user?.name : "-",
      surname: item?.user?.surname ? item?.user?.surname : "-",
      tckn: item?.user?.tckn ? item?.user?.tckn : "-",
      mission: item?.managment_type?.title ? item?.managment_type?.title : "-",
    }))
  );
  const [managementTechnicalTeamData, setManagementTechnicalTeamData] =
    useState(
      updateSelectedValuesProp?.season_managements_teknik_kadro.map(
        (item, index) => ({
          id: item?.id,
          name: item?.user.name ? item?.user.name : "-",
          surname: item?.user.surname ? item?.user.surname : "-",
          tckn: item?.user.tckn ? item?.user.tckn : "-",
          mission: item?.managment_type?.title
            ? item?.managment_type?.title
            : "-",
        })
      )
    );
  const [
    managementAdministrativeTeamData,
    setManagementAdministrativeTeamData,
  ] = useState(
    updateSelectedValuesProp?.season_managementsi̇dari_kadro.map(
      (item, index) => ({
        id: item?.id,
        name: item?.user.user_name ? item?.user.name : "-",
        tckn: item?.user.tckn ? item?.user.tckn : "-",
        mission: item?.managment_type?.title
          ? item?.managment_type?.title
          : "-",
      })
    )
  );
  const [addManagmentModalOpen, setAddManagmentModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [managmentTypeOptionData, setManagmentTypeOptionData] = useState(null);

  const selectedTabTeamData = () => {
    switch (selectedTabId) {
      case 0:
        return managementManagementData;

      case 1:
        return managementTechnicalTeamData;

      case 2:
        return managementAdministrativeTeamData;

      default:
        break;
    }
  };

  const selectedTabTeamDataType = () => {
    switch (selectedTabId) {
      case 0:
        return "yonetim";

      case 1:
        return "teknik_kadro";

      case 2:
        return "idari_kadro";

      default:
        break;
    }
  };

  const {
    errors,
    values,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      season_code: updateSelectedValuesProp?.season_code ?? nanoid(),
      season_title: updateSelectedValuesProp?.season_title ?? "",
      start_date: updateSelectedValuesProp?.start_date ?? null,
      end_date: updateSelectedValuesProp?.end_date ?? null,
      is_active: updateSelectedValuesProp?.is_active ?? false,
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        let result = null;
        // console.log(updateId, "updateId");
        if (updateId) {
          result = await updateSeason(
            updateId,
            values.season_code,
            values.season_title,
            values.start_date,
            values.end_date,
            values.is_active
          );
        } else {
          result = await createSeason(
            values.season_code,
            values.season_title,
            values.start_date,
            values.end_date,
            values.is_active
          );
        }

        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result?.message ?? "Ekleme Başarılı!",
            showConfirmButton: false,
            timer: 1000,
          });

          // Set isDataChange to true
          setLoading(false);
          setIsDataChange(true);

          //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: result?.message ?? "Ekleme Başarısız!",
            showConfirmButton: false,
            timer: 1000,
          });
        }
      } catch (error) {
        console.error("Hata oluştu:", error);
      } finally {
        setOpen(false);
      }
    },
  });

  const fetchDeleteSeason = async (id) => {
    try {
      const result = await deleteSeason(id);
      // console.log(result, "result");
      setIsDataChange(true);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchDeleteSeasonManagment = async (id) => {
    try {
      const result = await deleteSeasonManagement(id);
      // console.log(result, "result");
      setIsDataChange(true);
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const fetchGetUsers = async () => {
    // setLoading(true);
    try {
      const result = await getAllUser();
      const selectedRoles = getSelectedRoles(selectedTabId); // Güncel selectedTabId değerini geçiyoruz
      const filteredUsers = result.data.users.filter((user) =>
        user.roles.some((role) => selectedRoles.includes(role.role_code))
      );
      const formattedData = filteredUsers.map((user) => ({
        id: user.id,
        name: user.name || "-",
        surname: user.surname || "-",
        tckn: user.tckn || "-",
        roles: user.roles.map((role) => role.role_code) || [],
      }));
      setDataTableData(formattedData);
      setLoading(false);
      console.log("Veri başarıyla alındı:", result);
    } catch (error) {
      console.error("Veri alınırken bir hata oluştu:", error);
      setLoading(false);
    }
  };

  const getSelectedRoles = (selectedTabId) => {
    console.log("selec", selectedTabId); // Doğru değeri görmek için selectedTabId'yi kontrol edin
    switch (selectedTabId) {
      case 0:
        return [
          "executive_director",
          "administrative_responsible",
          "admin",
          "super_admin",
          "doctor",
          "masseur",
          "physiotherapist",
          "physchologist",
          "club_worker",
          "sport_director",
        ];
      case 1:
        return [
          "team_coach",
          "goalkeeper_coach",
          "team_coach",
          "team_manager",
          "technical_director",
        ];
      default:
        return [];
    }
  };

  const fetchGetAllManagmentTypes = async () => {
    try {
      const result = await getAllManagmentTypes();
      // console.log(result, "fetchGetAllManagmentTypes");

      setManagmentTypeOptionData(
        result.data.managmentTypes.map((item, index) => ({
          label: item.title ? item.title : "-",
          value: item.id,
        }))
      );
      return result;
    } catch (error) {
      console.log(error, "hata");
    }
  };

  const handleSeasonManagmentDeleteOnClick = async (item) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${item.name}" kişini silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteSeasonManagment(item?.id);
        Swal.fire(
          "Silindi!",
          `"${item.name} ${item.surname}" kişisi silindi.`,
          "success"
        );
      }
    });
  };

  const handleSeasonDeleteOnClick = async (updateSelectedValuesProp) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${updateSelectedValuesProp.season_title}" sezonunu silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteSeason(updateSelectedValuesProp?.id);
        Swal.fire(
          "Silindi!",
          `"${updateSelectedValuesProp?.season_title}" sezonu silindi.`,
          "success"
        );
      }
    });
  };

  const handleSearchOnChange = (e) => {
    const searchValue = e.target.value;
    setSearchInputValue(searchValue);
    const filteredData = dataTableData.filter((item) => {
      for (let key in item) {
        if (typeof item[key] === "string" && item[key].includes(searchValue)) {
          return true;
        }
      }
      return false;
    });
    setFilteredDataTableData(filteredData);
  };

  useEffect(() => {
    fetchGetUsers();
    fetchGetAllManagmentTypes();
  }, [isDataChange, selectedTabId]);

  // console.log(managementManagementData, "aaaaa");
  // console.log(selectedUserData, "batu");
  // console.log(managmentTypeOptionData, "managmentTypeOptionData");

  return (
    <>
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      {!loading && (
        <form onSubmit={handleSubmit}>
          <div className="grid w-full gap-4 pt-4 pb-4">
            <DynamicInput
              labelTitle={"Sezon Adı"}
              containerStyle={"w-full"}
              placeholder={
                updateSelectedValuesProp?.season_title ?? "Sezon Adı"
              }
              name={"season_title"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "season_title",
                  setFieldValue,
                  "input"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
            />
            {/* <DynamicInput
              labelTitle={"Sezon Kodu"}
              containerStyle={"w-full"}
              placeholder={
                updateSelectedValuesProp?.season_code ?? "Sezon Kodu"
              }
              name={"season_code"}
              value={values}
              onChange={(e1, e2) => {
                handleCustomChange(
                  e1,
                  e2,
                  "season_code",
                  setFieldValue,
                  "input"
                );
              }}
              error={errors}
              isTouched={touched}
              onBlur={handleBlur}
            /> */}
            <div className="flex flex-col items-center justify-center gap-4 2xl:flex-row">
              <DynamicInput
                labelTitle={"Sezon Başlangıç Tarihi"}
                containerStyle={"w-full"}
                name={"start_date"}
                value={values}
                onChange={(e1, e2) => {
                  handleCustomChange(
                    e1,
                    e2,
                    "start_date",
                    setFieldValue,
                    "datePicker"
                  );
                }}
                error={errors}
                isTouched={touched}
                onBlur={handleBlur}
                component_type={"datePicker"}
                placeholder={updateSelectedValuesProp?.start_date}
              />
              <DynamicInput
                labelTitle={"Sezon Bitiş Tarihi"}
                containerStyle={"w-full"}
                name={"end_date"}
                value={values}
                onChange={(e1, e2) => {
                  handleCustomChange(
                    e1,
                    e2,
                    "end_date",
                    setFieldValue,
                    "datePicker"
                  );
                }}
                error={errors}
                isTouched={touched}
                onBlur={handleBlur}
                component_type={"datePicker"}
                placeholder={updateSelectedValuesProp?.end_date}
              />
            </div>
            <div className="flex flex-col items-center justify-center gap-4">
              {
                <DynamicInput
                  labelTitle={"Durum"}
                  containerStyle={"w-full"}
                  placeholder={"Aktif"}
                  name={"is_active"}
                  value={values}
                  onChange={(e1, e2) => {
                    handleCustomChange(
                      e1,
                      e2,
                      "is_active",
                      setFieldValue,
                      "checkbox"
                    );
                  }}
                  error={errors}
                  isTouched={touched}
                  onBlur={handleBlur}
                  component_type={"checkbox"}
                />
              }
              {!updateSelectedValuesProp && (
                <div
                  class="flex items-start p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 w-full"
                  role="alert"
                >
                  <svg
                    class="flex-shrink-0 inline w-4 h-4 mr-3 mt-[2px]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span class="sr-only">Info</span>
                  <div>
                    <span class="font-medium">DİKKAT!</span>
                    <div className="flex flex-col gap-2 my-2 ml-2">
                      <p className="pl-2 border-l-2 border-yellow-300">
                        Sezon aktif etmek için "Sezon Tanımlamaları" bölümünden
                        aktif etmeniz gerekmektedir.
                      </p>
                      <p className="pl-2 border-l-2 border-yellow-300">
                        Sezonların kadro girişlerini "Sezon Tanımlamaları"ndan
                        yapabilirsiniz.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {updateSelectedValuesProp && (
                <>
                  <div className="flex w-full pt-8 pb-1">
                    <ButtonComponent
                      onClick={() =>
                        handleSeasonDeleteOnClick(updateSelectedValuesProp)
                      }
                      text={"Sezonu Sil"}
                      outline
                      customStyle={"w-fit h-[56px]"}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="flex items-center justify-end gap-4">
              <ButtonComponent
                onClick={() => {
                  setOpen(false);
                  resetForm();
                }}
                outline
                text={"Vazgeç"}
              />
              <ButtonComponent submit fill text={"Kaydet"} />
            </div>
            {updateSelectedValuesProp && (
              <div className="flex flex-col gap-4 pt-4 pb-16">
                <div className="w-full h-1 my-4 rounded-full bg-primary"></div>
                <PageTitle title={"Kadro Girişleri"} bg />
                <CustomModal
                  //faaliyet adresi ekleme
                  width={677}
                  isOpen={addManagmentModalOpen}
                  setOpen={setAddManagmentModalOpen}
                  footer={null}
                >
                  <CustomModalHeader
                    title={"Görev Ekleyin"}
                    description={"Aşağıdan görev ekleyin."}
                  />
                  <Formik
                    initialValues={{
                      season_id: updateId,
                      user_id: selectedUserData?.id,
                      role_id: "",
                      managment_type: "",
                      start_date: "",
                      end_date: "",
                      notes: "",
                      type: selectedTabTeamDataType(),
                      //yonetim
                      //teknik_kadro
                      //idari_kadro
                    }}
                    onSubmit={async (values) => {
                      values.managment_type = values.managment_type?.value;
                      setLoading(true);
                      try {
                        const result = await createSeasonManagment(
                          values?.season_id,
                          values?.user_id,
                          values?.role_id,
                          values?.managment_type,
                          values?.start_date,
                          values?.end_date,
                          values?.notes,
                          values?.type
                        );

                        if (result.success) {
                          Swal.fire({
                            position: "center",
                            icon: "success",
                            title: result?.message ?? "Giriş Başarılı!",
                            showConfirmButton: false,
                            timer: 1000,
                          });
                          setLoading(false);
                          setIsDataChange(true);

                          // Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
                        } else {
                          Swal.fire({
                            position: "center",
                            icon: "error",
                            title: result?.message ?? "Giriş Başarısız!",
                            showConfirmButton: false,
                            timer: 1000,
                          });
                        }
                      } catch (error) {
                        console.log(error, "createSeasonManagment Result");
                      }
                    }}
                  >
                    {({
                      errors,
                      values,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                      resetForm,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="flex flex-col py-4">
                          <DynamicInput
                            labelTitle={"Görevi"}
                            containerStyle={"w-full"}
                            placeholder={"Görev Seç"}
                            name={"managment_type"}
                            value={values}
                            onChange={(e1, e2) => {
                              handleCustomChange(
                                e1,
                                e2,
                                "managment_type",
                                setFieldValue,
                                "select"
                              );
                            }}
                            component_type={"select"}
                            customOptions={managmentTypeOptionData}
                            error={errors}
                            isTouched={touched}
                            onBlur={handleBlur}
                          />
                          <div className="flex gap-4">
                            <DynamicInput
                              labelTitle={"Başlangıç Tarihi"}
                              containerStyle={"w-full"}
                              placeholder={"Görev Seç"}
                              name={"start_date"}
                              value={values}
                              onChange={(e1, e2) => {
                                handleCustomChange(
                                  e1,
                                  e2,
                                  "start_date",
                                  setFieldValue,
                                  "datePicker"
                                );
                              }}
                              component_type={"datePicker"}
                              error={errors}
                              isTouched={touched}
                              onBlur={handleBlur}
                            />
                            <DynamicInput
                              labelTitle={"Son Tarihi"}
                              containerStyle={"w-full"}
                              placeholder={"Görev Seç"}
                              name={"end_date"}
                              value={values}
                              onChange={(e1, e2) => {
                                handleCustomChange(
                                  e1,
                                  e2,
                                  "end_date",
                                  setFieldValue,
                                  "datePicker"
                                );
                              }}
                              component_type={"datePicker"}
                              error={errors}
                              isTouched={touched}
                              onBlur={handleBlur}
                            />
                          </div>
                          <DynamicInput
                            labelTitle={"Notlar"}
                            containerStyle={"w-full"}
                            placeholder={"Notlar"}
                            name={"notes"}
                            value={values}
                            onChange={(e1, e2) => {
                              handleCustomChange(
                                e1,
                                e2,
                                "notes",
                                setFieldValue,
                                "textarea"
                              );
                            }}
                            component_type={"textarea"}
                            error={errors}
                            isTouched={touched}
                            onBlur={handleBlur}
                          />
                          <div className="flex justify-end gap-4 mt-4">
                            <ButtonComponent
                              onClick={() => setAddManagmentModalOpen(false)}
                              text={"Kapat"}
                              outline
                            />
                            <ButtonComponent text={"Kaydet"} fill submit />
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </CustomModal>
                <DynamicInput
                  labelTitle={"Arama Yap"}
                  containerStyle={"w-full"}
                  placeholder={"Değer Arayın..."}
                  name={"searchInput"}
                  value={searchInputValue}
                  onChange={(e) => handleSearchOnChange(e)}
                  error={null}
                  isTouched={null}
                  onBlur={null}
                />
                <TabbarComponent
                  type="card"
                  setSelectedTabId={setSelectedTabId}
                  selectedTabId={selectedTabId}
                  data={[
                    { title: "Yönetim ve Diğer İdari Kadro" },
                    { title: "Teknik Kadro" },
                  ]}
                />
                {/* Bura */}
                {/* {checkRole == 'team_manager' ? <div>asdads</div> : 'selam'} */}

                <DataTable
                  loading={loading}
                  columns={[
                    { title: "Ad", dataIndex: "name" },
                    { title: "Soyad", dataIndex: "surname" },
                    { title: "TC", dataIndex: "tckn" },
                    // { title: "Rol", dataIndex: "roles" },
                    {
                      title: "Seçenekler",
                      dataIndex: "options",
                      render: (_, index) => {
                        return (
                          <div className="flex gap-4">
                            <ButtonComponent
                              onClick={() => {
                                setAddManagmentModalOpen(true);
                                setSelectedUserData(index);
                              }}
                              text={"Seç"}
                              fill
                            />
                          </div>
                        );
                      },
                    },
                  ]}
                  // vada
                  data={
                    searchInputValue
                      ? filteredDataTableData
                      : dataTableData.filter((user) =>
                          user.roles.some((role) =>
                            [
                              "executive_director",
                              "administrative_responsible",
                              "admin",
                              "super_admin",
                              "doctor",
                              "physiotherapist",
                              "masseur",
                              "psychologist",
                              "club_worker",
                              "team_coach",
                              "goalkeeper_coach",
                              "team_coach",
                              "team_manager",
                              "technical_director",
                              "sport_director",
                            ].includes(role)
                          )
                        )
                  }
                />
                <div className="flex flex-col gap-4 p-4 border shadow-xl rounded-xl">
                  <h1 className="text-xl font-medium text-secondary">
                    Seçilen Kişiler
                  </h1>
                  <DataTable
                    loading={loading}
                    columns={[
                      { title: "Ad", dataIndex: "name" },
                      { title: "Soyad", dataIndex: "surname" },
                      { title: "TC", dataIndex: "tckn" },
                      { title: "Görevi", dataIndex: "mission" },
                      {
                        title: "Seçenekler",
                        dataIndex: "options",
                        render: (_, index) => {
                          // console.log(index, "omer");
                          return (
                            <>
                              <div className="flex gap-4">
                                {/* <ButtonComponent
                                  onClick={() => setAddManagmentModalOpen(true)}
                                  text={"Detay"}
                                  fill
                                /> */}
                                <ButtonComponent
                                  onClick={() =>
                                    handleSeasonManagmentDeleteOnClick(index)
                                  }
                                  text={"Sil"}
                                  outline
                                />
                              </div>
                            </>
                          );
                        },
                      },
                    ]}
                    data={selectedTabTeamData()}
                  />
                </div>
              </div>
            )}
          </div>
        </form>
      )}
    </>
  );
}

function CreateSeasonsModal({ isOpen, setOpen, setIsDataChange }) {
  return (
    <div>
      <CustomModal
        //faaliyet adresi ekleme
        width={677}
        isOpen={isOpen}
        setOpen={setOpen}
        footer={null}
      >
        <CustomModalHeader
          title={"Yeni Sezon Ekleyin"}
          description={"Aşağıdan yeni sezon ekleyin."}
        />
        <CreateSeasonsForm
          isOpen={isOpen}
          setOpen={setOpen}
          setIsDataChange={setIsDataChange}
        />
      </CustomModal>
    </div>
  );
}

export default CreateSeasonsModal;
