import React from "react";
import {Breadcrumb as Breadcrumbs} from "antd";

const Breadcrumb = ({items}) => {
    return (
        items && items?.length ? (
            <div className="flex items-center justify-end w-full my-3">
                <Breadcrumbs className={"inline-flex p-2 bg-white rounded-md items-center "} items={items}/>
            </div>
        ) : <div className="my-28"></div>
    );
}

export default Breadcrumb;
