import { Input, Popconfirm } from "antd";
import React, { useState } from "react";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import { AiOutlineEdit } from "react-icons/ai";
import { deleteTeamCategory, updateTeamCategory } from "../../services/axios";
import Swal from "sweetalert2";

export function CreateTeamCategory({
  //   isOpen,
  //   setOpen,
  setIsDataChange,
  isDataChange,
  updateSelectedValuesProp,
}) {
  const [selectedInputValue, setSelectedInputValue] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);

  const fetchUpdateTeamCategory = async (id, category_title) => {
    setConfirmLoading(true);
    try {
      const result = await updateTeamCategory(id, category_title);
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });
        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setConfirmLoading(false);
      setIsDataChange(true);
    }
  };

  const fetchDeleteTeamCategory = async (id) => {
    setConfirmLoading(true);
    try {
      const result = await deleteTeamCategory(id);
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });
        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setConfirmLoading(false);
      setIsDataChange(true);
    }
  };

  return (
    <>
      <div className="">
        <div className="w-full px-2 zoom-none-2">
          <div className="">
            {/* <p>select id: {item.id}</p> */}
            <p className="py-2 text-xl w-fit">
              Başlık:{" "}
              <span className="font-bold text-primary">
                {updateSelectedValuesProp?.category_title}
              </span>
            </p>
          </div>
          <hr />
          <div className="flex flex-col gap-4 py-4">
            <p className="text-sm border-b w-fit text-secondary">
              Kategoriyi Düzenle veya Sil
            </p>
            <Popconfirm
              className="w-fit"
              title="Kategoriyi Düzenle"
              description={
                <div>
                  <Input
                    type="text"
                    value={selectedInputValue}
                    placeholder={updateSelectedValuesProp?.category_title}
                    onChange={(e) => setSelectedInputValue(e.target.value)}
                  />
                  <div className="py-2">
                    <ButtonComponent
                      outline
                      text={"Sil"}
                      customStyle={"w-fit h-7"}
                      onClick={() =>
                        fetchDeleteTeamCategory(updateSelectedValuesProp?.id)
                      }
                    />
                  </div>
                </div>
              }
              okText="Kaydet"
              cancelText="Vazgeç"
              okButtonProps={{
                loading: confirmLoading,
              }}
              onConfirm={async () => {
                await fetchUpdateTeamCategory(
                  updateSelectedValuesProp.id,
                  selectedInputValue
                );
              }}
              onCancel={() => {
                setSelectedInputValue("");
              }}
              onOpenChange={() => {
                setSelectedInputValue("");
              }}
            >
              <div className="flex items-center gap-4 px-2 py-2 text-xl border-l-4 cursor-pointer border-primary group w-fit">
                <p>{updateSelectedValuesProp?.category_title}</p>
                <div className="flex items-center p-1 rounded opacity-0 group-hover:opacity-100 bg-primary w-fit">
                  <AiOutlineEdit className="text-xl text-white " />
                </div>
              </div>
            </Popconfirm>
          </div>
        </div>
      </div>
    </>
  );
}

function CreateTeamCategoryModal() {
  return <div>CreateTeamCategoryModal</div>;
}

export default CreateTeamCategoryModal;
