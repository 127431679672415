import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuBox = ({ menuList, menuMini }) => {
  const location = useLocation();

  return (
    <ul className="w-full">
      {menuList.map((item, index) => {
        return (
          <li key={index} className="py-1 border-t">
            <Link
              to={item.href}
              className={`flex items-center justify-between border-4 border-transparent hover:border-l-primary px-6 py-3 transition-all group hover:font-semibold hover:text-primary ${
                location.pathname === item.href &&
                "border-l-primary font-semibold text-primary"
              }`}
            >
              <p
                className={`relative truncate ${
                  menuMini
                    ? "w-0 opacity-0 transition-all duration-700 ease-in-out"
                    : "w-48 opacity-100"
                }`}
              >
                {item.title}
              </p>
              <span
                className={`text-primary text-lg ${
                  location.pathname === item.href
                    ? "opacity-100"
                    : "opacity-30 group-hover:opacity-100"
                }`}
              >
                {item.icon}
              </span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default MenuBox;
