import React from "react";

const Statistics = () => {
    return (
        <div>
            Statistics
        </div>
    );
}

export default Statistics;