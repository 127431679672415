import React, { useEffect, useState } from "react";
import InputComponent from "../../components/inputs";
import { Formik, useFormik } from "formik";
import validations from "../../utils/validations";
import {
  addMatchFormInputsData,
  matchFormInputsData,
  updateMatchFormInputsData,
} from "../../utils/datas/matchFormInputsData";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import DynamicInput from "../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../utils/handleCustomChange";
import {
  createActivityMatch,
  createMatches,
  getAllClub,
  getCriteriaTypes,
  getLeagues,
  getMatch,
  getSeasons,
  getSelects,
  updateMatches,
} from "../../services/axios";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import dayjs from "dayjs";

function MatchesFields({
  isUpdate,
  updateData,
  setIsDataChangeList,
  isDataChangeList,
  newEventData,
  isActivity,
}) {
  console.log(newEventData, "matchnewEventData");
  const [loading, setLoading] = useState(false);
  const [getAllClubOptions, setGetAllClubOptions] = useState([]);

  const [allActiveSeasonOptionData, setAllActiveSeasonOptionData] = useState(
    []
  );
  const [matchesTypeOptions, setMatchTypeOptions] = useState(null);
  const [matchesDificultyOptions, setMatchDificultyOptions] = useState(null);
  const [facilityOptions, setFacilityOptions] = useState(null);
  const [opponentCategory, setOpponentCategory] = useState(null);
  const [leagueOptions, setLeagueOptions] = useState(null);
  const [minuteCategory, setMinuteCategory] = useState(null);
  const [matchHalfCategory, setMatchHalfCategory] = useState(null);
  const [playerCountCategory, setPlayerCountCategory] = useState(null);
  const [matchWeekCategory, setMatchWeekCategory] = useState(null);
  const [fieldNameCategory, setFieldNameCategory] = useState(null);

  const fetchGetAllClub = async () => {
    setLoading(true);
    try {
      const result = await getAllClub();
      setGetAllClubOptions(
        result.response.clubs.map((item, index) => ({
          label: item.club_name,
          value: item.id,
        }))
      );
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      // setIsDataChangeList(false);
    }
  };
  const fetchLeagues = async () => {
    const result = await getLeagues();
    // console.log("rez", result.data.data.map((item) => item))
    const leagueOptions = result.data.data;
    setLeagueOptions(
      leagueOptions.map((item, index) => ({
        label: item?.name,
        value: item?.id,
      }))
    );
    console.log("rezalt", leagueOptions);
  };
  useEffect(() => {
    fetchLeagues();
  }, []);

  const fetchCreateMatches = async (
    home_team,
    guest_team,
    home_team_score,
    guest_team_score,
    season,
    week,
    match_date,
    coach_comment,
    goal_keeper_coach_comment,
    matches_type,
    match_dificulty
  ) => {
    setLoading(true);
    try {
      const result = await createMatches(
        home_team,
        guest_team,
        0, //home_team_score,
        0, //guest_team_score,
        season,
        week,
        match_date,
        "", //coach_comment,
        "", //goal_keeper_coach_comment,
        matches_type,
        match_dificulty
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });

        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChangeList(true);
    }
  };

  const fetchUpdateMatches = async (
    id,
    home_team,
    guest_team,
    home_team_score,
    guest_team_score,
    season,
    week,
    match_date,
    coach_comment,
    goal_keeper_coach_comment,
    matches_type,
    match_dificulty
  ) => {
    setLoading(true);
    try {
      const result = await updateMatches(
        id,
        home_team,
        guest_team,
        0, //home_team_score,
        0, //guest_team_score,
        season,
        week,
        match_date,
        "", //coach_comment,
        "", //goal_keeper_coach_comment,
        matches_type,
        match_dificulty
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });

        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChangeList(true);
    }
  };

  const fetchGetSeasons = async (isActive) => {
    setLoading(true);
    try {
      const result = await getSeasons();

      setAllActiveSeasonOptionData(
        result?.data.seasons
          .filter((filterItem, filterIndex) => filterItem.is_active == isActive)
          .map((item, index) => ({
            label: item?.season_title,
            value: item?.id,
          }))
      );

      console.log(result, "result");
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
    }
  };

  async function fetchGetSelects() {
    setLoading(true);
    try {
      const result = await getSelects();
      console.log("rezalt", result);
      const matchTypeOptions = result.response.filter(
        (item, index) => item.id == 1
      )[0]?.options;
      const matchDificultyOptions = result.response.filter(
        (item, index) => item.id == 2
      )[0]?.options;
      const facilityOptions = result.response.filter((item, index) => {
        console.log("itemdene", item.id == 22);
        console.log("itemdene123", item);
          return  item.id == 22
      })[0].options;
      const opponentCategory = result.response.filter(
        (item, index) => item.id == 21
      )[0].options;
      const minuteCategory = result.response.filter(
        (item, index) => item.id == 37
      )[0].options;
      const matchHalfCategory = result.response.filter(
        (item, index) => item.id == 40
      )[0].options;
      const playerCountCategory = result.response.filter(
        (item, index) => item.id == 38
      )[0].options;
      const matchWeekCategory = result.response.filter(
        (item, index) => item.id == 41
      )[0].options;
      const fieldNameCategory = result.response.filter(
        (item, index) => item.id == 39
      )[0].options;
      console.log(
        matchTypeOptions?.map((item, index) => ({
          label: item?.label,
          value: item?.id,
        })),
        "reulst"
      );
      setFieldNameCategory(
        fieldNameCategory?.map((item, index) => ({
          label: item?.label,
          value: item?.id,
        }))
      );
      setMatchWeekCategory(
        matchWeekCategory?.map((item, index) => ({
          label: item?.label,
          value: item?.id,
        }))
      );
      setPlayerCountCategory(
        playerCountCategory?.map((item, index) => ({
          label: item?.label,
          value: item?.id,
        }))
      );
      setMatchHalfCategory(
        matchHalfCategory?.map((item, index) => ({
          label: item?.label,
          value: item?.id,
        }))
      );
      setMinuteCategory(
        minuteCategory?.map((item, index) => ({
          label: item?.label,
          value: item?.id,
        }))
      );
      setOpponentCategory(
        opponentCategory?.map((item, index) => ({
          label: item?.label,
          value: item?.id,
        }))
      );
      setFacilityOptions(
        facilityOptions?.map((item, index) => ({
          label: item?.label,
          value: item?.id,
        }))
      );
      setMatchTypeOptions(
        matchTypeOptions?.map((item, index) => ({
          label: item?.label,
          value: item?.id,
        })) ?? ""
      );
      setMatchDificultyOptions(
        matchDificultyOptions?.map((item, index) => ({
          label: item?.label,
          value: item?.id,
        })) ?? ""
      );
      return result;
    } catch (error) {
      console.error("Hata oluştu:", error);
    } finally {
      setLoading(false);
      setIsDataChangeList(false);
    }
  }

  const fetchCreateActivityMatch = async (
    title,
    activity_type,
    start_date,
    start_time,
    type,
    season,
    league,
    week,
    difficulty,
    home_team,
    guest_team,
    small_team,
    football_ground_id,
    field,
    duration,
    half,
    number_of_player,
    activity_category_id,
    guest_team_category_id
  ) => {
    setLoading(true);
    try {
      const result = await createActivityMatch(
        title,
        activity_type,
        start_date,
        start_time,
        type,
        season,
        league,
        week,
        difficulty,
        home_team,
        guest_team,
        small_team,
        football_ground_id,
        field,
        duration,
        half,
        number_of_player,
        activity_category_id,
        guest_team_category_id
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });

        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChangeList(true);
    }
  };

  useEffect(() => {
    if (isUpdate) {
      if (updateData) {
        fetchGetAllClub();
        fetchGetSeasons(true);
        fetchGetSelects();
      }
    } else {
      fetchGetAllClub();
      fetchGetSeasons(true);
      fetchGetSelects();
    }
  }, [isDataChangeList, updateData, isUpdate]);

  console.log(matchesDificultyOptions, "matchesTypeOptions");

  const inputData = updateData
    ? updateMatchFormInputsData
    : addMatchFormInputsData;

  //tarih her seçildiğinde değerleri güncelleme
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  useEffect(() => {
    if (newEventData) {
      setStartDate(dayjs(newEventData.startStr).format("YYYY-MM-DD"));
      setEndDate(dayjs(newEventData.endStr).format("YYYY-MM-DD"));
      setStartTime(dayjs(newEventData.startStr).format("HH:mm:ss"));
      setEndTime(dayjs(newEventData.endStr).format("HH:mm:ss"));
    }
  }, [newEventData]);

  console.log(startDate, "matchStart");

  return (
    <div>
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}

      {!loading && matchesTypeOptions && matchesDificultyOptions && (
        <div className="grid h-full gap-8">
          <div className="">
            <div className="relative h-full p-6 bg-white rounded-lg">
              <p className="pt-4 pb-8 text-2xl font-semibold text-secondary">
                Maç Detayları
              </p>
              <div className="">
                <Formik
                  initialValues={{
                    matchesHomeTeam:
                      {
                        label: updateData?.home_team?.club_name,
                        value: updateData?.home_team?.id,
                      } || "",
                    matchesVisitingTeam:
                      {
                        label: updateData?.guest_team?.club_name,
                        value: updateData?.guest_team?.id,
                      } || "",
                    homeScore: updateData?.home_team_score?.toString() || "",
                    awayScore: updateData?.guest_team_score?.toString() || "",
                    matchesType: "",
                    matchesSeason:
                      {
                        label: updateData?.season?.season_title,
                        value: updateData?.season?.id,
                      } || "",
                    matchesLeague: "",
                    matchesTeam: "",
                    matchesWeek: updateData?.week?.toString() || "",
                    matchesDate: newEventData
                      ? newEventData.startStr
                      : updateData?.match_date || "",
                    coachReview: updateData?.coach_comment || "",
                    goalkeepingCoachReview:
                      updateData?.goal_keeper_coach_comment || "",
                    matches_type:
                      {
                        label: updateData?.matches_type?.label,
                        value: updateData?.matches_type?.value,
                      } || "",
                    difficulty:
                      {
                        label: updateData?.match_dificulty?.label,
                        value: updateData?.match_dificulty?.value,
                      } || "",

                    //takvim için

                    title: "",
                    activity_type: "mac",
                    status: "",
                    start_date: startDate,
                    end_date: endDate,
                    start_time: startTime,
                    end_time: endTime,
                    small_team: "",
                  }}
                  onSubmit={(values, { resetForm }) => {
                    console.log(values, "values");
                    try {
                      if (isActivity) {
                        fetchCreateActivityMatch(
                          values?.title,
                          values?.activity_type,
                          values?.start_date,
                          values?.start_time,
                          values?.type?.value,
                          values?.season?.value,
                          values?.league?.value,
                          values?.week?.value,
                          values?.difficulty?.value,
                          values?.home_team?.value,
                          values?.guest_team?.value,
                          values?.small_team,
                          values?.football_ground_id?.value,
                          values?.field?.value,
                          values?.duration?.value,
                          values?.half?.value,
                          values?.number_of_player?.value,
                          values?.activity_category_id,
                          values?.guest_team_category_id?.value
                        );
                      } else {
                        if (updateData) {
                          fetchUpdateMatches(
                            updateData?.id,
                            values?.matchesHomeTeam,
                            values?.matchesVisitingTeam,
                            values?.homeScore,
                            values?.awayScore,
                            values?.matchesSeason,
                            values?.matchesWeek,
                            values?.matchesDate,
                            values?.coachReview,
                            values?.goalkeepingCoachReview,
                            values?.matches_type,
                            values?.match_dificulty
                          );
                        } else {
                          fetchCreateMatches(
                            values?.matchesHomeTeam,
                            values?.matchesVisitingTeam,
                            values?.homeScore,
                            values?.awayScore,
                            values?.matchesSeason,
                            values?.matchesWeek,
                            values?.matchesDate,
                            values?.coachReview,
                            values?.goalkeepingCoachReview,
                            values?.matches_type,
                            values?.match_dificulty
                          );
                        }
                      }
                    } catch (error) {
                      console.log(error, "hata");
                    }
                  }}
                >
                  {({
                    errors,
                    values,
                    handleChange,
                    handleSubmit,
                    touched,
                    handleBlur,
                    setFieldValue,
                    resetForm,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {/* inputs */}
                      <div className="grid grid-cols-1 gap-12 text-primary">
                        {inputData.map((item, index) => {
                          return (
                            <div key={index}>
                              {/* oluşturulan alan başlığı */}
                              <div className="grid w-full gap-4 md:grid-cols-2">
                                {item.inputs.map((itemInputs, indexInputs) => {
                                  console.log("label", inputData);
                                  return (
                                    <div key={indexInputs}>
                                      <DynamicInput
                                        labelTitle={itemInputs.labelTitle}
                                        containerStyle={"w-full"}
                                        name={itemInputs.name}
                                        value={values}
                                        onChange={(e1, e2) => {
                                          handleCustomChange(
                                            e1,
                                            e2,
                                            itemInputs.name,
                                            setFieldValue,
                                            itemInputs.component_type
                                          );
                                        }}
                                        error={errors}
                                        isTouched={touched}
                                        onBlur={handleBlur}
                                        component_type={
                                          itemInputs.component_type
                                        }
                                        placeholder={itemInputs.placeholder}
                                        customOptions={
                                          itemInputs.name == "home_team" ||
                                          itemInputs.name == "guest_team"
                                            ? getAllClubOptions
                                            : itemInputs.name == "season"
                                            ? allActiveSeasonOptionData
                                            : itemInputs.name == "type"
                                            ? matchesTypeOptions
                                            : itemInputs.name == "difficulty"
                                            ? matchesDificultyOptions
                                            : itemInputs.name ==
                                              "football_ground_id"
                                            ? facilityOptions
                                            : itemInputs.name ==
                                              "guest_team_category_id"
                                            ? opponentCategory
                                            : itemInputs.name == "league"
                                            ? leagueOptions
                                            : itemInputs.name == "duration"
                                            ? minuteCategory
                                            : itemInputs.name == "half"
                                            ? matchHalfCategory
                                            : itemInputs.name ==
                                              "number_of_player"
                                            ? playerCountCategory
                                            : itemInputs.name == "week"
                                            ? matchWeekCategory
                                            : itemInputs.name == "field"
                                            ? fieldNameCategory
                                            : []
                                        }
                                        onlyNumber={itemInputs.onlyNumber}
                                        type={itemInputs?.type}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                        {/* inputs */}
                      </div>
                      <div className="flex items-center justify-end gap-4 mt-8">
                        <ButtonComponent
                          onClick={() => resetForm()}
                          outline
                          text="Tümünü Sil"
                        />
                        <ButtonComponent submit fill text="Kaydet" />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MatchesFields;
