import React, { useEffect, useState } from "react";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { Link, useOutletContext } from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import { GeneralDefinitionsInnerMenuDatas } from "../../utils/datas/GeneralDefinitionsMenuDatas/GeneralDefinitionsInnerMenuDatas";
import {
  addOption,
  addSelect,
  deleteOption,
  getSelects,
  updateOption,
} from "../../services/axios";
import { SettingOutlined } from "@ant-design/icons";
import { AiFillEdit, AiOutlineEdit } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Button, Input, Popconfirm } from "antd";
import { PulseLoader } from "react-spinners";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import DynamicInput from "../../components/inputs/dynamicInput";

import { useFormik } from "formik";
import Swal from "sweetalert2";
import { handleCustomChange } from "../../utils/handleCustomChange";
import { nanoid } from "nanoid";

function SelectDefinitions() {
  const [mainMenuSelected, setMainMenuSelected] = useState(0);
  const [innerMenuSelected, setInnerMenuSelected] = useState(0);
  const [setBreadcrumbData] = useOutletContext();
  const [responseDataItem, setResponseDataItem] = useState([]);
  const [dynamicFormMenuData, setDynamicFormMenuData] = useState([
    // getMenuItem("Oyuncular Formu", "menu-1", <SettingOutlined />, null),
  ]);
  const [selectedInputValue, setSelectedInputValue] = useState("");
  const [selectTitleInputValue, setSelectTitleInputValue] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openCreateOptionForm, setOpenCreateOptionForm] = useState(false);
  const [selectedSelectId, setSelectedSelectId] = useState(0);
  // console.log(selectedSelectId, "selectedSelectId");

  //tüm selectleri getirir
  async function fetchGetSelects() {
    setLoading(true);
    setDynamicFormMenuData([]);
    try {
      const result = await getSelects();
      setResponseDataItem(result.response);
      (dynamicFormMenuData || !responseDataItem) &&
        result.response.forEach((item, index) => {
          handleDataPush(item.title, index, <SettingOutlined />, null);
        });
      setLoading(false);
    } catch (error) {
      console.error("Hata oluştu:", error);
      setLoading(false);
    }
  }

  //sol menüdeki başlıkları oluşturur
  const handleDataPush = (label, value) => {
    const newItem = getMenuItem(label, value, <SettingOutlined />, null);
    setDynamicFormMenuData((prevInputs) => [...prevInputs, newItem]);
  };

  //data yoksa response data'yı getirir
  useEffect(() => {
    // console.log(responseDataItem, "result");
    if (responseDataItem?.length == 0) {
      fetchGetSelects();
    }
  }, []);

  useEffect(() => {
    if (responseDataItem && innerMenuSelected !== null) {
      const selectedItem = responseDataItem[innerMenuSelected];
      setSelectedSelectId(selectedItem?.id);
    }
  }, [responseDataItem, innerMenuSelected]);

  //axios'taki update fonksiyonunu çalıştırır
  async function fetchUpdateOption(label, value, select_list_id, option_id) {
    try {
      const result = await updateOption(
        label,
        value,
        select_list_id,
        option_id
      );
    } catch (error) {
      console.error("Hata oluştu:", error);
    }
  }

  //popconfirm'deki kaydet butona tıklanınca olacak işlem
  async function updateConfirm(label, value, select_list_id, option_id) {
    try {
      setConfirmLoading(true);
      await fetchUpdateOption(label, value, select_list_id, option_id);
      await fetchGetSelects();
    } catch (error) {
      console.error("Hata oluştu:", error);
    } finally {
      setConfirmLoading(false);
      setSelectedInputValue("");
    }
  }

  async function fetchAddOption(label, value, select_list_id) {
    try {
      const result = await addOption(label, value, select_list_id);
      // console.log(result, "add option");
    } catch (error) {
      console.log(error, "error");
    }
  }

  async function fetchAddSelect(title) {
    try {
      const result = await addSelect(title);
      // console.log(result, "add Select");
      if (result.success) {
        await fetchGetSelects(); // Verileri yenilemek için fetchGetSelects fonksiyonunu çağır
      }
      return result;
    } catch (error) {
      console.log(error, "error");
    }
  }

  const fetchDeleteOption = async (id) => {
    try {
      const result = await deleteOption(id);
      // console.log(result, "result");
      await fetchGetSelects();
      return result;
    } catch (error) {
      console.log("hata", error);
    }
  };

  const CreateDynamicFormInputData = [
    {
      labelTitle: "Seçenek Başlığı",
      name: "label",
      placeholder: "Seçenek Başlığı Giriniz",
      required: true,
      component_type: "input",
    },
  ];

  const handleDeleteOption = (item) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${item?.label}" seçeneğini silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteOption(item?.id);
        Swal.fire("Silindi!", `"${item?.label}" seçeneği silindi.`, "success");
      }
    });
  };

  const { errors, values, handleSubmit, touched, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        label: "",
      },
      onSubmit: async (values, { resetForm }) => {
        console.log("dene12", values)
        // console.log(values, "values");
        try {
          const result = await fetchAddOption(
            values.label,
            nanoid(), //randomName
            selectedSelectId
          );
          if (result.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result?.message ?? "Ekleme Başarılı!",
              showConfirmButton: false,
              timer: 1000,
            });

            //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: result?.message ?? "Ekleme Başarısız!",
              showConfirmButton: false,
              timer: 1000,
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          resetForm();
          setOpenCreateOptionForm(false);
          fetchGetSelects();
        }
      },
      validate: (values) => {
        console.log(errors, "errors");
        return errors;
      },
    });
  return (
    <div>
      <div className="w-full h-full px-4 pb-4 bg-gray-50 rounded-2xl">
        {/* inner key : {innerMenuSelected} */}
        {
          <>
            {loading && (
              <div className="flex">
                <PulseLoader color="red" />
                <p>Yükleniyor...</p>
              </div>
            )}
            {!loading && (
              <div className="flex flex-col w-full h-full px-4 py-8 bg-white rounded-2xl">
                <div className="flex">
                  <DrawerMenuAntd
                    items={dynamicFormMenuData}
                    selectedKey={innerMenuSelected}
                    setSelectedKey={setInnerMenuSelected}
                  />
                  {responseDataItem
                    ?.filter(
                      (filterItem, filterIndex) =>
                        filterIndex == innerMenuSelected
                    )
                    ?.map((item, index) => {
                      return (
                        <>
                          <div key={index} className="w-full px-2 zoom-none-2">
                            <div className="">
                              {/* <p>select id: {item.id}</p> */}
                              <p className="py-2 text-xl w-fit">
                                Başlık:{" "}
                                <span className="font-bold text-primary">
                                  {item.title}
                                </span>
                              </p>
                            </div>
                            <hr />
                            <div className="flex flex-col gap-4 py-4">
                              <p className="text-xs border-b w-fit">
                                Seçenekler
                              </p>

                              {item.options.map((itemOption, indexOption) => {
                                return (
                                  <Popconfirm
                                    key={itemOption.id}
                                    className="w-fit"
                                    title="Seçeneği Düzenle"
                                    description={
                                      <div>
                                        <Input
                                          type="text"
                                          value={selectedInputValue}
                                          placeholder={itemOption.label}
                                          onChange={(e) =>
                                            setSelectedInputValue(
                                              e.target.value
                                            )
                                          }
                                        />
                                        <div className="py-2">
                                          <ButtonComponent
                                            outline
                                            text={"Sil"}
                                            customStyle={"w-fit h-7"}
                                            onClick={() =>
                                              handleDeleteOption(itemOption)
                                            }
                                          />
                                        </div>
                                      </div>
                                    }
                                    okText="Kaydet"
                                    cancelText="Vazgeç"
                                    okButtonProps={{
                                      loading: confirmLoading,
                                    }}
                                    onConfirm={async () => {
                                      await updateConfirm(
                                        selectedInputValue,
                                        itemOption.value,
                                        item.id,
                                        itemOption.id
                                      );
                                    }}
                                    onCancel={() => {
                                      setSelectedInputValue("");
                                    }}
                                    onOpenChange={() => {
                                      setSelectedInputValue("");
                                    }}
                                  >
                                    <div className="flex items-center gap-4 px-2 py-2 text-xl border-l-4 cursor-pointer border-primary group w-fit">
                                      <p>{itemOption.label}</p>
                                      <div className="flex items-center p-1 rounded opacity-0 group-hover:opacity-100 bg-primary w-fit">
                                        <AiOutlineEdit className="text-xl text-white " />
                                      </div>
                                    </div>
                                  </Popconfirm>
                                );
                              })}
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>

                <div className="flex items-end justify-between gap-4 px-8 mt-8">
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-1">
                      <p className="text-xs">Yeni Seçenek Seçimi Ekle</p>
                      <Input
                        value={selectTitleInputValue}
                        onChange={(e) =>
                          setSelectTitleInputValue(e.target.value)
                        }
                        placeholder="Başlık giriniz..."
                      />
                    </div>
                    <ButtonComponent
                      loading={!selectTitleInputValue}
                      onClick={async () => {
                        try {
                          const result = await fetchAddSelect(
                            selectTitleInputValue
                          );
                          if (result.success) {
                            Swal.fire({
                              position: "center",
                              icon: "success",
                              title: result?.message ?? "Ekleme Başarılı!",
                              showConfirmButton: false,
                              timer: 1000,
                            });
                            // Ekleme başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
                          } else {
                            Swal.fire({
                              position: "center",
                              icon: "error",
                              title: result?.message ?? "Ekleme Başarısız!",
                              showConfirmButton: false,
                              timer: 1000,
                            });
                          }
                        } catch (error) {
                          console.error(error); // Hata konsola yazdırılıyor
                          Swal.fire({
                            position: "center",
                            icon: "error",
                            title: "Bir hata oluştu!",
                            text: "Seçenek ekleme işlemi başarısız oldu.",
                            showConfirmButton: false,
                            timer: 1000,
                          });
                        } finally {
                          setSelectTitleInputValue("");
                        }
                      }}
                      outline
                      text="Seçenek Seçimi Ekle"
                    />
                  </div>
                  <ButtonComponent
                    onClick={() => {
                      setOpenCreateOptionForm(true);
                    }}
                    outline
                    text="Seçenek Ekle"
                  />
                </div>
                <CustomModal
                  //faaliyet adresi ekleme
                  width={677}
                  isOpen={openCreateOptionForm}
                  setOpen={setOpenCreateOptionForm}
                  footer={null}
                >
                  <CustomModalHeader
                    title={"Yeni Input Ekleyin"}
                    description={"Yeni Input Ekleyin"}
                  />
                  <form onSubmit={handleSubmit} className="w-full pt-8">
                    <div className="grid w-full grid-cols-1 gap-4 px-8">
                      {CreateDynamicFormInputData.map((item, index) => (
                        <div key={index} className="w-full">
                          <DynamicInput
                            type={"text"}
                            labelTitle={item.labelTitle}
                            containerStyle={"w-full"}
                            placeholder={item.placeholder}
                            name={item.name}
                            value={values}
                            error={errors}
                            isTouched={touched}
                            onBlur={handleBlur}
                            onChange={(e, e2) =>
                              handleCustomChange(
                                e,
                                e2,
                                item.name,
                                setFieldValue,
                                item.component_type
                              )
                            }
                            required={true}
                            options={item?.options}
                            checkboxLabel={item.checkboxLabel}
                            component_type={item.component_type}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex items-center justify-end gap-4 px-8 mt-8">
                      <ButtonComponent
                        onClick={() => setOpenCreateOptionForm(false)}
                        outline
                        text="Vazgeç"
                      />
                      <ButtonComponent fill text="Kaydet" submit />
                    </div>
                  </form>
                </CustomModal>
              </div>
            )}
          </>
        }
      </div>
    </div>
  );
}

export default SelectDefinitions;
