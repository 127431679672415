import React, { useContext } from "react";
import { SidebarContext } from "../../../context";

import {FiMenu} from "react-icons/fi";

const ToggleSidebar = () => {
  const SidebarContextData = useContext(SidebarContext);

  return (
    <button
      className="flex items-center justify-center text-white lg:hidden"
      onClick={() => SidebarContextData.setActive((a) => !a)}
    >
      <FiMenu size={36} />
    </button>
  );
};

export default ToggleSidebar;
