import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Paths } from "../../routes";
import ApplicantsList from "./applicantsList";


const Applicants = () => {
  const [setBreadcrumbData] = useOutletContext();

  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel",

      },
      {
        title: "Adaylar",
      },
    ]);

  }, []);
  return (
    <>
      <div className="w-full p-4 bg-white rounded-md shadow">
        <ApplicantsList />
      </div>
    </>
  );
};

export default Applicants;
