import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuBox = ({ menuList }) => {
  const location = useLocation();

  return (
    <ul className="w-full">
      {menuList.map((item, index) => {
        return (
          <li key={index} className="py-1 border-t">
            <Link
              to={item.href}
              className={`flex items-center justify-between border-4 border-transparent hover:border-l-primary px-6 py-3 transition-all group hover:font-semibold hover:text-primary ${
                location.pathname === item.href &&
                "border-l-primary font-semibold text-primary"
              }`}
            >
              {item.title}
              <span
                className={`text-primary text-lg ${
                  location.pathname === item.href
                    ? "visible"
                    : "invisible group-hover:visible"
                }`}
              >
                {item.icon}
              </span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default MenuBox;
