import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import DynamicInput from "../../components/inputs/dynamicInput";
import { handleCustomChange } from "../../utils/handleCustomChange";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import { PulseLoader } from "react-spinners";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import {
  createActivityCategory,
  deleteActivityCategory,
  updateActivityCategory,
} from "../../services/axios";
import Swal from "sweetalert2";

export function CreateCategoriesForm({
  selectedCategory,
  isDataChange,
  setIsDataChange,
  isOpen,
  setOpen,
  allCategories,
}) {
  const [upCategoryOptions, setUpCategoryOptions] = useState(null);
  const [upCategoryInitial, setUpCategoryInitial] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUpCategory, setIsUpCategory] = useState(null); //üst kategori var mı checkbox

  const fetchUpdateActivitiesCategory = async (
    id,
    activity_field,
    title,
    up_category,
    is_up_category,
    type_field,
    add_main_time
  ) => {
    setLoading(true);
    try {
      const result = await updateActivityCategory(
        id,
        activity_field,
        title,
        up_category,
        is_up_category,
        type_field,
        add_main_time
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Düzenleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });
        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };

  const fetchCreateActivitiesCategory = async (
    activity_field,
    title,
    up_category,
    type_field,
    add_main_time
  ) => {
    setLoading(true);
    try {
      const result = await createActivityCategory(
        activity_field,
        title,
        up_category,
        type_field,
        add_main_time
      );
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });
        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };

  const fetchDeleteActivityCategory = async (id) => {
    setLoading(true);
    try {
      const result = await deleteActivityCategory(id);
      // console.log(result, "result");
      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };

  const handleDeleteActivityCategory = async (item) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${item?.title}" kategorisini silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteActivityCategory(item?.id);
        Swal.fire("Silindi!", `"${item?.title}" kategori silindi.`, "success");
      }
    });
  };

  const handleUpCategory = async () => {
    try {
      const createOption = await allCategories?.map((item, index) => ({
        label: item.title ? item.title : "-",
        value: item.id,
      }));
      return createOption;
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (allCategories) {
      handleUpCategory().then((createOption) => {
        setUpCategoryOptions(
          createOption?.filter((item) => item?.value != selectedCategory?.id)
        );
        setUpCategoryInitial(
          createOption?.find(
            (item) => item.value == selectedCategory?.up_category
          )
        ); //sadece üst kategorileri almak için
        setIsUpCategory(
          createOption?.find(
            (item) => item.value == selectedCategory?.up_category
          )
            ? true
            : false
        ); //checkbox için
      });
    }
  }, [allCategories]);

  const activityField =
    selectedCategory?.activity_field == "in"
      ? { label: "Saha İçi", value: "in" }
      : selectedCategory?.activity_field == "out"
      ? { label: "Saha Dışı", value: "out" }
      : null;

  const typeField =
      selectedCategory?.type == "team"
          ? { label: "Takım", value: "team" }
          : selectedCategory?.type == "group-player"
              ? { label: "Grup / Bireysel", value: "group-player" }
              : null;

  const addMainTimeField = selectedCategory?.isAddMainTime == 1 ? true : false;

  // console.log(selectedCategory, "selectedCategory")
  const initialValues = {
    title: selectedCategory?.title ?? "",
    activity_field: activityField ?? "",
    up_category: upCategoryInitial ?? "",
    type_field: typeField ?? "",
    add_main_time: selectedCategory?.isAddMainTime ?? true
  };

  return (
    <div>
      {loading ? (
        <>
          <div className="flex">
            <PulseLoader color="red" />
            <p>Yükleniyor...</p>
          </div>
        </>
      ) : null}
      {!loading && upCategoryOptions && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            values.activity_field = values?.activity_field?.value;
            values.type_field = values?.type_field?.value;
            isUpCategory && (values.up_category = values?.up_category?.value);

            if (selectedCategory) {
              fetchUpdateActivitiesCategory(
                selectedCategory?.id,
                values?.activity_field,
                values?.title,
                isUpCategory ? values?.up_category : "",
                isUpCategory ? 1 : 0,
                values?.type_field,
                values?.add_main_time ? 1 : 0
              );
            } else {
              fetchCreateActivitiesCategory(
                values?.activity_field,
                values?.title,
                isUpCategory ? values?.up_category : "",
                values?.type_field,
                values?.add_main_time ? 1 : 0
              );
            }
            // console.log(values, "values");
          }}
        >
          {({
            errors,
            values,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit} className="mt-4">
              <DynamicInput
                labelTitle={"Faaliyet Kategorisi Başlığı"}
                containerStyle={"w-full"}
                placeholder={"Faaliyet Kategorisi Başlığı"}
                name={"title"}
                value={values}
                onChange={(e1, e2) => {
                  handleCustomChange(e1, e2, "title", setFieldValue, "input");
                }}
                error={errors}
                isTouched={touched}
                onBlur={handleBlur}
              />
              <DynamicInput
                labelTitle={"Üst Kategorisi Var Mı?"}
                containerStyle={"w-full"}
                placeholder={"Üst Kategorisi Var Mı?"}
                value={isUpCategory}
                onChange={(e1, e2) => {
                  setIsUpCategory(e1.target.checked);
                }}
                component_type={"checkbox"}
              />
              {isUpCategory && (
                <DynamicInput
                  labelTitle={"Üst Kategorisi"}
                  containerStyle={"w-full"}
                  placeholder={"Üst Kategorisi"}
                  name={"up_category"}
                  value={values}
                  onChange={(e1, e2) => {
                    handleCustomChange(
                      e1,
                      e2,
                      "up_category",
                      setFieldValue,
                      "select"
                    );
                  }}
                  error={errors}
                  isTouched={touched}
                  onBlur={handleBlur}
                  component_type={"select"}
                  customOptions={upCategoryOptions}
                />
              )}
              {!isUpCategory && (
                  <>
                    <DynamicInput
                        labelTitle={"Faaliyet Alanı"}
                        containerStyle={"w-full"}
                        placeholder={"Faaliyet Alanı"}
                        name={"activity_field"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                              e1,
                              e2,
                              "activity_field",
                              setFieldValue,
                              "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={[
                          {
                            label: "Saha İçi",
                            value: "in",
                          },
                          {
                            label: "Saha Dışı",
                            value: "out",
                          },
                        ]}
                    />
                    <DynamicInput
                        labelTitle={"Faaliyet Türü"}
                        containerStyle={"w-full"}
                        placeholder={"Faaliyet Türü"}
                        name={"type_field"}
                        value={values}
                        onChange={(e1, e2) => {
                          handleCustomChange(
                              e1,
                              e2,
                              "type_field",
                              setFieldValue,
                              "select"
                          );
                        }}
                        error={errors}
                        isTouched={touched}
                        onBlur={handleBlur}
                        component_type={"select"}
                        customOptions={[
                          {
                            label: "Takım",
                            value: "team",
                          },
                          {
                            label: "Grup/Bireysel",
                            value: "group-player",
                          },
                        ]}
                    />
                  </>
              )}
              <DynamicInput
                  containerStyle={"w-full"}
                  placeholder={"Ana Faaliyet Süresine Eklensin"}
                  name={"add_main_time"}
                  value={values}
                  onChange={(e1, e2) => {
                    handleCustomChange(
                        e1,
                        e2,
                        "add_main_time",
                        setFieldValue,
                        "checkbox"
                    );
                  }}
                  error={errors}
                  isTouched={touched}
                  onBlur={handleBlur}
                  component_type={"checkbox"}
              />
              <div className="flex justify-end gap-4 mt-16">
                {selectedCategory && (
                  <ButtonComponent
                    text={"Sil"}
                    outline
                    onClick={() => {
                      handleDeleteActivityCategory(selectedCategory);
                    }}
                  />
                )}
                <div className="flex gap-4">
                  <ButtonComponent
                    text={"Temizle"}
                    outline
                    onClick={() => resetForm()}
                  />
                  {!selectedCategory && (
                    <ButtonComponent
                      text={"Vazgeç"}
                      outline
                      onClick={() => {
                        resetForm();
                        setOpen(false);
                      }}
                    />
                  )}
                  <ButtonComponent fill text={"Kaydet"} submit />
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}

function CreateCategoriesModal({
  isOpen,
  setOpen,
  setIsDataChange,
  allCategories,
}) {
  return (
    <div>
      <div>
        <CustomModal
          width={677}
          isOpen={isOpen}
          setOpen={setOpen}
          footer={null}
        >
          <CustomModalHeader
            title={"Yeni Kategori Ekleyin"}
            description={"Aşağıdan yeni kategori ekleyin."}
          />
          <CreateCategoriesForm
            isOpen={isOpen}
            setOpen={setOpen}
            setIsDataChange={setIsDataChange}
            allCategories={allCategories}
          />
        </CustomModal>
      </div>
    </div>
  );
}

export default CreateCategoriesModal;
