import React, { useEffect, useState } from "react";
import DrawerMenuAntd, {
  getMenuItem,
} from "../../../components/drawerMenuAntd";
import { SettingOutlined } from "@ant-design/icons";
import { PulseLoader } from "react-spinners";
import ButtonComponent from "../../../components/buttons/ButtonComponent";
import { getAllTreatmentsStatus } from "../../../services/axios";
import CreateTreatmentsStatusModal, {
  CreateTreatmentsStatusForm,
} from "../../../containers/modals/CreateTreatmentsStatusModal";

function AllTreatmentsStatus() {
  const [loading, setLoading] = useState(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [isDataChange, setIsDataChange] = useState(true);
  const [menuData, setMenuData] = useState([]);
  const [allTreatmentsStatusData, setAllTreatmentsStatusData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  // console.log(isDataChange, "isDataChange");

  const fetchGetAllTreatments = async () => {
    setLoading(true);
    setMenuData([]);
    try {
      const result = await getAllTreatmentsStatus();
      // console.log(result?.data?.statuses, "statuses");

      setAllTreatmentsStatusData(result?.data?.statuses);

      const allTreatmentsStatus = result?.data?.statuses;
      allTreatmentsStatus.map((item, index) => {
        handleDataPush(item?.treatments_status_title, index);
      });
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  const handleDataPush = (label, value) => {
    const newItem = getMenuItem(label, value, <SettingOutlined />, null);
    setMenuData((prev) => [...prev, newItem]);
  };

  useEffect(() => {
    isDataChange && fetchGetAllTreatments();
  }, [isDataChange]);

  function SelectedItemForm() {
    return allTreatmentsStatusData
      .filter((filterItem, filterIndex) => filterIndex == selectedMenuIndex)
      .map((item, index) => {
        return (
          <React.Fragment>
            <CreateTreatmentsStatusForm
              isOpen={modalOpen}
              setOpen={setModalOpen}
              setIsDataChange={setIsDataChange}
              isDataChange={isDataChange}
              updateSelectedValuesProp={item}
            />
          </React.Fragment>
        );
      });
  }
  return (
    <div>
      {loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      <div className="flex mt-4">
        <div className="flex flex-col">
          <DrawerMenuAntd
            items={menuData}
            selectedKey={selectedMenuIndex}
            setSelectedKey={setSelectedMenuIndex}
          />
          <ButtonComponent
            customStyle={"mt-4"}
            text={"Yeni Tedavi Durumu Ekle"}
            outline
            onClick={() => {
              setModalOpen(true);
            }}
          />
          <CreateTreatmentsStatusModal
            isOpen={modalOpen}
            setOpen={setModalOpen}
            setIsDataChange={setIsDataChange}
          />
        </div>
        <div className="w-full px-4">
          <SelectedItemForm />
        </div>
      </div>
    </div>
  );
}

export default AllTreatmentsStatus;
