import { configureStore } from "@reduxjs/toolkit";
import {
  MatchesSlice,
  PlayerSlice,
  ActivitiesSlice,
  TreatmentsSlice,
  ApplicantsSlice,
  unitDefinitionsSlice,
} from "./features";
import TeamsSlice from "./features/teams/TeamsSlice";
import authUserSlice from "./features/authUser/authUserSlice";

export const store = configureStore({
  reducer: {
    players: PlayerSlice,
    activities: ActivitiesSlice,
    matches: MatchesSlice,
    treatments: TreatmentsSlice,
    applicants: ApplicantsSlice,
    teams: TeamsSlice,
    authUser: authUserSlice,
    unitDefinitions: unitDefinitionsSlice,
  },
});
