import React, { useEffect, useState } from "react";
import { updateMatchFinish } from "../../../services/axios";
import Swal from "sweetalert2";
import ButtonComponent from "../../../components/buttons/ButtonComponent";

const MatchUpdateFinishBtn = ({
  matchId,
  isFinish,
  title,
  setIsDataChange
}) => {
  const [btnClickListener, setBtnClickListener] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchtUpdateMatchFinish = async () => {
    try {
      const result = await updateMatchFinish(matchId, isFinish);
      setBtnClickListener(false);

      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: isFinish ? "Maç Sonlandırıldı" : "Maç Devam Ediyor",
          showConfirmButton: false,
          timer: 1000
        });
        setIsDataChange(true);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Hata",
          showConfirmButton: false,
          timer: 1000
        });
      }
    } catch (error) {
      console.log(error, "hata");
    }
  };
  const handleCLick = async () => {
    try {
      Swal.fire({
        title: isFinish ? "Emin misiniz?" : "Maç Devam Etsin mi?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Evet",
        cancelButtonText: "Vazgeç"
      }).then((result) => {
        if (result.isConfirmed) {
          setBtnClickListener(true);
          setIsDataChange(true);
        }
      });
    } catch (error) {
      console.log(error, "hata");
    }
  };

  useEffect(() => {
    if (btnClickListener) {
      fetchtUpdateMatchFinish();
    }
  }, [btnClickListener]);
  return (
    <div>
      <div className="flex items-center justify-end py-4 pr-8">
        <ButtonComponent
          fill
          
          text={title}
          customStyle={"w-fit"}
          loading={loading}
          onClick={() => {
            handleCLick();
          }}
        />
      </div>
    </div>
  );
};

export default MatchUpdateFinishBtn;
