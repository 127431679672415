import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validations = Yup.object({
  tckn: Yup.string().required("TC Kimlik Zorunludur!"),
  // password: Yup.string().required("Şifre Zorunludur!"),
//   phoneNumber: Yup.string()
//     .required("Lütfen telefon numaranızı giriniz.")
//     .matches(phoneRegExp, "Lütfen geçerli bir telefon numarası giriniz.")
//     .min(10, "Eksik bir telefon numarası girdiniz.")
//     .max(10, "Uzun bir telefon numarası girdiniz."),
});

export default validations;
