import React, { useEffect, useState } from "react";
import { Paths } from "../../routes";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Widgets } from "../../containers/sections";
import UpcomingMatches from "../../containers/sections/UpcomingMatches";
import { logo } from "../../utils/filePath";
import { DataTable } from "../../components/tables";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterPlayers,
  GetApplicants,
  GetPlayers,
  GetTreatments
} from "../../services/redux/features";

import { Popconfirm } from "antd";
import { EditOutlined, FileSearchOutlined } from "@ant-design/icons";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";

import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import trLocale from "@fullcalendar/core/locales/tr";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import AlertDialogDelete from "../../components/alertDialogDelete";
import ScoreTable from "../../containers/sections/ScoreTable";
import { NextMatches } from "../../containers/sections/matches/nextMatches";
import RecentlyAddedPlayers from "../../containers/sections/recentlyAddedPlayers";
import MainClub from "../../containers/sections/mainClub";
import RecentlyAddedCandidates from "../../containers/sections/recentlyAddedCandidate/index";
import { RecentlyAddedTreatments } from "../../containers/sections/recentlyAddedTreatments";
import { TotalPlayerCount } from "../../containers/sections/totalPlayerCount";
import TreatmentCompletedPlayers from "../../containers/sections/treatmentCompletedPlayers";
import TreatmentApproachingPlayers from "../../containers/sections/treatmentApproachingPlayers";
import {TodaysInjured} from '../../containers/sections/todaysInjured'
import { TreatmentEnded } from '../../containers/sections/treatmentEnded'

const Dashboard = () => {
  const [setBreadcrumbData] = useOutletContext();

  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel"
      },
      {
        title: <Link to={Paths.Dashboard}>Anasayfa</Link>
      }
    ]);
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!players) {
      dispatch(GetPlayers());
    }
    if (!treatments) {
      dispatch(GetTreatments());
    }
    if (!applicants) {
      dispatch(GetApplicants());
    }
  }, []);

  const { players } = useSelector((state) => state.players);
  const { treatments } = useSelector((state) => state.treatments);
  const { applicants } = useSelector((state) => state.applicants);
  // console.log(treatments)

  const [events, setEvents] = useState([
    {
      start: "2023-06-07T21:00:00.000Z",
      end: "2023-06-10T21:00:00.000Z",
      title: "Custom Event",
      id: 1,
      color: "blue",
      //custom data
      key: 1,
      status: `Faaliyet ${1} Gerçekleşti`,
      activitiesDate: "02/02/2019",
      activitiesTime: "00:50",
      activitiesGroup: "Takım Antrenmanları ALFA U18/19",
      activitiesZone: "Metin Oktay Yerleşkesi",
      activitiesDescription: "Kuvvet takım Antrenmanları",
      activitiesTeam: "ALFA U18/19",
      manager: "Serkan Yeğin, Emre Inan",
      approval: true
    },
    {
      start: "2023-06-05T00:00:00.000Z",
      end: "2023-05-11T00:00:00.000Z",
      title: "Altınordu FK ile toplantı",
      id: 2,
      color: "red",
      //custom data
      key: 2,
      status: `Faaliyet ${1} Gerçekleşti`,
      activitiesDate: "02/02/2019",
      activitiesTime: "00:50",
      activitiesGroup: "Takım Antrenmanları ALFA U18/19",
      activitiesZone: "Metin Oktay Yerleşkesi",
      activitiesDescription: "Kuvvet takım Antrenmanları",
      activitiesTeam: "ALFA U18/19",
      manager: "Serkan Yeğin, Emre Inan",
      approval: false
    }
  ]);
  const handleSelect = (info) => {
    const { start, end } = info;
    console.log("Clicked!");
    const eventNamePrompt = prompt("Etkinlik Giriniz");
    if (true) {
      setEvents([
        ...events,
        {
          start,
          end,
          title: eventNamePrompt,
          id: Math.floor(Math.random() * 5)
        }
      ]);
      console.log(events, "events"); //yeni event eklendiğinde tüm faaliyetler
    }
  };
  const handleChange = (info) => {
    const updatedEvents = [];
    const newEvent = {
      start: info.event.start,
      end: info.event.end,
      title: info.event.title,
      id: Number(info.oldEvent.id),
      color: info.oldEvent.color
    };

    events.map((item, index) =>
      item.id === newEvent.id
        ? updatedEvents.push(newEvent)
        : updatedEvents.push(item)
    );
    setEvents(updatedEvents);
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="relative z-20 w-full rounded-md">
        <div className="flex items-center py-16">
          <Widgets />
        </div>
        <div className="grid gap-4 xl:grid-cols-3">
          <div className="flex flex-col">
            <NextMatches />
          </div>
          <div
            id="game-stat-section"
            className="p-5 pt-8 pb-12 bg-white border border-t-4 border-gray-100 shadow-xl rounded-xl border-t-primary focus:outline-none"
          >
            <MainClub />
          </div>
          {/* 2. kısım */}
          <ScoreTable />
          {/* 2. kısım sonu */}
        </div>
        <div className="grid gap-4 mt-8 xl:grid-cols-3">
          <TotalPlayerCount />
          {/* --- */}

          <RecentlyAddedPlayers />
          {/* --- */}

          <RecentlyAddedTreatments />

          {/* --- */}
          <RecentlyAddedCandidates />

          {/* --- */}
          <TreatmentCompletedPlayers />
          {/* --- */}
          <TreatmentApproachingPlayers />
           {/* --- */}
           <TodaysInjured />
           {/* ---*/}
           <TreatmentEnded />
           {/*  */}
        </div>
        {/* <UpcomingMatches /> */}
      </div>
    </>
  );
};

export default Dashboard;
