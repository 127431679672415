import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getAllTreatments} from "../../../axios";
const initialState = {
  treatments: [],
  filteredTreatments: [],
  loading: false,
  error: {
    code: null,
    message: null,
  },
};

export const GetTreatments = createAsyncThunk(
  "treatments/GetTreatments",
  async () => {
    try {
      const response = getAllTreatments()
      console.log("wc",response.data)
      return response.data
    } catch (error) {
      console.error(error)
    }
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     let treatmentsData = [];
    //     for (let i = 1; i <= 25; i++) {
    //       treatmentsData.push({
    //         key: i,
    //         treatmentsDate: "13/04/2023",
    //         treatmentsType: "2. Derece Sakatlık",
    //         treatmentsNameAndSurname: "Ege Alp Tunçel",
    //         treatmentsGripe: "Sağ Üst Arka Adele Ağrı",
    //         treatmentsTGS: "14",
    //         treatmentsIGS: "-8",
    //         treatmentsStatus: "Kontrol Tarihi Verildi",
    //         treatmentsEnd: "01/01/2024",
    //         treatmentsPersonnel: "Rasit Simsek",
    //         treatmentsControlDate: "19/04/2023",
    //         treatmentsKKGun: "",
    //       });
    //     }
    //     resolve(treatmentsData);
    //   }, 750);
    // });
  }
);

export const TreatmentsSlice = createSlice({
  name: "treatments",
  initialState,
  reducers: {
    filterTreatments: (state, action) => {
      const filterInputValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetTreatments.pending, (state, action) => {
      state.loading = true;
      state.message = null;
      state.error = {
        code: null,
        message: null,
      };
    });
    builder.addCase(GetTreatments.fulfilled, (state, action) => {
      console.log("bak", action.payload);
      state.treatments = action.payload;
      state.loading = false;
    });
    builder.addCase(GetTreatments.rejected, (state, action) => {
      state.loading = false;
      state.message = "Tedavi Verileri Getirme Reddedildi!!";
      state.error = {
        code: 500,
        message: "Get Treatments Rejected",
      };
    });
  },
});

export const { filterTreatments } = TreatmentsSlice.actions;
export default TreatmentsSlice.reducer;
