import React, { useEffect, useState } from "react";
import PageTitle from "../../components/pageTitle";
import DrawerMenuAntd, { getMenuItem } from "../../components/drawerMenuAntd";
import { SettingOutlined } from "@ant-design/icons";
import AddPlayerDynamicForm from "./addPlayerDynamicForm";

function DynamicForm() {
  return (
    <div>
      <div className="w-full h-full px-4 py-4 bg-white shadow rounded-2xl">
        <PageTitle title={"Dinamik Formlar"} bg />
        <div className="flex flex-col w-full gap-8">
          <AddPlayerDynamicForm personalInfo />
        </div>
      </div>
    </div>
  );
}

export default DynamicForm;
