import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Aside, Footer, Header, Sidebar } from "../containers/layout";
import Breadcrumb from "../components/breadcrumbs";
import ZoomOnResize from "../utils/zoom";
import {
  getAuthUser,
  getStoredBearerToken,
  logoutRequest,
} from "../services/axios";
import { useDispatch, useSelector } from "react-redux";
import { setAuthUser } from "../services/redux/features/authUser/authUserSlice";
import Swal from "sweetalert2";

const MainLayout = () => {
  const [breadcrumbData, setBreadcrumbData] = useState([]);
  const [loading, setLoading] = useState(false);
  const authUser = useSelector((state) => state.authUser.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchAuthUser = async () => {
    setLoading(true);
    try {
      const result = await getAuthUser();
      if (result.success) {
        dispatch(setAuthUser(result?.data?.user));
      } else {
        dispatch(setAuthUser(null));
        localStorage.removeItem("bearerToken");

        //Auth verisi dönmediyse ve hala token var ise alert çıkar
        if (getStoredBearerToken) {
          Swal.fire({
            title: "Oturumunuzun Süresi Doldu",
            text: "Giriş Ekranına Gidiniz!",
            icon: "warning",
            buttons: {
              // cancel: "İptal",
              confirm: "Giriş Ekranına Git",
            },
          }).then((isConfirmed) => {
            if (isConfirmed) {
              window.location.reload();
              // navigate("")
              //Silinen token'ın kaldırılması için sayfayı yeniler
            }
          });
        }
      }
      return result;
    } catch (error) {
      dispatch(setAuthUser(null));
      localStorage.removeItem("bearerToken");

      //Auth verisi dönmediyse ve hala token var ise alert çıkar
      Swal.fire({
        title: "Oturumunuzun Süresi Doldu",
        text: "Giriş Ekranına Gidiniz!",
        icon: "warning",
        buttons: {
          // cancel: "İptal",
          confirm: "Giriş Ekranına Git",
        },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          window.location.reload();
          //Silinen token'ın kaldırılması için sayfayı yeniler
        }
      });
      console.log(error, "auth error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!authUser) {
      fetchAuthUser();
    }
  }, [authUser]);

  return (
    <div className="zoom">
      {/* <ZoomOnResize /> */}

      <div className="absolute inset-0 w-full bg-cover bg-login-image h-[750px] bg-center z-0"></div>

      <div className="relative z-10">
        <Header />
        <div className="mx-auto mt-12">
          {/* max-w-[1840px] */}
          <Sidebar />
          <div className="relative flex w-full gap-6 px-4 mx-auto container-full">
            <div className="hidden lg:block py-14 lg:pt-24">
              <Aside />
            </div>
            <div className="w-full overflow-x-hidden">
              <Breadcrumb items={breadcrumbData} />
              <Outlet context={[setBreadcrumbData]} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
