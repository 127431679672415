import React, {useEffect} from "react";
import {Link, useOutletContext} from "react-router-dom";
import {Paths} from "../../routes";

import {ActivitiesList} from "../../containers/sections";

const Activities = () => {
    const [setBreadcrumbData] = useOutletContext();

    useEffect(() => {
        setBreadcrumbData([
            {
                title: 'Panel',
            },
            {
                title: <Link to={Paths.Activities}>Faaliyet Listesi</Link>,
            }
        ]);
    }, []);

    return (
        <div className="w-full p-4 bg-white rounded-md shadow">
            <ActivitiesList/>
        </div>
    );
}

export default Activities;