import React, { useState } from "react";
import { CreateTreatmentsForm } from "../../../containers/modals/CreateTreatmentsModal";
import PageTitle from "../../../components/pageTitle";

function AddTreatments() {
  const [isDataChange, setIsDataChange] = useState(true);
  return (
    <div className="p-4 bg-white">
      <PageTitle bg title={"Tedavi Ekle"} />
      <CreateTreatmentsForm
        isOpen={true}
        setOpen={null}
        setIsDataChange={setIsDataChange}
      />
    </div>
  );
}

export default AddTreatments;
