// src/App.js
import React from 'react';
import { Card, Table, Select, Button } from 'antd';
const { Option } = Select;

const teamAnalysisData = [
  { key: 1, siraNo: 1, durum: 'Rakip Ceza Sahasına Giriş', plus: 6, total: 19, oran: '32%' },
  { key: 2, siraNo: 2, durum: 'Girdiğimiz Gol Pozisyonu', plus: '-', total: '-', oran: '-' },
  { key: 3, siraNo: 3, durum: 'Rakibin Ceza Sahamıza Girişi', plus: 15, total: 15, oran: '100%' },
];

const playerListData = [
  { key: 1, no: 1, name: 'Ergin Altun', position: 1, status: 'İlk Kadro' },
  { key: 2, no: 2, name: 'Ergin Altun', position: 1, status: 'İlk Kadro' },
  { key: 3, no: 3, name: 'Ergin Altun', position: 1, status: 'İlk Kadro' },
  { key: 4, no: 4, name: 'Ergin Altun', position: 1, status: 'İlk Kadro' },
];

const matchActionsData = [
  { key: 1, time: 13, type: '1', goal: '1-0', assist: '-', player: 'Ahmet Halim', card: '-' },
  { key: 2, time: 25, type: '-', goal: '-', assist: '-', player: 'Ahmet Halim', card: 'SARI' },
];

const bestPlayersData = [
  { key: 1, best: 'Ahmet Ali', bestRating: '10', worst: 'Ahmet Ali', worstRating: '4.3' },
  { key: 2, best: 'Ahmet Ali', bestRating: '9.3', worst: 'Ahmet Ali', worstRating: '4' },
  { key: 3, best: 'Ahmet Ali', bestRating: '9', worst: 'Ahmet Ali', worstRating: '3.1' },
];

const matchNotesData = [
  'Son haftalarda düşüş yaşayan x bu maçta olağan üstü bir performans sergiledi.',
  'Peppers parmesan banana thin party. Sautéed wing steak buffalo mouth bbq rib bacon. Tomato meatball string ham tossed sausage chicken and. Green stuffed cheese sausage ipsum. Steak meat beef pepperoni peppers pan tomatoes.',
  'Mayo NY ricotta lovers meatball red crust meatball rib pineapple. Extra personal pepperoni olives fresh banana tomatoes large. Rib pizza cheese meatball pizza bacon. Bacon steak extra banana sausage mayo Hawaiian red anchovies. Bianca Hawaiian dolor sausage burnt pesto personal marinara.',
  'Platter ranch tossed mayo hand. String hand pie roll string broccoli banana extra. Garlic parmesan Bianca party string mozzarella.',
];

const columns = [
  { title: 'Sıra No', dataIndex: 'siraNo', key: 'siraNo' },
  { title: 'Durum', dataIndex: 'durum', key: 'durum' },
  { title: '+', dataIndex: 'plus', key: 'plus' },
  { title: 'Toplam', dataIndex: 'total', key: 'total' },
  { title: 'Oran', dataIndex: 'oran', key: 'oran', render: text => <span className={text.includes('%') ? 'text-red-500' : ''}>{text}</span> },
];

const playerColumns = [
  { title: 'No', dataIndex: 'no', key: 'no' },
  { title: 'Adı Soyadı', dataIndex: 'name', key: 'name' },
  { title: 'Pozisyon', dataIndex: 'position', key: 'position' },
  { title: 'Maç Durumu', dataIndex: 'status', key: 'status' },
];

const matchActionsColumns = [
  { title: 'DK > Devre', dataIndex: 'time', key: 'time' },
  { title: 'Gol Tipi', dataIndex: 'type', key: 'type' },
  { title: 'Atılan Gol', dataIndex: 'goal', key: 'goal' },
  { title: 'Asist', dataIndex: 'assist', key: 'assist' },
  { title: 'Yenilen Gol', dataIndex: 'player', key: 'player' },
  { title: 'Sarı Kart', dataIndex: 'card', key: 'card' },
];

const bestPlayersColumns = [
  { title: 'Maçın En İyi Oyuncuları', dataIndex: 'best', key: 'best', render: (text, record) => <div className="flex items-center space-x-2"><span className="text-green-500">{record.bestRating}</span> {text}</div> },
  { title: 'Maçın En Kötü Oyuncuları', dataIndex: 'worst', key: 'worst', render: (text, record) => <div className="flex items-center space-x-2"><span className="text-red-500">{record.worstRating}</span> {text}</div> },
];

const MatchesReportCard = () => {
  return (

    <div className="p-8 bg-white rounded-lg flex flex-col zoom-none">
    <p className="pb-8 text-2xl font-semibold">Maç Detayları</p>
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      <Card title="Takım Analiz ve İstatistikler" className="shadow-lg rounded-lg">
        <Table columns={columns} dataSource={teamAnalysisData} pagination={false} />
      </Card>
      <Card title="Takım Analiz ve İstatistikler" className="shadow-lg rounded-lg">
        <Table columns={columns} dataSource={teamAnalysisData} pagination={false} />
      </Card>
      <Card title="Oyuncu Listesi" className="shadow-lg rounded-lg">
        <Select defaultValue="ALFA U18" className="w-full mb-4">
          <Option value="ALFA U18">ALFA U18</Option>
        </Select>
        <Table columns={playerColumns} dataSource={playerListData} pagination={false} />
        <div className="flex justify-between mt-4">
          <Button type="primary" danger>
            Tümünü Sil
          </Button>
          <Button type="primary">Kaydet</Button>
        </div>
      </Card>
      <Card title="Maç Aksiyon İlerleyişi" className="shadow-lg rounded-lg">
        <Table columns={matchActionsColumns} dataSource={matchActionsData} pagination={false} />
        <div className="flex justify-between mt-4">
          <Button type="primary" danger>
            Tümünü Sil
          </Button>
          <Button type="primary">Yeni Aksiyon Ekle</Button>
        </div>
      </Card>
      <Card title="Maçın En İyi ve En Kötü Oyuncuları" className="shadow-lg rounded-lg">
        <Table columns={bestPlayersColumns} dataSource={bestPlayersData} pagination={false} />
      </Card>
      <Card title="Maç Notları" className="shadow-lg rounded-lg">
        <ul className="space-y-2">
          {matchNotesData.map((note, index) => (
            <li key={index} className="flex space-x-2">
              <span>📝</span>
              <p>{note}</p>
            </li>
          ))}
        </ul>
      </Card>
    </div>
    </div>
  );
};

export default MatchesReportCard;
