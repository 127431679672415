import React, { useEffect, useState } from "react";
import { DataTable } from "../../../components/tables";
import { getRecentlyAddedTreatments } from "../../../services/axios";
import { getAllRoles, getAllUser } from "../../../services/axios";

import dayjs from "dayjs";

export const TotalPlayerCount = () => {
  //   const [allUsersRoles, setAllUsersRoles] = useState();
  //   const [roleUserName, setRoleUserName] = useState([])
  const [userData, setUserData] = useState([]);
  const [dene, setDene] = useState(null);
  const [dataTableData, setDataTableData] = useState([]);

  const getUsersAll = async () => {
    try {
      const result = await getAllUser();

       const mert = result.data.users
       const a = mert.map((item) => item.roles.map((item2) => item2.name))
        const datas = a.filter((item) => item == 'footballer').length
        const datas1 = a.filter((item) => item == 'team_coach' || item == 'team_manager' || item == 'technical_director' || item == 'goalkeeper_coach').length
        const datas2 = a.filter((item) => item == 'candidate' || item == 'super_admin' || item == 'admin' || item == 'club_worker' || item == 'executive_director' || item == 'sport_director' || item == 'doctor' || item == 'physiotherapist' || item == 'masseur' || item == 'psychologist' || item == 'administrative_responsible').length
        console.log("datas", datas)

      const dataTableData = [{
        index: 1,
        player: datas,
        coach: datas1,
        personal: datas2
      }]
      setDataTableData(dataTableData);
      //  setUserData(datas)
      // setDene(datas.length)
      // console.log("dataaaa", datas);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getUsersAll();
  }, []);
  // const fetchGetRoles = async () => {
  //     setLoading(true);
  //     try {
  //       const result = await getAllRoles();
  //       setAllUsersRoles(result.data);
  //       const checkRole = result.data.map((item) => item.name)
  //       setRoleUserName(checkRole)
  //     //   setLoading(false);
  //       return result;
  //     } catch (error) {
  //       console.log("hata", error);
  //     } finally {
  //       setDataChange(false);
  //     }
  //   };
  const columnsCount = [
    {
      title: "Oyuncu Sayısı",
      dataIndex: "player",
    },
    {
      title: "Antrenör Sayısı",
      dataIndex: "coach",
    },
    {
      title: "İdari ve Destek Personel Sayısı",
      dataIndex: "personal",
    },
  ];

  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="px-4 py-8 bg-white rounded-2xl">
        <div className="flex items-center justify-between">
          <p className="font-semibold text-red-500">Oyuncu & Personel</p>
          {/* <a href="/treatments" className="text-sm hover:underline text-primary">Tüm Tedaviler</a> */}
        </div>
        <div className="pt-4">
          <DataTable
            columns={columnsCount}
            data={dataTableData}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};
