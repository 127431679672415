import React, { useEffect, useState } from "react";
import { getCoachStatistics } from "../../../services/axios";
import { DataTable } from "../../../components/tables";
import { Input } from "antd";

const columns = [
  { title: "Koç Adı", dataIndex: "coachName" },
  { title: "E-mail", dataIndex: "coachEmail" },
  { title: "Yaş", dataIndex: "coachAge" },
  { title: "Başlama Tarihi", dataIndex: "coachWorkStartDate" },
  { title: "Koçluk Lisansı", dataIndex: "coachCoachingLicense" },
];

const Coachs = () => {
  const [coachStatisticFilterData, setCoachStatisticFilterData] = useState({
    startDate: null,
    endDate: null,
    searchTerm: null,
  });
  
  const [isDataChange, setIsDataChange] = useState(true);
  const [dataTableData, setDataTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);


  const fetchGetCoachStatistic = async () => {
    setLoading(true);
    try {
      const results = await getCoachStatistics(
        coachStatisticFilterData.startDate,
        coachStatisticFilterData.endDate,
        coachStatisticFilterData.searchTerm,
      );
    

      const resultsDataTable = results?.data.map((item) => ({
        key: item?.coach_id,
        coachName: item?.name ? item?.name :"-" ,
        coachEmail: item?.email ? item?.email :"-",
        coachAge: item?.age ?  item?.age  :"-",
        coachWorkStartDate: item?.workStartDate ? item?.workStartDate :"-",
        coachCoachingLicense: item?.coachingLicense ? item?.coachingLicense :"-",
      }));
 
      setDataTableData(resultsDataTable);
      setLoading(false);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  useEffect(() => {
    setIsDataChange(true);
  }, [coachStatisticFilterData]);

  useEffect(() => {
    isDataChange && fetchGetCoachStatistic();
  }, [isDataChange, coachStatisticFilterData]);

  let timer;
  useEffect(() => {
    timer = setTimeout(() => {
      setCoachStatisticFilterData((prevState) => ({
        ...prevState,
        searchTerm: searchTerm,
      }));
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchTerm]);

  return (
    <>
      <div className="w-full p-4 bg-white rounded-md shadow">
        <div className="flex items-center justify-between text-gray-500">
        </div>
        <div className="flex flex-wrap-reverse items-center justify-between px-2 py-2 my-4 bg-gray-100 rounded-lg">
          <div className="flex items-center w-full sm:w-auto gap-3">
            <div className="">
              <span className="hidden w-full text-sm text-gray-500 sm:block">
                Tarih Aralığı:
              </span>
              <div className="flex items-center w-full sm:w-auto">
                <Input
                  type="date"
                  onChange={(e) => {
                    setCoachStatisticFilterData((prevState) => ({
                      ...prevState,
                      startDate: e.target.value,
                    }));
                  }}
                />
                <span className="mx-2">-</span>
                <Input
                  type="date"
                  onChange={(e) => {
                    setCoachStatisticFilterData((prevState) => ({
                      ...prevState,
                      endDate: e.target.value,
                    }));
                  }}
                />
 
              </div>
            </div>
          </div>
          <div className="flex items-center w-full sm:w-auto">
            <Input
              type="text"
              placeholder="Arama"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <DataTable columns={columns} data={dataTableData} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default Coachs;
