import React from "react";
import { Link } from "react-router-dom";

const Card = ({
  href,
  title,
  titleColor = "text-orange-400",
  icon,
  iconBgColor,
  subTitle,
}) => {
  return (
    <Link
      to={href}
      className="relative p-5 w-full h-16 overflow-hidden shadow-lg bg-gray-50"
    >
      <div
        className={`absolute flex items-center w-32 h-32 -mt-12 -ml-14 ${iconBgColor} rounded-full`}
      ></div>
      <div className="relative z-10 h-full flex items-center justify-between px-2">
        <div className="text-white flex items-center justify-center text-3xl">
          {icon}
        </div>
        <div className="flex flex-col items-end justify-center">
          <h5 className={`font-bold ${titleColor} text-xl`}>{title}</h5>
          <span className="text-sm">{subTitle}</span>
        </div>
      </div>
    </Link>
  );
};

export default Card;
