import React, { useEffect, useState } from "react";
import { Form, Input, Select, DatePicker, TimePicker, Button, Row, Col, Divider } from "antd";
import moment from "moment";
import "moment/locale/tr";
import { SettingOutlined } from "@ant-design/icons";
import { getAllClub, getLeagues, getSeasons, getSelects, listTeams } from "../../services/axios";
moment.locale("tr");

const { Option } = Select;

function MatchesReportForm({ updateData }) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [responseDataItem, setResponseDataItem] = useState(null);
  const [leagueOptions, setLeagueOptions] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [seaons, setAllActiveSeasonOptionData] = useState(null);
  const [teamslist, setListTeamsResultData] = useState([]);  
  
  async function fetchGetSelects() {
    setLoading(true);
    try {
      const result = await getSelects();
      setResponseDataItem(result.response);
      setLoading(false);
    } catch (error) {
      console.error("Hata oluştu:", error);
      setLoading(false);
    }
  }

  async function fetchGetTeams() {
    setLoading(true);
    try {
      const result = await getAllClub();
      setClubs(result?.response.clubs);
      setLoading(false);
    } catch (error) {
      console.error("Hata oluştu:", error);
      setLoading(false);
    }
  }

  const fetchLeagues = async () => {
    const result = await getLeagues();
    // console.log("rez", result.data.data.map((item) => item))
    const leagueOptions = result.data.data;
    setLeagueOptions(
      leagueOptions.map((item, index) => ({
        label: item?.name,
        value: item?.id,
      }))
    );
  };

  const fetchGetSeasons = async (isActive = true) => {
    setLoading(true);
    try {
      const result = await getSeasons();

      setAllActiveSeasonOptionData(
        result?.data.seasons
          .filter((filterItem, filterIndex) => filterItem.is_active == isActive)
          .map((item, index) => ({
            label: item?.season_title,
            value: item?.season_code,
          }))
      );

      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
    }
  };


  
  const fetchListTeams = async () => {
    setLoading(true);
    try {
      const result = await listTeams();
      setListTeamsResultData(result.data.teams);
      setLoading(false);
    } catch (error) {
      console.log("hata", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetSelects();
    fetchGetTeams();
    fetchLeagues()
    fetchListTeams();
    fetchGetSeasons();
    form.setFieldsValue({
      matchDate: updateData?.date ? moment(updateData.date) : null,
      matchTime: updateData?.time ? moment(updateData.time, "HH:mm:ss") : null,
      matchType: updateData?.type,
      league: updateData?.league,
      week: updateData?.week,
      season: updateData?.season?.season_code,
      difficulty: updateData?.difficulty,
      homeTeam: updateData?.home_team?.club_name,
      awayTeam: updateData?.guest_team?.club_name,
      homeTeamCategory: updateData?.small_team,
      guestTeamCategory: updateData?.guest_team_category_id,
      facilityName: updateData?.football_ground_id,
      fieldName: updateData?.field,
    });
  }, [updateData])

  console.log(teamslist,'eqwewq')

  return (
    <div className="p-8 bg-white rounded-lg flex flex-col zoom-none">
      <p className="pb-8 text-2xl font-semibold">Maç Detayları</p>
      <Form form={form} layout="vertical" >
        <Row gutter={24}>
          <Col span={12}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={<span className="text-red-500">Maç Tarihi</span>}
                  name="matchDate"
                >
                  <DatePicker
                    className="w-full"
                    format="DD.MM.YYYY"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span className="text-red-500">Saat</span>}
                  name="matchTime"
                >
                  <TimePicker
                    className="w-full"
                    format="HH:mm"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span className="text-red-500">Maç Türü</span>}
              name="matchType"
            >
              <Select className="w-full">
                {responseDataItem?.find(item => item?.id == 1)?.options.map(option => (
                  <Option key={option?.id} value={option?.value}>{option?.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span className="text-red-500">Lig</span>}
              name="league"
            >
              <Select className="w-full" placeholder="Seçiniz...">
                {leagueOptions?.find(item => item?.id == 1)?.options.map(option => (
                  <Option key={option?.id} value={option?.value}>{option?.label}</Option>
                ))}

              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span className="text-red-500">Hafta</span>}
              name="week"
            >
            <Select className="w-full" placeholder="Seçiniz...">
                {responseDataItem?.find(item => item?.id == 41)?.options.map(option => (
                  <Option key={option?.id} value={option?.id}>{option?.label}</Option>
                ))}

              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span className="text-red-500">Sezon</span>}
              name="season"
            >
              <Select className="w-full" placeholder="Seçiniz...">
              {seaons?.map(option => (
                  <Option key={option?.id} value={option?.value}>{option?.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span className="text-red-500">Zorluk</span>}
              name="difficulty"
            >
              <Select className="w-full" placeholder="Seçiniz...">
                {responseDataItem?.find(item => item?.id == 2)?.options.map(option => (
                  <Option key={option?.id} value={option?.value}>{option?.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={<span className="text-red-500">Ev Sahibi Kulüp</span>}
              name="homeTeam"
            >
              <Select className="w-full" placeholder="Seçiniz...">
              {clubs?.map(option => (
                  <Option key={option?.id} value={option?.id}>{option?.club_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span className="text-red-500">Misafir Kulüp</span>}
              name="awayTeam"
            >
              <Select className="w-full" placeholder="Seçiniz...">
              {clubs?.map(option => (
                  <Option key={option?.id} value={option?.id}>{option?.club_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span className="text-red-500">Altınordu Takımı</span>}
              name="homeTeamCategory"
            >
              <Select className="w-full" placeholder="Seçiniz...">
              {teamslist.map(option => (
                    <Option key={option?.id} value={option?.id}>{option?.team_title}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span className="text-red-500">Rakip Takım Kategorisi</span>}
              name="guestTeamCategory"
            >
              <Select className="w-full" placeholder="Seçiniz...">
              {responseDataItem?.find(item => item?.id == 21)?.options.map(option => (
                    <Option key={option?.id} value={option?.id}>{option?.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        {/* <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={<span className="text-red-500">Tesis Adı</span>}
              name="facilityName"
            >
       <Select className="w-full" placeholder="Seçiniz...">
              {responseDataItem?.find(item => item?.id == 22)?.options.map(option => (
                    <Option key={option?.id} value={option?.id}>{option?.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span className="text-red-500">Saha Adı</span>}
              name="fieldName"
            >
              <Input placeholder="Seçiniz..." />
            </Form.Item>
          </Col>
        </Row> */}
        <Button type="primary" htmlType="submit">
          Kaydet
        </Button>

      </Form>
    </div>
  );
}

export default MatchesReportForm;
