import React, {useEffect, useState} from "react";
import {getLeagues, getStandings} from "../../../services/axios";
import {PulseLoader} from "react-spinners";
import {NoData} from "../NoData";
import DynamicInput from "../../../components/inputs/dynamicInput";

export default function ScoreTable() {
    const [leaguesLoading, setLeaguesLoading] = useState(false);
    const [standingsLoading, setStandingsLoading] = useState(true);
    const [leagueOptions, setLeagueOptions] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState([]);
    const [standingParams, setStandingParams] = useState([]);

    const fetchGetLeagues = async () => {
        setLeaguesLoading(true);
        try {
            const result = await getLeagues(1);
            setLeagueOptions([]);
            setLeagueOptions((prevState) => [
                ...prevState,
                ...result?.data.data.map((item) => ({
                    label: item?.name,
                    value: item?.id
                }))
            ]);
            setSelectedLeague({
                league: {
                    label: result?.data.data[0]?.name,
                    value: result?.data.data[0]?.id
                }
            });
            return true;
        } catch (error) {
            console.log("hata", error);
        } finally {
            setLeaguesLoading(false);
        }
    };

    const fetchGetStandings = async () => {
        try {
            setStandingsLoading(true);
            const results = await getStandings(selectedLeague?.league?.value);
            let standings = results.response.data.map((item, index) => ({
                goalConceded: item.goalConceded,
                goalScored: item.goalScored,
                draw: item.draw,
                defeat: item.defeat,
                win: item.win,
                matchPlayed: item.matchPlayed,
                key: index + 1,
                average: item.average,
                point: item.point,
                club_name: item.club.club_name
            }));
            setStandingParams(standings);
        } catch (error) {
            console.log("hata", error);
        } finally {
            setStandingsLoading(false);
        }
    };

    useEffect(() => {
        fetchGetLeagues();
    }, []);

    useEffect(() => {
        if (selectedLeague) {
            if (Object.keys(selectedLeague).length > 0) {
                fetchGetStandings();
            }
        }
    }, [selectedLeague]);

    return (
        <>
            <div className="">
                <div
                    className="p-5 pt-8 pb-12 bg-white border border-t-4 border-gray-100 shadow-xl rounded-xl border-t-primary focus:outline-none lg:h-[500px]">
                    <div className="flex justify-between items-center mb-7">
                        <div className="text-base font-semibold text-gray-400">Puan Durumu</div>
                        <div>
                            <DynamicInput
                                containerStyle={"w-[300px]"}
                                placeholder={"Lig Seçiniz"}
                                name={"league"}
                                value={selectedLeague}
                                onChange={(e) => {
                                    setSelectedLeague({ league: e });
                                }}
                                component_type={"select"}
                                customOptions={leagueOptions}
                                disabled={leaguesLoading}
                                minHeight={"40px"}
                            />
                        </div>
                        {/*<a*/}
                        {/*  href="/scoreboard"*/}
                        {/*  className="text-sm transition duration-700 ease-out text-primary hover:border-b hover:border-b-primary hover:ease-in"*/}
                        {/*>*/}
                        {/*  📅 Puan Tablosu*/}
                        {/*</a>*/}
                    </div>
                    {standingsLoading ? (
                        <div className="flex justify-center items-center h-36">
                            <PulseLoader color="red"/>
                            <p>Yükleniyor...</p>
                        </div>
                    ) : (
                        <>
                            {standingParams.length > 0 ? (
                                <>
                                    <div className="flex justify-between mt-5">
                                        <div>
                                            <h5 className="mb-5 text-center">#</h5>
                                            <ul>
                                                {standingParams.map((item, index) => (
                                                    <li key={index} className="my-2.5">
                                                        {index + 1}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="w-6/12">
                                            <h5 className="mb-5">Takım</h5>
                                            <ul>
                                                {standingParams.map((item, index) => (
                                                    <li key={index} className="my-2.5 flex items-center">
                                                        <img
                                                            className="w-6"
                                                            src="https://images-ext-2.discordapp.net/external/G2l4Cy2V-EKu7IkstrI_SxPRJ2fVbfPpdicKVbd7jDM/https/kgo.googleusercontent.com/profile_vrt_raw_bytes_1587515401_10891.jpg"
                                                            alt=""
                                                        />
                                                        {item.club_name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div>
                                            <h5 className="mb-5 text-center">O</h5>
                                            <ul>
                                                {standingParams.map((item, index) => (
                                                    <li key={index} className="my-2.5">
                                                        {item.matchPlayed}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div>
                                            <h5 className="mb-5 text-center">G</h5>
                                            <ul>
                                                {standingParams.map((item, index) => (
                                                    <li key={index} className="my-2.5">
                                                        {item.win}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div>
                                            <h5 className="mb-5 text-center">B</h5>
                                            <ul>
                                                {standingParams.map((item, index) => (
                                                    <li key={index} className="my-2.5">
                                                        {item.draw}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div>
                                            <h5 className="mb-5 text-center">M</h5>
                                            <ul>
                                                {standingParams.map((item, index) => (
                                                    <li key={index} className="my-2.5 text-center">
                                                        {item.defeat}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div>
                                            <h5 className="mb-5 text-center">A</h5>
                                            <ul>
                                                {standingParams.map((item, index) => (
                                                    <li key={index} className="my-2.5 text-center">
                                                        {item.average}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div>
                                            <h5 className="mb-5 text-center">YG</h5>
                                            <ul>
                                                {standingParams.map((item, index) => (
                                                    <li key={index} className="my-2.5 text-center">
                                                        {item.goalConceded}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div>
                                            <h5 className="mb-5 text-center">AG</h5>
                                            <ul>
                                                {standingParams.map((item, index) => (
                                                    <li key={index} className="my-2.5 text-center">
                                                        {item.goalScored}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div>
                                            <h5 className="mb-5 text-center">P</h5>
                                            <ul>
                                                {standingParams.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        className="my-2.5 text-center font-bold"
                                                    >
                                                        {item.point}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <NoData/>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
