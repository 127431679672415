import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  teams: [],
  filteredTeams: [],
  loading: false,
  error: {
    code: null,
    message: null,
  },
};

export const GetTeams = createAsyncThunk(
  "teams/GetTeams",
  async (arg, thunkAPI) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let teamsData = [];
        for (let i = 1; i <= 25; i++) {
          teamsData.push({
            key: i,
            teamsCode: "1",
            teamsName: "Altınordu FK",
            teamsCategory: "U16",
          });
        }
        resolve(teamsData);
      }, 750);
    });
  }
);

export const TeamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    FilterTeams: (state, action) => {
      const filterInputValue = action.payload;
      if (filterInputValue) {
        state.loading = true;
        const filteredData = state.teams.filter(
          (item) =>
            item.teamsCode.toLowerCase().includes(filterInputValue.toLowerCase()) ||
            item.teamsName.toLowerCase().includes(filterInputValue.toLowerCase()) ||
            item.teamsCategory.toLowerCase().includes(filterInputValue.toLowerCase())
        );
        state.filteredTeams = filteredData;
        state.loading = false;
      } else {
        state.filteredTeams = [];
      }
      //filter işlemleri
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetTeams.pending, (state, action) => {
      state.loading = true;
      state.message = null;
      state.error = {
        code: null,
        message: null,
      };
    });
    builder.addCase(GetTeams.fulfilled, (state, action) => {
      state.teams = action.payload;
      state.loading = false;
    });
    builder.addCase(GetTeams.rejected, (state, action) => {
      state.loading = false;
      state.message = "Takımları Getirme Reddedildi!!";
      state.error = {
        code: 500,
        message: "Get Teams Rejected",
      };
    });
  },
});

export const { FilterTeams } = TeamsSlice.actions;

export default TeamsSlice.reducer;
