import React, { useEffect, useState } from "react";
import { DataTable } from "../../../components/tables";
import { getRecentlyAddedTreatments } from "../../../services/axios";
import dayjs from "dayjs";

export const TreatmentEnded = () => {
  const columnsTreatments = [
    {
      title: "Adı Soyadı",
      dataIndex: "treatmentsNameAndSurname"
    },
    {
      title: "Tarih",
      dataIndex: "treatmentsDate"
    }
  ];

  const [treatments, setTreatments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchGetRecentlyAddedTreatments = async () => {
    setLoading(true);
    try {
      const results = await getRecentlyAddedTreatments(10);
      let recentlyTreatments = results.response.data.map((item) => ({
        treatmentsNameAndSurname: item?.player?.user_name 
          ? item.player.user_name
          : "-",
        treatmentsDate: (dayjs(item.treatments_start_date).format("DD MMMM YYYY")) ? dayjs(item.treatments_start_date).format("DD MMMM YYYY") : "-"
        
      }));
      setTreatments(recentlyTreatments);
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetRecentlyAddedTreatments();
  }, []);

  return (
    <>
      <div className="px-4 py-8 bg-white rounded-2xl">
        <div className="flex items-center justify-between">
          <p className="font-semibold text-blue-500">Tedavisi Sonlanıp Takıma Katılanlar</p>
          <a href="/treatments" className="text-sm hover:underline text-primary">Tüm Tedaviler</a>
        </div>
        <div className="pt-4">
          <DataTable
            columns={columnsTreatments}
            data={treatments}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};
