import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import MenuBox from "./MenuBox";
import ToggleSidebar from "./ToggleSidebar";

import { HeaderMenuList } from "../../../utils";
import { Paths } from "../../../routes";
import { SearchBox, ToolBox } from "../../index";
import { logo } from "../../../utils/filePath";

const Header = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <>
      <header className="sticky top-0 left-0 z-[99] w-full select-none sm:relative">
        <div className="relative hidden w-full h-20 px-16 bg-white lg:flex">
          <div className="flex items-center justify-end w-full space-x-2">
            <div className="mr-8">
              <SearchBox />
            </div>
            <ToolBox />
          </div>
        </div>
        <div className="flex items-center w-full h-20 bg-primary lg:h-16">
          <div className="relative flex items-center justify-between w-full px-4 mx-auto ">
            {/* max-w-[1840px] */}
            <div>
              <Link
                to={Paths.Dashboard}
                className="flex items-center lg:absolute lg:-mt-16"
              >
                <div className="flex items-center justify-center lg:w-72">
                  <img
                    src={logo}
                    className="w-14 lg:w-[115px]"
                    alt="AltınOrdu"
                  />
                </div>
              </Link>
            </div>
            <div className="h-full xl:pr-36">
              <MenuBox menuList={HeaderMenuList} />
              <ToggleSidebar />
            </div>
          </div>
        </div>
      </header>

      {/*
             <div className="absolute w-full -z-10">
                <img
                    src={headerImage}
                    className="object-cover w-full h-[250px] lg:h-[400px] xl:h-[350px]"
                />
            </div>
            */}
    </>
  );
};

export default Header;
