import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAllCandidateUsers} from "../../../axios";

const initialState = {
  applicants: [],
  filteredApplicants: [],
  loading: false,
  error: {
    code: null,
    message: null,
  },
};

export const GetApplicants = createAsyncThunk('applicants/GetApplicants', async () => {
  try {
    const response = await getAllCandidateUsers()
    return response?.data?.users.map((item) => ({
      key: item?.id,
      candidateId: item?.candidate?.id,
      tckn: item?.tckn ? item?.tckn : "-",   
      user_name: item?.user_name ? item?.user_name : "-",
      status: item?.candidate ? "Düzenlendi" : "Düzenlenmedi"
    }));
  } catch (error) {
    throw error;
  }
});
export const ApplicantsSlice = createSlice({
  name: "applicants",
  initialState,
  reducers: {
    filterApplicants: (state, action) => {
      const filterInputValue = action.payload;
      //filter işlemleri
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetApplicants.pending, (state, action) => {
      state.loading = true;
      state.message = null;
      state.error = {
        code: null,
        message: null,
      };
    });
    builder.addCase(GetApplicants.fulfilled, (state, action) => {
      state.applicants = action.payload;
      state.loading = false;
    });
    builder.addCase(GetApplicants.rejected, (state, action) => {
      state.loading = false;
      state.message = "Aday Verileri Getirme Reddedildi!!";
      state.error = {
        code: 500,
        message: "Get Applicants Rejected",
      };
    });
  },
});

export const { filterApplicants } = ApplicantsSlice.actions;

export default ApplicantsSlice.reducer;