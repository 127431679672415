import React, { useEffect, useState, useRef, useContext } from "react";
import ButtonComponent from "../../../../components/buttons/ButtonComponent";
import DynamicInput from "../../../../components/inputs/dynamicInput";
import Select from "react-select";
import { Formik } from "formik";
import { handleCustomChange } from "../../../../utils/handleCustomChange";
import {
  bulkUpdateSelectedPlayers,
  createFixtureMatches
} from "../../../../services/axios";
import Swal from "sweetalert2";
import { selectedPlayerStatuses } from "../../../../components/attendance/options";

function TakeAttendanceModal({
  isOpen,
  selectedPlayers,
  activityId,
  setIsDataChange,
  setResultData
}) {
  useEffect(() => {
    console.log("BABKDABKDSAKBAKSDBKADB", selectedPlayers, "ksladklsadflklkdslksdlkdlkflksdlfkslkdflksdflkslkdfklsf")
  }, [])
  const selectedPlayerAverages = [
    { label: "Seçiniz", value: null },
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" }
  ];

  const selectedFatigueSurveyOptions = [
    { label: "Seçiniz", value: null },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
  ];
 useEffect(() => {
  console.log("seleectedPlayers", selectedPlayers)
 }, [])
  const playerIDs = [];
  const initialValues = {};
  selectedPlayers.forEach((player) => {
    console.log(player, "player.averageOption");
    initialValues[`playerId${player.key}`] = player.key;
    initialValues[`status${player.key}`] = player.status
      ? "player.statusOption"
      : { label: "Seçiniz", value: null };
    initialValues[`average${player.key}`] = player.average
      ? player.averageOption
      : { label: "Seçiniz", value: null };
    initialValues[`fatigue${player.key}`] = player?.fatigueSurvey
      ? player?.fatigueSurveyOption
      : { label: "Seçiniz", value: null };
    playerIDs.push(player.key);
  });

  const fetchUpdateActivitySelectedPlayer = async (data) => {
    try {
      const result = await bulkUpdateSelectedPlayers(data, activityId);
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Düzenleme Başarılı!",
          showConfirmButton: false,
          timer: 1000
        });
        setIsDataChange(true);
        setResultData(null);
        isOpen(false);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Düzenleme Başarısız!",
          showConfirmButton: false,
          timer: 1000
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    }
  };
  return (
    <div className={"h-[500px] overflow-auto"}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          console.log(values, "ppppppppppppppppppppp");
          values.playerIDs = playerIDs;
          fetchUpdateActivitySelectedPlayer(JSON.parse(JSON.stringify(values)));
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="pt-5">
              <div className="font-semibold text-xl">Toplu Seçim</div>
              <hr />
              <div className="grid grid-cols-3 pt-3">
                <div>
                  <div className="text-md font-semibold">Yoklama</div>
                  <div>
                    <Select
                      className="w-[300px] "
                      name={"allStatusChangeOptions"}
                      component_type={"select"}
                      options={[
                        { label: "Seçiniz", value: null },
                        ...selectedPlayerStatuses()
                      ]}
                      value={values.allStatusChangeOptions}
                      onChange={(e1, e2) => {
                        selectedPlayers.forEach((player) => {
                          setFieldValue("status" + player.key, e1);
                        });

                        handleCustomChange(
                          e1,
                          e2,
                          "allStatusChangeOptions",
                          setFieldValue,
                          "select"
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="text-md font-semibold">Puan</div>
                  <div>
                    <Select
                      className="w-[300px] "
                      name={"allAveragesChangeOptions"}
                      component_type={"select"}
                      options={selectedPlayerAverages}
                      value={values.allAveragesChangeOptions}
                      onChange={(e1, e2) => {
                        selectedPlayers.map((player) =>
                          setFieldValue("average" + player.key, e1)
                        );
                        handleCustomChange(
                          e1,
                          e2,
                          "allAveragesChangeOptions",
                          setFieldValue,
                          "select"
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="text-md font-semibold">Yorgunluk Anketi</div>
                  <div>
                    <Select
                      className="w-[300px] "
                      name={"allFatigueChangeOptions"}
                      component_type={"select"}
                      options={selectedFatigueSurveyOptions}
                      value={values.allFatigueChangeOptions}
                      onChange={(e1, e2) => {
                        selectedPlayers.map((player) =>
                          setFieldValue("fatigue" + player.key, e1)
                        );
                        handleCustomChange(
                          e1,
                          e2,
                          "allFatigueChangeOptions",
                          setFieldValue,
                          "select"
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 border-t flex flex-col justify-between">
              <div className="flex flex-col py-4">
                <table className="border-collapse border border-slate-400 ">
                  <thead>
                    {/* <tr>
                      <th colSpan="5" className="border border-slate-300"></th>
                      <th colSpan="4" className="border border-slate-300">
                        CATAPULT
                      </th>
                    </tr> */}
                    <tr>

                      <th className="border border-slate-300">Sıra No</th>
                      <th className="border border-slate-300">Ad Soyad</th>
                      {/* <th className="border border-slate-300">Soyad</th> */}
                      <th className="border border-slate-300">Pozisyon</th>
                      <th className="border border-slate-300">Yoklama</th>
                      <th className="border border-slate-300">Puan</th>
                      <th className="border border-slate-300">
                        Yorgunluk Anketi
                      </th>
                      {/* <th className="border border-slate-300">
                        Toplam Koşu Mesafesi
                      </th>
                      <th className="border border-slate-300">
                        Sprint Mesafesi
                      </th>
                      <th className="border border-slate-300">Sprint Sayısı</th>
                      <th className="border border-slate-300">
                        Yüksek Şiddetli Koşu
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {selectedPlayers.map((player, index) => (
                      <tr key={index}>
                        <td className="text-center border min-w-[100px]">
                          {index+1}
                        </td>
                        <td className="text-left pl-2 border min-w-[100px]">
                          {player.name} 
                        </td>
                        {/* <td className="text-center border min-w-[100px]">
                          {player.surname} 
                        </td> */}
                        <td className="text-center border min-w-[100px]">
                          {player.position ?? "-"}
                        </td>
                        <td className="text-center border min-w-[150px]">
                          <Select
                            options={[
                              { label: "Seçiniz", value: null },
                              ...selectedPlayerStatuses()
                            ]}
                            placeholder="Seçiniz"
                            name={`status${player.key}`}
                            value={values[`status${player.key}`]}
                            onChange={(selectedOption) => {
                              setFieldValue(
                                `status${player.key}`,
                                selectedOption.value
                              );
                              handleCustomChange(
                                selectedOption,
                                "",
                                `status${player.key}`,
                                setFieldValue,
                                "select"
                              );
                            }}
                          />
                        </td>
                        <td className="text-center border min-w-[100px]">
                          <Select
                            options={selectedPlayerAverages}
                            name={`average${player.key}`}
                            value={values[`average${player.key}`]}
                            onChange={(selectedOption) => {
                              setFieldValue(
                                `average${player.key}`,
                                selectedOption.value
                              );
                              handleCustomChange(
                                selectedOption,
                                "",
                                `average${player.key}`,
                                setFieldValue,
                                "select"
                              );
                            }}
                          />
                        </td>
                        <td className="text-center border min-w-[100px]">
                          <Select
                            placeholder="Seçiniz"
                            options={selectedFatigueSurveyOptions}
                            name={`fatigue${player.key}`}
                            value={values[`fatigue${player.key}`]}
                            onChange={(selectedOption) => {
                              setFieldValue(
                                `fatigue${player.key}`,
                                selectedOption.value
                              );
                              handleCustomChange(
                                selectedOption,
                                "",
                                `fatigue${player.key}`,
                                setFieldValue,
                                "select"
                              );
                            }}
                          />
                        </td>
                        {/* <td className="text-center border min-w-[100px]">-</td>
                        <td className="text-center border min-w-[100px]">-</td>
                        <td className="text-center border min-w-[100px]">-</td>
                        <td className="text-center border min-w-[100px]">-</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex gap-4 mt-8">
                <ButtonComponent
                  customStyle={"w-full border-0"}
                  onClick={() => {
                    isOpen(false);
                  }}
                  outline
                  text={"Vazgeç"}
                />
                <ButtonComponent
                  customStyle={"w-full"}
                  onClick={() => handleSubmit()}
                  fill
                  text={"Kaydet"}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default TakeAttendanceModal;
