import React, { useState } from "react";
import { Profile } from "../../index";
import { Files, SidebarMenuList } from "../../../utils";

import MenuBox from "./MenuBox";
import { useSelector } from "react-redux";
import { baseImageURL } from "../../../services/axios";
import { logo } from "../../../utils/filePath";
import { RiMenuFoldFill } from "react-icons/ri";

const Aside = () => {
  const { authBackground } = Files;
  const authUser = useSelector((state) => state.authUser.user);

  const [menuMini, setMenuMini] = useState(false);

  return (
    <aside
      className={`relative z-[999] flex mb-16 bg-white rounded-lg shadow transition-all duration-700 ease-in-out ${
        menuMini ? "w-[76px]" : "w-72"
      }`}
    >
      <div
        onClick={() => setMenuMini((prev) => !prev)}
        className="absolute cursor-pointer right-6 top-4"
      >
        <RiMenuFoldFill className="text-3xl text-primary" />
      </div>
      <div className="flex flex-col items-center w-full lg:-mt-16">
        <div className="hidden my-5 lg:flex">
          {!menuMini ? (
            <Profile
              image={
                authUser?.imageurl ? baseImageURL + authUser?.imageurl : logo
              }
              name={authUser?.user_name}
              job={authUser?.tckn}
            />
          ) : (
            <div className="h-[200px]"></div>
          )}
        </div>
        <div className="w-full">
          <MenuBox menuList={SidebarMenuList} menuMini={menuMini} />
        </div>
      </div>
    </aside>
  );
};

export default Aside;
