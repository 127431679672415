import React from "react";
import { MdOutlinedFlag } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { IoMdSettings } from "react-icons/io";

function CustomModalHeader({ title, description, icon }) {
  function DynamicIcon() {
    if (icon == 'MdOutlinedFlag') {
      return <MdOutlinedFlag className="w-full h-full" />
    }
    else if (icon == 'RiFileExcel2Fill') {
      return <RiFileExcel2Fill className="w-full h-full" />
    } else if (icon == "IoMdSettings") {
      return <IoMdSettings className="w-full h-full" />
    }
  }

  return (
    <div>
      <div className="flex items-center gap-4">
        <div className="h-9 w-9">
          <DynamicIcon />
        </div>
        <div className="flex flex-col">
          <p className="text-xl font-medium -ml-14">{title}</p>
          <p className="text-sm text-[#919191] font-medium">{description}</p>
        </div>
      </div>
    </div>
  );
}

export default CustomModalHeader;
