import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Paths } from "../../../routes";
import { BsChevronLeft, BsFillPersonCheckFill } from "react-icons/bs";
import { getCandidatePlayer } from "../../../services/axios";
import EditApplicantForm from "./form";
import { PulseLoader } from "react-spinners";

const EditApplicants = () => {
  const [setBreadcrumbData] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [isDataChange, setIsDataChange] = useState(false);

  const { id } = useParams();

  const fetchGetCandidate = async () => {
    setLoading(true);
    try {
      const res = await getCandidatePlayer(id);
      setCandidateData(res?.data?.data);
    } catch (error) {
      console.log(error, "error!!");
    } finally {
      setLoading(false);
      setIsDataChange(false);
    }
  };

  useEffect(() => {
    setBreadcrumbData([
      {
        title: "Panel"
      },
      {
        title: <Link to={Paths.Applicants.Applicants}>Adaylar</Link>
      },
      {
        title: "Aday Güncelle"
      }
    ]);
  }, [id]);

  useEffect(() => {
      fetchGetCandidate();
  }, []);

  useEffect(() => {
    if (isDataChange) {
      fetchGetCandidate();
    }
  }, [isDataChange]);

  const navigate = useNavigate();
  return (
    <>
      {loading ? (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      ) : (
        <div className="p-4 pb-10 bg-white rounded-md shadow-xl">
          <div className="flex items-center text-gray-500">
            {/*<button onClick={() => navigate(-1)}>*/}
            {/*    <BsChevronLeft size={21}/>*/}
            {/*</button>*/}

            <Link to="/applicants">
              <BsChevronLeft size={21} />
            </Link>
            <h4 className="ml-4 text-lg font-semibold">Aday Güncelle</h4>
          </div>
          <div className="w-full text-primary">
            <EditApplicantForm
              userId={id}
              candidateData={candidateData}
              setIsDataChange={setIsDataChange}
              id={id}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EditApplicants;
