import React from "react";

function PageTitle({icon, title, bg}) {
  return (
    <div className={`flex items-center w-full gap-2 px-4 py-2 my-4 text-2xl rounded-lg ${bg ? "bg-primary text-white" : "bg-white text-black"} `}>
      <div className="">{icon}</div>
      <p>{title}</p>
    </div>
  );
}

export default PageTitle;
