import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  matches: [],
  filteredMatches: [],
  loading: false,
  error: {
    code: null,
    message: null,
  },
};

export const GetMatches = createAsyncThunk(
  "matches/GetMatches",
  async (arg, thunkAPI) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let matchesData = [];
        for (let i = 1; i <= 25; i++) {
          matchesData.push({
            key: i,
            matchesType: "B",
            matchesSeason: "2022-2023",
            matchesLeague: "",
            matchesTeam: "ALFA U18/19",
            matchesWeek: "",
            matchesDate: "19/08/2022",
            matchesHomeTeam: "Altınordu",
            matchesVisitingTeam: "Göztepe",
          });
        }
        resolve(matchesData);
      }, 750);
    });
  }
);

export const MatchesSlice = createSlice({
  name: "matches",
  initialState,
  reducers: {
    filterMatches: (state, action) => {
      const filterInputValue = action.payload;
      //filter işlemleri
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetMatches.pending, (state, action) => {
      state.loading = true;
      state.message = null;
      state.error = {
        code: null,
        message: null,
      };
    });
    builder.addCase(GetMatches.fulfilled, (state, action) => {
      state.matches = action.payload;
      state.loading = false;
    });
    builder.addCase(GetMatches.rejected, (state, action) => {
      state.loading = false;
      state.message = "Maç Verileri Getirme Reddedildi!!";
      state.error = {
        code: 500,
        message: "Get Matches Rejected",
      };
    });
  },
});

export const { filterMatches } = MatchesSlice.actions;

export default MatchesSlice.reducer;
