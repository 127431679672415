import React, { useEffect } from "react";
import { PulseLoader } from "react-spinners";

function NextButton({ length, setSelectedTabId, selectedTabId, loading }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [selectedTabId]);
  return (
    <button
      onClick={() => {
        selectedTabId == length - 1
          ? setSelectedTabId(selectedTabId - 1)
          : setSelectedTabId(selectedTabId + 1);
      }}
      className="flex items-center justify-center w-full h-12 px-6 font-medium transition-all bg-white border rounded-md text-primary text-md hover:bg-primary/10 border-primary"
      disabled={loading}
    >
      {loading ? (
        <PulseLoader color="white" />
      ) : selectedTabId == length - 1 ? (
        "Geri"
      ) : (
        "İleri"
      )}
    </button>
  );
}

export default NextButton;
