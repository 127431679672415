export const selectedPlayerStatuses = (isAll = false) => {
    let options = [
        {label: "Geldi", value: "geldi"},
        {label: "İzinli", value: "izinli"},
        {label: "Sakat", value: "sakat"},
        {label: "Hasta", value: "hasta"},
        {label: "BT Yukarı Gitti", value: "bt_yukari_gitti"},
        {label: "BT Aşağı Gitti", value: "bt_asagi_gitti"},
        {label: "BT Yukarıdan Geldi", value: "bt_yukaridan_geldi"},
        {label: "BT Aşağıdan Geldi", value: "bt_asagidan_geldi"},
        {label: "Yok", value: "yok"},
        {label: "Milli Takımda", value: "milli_takimda"},
        {label: "Kart Cezalısı", value: "kart_cezalisi"},
        {label: "Takım Dinlenmesi", value: "takim_dinlenmesi"},
        {label: "Tedavi", value: "tedavi"},
        {label: "Sahaya Dönüş", value: "sahaya_donus"},
        {label: "Özel Antrenmanda", value: "ozel_antrenmanda"},
        {label: "Aday Oyuncu", value: "aday_oyuncu"}
    ]

    if(isAll){
        options.unshift({label: "Tümü", value: null});
    }
    return options;
};