import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components/tables";
import { getAllTreatments } from "../../../services/axios";

const TreatmentCompletedPlayers = () => {
  const columnsTreatment = [
    {
      title: "Adı Soyadı",
      dataIndex: "completeTreatmentName"
    }
  ];
  const [allTreatments, setAllTreatments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchGetAllCompletedTreatments = async () => {
    try {
      setLoading(true);
      const results = await getAllTreatments();
      console.log("reasd", results);
      let completedTreatments = results.data.treatments.map((item) => ({
        completeTreatmentName: item.treatment_player.user_name ? item.treatment_player.user_name : "-"
      }))
      // let recentlyCandidates = results.response.data.map((item) => ({
      //   candidateName: item.user_name ? item.user_name : "-"
      // }));
      setAllTreatments(completedTreatments);
      console.log("resp1", results)
      console.log("resp2", completedTreatments)
      console.log("resp3", allTreatments)
    } catch (error) {
      console.log("hata", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
     fetchGetAllCompletedTreatments();
  }, []);

  return (
    <>
      <div className="px-4 py-8 bg-white rounded-2xl">
        <div className="flex items-center justify-between">
          <p className="font-semibold text-green-400">Tedavisi Tamamlanananlar</p>
          <a href="/treatments" className="text-sm hover:underline text-primary">Tüm Adaylar</a>
        </div>
        <div className="pt-4">
          <DataTable
            columns={columnsTreatment}
            data={allTreatments}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default TreatmentCompletedPlayers;
