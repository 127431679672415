import React, { useState } from "react";
import CustomModal from "../../components/modals";
import CustomModalHeader from "../../components/modals/CustomModalHeader";
import { Formik } from "formik";
import DynamicInput from "../../components/inputs/dynamicInput";
import { createUnit, deleteUnit, updateUnit } from "../../services/axios";
import ButtonComponent from "../../components/buttons/ButtonComponent";
import Swal from "sweetalert2";
import { handleCustomChange } from "../../utils/handleCustomChange";
import { PulseLoader } from "react-spinners";

export function CreateUnitForm({
  isOpen,
  setOpen,
  setIsDataChange,
  isDataChange,
  updateSelectedValuesProp, //seçilen takımın data'sı.
}) {
  const [loading, setLoading] = useState(false);
  const [updateUnitTypeSelectValue, setUpdateUnitTypeSelectValue] =
    useState(null);
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [updatedShort_title, setUpdatedShort_title] = useState("");
  const [updatedType, setUpdatedType] = useState("");
  const [updateUnitCategorySelectValue, setUpdateUnitCategorySelectValue] =
    useState(null);

  const fetchUpdateUnit = async (id, title, short_title, type, referance) => {
    setLoading(true);
    try {
      const result = await updateUnit(id, title, short_title, type, referance);
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Güncelleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });

        //Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Güncelleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      // console.log(result, "cioomm");
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };
  const fetchCreateUnit = async (title, short_title, type, referance) => {
    setLoading(true);
    try {
      const result = await createUnit(title, short_title, type, referance);
      // console.log("objeresultresultresultresultct: ", result);
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Ekleme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });

        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Ekleme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log(error, "hata");
    } finally {
      setLoading(false);
      setIsDataChange(true);
    }
  };
  const onDeleteHandler = async (id) => {
    setLoading(true);
    try {
      const result = await deleteUnit(id);
      if (result.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result?.message ?? "Silme Başarılı!",
          showConfirmButton: false,
          timer: 1000,
        });

        //  Giriş başarılıysa, yapılacak işlemler veya yönlendirme buraya gelebilir
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result?.message ?? "Silme Başarısız!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      return result;
    } catch (error) {
      console.log("hata", error);
    } finally {
      setIsDataChange(true);
      setLoading(false);
    }
  };

  const handleDeleteUnit = async (updateSelectedValuesProp) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: `"${updateSelectedValuesProp.title}" birimi silmek istediğinizden emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteHandler(updateSelectedValuesProp?.id);
      }
    });
  };

  return (
    <div className="py-4">
      {updateSelectedValuesProp && loading && (
        <div className="flex">
          <PulseLoader color="red" />
          <p>Yükleniyor...</p>
        </div>
      )}
      {updateSelectedValuesProp && (
        <Formik
          initialValues={{
            title: updateSelectedValuesProp?.title ?? "",
            short_title: updateSelectedValuesProp?.short_title ?? "",
            type: updateSelectedValuesProp?.type ?? "",
          }}
          onSubmit={async (values, { resetForm }) => {
            // console.log(values, "values");
            values.type = values?.type?.value;
            try {
              await fetchUpdateUnit(
                updateSelectedValuesProp?.id,
                values?.title,
                values?.short_title,
                values?.type,
                values?.referance
              );
            } catch (error) {
              console.log(error, "hata");
            }
          }}
        >
          {({
            errors,
            values,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col">
                <DynamicInput
                  labelTitle={"Birim"}
                  containerStyle={"w-full"}
                  placeholder={updateSelectedValuesProp?.title ?? "Birim"}
                  name={"title"}
                  value={values}
                  onChange={(e1, e2) => {
                    handleCustomChange(e1, e2, "title", setFieldValue, "input");
                  }}
                  error={errors}
                  isTouched={touched}
                  onBlur={handleBlur}
                />
                <DynamicInput
                  labelTitle={"Birim kısaltması"}
                  containerStyle={"w-full"}
                  placeholder={
                    updateSelectedValuesProp?.short_title ?? "Birim kısaltması"
                  }
                  name={"short_title"}
                  value={values}
                  onChange={(e1, e2) => {
                    handleCustomChange(
                      e1,
                      e2,
                      "short_title",
                      setFieldValue,
                      "input"
                    );
                  }}
                  error={errors}
                  isTouched={touched}
                  onBlur={handleBlur}
                  component_type={"input"}
                />
                <DynamicInput
                  labelTitle={"Birim Tipi"}
                  containerStyle={"w-full"}
                  placeholder={updateSelectedValuesProp?.type ?? "Birim Tipi"}
                  name={"type"}
                  value={values}
                  onChange={(e1, e2) => {
                    handleCustomChange(e1, e2, "type", setFieldValue, "select");
                  }}
                  error={errors}
                  isTouched={touched}
                  onBlur={handleBlur}
                  component_type={"select"}
                  customOptions={[
                    { label: "Rakam", value: "rakam" },
                    { label: "Tek Karakter", value: "tek_karakter" },
                    { label: "Karakter", value: "karakter" },
                    { label: "Liste", value: "liste" },
                  ]}
                />
              </div>

              <div className="flex w-full pt-8 pb-1">
                <ButtonComponent
                  onClick={() => handleDeleteUnit(updateSelectedValuesProp)}
                  text={"Birimi Sil"}
                  outline
                  customStyle={"w-fit h-[56px]"}
                />
              </div>

              <div className="flex items-center justify-end gap-4">
                <ButtonComponent submit fill text={"Kaydet"} />
              </div>
            </form>
          )}
        </Formik>
      )}
      {!updateSelectedValuesProp && (
        <>
          <Formik
            initialValues={{
              title: "",
              short_title: "",
              type: "",
            }}
            onSubmit={async (values, { resetForm }) => {
              // console.log(values, "values");
              values.type = values?.type?.value;
              try {
                await fetchCreateUnit(
                  values?.title,
                  values?.short_title,
                  values?.type,
                  values?.referance
                );
              } catch (error) {
                console.log(error, "hata");
              }
              setOpen(false);
            }}
          >
            {({
              errors,
              values,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col">
                  <DynamicInput
                    labelTitle={"Birim"}
                    containerStyle={"w-full"}
                    placeholder={"Birim"}
                    name={"title"}
                    value={values}
                    onChange={(e1, e2) => {
                      handleCustomChange(
                        e1,
                        e2,
                        "title",
                        setFieldValue,
                        "input"
                      );
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                  />
                  <DynamicInput
                    labelTitle={"Birim kısaltması"}
                    containerStyle={"w-full"}
                    placeholder={"Birim kısaltması"}
                    name={"short_title"}
                    value={values}
                    onChange={(e1, e2) => {
                      handleCustomChange(
                        e1,
                        e2,
                        "short_title",
                        setFieldValue,
                        "input"
                      );
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                    component_type={"input"}
                  />
                  <DynamicInput
                    labelTitle={"Birim Tipi"}
                    containerStyle={"w-full"}
                    placeholder={"Birim Tipi"}
                    name={"type"}
                    value={values}
                    onChange={(e1, e2) => {
                      handleCustomChange(
                        e1,
                        e2,
                        "type",
                        setFieldValue,
                        "select"
                      );
                    }}
                    error={errors}
                    isTouched={touched}
                    onBlur={handleBlur}
                    component_type={"select"}
                    customOptions={[
                      { label: "Rakam", value: "rakam" },
                      { label: "Tek Karakter", value: "tek_karakter" },
                      { label: "Karakter", value: "karakter" },
                      { label: "Liste", value: "liste" },
                    ]}
                  />
                </div>

                <div className="flex items-center justify-end gap-4 pt-4">
                  <ButtonComponent
                    onClick={() => {
                      setOpen(false);
                      resetForm();
                    }}
                    text={"Vazgeç"}
                    outline
                  />
                  <ButtonComponent submit fill text={"Kaydet"} />
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}

function CreateUnitModal({ isOpen, setOpen, setIsDataChange }) {
  return (
    <div>
      <CustomModal width={677} isOpen={isOpen} setOpen={setOpen} footer={null}>
        <CustomModalHeader
          title={"Yeni Sezon Ekleyin"}
          description={"Aşağıdan yeni sezon ekleyin."}
        />
        <CreateUnitForm
          isOpen={isOpen}
          setOpen={setOpen}
          setIsDataChange={setIsDataChange}
        />
      </CustomModal>
    </div>
  );
}

export default CreateUnitModal;
