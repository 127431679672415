import { MainLayout, AuthLayout } from "../layouts";
import { Navigate } from "react-router-dom";
import {
  Calendar,
  Dashboard,
  Login,
  Players,
  Scoreboard,
  Matches,
  Curriculum,
  Activities,
  Statistics,
  Fixtures,
  Profile,
  Notifications,
  Treatments,
  Applicants,
  EditPlayers,
  Employees,
} from "../pages";
import AddActivities from "../pages/activities/addActivities";
import EditActivities from "../pages/activities/editActivities";
import EditMatches from "../pages/matches/editMatches";
import EditTreatments from "../pages/treatments/editTreatments";
import AddTreatments from "../pages/treatments/addTreatments";
import EditApplicants from "../pages/applicants/editApplicants";
import DetailActivities from "../pages/activities/detailActivities";
import ApprovalActivities from "../pages/activities/approvalActivities";
import TrainingCalendar from "../pages/trainingCalendar";
import StartingLineup from "../pages/startingLineup";
import Teams from "../pages/teams";
import EditTeam from "../pages/teams/editTeam";
import GeneralDefinitions from "../pages/generalDefinitions";
// import EditPlayers from "../pages/users/editPlayers";
import AddPlayers from "../pages/users/players/addPlayers";
import AllUsers from "../pages/users/allUsers";
import DynamicForm from "../pages/dynamicForm";
import CreateDynamicForm from "../pages/dynamicForm/createDynamicForm";
import EditTest from "../pages/activities/editTest";
import Reports from "../pages/reports";
import EditFixture from "../pages/fixtures/edit";
import AddApplicant from "../pages/applicants/addApplicant";
// import Coaches from "../pages/coaches/coaches";
import Coaches from "../pages/coaches/coaches"
import AddCoaches from "../pages/coaches/coaches/addCoaches";
import EditCoaches from "../pages/coaches/coaches/editCoaches";
import EditEmployees from "../pages/employees/employees/editEmployees";
import AddEmployees from "../pages/employees/employees/addEmployees";

const Paths = {
  Root: "/",
  RootA: "/*",
  Dashboard: "/dashboard",
  Users: {
    AllUsers: {
      AllUsers: "/users/all-users",
      AddAllUsers: "/users/add-all-users",
      EditAllUsers: "/users/edit-all-users/",
      EditAllUsersWithParams: "/users/edit-all-users/:id",
    },
    Players: {
      Players: "/users/players",
      AddPlayers: "/users/add-players",
      EditPlayers: "/users/edit-players/",
      EditPlayersWithParams: "/users/edit-players/:id",
    },
  },
  Coaches: {
    Coaches: "/coaches",
    AddCoaches: "/coaches/add-coaches",
    EditCoaches: "/coaches/edit-coaches/",
    EditCoachesWithParams: "/coaches/edit-coaches/:id",
  },
  Employees: {
    Employees: "/employees",
    AddEmployees: "/employees/add-employees",
    EditEmployees: "/employees/edit-employees/",
    EditEmployeesWithParams: "/employees/edit-employees/:id",
  },
  Calendar: "/calendar",
  Scoreboard: "/scoreboard",
  Matches: {
    Matches: "/matches",
    // AddMatches: "/matches/add-matches/",
    EditMatches: "/matches/edit-matches/",
    EditMatchesWithParams: "/matches/edit-matches/:id",
  },
  Statistics: "/statistics",
  Activities: {
    Activities: "/activities",
    AddActivities: "/activities/add-activities",
    EditActivities: "/activities/edit-activities/",
    EditActivitiesWithParams: "/activities/edit-activities/:id",
    DetailActivities: "/activities/detail-activities/",
    DetailActivitiesWithParams: "/activities/detail-activities/:id",
    ApprovalActivities: "/activities/approval-activities/",
    EditTest: "/activities/edit-test/",
    EditTestWithParams: "/activities/edit-test/:id",
  },
  Fixtures: {
    Fixtures: "/fixtures",
    FixturesWithParams: "/fixtures/:id",
  },
  Profile: "/profile",
  Notifications: "/notifications",
  Treatments: {
    Treatments: "/treatments",
    AddTreatments: "/treatments/add-treatments",
    EditTreatments: "/treatments/edit-treatments/",
    EditTreatmentsWithParams: "/treatments/edit-treatments/:id",
  },
  Applicants: {
    Applicants: "/applicants",
    AddApplicant: "/applicants/add-applicant/",
    EditApplicants: "/applicants/edit-applicants/",
    EditApplicantsWithParams: "/applicants/edit-applicants/:id",
  },
  Curriculum: "/curriculum",

  TrainingCalendar: {
    TrainingCalendar: "/training-calendar",
  },
  StartingLineup: {
    StartingLineup: "/starting-lineup",
  },
  Teams: {
    Teams: "/teams",
    AddTeam: "/teams/add-team",
    EditTeam: "/teams/edit-team/",
    EditTeamWithParams: "/teams/edit-team/:id",
  },
  GeneralDefinitions: {
    GeneralDefinitions: "/general-definitions",
    GeneralDefinitionsWithParams: "/general-definitions/:id",
    // GeneralDefinitions: "/teams/add-team",
    // GeneralDefinitions: "/teams/edit-team/",
    // GeneralDefinitions: "/teams/edit-team/:id",
  },

  DynamicForm: {
    DynamicForm: "/dynamic-form",
    CreateDynamicForm: "/create-dynamic-form",
  },

  Reports: {
    Reports: "/reports",
    ReportsWithParams: "/reports/:id",
  },

  Auth: {
    RootA: "/auth/*",
    Login: "/auth/login",
  },
};

const Routes = {
  PrivateRoutes: [
    {
      layout: <MainLayout />,
      pages: [
        {
          path: Paths.Dashboard,
          element: <Dashboard />,
        },
        {
          path: Paths.Users.AllUsers.AllUsers,
          element: <AllUsers />,
        },
        {
          path: Paths.Users.AllUsers.AddAllUsers,
          element: <AddPlayers />,
        },
        {
          path: Paths.Users.AllUsers.EditAllUsersWithParams,
          element: <EditPlayers />,
        },
        {
          path: Paths.Users.Players.Players,
          element: <Players />,
        },
        {
          path: Paths.Users.Players.AddPlayers,
          element: <AddPlayers />,
        },
        {
          path: Paths.Users.Players.EditPlayersWithParams,
          element: <EditPlayers />,
        },
        {
          path: Paths.Coaches.Coaches,
          element: <Coaches />,
        },
        {
          path: Paths.Coaches.AddCoaches,
          element: <AddCoaches />,
        },
        {
          path: Paths.Coaches.EditCoaches,
          element: <EditCoaches />,
        },
        {
          path: Paths.Employees.Employees,
          element: <Employees />,
        },
        {
          path: Paths.Employees.AddEmployees,
          element: <AddEmployees />,
        },
        {
          path: Paths.Employees.EditEmployees,
          element: <EditEmployees />,
        },
        {
          path: Paths.Calendar,
          element: <Calendar />,
        },
        {
          path: Paths.Scoreboard,
          element: <Scoreboard />,
        },
        {
          path: Paths.Matches.Matches,
          element: <Matches />,
        },
        // {
        //   path: Paths.Matches.AddMatches,
        //   element: <AddMatches />,
        // },
        {
          path: Paths.Matches.EditMatchesWithParams,
          element: <EditMatches />,
        },
        {
          path: Paths.Curriculum,
          element: <Curriculum />,
        },
        {
          path: Paths.Activities.Activities,
          element: <Activities />,
        },
        {
          path: Paths.Activities.AddActivities,
          element: <AddActivities />,
        },
        {
          path: Paths.Activities.EditActivitiesWithParams,
          element: <EditActivities />,
        },
        {
          path: Paths.Activities.EditTestWithParams,
          element: <EditTest />,
        },
        {
          path: Paths.Activities.DetailActivitiesWithParams,
          element: <DetailActivities />,
        },
        {
          path: Paths.Activities.ApprovalActivities,
          element: <ApprovalActivities />,
        },
        {
          path: Paths.Statistics,
          element: <Statistics />,
        },
        {
          path: Paths.Fixtures.Fixtures,
          element: <Fixtures />,
        },
        {
          path: Paths.Fixtures.FixturesWithParams,
          element: <EditFixture />,
        },
        {
          path: Paths.Profile,
          element: <Profile />,
        },
        {
          path: Paths.Notifications,
          element: <Notifications />,
        },
        {
          path: Paths.Treatments.Treatments,
          element: <Treatments />,
        },
        {
          path: Paths.Treatments.EditTreatmentsWithParams,
          element: <EditTreatments />,
        },
        {
          path: Paths.Treatments.AddTreatments,
          element: <AddTreatments />,
        },
        {
          path: Paths.Applicants.Applicants,
          element: <Applicants />,
        },
        {
          path: Paths.Applicants.AddApplicant,
          element: <AddApplicant />,
        },
        {
          path: Paths.Applicants.EditApplicantsWithParams,
          element: <EditApplicants />,
        },
        {
          path: Paths.TrainingCalendar.TrainingCalendar,
          element: <TrainingCalendar />,
        },
        {
          path: Paths.StartingLineup.StartingLineup,
          element: <StartingLineup />,
        },
        {
          path: Paths.Teams.Teams,
          element: <Teams />,
        },
        {
          path: Paths.Teams.EditTeamWithParams,
          element: <EditTeam />,
        },
        {
          path: Paths.GeneralDefinitions.GeneralDefinitionsWithParams,
          element: <GeneralDefinitions />,
        },
        {
          path: Paths.GeneralDefinitions.GeneralDefinitions,
          element: <GeneralDefinitions />,
        },
        {
          path: Paths.DynamicForm.DynamicForm,
          element: <DynamicForm />,
        },
        {
          path: Paths.DynamicForm.CreateDynamicForm,
          element: <CreateDynamicForm />,
        },
        {
          path: Paths.Reports.Reports,
          element: <Reports />,
        },
        {
          path: Paths.Reports.ReportsWithParams,
          element: <Reports />,
        },
        {
          path: Paths.RootA,
          element: <Navigate to={Paths.Dashboard} />,
        },
      ],
    },
  ],
  PublicRoutes: [
    {
      layout: <AuthLayout />,
      pages: [
        {
          path: Paths.Auth.Login,
          element: <Login />,
        },
        {
          path: Paths.RootA,
          element: <Navigate to={Paths.Auth.Login} />,
        },
      ],
    },
  ],
};

export { Paths, Routes };
