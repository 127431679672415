import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Profile, SearchBox, ToolBox } from "../../index";
import { SidebarContext } from "../../../context";
import { Files, SidebarMenuList } from "../../../utils";

import MenuBox from "./MenuBox";

const Sidebar = () => {
  const { profile } = Files;
  const location = useLocation();
  const SidebarContextData = useContext(SidebarContext);

  useEffect(() => {
    SidebarContextData.setActive(false);
  }, [location]);

  return (
    <div
      className={`fixed top-0 z-[999] w-72 h-screen bg-white shadow flex transition-all ${
        SidebarContextData.active ? "left-0" : "-left-80"
      } overflow-y-auto`}
    >
      <div className="flex flex-col items-center w-full">
        <div className="my-5">
          <Profile image={profile} name="Uğur Bey" job="CTO" />
        </div>
        <div className="w-full">
          <div className="flex justify-center">
            <ToolBox />
          </div>
          <div className="my-5">
            <SearchBox />
          </div>
          <MenuBox menuList={SidebarMenuList} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
