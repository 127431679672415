import React, {useEffect, useState, useRef, useContext} from "react";
import ButtonComponent from "../../../../components/buttons/ButtonComponent";
import DynamicInput from "../../../../components/inputs/dynamicInput";
import Select from "react-select";
import {Formik, useFormik} from "formik";
import {handleCustomChange} from "../../../../utils/handleCustomChange";
import {
    editActivityMovementDescription,
} from "../../../../services/axios";
import Swal from "sweetalert2";
import {selectedPlayerStatuses} from "../../../../components/attendance/options";


function EditActivityDescriptionForm({
    data,
    setActivityMovements,
    setOpen
                                     }) {
    const {
        errors,
        values,
        handleSubmit,
        touched,
        handleBlur,
        setFieldValue,
    } = useFormik({
        initialValues: {
            description: data?.description ?? ""
        },
        onSubmit: async (values) => {
            console.log(values, "values")
            try {
                const result = await editActivityMovementDescription(data?.id, values?.description);
                if (result.success){
                    setActivityMovements((prevState) => {
                        return prevState.map((item) => {
                            if (item.id === data?.id) {
                                return { ...item, description: result?.data?.description };
                            } else {
                                return item;
                            }
                        });
                    });
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: result?.message ?? "Düzenleme Başarılı!",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                    setOpen(false)
                }else{
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: result?.message ?? "Düzenleme Başarısız!",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                }
            } catch (error) {
                console.log("hata", error);
            } finally {
                //
            }

        }
    });

    useEffect(() => {
        if (data?.description !== values.description) {
            setFieldValue('description', data?.description ?? "");
        }
    }, [data, setFieldValue]);
    return (
        <form onSubmit={handleSubmit} className={"mt-5"}>
            <DynamicInput
                containerStyle={"w-full"}
                placeholder={"Aktivite Açıklaması"}
                name={"description"}
                value={values}
                onChange={(e1, e2) => {
                    handleCustomChange(
                        e1,
                        e2,
                        "description",
                        setFieldValue,
                        "textarea"
                    );
                }}
                error={errors}
                isTouched={touched}
                onBlur={handleBlur}
                component_type={"textarea"}
            />
            <div className={"mt-5"}>
                <ButtonComponent fill submit text={"Değişiklikleri Kaydet"}/>
            </div>
        </form>
    )
}

export default EditActivityDescriptionForm;