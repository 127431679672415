import React from "react";
import {Link} from "react-router-dom";
import {Progress} from "antd";

const LeftRoundedCard = ({href, percent, title, subTitle, bgColor}) => {
    return (
        <Link
            to={href}
            className={`flex items-center p-2 lg:px-3 ${bgColor} rounded-l-full rounded-r- xl:h-full`}
        >
            <Progress type="circle"size={110} strokeColor="#fff" percent={percent} format={(percent) => {
                return (
                    <span className="text-white font-semibold">{percent}%</span>
                )
            }}/>

            <div className="ml-4 text-white">
                <h3 className="text-xl font-bold">{title}</h3>
                <span className="text-md">{subTitle}</span>
            </div>
        </Link>
    );
};

export default LeftRoundedCard;
