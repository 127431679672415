import { Link } from "react-router-dom";

import { Input } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { FiPlus } from "react-icons/fi";
import { Paths } from "../../../routes";
import { DataTable } from "../../../components/tables";
import {
  GetTeams,
  FilterTeams,
} from "../../../services/redux/features";
import ActionButtonsDataTable from "../../../containers/sections/ActionButtonsDataTable";
import { useEffect, useState } from "react";

const TeamsTable = () => {
  const [filterInputValue, setFilterInputValue] = useState("");
  const [rowSelectionList, setRowSelectionList] = useState([]);

  const { teams, filterTeams, loading } = useSelector(
    (state) => state.teams
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!teams?.length) {
      dispatch(GetTeams());
    }
  }, []);

  useEffect(() => {
    dispatch(FilterTeams(filterInputValue));
  }, [filterInputValue]);

  const columns = [
    {
      title: "Takım Kodu",
      dataIndex: "teamsCode",
    },
    {
      title: "Takım Adı",
      dataIndex: "teamsName",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Kategori",
      dataIndex: "teamsCategory",
    },
    {
      title:
        "",
      dataIndex:
        "",
        width:"50%",
    },
    {
      title: "İşlem",
      fixed: "right",
      key: "action",
      sorter: true,
      render: (item, index) => {
        return (
          <div className="">
            <ActionButtonsDataTable
              to={Paths.Teams.EditTeamWithParams}
              id={item.key}
              toUpdate={Paths.Teams.EditTeamWithParams}
              toDetail={Paths.Teams.EditTeamWithParams}
              deleteDataLabel={item.name}
              detailButton
              updateButton
              deleteButton
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between text-gray-500">
        <h4 className="text-lg font-semibold">Takımlar</h4>
        {filterInputValue ? (
          <p className="text-sm ">
            Filtrelenmiş {filterTeams?.length} Takım Listeleniyor!
          </p>
        ) : (
          <p className="text-sm ">{teams?.length} Takım Listeleniyor!</p>
        )}
      </div>
      <div className="flex flex-wrap-reverse items-center justify-between px-2 py-2 my-4 bg-gray-100 rounded-lg">
        <ul className="flex items-center">
          <li>
            <Link
              to={Paths.Teams.EditTeam}
              className="flex p-2 text-gray-500 transition-all rounded-lg hover:bg-gray-200"
            >
              <FiPlus size={21} />
              <span className="ml-2 text-sm text-gray-500">Ekle</span>
            </Link>
          </li>
        </ul>
        <div className="flex items-center w-full sm:w-auto">
          <span className="hidden mr-2 text-sm text-gray-500 sm:block">
            Filtrele:{" "}
          </span>
          <Input
            type="text"
            placeholder="Filtrele.."
            value={filterInputValue}
            onChange={({ target }) => setFilterInputValue(target.value)}
          />
        </div>
      </div>
      <div className="">
        <DataTable
          columns={columns}
          data={filterInputValue ? filterTeams : teams}
          selection={{ rowSelectionList, setRowSelectionList }}
          loading={loading}
        />
      </div>
    </>
  );
};

export default TeamsTable;
