import { BsFillPersonCheckFill } from "react-icons/bs";

export const AllUserFormInputsData = [
  {
    icon: <BsFillPersonCheckFill className="text-2xl " />,
    title: "Kullanıcı Bilgileri Ekle",
    updateTitle: "Kullanıcı Bilgileri Güncelle",
    inputs: [
      {
        labelTitle: "Kullanıcı Kodu",
        id: "",
        name: "userCode",
        type: "text",
        placeholder: "Kullanıcı Kodu",
        required: true,
        leftIcon: <BsFillPersonCheckFill className="w-full h-full" />,
      },
      {
        labelTitle: "Kullanıcı Adı",
        id: "",
        name: "userNickname",
        type: "text",
        placeholder: "Kullanıcı Adı",
        required: true,
        leftIcon: <BsFillPersonCheckFill className="w-full h-full" />,
      },
      {
        labelTitle: "Şifre",
        id: "",
        name: "userPassword",
        type: "text",
        placeholder: "Kullanıcı Adı",
        required: true,
        leftIcon: <BsFillPersonCheckFill className="w-full h-full" />,
      },
      {
        labelTitle: "Şifre Tekrar",
        id: "",
        name: "userConfirmPassword",
        type: "text",
        placeholder: "Kullanıcı Adı",
        required: true,
        leftIcon: <BsFillPersonCheckFill className="w-full h-full" />,
      },
      {
        labelTitle: "Kullanıcıyı Pasif Yap",
        id: "",
        name: "userIsActive",
        checkbox: "checkbox",
        placeholder: "Kullanıcı Adı",
        required: true,
        leftIcon: <BsFillPersonCheckFill className="w-full h-full" />,
      },
    ],
  },
];
