import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputComponent from "../../../components/inputs";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Paths } from "../../../routes";
import { Input } from "antd";
import { PulseLoader } from "react-spinners";
import validations from "../../../utils/validations";
import PageTitle from "../../../components/pageTitle";
import { useFormik } from "formik";
import { ActivitiesFormInputsData } from "../../../utils/datas/activitiesFormInputsData";
import { BsChevronLeft } from "react-icons/bs";
import { GetActivities } from "../../../services/redux/features";

const DetailActivities = () => {
  const { activities, filteredActivities } = useSelector(
    (state) => state.activities
  );
  const [updateItem, setUpdateItem] = useState({});
  const [setBreadcrumbData] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const { id } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!activities?.length) {
      dispatch(GetActivities());
    }
  }, []);

  const ActivitiesFind = () => {
    let x = activities.find((item) => item.key == id);
    setValues(x);
    setUpdateItem(x);
  };

  useEffect(() => {
    ActivitiesFind();
    setBreadcrumbData([
      {
        title: "Panel",
      },
      {
        title: <Link to={Paths.Activities.Activities}>Faaliyet Listesi</Link>,
      },
      {
        title: (
          <Link to={Paths.Activities.EditActivities}>Faaliyet Detayı</Link>
        ),
      },
    ]);
  }, []);

  const {
    errors,
    values,
    setValues,
    handleChange,
    handleSubmit,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      status: "",
      activitiesDate: "",
      activitiesTime: "",
      activitiesDescription: "",
      activitiesGroup: "",
      activitiesZone: "",
      activitiesTeam: "",
      manager: "",
    },
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setTimeout(() => {
        setSaveError("Başarısız!");
        resetForm();
        setLoading(false);
      }, 500);
    },
    validationSchema: validations,
  });

  const navigate = useNavigate();
  return (
    <>
      <div className="p-4 pb-24 bg-white rounded-md shadow-xl">
        <div className="flex items-center text-gray-500">
          <button onClick={() => navigate(-1)}>
            <BsChevronLeft size={21} />
          </button>
          <h4 className="ml-4 text-lg font-semibold">Faaliyet Detayı</h4>
        </div>
        {/* content */}
        
        {/* content */}
      </div>
    </>
  );
};

export default DetailActivities;
